// Helpers
import { ComProfile } from '../comProifleHelpers'; 

// Components
import ComProfileCompare from './ComProfileCompare';
import SynergyTalentHrm from './SynergyTalentHrm';
import TalentNeeds from './TalentNeeds';

// Styles
import './style.css'

// Interfaces
interface ITalentCompareHrm {
    talentComProfile: ComProfile,
    hrmComProfile: ComProfile
    talentfirstName: string;
    helpTalentBy: {} | null;
    avoidSurvivalModeBy: {} | null;
    talentSummary: {} | null;
}

const TalentCompareHrm: React.FunctionComponent <ITalentCompareHrm> = (props): JSX.Element => {
    const { talentComProfile, hrmComProfile,talentfirstName, helpTalentBy, avoidSurvivalModeBy, talentSummary } = props;
    return(
        <div className='communication-compare' style={{ marginTop: "100px" }}>
            {/* Competability Profile Comperasion */}
            <ComProfileCompare
            talentComProfile={talentComProfile}
            hrmComProfile={hrmComProfile}
            talentfirstName={talentfirstName}/>
            {/* Synergy Between Manager and Talet */}
            {talentSummary ? 
            <SynergyTalentHrm
            summary={talentSummary}/> : null}
            {/* Talet Needs */}
            {helpTalentBy && avoidSurvivalModeBy ? 
            <TalentNeeds
            helpTalentBy={helpTalentBy}
            avoidSurvivalModeBy={avoidSurvivalModeBy}/> : null}
        </div>
    )
}

export default TalentCompareHrm;