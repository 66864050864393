import React from "react";
import orangArrowIcon from "../../icons/orange-arrow.svg";

import "./style.css";
import { getLabelValueArrFromCvInsights } from "../../helpers";

const CVHighlights: React.FunctionComponent<{
  avgYearsPerEmployer?: number;
  totalYearsExp?: number;
  totalYearsManagementExp?: number;
  gaps?: number;
  candidateSummary?: string;
  skills?: string[];
  lastJobTitle?: string;
  firstName?: string;
}> = ({
  avgYearsPerEmployer,
  totalYearsExp,
  totalYearsManagementExp,
  candidateSummary,
  skills,
  gaps,
  firstName
}): JSX.Element => {

  return (
    <div className="cv-highlights-section">
      {candidateSummary && firstName && 
      <div className="cv-highlights-section-summary">
        <div className="cv-highlights-headers-container">
          <h2 className="cv-highlights-header">
            {"Highlights"}
          </h2>
          {/* <p className="cv-highlights-header-text">
            {`Important information we gathered from ${firstName}'s CV`}
          </p> */}
        </div>
        <p className="cv-highlights-section-decription">
          {candidateSummary}
        </p>
      </div>}

      <div className="cv-highlights-insights-section">
        <div className="cv-highlights-headers-container">
          <span className="extracted-from-text">
            {"Extracted from"}
          </span>
          <span className="talent-cv-text">
            {"Talent CV"}
            <img src={orangArrowIcon} />
          </span>
        </div>
        <div className="cv-highlights-section-insights">
          {getLabelValueArrFromCvInsights({
            avgYearsPerEmployer,
            totalYearsExp,
            totalYearsManagementExp,
            gaps
          })?.map((labelValue, index) => (
            <React.Fragment key={index}>
              {index !== 0 &&
              <div className="cv-ihghlights-section-seperate-line"></div>}
              <div className="cv-highlights-section-insights-single">
                <h3>{labelValue.value}</h3>
                <span>{labelValue.label}</span>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      {skills && skills.length > 0 &&
      <div className="cv-highlights-section-skills-container"> 
        {skills.map((skill, index) => (
        <div className="cv-highlights-section-skill" key={index}>
          {skill}
        </div>))}
      </div>}
    </div>
  );
};

export default CVHighlights;
