import { Dialog } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

import './style.css';

const dialogStyle = {
    "& .MuiDialog-container": {
        backgroundColor: "#0008",
        "& .MuiPaper-root": {
            "maxWidth": "unset",
            "minWidth": "unset",
            "width": "unset",
        },
    },
}


const Modal: React.FunctionComponent<{ openModal: boolean, onClose: () => any, children?: React.ReactChild, title?: string }> = ({
    openModal,
    onClose,
    children,
    title,
}): JSX.Element => {
    return(
        <Dialog
        open={openModal}
        onClose={onClose}
        sx={dialogStyle}>
            <div className="report-modal-header">
                {title && 
                <h2 className="report-modal-header-title">{title}</h2>}
                <CloseIcon
                className="report-modal-header-close-icon"
                style={{ position: "absolute", right: "12", top: "16", cursor: "pointer", fontSize: "24px !important" }}
                onClick={() => onClose()}/>
            </div>
            {children}
        </Dialog>
    )
}

export default Modal;