// Components
import CustomSlider from './../../components/Slider';

// Level to icon object
import { levelIcon } from './levelObjects';

// Styles
import './style.css';

// Interfacea
interface ISkills {
    skills: []
}

const Skills: React.FunctionComponent<ISkills> = (props): JSX.Element => {
    const { skills } = props;
    return (
        <div>
            {skills?.map((s: any, i: number) => (
                <div 
                className='review-skills-section-single' 
                key={i+"skill"}
                onClick={e => {
                    const answerQuestionSection = document.getElementById(s.evaluator.title);
                    e.preventDefault();  // Stop Page Reloading
                    answerQuestionSection && answerQuestionSection.scrollIntoView({ behavior: 'smooth' });
                }}>
                    <span className='review-skills-section-name'>
                        {s.evaluator.title}
                    </span>
                    <div className='review-skills-section-score'> 
                        <div className='review-skills-section-icon'>
                            <img src={s.score !== -1 ? levelIcon[s.evaluator.level] : levelIcon['open_question']}/>
                        </div>
                        <div className='review-skills-section-slider'>
                            <CustomSlider
                            defaultValue={s.score * 100}
                            min={0}
                            max={100}
                            />
                        </div>
                        {s.score !== -1 ? 
                        <span className='review-skills-section-slider-value'>
                            {Math.floor(s.score * 100)} 
                        </span>
                        : 
                        <div className='review-skills-section-icon-condition'>
                            <img src={levelIcon['open_question']}/>
                        </div>}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Skills;