import profilePlaceholder from '@assets/icons/profile-placeholder.svg';
import phoneIcon from "../../icons/phone.svg";
import mailIcon from "../../icons/mail.svg";
import linkedinIcon from "../../icons/linkedin.svg";
import { CircleVideo } from './components';

import playIcon from '../../icons/play-icon.svg';

import './style.css';

const CandidateProfile: React.FunctionComponent<{ 
    profileImage?: string,  
    videoUrl?: string,
    phone?: string, 
    email?: string, 
    linkedin?: string,
    name?: string,
    onVideoPlayButtonClicked?: () => any,
}> = ({
    profileImage,
    videoUrl,
    phone,
    email,
    linkedin,
    name,
    onVideoPlayButtonClicked
}): JSX.Element => {

    return (
        <div className="candidate-profile-container">
            {videoUrl &&
            <div className='candiate-profile-video-container'>
                <CircleVideo
                muted
                loop
                autoPlay
                onClick={onVideoPlayButtonClicked}
                videoSrc={videoUrl}/>
                <img 
                className="candidate-profile-play-icon"
                onClick={onVideoPlayButtonClicked}
                src={playIcon} />
            </div>}

            {!videoUrl &&
            <img 
            className="candidate-profile-image" 
            src={profileImage ?? profilePlaceholder} 
            alt="candidate profile"/>}
            
            <div className="candidate-profile-info">
                {videoUrl &&
                <div 
                className="candidate-profile-play-video"
                onClick={onVideoPlayButtonClicked}>
                    <img src={playIcon}/>
                    <span>
                        Play Video
                    </span>
                </div>}
                {phone && phone.length === 10 &&
                <div className="candidate-profile-phone">
                    <img src={phoneIcon} alt="phone"/>
                    <span>{phone}</span>
                </div>}
                {email &&
                <div className="candidate-profile-email">
                    <img src={mailIcon} alt="email"/>
                    <span>{email}</span>
                </div>}
                {linkedin && name &&
                <a
                target={'_blank'}
                href={linkedin}
                className="candidate-profile-linkedin">
                    <img src={linkedinIcon} alt="linkedin"/>
                    <span>{name}</span>
                </a>}
            </div>
        </div>
    )
}

export default CandidateProfile;