import { getUsers } from "@queries/users"

// Auth
import { useAuth, IAuth } from "@auth"

// Permissions
import { usePermissions, IPermissions, AuthComponent } from "@permissions"

// Components
import { Title, Modalbox } from "@components"
import MaterialTable from '@/components/MaterialTable';

// Avatar icon
import { ReactComponent as UserIcon } from '@assets/icons/user.svg'

// Context
import PermissionsPageContext from "@context/PermissionsPage"

// Logic
import usePermissionsPage, { IPermissionsPage } from "@pages/Permissions/usePermissionsPage"

// Helpers
import { getPublicNameByUser, graphQueries } from "@helpers"

// Intefaces
import { GridColDef } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import {
  FormControl, MenuItem, Select,
  // Checkbox 
} from "@mui/material";

import { updateUserProfile } from "@/graphql/mutations/updateUserProfile";
import { Link } from "react-router-dom";
import { getAllDepartments } from "@/graphql/queries/departments"

import useLocalToast from "../../hooks/useLocalizedToast";
import LText from "@/components/LText";
import AppContext from "@/context/AppContext";


// Local Interfaces
interface ISelectOption {
  label: string
  value: string
}

export interface IDepartment {
  id: string;
  department: string;
}


// Roles
const _rolesArray: ISelectOption[] = [
  {
    label: "Company Administrator",
    value: "company_admin",
  },
  {
    label: "Lnrd Administrator",
    value: "lnrd_admin",
  },
  {
    label: "Company User",
    value: "company_user",
  },
]


/**
 *
 * Users and Permissions
 * @description provides user and permissions management
 *
 */
const PermissionsPage: React.FunctionComponent = (): React.ReactElement => {
  // Auth
  const { user }: IAuth = useAuth()

  // Permissions
  const { hasPermissions }: IPermissions = usePermissions()

  // States
  const [users, setUsers] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);
  const [roles, setRoles] = useState<ISelectOption[]>(_rolesArray.slice())

  const [rawDepartments, setRawDepartments] = useState<IDepartment[]>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState<boolean>(true)
  const { toast } = useLocalToast();
  const { locale, localizer, tr } = useContext(AppContext);

  const [
    // isUpdatingUser
    , setIsUpdatingUser] = useState<boolean>(false);

  // Logic
  const {
    fetcher,
    showModal,
    userToDelete,
    setShowModal,
    setUserToDelete,
    deleteUserRequest
  }: IPermissionsPage = usePermissionsPage()

  useEffect(() => {
    setRoles(localizer.localizeObject({ data: _rolesArray, locale, keys: 'label' } ))
  }, [locale])

  useEffect(() => {
    setIsLoadingUsers(true);
    fetcher(getUsers(""), 0, 100000)
      .then((data: any) => {
        // Save data for search
        setOriginalData(data.items);
        // Save data for filtering
        setUsers(data.items);
      })
      .catch(console.error)
      .finally(() => setIsLoadingUsers(false));
  }, []);

  useEffect(() => {
    setIsLoadingDepartments(true);
    graphQueries.sendRequest(getAllDepartments, {})
      .then(({ getAllDepartments: response }) => {
        const { departments } = response || {};

        setRawDepartments(departments || [])
      })
      .catch(console.error)
      .finally(() => setIsLoadingDepartments(false));
  }, [])

  useEffect(() => {
    if (rawDepartments.length) {
      setDepartments(rawDepartments.map(d => ({
        id: d.id,
        department: tr(d.department)
      })))
    }
  }, [locale, rawDepartments])

  // On update user permission
  const handlePermissionChange = async (userId: string, permissionLabel: string) => {
    const permissionType = roles.find(r => r.label === permissionLabel)

    const success = await graphQueries.sendRequest(
      updateUserProfile,
      {
        "updateUserUserId": userId,
        "updateUserRole": permissionType?.value
      }
    )

    if (!success) {
      toast("Failed to set user permission", {
        toastId: "user-permission",
        autoClose: 4000,
        containerId: "default"
      })
    } else {

      // Find row by id
      const row = users.find(u => u.id === userId);

      // Check if row exist
      if (row !== undefined) {
        // Change status to given status
        row.role = permissionType?.value;

        // Replace old value with the new one
        setUsers((prevState: any) => {
          return prevState.map((u: any) => u.id === userId ? row : u)
        });
      }
    }
  }

  // On delete user
  const handleUserDelete: React.MouseEventHandler<HTMLDivElement> = (user: any): void => {
    // We set user data to delete
    setUserToDelete(user as any)

    // We show modal box
    setShowModal(true)
  }

  // const handleBlockUnblockUser = (userId: string, block: boolean) => { 
  //   graphQueries.sendRequest(updateUserProfile, {
  //       "updateUserUserId": userId,
  //       "updateActive": !block
  //   })
  //   .then(() => {
  //     setOriginalData(prev => 
  //       prev.map(u => 
  //         u.id === userId ? 
  //         {...u, active: !block}
  //         : u 
  //       ))
  //   })
  //   .catch(() => toast("Cannot update user", { containerId: "default", type: "error" }))
  // }

  const handleDepartmentChange = (userId: string, departmentId: string) => {
    setIsUpdatingUser(true);

    const departmentLabel = departments.find(d => d.id === departmentId)?.department;
    if (!departmentLabel) {
      return toast("Cannot update user", { containerId: "default", type: "error" });
    }

    graphQueries.sendRequest(updateUserProfile, {
      "updateUserUserId": userId,
      "updateDepartmentId": departmentId
    })
      .then(() => {
        setOriginalData(prev =>
          prev.map(u =>
            u.id === userId ?
              { ...u, department: departmentLabel }
              : u
          ))
      })
      .catch(() => toast("Cannot update user", { containerId: "default", type: "error" }))
      .finally(() => setIsUpdatingUser(false))
  }


  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: tr("Name"),
      flex: 1,
      renderCell: (params: any) => (
        <>
          <div className="mr-3 h-9 w-9 rounded-full bg-blue-500 focus:outline-none focus:text-gray-900 text-gray-800 border-b-2 border-transparent flex items-center justify-center relative cursor-pointer">
            <UserIcon className="h-4" />
          </div>
          <Link
            className="no-underline text-gray-700 hover:text-indigo-600 font-medium"
            to={`/profile/${params.row.id}`}
          >
            {params.row.firstName + " " + params.row.lastName}
          </Link>
        </>
      )
    },
    {
      field: "role",
      headerName: tr("Edit Permissions"),
      hide: !hasPermissions("companyAdmin"),
      flex: 1,
      renderCell: (params) => {
        const rec = roles.find(r => r.value === params.row?.role)
        const role = rec?.value || "unknown"
        return (
        <AuthComponent permissions={["companyAdmin"]}>
          <FormControl >
            <Select
              MenuProps={{ sx: { "& .MuiList-root": { padding: "0 !important" } } }}
              value={role}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={(e: any) => handlePermissionChange(params.row.id, e.target.value)}
            >
              {roles.map(
                (role: ISelectOption) => {
                  if (role.value !== "lnrd_admin" || (role.value === "lnrd_admin" && /@unboxable\.com/.test(user!.email))) {
                    return <MenuItem value={role.value} key={role.value}>{role.label}</MenuItem>
                  }
                }
              )}
            </Select>
          </FormControl>
        </AuthComponent>
      )}
    },
    {
      field: "department",
      headerName: tr("Edit Department"),
      hide: !hasPermissions("companyAdmin"),
      flex: 1,
      renderCell: (params) => (
        <AuthComponent permissions={["companyAdmin"]}>
          <FormControl >
            <Select
              MenuProps={{ sx: { "& .MuiList-root": { padding: "0 !important" } } }}
              value={departments.find(d => d.department === params.row.department)?.id ?? departments.find(d => d.department.toLowerCase() === "Other department".toLowerCase())?.id ?? "null"}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={(e: any) => handleDepartmentChange(params.row.id, e.target.value)}
              native={false}
            >
              <MenuItem
                style={{ display: "none" }}
                value={"null"}
                disabled hidden>
                <LText text="Other department" />
              </MenuItem>
              {departments.map(
                (d) => <MenuItem value={d.id} key={d.id}>{d.department}</MenuItem>
              )}
            </Select>
          </FormControl>
        </AuthComponent>
      )
    },
    // {
    //     field: "block",
    //     headerName: "Blocked",
    //     sortable: false,
    //     disableColumnMenu: true,
    //     align: 'center',
    //     renderCell: (params) => (
    //       <AuthComponent permissions={["companyAdmin"]}>
    //         <FormControl>
    //           <Checkbox 
    //           disabled={isUpdatingUser}
    //           style={{ color: "#333" }}
    //           onChange={(e, checked) => handleBlockUnblockUser(params.row.id, checked)}
    //           checked={!params?.row?.active}
    //           />
    //         </FormControl>
    //     </AuthComponent>
    //     )
    // },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params) => (
        <AuthComponent permissions={["companyAdmin"]}>
          <div className="flex items-center justify-end" onClick={() => handleUserDelete(params.row)}>
            <a className="pb-0.5 text-red-500 border-transparent cursor-pointer rounded focus:outline-none focus:shadow-outline-gray">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon cursor-pointer icon-tabler icon-tabler-trash" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1={4} y1={7} x2={20} y2={7} />
                <line x1={10} y1={11} x2={10} y2={17} />
                <line x1={14} y1={11} x2={14} y2={17} />
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
              </svg>
            </a>
          </div>
        </AuthComponent>
      )
    }
  ]

  return (
    <main id="permissions">
      <PermissionsPageContext.Provider value={{ setShowModal, showModal, setUserToDelete }}>
        {/* Section Title */}
        <Title text={tr("Roles & Permissions")} />
        {/* Permissions Table */}
        <MaterialTable
          columns={columns}
          loading={isLoadingUsers || isLoadingDepartments}
          emptyStateTitle={tr("There are currently no users")}
          originalData={originalData}
          searchOnColumns={['firstName', 'role']}
        />

        {/* Confirm Delete Modalbox */}
        {showModal && (
          <Modalbox
            message={`${tr("Delete")} ${getPublicNameByUser(userToDelete!)}?`}
            description={`${tr("You're about to permanently delete")} ${getPublicNameByUser(userToDelete!)}. ${tr("Are you sure you want to continue?")}`}
            confirmText="Delete"
            onCancel={() => setShowModal(false)}
            onConfirm={() => {
              deleteUserRequest(userToDelete!)
              const newUsersList = users.filter(u => u.id !== userToDelete?.id);

              setUsers(newUsersList)
              setOriginalData(newUsersList)
            }}
          />
        )}
      </PermissionsPageContext.Provider>
    </main>
  )
}

export default PermissionsPage
