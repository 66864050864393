import { CheckIcon, UploadIcon, XIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';
import { ChangeEventHandler, useState } from 'react';
import '../styles/image-uploader.css';
import useLocalToast from "../../../../hooks/useLocalizedToast";

export interface ImageUploadProps {
  label: string | JSX.Element;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  clear?: () => void;
  multiple?: boolean;
  src?: any;
  message?: string;
  onDoUpload?: (files?: FileList) => Promise<string>;
  isImageUploaded?: boolean;
  size?: number;
  customImageTagStyle?: React.CSSProperties
  name?: string;
  className?: string;
}

const ImageUpload: React.FunctionComponent<ImageUploadProps> = ({
  label, multiple, clear, src, onDoUpload, isImageUploaded, onChange, size, customImageTagStyle, name, className
}) => {
  const [preview, setPreview] = useState<string | undefined>();
  const [imageFiles, setImageFiles] = useState<FileList>();
  const [showActions, setShowActions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imagePath, setImagePath] = useState<string>("");
  const { toast } = useLocalToast();

  useEffect(() => {
    const imgs = [];
    for (const f of imageFiles || []) {
      imgs.push(URL.createObjectURL(f));
    }
    const [p] = imgs;
    if (p) {
      setPreview(p);
    }
  }, [imageFiles]);

  const uploadFile: ChangeEventHandler<HTMLInputElement> = event => {
    const files = event.target.files;
    if (!files) {
      return console.error('no files');
    }
    setImagePath(() => event.target.value);
    setImageFiles(() => files);
    onChange?.(event);
  };



  return <div className={`image-upload-container ${className ?? ""}`} style={size ? { width: size + "px" } : {}}>
    <label className='image-upload-wrapper'>
      {label}
      <input
        name={name ?? undefined}
        value={imagePath}
        className='hide'
        onChange={uploadFile}
        type={'file'}
        multiple={multiple} />
    </label>
    {(preview || src) && <img
      style={customImageTagStyle}
      onPointerEnter={() => setShowActions(true)}
      className='image-upload-preview' src={src || preview} />}
    {
      showActions && <div
        onPointerLeave={() => {
          setShowActions(false);
        }}
        className='image-upload-actions-wrapper'>
        {isImageUploaded && <div
          className={'image-upload-icon-wrapper'}>
          <CheckIcon width={16} height={16} />
        </div>
        }
        {onDoUpload && preview && <div
          className={'image-upload-icon-wrapper'}>
          <UploadIcon width={16} height={16}
            className={loading ? ' upload-anim ' : ''}
            onClick={async event => {
              event.stopPropagation();
              if (loading) {
                return;
              }
              setLoading(true);
              const err = await onDoUpload(imageFiles);
              if (err) {
                toast(err, { containerId: "default" });
              }
              setLoading(false);
            }} />
        </div>
        }

        {clear && <div
          className='image-upload-icon-wrapper'>
          <XIcon
            width={16} height={16}
            onClick={event => {
              event.stopPropagation();
              clear();
              setPreview('');
              setImagePath('');
              setShowActions(false);
            }}
          />
        </div>}

      </div>}
  </div>
};
export default ImageUpload;