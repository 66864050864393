import { objectUtils, stringUtils } from "@/helpers"
import { IDisplayQuestion } from "."
import { IFormPage, QuestionCollection } from "./formPage"

export interface ISelectValueRecord {
	label: string
	value: number
}

export interface IAddQuestionOptions {
	/**
	 * The collection into which we add the question
	 */
	readonly collection: QuestionCollection
	/**
	 * Index of array of questions in which this question resides
	 */
	readonly index: number
	/**
	 * index of this question in the questions subarray
	 */
	readonly subIndex: number
	/**
	 * A question on which we base this question
	 */
	readonly template: IDisplayQuestion | null
	/**
	 * Optional data to merge into the copy of the template
	 */
	readonly data: Partial<IDisplayQuestion>
}


export interface IFormModelUtils {
	setSelectValue(selectOptions: Array<ISelectValueRecord> | null,
		value: string | null): Array<ISelectValueRecord>
	getQuestionStringValue(question: IDisplayQuestion | null | undefined): string
	/**
	 * Sets the value of a question at the requested index and subindex, creating the 
	 * relevant arrays if necessary
	 * @param options
	 */
	setValueInQuestionCollection({ collection, index, subIndex, template, data }: IAddQuestionOptions): QuestionCollection
	appendTitleAndValue(title: string, value: string): string
	/**
	 * Verify that pages in a datacollection have name properties. When not present, borrow it from
	 * the original model
	 * 
	 * Updates the questions IN PLACE
	 * @param data 
	 * @param models 
	 */
	updateFormPageDataNames(data: IFormPage[], models: IFormPage[] | null): IFormPage[]

	/**
	 * Tries to identify questions that have a mismatch between type and value. Uses the models, if possible,
	 * as an indication of the correct type
	 * @param questions
	 * @param page The form page from which the questions were taken. Used to identify the matching page in the models collection
	 * @param models 
	 */
	upgradeQuestions(questions: QuestionCollection, page: IFormPage, models: IFormPage[] | null): void

	findMatchingFormPage(page: IFormPage, referencePages: IFormPage[] | null): IFormPage | null

	isTextQuestion(question: IDisplayQuestion | undefined): boolean

}

// Input types that should have text values
const textInputTypes = {
	input: 1,
	textarea: 1,
	multiline: 1, //TODO is this really a type?
	email: 1
}

const upgradeOneQuestion = (q: IDisplayQuestion, model: IDisplayQuestion | undefined): IDisplayQuestion => {
	const qany = q as any || objectUtils.mergeObjects({}, model!)
	const targetType = (model && model.type) || q?.type
	if (!targetType) {
		qany.type = "none"
	}
	else if (targetType in textInputTypes && q.value) {
		if (typeof(q.value) === "object") {
			qany.value = qany.value.label || qany.value.value || ""
		}
	}
	else if (q.value === undefined || q.value === null) {
		qany.value = ""
	}
	if (qany.type !== targetType) {
		qany.type = targetType
	}
	if (model) {
		qany.required = model.required
	}
	return qany as IDisplayQuestion
}

const getStringValue = (value: undefined | null | string | ISelectValueRecord | [ISelectValueRecord]): string => {
	if (!value) {
		return ""
	}
	if (typeof value === "string") {
		return value
	}
	if (Array.isArray(value)) {
		return value.map(r => getStringValue(r)).filter(Boolean).join(',')
	}
	return value.label || ""
}

class FormModelUtils implements IFormModelUtils {

	public isTextQuestion(question: IDisplayQuestion | undefined | null): boolean {
		return Boolean(question && (question.type in textInputTypes))
	}

	public findMatchingFormPage(page: IFormPage, referencePages: IFormPage[] | null): IFormPage | null {
		if (!referencePages || !referencePages.length) {
			return null
		}
		let ret: IFormPage | undefined
		if (page.name) {
			const re = stringUtils.stringToRegex(page.name)
			ret = referencePages.find(p => re.test(p.name))
		}
		if (!ret && (page.key === 0 || page.key)) {
			ret = referencePages.find(p => p.key === page.key)
		}
		if (!ret && page.hash) {
			ret = referencePages.find(p => p.hash === page.hash)
		}
		return ret || null
	}


	public upgradeQuestions(questions: QuestionCollection, page: IFormPage, models: IFormPage[] | null): void {
		if (!questions || !questions.length) {
			return
		}
		// if questions is not null, page is guaranteed not null because it is the owner of the questions
		const modelPage = formModelUtils.findMatchingFormPage(page, models)
		questions.forEach((list, index) => {
			if (list && list.length) {
				const modelQuestions = modelPage?.questions
				const modelList = modelQuestions && modelQuestions[index]
				list.forEach((q, i) => list[i] = upgradeOneQuestion(q, modelList && modelList[i]))
			}
		})
	}


	public updateFormPageDataNames(data: IFormPage[], models: IFormPage[] | null): IFormPage[] {
		if (!data || !models || data === models || !data.some(page => !page.name)) {
			return data;
		}
		const toMap = (map: { [key: string]: IFormPage }, page: IFormPage) => {
			map[page.key] = page
			return map
		}
		const modelMap = models.reduce(toMap, {})
		const dataMap = data.reduce(toMap, {})
		Object.entries(dataMap).forEach(([key, dataPage]) => {
			const modelPage = modelMap[key]
			if (!modelPage) {
				console.warn(`createPosition: page ${key} does not exist in base model`)
				return
			}
			//const newCustom = dataPage.customQuestions && Object.values(dataPage.customQuestions)
			if (dataPage.customQuestions?.length) {
				const cq = modelPage.customQuestions || [] as QuestionCollection;
				cq!.push(...dataPage.customQuestions);
				(modelPage as any).customQuestions = cq
			}
			if (!dataPage.name) {
				(dataPage as any).name = modelPage.name
			}
		})
		return data
	}

	public setValueInQuestionCollection({ collection, index, subIndex, template, data }: IAddQuestionOptions): QuestionCollection {
		// If index for this custom question isn't created => create
		const subCollection = (collection[index] = (collection[index] || []))
		let question = subCollection[subIndex]
		if (!question) {
			//if (!template) {
			//	throw new Error(`Cannot set value of question  at ${index} ${subIndex}: null template`)
			//}
			question =
				objectUtils.mergeObjects({}, template!, { key: subIndex }) as IDisplayQuestion
			collection[index][subIndex] = question
		}
		objectUtils.mergeObjects(question, data)

		return collection
	}

	public setSelectValue(selectOptions: Array<ISelectValueRecord> | null,
		value: string | null): Array<ISelectValueRecord> {
		if (!value) {
			return selectOptions || []
		}
		if (!selectOptions || !selectOptions.length) {
			return [{ label: value, value: 0 }]
		}
		const re = stringUtils.stringToRegex(value, "i")
		let found = false // set as a side effect of sort, look away
		selectOptions.sort((rec1, rec2) => {
			const ret = re.test(rec1.label) ? -1
				: re.test(rec2.label) ? 1 : 0
			found = found || ret !== 0
			return ret;
		})
		if (!found) {
			selectOptions.unshift({ label: value, value: selectOptions.length })
		}
		selectOptions.forEach((rec, index) => rec.value = index)
		return selectOptions
	}

	public getQuestionStringValue(question: IDisplayQuestion | undefined | null): string {
		return getStringValue(question?.value)
	}

	public appendTitleAndValue(title: string, value: string): string {
		if (!title) {
			return value || ""
		}
		if (!value) {
			return title
		}
		if (title.indexOf("@value") >= 0) {
			return title.replace(/@value/i, value)
		}
		return `${title} ${value}`
	}
}

export const formModelUtils: IFormModelUtils = new FormModelUtils()