import React, { useContext} from "react";
import { IUser } from "@/auth/interfaces";
import DropdownButton from "@/components/DropdownButton/DropdownButton";
import Notifications from "@/components/Notifications";
import { INotification } from "@/components/Notifications/types";
import { IMenuItem } from "@/interfaces/sideMenu";
import { ICompanyInfo } from "@/models";
import { AuthComponent, permissions } from "@/permissions";
import { NavLink } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Profile from "@/layouts/SidebarLayout/ResponsiveNavbar/NavbarLarge/Profile";
import env from "react-dotenv";
import plugAnimation from '@/assets/animations/plug-play.json';
import { UnboxableButton } from "@/components";
import LNRDTheme from "@components/Select/styles";

import './style.css';
import { IAuth } from "@/auth";
import { mainMenuItems } from "@/content/menu";
import useMenu from "@/hooks/useMenu";
import LocaleMenu from "@/layouts/SidebarLayout/ResponsiveNavbar/NavbarLarge/LocaleMenu";
import AppContext from "@/context/AppContext";
import AuthContext from "@/auth/context";

const isHadPermissions = (permissionsArr: string[], auth: IAuth): boolean => {
    let isAllowed = true;
    permissionsArr.forEach(p => {
        if(!permissions[p](auth)) {
            isAllowed = false;
        }
    });

    return isAllowed
}

const MenuLink: React.FunctionComponent<{
    item: IMenuItem,
    auth: IAuth
}> = ({ item, auth }): JSX.Element => {

    const ACTIVE_CLASS = "items-active-link";
    const SUBITEM_ACTIVE_CLASS = "subitems-active-sublink";
    
    let currentPath = window.location.pathname;
    if(window.location.hash) currentPath += window.location.hash;
    
    
    const hasSubItems = Array.isArray(item?.subItems) && item?.subItems.length > 0

    const linkClasses = ["navigations-items-link"];
    const linkSubItemIsActive = hasSubItems && item.subItems.some(subItem => currentPath.includes(subItem.route));
    const linkIsActive = item.route && item.route === currentPath;
    if(linkSubItemIsActive || linkIsActive) linkClasses.push(ACTIVE_CLASS);
    
    return (
        <DropdownButton 
        buttonClassName="custom-link-button"
        dropdownPlacement={"bottom" as any}
        dropdownWidth={"unset"}
        dropdownClassName={"subitems-links-list"}
        buttonChildren={() => 
            <>
                {hasSubItems ? 
                <span className={linkClasses.join(' ')}>
                    {item.name}
                </span>
                :
                <NavLink 
                to={item.route!}
                className={linkClasses.join(' ')}
                >
                    {item.name}
                </NavLink>}
            </>
        } 
        dropdownChildren={(isOpen, setOpen) =>
            <div>
                {item.subItems?.map(si => (
                    si.permissions 
                    && si.permissions.length 
                    && !isHadPermissions(si.permissions, auth) ? null :
                    <li className="subitems-links-single" key={si.name}>
                        <NavLink
                        onClick={() => setOpen?.(false)}
                        to={si.route}
                        className={currentPath === si.route ? SUBITEM_ACTIVE_CLASS : ""}>
                            {si.name}
                        </NavLink>
                    </li>
                ))}
            </div>
        }
        />
    )
}


const Links: React.FunctionComponent<{
    companyId: string | undefined;
    auth: IAuth;
}> = ({companyId, auth }): JSX.Element => {
    const appCtx = useContext(AppContext);
    const { items } = useMenu(mainMenuItems, appCtx);

    return (
        <ul className="navigation-bar-links-list">
            {items.map((r, i)=> {
                if (
                    (r.name === "Internal" || r.route === "/assessments" || r.route === "/buildingblocks") &&
                    companyId !== env.POSITIONBANK_ID
                ) { return null }
                if(
                    r.permissions 
                    && r.permissions.length 
                    && !isHadPermissions(r.permissions, auth)
                ) { return null }
                return(
                    <li className="navigation-bar-links-list-single" key={r.name+i}>
                        <MenuLink
                        auth={auth} 
                        item={r}/>
                    </li>
                )
            })}
        </ul>
    )
}

const Logo: React.FunctionComponent<{ src: string, onClick: () => any }> = ({ src, onClick }): JSX.Element => (
    <div className="navigation-bar-links-logo" onClick={onClick}>
        <img
        src={src}
        className="h-4"
        alt="logo"
        />
    </div>
) 

const NavigationBar: React.FunctionComponent<{
    user: IUser | null;
    auth: IAuth;
    setCompany: any;
    notifications: INotification[];
    companyId: string | undefined;
    getUnreadNotificationsCount: () => number;
    readedNotifications: string[] | [] | undefined;
    markAllNotificationsAsRead: () => void;
    markNotificationAsRead: (id: string) => void;
    loadCompanies: (search: string) => Promise<any | undefined>;
    onCompanySelectChange: (item: any) => void;
    getDefaultOptions: any[];
    getInitialCompany: () => any;
    companies: Partial<ICompanyInfo>[] | undefined;
    userIsAdmin: boolean;
    showQuizButton: boolean;
    onClickQuizButton: () => void;
    history: any;
    companyName: string;
}> = ({
    user, 
    auth,
    setCompany, 
    notifications,
    companyId,
    getUnreadNotificationsCount,
    readedNotifications,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    loadCompanies,
    onCompanySelectChange,
    getDefaultOptions,
    getInitialCompany,
    companies,
    userIsAdmin,
    showQuizButton,
    history,
    onClickQuizButton,
    // companyName 
}): JSX.Element => {   
	const { tr } = useContext(AppContext);
    const { settings } = useContext(AuthContext);

    return(
        <div className="navigation-bar no-print">
            <div className="navigation-bar-links">
                <Logo 
                onClick={() => history.push("/positions")}
                src={"/unboxable-black-logo.svg"}/>
                <Links 
                auth={auth}
                companyId={companyId}/>
            </div>
            <div className="navigation-bar-actions-section">
                {showQuizButton &&
                <div className="navigation-bar-actions-quiz-button-wrapper">
                    <UnboxableButton
                    icon={plugAnimation}
                    iconIsPlayable={true}
                    onClick={onClickQuizButton}
                    playableIconProps={{ 
                        className: "navigation-bar-actions-quiz-button-icon",
                        autoplay: true, loop: true, 
                    }}
                    className="navigation-bar-actions-quiz-button"
                    >
                        {tr("Finish decoding your Hiring DNA")}
                    </UnboxableButton>
                </div>}

                <AuthComponent permissions={["admin"]}>
                    <div className="relative z-50" >
                        <AsyncSelect
                            loadOptions={loadCompanies}
                            placeholder={tr("Search for a company")}
                            {...LNRDTheme()}
                            className="w-48"
                            onChange={onCompanySelectChange}
                            defaultOptions={getDefaultOptions}
                            value={companies && getInitialCompany()}
                        />
                    </div>
                </AuthComponent>
                {/* {companyId &&
                <ChatRooms
                iframeChatTitle={`Discuss hiring for ${companyName}`}
                tooltipText={`Discuss ${companyName}`}
                roomId={companyId}/>} */}
                <Notifications
                    userEmail={user!.email}
                    userId={user!.id}
                    setCompany={setCompany}
                    notifications={notifications}
                    getUnreadNotificationsCount={getUnreadNotificationsCount}
                    readedNotifications={readedNotifications ?? []}
                    markAllNotificationAsRead={markAllNotificationsAsRead}
                    markNotificationAsRead={markNotificationAsRead} 
                    userIsAdmin={userIsAdmin}/>
                { settings.hasSetting("locale-menu") && <LocaleMenu /> }
                <Profile />
            </div>
        </div>
    )
}

export default NavigationBar;