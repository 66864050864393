type MutationDataTypes = "UUID!" | "String" | "[String]" | "JSON" | "[JSON]" | "Int" | "[Int]"

import { AnyObject } from "@/helpers/objectUtils";

interface IGraphQLFieldData {
    name: string
    type: MutationDataTypes
}

export interface IGraphQLFieldMap {
    [key: string]: IGraphQLFieldData
}

export interface IGraphQLQueryAndData {
    readonly query: string
    readonly data: { [key: string]: any }
}

export interface IGraphQLMutationInfo {
    /**
     * The query template
     */
    readonly template: string

    /**
     * Builds a full query using the template and the provided data, based on the private field map
     * @param data 
     */
    buildQuery(...params: { [key: string]: unknown }[]): IGraphQLQueryAndData | null
    /**
     * Returns the update field that matches the provided data field name
     * @param dataName 
     */
    getFieldData(dataName: string): IGraphQLFieldData | null
}

export class GraphQLMutationInfo implements IGraphQLMutationInfo {
    constructor(public readonly template: string,
        private fieldMap: IGraphQLFieldMap) {

    }

    public getFieldData(dataName: string): IGraphQLFieldData | null {
        return this.fieldMap[dataName] || null
    }

    public buildQuery(...params: { [key: string]: unknown }[]): IGraphQLQueryAndData | null {
        const headerData: AnyObject = {} // the data to place in the query header
        const queryParams: AnyObject = {} // the list of params passed to the mutation
        const queryData: AnyObject = {} // the actual query body
        for (const data of params) {
            this.addToQuery(data, {
                data: queryData, 
                params: queryParams, 
                header: headerData
            })
        }
        const finalParams = Object.entries(queryParams).map(([key, value]) => `${key}: ${value}`).join(",\n\t")
        const header = Object.entries(headerData).map(([key, value]) => `${key}: ${value}`).join(',')
        if (!header.length) {
            return null
        }
        return {
            query: this.template
                .replace("%PARAMS%", header)
                .replace("%DATA%", finalParams),
            data: queryData
        }
    }

    private addToQuery(data: AnyObject, queryFields: {
        header: AnyObject,
        params: AnyObject,
        data: AnyObject
    }): void {
        if (!data) {
            return
        }
        Object.entries(data).forEach(([key, value]) => {
            if (value === undefined) {
                return;
            }
            const field = this.getFieldData(key)
            if (field) {
                queryFields.params[key] = `$${field.name}`
                queryFields.header[`$${field.name}`] = field.type
                queryFields.data[field.name] = value
            }
        })
    }

}