
// Styles
import './style.css';

interface ICategory {
    title?: string;
    description?: string;
    content: JSX.Element;
    extraTitleContent?: JSX.Element;
}

const Category: React.FunctionComponent<ICategory> = (props):JSX.Element => {
    const { title, description, content, extraTitleContent } = props;
    return (
        <div className="report-category">
            <div className="report-category-title">
                <h3>{title}</h3>
                <p>
                    {description}
                </p>
                {extraTitleContent}
            </div>
            <div className="report-category-content">
                {content}
            </div>
        </div>
    )
}

export default Category;