import { useAuth } from "@/auth";
import { IUser } from "@/auth/interfaces";
import { createFavoriteCandidateMutation, deleteFavoriteCandidateMutation } from "@/graphql/mutations/favorites";
import { getFavByCandidateIdQuery, getFavByUserIdQuery } from "@/graphql/queries/favorites";
import { getUsers } from "@/graphql/queries/users";
import { graphQueries } from "@/helpers";
import { useState } from "react";

interface GetUsersResponse {
    getUsers: { users: IUser[], totalCount: number };
}

interface GetFavByUserIdResponse {
    getFavByUserId: {id: string}[]
}

interface GetFavByCandidateIdResponse {
    getFavByCandidateId: {id: string; firstName: string}[]
}

const useFavoriteCandidates = (): {
    companyUsers: {value: string, label: string}[];
    fetchCompanyUsers: () => Promise<void>;
    isFetchCompanyUsers: boolean;
    currentUser: {
        value: string,
        label: string
    };
    getFavoriteByUserId: (userId: string) => Promise<{ id: string; }[]>;
    getFavoriteByCandidateId: (candidateId: string) => Promise<{ id: string; firstName: string; }[]>;
    createFavoriteCandidate: (candidateId: string, userId: string, favoriteBy: string) => Promise<any>;
    deleteFavoriteCandidate: (candidateId: string, userId: string) => Promise<any>
} => {
    
    const { company, user } = useAuth();

    const [companyUsers, setCompanyUsers] = useState<{value: string, label: string}[]>([]);
    const [isFetchCompanyUsers, setIsFetchCompanyUsers] = useState<boolean>(false);
    

    const fetchCompanyUsers = async () => {
        if(!company?.id || !user?.id) {
            return
        }
        setIsFetchCompanyUsers(true);
        const response: GetUsersResponse = await graphQueries.sendRequest(getUsers(""), {
            "getUsersCompanyId": company.id,
            "getUsersSizeOfPage": 100000,
            "getUsersPage": 0
        });
        const { getUsers: getCompanyUsers } = response || {};
        const { users } = getCompanyUsers || {};

        setCompanyUsers(
            users.map(u => ({ 
                label: u.id === user.id ? `Me (${u.firstName})` : u.firstName,
                value: u.id 
            })) ?? []
        );
        setIsFetchCompanyUsers(false);
    }

    const getFavoriteByUserId= async (userId: string) => {
        const response: GetFavByUserIdResponse = await graphQueries.sendRequest(getFavByUserIdQuery, { userId });
        const { getFavByUserId } = response || {};
        return getFavByUserId ?? [];
    }

    const getFavoriteByCandidateId= async (candidateId: string) => {
        const response: GetFavByCandidateIdResponse = await graphQueries.sendRequest(getFavByCandidateIdQuery, { candidateId });
        const { getFavByCandidateId } = response || {};
        return getFavByCandidateId ?? [];
    }


    const createFavoriteCandidate = async (candidateId: string, userId: string, favoredBy: string) => {
        const response = await graphQueries.sendRequest(createFavoriteCandidateMutation, {candidateId, userId, favoredBy});

        return response;
    }

    const deleteFavoriteCandidate = async (candidateId: string, userId: string) => {
        const response = await graphQueries.sendRequest(deleteFavoriteCandidateMutation, {candidateId, userId});

        return response;
    }

    return {
        companyUsers,
        fetchCompanyUsers,
        isFetchCompanyUsers,
        currentUser: {
            value: user!.id,
            label: user!.firstName
        },
        getFavoriteByUserId,
        getFavoriteByCandidateId,
        createFavoriteCandidate,
        deleteFavoriteCandidate
    }
}

export { useFavoriteCandidates };