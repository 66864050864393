export const QuestionsAxis = {
  'Programming capabilities': 'programming_capabilities',
  'Analytical thinking': 'analytical_thinking',
  'Prioritizing Skills': 'prioritizing_skills',
  'Self learning': 'self_learning',
  'Learning motivation': 'learning_motivation',
  'Project management capabilities': 'project_management_capabilities',
  'Data-driven': 'data_driven',
  'Effective Communication': 'effective_communication',
  'Domain knowledge': 'domain_knowledge',
  'Product Management methodologies': 'product_management_methodologies',
  'Information technology': 'information_technology',
  'Problem solving': 'problem_solving',
  'Service oriented': 'service_oriented',
  'collaboration': 'collaboration',
  'Design fundamentals': 'design_fundamentals',
  'Design sense': 'design_sense',
  'Attention to detail': 'attention_to_detail',
  'Research': 'research',
  'Independence': 'independence',
  'methodologic': 'methodologic',
  'Test methodologies': 'test_methodologies',
  'Planning': 'planning',
  'organization and structure': 'organization_and_structure',
  'Relationship Management': 'relationship_management',
  'HR methodologies': 'hr_methodologies',
  'strategic thinking': 'strategic_thinking',
  'Talent Acquisition Management': 'talent_acquisition_management',
  'Campaign Management': 'campaign_management',
  'Proactiveness': 'proactiveness',
  'Persuasiveness': 'persuasiveness',
  'diplomacy': 'diplomacy',
  'Negotiation & conflict management': 'negotiation_conflict_management',
  'Writing capabilities': 'writing_capabilities',
  'Marketing Research': 'marketing_research',
  'Business Acumen': 'business_acumen',
  'Curiosity': 'curiosity',
  'Sales management': 'sales_management',
  'Self-management': 'self_management',
  'staying up-to-date': 'staying_up_to_date',
  'Self efficacy': 'self_efficacy',
  'Agreeableness': 'agreeableness',
  "Employees cultivation": "employees_cultivation",
  "Tech savvy": "tech_savvy",
  "Data analysis": "data_analysis",
  "Work Drive": "work_drive",
  "Emergent Leadership": "emergent_leadership",
  "Coping with Criticism": "coping_with_criticism",
  "Appropriate expectations": "appropriate_expectations",
  "Social involvement": "social_involvement"
}