
import './style.css';

type Size = 's' | 'r' | 'l' | 'xl'

interface ITitle extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
    size?: Size
}

    
const Title: React.FunctionComponent<ITitle> = (props): JSX.Element => {
    const { size = 'r' } = props;

    const headersClasses: { [key in Size]: string} = {
        'xl': "main-title-xl",
        'l': "main-title-l",
        'r': "main-title-r",
        's': "main-title-s"
    }

    return (
        <h1 
        {...props} 
        className={`main-title ${headersClasses[size]} ${props.className ?? ''}`}/>
    )
}

export default Title;