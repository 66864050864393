// Styles
import "./style.css";

import _ from "lodash";

// Interfaces
interface IHighlights {
  avgYearsPerEmployer: number;
  totalYearsExp: number;
  totalYearsManagementExp: number;
  candidateSummary: string;
  lastJobTitle: {
    title: string | null;
    content: any;
    current: boolean;
  };
  firstName: string;
}

// Static texts
const AVG_TEXT = "Avg. years per employer";
const TOTAL_EXP_TEXT = "Total years of experience";
const TOTAL_MNG_EXP_TEXT = "Total years of management experience";
const RECENT_POS_TEXT = "Recent position title";

const Highlights: React.FunctionComponent<IHighlights> = (
  props
): JSX.Element => {
  const {
    avgYearsPerEmployer,
    totalYearsExp,
    totalYearsManagementExp,
    lastJobTitle,
    candidateSummary,
    firstName
  } = props;

  function getCandidateSummary(candidateSummary: string, firstName: string) {
    if (candidateSummary && candidateSummary.includes("~")) {
      const newStr = candidateSummary.replace(/~+/g,'~');
      return newStr.replaceAll("~", _.capitalize(firstName || "the Talent"));
    }
    else if (candidateSummary && !candidateSummary.includes("~")) {
      return candidateSummary;
    }
    return "";
  }

  return (
    <div className="review-highlights-section">
      {candidateSummary ? (
        <p className="review-highlights-section-decription">
          {getCandidateSummary(candidateSummary, firstName)}
        </p>
      ) : null}
      <div className="review-highlights-section-insights">
        {avgYearsPerEmployer ? (
          <>
            <div className="review-highlights-section-insights-single">
              <h3>{avgYearsPerEmployer}</h3>
              <span>{AVG_TEXT}</span>
            </div>
            <div className="review-ihghlights-section-seperate-line"></div>
          </>
        ) : null}
        {totalYearsExp ? (
          <>
            <div className="review-highlights-section-insights-single">
              <h3>{totalYearsExp}</h3>
              <span>{TOTAL_EXP_TEXT}</span>
            </div>
            <div className="review-ihghlights-section-seperate-line"></div>
          </>
        ) : null}
        {totalYearsExp ? (
          <>
            {totalYearsManagementExp ? (
              <>
                <div className="review-highlights-section-insights-single">
                  <h3>{totalYearsManagementExp}</h3>
                  <span>{TOTAL_MNG_EXP_TEXT}</span>
                </div>{" "}
                <div className="review-ihghlights-section-seperate-line"></div>
              </>
            ) : null}
          </>
        ) : null}
        {lastJobTitle?.content &&  typeof(lastJobTitle?.content) == "string" ? (
          <div className="review-highlights-section-insights-single">
            <p>
              {RECENT_POS_TEXT}
            </p>
            <span>
              {/* <span style={{
                            fontStyle: "italic"
                        }}> */}
              {/* {recenetPositionTitle}</span> at {recentCompanyName} */}
              {lastJobTitle?.content}
            </span>
          </div>
        ) : null}
        {(lastJobTitle?.content?.text &&  typeof(lastJobTitle?.content?.text) == "string") ? 
        <div className="review-highlights-section-insights-single">
            <p>
              {RECENT_POS_TEXT}
            </p>
            <span>
              {/* <span style={{
                            fontStyle: "italic"
                        }}> */}
              {/* {recenetPositionTitle}</span> at {recentCompanyName} */}
              {lastJobTitle?.content?.text}
            </span>
          </div> : null}
      </div>
    </div>
  );
};

export default Highlights;
