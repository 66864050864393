import { useState } from "react";
import { IComponentStepperHookProps, IPage, IUseComponentStepper } from "./interfaces";

const useComponentStepper = ({
    initialPages, initialStepIndex, onStepChange, disableNextStep
}: IComponentStepperHookProps): IUseComponentStepper => {

    const [pages, setPages] = useState(initialPages);
    const [stepIndex, setStepIndex] = useState<number>(initialStepIndex);
    
    const pagesCount = Object.keys(pages).length-1;

    const getValidIndex = (index: number): number => {
        const isIndexTooLarge = index > pages.length-1;
        if(isIndexTooLarge) return pages.length-1;

        const isIndexTooSmall = index < 0;
        if(isIndexTooSmall) return 0

        return index
    }

    const handleStepChange = async (stepIndex: number) => {

        const validIndex = getValidIndex(stepIndex);

        const currentStep = pages[validIndex];
        const { handleStepLogic } = currentStep || {};

        if (handleStepLogic && !disableNextStep) { 
            handleStepLogic({ setStepIndex, stepIndex: validIndex, onStepChange });
            return;
        }

        if(validIndex) {
            await onStepChange?.(currentStep.id);
            if(!disableNextStep){
                setStepIndex(validIndex);
            }
        }
    }

    const getPagesFromStartToCurrentStep = (): IPage[] => {
        // const stepsNames = pages.map(p => p.stepName)
        return [...pages.slice(0, stepIndex+1)]
    }

    const componentProps = {
        currentStepIndex: stepIndex, 
        pagesCount, 
        pages,
        setPages, 
        setStep: handleStepChange 
    }

    const Component = pages[stepIndex]?.component(componentProps);


    return {
        currentStepIndex: stepIndex,
        currentStep: pages[stepIndex],
        pagesCount: pages.length,
        setPages,
        setStep: handleStepChange,
        getPagesFromStartToCurrentStep,
        Component,
        componentProps: componentProps
    }
}

export { useComponentStepper };