export const CircleVideo: React.FunctionComponent<{ videoSrc: string  } & React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>> = 
(props): JSX.Element => {
    const { videoSrc, className, onClick, ...rest } = props;
    
    return (
        <div 
        className="circle-video-container"
        onClick={(e: any) => onClick?.(e)}>
            <div className="circle-video-wrapper">
                <video 
                {...rest}
                src={videoSrc}
                className={"circle-video " + className ?? ""}>
                    <source src={videoSrc} type="video/webm"/>
                </video>
            </div>
        </div>
    )
}