import AutosizeInput from "react-input-autosize";
import ImageUpload from "../UnboxableForm/ui/components/ImageUpload";
import AutoResizeTextArea from "react-textarea-autosize"

import WizardLoading from "../WizardForm/components/Loading";
import { useContext } from "react";
import AppContext from "@/context/AppContext";

interface IUserProfileForm {
    handleChange: (e: any) => any
    handleSubmit: () => any
    showComProfile: boolean
    comProfileClicked: () => any
    username: string
    form: any
    loading: boolean
    src: any
    onUploadImage: (files: any) => void
    onImageClear: () => void
}


const UserProfileForm: React.FunctionComponent<IUserProfileForm> = (props) => {
    const {handleChange, handleSubmit, showComProfile, comProfileClicked, username, form, loading, src, onUploadImage, onImageClear} = props;
    const { tr } = useContext(AppContext);

    return(
        <div className="flex flex-col justify-center items-center wiz-page undefined wiz-valid-page">
        <div className="w-full flex flex-col justify-center items-center pr-12 overflow-visible">
          <div className="w-full flex-col justify-center items-center">
            <div className="flex justify-between mb-5">
              <div className="wiz-text-lg">
                {`${tr("Meet")} ${username}` }
              </div>
              { showComProfile ? 
                <div className="flex items-center">
                  <span 
                    onClick={comProfileClicked} 
                    style={{
                      cursor: "pointer",
                      textDecoration: 'underline',
                      color: "#F3AD81"
                      }}>
                    {tr("My communication style")}
                  </span>
                </div> : null}

            </div>
                <AutosizeInput
                // className={cx("placeholder-text-gray-400 wiz-edit-text pb-1 mt-2")}
                inputStyle={{
                  outline: 'none',
                  fontWeight: 300
                }}
                placeholder={tr("Your position")}
                value={form.jobTitle ? form.jobTitle : ""}
                onChange={(e) => handleChange(e)}
                disabled={false}
                name={"jobTitle"}
                />
                <hr className="border-gray-300 my-5"></hr>
                <AutosizeInput
                // className={cx("placeholder-text-gray-400 wiz-edit-text pb-1 mt-2")}
                inputStyle={{
                  outline: 'none',
                  fontWeight: 300
                }}
                defaultValue={form.email}
                disabled={true}
                />
                <hr className="border-gray-300 my-5"></hr>
                <AutoResizeTextArea
                className="w-full bg-transparent font-light block focus:outline-none resize-none"
                rows={3}
                maxRows={10}
                onChange={(e) => handleChange(e)}
                placeholder={`${tr("Please add your bio here")}...`}
                value={form.description || ""}
                name={"description"}
                />
                <hr className="border-gray-300 my-5"></hr>
                <ImageUpload 
                multiple={false}
                label={tr("Upload picture")}
                src={src}
                clear={() => onImageClear()}
                onChange={ async event => {
                    const files = event.target.files;
                    if(files) {
                      try {
                        await onUploadImage(files)
                      } catch(e) {
                        return 'fail to upload';
                      }
                    }
                    return '';
                  }
                }
                />
              <div className="mt-5" style={{display: 'flex'}}>
                <button 
                className="wiz-finish-btn px-4"
                onClick={handleSubmit}
                > {tr("Save")}</button>
                {loading && <WizardLoading className="ml-4" />}
              </div>
          </div>
        </div>
      </div>
    )
}

export default UserProfileForm;