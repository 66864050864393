import React, { useEffect } from 'react';
import { UnboxableButton } from '@/components';
import { IUnboxableButton } from '@/components/UnboxableButton';
import useChatRooms from './useChatRoom';
import { useAuth } from '@/auth';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import './style.css';
import { HtmlTooltip } from '@/components/WizardComponents/CustomTooltip/CustomTooltip';

interface IChatRooms {
    buttonProps?: IUnboxableButton;
    roomId: string;
    tooltipText?: string;
    onChatAvailable?: (iframeId: string, chatUrl: string) => any
}

const ChatRooms: React.FunctionComponent<IChatRooms> = ({
    children, buttonProps, roomId, tooltipText, onChatAvailable
}) => {
    const { user } = useAuth();

    const { iframeId, currentChatUrl, isChatAvailable } = useChatRooms({ user: user!, initialChatRoom: roomId })

    useEffect(() => {
        if(isChatAvailable && currentChatUrl) {
            onChatAvailable?.(iframeId, currentChatUrl)
        }
    }, [isChatAvailable, currentChatUrl])

    return (
        <>
        {tooltipText ?
        <HtmlTooltip
        title={<div>{tooltipText}</div>}>
            <div>
                <UnboxableButton
                className='chat-button'
                disabled={!isChatAvailable}
                {...buttonProps}>
                    {children ? children :
                    <MessageOutlinedIcon/>}
                </UnboxableButton>
            </div>
        </HtmlTooltip> 
        :
        <UnboxableButton
        className='chat-button'
        disabled={!isChatAvailable}
        {...buttonProps}>
            {children ? children :
            <MessageOutlinedIcon/>}
        </UnboxableButton>}
        {!isChatAvailable &&
        <iframe
        style={{ opacity: 0, zIndex: -1, display: "none" }}
        src={currentChatUrl.length > 0 ? currentChatUrl : ""}
        id={iframeId}
        title={"chat"}></iframe>}
        </>
    )
} 

export default ChatRooms;