import { IUser } from "@/auth/interfaces";
import { PositionClient } from "@/dal/position-client";
import { PositionTemplates } from "@/dal/position.interface";
import { submitPosition } from "@/graphql/mutations/createPosition";
import { hrmInPositions as hrmInPositionsQuery } from "@/graphql/queries/hrmInPositions";
import { graphQueries } from "@/helpers";
import { IPosition, SkillNecessityLevel } from "@/interfaces/pages/positions";
import { isEmpty } from "lodash";
import { prepareForSave } from "../helpers";

export const getMatchingWizardInitialFields = (position?: Partial<IPosition>): Partial<IPosition> => {
    const { 
        matchingTitle,
        matchingDescription,
        isManager,
        matchingYearsOfExperience,
        matchingYearsOfManagementExperience,
        matchingDepartment,
        matchingLocation,
        matchingJobTypes,
        matchingSkills,
        atsEmail,
        matchingOtherTitles, 
        matchingEducation,
        matchingLanguages,
        matchingVolunteering, 
        matchingEligibilities,
        matchingHobbies,
        matchingRecipients,
        matchingHrms,
        matchingPermissions,
        matchingBuildingBlocks,
        matchingCvKeywords } = position || {};
    
    let matchingEducationFilterd = matchingEducation;
    if(!isEmpty(matchingEducationFilterd)) {
        matchingEducationFilterd = matchingEducationFilterd?.filter(education => !isEmpty(education)) ?? [];
    }
    let matchingLanguagesFilterd = matchingLanguages;
    if(!isEmpty(matchingLanguagesFilterd)) {
        matchingLanguagesFilterd = matchingLanguagesFilterd?.filter(language => !isEmpty(language)) ?? [];
    }
    
    return {
        ...position,
        matchingTitle: !isEmpty(matchingTitle) ? matchingTitle : "",
        matchingDescription: !isEmpty(matchingDescription) ? matchingDescription : "", 
        matchingOtherTitles: !isEmpty(matchingOtherTitles) ? matchingOtherTitles : [],
        isManager: isManager === (true || false) ? isManager : false,
        matchingYearsOfExperience: matchingYearsOfExperience ?? 0,
        matchingYearsOfManagementExperience: matchingYearsOfManagementExperience ?? 0,
        matchingDepartment: !isEmpty(matchingDepartment) ? matchingDepartment : "",
        matchingLocation: !isEmpty(matchingLocation) ? matchingLocation : "",
        matchingJobTypes: !isEmpty(matchingJobTypes) ? matchingJobTypes : [],
        matchingSkills: !isEmpty(matchingSkills) ? matchingSkills : [],
        atsEmail: !isEmpty(atsEmail) ? atsEmail : "",
        matchingEducation: matchingEducationFilterd,
        matchingLanguages: matchingLanguagesFilterd,
        matchingVolunteering: !isEmpty(matchingVolunteering) ? matchingVolunteering : [],
        matchingEligibilities: !isEmpty(matchingEligibilities) ? matchingEligibilities : [],
        matchingHobbies: !isEmpty(matchingHobbies) ? matchingHobbies : [],
        matchingRecipients: !isEmpty(matchingRecipients) ? matchingRecipients : [],
        matchingHrms: !isEmpty(matchingHrms) ? matchingHrms : {},
        matchingPermissions: !isEmpty(matchingPermissions) ? matchingPermissions : [],
        matchingBuildingBlocks: !isEmpty(matchingBuildingBlocks) ? matchingBuildingBlocks : [],
        matchingCvKeywords: !isEmpty(matchingCvKeywords) ? matchingCvKeywords : [],
    }
}

export const submitPositionToElastic = async (positionId: string): Promise<{} | any> => {
    const response = await graphQueries.sendRequest(submitPosition, {v2PositionId: positionId});
    
    return response
    
}

export const createNewPosition = async (position: Partial<IPosition>, companyId?: string, user?: IUser | null): Promise<Partial<IPosition> | undefined> => {
    const { id: userId, email: userEmail } = user || {};

    if(!companyId || !userId || !userEmail || !user) 
        return
    
    const newposition = {
        ...position,
        companyId,
        userId,
        status: "Draft"
    } as any;

    const validData = prepareForSave(newposition);

    const creation = await PositionClient.create(validData as any);

    const { id } = creation || {};
    if(!id)
        return
    
    return {
        ...position,
        ...creation
    };
}

export const getPosition = async (position_id?: string | null): Promise<Partial<IPosition> | null> => {
    const position = await PositionClient.read({positionId: position_id!}) as Partial<IPosition>
    if(!position)
        return null

    return position;
};

export const thereIsNoDiscoveryFields = (position: Partial<IPosition>): boolean => {
    const { 
        yearsOfExperience,
        yearsOfManagementExperience, 
        description,
        location,
        skills,
        qualifications,
        eligibilities,
        rolesAndResponsibilities,
        dayInALife,
        leaders,
        templates
    } = position;
    
    return !(yearsOfExperience || yearsOfManagementExperience || description || location || skills || qualifications || eligibilities || rolesAndResponsibilities || dayInALife || leaders || templates)
}

export const getDiscoveryFieldsFromMatchingFields = (position: Partial<IPosition>): Partial<IPosition> => {
    const { 
        matchingDescription,
        matchingYearsOfExperience,
        matchingYearsOfManagementExperience,
        matchingLocation,
        matchingSkills,
        matchingJobTypes,
        matchingEligibilities,
        matchingHobbies } = position || {};
    
    return {
        yearsOfExperience: matchingYearsOfExperience,
        yearsOfManagementExperience: matchingYearsOfManagementExperience,
        description: matchingDescription,
        location: matchingLocation,
        jobTypes: matchingJobTypes?.[0],
        skills: matchingSkills?.map(skill => ({ ...skill, necessityLevel: SkillNecessityLevel.MUST })),
        eligibilities: matchingEligibilities,
        rolesAndResponsibilities: matchingHobbies,
        templates: PositionTemplates.Classic,
    }
}

export const getQuizBuildingBlocks = async (userId: string, position_id: string): Promise<string[]> => {
    const userHrmInPositions: any = await graphQueries.sendRequest(hrmInPositionsQuery, {
        userId: userId
    });
    const { hrmInPositions } = userHrmInPositions || {};
    const { oneTimeCleanBlocks, normalBlocks } = hrmInPositions || {};

    const positionBlocks = normalBlocks[position_id] ?? [];

    return [ ...(positionBlocks ?? []), ...(oneTimeCleanBlocks ?? []) ]
}