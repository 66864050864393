// Components
import Button from '@/components/Button';

// Levels
import { levels, levelEnum, levelIcon } from './levelObjects';

// Styles
import './style.css';

const SkillsTitleContent: React.FunctionComponent = ():JSX.Element => {
    return(
        <div >
            <div className="review-skills-roadmap-content">
                {levels.map((l, i)=> (
                    <div className={'review-skills-roadmap-single'} key={i+"cont"}> 
                        <img src={levelIcon[l]} />
                        <span>{levelEnum[l]}</span>
                    </div>
                ))}
            </div>
            <Button 
            text={'READ ANSWER'} 
            className="review-skills-roadmap-button no-print" 
            type={"regular"}   
            onClick={e => {
                const answerQuestionSection = document.getElementById("answer-question-section");
                e.preventDefault();  // Stop Page Reloading
                answerQuestionSection && answerQuestionSection.scrollIntoView({ behavior: 'smooth' });
            }}/>

        </div>
    )
} 

export default SkillsTitleContent;