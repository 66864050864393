import { gql } from "graphql-request"

// export const uploadFileToS3 = gql`
// mutation UploadFile($fileData: String!, $fileName: String!) {
//   uploadFile(fileData: $fileData, fileName: $fileName)
// }
// `

export const uploadFileToS3 = gql`
mutation GenericUploadFile($fileData: String!, $fileName: String!) {
  genericUploadFile(fileData: $fileData, fileName: $fileName)
}
`