// React
import { createContext } from "react"

// Intefaces
import { IUser } from "@interfaces/pages/permissions"

// Local Interface
export interface IPermissionsPageContext {
  setShowModal: React.Dispatch<boolean>
  setUserToDelete: React.Dispatch<IUser>
  showModal: boolean
}

// Default values
const defaultContext: IPermissionsPageContext = {
  setShowModal: () => null,
  setUserToDelete: () => null,
  showModal: false
}

// Context
const PermissionsPage = createContext<IPermissionsPageContext>(defaultContext)

export default PermissionsPage
