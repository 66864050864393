import { useAuth } from "@/auth";
import WizardLoading from "@/components/WizardComponents/WizardLoading/WizardLoading";
import { useParams } from "react-router";
import ReviewProcess from "./V1";
import CandidateReport from "./V2";
import { useCandidateReport } from "./V2/hooks/useCandidateReport";

interface IReviewProcessURLParams {
    positionId: string;
    candidateId: string;
}

const CandidateReportNavigator: React.FunctionComponent = (): JSX.Element => {
    const { candidateId, positionId }: IReviewProcessURLParams = useParams();
    const { company } = useAuth();

    const {
        loading,
        candidateData,
        positionData,
        comProfileForecast,
        cvData,
        videoData,
        reccomendations,
        isOldReport,
        questionsAnswers,
        skippedQuestions,
        isUserRankAnswer,
        setIsUserRankAnswer,
        getAndSetCandidateData,
        rankAnswer,
        setCandidateStatus,
    } = useCandidateReport({ 
        candidateId: candidateId, 
        positionId: positionId ,
        companyId: company?.id ?? ""
    });

    return !loading ? (
        isOldReport ? 
        <ReviewProcess/> 

        : <CandidateReport
            loading={loading}
            candidateData={candidateData}
            reccomendations={reccomendations}
            positionData={positionData}
            comProfileForecast={comProfileForecast}
            cvData={cvData}
            videoData={videoData}
            isOldReport={isOldReport}
            questionsAnswers={questionsAnswers}
            skippedQuestions={skippedQuestions}
            positionId={positionId}
            isUserRankAnswer={isUserRankAnswer}
            setIsUserRankAnswer={setIsUserRankAnswer}
            rankAnswer={rankAnswer}
            getAndSetCandidateData={getAndSetCandidateData}
            setCandidateStatus={setCandidateStatus}
            />

    ) : <WizardLoading/>
}

export default CandidateReportNavigator;