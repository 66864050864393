
// Icons
import { ComProfile } from '../comProifleHelpers';
import arrowIcon from '../../icons/arrow.svg';

// Styles
import './style.css';
import ComProfileMain from '../../components/ComProfileMain/ComProfileMain';

// Interfaces
interface ICommunicationStyleDescription {
    qualities: any;
    values: any;
    needs: any;
    strengths: any;
    comProfile: ComProfile;
}

const CommunicationStyleDescription: React.FunctionComponent<ICommunicationStyleDescription> = (props): JSX.Element => {
    const { qualities, values, needs, strengths, comProfile } = props;

    return (
    <div className="talent-report-communication-style-workstyle-description">
    <div className="talent-report-communication-style-workstyle-description-singles-block">
        <div className="talent-report-communication-style-workstyle-description-single">
            <img src={arrowIcon}/>
            <div className="">
                <p>It means that he/she can become <span>a good</span></p>
                <div className="talent-report-communication-style-workstyle-description-single-line">
                    {Object.keys(qualities).map((key, index) => (
                        <div key={key+index}>
                            {qualities[key]}
                        </div>
                    ))} for your team.
                </div>
            </div>
        </div>
        <div className="talent-report-communication-style-workstyle-description-single">
            <img src={arrowIcon}/>
            <div>
                <p>This Talent <span>brings values</span> like</p>
                <div className="talent-report-communication-style-workstyle-description-single-line">
                    {Object.keys(values).map((key, index) => (
                        <div key={key+index}>
                         {values[key]}
                        </div>
                    ))} for your team.
                </div>
            </div>
        </div>
        <div className="talent-report-communication-style-workstyle-description-single">
            <img src={arrowIcon}/>
            <div>
                <p>while <span>seeking for</span></p>
                <div className="talent-report-communication-style-workstyle-description-single-line">
                    {Object.keys(needs).map((key, index) => (
                        <div key={key+index}>
                         {needs[key]}
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className="talent-report-communication-style-workstyle-description-single">
            <img src={arrowIcon}/>
            <div>
                <p>in order to show the following work <span>strengths</span></p>
                <div className="talent-report-communication-style-workstyle-description-single-line">
                    {Object.keys(strengths).map((key, index) => (
                        <div key={key+index}>
                         {strengths[key]}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
    <div className="talent-report-communication-style-workstyle-description-main">
        <ComProfileMain talentComProfile={comProfile}/>
    </div>
</div>
)}

export default CommunicationStyleDescription;