
import React, { useCallback } from "react"
import { toast } from "react-toastify"
import { IAssessment, ISkillEval } from "../../data/types"
import EditorSurveyCreator from "../EditorSurveyCreator"
import { EvalTypes } from "../../data/editor.utils"
import { CheckListDropDown } from "@/components/CheckListDropDown"
import { EditorApi } from "../../data/editor.api"
import { isEmpty } from "lodash"
import { IPosition } from "@/interfaces/pages/positions"
import useLocalToast from "../../../../hooks/useLocalizedToast";

const SkillEvalEditor: React.FunctionComponent<{
    evaluator: ISkillEval
    position: IPosition | null,
    onChange: (neweval: ISkillEval, changeState?: boolean) => Promise<boolean>,
    selectedBuildingBlock: IAssessment | null
}> = ({ evaluator, onChange, position, selectedBuildingBlock }) => {

    const { toast: localToast } = useLocalToast();

    const hasContent = useCallback((e: ISkillEval) => {
        const { pages } = e?.content || {};
        if(!isEmpty(pages)) {
            return pages.some((p: any) => Object.keys(p?.elements || {}).length > 0);
        }
        return false;
    }, []);


    const onSave = useCallback(async (survey: any) => {
        evaluator.content = survey
        const result = await onChange(evaluator, false)
        return result
    }, [evaluator]);

    const saveEvalAsTemplate = useCallback(async (e: ISkillEval) => {
        if(!hasContent(e)) {
            return localToast('No questions! cannot create template...', { containerId: "default" });
        }
        let msg = "Failed to update skill evaluator"
        const t = localToast("Updating...", { containerId: "default" })
        try {
            const [ updated ] = await EditorApi.postSkillEvals(e)
            if(updated) {
               msg = "Skill evluator updated!"
            }
        } catch(e: any) {
            console.error(e.message)
        }
        toast.dismiss(t)
        localToast(msg, { containerId: "default" })
    }, []);

    const UI = <div className="rounded-b bg-gray-300 p-4">
        {
            <div className={'flex flex-row items-center pb-4'}>
                <div className='mx-6 text-gray-500'>
                    {selectedBuildingBlock && 
                    `${selectedBuildingBlock?.metadata?.buildingblock?.name} for ${position?.title}`}
                </div>
                {evaluator.eval_type !== EvalTypes.ELIGIBILITY ? <div className="flex items-center">
                {  evaluator.level && <span>
                    Evalutates {evaluator.level} in { evaluator.skill } 
                   </span>}
                </div> : 
                <CheckListDropDown
                    noItemsMessage="No Eligibilities"
                    selected={0}
                    list={position?.eligibilities || []}/>
                }
                <div className="flex-grow text-right">
                    <button 
                        onClick={() => saveEvalAsTemplate(evaluator)}
                        className="mx-2 my-2 bg-white transition duration-150 ease-in-out focus:outline-none hover:bg-gray-100 rounded px-6 py-2 text-sm">
                        Save  <strong>{evaluator?.title}</strong> as template
                    </button>
                </div>
            </div>
        }
        <div>
            <EditorSurveyCreator
                onSave={onSave}
                survey={evaluator.content || {}} />
        </div>
    </div>
    return evaluator ? UI : <></>
}

export default React.memo(SkillEvalEditor)