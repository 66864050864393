/**
 *
 *  Button Component
 *
 *  @module components/Button
 *
 *  @author diegoulloao
 *
 */

// Utils
import cx from "classnames"
import LText from "../LText"

// Local Interfaces
interface ButtonProps {
  text: string
  icon?: JSX.Element
  disabled?: boolean
  size?: "lg" | "md" | "sm"
  iconPos?: "left" | "right"
  className?: string | object | []
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?:
    "primary"
    | "black"
    | "regular"
    | "light"
    | "bordered"
    | "wizard-black"
    | "wizard-finish"
    | "default"
}

/**
 *
 *  Button
 *  @description button component with multiple variants
 *
 */
const Button: React.FunctionComponent<ButtonProps> =
  ({ text, type, size, onClick, icon:Icon, iconPos="left", disabled=false, className }): JSX.Element => {
    // Type & Size for classes
    let typeClasses, sizeClasses

    // Type classes map
    switch (type) {
      case "primary":
        typeClasses = [
          "bg-indigo-600 hover:bg-indigo-500",
          "text-white"
        ]

        break

      case "bordered":
        typeClasses = [
          "bg-white hover:bg-gray-100",
          "border border-indigo-600 hover:border-indigo-700",
          "text-indigo-600 hover:text-indigo-700"
        ]

        break

      case "black":
        typeClasses = [
          "bg-white hover:bg-gray-100",
          "border border-gray-700 hover:border-gray-800",
          "text-gray-700 hover:text-gray-800"
        ]

        break

      case "light":
        typeClasses = [
          "bg-white hover:bg-gray-100",
          "border border-gray-300",
          "text-gray-500"
        ]

        break

      case "regular":
        typeClasses = [
          "bg-black font-medium text-white"
        ]

        break

      case "wizard-black":
        typeClasses = [
          "bg-black text-white text-lg"
        ]

        break

      case "wizard-finish":
        typeClasses = [
          "bg-green text-white font-bold"
        ]

        break


      default:
        typeClasses = [
          "bg-gray-200 hover:bg-gray-300",
          "text-indigo-600"
        ]
    }

    // Size classes map
    switch (size) {
      case "lg":
        sizeClasses = ["text-md", "px-8 py-3", "leading-6"]
        break

      case "md":
        sizeClasses = ["text-sm", "px-8 py-2", "leading-6"]
        break

      case "sm":
        sizeClasses = ["text-xs", "px-6 py-2"]
        break

      default:
        sizeClasses = ["text-sm", "px-8 py-3"]
    }

    return (
      <button
        className={cx(
          "transition duration-150 ease-in-out rounded",
          typeClasses,
          sizeClasses,
          className
        )}

        onClick={onClick || (() => null)}
        disabled={disabled}
      >
        {iconPos === "left" && Icon }
        <LText text={text} />
        {iconPos === "right" && Icon}
      </button>
    )
  }

export default Button
