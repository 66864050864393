import moment from 'moment';

import { getJobSimPositionNewUrl, graphQueries, isUnboxableUser } from "@/helpers";

import { INotification } from "../types";

import './style.css';
import { AssessmentStatus } from '@/pages/AssessmentEditor/data/editor.utils';


const NotificationTitles: React.FunctionComponent<Partial<INotification>> = ({ eventName, positionName, companyName, isUnread }): JSX.Element => (
    <div className="notification-center-single-title">
        <span className="notification-center-single-title-event">{eventName}</span>

        {eventName === EventNames.NEW_COMPANY ? 
        <span className="notification-center-single-title-value">{`: ${companyName}`}</span> : null}

        {eventName !== EventNames.JOB_SIM_CHANGED ? 
        <span className="notification-center-single-title-value">{`: ${positionName}`}</span> : null}
        {isUnread ? <div className="unread-notification-circle"></div> : false}
    </div>
) 

const AssessmentStatusLabel: {[key: string]: string} = {
    "": "no  assessment",
    [AssessmentStatus.DRAFT]: "Draft",
    [AssessmentStatus.PENDING_REVIEW]: "Pending review",
    [AssessmentStatus.READY]: "Ready"
};

const NotificationTags: React.FunctionComponent<Partial<INotification>> = ({ eventName, createdBy, positionName, changedStatus, companyName }): JSX.Element => (
    <div className="notification-center-single-tags">
        {eventName !== EventNames.JOB_SIM_CHANGED ? 
        <div className="notification-center-single-tag">
            <span>{"Created by: "}</span>
            {
            eventName !== EventNames.NEW_COMPANY && companyName ?
                <span>{createdBy} at {companyName}</span>

                :<span>{createdBy}</span>
            }
        </div> 
        : null }
        {eventName === EventNames.JOB_SIM_CHANGED && positionName ? 
        <div className="notification-center-single-tag">
            <span>{"Position: "}</span>
            <span>{positionName}</span>
        </div> : null}
        {eventName === EventNames.JOB_SIM_CHANGED && changedStatus ? 
        <div className="notification-center-single-tag">
            <span>{"Status: "}</span>
            <span>{AssessmentStatusLabel[changedStatus]}</span>
        </div> : null}
    </div>
)

const NotificationActions: React.FunctionComponent<{
    eventName: string, 
    eventToActions: { [key: string]: { text: string, action: () => void, showAction: boolean }[] }, 
    markNotificationAsRead: (id: string) => void, 
    id: string }> = 
    ({
        eventName, 
        eventToActions, 
        markNotificationAsRead, 
        id
    }): JSX.Element => (

    <div className="notification-center-single-buttons">
        { eventToActions[eventName]?.map((a, i: number) => (
            a.showAction &&
            <button 
            key={`${a.text}-${i}`} 
            onClick={() => {
                a.action()
                markNotificationAsRead(id)
            }}>
                {a.text}
            </button>
        ))}
    </div>
)

const NotificationDate: React.FunctionComponent<Partial<INotification>> = ({createdAt}): JSX.Element => {
    const calendarStrings = {
        lastDay : '[Yesterday]',
        sameDay : 'h:mm',
        lastWeek : 'MMM d[,] YYYY',
        sameElse : 'MMM d[,] YYYY'
    };  
    return <span>{moment(createdAt).calendar(null, calendarStrings)}</span> 
}
interface INotificationComponent {
    notification: INotification;
    showSeperateLine: boolean;
    userEmail: string;
    userIsAdmin: boolean;
    setCompany: (val: any) => any;
    userId: string;
    history: any;
    jobSimOrigin: string;
    markNotificationAsRead: (id: string) => void;
    isUnreadNotification: boolean;
 }

enum EventNames {
    NEW_COMPANY = "New Company",
    NEW_POSITION = "New Position",
    JOB_SIM_CHANGED = "Job simulation changed"
}

const Notification: React.FunctionComponent<INotificationComponent> = ({ 
        notification,
        showSeperateLine,
        userEmail,
        userIsAdmin,
        userId,
        setCompany,
        history, 
        jobSimOrigin,
        markNotificationAsRead,
        isUnreadNotification
    }): JSX.Element => {
    
    const { 
        id, 
        CompanyId, 
        V2PositionId, 
        eventName,
        createdBy, 
        changedStatus, 
        companyName,
        companySlug,
        positionSlug,
        positionTemplates,
        positionName, 
        positionSkills,
        createdAt
    } = notification;

    const navigateToDiscoveryPage = (companySlug: string, positionSlug: string, positionTemplates: string, userEmail: string) => {
        const a = document.createElement('a')
        a.target = "_blank"
        a.href = getJobSimPositionNewUrl({
            url: jobSimOrigin,
            companySlug,
            positionSlug,
            templatesNum: positionTemplates,
            isUnboxableUser: isUnboxableUser(userEmail)
        })
        a.click()
    }

    const navigateToAllCompanyPositions = async (userId: string, companyId: string, toEditCompany: boolean) => {
        const res = await graphQueries.changeUserCompany(userId, companyId)
        if (res.error) {
            console.error(res.error);
        }
        else {
            setCompany(() => ({
                            id: CompanyId
                        }));

            toEditCompany ? 
            history.push('/company/create#company-story') : history.push('/positions') 
        }
    }

    const eventToActions: any = {
        [EventNames.NEW_POSITION]: [
            {
                text: "Edit position", 
                action: () => history.push(`/positions/editor/edit/${V2PositionId}`),
                showAction: userIsAdmin
            },
            {
                text: "Launch discovery", 
                action: () => navigateToDiscoveryPage(companySlug, positionSlug, positionTemplates, userEmail),
                showAction: true
            }
        ],
        [EventNames.NEW_COMPANY]: [
            {
                text: "Edit company", 
                action: () => navigateToAllCompanyPositions(userId, CompanyId, true),
                showAction: userIsAdmin
            },
            {
                text: "Show all positions", 
                action: () => navigateToAllCompanyPositions(userId, CompanyId, false),
                showAction: true
            }
        ],
        [EventNames.JOB_SIM_CHANGED]: [
            {
                text: "Edit assessment", 
                action: () => history.push(`/editor/assessment?position_id=${V2PositionId}`),
                showAction: userIsAdmin
            },
            {
                text: "Launch discovery", 
                action: () => navigateToDiscoveryPage(companySlug, positionSlug, positionTemplates, userEmail),
                showAction: true
            }
        ]
    }

    return (
    <>
    <div className="notification-center-single">
        <div className="notification-center-single-content">
            <NotificationTitles 
            eventName={eventName} 
            positionName={positionName} 
            companyName={companyName}
            isUnread={isUnreadNotification}/>
            <NotificationTags
            eventName={eventName}
            companyName={companyName}
            createdBy={createdBy}
            positionName={positionName}
            changedStatus={changedStatus}
            positionSkills={positionSkills}/>
            <NotificationActions
            id={id}
            eventName={eventName}
            eventToActions={eventToActions}
            markNotificationAsRead={markNotificationAsRead}/>
        </div>
        <div className="notification-center-single-date">
            <NotificationDate
            createdAt={createdAt}/>
        </div>
    </div>
    {showSeperateLine ? 
    <div className="notification-center-single-seperate-line"></div> 
    : null}
    </>
    )
}

export default Notification;