import * as React from 'react';
import { useState, useRef } from 'react';
import ReactCrop, {
  Crop,
  //   PixelCrop,
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';
// import 'react-image-crop/src/ReactCrop.scss';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, DialogActions } from '@mui/material';
import Button from '@mui/material/Button';

/*
    use those values for the crop size (got it from Noa):
    For sticky-first-question panel:
    max-width: 170px;
    max-height: 126px;
    For regular images:
    max-width: 600px;
    max-height: 600px;
  */

export interface ImageCropperProps {
  open: boolean;
  imageLink: string;
  onImageCropperClose: (crop: any) => void;
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 40,
        height: 40,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

export default function ImageCropper(props: ImageCropperProps): any {
  const { open, imageLink, onImageCropperClose } = props;
  const handleCropDialogClose = () => {
    // console.log('crop image dialog close event:', value);
    // console.log('cropped Image: ', crop);
    onImageCropperClose(null);
  };
  const [crop, setCrop] = useState<Crop>();
  //   const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [aspect, setAspect] = useState<number | undefined>(1);
  const imgRef = useRef<HTMLImageElement>(null);
  function onCropDialogOKClick() {
    // console.log('OK crop dialog button clicked');
    // console.log('crop: ', crop);
    // console.log('completedCrop: ', completedCrop);
    const base64Crop = getBase64FromCrop();
    onImageCropperClose(base64Crop);
  }
  function onCropDialogCancelClick() {
    // console.log('Cancel crop dialog button clicked');
    onImageCropperClose(null);
  }

  function onCropDialogSquareClick() {
    // console.log('Square crop dialog button clicked');
    if (imgRef.current) {
      const { width, height } = imgRef.current;
      const chosenAspect = 1;
      setAspect(chosenAspect);
      setCrop(centerAspectCrop(width, height, chosenAspect));
    }
  }
  function onCropDialogRectangleClick() {
    // console.log('Rectangle crop dialog button clicked');
    if (imgRef.current) {
      const { width, height } = imgRef.current;
      const chosenAspect = 170 / 126;
      setAspect(chosenAspect);
      setCrop(centerAspectCrop(width, height, chosenAspect));
    }
  }
  function onCropDialogFreeFormClick() {
    // console.log('Free form crop dialog button clicked');
    setAspect(undefined);
  }
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  function getBase64FromCrop() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!crop) {
      console.error('crop is undefined, cannot create base64');
      return null;
    }
    if (!ctx) {
      console.error('Could not create context from Canvas');
      return null;
    }
    if (!imgRef.current) {
      console.error('No imgRef Image Reference, aborting convert to base 64');
      return null;
    }
    // const pixelRatio = window.devicePixelRatio;
    // reference: https://codesandbox.io/s/72py4jlll6?file=/src/index.js:1523-1548
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
    // const base64Canvas = canvas.toDataURL("image/jpeg").split(';base64,')[1];
    const base64Canvas = canvas.toDataURL('image/jpeg');
    return base64Canvas;
  }
  return (
    <Dialog
      onClose={handleCropDialogClose}
      //   onClose={onClose}
      open={open}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle style={{ textAlign: 'center' }}>Image Crop</DialogTitle>
      <div style={{ fontSize: '0.8em', textAlign: 'center' }}>
        cropping image "{imageLink}" <br />
      </div>
      <DialogActions style={{ textAlign: 'center' }}>
        <div style={{ flex: 1 }}>
          {/* <div style={{ textAlign: 'center' }}> */}
          Crop Shape: &nbsp;
          <Button variant="outlined" onClick={onCropDialogSquareClick}>
            Square (1X1)
          </Button>
          <Button
            variant="outlined"
            onClick={onCropDialogRectangleClick}
            autoFocus
          >
            Rectangle (170X126 PX)
          </Button>
          <Button
            variant="outlined"
            onClick={onCropDialogFreeFormClick}
            autoFocus
          >
            Free Form
          </Button>
        </div>
      </DialogActions>
      <DialogContent style={{ textAlign: 'center' }}>
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          //   onChange={(_, percentCrop) => setCrop(percentCrop)}
          //   onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            src={imageLink}
            ref={imgRef}
            crossOrigin="anonymous"
            onLoad={onImageLoad}
            style={{ width: '100%' }}
          />
        </ReactCrop>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCropDialogCancelClick}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={onCropDialogOKClick}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
