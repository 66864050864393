/**
 *
 * @module src/components/LNRDLoading
 *
 * @description LNRD loading state screen
 *
 * @author diegoulloao
 *
 */

// Assets
import lnrdLoadingGif from "@assets/animations/lnrd-loading.gif"

/**
 *
 *  LNRD Loading
 *  @description loading state screen for LNRD
 *
 */
const LNRDLoading: React.FunctionComponent<{ className?: string }> = ({ className }): JSX.Element => {
  return (
    <div className={`lnrd-loading flex flex-col justify-center items-center w-full h-full ${className ?? ""}`}>
      <img src={lnrdLoadingGif} alt="loading" className="max-w-md" />
    </div>
  )
}

export default LNRDLoading
