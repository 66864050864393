// React
import { useState, useEffect } from "react";

// Hooks
import { useLocalStorage } from "@hooks";

// Auth Context
import AuthContext from "@auth/context";

import Cookies from "js-cookie";

// Http
//import axios from "axios"
import useSWR from "swr";

// Graphql
import { getLinkedCompanies } from "@queries/company";

// Helpers
import { generateQueryKey, graphQueries } from "@helpers";

// Interfaces
import { IAuthContext, IUser, IProvider, ICompany } from "@auth/interfaces";
import { ISettingsService, SettingsService } from "@/helpers/settingsService";
import axios from "axios";
import TagManager from "react-gtm-module";

// Local Interfaces
interface IAUthProvider {
  //api: string
  children: JSX.Element;
}

const parseUserToken = (token?: string | null): IUser | null => {
  if (!token) {
    return null;
  }
  try {
    const userObj = JSON.parse(atob(token.split(".")[1]));
    const formattedUserObj = { ...userObj, department: userObj?.Department?.department };
    return formattedUserObj;
  } catch (e) {
    console.error`Error parsing user token ${token}`;
    return null;
  }
};

//interface ICredentials {
//readonly jwtToken: string,
//readonly refreshToken: string
//}

// Token URL Param
const urlSearchParams: URLSearchParams = new URLSearchParams(
  window.location.search
);
const isFirstTime: boolean = urlSearchParams.get("isFirstTime") === "true";

/**
 * Auth Provider
 * @description provides all global states for app authorization
 *
 */
const AuthProvider: React.FunctionComponent<IAUthProvider> = ({
  children: app /*, api*/,
}): React.ReactElement => {
  // Endpoints
  //const validateTokenEndpoint = `${api}/auth/validate/token`
  //const refreshTokenEndpoint = `${api}/refresh/token`

  // Choose site modal
  const accessToken: string | null =
    Cookies.get("infoToken") || urlSearchParams.get("token");
  const [isChooseSiteModalVisible, setChooseSiteModalVisible] =
    useState<boolean>(false);
  const [settings] = useState<ISettingsService>(
    new SettingsService(urlSearchParams)
  );

  const [isSettingsLoaded, setSettingsLoaded] = useState<boolean>();

  // Credentials
  const [token, setToken] = useState<string>(accessToken || "");
  const [refreshToken, setRefreshToken] = useLocalStorage<string>(
    "refreshToken",
    ""
  );

  // Boolean states
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    (): boolean => !!token
  );
  const [isLoading, setIsLoading] = useState<boolean>(Boolean(accessToken));
  const [error, setError] = useState<Error | null>(null);

  // Company
  const [company, setCompany] = useLocalStorage<Partial<ICompany> | null>(
    "company",
    { id: "" }
  );

  // Data states
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [user, setUser] = useState<IUser | null>(parseUserToken(token));

  const logout = () => {
    if (!user) {
      return;
    }
    const url = `${settings.getSetting("REST_API")}/logout`;
    (async function () {
      const logoutResponse = await axios.post(
        url,
        { refreshToken },
        { withCredentials: true }
      );
      void logoutResponse;
    })();

    // Clear localstoragge
    localStorage.clear();

    // Delete tokens
    setToken("");
    setRefreshToken(null);

    // Set authenticated to false
    setIsAuthenticated(false);
    
    if (Cookies.get("returnTo")) {
      Cookies.remove("returnTo");
    }
  };

  const updateUserFromToken = (userToken: string | null): IUser | null => {
    if (!userToken) {
      if (user) {
        logout();
      }
      return null;
    }
    const newUser = parseUserToken(userToken);
    if (!newUser) {
      console.warn(`Could not update user from ${userToken}`);
      return user;
    }
    if (!user) {
      setUser(newUser);
      return user;
    }
    const currentUser = { ...user };
    let changed = false;
    for (const [key, value] of Object.entries(newUser)) {
      if (value !== undefined && (currentUser as any)[key] !== value) {
        (currentUser as any)[key] = value;
        changed = true;
      }
    }
    if (changed) {
      setUser(currentUser);
    }
    return user!;
  };

  useEffect(() => {
    if (!isSettingsLoaded) {
      (settings as SettingsService).load().then(() => {
        setSettingsLoaded(true);
      });
    }
  }, []);

  // Effects
  useEffect(() => {
    if (!company?.id) setCompany(user ? { id: user.CompanyId } : null), [user];
  });

  useEffect(() => {
    const user = parseUserToken(accessToken);
    // proceed with user session only after the settings are loaded
    graphQueries.setServerURL(settings.getSetting("GRAPHQL_ENTRYPOINT"));
    if (user && isSettingsLoaded) {
      graphQueries.setAuthorizationToken(accessToken!);
      user.isFirstTime = isFirstTime;

      // Set the authenticated user
      setUser(user);

      // Set Token
      setToken(accessToken!);
      setIsAuthenticated(true);
      setIsLoading(false);

      if (user.isFirstTime) {
        //report login first time
        TagManager.dataLayer({
          dataLayer: {
            event: "GA_Event",
            event_action: "login",
            event_category: "workspace_activities",
            event_label: company?.name,
            more_info: "firstime",
          },
        });
      } else {
        TagManager.dataLayer({
          dataLayer: {
            event: "GA_Event",
            event_action: "login",
            event_category: "workspace_activities",
            event_label: company?.name,
            more_info: "returning",
          },
        });
      }
    }
  }, [isSettingsLoaded]);

  // Get Linked Companies
  useSWR<any[]>(
    company?.id ? getLinkedCompanies(generateQueryKey(company.id)) : null,
    (query: string) =>
      graphQueries
        .sendRequest(query, {
          companyId: company!.id,
        })
        .then((data: any) => {
          return data;
        }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (data: any) => {
        company.name = data.getAllCompanyInformation.name;
        company.slug = data.getAllCompanyInformation.slug;
        company.emailDomain = data.getAllCompanyInformation.emailDomain;
        setCompany(({
            ...company,
            linkedCompanies: data.getLinkedCompanies,
          })
        );
      },
    }
  );

  // Auth Context values
  const store: IAuthContext = {
    isAuthenticated,
    setIsAuthenticated,

    error,
    setError,

    isLoading,
    setIsLoading,

    provider,
    setProvider,

    user,
    setUser,

    company,
    setCompany,

    isChooseSiteModalVisible,
    setChooseSiteModalVisible,

    token,
    setToken,
    refreshToken,
    setRefreshToken,
    updateUserFromToken,

    settings,

    logUserOut: logout,
  };

  return <AuthContext.Provider value={store}>{app}</AuthContext.Provider>;
};

export default AuthProvider;
