// React
import { useLayoutEffect, useRef } from "react"

// Axios
import axios from "axios"

// Icons
import { BsX, BsFiles, BsFillFilePdfFill as BsPDF } from "react-icons/bs"

// Helpers
import { copyToClipboard } from "@helpers"

// Review Logic
import { useReview } from "../../useReview"

// Interfaces
import { IReview } from "@interfaces/pages/reviewProcess"

// Local Interfaces
interface ShareReportModalProps {
  setShowShareModal: React.Dispatch<boolean>
}

// Constants
const maxLinkLength = 53

/**
 *
 *  Part: Share Report Modal
 *  @description shows an modal box to download or share the pdf report
 *
 */
const ShareReportModal: React.FunctionComponent<ShareReportModalProps> = ({ setShowShareModal }): JSX.Element => {
  // References
  const ref = useRef<HTMLDivElement>(null)

  // Logic
  const { data }: IReview = useReview()

  // Effects
  useLayoutEffect(() => {
    setTimeout(() => ref.current?.classList.remove("opacity-0"), 0)
  }, [])

  //downloading file
const downloadPdf = () => {
  if (data && data.candidate.pdfUrl) {
    axios({
      url: data.candidate.pdfUrl,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `${data.candidate.personalInformation.email}-${data?.position?.name}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        console.log(error)
      });
  }
}

  return (
    <div
      className="flex items-center justify-center py-8 px-4 bg-black fixed top-0 right-0 w-screen h-screen bg-opacity-70"
      style={{ zIndex: 9999 }}
    >
      <div className="rounded shadow-lg p-6 bg-white text-center opacity-0 transition duration-100" ref={ref} style={{ width: 550 }}>
        <div className="flex justify-between">
          <h1 className="text-lg font-bold leading-none dark:text-gray-100 text-gray-800 "></h1>
          <BsX size={32} className="cursor-pointer relative -top-1.5 text-gray-400" onClick={() => setShowShareModal(false)} />
        </div>

        <button onClick={() => downloadPdf()}>
          <span className="text-lg">Download PDF</span> <BsPDF size={24} className="inline ml-1 relative -top-0.5" />
        </button>

        <p className="text-sm leading-5 mt-6 text-gray-500">Or share with link</p>

        <div className="mt-5">
          <div className="flex items-center justify-between text-sm font-semibold leading-6 rounded-md h-12 w-full border border-gray-200">
            <div>
              <p className="pl-4 text-base font-light leading-tight text-gray-500">
                { window.location.href.substr(0, maxLinkLength) }
                { window.location.href.length > maxLinkLength ? "..." : "" }
              </p>
            </div>

            <div className="bg-white p-3.5 rounded-r cursor-pointer" onClick={() => copyToClipboard(window.location.href)}>
              <BsFiles size={18} className="text-black" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareReportModal
