const Loading: React.FunctionComponent = (): JSX.Element => {
  return (
    <main className="loading">
      <div className="bg-white p-2 sm:p-4 h-screen rounded flex flex-col sm:flex-row gap-5 select-none w-screen">
        <div className="h-52 sm:h-full sm:w-48 rounded-xl bg-gray-200 animate-pulse" ></div>

        <div className="flex flex-col flex-1 gap-5 sm:p-2">
          <div className="flex flex-1 flex-col gap-3">
            <div className="bg-gray-200 w-full animate-pulse h-24 rounded-2xl mb-8" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
          </div>

          <div className="mt-auto flex gap-3">
            <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" ></div>
            <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" ></div>
            <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full ml-auto" ></div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Loading
