import { IPosition } from "@/interfaces/pages/positions"
import { FormikErrors, FormikProps } from "formik"
export interface IWizardStep {
    formik: FormikProps<Partial<IPosition>>,
    validateForm?: (values?: any) => Promise<FormikErrors<Partial<IPosition>>>
    isLoading?: boolean
    setIsLoading?: (isLoading: boolean) => void
}

export enum WizardModes {
    EDIT = "edit",
    CREATE = "create"
}

export enum WizardStepsIds { 
    SELECT_POSITION = "select_a_position",
    DESCRIPTION = "description",
    REQUIREMENTS = "requirements",
    DEFAULT_BLOCKS = "defualt_blocks",
    TraitsAndSkills = 'traits_and_skills',
    QUALIFICATIONS = "qualifications",
    ELIGIBILITIES = "eligibilities",
    RECIPIENTS = "recipients",
    BUILDING_BLOCKS = "building_blocks",
    PUBLISH = "publish"
}

export interface SkillObject {
    positionTitle: string[],
    similarTitles: string[],
    skills: string[],
    rolesAndResposibilities: string[]
}