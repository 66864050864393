import { ICompanyFormPage, ICompanyInfo, ICompanyFormData, IDisplayQuestion } from "@/models"
import { QuestionCollection } from "@/models/formPage";
import { DisplayQuestion } from "@/models/question";
export type QuestionKeys = keyof IDisplayQuestion

type QuestionValueTypes = string | object | boolean | number | null

/**
 * implements bidirectional transformation of data
 * between question (template) formats and company data
 */
export interface ICompanyFormPageAdapter {
	/**
	 * Updates in place the fields in the company form template, based on the 
	 * company data
	 * 
	 * @param company
	 */
	updateFormPageFromCompany(company: ICompanyInfo): ICompanyFormPage
	/**
	 * Creates company information from the internal page data
	 */
	createUpdate(): Partial<ICompanyInfo>
	getQuestionField(index: number, subIndex: number, field: QuestionKeys): QuestionValueTypes
	setQuestionField(index: number, subIndex: number, field: QuestionKeys, value: QuestionValueTypes): boolean
	setQuestionValue(index: number, subIndex: number, value: QuestionValueTypes): boolean
	/**
	 * Returns the value of the question. If the value is an object, a reference is returned so changing it
	 * changes the question
	 * @param index 
	 * @param subIndex 
	 */
	getQuestionValue(index: number, subIndex: number): QuestionValueTypes
	getQuestion(index: number, subIndex: number): IDisplayQuestion | null
	readonly questions: QuestionCollection
	readonly customQuestions: QuestionCollection | null
	readonly models: QuestionCollection | null
}


/**
 * Base class for company form adapters, that implement bidirectional transformation of data
 * between question (template) formats and company data
 * Abstract because only page specific adapters should be instantiated.
 */
export abstract class CompanyFormPageAdapter implements ICompanyFormPageAdapter{
	constructor(protected page: ICompanyFormPage, public readonly name: keyof ICompanyFormData) {

	}

	abstract createUpdate(): Partial<ICompanyInfo>

	public updateFormPageFromCompany(company: ICompanyInfo): ICompanyFormPage {
		if (!company) {
			console.warn(`companyStoryAdapter ${this.name}: missing company`);
			return this.page;
		}
		if (!this.page) {
			console.warn(`companyStoryAdapter ${this.name}: no page data`);
			return this.page
		}
		return this._doUpdateFormPageFromCompany(company);
	}


	public get questions(): QuestionCollection {
		return this.page?.questions
	}

	public get customQuestions(): QuestionCollection | null {
		return this.page?.customQuestions || null
	}

	public get models(): QuestionCollection | null {
		return this.page?.models || null
	}

	public setQuestionField(index: number, subIndex: number, field: QuestionKeys, value: QuestionValueTypes): boolean {
		const q = this.getQuestion(index, subIndex) as IDisplayQuestion;
		if (q) {
			(q as any)[field] = value;
		}
		return Boolean(q)
	}

	public getQuestionField(index: number, subIndex: number, field: QuestionKeys): QuestionValueTypes {
		const q = this.getQuestion(index, subIndex) as IDisplayQuestion;
		return q? q[field]! : null
	}

	public setQuestionValue(index: number, subIndex: number, value: QuestionValueTypes): boolean {
		return this.setQuestionField(index, subIndex, "value", value)
	}

	public getQuestionValue(index: number, subIndex: number): QuestionValueTypes {
		const q = this.getQuestion(index, subIndex)
		return q ? q.value : null
	}

	public getQuestion(index: number, subIndex: number): IDisplayQuestion | null {
		const qs = this.questions
		if (!qs) {
			return null
		}
		const qlist: IDisplayQuestion[] = qs[index]
		if (qlist) {
			const q = qlist[subIndex]
			return q || null
		}
		return null
	}

	//////////// Implementation ////////////////////

	/**
	 * Creates an array of questions, each based on a model (if available) and partial question data
	 * @param modelIndex The index in the form data models array
	 * @param models The models to merge into the questions
	 * @returns {Array<IDisplayQuestion>} an array of new question objects
	 */

	protected createQuestions(modelIndex: number, models: Partial<IDisplayQuestion>[]): Array<IDisplayQuestion> {
		const templates = this.models && this.models[modelIndex] || []
		const ret: Array<IDisplayQuestion> = models.map((model, index) =>
			new DisplayQuestion(templates[index]).merge(model))
		return ret
	}
	/**
	 * Returns a copy of the arrays, so you can't use this to update the original arrays, only individual questions
	 */
	protected allQuestionGroups(): QuestionCollection {
		return (this.questions || []).concat(this.customQuestions || [])
	}

	/**
	 * Performs the actual update. Called only if the page exists
	 * @param company 
	 */
	protected abstract _doUpdateFormPageFromCompany(company: ICompanyInfo): ICompanyFormPage;
}