
interface AllStatuses {
	"Open": 1,
	"Closed": 1,
	"Hired": 1,
	"Frozen": 1,
	"Draft": 1,
	"Coming Soon": 1
}

const allStatuses: AllStatuses = {
	"Open": 1,
	"Closed": 1,
	"Hired": 1,
	"Frozen": 1,
	"Draft": 1,
	"Coming Soon": 1
}  

export type PositionStatus = keyof AllStatuses;

export const getAllPositionStatuses = (): PositionStatus[] => {
	return Object.keys(allStatuses) as PositionStatus[]
}

