/**
 *
 * @module src/pages/Referrals/AddReferral/OneByOne
 *
 * @description referral input container / mode: one-by-one
 *
 * @author diegoulloao
 *
 */

// Parts
import ReferralItem from "@pages/Referrals/AddReferral/OneByOne/ReferralItem"

// Icons
import { BsPlus } from "react-icons/bs"

// Interfaces
import { IReferrer, OneByOneProps } from "@interfaces/pages/referrals"

/**
 *
 *  Part: One By One
 *  @description view to add referrers one by one
 *
 */
const OneByOne: React.FunctionComponent<OneByOneProps> = ({ referrers, setReferrers, edit }): JSX.Element => {
  // Handlers
  const setNewReferrer: React.MouseEventHandler<HTMLButtonElement> = (): void => {
    // Set a new referrer to the list
    setReferrers((currentReferrers: IReferrer[]) => {
      // Copy of current referrers list
      const referrers: IReferrer[] = [...currentReferrers]

      // We push a new slot to referrers list
      referrers.push({ position: null, email: null, name: null, link: null })

      return referrers
    })
  }

  return (
    <div className="add-referrals one-by-one space-y-12">
      {/* Referrer row */}
      {referrers.map((r: IReferrer, i: number) => (
        <ReferralItem 
          key={i}
          setReferrers={setReferrers}
          referralItem={r}
          index={i}
          edit={edit}
        />
      ))}

      {/* Add a new Referrer */}
      {!edit && (
        <button
          className="border-b border-gray-400 hover:border-black pb-1 text-gray-500 hover:text-black transition"
          onClick={setNewReferrer}
        >
          <BsPlus className="inline relative -top-0.5" size="24" /> Add Referrer
        </button>
      )}
    </div>
  )
}

export default OneByOne
