import { MultiTransactionManager } from "./multiTransactionManager";
import { ISettingsService } from "./settingsService";

export interface IUploadParams {
	readonly data: string | ArrayBuffer
	readonly name?: string
	readonly tags?: string[]
}

export interface IUploadResult {
	error: string | null
	url: string | null
	isProcessed: boolean
}

export class MediaUploader extends MultiTransactionManager {
	private readonly apiKey: string
	private readonly uploadUrl: string
	constructor(settings: ISettingsService) {
		super("image uploader")
		if (!(this.apiKey = settings.getSetting("CLOUDINARY_API_KEY"))) {
			throw new Error("Media uploader: missing CLOUDINARY_API_KEY key (check .env)");
		}
		if (!(this.uploadUrl = settings.getSetting("CLOUDINARY_UPLOAD_URL"))) {
			throw new Error("Media uploader: missing CLOUDINARY_UPLOAD_URL key (check .env)");
		}
	}
	/**
	 * Returns a promise that resolves when this upload is complete
	 * 
	 * Swallows exceptions
	 * @param params 
	 */
	upload(params: IUploadParams): Promise<IUploadResult> {
		const transaction = this.addTransaction();
		return this._upload(transaction.key, params)
	}

	private async _upload(key: string, params: IUploadParams): Promise<IUploadResult> {
		try {
			const formData = new FormData()
			formData.append('file', String(params.data))
			formData.append('tags', (params.tags || []).join(' '))
			// TODO move to configuration
			formData.append('upload_preset', 'default-unsigned')
			// TODO move to configuration
			formData.append('api_key', this.apiKey)

			const response = await fetch(this.uploadUrl, {
				method: "POST",
				body: formData
			})
			const data = await response.json()
			const error = (data.error && data.error.message)
				|| (this.uploadUrl ? null : "upload failed")
			const uploadedUrl: string | null = data && (data.secure_url || data.url) as string || null
			// Make sure this promise resolves before we notify the world that the entire transaction has ended
			setTimeout(() => this.onTransactionDone(key, [error!]), 0);
			return {
				url: uploadedUrl,
				error,
				isProcessed: true
			}
		}
		catch (e) {
			return {
				url: null,
				error: String(e),
				isProcessed: false
			}
		}
	}
}
