import axios from "axios";

import { getAllReportData, getReportCandidateData } from "@/graphql/queries/candidates";
import { graphQueries } from "@/helpers";
import { CandidateReportResponse, CandidateResponse, ComProfileForecastResponse, ComProfileResponse, CorrectAnswerFields } from "./types";
import env from "react-dotenv";
import { getComProfileForecast, getComProfileReview, getReferrerByCode } from "@/graphql/queries/review";
import { getUser } from "@/graphql/queries/users";
import { IUser } from "@/auth/interfaces";
import { ComProfile } from "./report.utils";
import { submitCorrectAnswer, updateCandidate } from "@/graphql/mutations/candidateReport";

const VIDEOS_API_ORIGIN = env.UNBOXABLE_VIDEOS_API;

export class ReportApi {

    public static videosApiEndpoint = VIDEOS_API_ORIGIN

    static async getCandidateBlocksByCandidateId(candidateId: string, positionId: string, companyId: string): Promise<CandidateReportResponse> {
        try {
            const response = await graphQueries.sendRequest(getAllReportData, {
                candidateId: candidateId,
                candidateId2: candidateId,
                positionId: positionId,
                companyId: companyId
            });
            
            const { getV2Position } = response;
            response.getV2Position = getV2Position?.[0];
    
            return response;
            
        }catch(e) {
            return { } as any;
        }
    }

    static async getCandidateData(candidateId: string): Promise<CandidateResponse> {
        try {
            const { getCandidate: response } = await graphQueries.sendRequest(getReportCandidateData, {
                candidateId: candidateId
            });
    
            return response;
        }catch(e) {
            return { } as any;
        }
    }

    static async getVideoUrlByVideoName(videoName: string): Promise<string | undefined> {
        try {
            const response = await axios.post(`${this.videosApiEndpoint}/uploads/getPresignedUrls`, {
                fileName: videoName
            });
                    
            return response?.data?.location;
        }catch(e) {
            return undefined
        }
    }

    static async getCommunicationStyleDetailed(profileLetter: ComProfile): Promise<ComProfileResponse> {
        try {
            const response = await graphQueries.sendRequest(getComProfileReview(""), {
                profileLetter: profileLetter
            });
    
            const { comprofile } = response;
    
            return comprofile?.[0];
        }catch(e) {
            return { } as any;
        }
    }

    static async getUserByEmail(email: string): Promise<IUser> {
        try {
            const {getUser: response} = await graphQueries.sendRequest(getUser, {
                email: email,
            });
    
            return response;
        }catch(e) {
            return { } as any;
        }
    }

    static async getComProfileForcast(profileLetter1: ComProfile, profileLetter2: ComProfile): Promise<ComProfileForecastResponse | {}> {
        try {
            const { getComProfileForecast: response } = await graphQueries.sendRequest(getComProfileForecast, {
                comProfileOne: profileLetter2,
                comProfileTwo: profileLetter1,
            });
            
            return response;
        }catch(e) {
            return {}
        }
    }

    static async getRfferalByCode(referrerCode: string): Promise<string | undefined> {
        try {
            const { getReferrer: response } = await graphQueries.sendRequest(getReferrerByCode, { code: referrerCode });
    
            return response?.name;
        }catch(e) {
            return undefined
        }
    }

    static async submitCorrectAnswer(args: { candidateId: string, assessmentId: string, correctAnswers: CorrectAnswerFields[] }): Promise<boolean> {    
        try {
            const { submitCorrectAnswers: response } = await graphQueries.sendRequest(submitCorrectAnswer, {
                ...args,
                correctAnswers: args?.correctAnswers?.map(ca => ({
                    [ca.questionUid]: ca.score
                }))
            });
            
            return response?.response ? true : false;
        } catch(e) {
            return false
        } 
    }

    static async updateCandidate(args: {
        firstName?: string,
        phone?: string,
        candidateId?: string,
        middleName?: string,
        lastName?: string,
        email?: string
    }): Promise<string | undefined>{     
        try {
            const { updateCandidate: response } = await graphQueries.sendRequest(updateCandidate, args);
    
            const { response: message } = response || {};
    
            return message;

        } catch(e) {
            return undefined
        }
    }
}