// Router
//import { Redirect } from "react-router-dom"

// Auth0
//import { useAuth0, Auth0ContextInterface } from "@auth0/auth0-react"

const SignUp: React.FunctionComponent = (): JSX.Element => {
  return (
    <main id="login">
      <div>
        <section className="bg-white dark:bg-gray-900 {-- h-screen --}">
          <div className="mx-auto flex justify-center md:items-center relative md:h-full">
            <form id="login" className="w-full sm:w-4/6 md:w-3/6 lg:w-4/12 xl:w-3/12 text-gray-800 mb-32 sm:mb-0 my-40 sm:my-12 px-2 sm:px-0">
              <div className="px-2 flex flex-col items-center justify-center mt-8 sm:mt-0">
                <img src="/lnrd-black.svg" className="h-14" alt="logo" />
                <h2 className="text-4xl dark:text-gray-100 leading-tight pt-8">Sign up</h2>
              </div>
              <div className="mt-12 w-full px-2 sm:px-6">
                <div className="flex flex-col mt-5">
                  <label htmlFor="email" className="text-md font-semibold dark:text-gray-100 leading-tight">
                    Email
                  </label>
                  <input required name="email" id="email" className="h-10 px-2 w-full rounded mt-2 text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-800 dark:border-gray-700 dark:focus:border-indigo-600 focus:outline-none focus:border focus:border-indigo-700 border-gray-300 border shadow" type="email" />
                </div>
                <div className="flex flex-col mt-5">
                  <label htmlFor="password" className="text-md font-semibold dark:text-gray-100 fleading-tight">
                    Password
                  </label>
                  <input required name="password" id="password" className="h-10 px-2 w-full rounded mt-2 text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-800 dark:border-gray-700 dark:focus:border-indigo-600 focus:outline-none focus:border focus:border-indigo-700 border-gray-300 border shadow" type="password" />
                </div>
              </div>
              <div className="pt-6 w-full flex justify-between px-2 sm:px-6">
                <div className="flex items-center ">
                  <input id="rememberme" name="rememberme" className="w-3 h-3 mr-2 bg-white dark:bg-gray-800" type="checkbox" />
                  <label htmlFor="rememberme" className="text-xs dark:text-gray-100">
                    Remember Me
                  </label>
                </div>
                <a className="text-xs text-black hover:text-indigo-600" href="/forgot">
                  Forgot Password?
                </a>
              </div>
              <div className="px-2 mb-16 sm:mb-56 md:mb-16 sm:px-6">
                <button className="focus:outline-none w-full bg-black transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-8 py-3 text-sm mt-6" onClick={() => window.location.pathname = "/dashboard"}>Sign up</button>

                <div className="w-full flex items-center justify-between py-12">
                  <hr className="w-full text-gray-500" />
                  <p className="text-base font-normal leading-4 px-2.5 text-gray-500">OR</p>
                  <hr className="w-full text-gray-500" />
                </div>

                <button aria-label="Continue with google" className="focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded border-gray-700 flex justify-center items-center w-full">
                  <svg width={19} height={20} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.9892 10.1871C18.9892 9.36767 18.9246 8.76973 18.7847 8.14966H9.68848V11.848H15.0277C14.9201 12.767 14.3388 14.1512 13.047 15.0812L13.0289 15.205L15.905 17.4969L16.1042 17.5173C17.9342 15.7789 18.9892 13.221 18.9892 10.1871Z" fill="#4285F4" />
                    <path d="M9.68813 19.9314C12.3039 19.9314 14.4999 19.0455 16.1039 17.5174L13.0467 15.0813C12.2286 15.6682 11.1306 16.0779 9.68813 16.0779C7.12612 16.0779 4.95165 14.3395 4.17651 11.9366L4.06289 11.9465L1.07231 14.3273L1.0332 14.4391C2.62638 17.6946 5.89889 19.9314 9.68813 19.9314Z" fill="#34A853" />
                    <path d="M4.17667 11.9366C3.97215 11.3165 3.85378 10.6521 3.85378 9.96562C3.85378 9.27905 3.97215 8.6147 4.16591 7.99463L4.1605 7.86257L1.13246 5.44363L1.03339 5.49211C0.37677 6.84302 0 8.36005 0 9.96562C0 11.5712 0.37677 13.0881 1.03339 14.4391L4.17667 11.9366Z" fill="#FBBC05" />
                    <path d="M9.68807 3.85336C11.5073 3.85336 12.7344 4.66168 13.4342 5.33718L16.1684 2.59107C14.4892 0.985496 12.3039 0 9.68807 0C5.89885 0 2.62637 2.23672 1.0332 5.49214L4.16573 7.99466C4.95162 5.59183 7.12608 3.85336 9.68807 3.85336Z" fill="#EB4335" />
                  </svg>
                  <p className="text-base font-medium ml-4 text-gray-700">Continue with Google</p>
                </button>

                <button aria-label="Continue with linkedin" className="mt-3 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded border-gray-700 flex justify-center items-center w-full">
                  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                    aria-describedby="desc" role="img">
                    <title>Linkedin</title>
                    <desc>A color styled icon from Orion Icon Library.</desc>
                    <path data-name="layer1"
                      fill="#0077b7" d="M1.15 21.7h13V61h-13zm46.55-1.3c-5.7 0-9.1 2.1-12.7 6.7v-5.4H22V61h13.1V39.7c0-4.5 2.3-8.9 7.5-8.9s8.3 4.4 8.3 8.8V61H64V38.7c0-15.5-10.5-18.3-16.3-18.3zM7.7 2.6C3.4 2.6 0 5.7 0 9.5s3.4 6.9 7.7 6.9 7.7-3.1 7.7-6.9S12 2.6 7.7 2.6z"></path>
                  </svg>
                  <p className="text-base font-medium ml-4 text-gray-700">Continue with Linkedin</p>
                </button>

                <p className="mt-16 text-xs text-center dark:text-gray-100">
                  Do you Have An Account?{" "}
                  <a className="underline text-black hover:text-indigo-600" href="/">
                    Sign In
                  </a>
                </p>
              </div>
            </form>
          </div>
        </section>
      </div>
    </main>
  )
}

export default SignUp
