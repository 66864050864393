import { gql } from "graphql-request";

export const submitCorrectAnswer = gql`
mutation SubmitCorrectAnswers($candidateId: UUID!, $assessmentId: UUID!, $correctAnswers: [JSON]!) {
    submitCorrectAnswers(candidateId: $candidateId, assessmentId: $assessmentId, correctAnswers: $correctAnswers) {
        response
    }
}
`

export const updateCandidate = gql`
mutation UpdateCandidate($firstName: String, $phone: String, $candidateId: UUID!, $middleName: String, $lastName: String, $email: String) {
    updateCandidate(firstName: $firstName, phone: $phone, candidateId: $candidateId, middleName: $middleName, lastName: $lastName, email: $email) {
        response
    }
}`