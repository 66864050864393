
import { MenuItem, Select, SelectProps } from "@mui/material";
import { HtmlTooltip } from "../CustomTooltip/CustomTooltip";
import Label from "../Label/Label";
import InfoIcon from '@mui/icons-material/Info';

import './style.css';

type SimpleSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;
interface IMultiSelectExtra {
    options: {value: any, label: string}[], 
    label?: string, 
    uiType?: "primary" | "white-bordered" | "white",
    tooltip?: string;
    containerStyle?: React.CSSProperties;
}

// eslint-disable-next-line
export interface IDropdown extends SimpleSpread<SelectProps<any>, IMultiSelectExtra> {}

const Dropdown: React.FunctionComponent<IDropdown> = 
(props): JSX.Element => {
    const { options, uiType = "primary", label, tooltip, className, placeholder, children, containerStyle, ...filterdProps } = props;
    
    const uiTypeStyle = {
        "white-bordered": {
            fontSize: "12px",
            border: "1px solid #000",
            // width: "220px",
            height: "45px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            "& .MuiList-root": {
                padding: "0 !important"
            },
            "& .Mui-focused": {
                border:"none",
                outline: "none"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            }
        },
        "white": {
            fontSize: "12px",
            border: "none",
            // width: "220px",
            height: "45px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            "& .MuiList-root": {
                padding: "0 !important"
            },
            "& .Mui-focused": {
                border:"none",
                outline: "none"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            }
        },
        "primary": {
            fontSize: "12px",
            border: "none",
            width: "220px",
            height: "35px",
            backgroundColor: "#EDEDED",
            borderRadius: "50px",
            "& .MuiList-root": {
                padding: "0 !important"
            },
            "& .Mui-focused": {
                border:"none",
                outline: "none"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            }
        }
    }
    
    return (
    <div className="custom-dropdown-container" style={containerStyle}>
        {label ? 
        tooltip ?  
            <div style={{ marginBottom: "7px" }}>
                <Label>{label}</Label>
                <HtmlTooltip
                placement="bottom-start"
                title={<p>{tooltip}</p>} > 
                    <InfoIcon sx={{ fontSize: "12px", marginLeft: "4px" }}/>
                </HtmlTooltip>
            </div>
            : <Label>{label}</Label>
        : null}
        <Select 
        className={`wizard-custom-dropdown ${className ?? ""}`}
        MenuProps={{
            PaperProps: {
                sx: {
                    "& .MuiList-root": {
                        padding: "0 !important",
                    },
                    "& .MuiMenuItem-root": {
                        fontSize: "12px"
                    }
                }
            }
        }}
        displayEmpty
        renderValue={(value: any) => (value ?? placeholder ?? "")}
        sx={uiTypeStyle[uiType]}
        {...filterdProps} 
        >
            {children ?? 
            options.map((o) => (
            <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem> 
            ))}
        </Select>
    </div>
    )
}

export default Dropdown;