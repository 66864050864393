import { useEffect, useState, useContext } from "react";

import { useAuth } from "@/auth";
import { Title } from "@/components";
import RelevancyCards from "@/components/RelevencyCards";
import { EditorApi } from "../AssessmentEditor/data/editor.api";
import { BuildingBlock } from "../BuildingBlocks/BuildingBlockEditor";
import { TypeOfRelevency } from "../CandidateReport/V2/data/report.utils";
import { TalentsTable } from "../Talents/components";
import { getCandaiteByRelevancy } from "../Talents/helpers";
import { ICandidate } from "../Talents/interfaces";
import AppContext from "@/context/AppContext";

const MyFavorites: React.FunctionComponent = (): JSX.Element => {

    const { user } = useAuth();

    const [favorites, setFavorites] = useState<ICandidate[]>([]);
    const [candidatesByRelevancy, setCandidatesByRelevancy] = useState<{ [key in TypeOfRelevency]: ICandidate[] }>();
    const [positionBlockTypes, setPositionBlockTypes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [buildingBlocks, setBuildingBlocks] = useState<BuildingBlock[]>([]);
    const [isLoadingBuildingBlocks, setIsLoadingBuildingBlocks] = useState<boolean>(false);
    const { tr } = useContext(AppContext);

    useEffect(() => {
        setIsLoading(true)
        getCandaiteByRelevancy({
            sizeOfPage: 10000,
            page: 0
        }, user?.id )
        .then(({ 
            gemsCandidates,
            starsCandidates,
            qualifiedCandidates,
            allCandidates,
            blockTypes
        }) => {   
            setCandidatesByRelevancy({
                gem: gemsCandidates,
                star: starsCandidates,
                relevant: qualifiedCandidates,
                notQualified:[],
            })
            setFavorites(allCandidates);
            setPositionBlockTypes(blockTypes);
        })
        .catch(console.error)
        .finally(() => setIsLoading(false))
    },[])

    useEffect(() => {
        setIsLoadingBuildingBlocks(true);
        EditorApi.getBuildingBlocks()
        .then(setBuildingBlocks)
        .catch(console.error)
        .finally(() => setIsLoadingBuildingBlocks(false))
    }, [])

    return (
        <div>
        <Title text={tr("My favorite candidates")}/>
        <RelevancyCards 
        loading={isLoading}
        candidatesByRelevancy={candidatesByRelevancy as any}
        />
        <TalentsTable 
        candiadtes={favorites} 
        setCandidates={setFavorites}
        isLoading={isLoading || isLoadingBuildingBlocks} 
        isFavoritesPage={true}
        buildingBlocks={buildingBlocks} 
        positionBlockTypes={positionBlockTypes}/>
        </div>
    )
}

export default MyFavorites;