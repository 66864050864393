import { useEffect, useState, useContext } from 'react';

import unboxableImg from "@assets/logos/unboxable-logo.svg";
import microsoftWhiteLogo from "@assets/icons/microsoft-white.svg";
import lightningIcon from './lightning-icon.svg';


import { LNRDLoading, LoginButton } from '@/components';

import ReactHtmlParser from 'react-html-parser';

import './style.css'
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router';
import { graphQueries } from '@/helpers';
import { getLoginScreenMetadataByTypeId } from '@/graphql/queries/loginPageContent';

import AppContext from '@/context/AppContext';

interface ILoginScreenMetadata {
    title: string,
    description: string,
    icons: {
        name: string,
        url: string
    }[]
}

const DEFAULT_TITLE = "Sign up and open your first position for free";
const DEFAULT_DESCRIPTION = "Welcome to your Workspace, the hub for hiring with accuracy. Manage job positions, review candidates, and make smart decisions."
const DEFAULT_ICON = [{
    name: "lightning",
    url: lightningIcon
}];

const Login: React.FunctionComponent = (): JSX.Element => {

	const { search } = useLocation();
	const query = new URLSearchParams(search);
    const { locale, localizer } = useContext(AppContext);

    const [rawContent, setRawContent] = useState<ILoginScreenMetadata>({
        title: DEFAULT_TITLE,
        description: DEFAULT_DESCRIPTION,
        icons: DEFAULT_ICON
    })

    const [content, setContent] = useState<ILoginScreenMetadata>(rawContent);

    const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);
    const [imagesIsLoading, setImagesIsLoading] = useState<boolean>(true);

    const pageContentIsLoading = (): boolean => {
        return (pageIsLoading || imagesIsLoading)
    }

    const reportLoginPageLandingEvent = (): void => {
        TagManager.dataLayer({
            dataLayer: {
                event: "GA_Event",
                event_action: "login_page_landing",
                event_category: "workspace_activities",
                event_label: "before_login",
            },
        });
    }

    const getScreenType = (): number => {
        const screenType = query.get("screenView") ?? "1";
        return Number(screenType)
    }

    const withTimeout = (millis: number, promise: Promise<any>): Promise<any> => {
        const timeout = new Promise((resolve, reject) =>
            setTimeout(
                () => reject(`Timed out after ${millis} ms.`),
                millis));
        return Promise.race([
            promise,
            timeout
        ]);
    };

    const fetchPageMetadata = async (screenType: number): Promise<ILoginScreenMetadata | null> => {
        const response = await withTimeout (
            10000,
            graphQueries.sendRequest(getLoginScreenMetadataByTypeId, { type: screenType })
        )

        if(response.errors)
            return null
        
        return response?.getLoginScreenMetadataByTypeId?.metadata
    }

    useEffect(() => {
        setContent(localizer.localizeObject({
            data: rawContent,
            locale,
            keys: ["title", "description"]
        }))
    }, [locale, rawContent])

    useEffect(() => {
        reportLoginPageLandingEvent();
        fetchPageMetadata(getScreenType())
            .then((pageContent) => {
                if(pageContent) {
                    setRawContent(pageContent as ILoginScreenMetadata);
                }
            })
            .catch((e) => console.error(e))
            .finally(() => setPageIsLoading(false));
    }, [])

    return (
        <div className='login-page'>
            {pageContentIsLoading() && <LNRDLoading className='custom-loading-size'/>}
            <div 
            style={pageContentIsLoading() ? { display: "none" } : {}}
            className='login-page-container'>
                <img 
                src={unboxableImg} 
                className="login-page-logo" 
                alt="logo" />
                <img 
                src={content.icons[0].url} 
                className="login-page-icon"
                onLoad={() => setImagesIsLoading(false)}
                alt="icon"/>
                
                <h1 className='login-page-header'>
                    {ReactHtmlParser(content.title)}
                </h1>
                <p className='login-page-description'>
                    {ReactHtmlParser(content.description)}
                </p>
                <p className='login-page-sentence'>
                    {"Please use your work email address to login."}
                </p>
                <div className='login-page-buttons'>
                    <LoginButton type='google'/>
                    <LoginButton type='microsoft' icon={microsoftWhiteLogo}/>
                </div>
                <div className='login-page-footer'>
                    <a
                    href="https://www.unboxable.com/privacy-policy/"
                    target="_blank">{"Privacy"}</a>
                    &nbsp;|&nbsp;
                    <a
                    href="https://www.unboxable.com/"
                    target="_blank">{"About"}</a>
                </div>
            </div>
        </div>
    )
}

export default Login;