import { GraphQlClient } from '../graph-client/graphql-client';
// import { SuperSimpleAndSuperGenericBackwardCompetability } from './competability';
import { 
  ReadData, 
  ReadResult,
  CreateData,
  CreateResult,
  UpdateResult,
  UpdateData
} from './interfaces';
export class PositionClient {

  static async read(data: ReadData): Promise<ReadResult | undefined> {
    const {  getV2Position } = await GraphQlClient.getPosition(data) || {};
    const [ position ] = getV2Position || [];
    return position; // SuperSimpleAndSuperGenericBackwardCompetability(position);
  }

  static async create(data: CreateData): Promise<CreateResult | undefined> {
    const { createV2Position } = await GraphQlClient.createPosition(data) || {};
    return createV2Position;
  }

  static async update(data: UpdateData): Promise<UpdateResult | undefined> {
    const { updateV2Position } = await GraphQlClient.updatePosition(data) || {};
    return updateV2Position;
  }

}