import { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router";

const useBackgroundColor = (defaultBgColor: string, bgColorToRoute: {[str: string]: string[]} ): { bgColor: string } => {

    const [bgColor, setBgColor] = useState<string>(defaultBgColor);

    const location = useLocation();

    const needToChangeColor = (routes: string[], currentPathname: string): boolean => {
        const isMatch = matchPath(currentPathname, { path: routes, exact: true, strict: false})

        if(isMatch && isMatch.isExact) {
            return true;
        }

        return false
    }

    const changeBackgroundColor = (): void => {
        let bgIsChanged = false;

        for (const color in bgColorToRoute) {

            const routes = bgColorToRoute[color]
            if(needToChangeColor(routes, location.pathname)) {
                setBgColor(color)
                bgIsChanged = true;
            }
        }

        if(!bgIsChanged) {
            setBgColor(defaultBgColor)
        }
    }

    useEffect(() => {
        changeBackgroundColor()
    }, [location]);

    return { bgColor }
}

export default useBackgroundColor;