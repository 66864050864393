import CloseIcon from '@mui/icons-material/Close';
import { HtmlTooltip } from '../CustomTooltip/CustomTooltip';

import './style.css';


interface ISuggestionCard {
    suggestion?: { value: any, label: string }
    showDeleteIcon?: boolean,
    onClick?: (suggestion: { value: any, label: string }) => any,
    toolTipComponent?: any,
    toolTipStyle?: React.CSSProperties | undefined,
    selected?: boolean,
    className?: string,
    children?: React.ReactNode,
}

const SuggestionCard: React.FunctionComponent<ISuggestionCard> = ({
    suggestion,
    showDeleteIcon,
    onClick,
    toolTipComponent,
    toolTipStyle,
    selected,
    className,
    children
}):JSX.Element => {
    const withToolTip = toolTipComponent ? true : false;

    return (
        <HtmlTooltip 
        title={withToolTip ? toolTipComponent : ""} 
        style={toolTipStyle}
        placement='right'>
            <div 
            className={`suggestion-card-container orange-bg-on-hover-style ${selected ? "suggestion-card-selected" : ""} ${className ?? ""}`} 
            onClick={(onClick && suggestion) ? () => onClick(suggestion) : undefined}>
                <span>{children ?? suggestion?.label}</span>
                {showDeleteIcon && 
                <CloseIcon/>}
            </div>
        </HtmlTooltip>
    )
}

export default SuggestionCard;