import LText from "@/components/LText"

const NotFound: React.FunctionComponent = (): JSX.Element => {
  return (
    <main className="404-page">
      <h1 className="text-3xl">
        <LText text="Page Not Found" /> 404</h1>
    </main>
  )
}

export default NotFound
