

export interface IImageUtils {
	/**
	 * Returns an empty string in case of an error
	 * @param image 
	 * @param maxDimension 
	 */
	resizeImage(image: HTMLImageElement, maxDimension: number): string
	/**
	 * Returns an empty string in case of an error
	 * @param image 
	 * @param maxDimension 
	 */
	resizeImageData(image: string | ArrayBuffer, maxDimension: number): Promise<string>
}

const imageResizer = (image: HTMLImageElement, maxDimension: number): string => {
	if (!image || !image.width || !image.height) {
		return ""
	}
	let width = image.width,
		height = image.height;
	if (maxDimension < width) {
		height *= maxDimension / width;
		width = maxDimension;
	}
	if (maxDimension < height) {
		width *= maxDimension / height;
		height = maxDimension;
	}
	// create an off-screen canvas
	const canvas = window.document.createElement('canvas'),
		ctx = canvas.getContext('2d')!;

	// set its dimension to target size
	canvas.width = width;
	canvas.height = height;

	// draw source image into the off-screen canvas:
	ctx.drawImage(image, 0, 0, width, height);

	// encode image to data-uri with base64 version of compressed image
	return canvas.toDataURL();
}

export class ImageUtils implements IImageUtils {
	async resizeImageData(image: string | ArrayBuffer, maxDimension: number): Promise<string> {
		if (!image) {
			return ""
		}
		if (!maxDimension || isNaN(maxDimension)) {
			return String(image)
		}
		return new Promise((resolve) => {
			try {
				const img = new Image()
				img.addEventListener("load", event => {
					resolve(imageResizer(event.target as HTMLImageElement, maxDimension))
				})
				img.addEventListener("error", event => {
					console.error("resizeImgeData:", String(event))
					resolve(image as string)
				})
				img.src = String(image)
			}
			catch (e) {
				console.error("resize image:", e)
				return image as string
			}
		})
	}

	public resizeImage(image: HTMLImageElement, maxDimension: number): string {
		return imageResizer(image, maxDimension)
	}
}