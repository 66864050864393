import synergyPositiveIcon from '../../icons/synergy-positive.svg';
import synergyNegativeIcon from '../../icons/synergy-negative.svg';
// import arrowCircleIcon from '../../icons/arrow-circle.svg';
import successIcon from '../../icons/success.svg';
import failureIcon from '../../icons/fail.svg';

import './style.css';
import { ReportHeader } from '../../components';

const SynergyReccommendation: React.FunctionComponent<{
    hiredSynergy?: string[];
    avoidSynergy?: string[];
}> = ({
    hiredSynergy,
    avoidSynergy
}):JSX.Element => {
    return (
        <div>
            <ReportHeader>if hired</ReportHeader>
            <div className='synergy-table-cards-container'>
                {hiredSynergy && 
                <div className='synergy-table-card'>
                    <div className='synergy-table-card-top'>
                        <h3>
                            <span className='synergy-positive-color'>
                                {"If hired, "}
                            </span> 
                            {"make sure you help this Talent thrive:"}
                        </h3>
                        <img src={synergyPositiveIcon}/>
                    </div>
                    {hiredSynergy.map((item, index) => (
                        <div key={index} className='synergy-table-card-item'>
                            <img src={successIcon}/>
                            <span> {item} </span>
                        </div>
                    ))}
                </div>}
                {avoidSynergy &&
                <div className='synergy-table-card'>
                    <div className='synergy-table-card-top'>
                        <h3>
                            <span className='synergy-negative-color'>
                                {"Avoid "}
                            </span>
                            {"putting the Talent in survival mode:"}
                        </h3>
                        <img src={synergyNegativeIcon}/>
                    </div>
                    {avoidSynergy.map((item, index) => (
                        <div key={index} className='synergy-table-card-item'>
                            <img src={failureIcon}/>
                            <span> {item} </span>
                        </div>
                    ))}
                </div>}
            </div>
            {/* <div className='synergy-forcast-sentence-container'>
                <p>
                    {"Forecasts and recommendations on the"} 
                    <span>
                        &nbsp;{"synergy between the Manager and Talent."}
                    </span>
                </p>
                <img src={arrowCircleIcon}/>
            </div> */}
        </div>
    )
}

export default SynergyReccommendation;