
import React, { useState } from "react";

// Third party components
import { Menu, MenuItem } from "@mui/material";


const DropDownWithDotsMaterial: React.FunctionComponent<{
  options: any;
  customIcon?: () => React.ReactNode;
  customItems?: () => React.ReactNode;
}> = ({ options, customIcon, customItems }): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return(
        <div>
        <div
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e: any) => handleClick(e)}
          style={{cursor: 'pointer'}}
        >
            {customIcon ? customIcon()
            : <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <circle cx={12} cy={5} r={1} />
                <circle cx={12} cy={12} r={1} />
                <circle cx={12} cy={19} r={1} />
            </svg>}
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          className="table-items-menu"
        >
            {customItems ? customItems() 
            : options.map((option: any, i: number) => (
                <MenuItem key={i} onClick={handleClose}>
                  <button className="w-full h-full text-left py-3 px-3" onClick={option.onClick}>{option.label}</button>
                </MenuItem>
            ))}
        </Menu>
      </div>
    )
}

export default DropDownWithDotsMaterial;