import { proxyDataService } from "@/graphql/mutations/proxyDataService"
import { graphQueries } from "@/helpers"

export const getLanguagesOptions = async (): Promise<{ value: any, label: string }[] | null> => {
    const response = await graphQueries.sendRequest(proxyDataService, {
        path: "/common_data/lang",
        method: "GET"
    })

    const { proxyDataService: languages } = response || {};
    
    if (!languages) {
        return null;
    }

    return languages.map((lang: {alpha2: string, English: string}) => ({value: lang.alpha2, label: lang.English}));
    
}

export const getEducationsOptions = async (): Promise<{ value: any, label: string }[] | null> => {
    const response = await graphQueries.sendRequest(proxyDataService, {
        path: "/common_data/academic_degrees",
        method: "GET"
    })

    const { proxyDataService: educations } = response || {};
    
    if (!educations) {
        return null;
    }

    return educations.map((degree: {abbreviation: string, name: string}) => ({value: degree.abbreviation, label: degree.name}));
    
}