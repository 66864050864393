import { Autocomplete, AutocompleteChangeReason, AutocompleteProps, Chip, Popper, styled, TextField, TextFieldProps, createFilterOptions, FilterOptionsState, AutocompleteRenderGetTagProps, AutocompleteRenderInputParams, SxProps, Theme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './style.css'
import Label from "../Label/Label";
import { ReactNode, useEffect, useState } from "react";
import { removeDuplicates } from "@/helpers";

const filter = createFilterOptions();

export const StyledTextInput = styled(TextField)({
    fontSize: "12px",
    fontFamily: "Mulish",
    'input': {
        fontSize: "12px",
        fontFamily: "Mulish",
        minWidth: "200px !important",
        width: "100%"
    },
    "&.Mui-focused": {
        border: "none",
        outline: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        outline: "none"
    },
    "& .MuiOutlinedInput-root": {
        padding: "0 !important",
        alignSelf: "center",
        height: "100%",
    }
});

const StyledChip = styled(Chip)({
    backgroundColor: "#000",
    border: "1px solid #333333",
    borderRadius: "50px",
    padding: "6px 14px",
    color: "#fff",
    fontFamily: "Mulish",
    height: "unset",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
        color: "#fff",
        margin: "0",
        fontSize: "14px"
    },
    "& .MuiSvgIcon-root:hover": {
        color: "#fff",
        margin: "0",
        fontSize: "14px"
    },
    "& span": {
        paddingLeft: "0px",
        fontSize: "12px",
    }
})

const StyledAutocomplete = styled(Autocomplete)({
    border: "1px solid #999999",
    borderRadius: "12px",
    flexWrap: "wrap",
    display: "flex",
    minHeight: "45px",
    "& .MuiAutocomplete-endAdornment": {
        display: "none",
    },
    "& .MuiFormControl-root": {
        width: "fit-content !important"
    }
});

const StyledPopper = styled(Popper)({
    // marginTop: "4px !important",
    "& ul" : {
        padding: "0 !important",
        "& li": {
            fontSize: "14px",
            fontFamily: "Mulish",
        }
    }
})

interface IAutocomplete extends Partial<AutocompleteProps<any, any, any, any>>{
    InputProps?: TextFieldProps;
    options: any[];
    getOptionLabel: (option: any) => string;
    formatFreeSoloOption?: (freeSoloOption: string) => any 
    label?: string;
    clearOptionOnClose?: boolean;
    renderCustomInput?: (params: AutocompleteRenderInputParams, setInpuValue: (val: string) => void ) => ReactNode;
    chipStyle?: SxProps<Theme>;
    autocompleteStyle?: SxProps<Theme>;
}

const AutocompleteCustom: React.FunctionComponent<IAutocomplete> = ({
    InputProps, 
    label, 
    options, 
    limitTags, 
    clearOptionOnClose, 
    getOptionLabel, 
    onClose,
    onOpen,
    formatFreeSoloOption, 
    onChange, 
    renderCustomInput, 
    chipStyle,
    autocompleteStyle,
    ...rest
}): JSX.Element => {

    const [limitTagsNum, setLimitTagsNum] = useState<number | undefined>(limitTags);

    const [initialOptions, setOptions] = useState<any[]>(options);

    const [inputValue, setInputValue] = useState<string>("");

    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        if(rest.loading === true) {
            setOptions(removeDuplicates(options, JSON.stringify))
        }
    }, [options])

    useEffect(() => {
        if(clearOptionOnClose && !open) {
            setOptions([])
        }
    }, [open])
    
    const handleChange = (e: React.SyntheticEvent<Element, Event>,v: any[], r: AutocompleteChangeReason ): void => {

        const isInvalidChange = (r === "createOption" && v.find(o => typeof o === "string")) ? true : false;
        if(isInvalidChange) return;

        let values = v;
        const needResetInputValue = r === "createOption" || r === "selectOption";
        const isCreateNewOption = r === "selectOption" && rest.freeSolo === true && v?.find(o => o.freeSolo) ;
        
        if(isCreateNewOption) {
            const newOption = v?.find(o => o.freeSolo) 
            const formattedOption = formatFreeSoloOption?.(newOption) 
            if(formattedOption){
                values = values.map(o => o.freeSolo ? formatFreeSoloOption?.(o) : o)
                setOptions([...(initialOptions ?? []), formattedOption])
            }            
        }                

        if(needResetInputValue) {
            setInputValue("")
        }

        onChange?.(e,values,r)
    }

    const filterOptions = (options: unknown[], params: FilterOptionsState<unknown>): unknown[] => {
        const filtered = filter(options, params);
        
        if (params.inputValue !== "") {
            filtered.push({
                freeSolo: true,
                freeSoloText: `Add "${params.inputValue}"`,
                value: params.inputValue,
                label: params.inputValue
            }); 
        }
        
        return filtered;
    }

    const ghandleGetOptionLabel = (option: any): string => {
        if(option.freeSolo && option.freeSoloText) return option.freeSoloText;
        return getOptionLabel(option)
    }

    const renderTags = (value: unknown[], getTagProps: AutocompleteRenderGetTagProps ): JSX.Element => {
        const numberOfOptions = value.length;

        return (
            <>
            {value.slice(0, limitTagsNum ?? numberOfOptions).map((option: any, index: number) => (
                <StyledChip  
                deleteIcon={<CloseIcon />}
                sx={chipStyle}
                label={option.freeSolo && option.freeSoloText ? option.freeSoloText : getOptionLabel(option)}
                {...getTagProps({ index })}/>
            ))}
            {limitTagsNum && 
            (numberOfOptions > limitTagsNum) &&
            <div 
            onClick={() => setLimitTagsNum(numberOfOptions)} 
            className="custom-show-more-chip">
                {`+ ${numberOfOptions - limitTagsNum}`}
            </div>}
            </>
        )
    }

    const handleRenderInput = (params: AutocompleteRenderInputParams, setInputValue: (value: string) => void): JSX.Element => (
        <StyledTextInput 
        onKeyDown={(event: any) => {
            if (event.key === 'Backspace') {
                event.stopPropagation();
            }
        }}
        onChange={(event) => setInputValue(event.target.value)}
        {...params}
        {...InputProps}
        />
    )

    return (
        <div>
        {label &&
        <Label>{label}</Label>}     
        <div className="styled-autocomplete-wrapper">
            <StyledAutocomplete
            sx={autocompleteStyle}
            id="combo-box-demo"
            getOptionLabel={ghandleGetOptionLabel}
            isOptionEqualToValue={(option: any, value: any) => JSON.stringify(option.value || option) === JSON.stringify(value.value || value)}
            options={initialOptions}
            onChange={(e, v: any, r) => handleChange(e,v,r)}
            disableClearable={true}
            inputValue={inputValue}
            multiple={true}
            open={open}
            onOpen={(e) => {
                setOpen(true)
                onOpen?.(e)
            }}
            onClose={(e, r) => {
                setOpen(false)
                onClose?.(e, r)
            }}
            PopperComponent={(props) => <StyledPopper {...props} placement={"bottom"}/>}
            filterOptions={rest.freeSolo ? filterOptions : undefined}
            renderTags={renderTags}
            renderInput={(params) => renderCustomInput ?
                renderCustomInput(params, setInputValue) 
                :
                handleRenderInput(params, setInputValue)
            }
            renderOption={(props, option) => (
                <li {...props} key={`${getOptionLabel(option)}-${JSON.stringify(option)}`}>
                    {getOptionLabel(option)}
                </li>
            )}
            {...rest}
            />
        </div>
        </div>
    )
}

export default AutocompleteCustom;