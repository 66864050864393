export const getCompanyDefaultInfo = `
query Query($companyId: UUID!) {
	getDataServiceInfo(companyId: $companyId) {
		hq
		profilePictureUrl
		linkedinUrl
		name
		location
		employeesRange
		yearFounded
		description
		industry
		companyWebsite
	}
}`
