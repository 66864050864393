import ArrayControlForm from "@/components/WizardComponents/ArrayControlForm/ArrayControlForm";
import Dropdown from "@/components/WizardComponents/Dropdown/Dropdown";
import TextArea from "@/components/WizardComponents/TextArea/TextArea";
import WizardStepTemplate from "@/components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { IWizardStep } from "../../interfaces";

import './style.css';

export const dayInALifeOptions = [
    { value: "Kickoff", label: "Kickoff" },
    { value: "Morning", label: "Morning" },
    { value: "Lunch", label: "Lunch" },
    { value: "Afternoon", label: "Afternoon" },
    { value: "Until evening/night", label: "Until evening/night" }
]

const TITLE = `Describe “A Day in the Life Of”`;
const DESCRIPTION  = "This is the most compelling part for the Talent! It's usually the deciding factor for choosing one company over another. Take this chance to be transparent and share never-before-seen details of life at the company."

const DayInALifeStep: React.FunctionComponent<IWizardStep> = ({ formik }) => {
    const { values, setFieldValue, handleChange } = formik;
    const { dayInALife } = values;

    return (
        <WizardStepTemplate
        title={TITLE}
        description={DESCRIPTION}>
            <ArrayControlForm 
            setFieldValue={setFieldValue} 
            header={"Day In A Life"} 
            fieldName={"dayInALife"} 
            values={dayInALife ?? []} 
            defaultItemWhenAdded={{}} 
            addButtonText={"+ Add an event"}
            Elements={(i) => (
                <div className="day-in-a-life-wrapper">
                    <Dropdown 
                    style={{ width: "200px" }}
                    uiType="white-bordered"
                    options={dayInALifeOptions}
                    name={`dayInALife[${i}].time`}
                    placeholder={"Choose one..."}
                    value={dayInALife?.[i]?.time}
                    onChange={handleChange}/>
                    <TextArea
                    style={{     
                        width: "545px",
                        height: "100px",
                        marginTop: "8px",
                        // marginBottom: "20px",
                    }}
                    placeholder="Describe the event" 
                    uiType="white-bordered"
                    name={`dayInALife[${i}].description`}
                    onChange={handleChange}
                    value={dayInALife?.[i]?.description ?? ""}/>
                </div>
            )}/>
        </WizardStepTemplate>
    )
}

export default DayInALifeStep;