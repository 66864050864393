import { createReportRecommendation, deleteReportRecommendation, updateReportRecommendation } from "@/graphql/mutations/recommendations";
import { gatAllReportRecommendations } from "@/graphql/queries/recommendations";
import { graphQueries } from "@/helpers";
import { useEffect, useState } from "react";
import { ICreateRecommendation, IDeleteRecommendation, IRecommendation, IUpdateRecommendation } from "./data/types";

const useRecommendations = ({ companyId }: { companyId?: string }): {
    recommendations: IRecommendation[];
    isLoadingRecommendations: boolean;
    isUpdatedingRecommendations: boolean;
    isCreatingRecommendations: boolean;
    isDeletingRecommendations: boolean;
    fetchRecommendations: () => Promise<IRecommendation[]>;
    createRecommendation: (data: ICreateRecommendation) => Promise<{ id: string }>;
    updateRecommendation: (data: IUpdateRecommendation) => Promise<{ response: string }>;
    deleteRecommendation: (data: IDeleteRecommendation) => Promise<{ response: string }>;

} => {

    const [recommendations, setRecommendations] = useState<IRecommendation[]>([]);

    const [isLoadingRecommendations, setIsLaodingRecommendations] = useState<boolean>(false);
    const [isUpdatedingRecommendations, setIsUpdatedingRecommendations] = useState<boolean>(false);
    const [isCreatingRecommendations, setIsCreatingRecommendations] = useState<boolean>(false);
    const [isDeletingRecommendations, setIsDeletingRecommendations] = useState<boolean>(false);

    useEffect(() => {
        fetchRecommendations()
    }, []);

    const fetchRecommendations = async (): Promise<IRecommendation[]> => {
        setIsLaodingRecommendations(true);
        const { getReportRecommendations: response } = await graphQueries.sendRequest(gatAllReportRecommendations, { companyId });
        setRecommendations(response)
        setIsLaodingRecommendations(false);

        return response ?? [];
    }

    const createRecommendation = async (data: ICreateRecommendation): Promise<{ id: string }> => {
        setIsCreatingRecommendations(false);
        const { createReportRecommendation: response } = await graphQueries.sendRequest(createReportRecommendation, data);
        const newRecommendation: IRecommendation = {
            ...data,
            UserId: data.userId,
            CompanyId: data.companyId,
            id: response.id 
        }
        setRecommendations(prev => [ newRecommendation, ...prev ])
        setIsCreatingRecommendations(false);
        
        return response
    }

    const updateRecommendation = async (data: IUpdateRecommendation): Promise<{ response: string }> => {
        setIsUpdatedingRecommendations(true);
        const { updateReportRecommendation: response } = await graphQueries.sendRequest(updateReportRecommendation, data);
        setRecommendations(prev => {
            return prev.map(r => 
                r.id === data.reportRecommendationId ? 
                { ...data, id: r.id } : r)
        });
        setIsUpdatedingRecommendations(false);

        return response;
    }

    const deleteRecommendation = async (data: IDeleteRecommendation): Promise<{ response: string }> => {
        setIsDeletingRecommendations(true);
        const { deleteReportRecommendation: response } = await graphQueries.sendRequest(deleteReportRecommendation, data);
        setRecommendations(prev => prev.filter(r => r.id !== data.reportRecommendationId))
        setIsDeletingRecommendations(false)
        return response;
    }

    return {
        recommendations, 
        isLoadingRecommendations,
        isCreatingRecommendations,
        isUpdatedingRecommendations,
        isDeletingRecommendations,
        fetchRecommendations,
        createRecommendation,
        updateRecommendation,
        deleteRecommendation
    }
}

export default useRecommendations;