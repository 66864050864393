import CloseIcon from '@mui/icons-material/Close';
export interface DeleteModalMessageProps {
  title?: string;
  content?: string;
  buttonApprove?: string;
  buttonCancel?: string;
  withCloseButton?: boolean;
  onApprove?: () => void;
  onClose?: () => void;
}
const Defaults: Partial<DeleteModalMessageProps> = {
  title: 'Are you sure you want to delete this item ?',
  content: 'This action cannot be revert',
  buttonCancel: 'Cancel',
  buttonApprove: 'Delete'
};
const DeleteModalMessage: React.FunctionComponent<DeleteModalMessageProps> = ({
  title, content, buttonApprove, buttonCancel, withCloseButton, onApprove, onClose
}) => {
  return <div className='p-4 text-black'>
    <div className='flex justify-between'>
      <span className='text-xl mt-3 mr-20'>{title || Defaults.title}</span>
      { withCloseButton && <CloseIcon onClick={onClose} className='cursor-pointer' fontSize='small'/> }
    </div>
    <div className='text-gray-700 my-2'>{ content || Defaults.content }</div>
    <div className='flex items-center justify-center mt-8'>
    <button className='mr-4 border rounded py-2 px-4 hover:bg-gray-100' 
        onClick={onClose}>{ buttonCancel || Defaults.buttonCancel }</button>
      <button className='mr-4 border rounded py-2 px-4 bg-gray-800 text-white hover:bg-black' 
        onClick={onApprove}>{ buttonApprove || Defaults.buttonApprove }</button>
    </div>
  </div>
}

export default DeleteModalMessage;