import './style.css';
import Modal from "@/components/WizardComponents/Modal/Modal";

const PreviewModal: React.FunctionComponent<{
    open: boolean;
    onClose: () => void;
    onClickReturnToAllPositions: () => void;
    onClickPreview: () => void;
}>  = ({
    open,
    onClose,
    onClickReturnToAllPositions,
    onClickPreview,
}): JSX.Element => {

    return (
        <Modal 
        openModal={open} 
        onClose={onClose}>
            <div className="discovery-preview-modal-container">
                <h1 className="discovery-preview-modal-header">Position discovery page is ready!</h1>
                <button
                    onClick={onClickPreview}
                    className="discovery-preview-modal-start-quiz-button"
                >
                    Preview
                </button>
                <span onClick={() => onClickReturnToAllPositions()} className="discovery-preview-modal-skip-sentence">
                    Return to all simulations
                </span>
            </div>
        </Modal>

    )
}

export default PreviewModal;