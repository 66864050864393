import { IPosition, PositionSkill } from "@/interfaces/pages/positions";
import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { EditorApi } from "../../data/editor.api";
import { IAssessment, ISkillEval } from "../../data/types";

import './style.css';

const CustomSelect: React.FunctionComponent<{
    value: any,
    onSelect: (value: any) => void,
    options: { value: any, label: string }[],
    placeholder: { value: any, label: string } | null
}> = ({ value, onSelect, options, placeholder }): JSX.Element => {
    return (
        <Select
        value={value}
        onChange={(e) => onSelect(e.target.value)}
        >
            {placeholder &&
            <MenuItem value={placeholder.value} disabled style={{display: "none"}}>
                {placeholder.label}
            </MenuItem>}
            {options.map((o, i) => (
                <MenuItem value={o.value} key={i}>
                    {o.label}
                </MenuItem>
            ))}
        </Select>
    )
}

// export interface BuildingBlock {
//     id: string,
//     data: any,
//     disabled: boolean,
//     text: string,
//     value: BuildingBlockEnums,
//     subBuildingBlocks: BuildingBlock[] | []
// }

const BuildingBlocksSelect: React.FunctionComponent<{ 
    position: IPosition | null,
    buildingBlocks: IAssessment[], 
    initialId: string,
    setAssessmentToEdit: (se: ISkillEval) => void,
    setCurrentBuildingBlock: (bb: any) => void }> = 
({ buildingBlocks, initialId, setAssessmentToEdit, setCurrentBuildingBlock, position }): JSX.Element => {

    const { matchingSkills } = position || {}; 

    const [selectedBB, setSelectedBuildingBlock] = useState<Partial<IAssessment> | null>(null);
    const [selectedPositionSkill, setSelectedPositionSkill] = useState<Partial<ISkillEval> | null>(null);

    const [showSkillsSelect, setShowSkillSelect] = useState<boolean>(false);
    
    useEffect(() => {
        if(buildingBlocks) {
            const initialBuildingBlock = buildingBlocks[Number(initialId)];
            const { id } = initialBuildingBlock || {};
            // Load initial BB to edit
            if(id) {
                setSelectedBuildingBlock(initialBuildingBlock)
                onSelectBuildingBlock(initialBuildingBlock.id)
            }
        }
    }, [buildingBlocks])

    const onRegularBBSelected = (foundBuildingBlock: Partial<IAssessment>) => {
        const { metadata } = foundBuildingBlock;
        const { skillevals } = foundBuildingBlock;

        setShowSkillSelect(false)
        setCurrentBuildingBlock(() => foundBuildingBlock )
        
        if(!skillevals || skillevals.length <= 0) {
            const skillevalToCreate = [{
                necessityLevel: "none",
                proficiency: "none",
                name: metadata?.buildingblock?.id ?? "no_bb_type"
            }];
            createSkillevalsSetBBAndAssessToEdit(foundBuildingBlock.id, skillevalToCreate)
            .then((updated_bb) => {
                if(!updated_bb) return;
                setSelectedBuildingBlock(() => updated_bb[0])
                setAssessmentToEditor(updated_bb[0], skillevalToCreate[0])
            })
        }
        else {
            setAssessmentToEdit(skillevals[0])
            setSelectedPositionSkill(skillevals[0])
        }
    }

    const onSkillBBSelected = (foundBuildingBlock: Partial<IAssessment>) => {
        const { skillevals } = foundBuildingBlock;

        const skillevalsTitles = skillevals?.map(se => se.title?.toLowerCase().trim() ?? "") ?? [];
        const skillevalsToCreate = matchingSkills?.flatMap(s => 
            skillevalsTitles.includes(s.name?.toLowerCase().trim()) ? 
            [] 
            : s.name?.toLowerCase().trim()) ?? [];

        const formattedSkillevalsToCreate = skillevalsToCreate.map((s) => ({
            necessityLevel: "none",
            proficiency: "none",
            name: s
        }));
        setCurrentBuildingBlock(() => foundBuildingBlock)

        if(skillevalsToCreate.length > 0) {
            createSkillevalsSetBBAndAssessToEdit(foundBuildingBlock.id, formattedSkillevalsToCreate)
            .then((updated_bb) => {
                if(!updated_bb) return;
                setSelectedBuildingBlock(() => updated_bb[0])
                setAssessmentToEdit(updated_bb[0].skillevals[0])
                setSelectedPositionSkill({...updated_bb[0].skillevals[0], title: updated_bb[0].skillevals[0].title.length > 0 ? updated_bb[0].skillevals[0].title : "general" })
                setShowSkillSelect(true);
            })
        } 
        else {
            if(!skillevals) return;
            const newSkillevals = skillevals.map(se => ({...se, title: se.title.length > 0 ? se.title : "general"}));
            setAssessmentToEdit(newSkillevals[0])
            setSelectedPositionSkill(newSkillevals[0])
            setShowSkillSelect(true)

        }
    }

    const setAssessmentToEditor = (bb: Partial<IAssessment>, skill: Partial<PositionSkill>) => {
        const { skillevals } = bb || {};
        
        if(!skillevals)
            return null
        
        const skillToEdit = skillevals.find(se => se.title === skill.name);

        const { id } = skillToEdit || {};
        
        if(id)
            setAssessmentToEdit(skillToEdit!)
    }
    
    const createSkillevalsSetBBAndAssessToEdit = async (id: string | null | undefined, skills: Partial<PositionSkill>[] | null | undefined) => {
        if(!id || !skills) {
            console.error("[createSkillSetBBAndAssessToEdit] Invalid skill or id");
            return false
        }
        const bbs = await EditorApi.postAssessmentSkills(id, skills);

        return bbs;
    }

    const onSelectBuildingBlock = (value: any) => {        
        const foundBuildingBlock = buildingBlocks.find(bb => bb.id === value);
        
        if(!foundBuildingBlock)
            return null

        const { metadata } = foundBuildingBlock;
        const { buildingblock } = metadata || {};
        const { id: bbType } = buildingblock || {};
            
        setSelectedBuildingBlock(foundBuildingBlock);

        if(bbType === "skl") {
            return onSkillBBSelected(foundBuildingBlock);
        }
        
        onRegularBBSelected(foundBuildingBlock);
    }

    const onPositionSkillSleceted = (value: any, selectedBB: Partial<IAssessment>) => {
        
        const { skillevals } = selectedBB || {};
        const buildingBlockSkill = skillevals?.find(se => se.id === value);
        const skillExistInBuildingBlock = buildingBlockSkill !== undefined

        if(skillExistInBuildingBlock) {
            setAssessmentToEdit(buildingBlockSkill);
            setSelectedPositionSkill(buildingBlockSkill)
        }

    }

    const getSkillevalsOptions = (skillevals: ISkillEval[]): { value: string, label: string }[] => {
        const positionSkills = matchingSkills?.map(s => s.name.toLowerCase()) ?? [];
        const newSkillEvalOptions = skillevals.map(s => { 
            s = {...s, title: s.title.toLowerCase()}
            const label = s.title.length > 0 && s.title !== "general" ?
                positionSkills.includes(s.title) ? s.title : `${s.title} (Skill not in position)`
                : "general";

            return {
                value: s.id!,
                label,
            }
        })
        const sortedSkillevalsOptions = newSkillEvalOptions.sort(s => s.label === "general" ? 1 : 0);

        return sortedSkillevalsOptions;
    }

    return(
        <div className="building-blocks-dropdowns">
            { selectedBB &&
            <CustomSelect 
            value={selectedBB.id} 
            onSelect={onSelectBuildingBlock}
            options={
                buildingBlocks
                    .map((bb) => ({ value: bb?.id, label: bb?.metadata?.buildingblock?.name }))
            } 
            placeholder={null}/>}
            {showSkillsSelect && selectedBB?.skillevals && selectedBB.skillevals.length > 1 && selectedBB &&
            <CustomSelect 
            value={selectedPositionSkill ? selectedPositionSkill?.id : "placeholder"} 
            onSelect={(value) => onPositionSkillSleceted(value, selectedBB)} 
            options={getSkillevalsOptions(selectedBB.skillevals)} 
            placeholder={{ value: "placeholder", label: "Skills" }}/>}
        </div>
    )
}

export default BuildingBlocksSelect;