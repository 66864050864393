import HelpIcon from '@mui/icons-material/Help';
import { HtmlTooltip } from '@/components/WizardComponents/CustomTooltip/CustomTooltip';

import './style.css';
import { TypeOfRelevency } from '../../data/report.utils';

const relevancyDetails = {
    [TypeOfRelevency.STAR]: {
        label: 'is a Star!',
        title: "A Star is the rarest Talent.",
        description: "Candidates possess the skills to knock job duties out of the park, the accomplishments that prepare them for the role's responsibilities, and a highly compatible Workstyle with yours. This is a trifecta for success!"
    },
    [TypeOfRelevency.GEM]: {
        label: 'is a Gem!',
        title: "A Gem is the purest Talent.",
        description: "Candidates don't have the ideal professional experience or education, yet proved they have the skills to deliver the goods. Their Workstyle is in sync with yours, forecasting a thriving work relationship that will overcome roadblocks and enjoy success."
    },
    [TypeOfRelevency.QUALIFIED]: {
        label: 'is Relevant!',
        title: "Heads up! High potential is identified!",
        description: "This candidate seems to be well qualified and compatible but might need guidance in terms of hard skills required for the position."
    }
}

const CandidateNameAndRank:React.FunctionComponent<{
    candidateName?: string;
    candidateRelevancy?: TypeOfRelevency;
    candidateRank?: number;
    positionCandidatesCount?: number;
}> = ({ 
    candidateName,
    candidateRelevancy,
    // candidateRank,
    // positionCandidatesCount
 }):JSX.Element => {
    return (
        <div className='candidate-name-and-rank-container'>
            {candidateName &&
            <div className='candidate-name-definition'>
                {candidateRelevancy && candidateRelevancy !== TypeOfRelevency.NOTQUALIFIED ?
                <HtmlTooltip
                title={<div className='candidate-relevancy-tooltip'>
                            <span>{relevancyDetails[candidateRelevancy]?.title}</span>
                            <p>
                                {relevancyDetails[candidateRelevancy]?.description}
                            </p>
                        </div>}>
                <h1 className='candidate-name'>
                    {candidateName}
                    <span>
                        {`${relevancyDetails[candidateRelevancy]?.label}`}
                    </span>
                </h1>
                </HtmlTooltip> 
                :
                <h1 className='candidate-name'>
                    {candidateName}
                </h1>}

                {candidateRelevancy && candidateRelevancy !== TypeOfRelevency.NOTQUALIFIED &&
                <HelpIcon className='question-icon'/>}

            </div>}

            {/* {candidateRank && positionCandidatesCount &&
            <span className='candidate-rank'>
                {`Ranked ${candidateRank} of ${positionCandidatesCount}`}
            </span>} */}
        </div>
    )
} 

export default CandidateNameAndRank;