import protectionIcon from '../icons/comprofiles-icons/Protection.svg';
import controlIcon from '../icons/comprofiles-icons/Control.svg';
import collaborationIcon from '../icons/comprofiles-icons/Collaboration .svg';
import ideasIcon from '../icons/comprofiles-icons/Ideas.svg';
import competiotionIcon from '../icons/comprofiles-icons/Competition .svg';
import stabilityIcon from '../icons/comprofiles-icons/Stability .svg';
import innovationIcon from '../icons/comprofiles-icons/Innovation.svg';
import facilitationIcon from '../icons/comprofiles-icons/Faciliation.svg';
import safetyIcon from '../icons/comprofiles-icons/Safety .svg';

export enum ComProfileLabels {
    RV = "Protective Authority",
    SV = "Control",
    R = "Relationships & People",
    I = "Ideas",
    V = "Competition",
    S = "Structures",
    IV = "Innovation",
    RI = "Facilitation",
    RS = "Safety"
}

export enum ComProfile {
    RV = "RV",
    SV = "SV",
    R = "R",
    I = "I",
    V = "V",
    S = "S",
    IV = "IV",
    RI = "RI",
    RS = "RS"
}

export const comProfileToSrcIcon: any = {
    "RV": protectionIcon,
    "SV": controlIcon,
    "R": collaborationIcon,
    "I": ideasIcon,
    "V": competiotionIcon,
    "S": stabilityIcon,
    "IV": innovationIcon,
    "RI": facilitationIcon,
    "RS": safetyIcon
}

export enum TypeOfRelevency {
    STAR = "star",
    GEM = "gem",
    QUALIFIED = "relevant",
    NOTQUALIFIED = "notQualified"
}

export enum ChannelValues { 
    LOW = "low",
    HIGH = "high",
    VERYHIGH = "veryHigh",
    MEDIUM = "medium",
    NO_BLOCK = "noBlocks"
}

export enum ChannelTypes { 
    COMPATIBILITY = "compatibility",
    QUALIFICATION = "qualification",
    SKILLFULLNESS = "skillfullness",
}

export const comProfileToLabel: any = {
    [ComProfile.RV]: ComProfileLabels.RV,
    [ComProfile.SV]: ComProfileLabels.SV,
    [ComProfile.R]: ComProfileLabels.R,
    [ComProfile.I]: ComProfileLabels.I,
    [ComProfile.V]: ComProfileLabels.V,
    [ComProfile.S]: ComProfileLabels.S,
    [ComProfile.IV]: ComProfileLabels.IV,
    [ComProfile.RI]: ComProfileLabels.RI,
    [ComProfile.RS]: ComProfileLabels.RS,
}
