/**
 *
 *  @module src/pages/Permissions/usePermissionsPage
 *
 *  @description permissions page logic
 *
 *  @author diegoulloao
 *
 */

// React
import { useState } from "react"

// Toast
import { toast } from "react-toastify"

// Auth
import { useAuth, IAuth } from "@auth"

// Mutations
import { updateUserProfile } from "@mutations/updateUserProfile"

// Intefaces
import { IUser } from "@interfaces/pages/permissions"
import { graphQueries } from "@/helpers"

// Shared Interfaces
export interface IPermissionsPage {
  showModal: boolean
  userToDelete: IUser | null
  setShowModal: React.Dispatch<boolean>
  deleteUserRequest: (user: IUser) => void
  setUserToDelete: React.Dispatch<IUser | null>
  fetcher: (query: any, page: number, limit: number) => Promise<{ items: any; count: any; }> // TODO: fix type !!
}

/**
 *
 *  Logic: Permissions Page
 *  @description contains all Permissions page logic
 *
 */
const usePermissionsPage = (): IPermissionsPage => {
  // States
  const [showModal, setShowModal] = useState<boolean>(false)
  const [userToDelete, setUserToDelete] = useState<IUser | null>(null)

  // Auth
  const auth: IAuth = useAuth()

  // Fetcher
  // eslint-disable-next-line
  const fetcher = async (query: any, page: number, limit: number) => {
    return await graphQueries.sendRequest(query, {
      "getUsersCompanyId": auth.company?.id,
      "getUsersSizeOfPage": limit,
      "getUsersPage": page
    }).then(({ getUsers }: any) => (
      {
        items: getUsers.users,
        count: getUsers.totalCount
      }
    ))
  }

  // Delete User Request
  const deleteUserRequest = async (user: IUser): Promise<void> => {

    // We make the request to delete user in API
    try {
      const response = await graphQueries.sendRequest(updateUserProfile, {
        updateUserUserId: user.id,
        updateUserDeleted: true
      })

      toast(response.updateUser.message, { containerId: "default" })

    } catch (error: any) {
      console.error(error)
    }

    // In success case we hide the modal
    setShowModal(false)
  }

  return {
    fetcher,
    showModal,
    setShowModal,
    userToDelete,
    setUserToDelete,
    deleteUserRequest
  }
}

export default usePermissionsPage
