import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';
// import * as React from 'react';
import * as SurveyKo from 'survey-knockout';
import * as SurveyJSCreator from 'survey-creator';
import 'survey-creator/survey-creator.css';
import 'survey-react/survey.css';
import 'jquery-ui/themes/base/all.css';
import 'nouislider/dist/nouislider.css';
import 'select2/dist/css/select2.css';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';

import 'jquery-bar-rating/dist/themes/css-stars.css';
import 'jquery-bar-rating/dist/themes/fontawesome-stars.css';

// import $ from 'jquery';

import 'jquery-ui/ui/widgets/datepicker.js';
import 'select2/dist/js/select2.js';
import 'jquery-bar-rating';

//import "icheck/skins/square/blue.css"
import 'pretty-checkbox/dist/pretty-checkbox.css';

// eslint-disable-next-line
// {@ts-expect-error
// import * as widgets from 'surveyjs-widgets';
import { MediaUploader } from '@/helpers/mediaUploader';
import { EditorSurveyUtils } from './editorSurveyUtils';
import { useContext, useState } from 'react';
import AuthContext from '@/auth/context';
import ImageCropper from './imageCropper';
import { QuestionsAxis } from '../../constants';

const ADD_TO_HIGHLIGHTS_PROPERTY_NAME = 'add-to-highlights';
const PANEL_TYPES_PROPERTY_NAME = 'panel-type';
const UNBOXABLE_PROPERTY_GROUP_NAME = 'Unboxable';
enum PANEL_TYPES {
  STICKY_MEDIA = 'Sticky First Question',
  GROUP = 'Grouped Questions',
}
let currentImageQuestionToCrop: any = null;

// for linting
void EditorSurveyUtils;
SurveyJSCreator.StylesManager.applyTheme('stone');

/* // Niv: I removed those, since it seems they don't do anything
//widgets.icheck(SurveyKo, $)
widgets.prettycheckbox(SurveyKo);
widgets.select2(SurveyKo, $);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo, $);
widgets.jqueryuidatepicker(SurveyKo, $);
widgets.nouislider(SurveyKo);
widgets.select2tagbox(SurveyKo, $);
//widgets.signaturepad(SurveyKo)
widgets.sortablejs(SurveyKo);
//widgets.ckeditor(SurveyKo)
widgets.autocomplete(SurveyKo, $);
widgets.bootstrapslider(SurveyKo);
*/
SurveyKo.Serializer.addProperty('question', {
  name: 'annotations:string',
  category: 'general',
});
SurveyKo.Serializer.addProperty("question", {
  name: "axis:string",
  displayName: 'Choose Question Axe',
  choices: Object.values(QuestionsAxis),
  category: 'general'
});
SurveyKo.Serializer.addProperty('question', {
  name: 'hrmTitle:string',
  category: 'general',
});

SurveyKo.Serializer.addProperty('page', {
  name: 'annotations:string',
  category: 'general',
});

SurveyKo.Serializer.addProperty("question", {
  name: `instructors`,
  type: "itemvalues",
  category: "Instructors",
})

SurveyKo.Serializer.addClass("itemvalues_ex", 
[
  {name: "instructorType", choices: [ "Boolean", "Likert", "Description" ] },
  { name: "value", 
    type: "string",
    visible: false,
    default: false
  }
], 
null as any, "itemvalue");
SurveyKo.Serializer.findProperty("question", "instructors").type = "itemvalues_ex[]"


// Add option to add the question to "highlights":
SurveyKo.Serializer.addProperty('question', {
  name: `${ADD_TO_HIGHLIGHTS_PROPERTY_NAME}:boolean`,
  //default: `${questionHumanQualities.NONE}`,
  category: UNBOXABLE_PROPERTY_GROUP_NAME,
});

// Add "Panel Type" to panel's properties:
SurveyKo.Serializer.addProperty('panel', {
  name: `${PANEL_TYPES_PROPERTY_NAME}:dropdown`,
  default: `${PANEL_TYPES.STICKY_MEDIA}`, // note!: when assigning default value, it's not saved in the JSON.
  choices: EditorSurveyUtils.enumToTextArray(PANEL_TYPES),
  category: UNBOXABLE_PROPERTY_GROUP_NAME,
});

// make the "defaultValue" of files be visible (it's not visible by default), so one can upload a file to be shown in the discovery:
SurveyKo.Serializer.findProperty('file', 'defaultValue').visible = true;
// make Panels and questions name readOnly:
SurveyKo.Serializer.findProperty('question', 'name').readOnly = true;
SurveyKo.Serializer.findProperty('panel', 'name').readOnly = true;

let nextContainerId = 0;

const EditorSurveyCreator: React.FunctionComponent<{
  survey: any;
  onSave: (survey: any) => Promise<boolean>;
}> = ({ survey, onSave }) => {
  //const surveyCreatorContainer = 'surveyCreatorContainer'
  const options = {
    showEmbededSurveyTab: false,
    isAutoSave: true,
    showPagesToolbox: false,
    showState: true,
    showJSONEditorTab: false,
    showLogicTab: false,
    showTestSurveyTab: false,
    haveCommercialLicense: true,
  };

  const [surveyCreatorContainerId] = useState<string>(
    `surveyCreatorContainer${nextContainerId++}`,
  );
  const { settings } = useContext(AuthContext);
  const [jsonSurvey, setJsonSurvey] = useState(null);
  const [onCloseCrop, setOnCloseCrop] = useState(false);
  const [imageCropOpen, setImageCropOpen] = useState(false);
  const [imageToCropLink, setImageToCropLink] = useState('');
  const surveyCreator = new SurveyJSCreator.SurveyCreator(null, options);

  //surveyCreator.propertyGridObjectEditorModel
  // surveyCreator.survey.JsonObject.metaData.addProperty("question", {
  //   name: "tagbox:set",
  //   choices: [1, 2, 3, 4, 5]
  // });
  // SurveyKo.SurveyQuestionEditorDefinition.definition[
  //   "question"
  // ].properties.push("tagbox");
  // surveyCreator.survey.editorLocalization.getLocale("").pe.tagbox = "Tagbox";

  // make sure new questions gets a unique name when created:

  // @ts-expect-error "interrace probably exists"
  surveyCreator.onQuestionAdded.add((sender, options) => {
    const q = options.question;
    //q.title = 'Question Title'; // niv: this creates a bug:  changing the question type destroys the original title, so I commented it.
    q.name = uuid();
    q.hrmTitle = q.title;
  });

  // create a unique panel name:
  // @ts-expect-error "interrace probably exists"
  surveyCreator.onPanelAdded.add((sender, options) => {
    const p = options.panel;
    if (!options.panel.title) {
      // if there is no title, create a default one:
      p.title = 'Panel Title';
    }
    p.name = uuid();
  });

  /**
   * Handle copy button pressed on question or panel
   * copy it to the clipboard
   * @param objectName the object name to find in the Survey and copy to the clipboard
   * @returns null
   */
  function copyElementToClipboard(objectName: string) {
    // try to find out if this question name is a panel:
    const panel = surveyCreator.survey.getPanelByName(objectName);
    if (panel) {
      // this is a panel!
      EditorSurveyUtils.copyPanelToClipboard(panel);
      return;
    }
    const question = surveyCreator.survey.getQuestionByName(objectName);
    if (!question) {
      console.error(
        "Copy failed: Couldn't find object's name in survey: ",
        objectName,
      );
      return;
    }
    // this is a question
    EditorSurveyUtils.copyQuestionToClipboard(question);
  }

  // mutate the item's element menu (the small UI that appears above the question when you choose it):
  // 1) Delete the original "Copy" button - it will become obsolete because of the new copy paste feature
  // 2) Add a new button into element menu, to copy whole question or panel to the clipboard
  // @ts-expect-error "interrace probably exists"
  surveyCreator.onDefineElementMenuItems.add(function (editor, options) {
    // remove original "Copy" and "Add to toolbox" buttons:
    options.items = options.items.filter((item: any) => {
      return item.text != 'Copy' && item.text != 'Add to toolbox';
    });
    // add the new "copy to clipboard" button:
    options.items.unshift({
      name: 'copy',
      text: 'Copy to clipboard (CTRL+c)',
      onClick: function (obj: any) {
        const objectName = obj.name;
        copyElementToClipboard(objectName);
      },
    });
    const question = options?.obj;
    const questionType = question?.getType();
    if (questionType === 'image') {
      // console.log(question);

      // crop button to in image's element menu is invisible :=(
      // temporary solution: use the removed "addtotoolbox" icon:
      options.items.unshift({
        name: 'addtotoolbox',
        text: 'Crop Image',
        onClick: function (obj: any) {
          const objectName = obj.name;
          cropImageByQuestionName(objectName);
        },
      });
    }
  });

  /**
   * pastes a question into the current SurveyJS page.
   * question.name should contain the question's type
   * @returns
   */
  async function pasteElement() {
    const text = await EditorSurveyUtils.getClipboard();
    let elements: any[] = [];
    try {
      const element = JSON.parse(text);
      if(Array.isArray(element)) {
        elements = element;
      }else{
        elements.push(element);
      }
    } catch (e) {
      console.error(
        'invalid JSON in paste action, ignoring paste action! clipboard: ',
        text,
      );
      return;
    }
    for(let element of elements) {
      console.log(element);
      // check to see if question has a name:
      // (the name suppose to contain the question's type!)
      if (!element || !element.name) {
        console.error(
          'missing question name. ignoring paste action! clipboard: ',
          element,
        );
        return;
      }
      const elementType = element.name;
      if (element.name === 'panel') {
        // change all names to new UUID's:
        element = EditorSurveyUtils.regenerateAllPanelNames(element);
      }
      const newQuestion = SurveyKo.Serializer.createClass(elementType);
      newQuestion.fromJSON(element);
      surveyCreator.survey.currentPage.addElement(newQuestion);
    }
  }

  // Add a "paste question" button to the toolbar
  surveyCreator.toolbarItems.push(
    new SurveyKo.Action({
      id: 'pasteElement',
      visible: true,
      title: 'Paste Element (CTRL+v)',
      enabled: true,
      action: function () {
        pasteElement();
      },
    }),
  );

  // catch copy (ctrl+c) and paste (ctrl+v) key down events:
  surveyCreator.onKeyDownHandler = (e: any) => {
    const ctrlKeyPressed: boolean = e && e.ctrlKey;
    if (!ctrlKeyPressed) {
      return;
    }
    // const currentQuestionName = e && e.target && e.target.dataset && e.target.dataset.name; // old way (worked for questions but not panels!)
    const selectedElement = surveyCreator.survey.selectedElement;
    const currentQuestionName = selectedElement?.name;
    switch (e.key) {
      case 'c':
      case 'C':
        if (!currentQuestionName) {
          console.log(
            'Copy shortcut: did not find a proper element to copy! cancelling copy... the copy event: ',
            e,
          );
          return;
        }
        copyElementToClipboard(currentQuestionName);
        break;
      case 'v':
      case 'V':
        pasteElement();
        break;
    }
  };

  // CKEditor: inline editing functionality:
  EditorSurveyUtils.attachEditorToSurvey(surveyCreator);

  surveyCreator.JSON = survey;
  // setJsonSurvey(survey);
  surveyCreator.showToolbox = 'right';
  surveyCreator.showPropertyGrid = 'right';
  surveyCreator.rightContainerActiveItem('toolbox');

  // useEffect(() => {
  //   if (survey) {
  //     // setJsonSurvey(survey);
  //     // surveyCreator.showToolbox = 'right';
  //     // surveyCreator.showPropertyGrid = 'right';
  //     // surveyCreator.rightContainerActiveItem('toolbox');
  //   }
  // });
  // console.log('In main func');
  // useEffect(() => {
  //   console.log('In useEffect() with []'); // happens indeed only once, even when changing tabs
  // }, []);

  useEffect(() => {
    if (jsonSurvey) {
      surveyCreator.JSON = jsonSurvey;
      // surveyCreator.showToolbox = 'right';
      // surveyCreator.showPropertyGrid = 'right';
      // surveyCreator.rightContainerActiveItem('toolbox');
    }
    // }, [jsonSurvey]);
  }, [jsonSurvey, onCloseCrop]);

  const saveMySurvey = async (saveNo: any, callback: any) => {
    const { JSON } = surveyCreator;
    EditorSurveyUtils.checkDuplicateNamesInJson(JSON);
    const uploader = new MediaUploader(settings);
    const convertToBase64Result = await EditorSurveyUtils.convertBase64ToLinks(
      JSON,
      uploader,
    );
    if (convertToBase64Result.errors.length) {
      console.error(
        'upload survey images errors',
        convertToBase64Result.errors,
      );
    }

    const result = await onSave(JSON);
    // refresh the Survey's json in case there was a base64 conversion (cause it won't update automatically):
    if (
      convertToBase64Result.processedCount > 0 ||
      convertToBase64Result.errors.length
    ) {
      surveyCreator.JSON = JSON; // working! base64 links are being refreshed to the saved links
      setJsonSurvey(JSON);
      // surveyCreator.render(surveyCreatorContainerId); // this refreshes the image preview after crop
      // surveyCreator.survey.fromJSON(JSON);
    }
    !!callback && callback(saveNo, result);
  };

  surveyCreator.saveSurveyFunc = saveMySurvey;

  setTimeout(() => {
    surveyCreator.render(surveyCreatorContainerId);
  }, 0);

  /////////////////////////////////////////////////////// Image Crop /////////////////////////////////////////////////////////////////////////

  // const handleImageCropClose = (value: string) => {
  const handleImageCropClose = (base64Crop: any) => {
    setImageCropOpen(false);
    if (
      base64Crop &&
      currentImageQuestionToCrop &&
      currentImageQuestionToCrop.imageLink
    ) {
      currentImageQuestionToCrop.imageLink = base64Crop;
    } else {
      // reload page on cancel crop dialog to fix refresh problems
      currentImageQuestionToCrop = null;
      // setOnCloseCrop(!onCloseCrop);
      //location.reload(); // this was used to refresh all window when stuff did not render correctly after crop window was opened
    }
    setOnCloseCrop(!onCloseCrop);
  };

  const cropImageByQuestionName: any = (questionName: string) => {
    const question = surveyCreator.survey.getQuestionByName(questionName);
    if (!question) {
      console.error(
        'While trying to crop image by question name, could not found question name: ',
        questionName,
      );
      return;
    }
    currentImageQuestionToCrop = question;
    const imageLink = question?.imageLink;
    console.log('crop request for question: ', imageLink);
    const { JSON } = surveyCreator;
    setJsonSurvey(JSON);
    setImageToCropLink(imageLink);
    setImageCropOpen(true);
  };

  /////////////////////////////////////////////////////// ~Image Crop /////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div id={surveyCreatorContainerId}>
        <ImageCropper
          imageLink={imageToCropLink}
          onImageCropperClose={handleImageCropClose}
          open={imageCropOpen}
        ></ImageCropper>
      </div>
    </>
  );
};

export default EditorSurveyCreator;
