import { gql } from "graphql-request"

// Get positions by company id for Positions Table
// TODO - adding isPublished JRA-874 
export const getPositions = (name: string): string => gql`
  query ${name} (
    $companyId: UUID!,
    $sizeOfPage: Int,
    $page: Int,
    $titleName: String,
    $haveCandidates: Boolean,
    $slug: String
  ) {
    getV2Positions(
      companyId: $companyId,
      sizeOfPage: $sizeOfPage,
      page: $page,
      titleName: $titleName,
      haveCandidates: $haveCandidates
      slug: $slug
    ) {
      positions {
        id
        openedBy
        openedByEmail
        department
        templates
        title
        matchingTitle
        description
        status
        numberOfCandidates
        slug
        isPublished
      }
      count
    }
  }
`

// Get positions count by company id for Positions Table
export const getPositionsCount = (name: string): string => gql`
  query ${name} (
    $companyId: UUID!,
    $sizeOfPage: Int,
    $page: Int,
    $titleName: String,
    $haveCandidates: Boolean,
    $slug: String
  ) {
    getV2Positions(
      companyId: $companyId,
      sizeOfPage: $sizeOfPage,
      page: $page,
      titleName: $titleName,
      haveCandidates: $haveCandidates
      slug: $slug
    ) {
      positions {
        id
      }
      count
    }
  }
`

// Get all positions
// TODO - adding isPublished JRA-874 
export const getAllPositions = (name: string): string => gql`
  query ${name} (
    $sizeOfPage: Int,
    $page: Int,
    $titleName: String,
    $haveCandidates: Boolean
  ) {
    getAllV2Positions(
      sizeOfPage: $sizeOfPage,
      page: $page,
      titleName: $titleName,
      haveCandidates: $haveCandidates
    ) {
      positions {
        id
        openedBy
        openedByEmail
        companyName
        department
        title
        matchingTitle
        description
        assessmentName
        assessmentId
        assessmentStatus
        isPublished
      }
      count
    }
  }
`

// Get all positions count
export const getAllPositionsCount = (name: string): string => gql`
  query ${name} (
    $sizeOfPage: Int,
    $page: Int,
    $titleName: String,
    $haveCandidates: Boolean
  ) {
    getAllV2Positions(
      sizeOfPage: $sizeOfPage,
      page: $page,
      titleName: $titleName,
      haveCandidates: $haveCandidates
    ) {
      positions {
        id
      }
      count
    }
  }
`

// Get positions by company id
export const getPositionsByCompanyId = (name: string): string => gql`
query ${name} (
  $companyId: UUID!,
  $sizeOfPage: Int,
  $page: Int,
  $titleName: String,
  $haveCandidates: Boolean,
  $assessmentStatus: String
) {
  getV2Positions(
    companyId: $companyId,
    sizeOfPage: $sizeOfPage,
    page: $page,
    titleName: $titleName,
    haveCandidates: $haveCandidates,
    assessmentStatus: $assessmentStatus
  ) {
    positions {
      id
      assessmentId
      assessmentName
      assessmentStatus
      department
      title
      description
      yearsOfExperience
      yearsOfManagementExperience
      status
      skills {
        necessityLevel
        proficiency
        name
      }
      education {
        degree
        educationType
        scale
      }
      positionFormJson
      cultureAndValue {
        value
        belief
      }
      recipients
      atsEmail
      metadata
      rolesAndResponsibilities
      eligibilities
      isPublished
      leaders
      positionImage
      slug
      comProfile
      location
      jobTypes
      templates
      educations
      qualifications
      isManager
      languages
      hobbies
      volunteering
      matchingOtherTitles
      matchingYearsOfManagementExperience
      matchingYearsOfExperience
      matchingTitle
      matchingDescription
      matchingDepartment
      matchingSkills
      matchingEducation
      matchingEligibilities
      matchingLocation
      matchingBuildingBlocks
      matchingLanguages
      matchingHobbies
      matchingVolunteering
      matchingRecipients
      matchingJobTypes
      matchingPermissions
      matchingCvKeywords
      permissions
      cvKeywords
      updatedRolesAndResponsibilities
      matchingHrms
      matchingRelatedSkills
    }
    count
  }
}`

export const getPositionTitleAndIdsByCompanyId = (name?: string): string => gql`
query ${name ?? "GetPositionTitleAndIdsByCompanyId"} (
  $companyId: UUID!,
  $sizeOfPage: Int,
  $page: Int,
  $titleName: String,
  $haveCandidates: Boolean,
  $assessmentStatus: String
) {
  getV2Positions(
    companyId: $companyId,
    sizeOfPage: $sizeOfPage,
    page: $page,
    titleName: $titleName,
    haveCandidates: $haveCandidates,
    assessmentStatus: $assessmentStatus
  ) {
    positions {
      id
      matchingTitle
    }
    count
  }
}`

// Get position by id
export const getPositionById = gql`
  query (
      $positionId: UUID!,
      $sizeOfPage: Int,
      $page: Int
    ) {
      getV2Position(
      positionId: $positionId,
      sizeOfPage: $sizeOfPage,
      page: $page
    ) {
      id
      CompanyId
      department
      title
      description
      positionImage
      status
      atsEmail
      leaders
      eligibilities
      recipients
      rolesAndResponsibilities
      dayInALife {
        time
        description
      }
      skills {
        necessityLevel
        proficiency
        name
      }
      matchingSkills
      education {
        degree
        educationType
        scale
      }
      cultureAndValue {
        value
        belief
      }
      metadata
      location
      templates
      jobTypes
    }
  }
`

// Get position by id
export const getPositionSlug = gql`
  query (
      $positionId: UUID!,
      $sizeOfPage: Int,
      $page: Int
    ) {
      getV2Position(
      positionId: $positionId,
      sizeOfPage: $sizeOfPage,
      page: $page
    ) {
      slug
    }
  }
`

export const getPositionByPositionId = (key: string): string => `
  query getV2Position_${key} ($positionId: UUID!) {
    getV2Position( positionId: $positionId, sizeOfPage: 1 page: 0) {
      positionFormJson
    }
  }
`

export const getPositionInfoById = (key: string): string => `
  query ${key} ($positionId: String) {
    genericSearchBox(table: "V2Position", column: "id", value: $positionId, sizeOfPage: 1, page: 0) {
      response
    }
  }
`

export const getComprofileByAnswers = gql`
query GetComProfile($answers: JSON) {
  getComProfile(answers: $answers) {
    fundamentalProfile {
      profile
      qualities
      strengths
      needs
      values
      workStyle
      metadata
    }
    perceivedProfile {
      profile
      qualities
      strengths
      needs
      values
      workStyle
      metadata
    }
  }
}
`