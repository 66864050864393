import { gql } from "graphql-request";

export const getAllNotifications = gql`
query GetAllNotifications($limit: Int) {
    getAllNotifications(limit: $limit) {
      notifications {
        id
        CompanyId
        V2PositionId
        eventName
        createdAt
        createdBy
        updatedAt
        changedStatus
        positionName
        positionSkills
        positionTemplates
        positionSlug
        companyName
        companyEmail
        companySlug
      }
      count
    }
}`

export const getAllNotificationsByCompanyId = gql`
query GetAllNotifications($limit: Int, $companyId: UUID!) {
  getAllNotificationsByCompanyId(limit: $limit, companyId: $companyId) {
    notifications {
      id
      CompanyId
      V2PositionId
      eventName
      createdAt
      createdBy
      updatedAt
      changedStatus
      positionName
      positionSkills
      positionTemplates
      positionSlug
      companyName
      companyEmail
      companySlug
    }
    count
  }
}
`