import { proxyDataService } from "@/graphql/mutations/proxyDataService";
import { flatten, graphQueries, removeDuplicates } from "@/helpers";
import { throttle } from "lodash";

export const searchSkillsOptions = throttle(async (searchVal: string): Promise<{label: string, value: string}[]> => {
    const { proxyDataService: data } = await graphQueries.sendRequest(proxyDataService, {
        path: "/common_data/skills?q=" + searchVal,
        method: "GET"
    });
    const skillsWithoutDuplicates = removeDuplicates(data, JSON.stringify)

    const skillsOptions = skillsWithoutDuplicates.map((s) => ({ label: s, value: s }));
    
    return skillsOptions;
}, 1000);

export const getSimilarRelatedSkills = async (skills: string[]): Promise<string[]> => {
    const {proxyDataService: response} = await graphQueries.sendRequest(proxyDataService, {
        path: "/hiring/jobs/skill_similarity",
        method: "POST",
        body: {
            skills: skills
        }
    });
    const similarSkills = response?.map((s: any) => s?.similar)
    const mergedSimilarSkills = flatten(similarSkills)
    return mergedSimilarSkills
}