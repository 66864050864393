// Styles
import React from 'react';
import { ComProfile } from '../../data/report.utils';
import './style.css';

// Interfaces
interface IComProfileMain {
    talentComProfile: ComProfile,
    hrmComProfile?: ComProfile
}

const comProfileTraingles: any = {
    "RS": ({style}: any) => <path className={style ?? "cls-1-regular"} d="M49.31,168.93,126,137.16a1.18,1.18,0,0,1,1.55,1.55L95.77,215.49a1.18,1.18,0,0,1-1.93.38l-44.92-45A1.19,1.19,0,0,1,49.31,168.93Z"/>,
    "SV": ({style}: any) => <path className={style ?? "cls-1-regular"} d="M48.83,93.56,94.07,49.13a1.18,1.18,0,0,1,1.92.4l31.59,76.25a1.18,1.18,0,0,1-1.55,1.54L49.21,95.5A1.18,1.18,0,0,1,48.83,93.56Z"/>,
    "R": ({style}: any) => <path className={style ?? "cls-1-regular"} d="M99.6,217.05l31.8-76.76a1.18,1.18,0,0,1,2.19,0l31.79,76.76a1.18,1.18,0,0,1-1.09,1.63H100.7A1.18,1.18,0,0,1,99.6,217.05Z"/>,
    "I": ({style}: any) => <path className={style ?? "cls-1-regular"} d="M140.54,131.14l76.79-31.8a1.19,1.19,0,0,1,1.64,1.09v63.62a1.18,1.18,0,0,1-1.64,1.09l-76.79-31.81A1.19,1.19,0,0,1,140.54,131.14Z"/>,
    "V": ({style}: any) => <path className={style ?? "cls-1-regular"} d="M100.91,46.31h63.16a1.19,1.19,0,0,1,1.1,1.64L133.59,124.2a1.19,1.19,0,0,1-2.19,0L99.81,48A1.19,1.19,0,0,1,100.91,46.31Z"/>,
    "S": ({style}: any) => <path className={style ?? "cls-1-regular"} d="M47.66,99.33l76.8,31.82a1.19,1.19,0,0,1,0,2.19l-76.8,31.8A1.19,1.19,0,0,1,46,164.05V100.43A1.19,1.19,0,0,1,47.66,99.33Z"/>,
    "IV": ({style}: any) => <path className={style ?? "cls-1-regular"} d="M170.92,49.13l45.24,44.44a1.19,1.19,0,0,1-.38,1.94L139,127.33a1.19,1.19,0,0,1-1.55-1.55L169,49.52A1.19,1.19,0,0,1,170.92,49.13Z"/>,
    "RI": ({style}: any) => <path className={style ?? "cls-1-regular"} d="M139,137.15,215.75,169a1.2,1.2,0,0,1,.39,1.94l-45,45a1.2,1.2,0,0,1-1.94-.39L137.4,138.7A1.18,1.18,0,0,1,139,137.15Z"/>,
}

const comProfileNames: any = {
    "RS": ({style}: any) => (
        <>
        <path className={style ?? "cls-1-regular"} d="M24.19,203.58l.7.71a2.19,2.19,0,0,0,.3,3.26c.79.79,1.72,1,2.44.29s.28-1.66-.52-2.92-1.36-2.4-.28-3.48,2.31-.73,3.48.44c1.43,1.44,1.3,2.76.38,4l-.68-.69a1.88,1.88,0,0,0-.25-2.71c-.78-.78-1.62-1-2.22-.37s-.2,1.49.49,2.52c.89,1.34,1.64,2.64.34,3.93-1.08,1.08-2.56.8-3.8-.43C23,206.48,23.09,204.8,24.19,203.58Z"/>
        <path className={style ?? "cls-1-regular"} d="M35.14,206.94l.81.81-3.64,7.87-.78-.79,1.14-2.36-2.29-2.29L28,211.31l-.74-.74Zm-4,2.86,1.88,1.88,1.27-2.59c.21-.43.56-1.06.56-1.06l0,0-1.08.55Z"/>
        <path className={style ?? "cls-1-regular"} d="M38.81,210.62l3.9,3.91-.64.63L38.9,212l-1.8,1.79,2.65,2.65-.61.61-2.65-2.64-2.71,2.7-.72-.72Z"/>
        <path className={style ?? "cls-1-regular"} d="M43.58,215.4l4.05,4.06-.63.64-3.34-3.34-1.82,1.81,3,3-.61.61-3-3-2,2,3.43,3.44-.67.66-4.15-4.16Z"/>
        <path className={style ?? "cls-1-regular"} d="M48.34,220.17l4.55,4.56-.64.63-1.91-1.91-5.12,5.11-.72-.73,5.12-5.11-1.91-1.91Z"/>
        <path className={style ?? "cls-1-regular"} d="M52.11,230.58l1.23-5.4.81.81-1.09,4.38v0l4.41-1.06.77.77-5.44,1.22-2.42,2.42-.72-.73Z"/>
        </>
    ),
    "SV": ({style}: any) => (
        <>
        <path className={style ?? "cls-1-regular"} d="M20.12,66c-1.67-1.71-1.95-4-.22-5.73A3,3,0,0,1,24,59.84l-.71.7a2.11,2.11,0,0,0-2.82.38c-1.28,1.25-1,3,.36,4.34s3.12,1.61,4.33.42a2.25,2.25,0,0,0,.11-3.25l.72-.71A3.46,3.46,0,0,1,26.94,64a3.36,3.36,0,0,1-1.14,2.31C24.15,68,21.85,67.76,20.12,66Z"/>
        <path className={style ?? "cls-1-regular"} d="M26,60.21a4,4,0,1,1,5.67-5.58c1.71,1.74,1.89,4.09.13,5.81A3.9,3.9,0,0,1,26,60.21ZM31,55.36C29.61,54,27.87,53.69,26.51,55s-1.08,3.08.25,4.44,3.07,1.67,4.44.33S32.28,56.73,31,55.36Z"/>
        <path className={style ?? "cls-1-regular"} d="M29.84,50.76l.77-.75,6.6,1.43c.49.11,1.15.33,1.15.33h0L33.69,47l.68-.67,5.7,5.8-.75.73-6.63-1.42c-.46-.1-1.13-.3-1.13-.3h0l4.67,4.76-.68.67Z"/>
        <path className={style ?? "cls-1-regular"} d="M35.22,45.48,39.81,41l.63.64-1.93,1.9,5.07,5.15-.73.72-5.07-5.16-1.93,1.9Z"/>
        <path className={style ?? "cls-1-regular"} d="M40.66,40.13l2.71-2.66a2.2,2.2,0,0,1,3.27-.09,1.91,1.91,0,0,1,.41,2.42l0,0c.8-.37,1.39-.15,2.29.65s1.31,1,1.48,1l0,0-.78.78c-.23,0-.47-.18-1.49-1.09s-1.53-.76-2.3,0l-1.62,1.58,2.41,2.45-.73.72Zm3.44,2,1.72-1.69A1.44,1.44,0,0,0,46,38.23c-.69-.71-1.36-.75-2.22.09L42,40.05Z"/>
        <path className={style ?? "cls-1-regular"} d="M48.72,37.91a3.92,3.92,0,0,1-.13-5.82c1.75-1.72,4.1-1.49,5.81.24a4,4,0,1,1-5.68,5.58Zm4.93-4.85c-1.34-1.36-3.08-1.67-4.44-.33s-1.08,3.08.26,4.44,3.07,1.67,4.43.33S55,34.43,53.65,33.06Z"/>
        <path className={style ?? "cls-1-regular"} d="M52.55,28.46l.73-.72,5,5.12,3-3,.66.67-3.77,3.7Z"/>
        </>
    ),
    "R": ({style}: any) => (
        <>
        <path className={style ?? "cls-1-regular"} d="M85.42,258.26c0-2.38,1.43-4.23,3.85-4.23a3,3,0,0,1,3.26,2.59h-1a2.11,2.11,0,0,0-2.28-1.71c-1.78,0-2.78,1.45-2.78,3.35s1.1,3.33,2.79,3.33a2.26,2.26,0,0,0,2.37-2.24h1a3.46,3.46,0,0,1-1,2.29,3.4,3.4,0,0,1-2.43.85C86.92,262.49,85.42,260.73,85.42,258.26Z"/>
        <path className={style ?? "cls-1-regular"} d="M93.65,258.26a4,4,0,1,1,4,4.24A3.9,3.9,0,0,1,93.65,258.26Zm6.91,0c0-1.9-1-3.35-2.93-3.35s-2.93,1.45-2.93,3.35,1,3.34,2.93,3.34S100.56,260.17,100.56,258.26Z"/>
        <path className={style ?? "cls-1-regular"} d="M103,254.21h1v7.18h4.25v.94H103Z"/>
        <path className={style ?? "cls-1-regular"} d="M109.5,254.21h1v7.18h4.26v.94H109.5Z"/>
        <path className={style ?? "cls-1-regular"} d="M118.29,254.21h1.15l3,8.12h-1.11l-.87-2.48h-3.23l-.88,2.48h-1Zm-.77,4.81h2.66l-.93-2.72c-.16-.46-.36-1.15-.36-1.15h0s-.21.68-.37,1.15Z"/>
        <path className={style ?? "cls-1-regular"} d="M123.53,254.21H127a2.6,2.6,0,0,1,2,.7,1.94,1.94,0,0,1,.52,1.39,1.67,1.67,0,0,1-1.31,1.7v0a2,2,0,0,1,1.64,2,2.25,2.25,0,0,1-.59,1.56,2.7,2.7,0,0,1-2,.77h-3.63Zm3.58,7.2a1.77,1.77,0,0,0,1.27-.41,1.33,1.33,0,0,0,.37-.94c0-1-.72-1.51-1.71-1.51h-2.49v2.86Zm-.26-3.73c1,0,1.58-.48,1.58-1.32s-.54-1.27-1.44-1.27h-2.44v2.59Z"/>
        <path className={style ?? "cls-1-regular"} d="M130.77,258.26a4,4,0,1,1,4,4.24A3.9,3.9,0,0,1,130.77,258.26Zm6.91,0c0-1.9-1-3.35-2.93-3.35s-2.93,1.45-2.93,3.35,1,3.34,2.93,3.34S137.68,260.17,137.68,258.26Z"/>
        <path className={style ?? "cls-1-regular"} d="M140.12,254.21h3.8a2.19,2.19,0,0,1,2.39,2.22,1.93,1.93,0,0,1-1.41,2v0c.83.29,1.09.86,1.17,2.07s.23,1.63.38,1.73v.05h-1.1c-.17-.15-.21-.45-.3-1.82-.08-1.19-.56-1.61-1.65-1.61h-2.26v3.43h-1Zm1,3.86h2.41c1.14,0,1.71-.57,1.71-1.49s-.46-1.49-1.65-1.49h-2.47Z"/>
        <path className={style ?? "cls-1-regular"} d="M150.08,254.21h1.15l3,8.12h-1.11l-.87-2.48H149l-.87,2.48h-1Zm-.77,4.81H152L151,256.3c-.16-.46-.37-1.15-.37-1.15h0l-.37,1.15Z"/>
        <path className={style ?? "cls-1-regular"} d="M153.75,254.21h6.43v.9h-2.71v7.22h-1v-7.22h-2.7Z"/>
        <path className={style ?? "cls-1-regular"} d="M161.4,254.21h1v8.12h-1Z"/>
        <path className={style ?? "cls-1-regular"} d="M163.84,258.26a4,4,0,1,1,4,4.24A3.91,3.91,0,0,1,163.84,258.26Zm6.91,0c0-1.9-1-3.35-2.93-3.35s-2.93,1.45-2.93,3.35,1,3.34,2.93,3.34S170.75,260.17,170.75,258.26Z"/>
        <path className={style ?? "cls-1-regular"} d="M173.2,254.21h1.08l3.71,5.64c.27.42.59,1,.59,1h0v-6.69h1v8.12h-1l-3.73-5.66c-.26-.39-.6-1-.6-1h0v6.67h-1Z"/>
        </>
    ),
    "I": ({style}: any) => (
        <>
        <path className={style ?? "cls-1-regular"} d="M262.73,116.37v1h-8.15v-1Z"/>
        <path className={style ?? "cls-1-regular"} d="M262.73,119.13v3c0,2.45-1.67,3.61-4.1,3.61s-4-1.24-4-3.69v-2.88ZM255.48,122c0,1.91,1.23,2.68,3.15,2.68s3.21-.69,3.21-2.6v-1.9h-6.36Z"/>
        <path className={style ?? "cls-1-regular"} d="M262.73,127.11v5.75h-.9v-4.73h-2.58v4.3h-.86v-4.3h-2.86V133h-.95v-5.89Z"/>
        <path className={style ?? "cls-1-regular"} d="M262.73,136.84V138l-8.15,3v-1.12l2.49-.87v-3.24l-2.49-.88v-1Zm-4.83-.78v2.67l2.73-.93c.46-.16,1.15-.37,1.15-.37v0l-1.15-.38Z"/>
        <path className={style ?? "cls-1-regular"} d="M257.34,141.43v1a2.18,2.18,0,0,0-2.1,2.51c0,1.13.52,1.94,1.53,1.94s1.38-1,1.7-2.44.73-2.66,2.26-2.66c1.35,0,2.16,1.11,2.16,2.76,0,2-1,2.89-2.54,3.09v-1a1.88,1.88,0,0,0,1.74-2.09c0-1.11-.47-1.84-1.31-1.84s-1.2.91-1.44,2.13c-.31,1.59-.7,3-2.54,3-1.52,0-2.38-1.24-2.38-3C254.42,142.62,255.7,141.52,257.34,141.43Z"/>
        </>
    ),
    "V": ({style}: any) => (
        <>
        <path className={style ?? "cls-1-regular"} d="M94.13,6.74c0-2.39,1.43-4.24,3.85-4.24a3,3,0,0,1,3.26,2.59h-1A2.11,2.11,0,0,0,98,3.39c-1.78,0-2.78,1.44-2.78,3.35s1.1,3.33,2.79,3.33a2.26,2.26,0,0,0,2.37-2.24h1a3.46,3.46,0,0,1-1,2.28,3.36,3.36,0,0,1-2.43.85C95.63,11,94.13,9.2,94.13,6.74Z"/>
        <path className={style ?? "cls-1-regular"} d="M102.38,6.74a4,4,0,1,1,8,0,4,4,0,1,1-8,0Zm6.91,0c0-1.91-1-3.35-2.93-3.35s-2.93,1.44-2.93,3.35,1,3.34,2.93,3.34S109.29,8.65,109.29,6.74Z"/>
        <path className={style ?? "cls-1-regular"} d="M111.73,2.68H113l2.64,6.75h0l2.61-6.75h1.33V10.8h-1V4.13h0s-.21.63-.36,1L116,10.8h-.78L113,5.15c-.16-.41-.36-1-.36-1h0V10.8h-.92Z"/>
        <path className={style ?? "cls-1-regular"} d="M121.36,2.68h3.38a2.42,2.42,0,0,1,1.93.79A2.33,2.33,0,0,1,127.22,5c0,1.49-.85,2.39-2.63,2.39h-2.21v3.4h-1Zm1,3.89h2.13c1.15,0,1.7-.58,1.7-1.51s-.61-1.49-1.64-1.49h-2.19Z"/>
        <path className={style ?? "cls-1-regular"} d="M128.38,2.68h5.74v.9H129.4V6.15h4.29V7H129.4V9.86h4.85v.94h-5.87Z"/>
        <path className={style ?? "cls-1-regular"} d="M135.14,2.68h6.43v.9h-2.7V10.8h-1V3.58h-2.71Z"/>
        <path className={style ?? "cls-1-regular"} d="M142.8,2.68h1V10.8h-1Z"/>
        <path className={style ?? "cls-1-regular"} d="M145,2.68h6.43v.9h-2.71V10.8h-1V3.58H145Z"/>
        <path className={style ?? "cls-1-regular"} d="M152.69,2.68h1V10.8h-1Z"/>
        <path className={style ?? "cls-1-regular"} d="M155.12,6.74a4,4,0,1,1,7.95,0,4,4,0,1,1-7.95,0Zm6.91,0c0-1.91-1-3.35-2.93-3.35s-2.93,1.44-2.93,3.35,1,3.34,2.93,3.34S162,8.65,162,6.74Z"/>
        <path className={style ?? "cls-1-regular"} d="M164.49,2.68h1.08l3.7,5.65c.27.42.59,1,.59,1h0V2.68h1V10.8h-1.05l-3.72-5.65c-.26-.4-.6-1-.6-1h0V10.8h-.95Z"/>
        </>
    ),
    "S": ({style}: any) => (
        <>
        <path className={style ?? "cls-1-regular"} d="M7.65,159.18v-1a2.19,2.19,0,0,0,2.09-2.51c0-1.12-.52-1.93-1.53-1.93s-1.37,1-1.69,2.43-.73,2.66-2.26,2.66c-1.34,0-2.15-1.12-2.15-2.76,0-2,1-2.88,2.54-3.08v1A1.88,1.88,0,0,0,2.91,156c0,1.11.46,1.83,1.31,1.83s1.19-.91,1.43-2.12c.31-1.58.7-3,2.53-3,1.52,0,2.37,1.24,2.37,3C10.55,158,9.28,159.09,7.65,159.18Z"/>
        <path className={style ?? "cls-1-regular"} d="M2.27,152.29v-6.43h.9v2.71h7.22v1H3.17v2.7Z"/>
        <path className={style ?? "cls-1-regular"} d="M2.27,143.35V142.2l8.12-3v1.11l-2.47.87v3.24l2.47.87v1Zm4.82.77v-2.66l-2.73.93c-.45.16-1.14.37-1.14.37v0l1.14.37Z"/>
        <path className={style ?? "cls-1-regular"} d="M2.27,138.14V134.7a2.58,2.58,0,0,1,.71-2,1.91,1.91,0,0,1,1.38-.52,1.68,1.68,0,0,1,1.71,1.31h0a2,2,0,0,1,2-1.64,2.24,2.24,0,0,1,1.55.59,2.7,2.7,0,0,1,.77,2v3.63Zm3.48-3.32c0-1-.49-1.58-1.33-1.58s-1.26.54-1.26,1.45v2.43H5.75Zm3.72-.26a1.77,1.77,0,0,0-.4-1.27,1.34,1.34,0,0,0-1-.37c-1,0-1.51.72-1.51,1.71v2.49H9.47Z"/>
        <path className={style ?? "cls-1-regular"} d="M2.27,130.6v-1h8.12v1Z"/>
        <path className={style ?? "cls-1-regular"} d="M2.27,127.85v-1H9.45v-4.26h.94v5.28Z"/>
        <path className={style ?? "cls-1-regular"} d="M2.27,121.36v-1h8.12v1Z"/>
        <path className={style ?? "cls-1-regular"} d="M2.27,119.13V112.7h.9v2.71h7.22v1H3.17v2.7Z"/>
        <path className={style ?? "cls-1-regular"} d="M7,109.1,2.27,112V110.9l3.86-2.33v0l-3.86-2.36V105.1l4.71,3h3.41v1Z"/>
        </>
    ),
    "IV": ({style}: any) => (
        <>
        <path className={style ?? "cls-1-regular"} d="M198.84,15.73l.73.72-5.69,5.8-.73-.72Z" />
        <path className={style ?? "cls-1-regular"} d="M200.79,17.65l.77.76L200.25,25c-.1.49-.31,1.16-.31,1.16v0l4.69-4.78.69.67-5.7,5.8-.74-.74,1.3-6.64c.1-.47.28-1.15.28-1.15h0l-4.68,4.75-.68-.66Z" />
        <path className={style ?? "cls-1-regular"} d="M206.56,23.31l.77.75L206,30.69c-.1.49-.31,1.16-.31,1.16l0,0,4.69-4.78.68.67-5.7,5.8-.74-.74,1.3-6.65c.1-.46.28-1.14.28-1.14v0l-4.68,4.76-.68-.67Z" />
        <path className={style ?? "cls-1-regular"} d="M209.24,31.63a4,4,0,1,1,5.68,5.58,4,4,0,1,1-5.68-5.58Zm4.93,4.84c1.34-1.36,1.62-3.1.26-4.44s-3.1-1-4.44.34-1.61,3.1-.25,4.43S212.83,37.84,214.17,36.47Z" />
        <path className={style ?? "cls-1-regular"} d="M218,34.56l.78.77L216,41c-.17.36-.53,1-.53,1v0s.65-.39,1-.56l5.65-2.92.76.74-7.72,3.8-.81-.79Z" />
        <path className={style ?? "cls-1-regular"} d="M224.72,41.15l.82.8L222,49.85l-.8-.78,1.12-2.37L220,44.43l-2.36,1.15-.74-.73Zm-3.93,2.9,1.9,1.86,1.25-2.6c.2-.44.54-1.07.54-1.07v0l-1.08.56Z" />
        <path className={style ?? "cls-1-regular"} d="M227.35,43.73l4.59,4.51-.63.64L229.38,47l-5.06,5.16-.73-.72,5.06-5.15-1.93-1.9Z" />
        <path className={style ?? "cls-1-regular"} d="M232.8,49.08l.73.72-5.69,5.8-.73-.72Z" />
        <path className={style ?? "cls-1-regular"} d="M231.7,53.69a4,4,0,1,1,5.68,5.58,4,4,0,1,1-5.68-5.58Zm4.93,4.84c1.34-1.36,1.62-3.1.26-4.44s-3.1-1-4.44.34-1.61,3.1-.25,4.43S235.3,59.9,236.63,58.53Z" />
        <path className={style ?? "cls-1-regular"} d="M241.22,57.36l.77.75-1.31,6.63c-.1.49-.31,1.16-.31,1.16h0l4.69-4.77.69.67-5.7,5.79-.74-.73,1.3-6.65c.09-.46.28-1.14.28-1.14v0l-4.68,4.76-.68-.67Z" />
        </>
    ),
    "RI": ({style}: any) => (
        <>
        <path className={style ?? "cls-1-regular"} d="M201,236.4l3.89-3.9.64.63-3.17,3.18,1.79,1.8,2.64-2.65.62.61-2.65,2.65,2.72,2.7-.73.73Z"/>
        <path className={style ?? "cls-1-regular"} d="M206.88,230.49l.81-.82,7.88,3.62-.79.79-2.37-1.14-2.29,2.3,1.14,2.37-.74.74Zm2.87,3.95,1.88-1.88L209,231.29c-.44-.21-1.07-.55-1.07-.55h0l.55,1.08Z"/>
        <path className={style ?? "cls-1-regular"} d="M212.93,230.18c-1.69-1.69-2-4-.28-5.73a3,3,0,0,1,4.14-.48l-.71.71a2.11,2.11,0,0,0-2.82.41c-1.26,1.27-.95,3,.41,4.35s3.13,1.57,4.33.37a2.26,2.26,0,0,0,.09-3.26l.71-.71a3.39,3.39,0,0,1,.93,2.3,3.35,3.35,0,0,1-1.12,2.33C217,232.1,214.67,231.92,212.93,230.18Z"/>
        <path className={style ?? "cls-1-regular"} d="M216.11,221.24l.73-.73,5.75,5.75-.72.72Z"/>
        <path className={style ?? "cls-1-regular"} d="M218.05,219.29l.72-.72,5.09,5.08,3-3,.67.67L223.81,225Z"/>
        <path className={style ?? "cls-1-regular"} d="M222.64,214.7l.72-.72,5.76,5.74-.73.72Z"/>
        <path className={style ?? "cls-1-regular"} d="M226.24,211.09l.81-.81,7.88,3.62-.79.79-2.37-1.14-2.29,2.29,1.14,2.37-.74.74Zm2.86,4,1.88-1.89-2.59-1.27c-.43-.21-1.07-.55-1.07-.55v0s.33.63.55,1.07Z"/>
        <path className={style ?? "cls-1-regular"} d="M228.83,208.5l4.54-4.56.64.64-1.91,1.91,5.12,5.11-.73.73-5.12-5.11-1.91,1.91Z"/>
        <path className={style ?? "cls-1-regular"} d="M234.24,203.08l.72-.73,5.75,5.75-.72.72Z"/>
        <path className={style ?? "cls-1-regular"} d="M238.84,204.21a4,4,0,1,1,5.82.18A3.92,3.92,0,0,1,238.84,204.21Zm4.89-4.9c-1.35-1.35-3.1-1.64-4.45-.29s-1.05,3.1.3,4.45,3.09,1.64,4.44.28S245.08,200.66,243.73,199.31Z"/>
        <path className={style ?? "cls-1-regular"} d="M242.58,194.72l.76-.77,6.62,1.37c.49.11,1.16.32,1.16.32l0,0-4.74-4.73.67-.67,5.76,5.74-.74.74-6.65-1.36c-.46-.1-1.14-.29-1.14-.29v0l4.72,4.71-.67.68Z"/>
        </>
    )
}

const comProfileTrainglesHrm: any = {
    "RS": () => (
        <g className="cls-5-hrm-RS">
        <line className="cls-6-hrm" x1="165.39" y1="153.33" x2="81.41" y2="128.67" />
        <line className="cls-6-hrm" x1="161.58" y1="157.14" x2="77.6" y2="132.48" />
        <line className="cls-6-hrm" x1="157.77" y1="160.95" x2="73.79" y2="136.29" />
        <line className="cls-6-hrm" x1="153.96" y1="164.76" x2="69.98" y2="140.1" />
        <line className="cls-6-hrm" x1="150.15" y1="168.57" x2="66.17" y2="143.91" />
        <line className="cls-6-hrm" x1="146.34" y1="172.38" x2="62.36" y2="147.72" />
        <line className="cls-6-hrm" x1="142.53" y1="176.19" x2="58.55" y2="151.53" />
        <line className="cls-6-hrm" x1="138.72" y1="180" x2="54.74" y2="155.34" />
        <line className="cls-6-hrm" x1="134.91" y1="183.81" x2="50.93" y2="159.15" />
        <line className="cls-6-hrm" x1="131.09" y1="187.62" x2="47.12" y2="162.96" />
        <line className="cls-6-hrm" x1="127.28" y1="191.43" x2="43.31" y2="166.77" />
        <line className="cls-6-hrm" x1="123.47" y1="195.24" x2="39.49" y2="170.58" />
        <line className="cls-6-hrm" x1="119.66" y1="199.05" x2="35.68" y2="174.39" />
        <line className="cls-6-hrm" x1="115.85" y1="202.86" x2="31.87" y2="178.21" />
        <line className="cls-6-hrm" x1="112.04" y1="206.68" x2="28.06" y2="182.02" />
        <line className="cls-6-hrm" x1="108.23" y1="210.49" x2="24.25" y2="185.83" />
        <line className="cls-6-hrm" x1="105.39" y1="215.26" x2="21.41" y2="190.6" />
        </g>
    ),
    "SV": () => (
        <g className="cls-5-hrm-SV">
        <line className="cls-6-hrm" x1="111.67" y1="165.39" x2="136.33" y2="81.41" />
        <line className="cls-6-hrm" x1="107.86" y1="161.58" x2="132.52" y2="77.6" />
        <line className="cls-6-hrm" x1="104.05" y1="157.77" x2="128.71" y2="73.79" />
        <line className="cls-6-hrm" x1="100.24" y1="153.96" x2="124.9" y2="69.98" />
        <line className="cls-6-hrm" x1="96.43" y1="150.15" x2="121.09" y2="66.17" />
        <line className="cls-6-hrm" x1="92.62" y1="146.34" x2="117.28" y2="62.36" />
        <line className="cls-6-hrm" x1="88.81" y1="142.53" x2="113.47" y2="58.55" />
        <line className="cls-6-hrm" x1="85" y1="138.72" x2="109.66" y2="54.74" />
        <line className="cls-6-hrm" x1="81.19" y1="134.91" x2="105.85" y2="50.93" />
        <line className="cls-6-hrm" x1="77.38" y1="131.09" x2="102.04" y2="47.12" />
        <line className="cls-6-hrm" x1="73.57" y1="127.28" x2="98.23" y2="43.31" />
        <line className="cls-6-hrm" x1="69.76" y1="123.47" x2="94.42" y2="39.49" />
        <line className="cls-6-hrm" x1="65.95" y1="119.66" x2="90.61" y2="35.68" />
        <line className="cls-6-hrm" x1="62.14" y1="115.85" x2="86.79" y2="31.87" />
        <line className="cls-6-hrm" x1="58.32" y1="112.04" x2="82.98" y2="28.06" />
        <line className="cls-6-hrm" x1="54.51" y1="108.23" x2="79.17" y2="24.25" />
        <line className="cls-6-hrm" x1="49.74" y1="105.39" x2="74.4" y2="21.41" />
        </g>
    ),
    "R": () => (
        <g className="cls-5-hrm-R">
        <line className="cls-6-hrm" x1="170.48" y1="123.97" x2="93.67" y2="165.92" />
        <line className="cls-6-hrm" x1="170.48" y1="129.36" x2="93.67" y2="171.31" />
        <line className="cls-6-hrm" x1="170.48" y1="134.75" x2="93.67" y2="176.7" />
        <line className="cls-6-hrm" x1="170.48" y1="140.14" x2="93.67" y2="182.08" />
        <line className="cls-6-hrm" x1="170.48" y1="145.53" x2="93.67" y2="187.47" />
        <line className="cls-6-hrm" x1="170.48" y1="150.92" x2="93.67" y2="192.86" />
        <line className="cls-6-hrm" x1="170.48" y1="156.31" x2="93.67" y2="198.25" />
        <line className="cls-6-hrm" x1="170.48" y1="161.69" x2="93.67" y2="203.64" />
        <line className="cls-6-hrm" x1="170.48" y1="167.08" x2="93.67" y2="209.03" />
        <line className="cls-6-hrm" x1="170.48" y1="172.47" x2="93.67" y2="214.42" />
        <line className="cls-6-hrm" x1="170.48" y1="177.86" x2="93.67" y2="219.81" />
        <line className="cls-6-hrm" x1="170.48" y1="183.25" x2="93.67" y2="225.19" />
        <line className="cls-6-hrm" x1="170.48" y1="188.64" x2="93.67" y2="230.58" />
        <line className="cls-6-hrm" x1="170.48" y1="194.03" x2="93.67" y2="235.97" />
        <line className="cls-6-hrm" x1="170.48" y1="199.42" x2="93.67" y2="241.36" />
        <line className="cls-6-hrm" x1="170.48" y1="204.8" x2="93.67" y2="246.75" />
        <line className="cls-6-hrm" x1="171.85" y1="210.19" x2="95.03" y2="252.14" />
        </g>
    ),
    "I": () => (
        <g className="cls-5-hrm-I">
        <line className="cls-6-hrm" x1="123.97" y1="94.52" x2="165.92" y2="171.33" />
        <line className="cls-6-hrm" x1="129.36" y1="94.52" x2="171.31" y2="171.33" />
        <line className="cls-6-hrm" x1="134.75" y1="94.52" x2="176.7" y2="171.33" />
        <line className="cls-6-hrm" x1="140.14" y1="94.52" x2="182.08" y2="171.33" />
        <line className="cls-6-hrm" x1="145.53" y1="94.52" x2="187.47" y2="171.33" />
        <line className="cls-6-hrm" x1="150.92" y1="94.52" x2="192.86" y2="171.33" />
        <line className="cls-6-hrm" x1="156.31" y1="94.52" x2="198.25" y2="171.33" />
        <line className="cls-6-hrm" x1="161.69" y1="94.52" x2="203.64" y2="171.33" />
        <line className="cls-6-hrm" x1="167.08" y1="94.52" x2="209.03" y2="171.33" />
        <line className="cls-6-hrm" x1="172.47" y1="94.52" x2="214.42" y2="171.33" />
        <line className="cls-6-hrm" x1="177.86" y1="94.52" x2="219.81" y2="171.33" />
        <line className="cls-6-hrm" x1="183.25" y1="94.52" x2="225.19" y2="171.33" />
        <line className="cls-6-hrm" x1="188.64" y1="94.52" x2="230.58" y2="171.33" />
        <line className="cls-6-hrm" x1="194.03" y1="94.52" x2="235.97" y2="171.33" />
        <line className="cls-6-hrm" x1="199.42" y1="94.52" x2="241.36" y2="171.33" />
        <line className="cls-6-hrm" x1="204.8" y1="94.52" x2="246.75" y2="171.33" />
        <line className="cls-6-hrm" x1="210.19" y1="93.15" x2="252.14" y2="169.97" />
        </g>  
    ),
    "V": () => (
        <g className="cls-5-hrm-V">
        <line className="cls-6-hrm" x1="94.52" y1="141.03" x2="171.33" y2="99.08" />
        <line className="cls-6-hrm" x1="94.52" y1="135.64" x2="171.33" y2="93.69" />
        <line className="cls-6-hrm" x1="94.52" y1="130.25" x2="171.33" y2="88.3" />
        <line className="cls-6-hrm" x1="94.52" y1="124.86" x2="171.33" y2="82.92" />
        <line className="cls-6-hrm" x1="94.52" y1="119.47" x2="171.33" y2="77.53" />
        <line className="cls-6-hrm" x1="94.52" y1="114.08" x2="171.33" y2="72.14" />
        <line className="cls-6-hrm" x1="94.52" y1="108.69" x2="171.33" y2="66.75" />
        <line className="cls-6-hrm" x1="94.52" y1="103.31" x2="171.33" y2="61.36" />
        <line className="cls-6-hrm" x1="94.52" y1="97.92" x2="171.33" y2="55.97" />
        <line className="cls-6-hrm" x1="94.52" y1="92.53" x2="171.33" y2="50.58" />
        <line className="cls-6-hrm" x1="94.52" y1="87.14" x2="171.33" y2="45.19" />
        <line className="cls-6-hrm" x1="94.52" y1="81.75" x2="171.33" y2="39.81" />
        <line className="cls-6-hrm" x1="94.52" y1="76.36" x2="171.33" y2="34.42" />
        <line className="cls-6-hrm" x1="94.52" y1="70.97" x2="171.33" y2="29.03" />
        <line className="cls-6-hrm" x1="94.52" y1="65.58" x2="171.33" y2="23.64" />
        <line className="cls-6-hrm" x1="94.52" y1="60.2" x2="171.33" y2="18.25" />
        <line className="cls-6-hrm" x1="93.15" y1="54.81" x2="169.97" y2="12.86" />
    </g>
    ),
    "S": () => (
        <g className="cls-5-hrm-S">
        <line className="cls-6-hrm" x1="141.03" y1="170.48" x2="99.08" y2="93.67" />
        <line className="cls-6-hrm" x1="135.64" y1="170.48" x2="93.69" y2="93.67" />
        <line className="cls-6-hrm" x1="130.25" y1="170.48" x2="88.3" y2="93.67" />
        <line className="cls-6-hrm" x1="124.86" y1="170.48" x2="82.92" y2="93.67" />
        <line className="cls-6-hrm" x1="119.47" y1="170.48" x2="77.53" y2="93.67" />
        <line className="cls-6-hrm" x1="114.08" y1="170.48" x2="72.14" y2="93.67" />
        <line className="cls-6-hrm" x1="108.69" y1="170.48" x2="66.75" y2="93.67" />
        <line className="cls-6-hrm" x1="103.31" y1="170.48" x2="61.36" y2="93.67" />
        <line className="cls-6-hrm" x1="97.92" y1="170.48" x2="55.97" y2="93.67" />
        <line className="cls-6-hrm" x1="92.53" y1="170.48" x2="50.58" y2="93.67" />
        <line className="cls-6-hrm" x1="87.14" y1="170.48" x2="45.19" y2="93.67" />
        <line className="cls-6-hrm" x1="81.75" y1="170.48" x2="39.81" y2="93.67" />
        <line className="cls-6-hrm" x1="76.36" y1="170.48" x2="34.42" y2="93.67" />
        <line className="cls-6-hrm" x1="70.97" y1="170.48" x2="29.03" y2="93.67" />
        <line className="cls-6-hrm" x1="65.58" y1="170.48" x2="23.64" y2="93.67" />
        <line className="cls-6-hrm" x1="60.2" y1="170.48" x2="18.25" y2="93.67" />
        <line className="cls-6-hrm" x1="54.81" y1="171.85" x2="12.86" y2="95.03" />
        </g>
    ),
    "IV": () => (
        <g className="cls-5-hrm-IV">
        <line className="cls-6-hrm" x1="99.61" y1="111.67" x2="183.59" y2="136.33" />
        <line className="cls-6-hrm" x1="103.42" y1="107.86" x2="187.4" y2="132.52" />
        <line className="cls-6-hrm" x1="107.23" y1="104.05" x2="191.21" y2="128.71" />
        <line className="cls-6-hrm" x1="111.04" y1="100.24" x2="195.02" y2="124.9" />
        <line className="cls-6-hrm" x1="114.85" y1="96.43" x2="198.83" y2="121.09" />
        <line className="cls-6-hrm" x1="118.66" y1="92.62" x2="202.64" y2="117.28" />
        <line className="cls-6-hrm" x1="122.47" y1="88.81" x2="206.45" y2="113.47" />
        <line className="cls-6-hrm" x1="126.28" y1="85" x2="210.26" y2="109.66" />
        <line className="cls-6-hrm" x1="130.09" y1="81.19" x2="214.07" y2="105.85" />
        <line className="cls-6-hrm" x1="133.91" y1="77.38" x2="217.88" y2="102.04" />
        <line className="cls-6-hrm" x1="137.72" y1="73.57" x2="221.69" y2="98.23" />
        <line className="cls-6-hrm" x1="141.53" y1="69.76" x2="225.51" y2="94.42" />
        <line className="cls-6-hrm" x1="145.34" y1="65.95" x2="229.32" y2="90.61" />
        <line className="cls-6-hrm" x1="149.15" y1="62.14" x2="233.13" y2="86.79" />
        <line className="cls-6-hrm" x1="152.96" y1="58.32" x2="236.94" y2="82.98" />
        <line className="cls-6-hrm" x1="156.77" y1="54.51" x2="240.75" y2="79.17" />
        <line className="cls-6-hrm" x1="159.61" y1="49.74" x2="243.59" y2="74.4" />
        </g>
    ),
    "RI": () => (
        <g className="cls-5-hrm-RI">
        <line className="cls-6-hrm" x1="153.33" y1="99.61" x2="128.67" y2="183.59" />
        <line className="cls-6-hrm" x1="157.14" y1="103.42" x2="132.48" y2="187.4" />
        <line className="cls-6-hrm" x1="160.95" y1="107.23" x2="136.29" y2="191.21" />
        <line className="cls-6-hrm" x1="164.76" y1="111.04" x2="140.1" y2="195.02" />
        <line className="cls-6-hrm" x1="168.57" y1="114.85" x2="143.91" y2="198.83" />
        <line className="cls-6-hrm" x1="172.38" y1="118.66" x2="147.72" y2="202.64" />
        <line className="cls-6-hrm" x1="176.19" y1="122.47" x2="151.53" y2="206.45" />
        <line className="cls-6-hrm" x1="180" y1="126.28" x2="155.34" y2="210.26" />
        <line className="cls-6-hrm" x1="183.81" y1="130.09" x2="159.15" y2="214.07" />
        <line className="cls-6-hrm" x1="187.62" y1="133.91" x2="162.96" y2="217.88" />
        <line className="cls-6-hrm" x1="191.43" y1="137.72" x2="166.77" y2="221.69" />
        <line className="cls-6-hrm" x1="195.24" y1="141.53" x2="170.58" y2="225.51" />
        <line className="cls-6-hrm" x1="199.05" y1="145.34" x2="174.39" y2="229.32" />
        <line className="cls-6-hrm" x1="202.86" y1="149.15" x2="178.21" y2="233.13" />
        <line className="cls-6-hrm" x1="206.68" y1="152.96" x2="182.02" y2="236.94" />
        <line className="cls-6-hrm" x1="210.49" y1="156.77" x2="185.83" y2="240.75" />
        <line className="cls-6-hrm" x1="215.26" y1="159.61" x2="190.6" y2="243.59" />
        </g>
    )
}

const ComprofileShape: React.FunctionComponent<IComProfileMain> = (props): JSX.Element => {
    const { talentComProfile, hrmComProfile } = props;
    return(
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 265">
            <defs>
            <clipPath id="clip-path-RS">
                <path className="cls-7-hrm" d="M49.31,168.93,126,137.16a1.18,1.18,0,0,1,1.55,1.55L95.77,215.49a1.18,1.18,0,0,1-1.93.38l-44.92-45A1.19,1.19,0,0,1,49.31,168.93Z" />
            </clipPath>  
            <clipPath id="clip-path-SV">
                <path className="cls-7-hrm" d="M48.83,93.56,94.07,49.13a1.18,1.18,0,0,1,1.92.4l31.59,76.25a1.18,1.18,0,0,1-1.55,1.54L49.21,95.5A1.18,1.18,0,0,1,48.83,93.56Z" />
            </clipPath>  
            <clipPath id="clip-path-R">
                <path className="cls-7-hrm" d="M99.6,217.05l31.8-76.76a1.18,1.18,0,0,1,2.19,0l31.79,76.76a1.18,1.18,0,0,1-1.09,1.63H100.7A1.18,1.18,0,0,1,99.6,217.05Z" />
            </clipPath>    
            <clipPath id="clip-path-I">
                <path className="cls-7-hrm" d="M140.54,131.14l76.79-31.8a1.19,1.19,0,0,1,1.64,1.09v63.62a1.18,1.18,0,0,1-1.64,1.09l-76.79-31.81A1.19,1.19,0,0,1,140.54,131.14Z" />
            </clipPath>   
            <clipPath id="clip-path-V">
                <path className="cls-7-hrm" d="M100.91,46.31h63.16a1.19,1.19,0,0,1,1.1,1.64L133.59,124.2a1.19,1.19,0,0,1-2.19,0L99.81,48A1.19,1.19,0,0,1,100.91,46.31Z" />
            </clipPath>   
            <clipPath id="clip-path-S">
                <path className="cls-7-hrm" d="M47.66,99.33l76.8,31.82a1.19,1.19,0,0,1,0,2.19l-76.8,31.8A1.19,1.19,0,0,1,46,164.05V100.43A1.19,1.19,0,0,1,47.66,99.33Z" />
            </clipPath>   
            <clipPath id="clip-path-IV">
                <path className="cls-7-hrm"  d="M170.92,49.13l45.24,44.44a1.19,1.19,0,0,1-.38,1.94L139,127.33a1.19,1.19,0,0,1-1.55-1.55L169,49.52A1.19,1.19,0,0,1,170.92,49.13Z" />
            </clipPath>      
            <clipPath id="clip-path-RI">
                <path className="cls-7-hrm" d="M139,137.15,215.75,169a1.2,1.2,0,0,1,.39,1.94l-45,45a1.2,1.2,0,0,1-1.94-.39L137.4,138.7A1.18,1.18,0,0,1,139,137.15Z"/>
            </clipPath>        
            </defs>
            {
                Object.keys(comProfileTraingles).map((entery: any, i: number) => {
                    const CTalent = comProfileTraingles[entery]
                    const Chrm = comProfileTrainglesHrm[entery]

                    
                    if(talentComProfile === hrmComProfile) {
                        if (talentComProfile === ComProfile.RV && (entery === ComProfile.R || entery === ComProfile.V)) {
                            return <React.Fragment key={i+entery+"traingle"}><CTalent style={"cls-2-chosen-talent"}/><Chrm /> </React.Fragment>
                        }
                        else {
                            if(talentComProfile === entery) {
                                return <React.Fragment key={i+entery+"traingle"}><CTalent style={"cls-2-chosen-talent"}/><Chrm /> </React.Fragment>
                            }
                        }
                    }
                    else if(talentComProfile === ComProfile.RV && (hrmComProfile === ComProfile.R || hrmComProfile === ComProfile.V)) {
                        if(hrmComProfile === entery) {
                            return <React.Fragment key={i+entery+"traingle"}><CTalent style={"cls-2-chosen-talent"}/><Chrm /> </React.Fragment>
                        }
                        if (entery === ComProfile.R || entery === ComProfile.V) {
                            return <CTalent key={i+entery+"traingle"} style={"cls-2-chosen-talent"}/>
                        }
                        
                    }
                    else if(hrmComProfile === ComProfile.RV && (talentComProfile === ComProfile.R || talentComProfile === ComProfile.V)) {
                        if(talentComProfile === entery) {
                            return <React.Fragment key={i+entery+"traingle"}><CTalent style={"cls-2-chosen-talent"}/><Chrm /> </React.Fragment>
                        }
                        if (entery === ComProfile.R || entery === ComProfile.V) {
                            return <Chrm key={i+entery+"traingle"} style={"cls-2-chosen-talent"}/>
                        }
                    } 
                    else if(talentComProfile === ComProfile.RV && (entery === ComProfile.R || entery === ComProfile.V)) {
                        return <CTalent key={i+entery+"traingle"} style={"cls-2-chosen-talent"}/>
                    }
                    else if(hrmComProfile === ComProfile.RV && (entery === ComProfile.R || entery === ComProfile.V)) {
                        return <Chrm key={i+entery+"traingle"} />
                    }
                    else if(talentComProfile === entery) {
                        return <CTalent key={i+entery+"traingle"} style={"cls-2-chosen-talent"}/>
                    }
                    else if (hrmComProfile === entery) {                        
                        return <Chrm key={i+entery+"traingle"}/>
                    }
                    return <CTalent key={i+entery+"traingle"}/>
                    
                }) 
            }
            {
                Object.keys(comProfileNames).map((entery: any, i: number) => {
                    const C = comProfileNames[entery]

                    if(talentComProfile === hrmComProfile) {
                        if(talentComProfile === ComProfile.RV && (entery === ComProfile.R || entery === ComProfile.V)) {
                            return <C key={i+entery+"name"} style={"cls-2-chosen-talent"}/>
                        } 
                        else if(talentComProfile === entery) {
                            return <C key={i+entery+"name"} style={"cls-2-chosen-talent"}/>
                            
                        } 
                    }
                    else if(talentComProfile === ComProfile.RV && (hrmComProfile === ComProfile.R || hrmComProfile === ComProfile.V)) {
                        if(hrmComProfile === entery) {
                            return <C key={i+entery+"name"} style={"cls-2-chosen-hrm"}/>
                        }
                        if (entery === ComProfile.R || entery === ComProfile.V) {
                            return <C key={i+entery+"name"} style={"cls-2-chosen-talent"}/>
                        }
                    }
                    else if(hrmComProfile === ComProfile.RV && (talentComProfile === ComProfile.R || talentComProfile === ComProfile.V)) {
                        if(talentComProfile === entery) {
                            return <C key={i+entery+"name"} style={"cls-2-chosen-talent"}/>
                        }
                        if (entery === ComProfile.R || entery === ComProfile.V) {
                            return <C key={i+entery+"name"} style={"cls-2-chosen-hrm"}/>

                        }
                    } 
                    else if(talentComProfile === ComProfile.RV && (entery === ComProfile.R || entery === ComProfile.V)) {
                        return <C key={i+entery+"name"} style={"cls-2-chosen-talent"}/>
                    }
                    else if(hrmComProfile === ComProfile.RV && (entery === ComProfile.R || entery === ComProfile.V)) {
                        return <C key={i+entery+"name"} style={"cls-2-chosen-hrm"}/>
                    }
                    else if(talentComProfile === entery) {
                        return <C key={i+entery+"name"} style={"cls-2-chosen-talent"}/>
                    }
                    else if (hrmComProfile === entery) {
                        return <C key={i+entery+"name"} style={"cls-2-chosen-hrm"}/>
                    }
                    return <C key={i+entery+"name"}/>
                }) 
            }
            {/* <!-- BORDER DONT TOUCH --> */}
            <path className="cls-1-regular" d="M169.3,242.79H95.79a21.9,21.9,0,0,1-15.55-6.42l0-.05-51.86-52a21.92,21.92,0,0,1-6.42-15.56V95.46a22.2,22.2,0,0,1,6.6-15.73L80.58,28.59A21.89,21.89,0,0,1,96,22.21h72.89a21.92,21.92,0,0,1,15.52,6.39l52.07,51.13a22.19,22.19,0,0,1,6.59,15.73v73.46a21.86,21.86,0,0,1-6.44,15.56L184.9,236.32A21.92,21.92,0,0,1,169.3,242.79ZM96.09,25H96a19.2,19.2,0,0,0-13.5,5.59L30.44,81.7a19.39,19.39,0,0,0-5.77,13.76V168.8a19.17,19.17,0,0,0,5.61,13.61l51.9,52A19.18,19.18,0,0,0,95.79,240H169.3A19.12,19.12,0,0,0,183,234.37l51.73-51.83a19.14,19.14,0,0,0,5.64-13.62V95.46a19.43,19.43,0,0,0-5.77-13.76L182.47,30.56A19.17,19.17,0,0,0,168.89,25Z"/>
        </svg>
    )
}

export default ComprofileShape;