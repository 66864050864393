import UnboxableButton from "@/components/UnboxableButton";
import MultiSelect, { IMultiSelect } from "@/components/WizardComponents/MuiltiSelect/MultiSelect";
import CloseIcon from '@mui/icons-material/Close';
import { Collapse, ListItemButton, ListItemText, List } from "@mui/material";
import './style.css';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, { useState } from "react";

export type FilterTypes = 'enum' | 'number'

export type NumberFilterOperators = '>=';

export interface IFilter { 
    field: string,
    placeholder: string,
    options: { value: string, label: string }[],
    displayName: string,
    valueMapper?: { [value: string | number]: string },
    type?: FilterTypes,
    pathToField?: string
}

type FilterStructure = { 
    [key in FilterTypes]: any 
}

export interface ISelectedFilters extends FilterStructure {
    'enum': {
        [fieldName: string]: string[]
    },
    'number': {
        [fieldName: string]: {operator?: NumberFilterOperators, filterValue?: number, name?: string}
    }
}

interface ITableEnumFilters {
    filters?: IFilter[];
    onFilterChange: (name: string, selectedOptions: string[]) => any;
    onNumberFilterChange: (name: string, operator: NumberFilterOperators, value: number | null ) => any;
    multiSelectProps?: IMultiSelect;
    selectedFilters?: ISelectedFilters;
    numberFilterPlaceholder?: string;
}

const operatorValues = [50,75,90,100]
const operators: NumberFilterOperators[] = [">="];
const operatorLabels: { [key in NumberFilterOperators]: string } = { ">=": "Scored more than" }
const lastItemLabel = "Perfect score!"
const operatorsOptions: { label: string, operator: NumberFilterOperators, value: number }[] = []
operators.forEach((o) => {
    operatorValues.forEach((ov, i) => {
        operatorsOptions.push({
            label: (i + 1) === operatorValues.length ? lastItemLabel : `${operatorLabels[o]} ${ov}`, operator: o, value: ov
        })
    })
});

const TableEnumFilters: React.FunctionComponent<ITableEnumFilters> = ({
    filters, onFilterChange, onNumberFilterChange, multiSelectProps, selectedFilters, numberFilterPlaceholder
}): JSX.Element => {

    const [open, setOpen] = useState<{ [name: string]: boolean}>({});
    
    const { uiType="white" } = multiSelectProps || {};
    
    const isNumberFilters = filters && filters.findIndex(f => f.type === "number") !== -1;
    const numberFilters = isNumberFilters ? filters.filter(f => f.type === "number") : [];

    const getFilterName = (fieldName: string, value: any): string => {
        const foundFilter = filters?.find(f => f.field == fieldName);
        if(!foundFilter) return `${fieldName} - ${value}`;

        const { valueMapper, displayName } = foundFilter || {};
        if(!valueMapper) return `${displayName} - ${value}`;

        return `${displayName} - ${valueMapper[value]}`
    }

    const getNumberFiletrName = (fieldName: string, operator: NumberFilterOperators, filterValue: number) => {
        const operatorLabel = filterValue === 100 ? "Perfect score" : operatorLabels[operator]
        filterValue = (filterValue === 100 ? "" : filterValue) as any
        const foundFilter = filters?.find(f => f.field == fieldName);
        if(!foundFilter) return `${operatorLabel} ${filterValue} in ${fieldName}`;

        const { valueMapper, displayName } = foundFilter || {};
        if(!valueMapper) return `${operatorLabel} ${filterValue} in ${displayName}`;

        return `${operatorLabel} ${valueMapper[filterValue]} in ${displayName}`
    }

    return (
        <div className="table-enum-filter-section">
            {filters &&
            <div className="table-enum-filter-dropdowns">
                {filters.flatMap((f, i) => (
                    f.type !== "number" ?
                    <MultiSelect
                    containerStyle={{ height: 36 }}
                    key={`${f.field}-${i}`}
                    className="table-enum-filter-dropdwon"
                    renderValue={(value) => value.length ? `Filtered ${f.placeholder}` : f.placeholder}
                    name={f.field}
                    placeholder={f.placeholder}
                    options={f.options}
                    uiType={uiType}
                    value={selectedFilters?.enum?.[f.field] ?? []}
                    onChange={onFilterChange as any}
                    {...multiSelectProps}/> : []
                ))}

                {isNumberFilters &&
                    <MultiSelect
                    disableHandleChange={true}
                    containerStyle={{ height: 36 }}
                    className="table-enum-filter-dropdwon"
                    placeholder={numberFilterPlaceholder}
                    options={[]}
                    value={[]}
                    uiType={uiType}
                    {...multiSelectProps}>
                        <List
                        sx={{ maxHeight: "450px" }}
                        >
                            {numberFilters.map((f, i) => (
                                <React.Fragment key={`${i}-${f.field}`}>
                                    <ListItemButton onClick={() => {
                                        setOpen(prev => ({
                                            ...prev,
                                            [f.field]: !prev[f.field]
                                        }))
                                    }}>
                                        <ListItemText 
                                        sx={{ "& .MuiTypography-root": { fontSize: "12px", fontFamily: "Mulish" } }}
                                        primary={f.displayName}/>
                                        {open[f.field] ? <ExpandLess sx={{ fontSize: "12px" }}/> : <ExpandMore sx={{ fontSize: "12px" }}/>}
                                    </ListItemButton>
                                    <Collapse in={open?.[f.field]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {operatorsOptions.map(oo =>{ 
                                                const isSelected  = selectedFilters?.number?.[f.field] && selectedFilters?.number?.[f.field].filterValue === oo.value;
                                                return (
                                                    <ListItemButton 
                                                    key={oo.value}
                                                    selected={isSelected}
                                                    sx={{ pl: 4 }} 
                                                    onClick={() => onNumberFilterChange(f.field, oo.operator, isSelected ? null : oo.value)}>
                                                        <ListItemText 
                                                        sx={{ "& .MuiTypography-root": { fontSize: "12px", fontFamily: "Mulish" } }}
                                                        primary={oo.label} />
                                                    </ListItemButton>
                                                )
                                            })}
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                            ))}
                        </List>
                    </MultiSelect>}
            </div>}
            {(selectedFilters && Object.entries(selectedFilters).length) ?
            <div className="table-enum-filter-selected">
                {Object.entries(selectedFilters.enum).map(([name, values], i) => (
                    values.map(value => (
                        <UnboxableButton 
                        key={`${i}-${getFilterName(name, value)}`}
                        className="custom-filter-chip"
                        iconDir={"right" as any}
                        icon={() => <CloseIcon sx={{ fontSize: "14px", marginLeft: "10px" }}/>}
                        onClick={() => {
                            const newValues = values.filter(v => v !== value);
                            onFilterChange(name, newValues)
                        }}
                        btnStyle={"white" as any}>
                            {getFilterName(name, value)}
                        </UnboxableButton>
                    ))
                ))}
                {Object.entries(selectedFilters.number).map(([name, values], i) => (
                    <UnboxableButton 
                    key={`${i}-${values.name}`}
                    className="custom-filter-chip"
                    iconDir={"right" as any}
                    icon={() => <CloseIcon sx={{ fontSize: "14px", marginLeft: "10px" }}/>}
                    onClick={() => onNumberFilterChange(name, values.operator!, null)}
                    btnStyle={"white" as any}>
                        {getNumberFiletrName(name, values.operator!, values.filterValue!)}
                    </UnboxableButton>
                ))}
            </div> : null}
        </div>
    )
}

export default TableEnumFilters;
