import { useRef, useState, useEffect } from 'react';
import Label from '../Label/Label';
import './style.css'

const TextField: React.FunctionComponent<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { label?: string, uiType?: "grey" | "white-bordered" }> = ( props ): JSX.Element => {
    const { label, uiType="grey", value, onChange, ...restProps } = props;

    const [cursor, setCursor] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        const input: any = ref.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor, value])

    const handleChange = (e: any) => {
        setCursor(e.target.selectionStart)
        onChange && onChange(e);
    }

    const uiClasses = {
        "grey": "wizard-elements-text-field-grey",
        "white-bordered": "wizard-elements-text-field-white-bordered",
    }
    const uiClass = uiClasses[uiType];

    return(
        <div className='wizard-text-field-container'>
            {label &&
            <Label>{label}</Label>}
            <input 
            {...restProps}
            onChange={handleChange}
            ref={ref}
            value={value}
            type={"text"} 
            className={`wizard-custom-text-field ${uiClass} ${props.className ?? ""}`}
            />

        </div>
    )
}

export default TextField