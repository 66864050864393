import cx from "classnames"
import LText from "../LText";

// Local Interfaces
export interface TitleProps {
  readonly text: string
  readonly className?: string
  readonly subText?: string
  style?: React.CSSProperties;
}

/**
 *
 * Page Title
 * 
 * Locale aware, no need to translate the title
 * @description title for all page sections including breadcrumbs
 *
 */
const Title: React.FunctionComponent<TitleProps> = ({ text, subText, className, style }): JSX.Element => {
  // Menu hook
  // const { menu, submenu }: Menu = useMenu()

  return (
    <div className="page-title">
      {/* Title */}
      <h1 className="text-base sm:text-lg md:text-xl lg:text-2xl leading-normal text-gray-800 mb-10" style={style}>
        <span className={cx("font-bold", className)} style={style}>
          <LText text={text} />
          {subText && ": "}
          </span>
        {subText && <span className="font-light"><LText text={subText} /></span>}
      </h1>
    </div>
  )
}

export default Title
