// Icons
import juniorIcon from '../../icons/junior.svg'
import intermidiateIcon from '../../icons/mid-level.svg';
import seniorIcon from '../../icons/senior.svg';
import openQuestion from '../../icons/open-question.svg'

export const levelIcon: any = {
    "junior": juniorIcon,
    "intermediate": intermidiateIcon,
    "senior": seniorIcon,
    "open_question": openQuestion
}


export const levelEnum: any = {
    "junior": "Junior",
    "intermediate": "Mid level",
    "senior": "Senior",
    "open_question": "Open Question"
}

export const levels = ["junior", "intermediate", "senior", "open_question"]