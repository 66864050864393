/**
 *
 *  @module src/pages/Positions/usePositionsPage
 *
 *  @description positions page logic
 *
 *  @author buskerone
 *
 */

// React
import React, { useState } from "react"

// Toast
import { toast } from "react-toastify"

// Auth
import { useAuth, IAuth } from "@auth"

// Mutations
import { deletePosition } from "@/graphql/mutations/deletePosition"

// Intefaces
import { IPosition } from "@interfaces/pages/positions"
import { graphQueries } from "@/helpers"

// Shared Interfaces
export interface IPositions {
  showModal: boolean
  positionToDelete: IPosition | null
  setShowModal: React.Dispatch<boolean>
  deletePositionRequest: (position: IPosition) => Promise<void>
  setPositionToDelete: React.Dispatch<IPosition|null>
  fetcher: (query: any, page: number, limit: number, keyword: string) => any,
  activeCandidates: boolean,
  setActiveCandidates: React.Dispatch<boolean>
  setMutate: React.Dispatch<Function>
}

/**
 *
 *  Logic: Positions Page
 *  @description contains all Positions page logic
 *
 */
const usePermissionsPage = (): IPositions => {
  // States
  const [showModal, setShowModal] = useState<boolean>(false)
  const [activeCandidates, setActiveCandidates] = useState<boolean>(false)
  const [positionToDelete, setPositionToDelete] = useState<IPosition|null>(null)
  const [mutate, setMutate] = useState<Function>(() => () => null)

  // Auth
  const auth: IAuth = useAuth()

// Fetcher
  // eslint-disable-next-line
  const fetcher = (query: any, page: number, limit: number, keyword: string) =>
    graphQueries.sendRequest(query, {
      "companyId": auth.company?.id,
      "sizeOfPage": limit,
      "page": page,
      "titleName": keyword,
      "haveCandidates": activeCandidates
    })
      .then(({ getV2Positions }) => ({
        items: getV2Positions.positions,
        count: getV2Positions.count
      }))

  // Delete User Request
  const deletePositionRequest = async (position: IPosition): Promise<void> => {

    // We make the request to delete user in API
    try {
      const response: any = await graphQueries.sendRequest(deletePosition, {
        v2PositionId: position.id,
      })

      toast(response.deleteV2Position.message, { containerId: "default", type: "success" })

    } catch (error: any) {
      console.error(error)
    }

    // In success case we hide the modal
    setShowModal(false)
    mutate()
  }

  return {
    fetcher,
    showModal,
    setShowModal,
    positionToDelete,
    setPositionToDelete,
    deletePositionRequest,
    activeCandidates,
    setActiveCandidates,
    setMutate
  }
}

export default usePermissionsPage
