// React
import React from "react"
import { ChevronLeftIcon } from "@heroicons/react/solid"

// Components
import Page from "@components/WizardForm/components/Page"
import WizardLoading from "@components/WizardForm/components/Loading"
import { WizardFormContext } from "@components/WizardForm/context"

// Utils
import ProgressBar from "@ramonak/react-progress-bar"

// Utils
import cx from "classnames"
import { IFormPageCollection } from "@/models/formPage"
import { TabDisplayTypes } from ".."
import WizardTabControls from "./WizardTabControls"

// Local Interfaces
interface MainWizardContainerProps {
  model: IFormPageCollection
  finishButtonText: string
  okButtonText: string
  showTabs: TabDisplayTypes
  progressBar?: boolean
  pressEnter?: boolean
  showSave?: boolean
  className?: string
}

/**
 *
 *  Wizard Form
 *  @description manages questions inside pages, controls and statuses
 *
 */
const MainWizardContainer: React.FC<MainWizardContainerProps> = ({
  model,
  className,
  okButtonText,
  finishButtonText,
  progressBar,
  showSave,
  showTabs /*, pressEnter=true */,
}): JSX.Element => {
  // Context
  const {
    finishLoading,
    handlePrevPage,
    handleNextPage,
    handleFinish,
    canFinish,
    pages,
    page,
  } = React.useContext(WizardFormContext);

  const sections = model.pages.map((p: any) => p.name);
  const formPage = model.pages[page];

  const showFinish = !canFinish || canFinish(page, model.pages[page]);
  progressBar = progressBar !== false;

  if (!formPage) {
    return <div>No Content</div>;
  }

  return (
    <div
      className={cx(
        "flex flex-col justify-center items-center wiz-page",
        className
      )}
    >
      <div className="w-full flex flex-col justify-center items-center pr-12 overflow-visible">
        <div className="w-full lex flex-col justify-center items-center">
          <WizardTabControls showTabs={showTabs} sections={sections} />
          <div className="flex justify-start items-center">
            {/* Page containing the questions */}
            <Page page={model.pages[page]} pageIndex={page} /> {/* PAGE */}
          </div>
        </div>

        {/* Page controls */}
        <div className="w-full flex justify-between items-end">
          {(showSave || showFinish || progressBar) && (
            <div className="w-full justify-between flex items-center mt-12">
              {(progressBar !== false || showSave || showFinish) && (
                <div className="flex justify-center items-center">
                  {(page !== 0 && progressBar) && (
                    <button
                      onClick={() => handlePrevPage()}
                      className={
                        "flex items-center justify-center wiz-prev-btn" +
                        (showSave || showFinish ? " has-next" : "")
                      }
                    >
                      <ChevronLeftIcon className="w-6 h-6" />
                    </button>
                  )}

                  {/* Ok (proceed) button */}
                  {showSave && (
                    <button
                      onClick={() => handleNextPage()}
                      className={
                        "mr-4 wiz-next-btn" + ((page !== 0 && progressBar) ? " has-prev" : "")
                      }
                    >
                      {okButtonText}
                    </button>
                  )}

                  {showFinish && (
                    <button
                      onClick={() => handleFinish()}
                      className="wiz-finish-btn px-4"
                    >
                      {" "}
                      {finishButtonText}
                    </button>
                  )}

                  {finishLoading && <WizardLoading className="ml-4" />}
                </div>
              )}

              {/* pressEnter && <span className="text-gray-800 text-sm ml-5">Press enter</span> */}
              {(finishLoading || progressBar) && (
                <div className="flex gap-3">
                  {/* Progress Bar containing page index and step status */}
                  {progressBar && (
                    <div className="flex flex-col justify-center wiz-progress">
                      <span className="wiz-progress-text">
                        {`${page} / ${pages - 1} complete`}
                      </span>

                      <ProgressBar
                        width="159px"
                        height="4px"
                        bgColor="black"
                        isLabelVisible={false}
                        completed={(100 * page) / (pages - 1)}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MainWizardContainer
