import LNRDLoading from "@/components/LNRDLoading";

import './style.css';

const WizardLoading: React.FunctionComponent<{ isLoading?: boolean }> = ({ isLoading }): JSX.Element => {
    return (
        <div className={isLoading === false ? "fade-animation-loading" : "custom-wizard-loading-container"}>
            <LNRDLoading/>
        </div>
    )
}

export default WizardLoading;