import { useContext, useState } from "react";

import { Title } from "@/components";
import WarningMdoal from "@/components/WizardComponents/WarningModal/WarningModal";
import Modal from "../../components/WizardComponents/Modal/Modal";
import { RecommendationEditor, RecommendationsTable } from "./components";
import useRecommendations from "./useRecommendations";
import './style.css';
import { IRecommendation } from "./data/types";
import { useAuth } from "@/auth";
import useLocalToast from "../../hooks/useLocalizedToast";
import AppContext from "@/context/AppContext";

const Recommendations: React.FunctionComponent = (): JSX.Element => {

    const { 
        recommendations, 
        isLoadingRecommendations,
        deleteRecommendation,
        createRecommendation,
        updateRecommendation} = useRecommendations({});

    const { user } = useAuth();

    const [openRecommendationEditor, setOpenRecommendationEditor] = useState<boolean>(false);
    const [isRecommendationEditorInEditMode, setIsRecommendationEditorInEditMode] = useState<boolean>(false);
    const [recommendationToEdit, setRecommendationToEdit] = useState<string | undefined>(undefined);
    
    const [ showDeleteWarningModal, setShowDeleteWarningModal ] = useState<boolean>(false);
    const [ recommendationToDelete, setRecommendationToDelete ] = useState<string | undefined>(undefined);
    const { tr } = useContext(AppContext);
    const { toast } = useLocalToast();

    const onClickDeleteRecommendation = (recommendationId: string) => {
        setRecommendationToDelete(recommendationId)
        setShowDeleteWarningModal(true)
    }

    const onApproveRecommendationDeletion = () => {
        setShowDeleteWarningModal(false);
        if(!recommendationToDelete) return alert("Recommendation Id is missing");
        deleteRecommendation({ reportRecommendationId: recommendationToDelete})
        .then(() => {
            toast("Recommendation deleted", { containerId: "default", type: "success" })            
        });
    }

    const onClickAddRecommedation = () => {
        setIsRecommendationEditorInEditMode(false);
        setOpenRecommendationEditor(true);
    }
    
    const onClickEditRecommendation = (recommendationId: string) => {
        setIsRecommendationEditorInEditMode(true);
        setRecommendationToEdit(recommendationId);
        setOpenRecommendationEditor(true);
    }

    const onSaveRecommendation = (recommendation: IRecommendation, isEditMode?: boolean) => {
        const { id } = recommendation;
        const mode = isEditMode ? "edit" : "create";
        const funcToExecute = {
            "edit": async () => await updateRecommendation({ reportRecommendationId: id!, ...recommendation }),
            "create": async () => await createRecommendation({ ...recommendation, userId: user?.id })
        }

        funcToExecute[mode]()
        .then(() => {
            toast(`Recommendation ${ isEditMode ? "updated" : "created" } successfully`, { containerId: "default", type: "success" } )
        })
        .catch(() => {
            toast(`Failed to ${ isEditMode ? "update" : "create" } recommendation`, { containerId: "default", type: "error" } )
        })
        .finally(() => {
            setOpenRecommendationEditor(false);
        })
    }

    return (
        <div>
            <Title text={tr("Recommendations Center")} />
            <RecommendationsTable
            tableProps={{
                filterMultiSelectProps: { uiType: "white" } as any,
                searchOnColumns: [""],
            }}
            addButtonProps={{ 
                btnSize: "medium" as any,
                className: "add-new-recommnedation-custom-btn",
                icon: undefined,
                children: "Add new recommendation"
            }}
            recommedations={recommendations} 
            loading={isLoadingRecommendations} 
            onClickEditRecommendation={onClickEditRecommendation}
            onClickDeleteRecommendation={onClickDeleteRecommendation}
            onClickAddRecommendationBtn={onClickAddRecommedation}/>

            <Modal 
            openModal={openRecommendationEditor} 
            onClose={() => setOpenRecommendationEditor(false)}>
                <RecommendationEditor
                isEditMode={isRecommendationEditorInEditMode}
                recommendationToEdit={recommendations.find(r => r.id === recommendationToEdit)}
                onSubmit={(r) => onSaveRecommendation(r as IRecommendation, isRecommendationEditorInEditMode) }/>
            </Modal>

            <WarningMdoal
            warningMessage={`${tr("Are you sure you want to continue?")} ${tr("this action will delete the specified recommendation.")}` }
            showModal={showDeleteWarningModal}
            onClose={() => setShowDeleteWarningModal(false)}
            onApprove={onApproveRecommendationDeletion}/>
        </div>
    )
} 

export default Recommendations;