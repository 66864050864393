import { gql } from "graphql-request"

// Get positions by company id
export const getUsers = (key: string): string => gql`
  query getUsers_${key} (
    $getUsersCompanyId: UUID!,
    $getUsersSizeOfPage: Int,
    $getUsersPage: Int
  ) {
    getUsers(
      companyId: $getUsersCompanyId,
      sizeOfPage: $getUsersSizeOfPage,
      page: $getUsersPage
    ) {
      users {
        id
        userImage
        userImageName
        pending
        description
        deleted
        role
        comProfile
        active
        phone
        jobTitle
        lastName
        firstName
        email
        department
      }
      totalCount
    }
  }
`

// Get users by company id
export const getAllUsers = (key: string): string => gql`
  query Users${key} (
    $companyId: UUID!, $page: Int, $sizeOfPage: Int
  ) {
    getUsers(
      companyId: $companyId,
      sizeOfPage: $sizeOfPage,
      page: $page
    ) {
      totalCount
      users {
        id
        email
        firstName
        lastName
        jobTitle
        phone
        active
        role
        deleted
        description
        pending
        userImageName
        userImage
      }
    }
  }
`

export const getUser = gql`
  query GetUser($email: String, $userId: String) {
    getUser(email: $email, userId: $userId) {
      id
      email
      firstName
      lastName
      jobTitle
      phone
      active
      comProfile
      role
      deleted
      description
      pending
      userImageName
      userImage
    }
  }
`