// Toast
import { toast } from "react-toastify"

// React
import { useContext, useState } from "react"

// Router
import { useParams } from "react-router-dom"

// Auth
import { useAuth, IAuth } from "@auth"

// Components
import { Title } from "@components"
import UserProfileForm from "@/components/UserProfileForm/UserProfileForm"

// Parts
import Loading from "@pages/UserProfile/Loading"

// Http
import useSWR from "swr"

// Queries
import { getUser } from "@queries/generic"
import { updateUserProfile } from "@mutations/updateUserProfile"

// Wizard Model
import { graphQueries, toBase64 } from "@/helpers"
import {  MediaUploader } from "@/helpers/mediaUploader"
import AuthContext from "@/auth/context"
import { IUser } from "@/auth/interfaces"

import ComProfileSurvey from "@/components/ComProfileSurvey"
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie"
import { Analytics } from "@/helpers/analytics"
import AppContext from "@/context/AppContext"

// Fetcher
const fetcher = (query: string, userId: string) =>
  graphQueries.sendRequest(query, {
    "genericSearchBoxTable": "User",
    "genericSearchBoxColumn": "id",
    "genericSearchBoxValue": userId,
    "genericSearchBoxSizeOfPage": 1,
    "genericSearchBoxPage": 0
  })
  .then((data: any) => data.genericSearchBox.response.rows[0])

/**
 *
 *  User Profile
 *  @description shows user profile information and other users information if admin
 *
 */
const UserProfile: React.FunctionComponent = (): JSX.Element => {
  // URL Params
  const { userId }: { userId: string } = useParams()
  
  // Auth
  const { user }: IAuth = useAuth()
  
  // Uploader
  const { settings } = useContext(AuthContext)

  // States
  const [form, setForm] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false)
  const [preview, setPreview] = useState<any>("");
  const [ userInfo, setUserInfo ] = useState<IUser>();

  const [openModal, setOpenModal] = useState(false);

  const { tr } = useContext(AppContext);


  // SWR
  // Fetch user info
  const { isValidating, error } = useSWR(
    userId ?
      [getUser(`getUser_${userId.replace(/[0-9]|-/g, "")}`), userId]
    : [getUser(`getUser_${user!.id.replace(/[0-9]|-/g, "")}`), user!.id],
    fetcher,
    {
      revalidateOnFocus: false,
      // onSuccess: (userInfo: any) => setModel(replaceFormModel(userInfo))
      onSuccess: (userInfo: any) => {
        setUserInfo(userInfo);
        setForm({
          jobTitle: userInfo.jobTitle,
          description: userInfo.description,
          email: userInfo.email
        });
        setPreview(userInfo.userImage);
      }
    }
  )

  /**
   * Handle form submit
   * 
   */
  const onSubmit = async () => {
    // Set Wizard Form loading
    setLoading(true)
    
    // Update user on server
    const updateUser = await graphQueries.sendRequest(updateUserProfile, {
      "updateUserUserId": userId || user!.id,
      "updateUserJob": form.jobTitle ? form.jobTitle : "",
      "updateUserDescription": form.description ? form.description : "",
      "updateUserUserImage": preview && preview.length > 0 ? preview : null
    })
    .then((data: any) => data.updateUser.message)
    .then(msg => toast(<div className="font-sans" style={{textAlign: "center"}}>{msg}</div>, {
      type: "default",
      containerId: "default"
    }))

    // Hide Wizard Form loading
    setLoading(false)
    
    return updateUser
  }

  /**
   * Handle form change
   * 
   */
  const handleChange = (e: any) => {
    setForm((prevState:any)=> ({...prevState, [e.target?.name]: e.target?.value}));   
  }

  /**
   * Image upload handler
   * 
   */ 
  const upload = async (files: FileList | null) => {
    const [ file ] = files || [];
    if(file) {
      try {
        const imageData = (file && await toBase64(file)) || "";
    const mediaUploader = new MediaUploader(settings);
        const result = await mediaUploader.upload({
          data: imageData,
          name: file.name
        });
        const { url, error } = result || {};
        if(error) {
          throw new Error(error); 
        }
        if(url) {          
          return setPreview(url);
        }
        throw Error('Something went wrong...');
      } catch(e) {
        console.error(e);
      }

    }
  };

  /**
   * Clear image
   * 
   */
  const onImageClear = () => {
    setPreview("")
  }

  const onComprofileClicked = () => {
    const userSource = "userProfile";
    Cookies.set('reportComprofileSource', userSource)
    Cookies.set('reportComprofileSessionId', uuidv4())
    Analytics.sendComprofileWizardReportEvent({
      user_id: user?.id ?? null,
      screen: "1",
      event: "start",
      source: userSource
    })
    setOpenModal(true);
  }

  return (
    <main id="profile">
      {/* Title */}
      <Title text={ tr(userId === user!.id ? "My Profile" : "User Profile") }/>

      { (() => {
        if ( userId && isValidating ) {
          return <Loading />
        }

        if ( error ) {
          return (
            <div>
              Error!
            </div>
          )
        }

        return (
          <>
            {/* Form and User Information */}
            <UserProfileForm 
            handleChange={handleChange} 
            handleSubmit={() => onSubmit()} 
            username={userInfo?.firstName + " " + userInfo?.lastName}
            form={form} 
            loading={loading}
            src={preview} 
            onUploadImage={upload} 
            onImageClear={onImageClear}
            showComProfile={user?.id === userId ? true : false}
            comProfileClicked={() => onComprofileClicked()} 
            />
          </>
        )
      })() }
      {openModal && <ComProfileSurvey openModal={setOpenModal.bind(this)}/>}
    </main>
  )
}

export default UserProfile;
