import React, { useEffect, useState } from 'react';
import './style.css';

const ReportNavigation: React.FunctionComponent<{
    routes?: { label: string, iconSrc: string | React.ReactNode, sectionid: string, href?: string, printModeLabel?: string, onlyAts?: boolean  }[],
    activeLink?: string;
    onLinkClicked?: (sectionId: string) => any
}> = ({ routes, activeLink, onLinkClicked }): JSX.Element => {

    const [activeSection, setActiveSection] = useState<string | undefined>(activeLink ?? routes?.[0]?.sectionid);

    useEffect(() => {
        if(activeLink) {
            setActiveSection(activeLink)
        }
    }, [activeLink])

    const navigateToSection = (sectionid: string): void => {
        const section = document.getElementById(sectionid);
        section && section.scrollIntoView({ behavior: 'smooth' });
        onLinkClicked?.(sectionid)
        setActiveSection(sectionid);
    }

    return (
        <div className='candidate-report-navigation-bar'>
            {routes && 
            routes.map((route, index) => {
                const isActive = activeSection === route.sectionid;
                return <React.Fragment key={index}>
                {!route.onlyAts &&
                <div 
                onClick={() => navigateToSection(route.sectionid)}
                className={`candidate-report-navigation-bar-item  no-print ${isActive ? 'navigation-bar-item-active' : ""}`}>
                    <div className={`candidate-report-navigation-bar-item-traingle ${isActive ? 'navigation-bar-show-traingle' : ""}`}></div>
                    <div className='candidate-report-navigation-bar-item-icon'>
                        { typeof route.iconSrc === "string" ?
                        <img src={route.iconSrc} alt={route.label} /> :
                        route.iconSrc}
                    </div>
                    <span className='candidate-report-navigation-bar-item-label'>
                        {route.label}
                    </span>
                </div>}
                {/* ATS version link */}
                <a 
                    href={route.href}
                    target='_blank'
                    onClick={() => navigateToSection(route.sectionid)}
                    className={`candidate-report-navigation-bar-item-a ${isActive ? 'navigation-bar-item-active' : ""}`}>
                        <div className={`candidate-report-navigation-bar-item-traingle no-print ${isActive ? 'navigation-bar-show-traingle' : ""}`}></div>
                        <div className='candidate-report-navigation-bar-item-icon'>
                            { typeof route.iconSrc === "string" ?
                            <img src={route.iconSrc} alt={route.label} /> :
                            route.iconSrc}
                        </div>
                        <span className='candidate-report-navigation-bar-item-label-ats'>
                            {route.printModeLabel ?? route.label}
                        </span>
                </a>
                </React.Fragment>
            })}
        </div>
    )
}

export default ReportNavigation;