import { gql } from "graphql-request"

export const getReview = (key: string): string => `
  query getReview_${key} (
    $candidateId: String,
    $positionId: UUID!
  ) {
    candidate: genericSearchBox(
      table: "Candidate",
      column: "id",
      value: $candidateId,
      sizeOfPage: 1,
      page: 0
    ) {
      response
    }

    position: getV2Position(
      positionId: $positionId,
      sizeOfPage: 1,
      page: 0
    ) {
      title
    }
  }
`

export const getCandidateInfoForReview = (key: string): string => `
  query GetCandidate_${key} ($candidateId: UUID) {
    candidate: getCandidate(candidateId: $candidateId) {
      id
      firstName
      lastName
      email
      phone
      deleted
      pdfUrl
      candidateStatus
      isSharingProfile
      comProfile
      V2PositionId
      scoredQuestionResponse
      referrer
      cv
      skippedQuestionResponse
      cvName
    }
  }
`

export const getComProfileReview = (key: string): string => `
  query GetAllComProfiles_${key} ($profileLetter: String) {
    comprofile: getAllComProfiles(profileLetter: $profileLetter) {
      profile
      workStyle
      qualities
      values
      needs
      strengths
      metadata
    }
  }
`

export const getComProfile = gql`
query GetAllComProfiles($profileLetter: String) {
  getAllComProfiles(profileLetter: $profileLetter) {
    profile
    qualities
    strengths
    needs
    values
    workStyle
  }
}
`
export const getComProfileForecast = gql`
  query GetAllComProfiles($comProfileOne: String!, $comProfileTwo: String!) {
    getComProfileForecast(comProfileOne: $comProfileOne, comProfileTwo: $comProfileTwo) {
      id
      summary
      helpYourTalentThriveBy
      avoidSurvivalModeBy
      comProfileOne
      comProfileTwo
    }
  }
`

export const getCandidateExperience = gql`
query GetCandidateExperience($email: String) {
  getCandidateExperience(email: $email) {
    finalProcessedProfile
    linkedinUrl
    cvPath
  }
}
`

export const getReferrerByCode = gql`
query GetReferrer($code: String!) {
  getReferrer(code: $code) {
    name
  }
}
`