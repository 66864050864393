import { EditorApi } from "@/pages/AssessmentEditor/data/editor.api";
import { useEffect, useState, useContext, useCallback } from "react";
import WizardStepTemplate from "@components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { IWizardStep } from "../../interfaces";
import { graphQueries } from "@/helpers";
import { PositionClient } from "@/dal/position-client";
import { AssessmentStatus } from "@/pages/AssessmentEditor/data/editor.utils";
import { useHistory } from "react-router";
import { IAuth, useAuth } from "@/auth";
import { CustomizeYourSimulation, GetTheSimulation, WhatsNext } from "./components";
import env from "react-dotenv";

import useLocalToast from "../../../../../hooks/useLocalizedToast";

import './style.css';
import AppContext from "@/context/AppContext";


const DESCRIPTION = "Simply copy the link below and share it with your potential candidates"

const BuildingBlocksStep: React.FunctionComponent<
    IWizardStep &
    {
        openDNAQuizModal: (open: boolean) => any,
        setStartQuizOnOpenModal: (open: boolean) => any,
        hideQuizButton: boolean;
    }> = ({
        formik, isLoading, setIsLoading, openDNAQuizModal, setStartQuizOnOpenModal, hideQuizButton
    }): JSX.Element => {

        const { values, setFieldValue } = formik;
        const { matchingBuildingBlocks, id: positionId, matchingTitle, slug, templates } = values;

        const history = useHistory();
        const { localizer, locale } = useContext(AppContext);

        const [buildingBlocks, setBuildingBlocks] = useState<{ label: string, value: any, description?: string, link?: string }[]>([]);

        const [isCustomizeSimulation, setIsCustomizeSimulation] = useState<boolean>(false);

        const [buildingBlockLoading, setBuildingBlockLoading] = useState<boolean>(true);

        const { toast } = useLocalToast();

        const { tr} = useContext(AppContext);


        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const getSimulatorLink = useCallback((bb_type?: string, companySlug?: string, positionSlug?: string, _template_code?: string) => {
            if (!companySlug || !positionSlug) return "";
            // let tmpl =  '';
            // if(template_code && template_code !== PositionTemplates.Classic) {
            //     tmpl = `${template_code}/`;
            // }
            return localizer.localizeURL(`${env.JOBSIMULATOR_ORIGIN}/${companySlug}/${positionSlug}/welcome${bb_type ? "?assess=" + bb_type : ""}`, locale)
        }, [locale]);


        const [
            // error
            , setError] = useState<any>(null);
        const auth: IAuth = useAuth()

        const [simulatorUrl, setSimulatorUrl] = useState<string>("")

        const [whatsNextCards] = useState([
            {
                text: "Take the Hiring DNA Quiz",
                onClick: () => {
                    openDNAQuizModal(true);
                    setStartQuizOnOpenModal(true);
                },
                hide: hideQuizButton
            },
            {
                text: "Create a discovery page for the position",
                onClick: () => {
                    history.replace(`/positions/wizard/discovery/${positionId}`)
                    history.go(0);
                }
            },
            {
                text: "Create a new Job simulation",
                onClick: () => {
                    history.replace("/positions/wizard");
                    history.go(0);
                }
            }
        ]);

        const updateSimulatorLink = useCallback(async (position_id: string) => {
            const { id: company_id } = auth.company || {}

            if (!company_id) {
                setError("Unknown company")
                return
            }

            const result = (await graphQueries.getCompanyById(company_id)) || {}
            // const resultSlug = (await graphQueries.getPositionSlug(position_id)) || {}
            const position = await PositionClient.read({ positionId: position_id });
            let url: string | null = null
            if (result.data) {
                url = getSimulatorLink(
                    undefined,
                    result.data.slug!,
                    position?.slug || '',
                    position?.templates)

                if (url) {
                    setSimulatorUrl(url)
                    const { assessmentStatus } = position || {};
                    const updated = await PositionClient.update({
                        positionId: positionId ?? "",
                        ...(assessmentStatus ? {} : { assessmentStatus: AssessmentStatus.PENDING_REVIEW })
                    });
                    if (!updated) {
                        console.error(`Failed to update position status`);
                    }
                } else {
                    setError(`Failed to create simulator url`)
                }
            } else {
                setError(
                    `Error fetching data for company ${positionId}: ${result.error || "unknown"
                    } `
                )
            }
        }, [auth.company]);

        useEffect(() => {
            setIsLoading?.(true);
            setBuildingBlockLoading(true);
            EditorApi.getBuildingBlocks()
                .then(bbs => {
                    setBuildingBlocks(
                        bbs
                        .filter(bb => !/^lugo/i.test(bb.name!))
                        .map(bb => ({
                            label: bb.name!, 
                            value: bb.id!,
                            description: bb?.metadata?.description,
                            link: getSimulatorLink(bb?.id, auth?.company?.slug, slug, templates)
                        })))
                })
                .catch(console.error)
                .finally(() => setBuildingBlockLoading(false));

            if (!positionId) {
                toast("Missing position id", {
                    toastId: "resume-create-position",
                    autoClose: 5000,
                    type: "error",
                    containerId: "default",
                })
                history.replace("/")
                return
            }
            (async () => {
                updateSimulatorLink(positionId)
            })()
        }, [])

        useEffect(() => {
            if (simulatorUrl && !buildingBlockLoading) {
                setIsLoading?.(false)
            }
        }, [buildingBlockLoading, simulatorUrl])

        const onSaveCustomzieSimulation = (bbs: string[]) => {
            setFieldValue("matchingBuildingBlocks", bbs)
            setIsCustomizeSimulation(false)
            EditorApi.postAssessmentsByPosition({
                values,
                matchingBuildingBlocks: bbs,
            } as any)
            // submitPositionToElastic(positionId!)
            toast("Your simulation has been saved", {
                toastId: "resume-create-position",
                autoClose: 3000,
                type: "success",
                containerId: "default"
            })
        }

        const needToRenderSeperateLine = (index: number, bbLength: number): boolean => {
            return index !== 0 && index !== bbLength && index % 3 === 0;
        }

        const copyLinkToClipboard = useCallback((link?: string) => {
            if (!link) return;

            navigator.clipboard.writeText(link);
            toast("Copied to clipboard", {
                toastId: "resume-create-position",
                autoClose: 3000,
                type: "success",
                containerId: "default"
            })
        }, []);

        return (
            <WizardStepTemplate
                title={`${matchingTitle || tr("Your")} ${tr("Job Simulation is ready")}!`}
                description={DESCRIPTION}>
                {!isLoading &&
                    isCustomizeSimulation &&
                    <CustomizeYourSimulation
                        buildingBlocks={buildingBlocks}
                        matchingBuildingBlocks={matchingBuildingBlocks}
                        needToRenderSeperateLine={needToRenderSeperateLine}
                        onCancelCustomize={() => setIsCustomizeSimulation(false)}
                        onSaveCustomizeSimulation={onSaveCustomzieSimulation}
                        onCopyLinkClicked={copyLinkToClipboard} />}

                {!isLoading &&
                    !isCustomizeSimulation &&
                    <GetTheSimulation
                        onClickCustomizeSim={() => setIsCustomizeSimulation(true)}
                        simulatorUrl={simulatorUrl}
                        copyJobSimUrlToClipboard={copyLinkToClipboard} />}

                <WhatsNext
                    cards={whatsNextCards} />
            </WizardStepTemplate>
        )
    }

export default BuildingBlocksStep;