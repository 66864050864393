import { useCallback } from "react"
import { DocumentDuplicateIcon, ExclamationCircleIcon } from "@heroicons/react/solid"
import { CSSProperties, HTMLAttributeAnchorTarget } from "react"
import { toast } from "react-toastify"
// Helpers
import { copyToClipboard } from "@helpers"
import useLocalToast from "../../hooks/useLocalizedToast";

export interface PrettyLinkProps {
    text: string
    href: string
    target: HTMLAttributeAnchorTarget
    canCopy: boolean
    classes: Partial<{ 
        iconClass: string,
        textClass: string,
        containerClass: string,
     }>,
    styles: Partial<{ 
        linkStyle: CSSProperties,
        containerStyle: CSSProperties,
        iconStyle: CSSProperties
    }>
}


function noLinkToast() {
    toast(<div className="flex items-center">
    <ExclamationCircleIcon
        color="orange"
        className="inline-block w-5 h-5"/>
        <div className="mx-2">No link</div>
        </div>, { containerId: "default" })
}

const PrettyLink: React.FunctionComponent<Partial<PrettyLinkProps>> = ({ 
    text, target, href, canCopy, styles, classes 
}) => {
    const { toast } = useLocalToast();
    const {
        iconStyle,
        linkStyle,
        containerStyle
    } = styles || {}
    const {
        iconClass, 
        textClass, 
        containerClass
    } = classes || {}
    const copy = useCallback(async (text?: string) => {
        if(text){
            try {
                await copyToClipboard(text)
                toast('Link Copied!', { containerId: "default" })
            } catch(e) {
                console.error('copyToClipboard: Copy failed', text)
            }
        }
    }, []);
    return <div 
        onClick={ () => !href && noLinkToast() }
        style={ containerStyle }
        className={`relative flex items-center box-border overflow-hidden rounded p-6 ${containerClass}`}>
        {!href && <ExclamationCircleIcon 
            color="orange"
            className="absolute transform -translate-x-2 -translate-y-5 inline-block w-5 h-5 cursor-pointer"/>
        }
        <a className={`flex-grow cursor-pointer inline-block no-underline mr-2 ${textClass} truncate`} 
            
            href={href}
            style={ { ...(href? {} : { pointerEvents: 'none' }), ...linkStyle }}
            target={target}>{ text }</a>
        <div className={`inline-block bg-transparent ${iconClass}`}>
        { canCopy && <DocumentDuplicateIcon
            onClick={() => copy(href) }
            style={iconStyle}
            className="w-6 w-6 cursor-pointer "/> } 
        </div>
 

    </div>
}

export default PrettyLink