
import { useState } from 'react';
import { QuestionFeedback } from '../../components';
import ReactHtmlParser from "react-html-parser";
import { LNRDLoading } from '@/components';
import { CorrectAnswerFields } from '../../data/types';
import useLocalToast from "../../../../../hooks/useLocalizedToast";
import './style.css';

const RankOpenQuestions: React.FunctionComponent<{
    candidateFirstName?: string,
    openQuestions?: any[],
    questionIndex: number,
    onSubmitQuestionRank: (assessmentId: string, correctAnswers: CorrectAnswerFields) => Promise<boolean>,
    onDoneRankingAnswers?: () => void,
}> = ({
    candidateFirstName,
    openQuestions,
    questionIndex: qIndex,
    onSubmitQuestionRank,
    onDoneRankingAnswers,
}): JSX.Element => {
    
    const [questionIndex, setQuestionIndex] = useState<number>(qIndex);
    
    const [rank, setRank] = useState<number>(openQuestions?.[questionIndex]?.correctAnswer ? openQuestions?.[questionIndex]?.correctAnswer * 100 : 0);
    const [isRanked, setIsRanked] = useState<boolean>(false);   

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { toast } = useLocalToast();

    const handleNextButtonClick = () => {
        if (openQuestions && questionIndex + 1 < openQuestions.length) {
            const newIndex = questionIndex + 1;
            setQuestionIndex(newIndex);
            setIsRanked(openQuestions?.[newIndex]?.correctAnswer !== undefined);
            setRank(openQuestions?.[newIndex]?.correctAnswer ? openQuestions?.[newIndex]?.correctAnswer * 100 : 0);
        }else{ 
            onDoneRankingAnswers?.();
        }
    }

    const handleSubmitRank = (rank: number) => {
        setIsLoading(true);
        onSubmitQuestionRank(openQuestions?.[questionIndex]?.assessmentId, {
            questionUid: openQuestions?.[questionIndex]?.question?.name,
            score: rank / 100
        })
        .then((res) => {            
            if(!res) toast("Error submitting question rank", { containerId: "default", type: "error" });

            setIsRanked(res);
        })
        .catch(()=> toast("Error submitting question rank", { containerId: "default", type: "error" }))
        .finally(() => setIsLoading(false));
    }

    return (
        <div className="rank-open-questions-container">
            {isLoading &&
            <div className='rank-open-questions-loading'>
                <LNRDLoading/>
            </div>}
            <div className="rank-open-questions-question-container">
                <span className='rank-open-questions-question-header'>
                    You asked
                </span>
                <div className='rank-open-questions-question-candidate'>
                    "{ReactHtmlParser(openQuestions?.[questionIndex]?.question?.payload?.title 
                    ?? openQuestions?.[questionIndex]?.question?.title)}"
                </div>
            </div>

            <div className="rank-open-questions-answer-container">
                <span className='rank-open-questions-answer-header'>
                    {`${candidateFirstName ?? "The talent"} replied`}
                </span>
                <div className='rank-open-questions-answer-candidate'>
                    {ReactHtmlParser(openQuestions?.[questionIndex]?.question?.answer ?? 
                    openQuestions?.[questionIndex]?.question?.payload?.answer)}
                </div>
            </div>
            <div className='rank-open-questions-feedback-container'>
                <QuestionFeedback
                title={"Make sure your rank is as  objective as can be!"}
                description={"Below please find instructions about how to rank your answer. A full answer should have all the listed characteristics."}
                ranked={isRanked}
                questionRank={rank}
                setQuestionRank={setRank}
                rankPreviewProps={{
                    onClickEdit: () => setIsRanked(false),
                    onClickNextAnswerButton: handleNextButtonClick,
                    finishButton: !(openQuestions && questionIndex + 1 < openQuestions.length)
                }}
                instructors={
                    openQuestions?.[questionIndex]?.question?.payload?.
                    instructors?.
                    map((i: any) => i?.text)?.
                    filter((i: any) => i !== undefined) ?? []
                }
                onSubmitQuestionRank={handleSubmitRank}/>
            </div>
        </div>
    )
}

export default RankOpenQuestions;