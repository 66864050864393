import { useState, useContext } from "react";
import WizardStepTemplate from "@components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { IWizardStep } from "../../interfaces";
import SuggestionCard from "@/components/WizardComponents/SuggestionCard/SuggestionCard";
import { getSimilarRelatedSkills, searchSkillsOptions } from "./helpers";
import AutocompleteCustom, { StyledTextInput } from "@/components/WizardComponents/AutocompleteNew/AutocompleteNew";

import './style.css';
import { flatten } from "@/helpers";
import React from "react";
import AppContext from "@/context/AppContext";

const TITLE = `Choose which skills you want us to search for in the candidate's profile.`;
const DESCRIPTION = "Based on our research, the following are the skills, traits and roles & responsibilities that you will most likely look for for this position. Add or remove skills to customize your requirements."

const TraitsAndSkillsStep: React.FunctionComponent<IWizardStep> = ({ formik }):JSX.Element => {
    const { values, setFieldValue } = formik;
    const { matchingSkills, matchingCvKeywords } = values;
    
    const [loadingSkillOptions, setLoadingSkillOptions] = useState<boolean>(false);
    const [skillsSuggesstions, setSkillsSuggesstions] = useState<{ label: string, value: any }[]>([]);
    const [showAddSkillInput, setShowAddSkillInput] = useState<boolean>(false);

    const { tr } = useContext(AppContext)

    const onSkillsChanged = (newSkillsArr: { value: any, label: string }[]) => {
        const skills = newSkillsArr.map(s => ({            
            name: s.value,
            necessityLevel: "none",
            proficiency: "none"
        }))

        setFieldValue("matchingSkills", skills)

        getSimilarRelatedSkills(skills?.map(s => s.name) as any)
        .then((relatedSkills) => {
            setFieldValue("matchingRelatedSkills", relatedSkills)
        })
        .catch(console.error)
    }

    const onSkillsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        
        setLoadingSkillOptions(true);
        searchSkillsOptions(value as any)
        ?.then((skills) => {
            const newSkillsList = flatten([skills, matchingSkills?.map(s => ({ label: s.name, value: s.name })) ?? []])
            
            setSkillsSuggesstions([...newSkillsList])
        })
        ?.catch(console.error)
        ?.finally(() => setLoadingSkillOptions(false))
    }

    return (
        <WizardStepTemplate
        title={TITLE}
        description={tr(DESCRIPTION)}>
            {/* {!isLoading && !isSkillLoading && */}
            <div className="skills-step-container"> 
                <AutocompleteCustom
                label={tr("Traits and skills")}
                style={{ border: "none", width: "100%" }}
                options={skillsSuggesstions}
                loading={loadingSkillOptions}
                value={matchingSkills?.map(s => ({ label: s?.name, value: s?.name }))} 
                getOptionLabel={(option: {label: string, value: string}) => option.label}
                clearOptionOnClose={true}
                onChange={(e,v) => onSkillsChanged(v)}
                limitTags={10}
                renderCustomInput={(params, setInputValue) => (
                    <>
                        <StyledTextInput
                        autoFocus={true}
                        placeholder={tr("Search for traits and skills")}
                        className="skills-and-traits-add-skill-input"
                        onKeyDown={(event: any) => {
                            if (event.key === 'Backspace') {
                                event.stopPropagation();
                            }
                        }}
                        onBlur={() => {setInputValue(""); setShowAddSkillInput(false);}}
                        onChange={(e: any) => {
                            onSkillsInputChange(e);
                            setInputValue(e.target.value)
                        }}
                        sx={{
                            "& input": {
                                display: showAddSkillInput ? "block" : "none",
                            }
                        }}
                        {...params}
                        />
                        {!showAddSkillInput &&
                        <div>
                            <SuggestionCard 
                            className="skills-suggestion-card-custom-add-new"
                            selected={true}
                            suggestion={{ value: undefined, label: `+ ${tr("Add skill")}` }} 
                            onClick={() => {
                                setShowAddSkillInput(true)
                            }}/>
                        </div>}
                    </>
                )}/>
                <div>
                    <div className="skills-step-cv-keywords-container">
                    <AutocompleteCustom
                    style={{ width: "100%" }}
                    label={tr("Add any keywords that excite you on a candidate's CV")}
                    InputProps={{ placeholder: tr("E.g interests, volunteering etc") }}
                    getOptionLabel={(option: {label: string, value: string}) => option.label}
                    options={matchingCvKeywords?.map(s => ({ label: s, value: s })) ?? []}
                    value={matchingCvKeywords?.map(s => ({ label: s, value: s }))}
                    formatFreeSoloOption={(o: any) => ({ value: o?.value, label: o?.value })}
                    onChange={(e, v) => setFieldValue("matchingCvKeywords", v.map((s: any) => s.value))}
                    freeSolo={true}
                    />
                    </div>
                </div>
            </div>
            
        </WizardStepTemplate>
    )
}

export default TraitsAndSkillsStep;