/**
 *
 *  Review Process Hook (Logic)
 *
 *  @module pages/ReviewProcess/useReview
 *
 *  @author diegoulloao
 *
 */

// React
import { useState, useMemo } from "react"

// Router
import { useParams, useLocation } from "react-router-dom"

// SWR
import useSWR, { SWRResponse } from "swr"
import useSWRImmutable from "swr/immutable"

// Query
import { getCandidateInfoForReview, getComProfileReview } from "@queries/review"
import { getPositionInfoById } from "@queries/positions"

// Helpers
import { generateQueryKey, graphQueries } from "@helpers"
import { getReferrerByCode } from "@queries/referrers"

// Interfaces
import { Location } from "history"
import { IChartValues, IReview } from "@interfaces/pages/reviewProcess"
import { EvalTypes } from "@/pages/AssessmentEditor/data/editor.utils"

// Local Interfaces
interface IReviewProcessURLParams {
  positionId: string
  candidateId: string
}

// Fetcher
const fetcher = (query: string, candidateId: string) => 
graphQueries.sendRequest(query, { candidateId })
  .then((data: any) => {
    // Direct object access
    const highlightsObject: any[] = data.candidate?.scoredQuestionResponse?.result[0].report.skillevals[0].questions

    // We pick-up only the importance data
    const cleanedData: any = {
      candidate: {
        pdfUrl: data.candidate?.pdfUrl,
        personalInformation: {
          firstName: data.candidate?.firstName,
          lastName: data.candidate?.lastName,
          middleName: data.candidate?.middleName,
          email: data.candidate?.email,
          phoneNumber: data.candidate?.phone,
          referrer: data.candidate?.referrer
        },

        skippedQuestionResponse: data.candidate?.skippedQuestionResponse,

        comProfile: data.candidate?.comProfile,
        isSharingProfile: data.candidate?.isSharingProfile,

        candidateId,
        candidateStatus: data.candidate?.candidateStatus,

        highlights: (
          highlightsObject
            ?.filter((h: any) => h.question.type.toLowerCase() === "boolean")
            .map((e: any) => ({
              question: e.question.title || e.question.payload.title,
              answer: e.question.answer
            }))
        ),

        skills: (() => {
          const skillevals: any[] = data.candidate?.scoredQuestionResponse?.result[0].report.skillevals

          if (!skillevals) return

          const skillsCollection: any[] = []

          for ( let i = 0 ; i < skillevals.length ; i++ ) {
            skillsCollection.push({
              id: skillevals[i].evaluator?.id,
              name: skillevals[i].evaluator?.title,
              type: skillevals[i].evaluator?.eval_type,
              level: skillevals[i].evaluator?.level,
              skill: skillevals[i].evaluator?.skill,
              score: (skillevals[i]?.score !== -1)
                ? skillevals[i]?.score * 100
                : skillevals[i]?.score,
              factorScoring: skillevals[i]?.factorScoring * 100,
              questions: skillevals[i]?.questions
            })
          }

          // Filteres Skills with no Eligibility questions
          const filteredSkills: any = skillsCollection?.filter(
            (s: any) => s.type.toLowerCase().trim() !== EvalTypes.ELIGIBILITY
          )

          return filteredSkills
        })()
      }

      // position: {
      //   name: data?.position[0]?.title
      // }
    }

    return cleanedData
  })

const referrerFetcher = (query: string, code: string) =>
  graphQueries.sendRequest(query, { code })
  .then((data: any) => data.getReferrer.name)

const comprofileFetcher = (query: string, profileLetter: string) =>
  graphQueries.sendRequest (query, { profileLetter })
  .then((data: any) => data.comprofile)

/**
 *
 *  Use Review Custom Hook
 *  @description provides functionallity for Review Process
 *
 */
const useReview = (): IReview => {
  // States
  const [chartData, setChartData] = useState<IChartValues|null>(null)
  const [showShareModal, setShowShareModal] = useState<boolean>(false)

  // Router
  const { positionId, candidateId }: IReviewProcessURLParams = useParams()

  // Location
  const { state }: Location<{ positionName?: string }> = useLocation()

  // Query key based on id's is memorized (this avoid infinite re-renderings)
  const queryKey: string = useMemo<string>(
    () => generateQueryKey(`${positionId}-${candidateId}`), []
  )
 
  // SWR
  const { data, isValidating, error } = useSWR(
    [getCandidateInfoForReview(queryKey), candidateId],
    fetcher
  )

  const { data: dataForComProfile } = useSWRImmutable(
    [
      data?.candidate?.comProfile && data?.candidate?.isSharingProfile
        ? getComProfileReview(generateQueryKey(data?.candidate?.comProfile))
        : null,
        data?.candidate?.comProfile
    ],
    comprofileFetcher
  )

  const { data: referrerData } = useSWRImmutable(
    [
      data?.candidate.personalInformation.referrer
        ? getReferrerByCode(generateQueryKey(data?.candidate.personalInformation.referrer))
        : null,
      data?.candidate.personalInformation.referrer
    ],
    referrerFetcher
  )

  // gets position name only if it's not coming from route state
  const { data: positionInfo }: SWRResponse<any, Error> = useSWR(
    (!state?.positionName)
      ? [getPositionInfoById(generateQueryKey(positionId)), positionId]
      : null,

    // fetcher
    (query: string, positionId: string): Promise<any> => {
      return graphQueries.sendRequest(query, { positionId })
    }
  )

  return {
    data,
    error,
    chartData,
    positionInfo,
    referrerData,
    setChartData,
    showShareModal,
    setShowShareModal,
    isLoading: isValidating,
    dataForComProfile
  }
}

export { useReview }
