import { ChannelTypes, ChannelValues, TypeOfRelevency } from "../CandidateReport/V2/data/report.utils";

export interface ICandidate { 
    id: string;
    V2PositionId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    referrer?: string;
    deleted?: boolean;
    phone?: string;
    pdfUrl?: string;
    skippedQuestionResponse?: any;
    scoredQuestionResponse?: any;
    candidateStatus?: string;
    isSharingProfile?: boolean;
    comProfile?: string;
    comProfileResponses?: {};
    parsedLinkedinUrl?: {};
    createdAt?: string;
    updatedAt?: string;
    cvPath?: string;
    favoredBy?: string; 
    cvName?: string;
    cvFileUpdateTime?: string;
    linkedinUrl?: string;
    parsedCv?: {};
    parsedCvUpdateTime?: string;
    parsedLinkedin?: {};
    parsedLinkedinUpdateTime?: string;
    finalProcessedProfile?: {};
    finalProcessedProfileUpdateTime?: string;
    cv?: string;
    relevancy?: TypeOfRelevency;
    relevancyPerChannel?: {
        [key in ChannelTypes]: ChannelValues
    };
    blocksMeanings?: [JSON];
    imageUrl?: string;
    v2PositionTitle?: string;
    blocksScore: {
        [blockType: string]: {
            score?: number
        }
    };
    status: ProceedStatus | null;
}

export enum ProceedStatus {
    pending = 'pending',
    go = 'validated',
    notGo = 'rejected'
}