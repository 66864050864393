//Icons 
import infoIcon from '../../icons/info.svg';
import lightIcon from '../../icons/light.svg';

interface ISynergyTalentHrm {
    summary: any;
}

const SynergyTalentHrm: React.FunctionComponent<ISynergyTalentHrm> = (props):JSX.Element => {
    const { summary } = props;
    const objKeysArr = Object.keys(summary)
    
    const tableElements:React.FunctionComponent[] = [];
    for(let i=0; i<objKeysArr.length; i+=2) {
        
        if(summary[objKeysArr[i]] && summary[objKeysArr[i+1]]) {
            tableElements.push(
            () => <tr>
                <td style={{ width: "50%" }}>
                    <div className='table-cell-content'>
                        <img src={lightIcon}/>
                        <span>{summary[objKeysArr[i]]}</span>
                    </div>
                </td>
                <td style={{ width: "50%" }}>
                    <div className='table-cell-content'>
                        <img src={lightIcon}/>
                        <span>{summary[objKeysArr[i+1]]}</span>
                    </div>
                </td>
            </tr>
            )
        } 
        if( summary[objKeysArr[i]] && !summary[objKeysArr[i+1]]) {
            
            tableElements.push(
                () => <tr>
                <td>
                    <div className='table-cell-content'>
                        <img src={lightIcon}/>
                        <span>{summary[objKeysArr[i]]}</span>
                    </div>
                </td>
                <td>
                </td>
            </tr>
            )
        }
    }    
    
    return (
    <div className="synergy-talent-hrm-container no-print" >
        <div className="synergy-talent-hrm-description">
            <img src={infoIcon}/>
            <p>
                Here, you will find forecasts and recommendations on the synergy between the Manager and Talent. 
                They correspond to relational exchanges and situations when both parties are in full possession 
                of their personal resources, skills, and talents.
            </p>
        </div>
        <h3 className="synergy-talent-hrm-title">
            This working relationship has <span>POTENTIAL</span> to be in growth. Together you will: 
        </h3>
        <table className="talent-report-synergy-table">
        <tbody>
            {tableElements.map((E, i)=> (
                <E key={i+"synergy"}/>
            ))}
        </tbody>
        </table>
    </div>
    )
}

export default SynergyTalentHrm;