

export interface ITextSelectionState {
    save(): void;
    restore(): void;
}

class TextSelectionState<T extends (HTMLInputElement | HTMLTextAreaElement)> implements ITextSelectionState {
    private _start = 0;
    private _end = 0;
    constructor(private input: T) {
    }

    public save(): void {
        this._start = this.input.selectionStart || 0;
        this._end = this.input.selectionEnd || 0;
    }

    public restore(): void {
        this.input.selectionStart = this._start;
        this.input.selectionEnd = this._end;
    }
}

const _createTextSelectionState = (input: HTMLInputElement | HTMLTextAreaElement): ITextSelectionState => {
    return new TextSelectionState(input);
}

export const createTextSelectionState = _createTextSelectionState;
