import { BaseFormModel } from "./baseFormModel";

type QuestionTypes = "multiline" | "input" |
	"textarea" | "multiselect" | "image"
	| "email" | "custom" | "static" | "none"

interface ISelectValueRecord {
	label: string
	value: number
}


export interface IDisplayQuestion {
	readonly key: number | undefined
	/**
	 * The type of the question, e.g. "input"
	 */
	readonly type: QuestionTypes
	/**
	 * The value passed to the api call that fetches the content
	 */
	readonly valueType: string
	readonly placeholder: string
	/**
	 * Initial value to use
	 */
	readonly defaultValue: string
	value: string
	title: string
	/**
	 * Preferred way to obtain values for the control, e.g. select box
	 * If dataUrl is not present, use value
	 */
	readonly dataSource: string | Array<ISelectValueRecord>
	readonly required: boolean
	readonly isMulti: boolean
	readonly editable: boolean
	readonly inline: boolean
	readonly width: string | number | null
	readonly fullWidth: boolean
	readonly createLabel: string
	readonly noOptionsMessage: string
	readonly disabled: boolean
}

export class DisplayQuestion extends BaseFormModel<IDisplayQuestion> implements IDisplayQuestion {

	constructor(props: Partial<IDisplayQuestion> | undefined | null) {
		super(null)
		this.merge(props);
	}
	// Data members
	public type: QuestionTypes = "none"

	public key = undefined
	public valueType = "";
	public placeholder = ""
	public defaultValue = ""
	public value = ""
	public title = ""
	public required = false
	public isMulti = false
	public editable = true
	public inline = false
	public width: string | null = null
	public fullWidth = false
	public dataSource = ""
	public disabled = false

	public createLabel = ""
	public noOptionsMessage = ""

}