import { gql } from "graphql-request"

export const deletePosition = gql`
  mutation DeleteV2PositionMutation(
    $v2PositionId: UUID!
  ) {
    deleteV2Position(
      V2positionId: $v2PositionId
    ) {
      message
    }
  }
`