import { Slider, styled } from '@mui/material';

import './style.css';

const CustomSlider = styled(Slider)({
    color: '#50B685',
    height: 10,
    padding: 0,
    '& .MuiSlider-thumb': {
        width: 15,
        height: 15,
    },
    '& .MuiSlider-valueLabel': {
        backgroundColor: '#50B685',
    },
    '& .MuiSlider-rail': {
        backgroundColor: "#50B68540",
        opacity: "1"
    }
});  

export interface QuestionFeedbackProps {
    title?: string | React.ReactElement[],
    description?: string | React.ReactElement[],
    instructors?: string[] | React.ReactElement[],
    rankPreviewProps?: {
        onClickEdit?: () => void,
        onClickNextAnswerButton?: () => void,
        finishButton?: boolean,
    }
    onSubmitQuestionRank?: (rank: number) => void,
    ranked?: boolean
    questionRank: number
    setQuestionRank: (rank: number) => void
    hideRankControl?: boolean
}

const QuestionFeedback: React.FunctionComponent<QuestionFeedbackProps> = ({ 
    title, description, instructors, onSubmitQuestionRank, 
    setQuestionRank, rankPreviewProps, ranked, questionRank, hideRankControl
}):JSX.Element => {

    const renderQuestionTitle = (title: string | React.ReactElement[]): JSX.Element => {
        if (typeof title === "string") {
            return <h3>{title}</h3>
        } else {
            return <>{title}</>
        }
    }

    const renderQuestionDescription = (description: string | React.ReactElement[]): JSX.Element => {
        if (typeof description === "string") {
            return <span>{description}</span>
        } else {
            return <>{description}</>
        }
    }

    const renderQuestionInstructors = (instructors: string[] | React.ReactElement[]): JSX.Element => {
        const [firstInstructor, ] = instructors;
        if (typeof firstInstructor === "string") {
            return <ul>
                {instructors.map((instructor, index) => (
                    <li key={index}>{instructor}</li>
                ))}
            </ul>
        } else {
            return <>{instructors}</>
        }
    }

    let component = (
        <div className='report-question-feedback-container'>
            {!hideRankControl &&
            <div className='report-question-feedback-controllers'>
                <span className='question-feedback-controllers-label'>
                    Your Rank
                </span>
                <div className='question-feedback-controllers-slider-wrapper'>
                    <CustomSlider
                    min={0}
                    max={100}
                    defaultValue={0}
                    value={questionRank}
                    onChange={(event, value) => setQuestionRank(value as number)}
                    valueLabelDisplay='on'/>
                </div>
                <button 
                className='question-feedback-controllers-button'
                onClick={() => onSubmitQuestionRank?.(questionRank)}>Submit</button>
            </div>}
            {instructors && instructors.length > 0 && title &&
            <div className='report-question-feedback-instructors-container'>
                <div className="question-feedback-tips-span">
                    <span>What should I focus on?</span> 
                </div>
                <div className='report-question-feedback-instructors'>
                    <div className='feedback-instructors-traingle'></div>
                    {title &&
                    renderQuestionTitle(title)}
                    
                    {description &&
                    renderQuestionDescription(description)}
                    
                    {instructors && instructors.length > 0 &&
                    renderQuestionInstructors(instructors)}
                </div>
            </div>}
        </div>
    )

    if(ranked) {
        component = (
            <div className='rank-open-questions-ranked-contaienr'>
                <div className='rank-open-questions-ranked-preview'>
                    <span className='rank-open-questions-ranked-label'>
                        {`Thank you! Your score is ${questionRank}`}
                    </span>
                    <span 
                    onClick={rankPreviewProps?.onClickEdit}
                    className='rank-open-questions-ranked-edit-label'>
                        {"Edit score"}
                    </span>
                </div>
                <button 
                onClick={rankPreviewProps?.onClickNextAnswerButton}
                className='rank-open-questions-ranked-next-button'> 
                    {rankPreviewProps?.finishButton ? "Done" : "Next answer >"}
                </button>
            </div>
        )
    }
    return component
}

export default QuestionFeedback;
