import TextField from "../TextField/TextField"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import './style.css';

interface IConfirmationInput{
    inputValue: string;
    placeholder?: string;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onConfirm: () => any;
    onCancel: () => any;
    hideCancelButton?: boolean;
}

export const ConfirmationInput: React.FunctionComponent<IConfirmationInput> = ({
    inputValue,
    placeholder,
    handleInputChange,
    onConfirm,
    onCancel,
    hideCancelButton,
}): JSX.Element => {
    return (
    <div className="confirmation-input-container">
        <TextField
        uiType="white-bordered"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}/>
        <IconButton 
        onClick={() => onConfirm()}
        className="confirmation-input-confirm-button">
            <CheckIcon/> 
        </IconButton>
        {!hideCancelButton &&
        <IconButton 
        onClick={() => onCancel()}
        className="confirmation-input-confirm-button">
            <CloseIcon/> 
        </IconButton>}
    </div>
    )
}