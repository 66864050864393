// React
import { useContext, useEffect, useState } from 'react'

// Wizard Form Context
import { WizardFormContext } from "@components/WizardForm/context"

// Helpers
import { imageUtils, isURL, objectUtils, toBase64 } from "@helpers"
import { formModelUtils } from '@/models/formModelUtils'
import { QuestionCollection } from '@/models/formPage'
import { UploadImageProps } from '@/interfaces/components/uploadImage'
import { IUploadResult } from '@/helpers/mediaUploader'

// Interfaces
interface IUploadImage {
  uploadAndPreview: (files: File[]) => Promise<void>
  preview: File | Base64 | string | null
  isUploading: boolean
}

// Local Types for Base64
type Opaque<T, K extends string> = T & { __typename: K }
type Base64 = Opaque<string, "base64">

const resizeImage = async (imageData: string | null | undefined, maxSize: number | undefined): Promise<string> => {
  if (!maxSize || isNaN(maxSize) || !imageData) {
    return String(imageData || "")
  }
  return await imageUtils.resizeImageData(imageData, maxSize)
}

const useUploadImage = ({ pageIndex, index, subIndex, isCustom, maxSize: maxWidth }: UploadImageProps): IUploadImage => {
  // Const
  const questionTypeKey = !isCustom ? "questions" : "customQuestions"

  // Context
  const { setDataCollection, dataCollection, templateQuestions, customQuestions, uploader, setError, autoUpload, setDirty } = useContext(WizardFormContext)

  const extractImageUrl = (): string | null =>
    objectUtils.getValueByKeys(dataCollection,
      [pageIndex, questionTypeKey, index, subIndex, "value"], null)


      // States
  const [preview, setPreview] = useState<File | Base64 | string | null>((): Base64 | string | null => {
    return extractImageUrl()
  })

  const [isUploading, setIsUploading] = useState<boolean>(false)

  useEffect(() => {
    if (autoUpload && !isUploading) {
      const imageData = extractImageUrl()
      if (imageData && !isURL(imageData)) {
        (async () => {
          const resized = await resizeImage(extractImageUrl(), maxWidth)
          if (!resized) {
            setError("Failed to load stored image")
            return
          }
          setIsUploading(true)
          uploader.upload({ data: resized })
          .then(result => {
            setIsUploading(false)
            if (result.url) {
              setPreview(result.url)
              saveValueToDataCollection(result.url)
            }
            else {
              setError(`upload error ${result.error || "unknown"}`)
            }
          })
        })()
      }
    }
  }, [])
1
  useEffect(() => {
    const newUrl = extractImageUrl()
    if (newUrl !== preview) {
      setPreview(newUrl)
    }
  }, [dataCollection])


  /**
   * Updates the data collection with a new value for the image and its title
   * @param value
   * @param title 
   */
  const saveValueToDataCollection = (value: string, title?: string) => {
    setDataCollection((dc: any) => {
      const newDc: any = [...dc]
      const questions: QuestionCollection = newDc[pageIndex][questionTypeKey]
      const templates = isCustom ? customQuestions : templateQuestions
      const data: any = { value }
      if (title) {
        data.title = title
      }
      formModelUtils.setValueInQuestionCollection({
        collection: questions,
        index,
        subIndex,
        template: templates[pageIndex][index][subIndex],
        data
      })
      return newDc

    })
    setDirty(true);


  }

  // Handlers
  const uploadAndPreview = async (files: File[]): Promise<void> => {
    const file = files[0]
    const imageData = (file && await toBase64(file)) || ""
    // Image name
    const imgName = file?.name
    if (imageData && uploader) {
      setIsUploading(true)
      uploader.upload({
        data: imageData,
        name: imgName
      })
        .then((result: IUploadResult) => {
          setIsUploading(false)
          if (result.url) {
            setPreview(result.url)
            saveValueToDataCollection(result.url, imgName)
          }
          else if (result.error) {
            setError(`Image upload error: ${result.error}`)
          }
        })
    }
    // In the meantime, set the preview to the raw file data
    setPreview(file)

    // Convert Image to base
    const base64Image = await imageUtils.resizeImageData(imageData, maxWidth)

    // Save it in Data Collection
    return saveValueToDataCollection(base64Image, imgName)
  }

  void autoUpload // lint

  return {
    uploadAndPreview,
    isUploading,
    preview
  }
}

export default useUploadImage
export type { IUploadImage }
