import './style.css';

import ReactHtmlParser from 'react-html-parser';
import { UnboxableButton } from '@/components';
import { FormattedRecommendations } from '../../hooks/useCandidateReport';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const ReccomendationAccordion: React.FunctionComponent<{ 
    reccomendations?: FormattedRecommendations
}> = 
({ reccomendations }): JSX.Element => {
    
    const [ openSections, setOpenSections ] = useState<{ [section: string]: boolean }>(getInitialOpenSectionState());

    function getInitialOpenSectionState() {
        const state: { [section: string]: boolean } = {};
        Object.keys(reccomendations ?? { }).forEach((k, i) => {
            state[k] = i === 0;
        });
        return state;
    }

    return <>
        {reccomendations &&
        Object.entries(reccomendations)?.map(([section, reccomendations], index) => (
            <div key={index} className='reccomendation-card-container'>
                <div 
                onClick={() => setOpenSections(prev => ({
                    ...prev,
                    [section]: !prev[section]
                }))}
                className='reccomendation-card-section-title-container'>
                    <h2 className='reccomendation-card-section-title'>
                        {ReactHtmlParser(section)}
                    </h2>
                    {openSections[section] ? 
                    <ExpandLessIcon/> 
                    : <ExpandMoreIcon/>}
                </div>
                <div className={`reccomendation-card-reccomendations-container ${openSections[section] ? 'reccomendation-card-reccomendations-container-open' : 'reccomendation-card-reccomendations-container-close'}`}>
                <ul
                    className={'reccomendation-card-reccomendations'}>
                        {reccomendations && 
                        reccomendations.hr.map((reccomendation, index) => ( 
                            <li key={index} className={"reccomendation-card-reccomendation"}>
                                <div className='reccomendation-card-reccomendation-user-details'>
                                    {reccomendation.User?.userImage ? 
                                    <img src={reccomendation.User?.userImage} className={"reccomendation-card-reccomendation-image"}/>
                                    : <div className='reccomendation-card-reccomendation-image reccomendation-image-blue'> 
                                        <span> 
                                            {`${reccomendation.User?.firstName[0] ?? "User"}${reccomendation.User?.lastName?.[0] ?? ""}`}
                                        </span>
                                    </div>}
                                    <span>{`${reccomendation.User?.firstName ?? "User"} ${reccomendation.User?.lastName ?? ""}`}</span>
                                </div>
                                <div className='reccomendation-card-reccomendation-hr-message'>
                                    {ReactHtmlParser(reccomendation?.recommendationText ?? "")}
                                </div>
                            </li>
                        ))}
                    </ul>
                    <ul
                    className={'reccomendation-card-reccomendations'}>
                        {reccomendations && 
                        reccomendations.unboxable.map((reccomendation, index) => ( 
                            <li key={index} className={"reccomendation-card-reccomendation"}>
                                <div className='reccomendation-card-reccomendation-user-details'>
                                    <div className='reccomendation-card-reccomendation-image'>U</div>
                                    <span>Unboxable</span>
                                </div>
                                <div className='reccomendation-card-reccomendation-hr-message'>
                                    {ReactHtmlParser(reccomendation?.recommendationText ?? "")}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        ))}
    </>
}

const ReccomendationCenter: React.FunctionComponent<{ 
    firstName?: string, 
    reccomendations?: FormattedRecommendations,
    onClickAddRecommendationBtn?: () => any
}> = ({ firstName, reccomendations, onClickAddRecommendationBtn }):JSX.Element => {
    return (
        <div className="reccomendation-center-container">
            <div className="reccomendation-center-header-container">
                <h1 className='reccomendation-center-header'>
                What to pay attention to in regards to {firstName}
                </h1>
            </div>
            <p className='reccomendation-center-explenation'>
            Based on Unboxable Hiring DNA, here is a list of recommendations and tips to help you  prepare and conduct a great hiring process.   
            </p>
            <UnboxableButton 
            onClick={onClickAddRecommendationBtn}
            className='recommendation-center-add-recommendation-btn'
            btnStyle={'black' as any}>
                + Add recommendation
            </UnboxableButton>
            <div className='reccomendation-center-reccomendations'>
                <ReccomendationAccordion 
                reccomendations={reccomendations}/>
            </div>
        </div>
    )
}

export default ReccomendationCenter;