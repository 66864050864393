// Helpers
import { comProfileToWorkStyle } from "../comProifleHelpers";

// Styles
import "./style.css";
import CommunicationStyleDescription from "./CommunicationStyleDescription";
import Message from "../../components/Message/Message";
import ComprofileIcons from "../../components/ComprofileIcons";

// Interfaces
interface ICommunicationStyle {
  talentfirstName: string;
  talentComProfile: any;
}

const CommunicationStyle: React.FunctionComponent<ICommunicationStyle> = (
  props
): JSX.Element => {
  const { talentfirstName, talentComProfile } = props;
  return (
    <div className="communication-style" style={{ marginTop: "100px" }}>
      <div className="talent-report-communication-style-headers">
        <h2>
          {talentfirstName ? talentfirstName : "Anonymous"}'s Communication
          Style
        </h2>
        <span style={{ fontSize: "12px", color: "#A1A1A1" }}>
          WHAT CAN YOU LEARN?
        </span>
      </div>
        <div className="talent-report-communication-style-workstyle">
          <h3>
            This Talent belongs <br />
            to the <span>work style of</span>
          </h3>
          <div className="talent-report-communication-style-icon">
            <div className="talent-report-communication-style-icon-image">
              <ComprofileIcons
                comProfile={talentComProfile?.profile}
                color={"#F8F3EE"}
              />
            </div>
            <span>
              {comProfileToWorkStyle[talentComProfile?.profile].toUpperCase()}
            </span>
          </div>
          <a
            className="talent-report-communication-style-icon-read-more"
            href={window.location.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more
          </a>
        </div>
      {/* Communication Style Description */}
      <CommunicationStyleDescription
        comProfile={talentComProfile.profile}
        qualities={talentComProfile.qualities}
        strengths={talentComProfile.strengths}
        values={talentComProfile.values}
        needs={talentComProfile.needs}
      />
      {/* Ask Yourself */}
      <Message>
        <h3 className="talent-report-ask-yourself-message no-print">
          <span>Ask yourself,</span> Can me and my team provide this?
        </h3>
      </Message>
    </div>
  );
};

export default CommunicationStyle;
