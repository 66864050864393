import { getPositionById } from "@/graphql/queries/positions"
import { graphQueries } from "@/helpers"
import { IPosition } from "@/interfaces/pages/positions";
import { BuildingBlock } from "@/pages/BuildingBlocks/BuildingBlockEditor";
import {
    CILENT_ORIGIN,
    EditorApiHeaders as headers,
    GQL_EDITOR,
    EditorTables,
    CommonCols,
    AssessmentStatus} from "./editor.utils"
import { ElasticQuestionsResponse, IAssessment, IQuestion, ISkill, ISkillEval } from "./types"

// Do not move this url to .env file (Idan Gibly)
const EDITOR_ORIGIN = `http://localhost:4000`;
class Fetcher {
    static async fetch(url: string, options: any): Promise<any> {
        let result = null
        try {
            result = await fetch(url, options)
        } catch(e) {
            console.error(e)
        }
        return result
    }

    static async request(query: string, params: any) : Promise<any> {

        let result = null
        try {
            result = await graphQueries.sendRequest(query, params)
        } catch(e) {
            console.error(e)
        }
        return result
    }
}

async function GraphQlEditor(data: {
    path: string,
    method: 'post' | 'get' | 'delete',
    body?: any,
    noDataKey?: any
}): Promise<any> {
    try {
        const { path, method, body, noDataKey } = data
        const args = {
            "requestToEditorRequestType": method,
            "requestToEditorRequestPath": path,
            "requestToEditorParams": noDataKey ? body : { data: body }
        }

        const { requestToEditor } = await Fetcher.request(GQL_EDITOR, args)
        const { response } = requestToEditor || {}
        return response
    } catch(e) {
       console.error(e)
    }
    return null
}

async function RESTEditor(data: {
    path: string,
    method: 'post' | 'get' | 'delete',
    body?: any
}): Promise<any> {
    try {
        const { path, method, body } = data
        const res = await Fetcher.fetch(`${EDITOR_ORIGIN}${path}`, {
            method,
            headers,
            ...body && { body: JSON.stringify({ data: body }) }
        })
        const json = await res.json()
        return json
    } catch(e) {
       console.error(e)
    }
    return null
}
enum Protocols { REST, GQL }
const protocolApi = [ RESTEditor, GraphQlEditor ][Protocols.GQL]//[protocol]

export class EditorApi {

    static process = false
    public static editorClientUrl =`${CILENT_ORIGIN}/survey`

    private static async get(name: string, query: {[k: string]: any}) {
        this.process = true
        let r =  { result: [] }
        const path = `/api/read/${name}?${new URLSearchParams(query)}`
        try {
            r = await protocolApi({
                path,
                method: "get"
            })
        } catch(e) {
            console.error(e)
        } finally {
            this.process = false
        }
        return r
    }
    private static async post(name: string, data: {[k: string]: any }) {
        const path = `/api/write/${name}`
        this.process = true
        let r  =  { result: [] }
        try {

            r = await protocolApi({
                path,
                method: "post",
                body: data
            })
        } catch(e) {
            console.error(e)
        } finally {
            this.process = false

        }
        return r
    }
    private static async del(name: string, id: string ) {
        const path = `/api/delete/${name}/${id}`
        let r =  { result: 0 }
        this.process = true
        try {
            r = await protocolApi({
                path,
                method: 'delete'
            })
        } catch(e) {
            console.error(e)
        } finally {
            this.process = false
        }
        return r
    }



    static getEvalSurveyUrl(evl: ISkillEval | null): string {
        const { id } = evl || {}
        if(!id) { return '' }
        const query = new URLSearchParams([
            ['id', `${id}`],
            ['baseUrl', encodeURIComponent(EDITOR_ORIGIN)]
        ])
        return `${EditorApi.editorClientUrl}?${query}`
    }

    static async getSkillEvals(query: Partial<ISkillEval>): Promise<ISkillEval[]> {
        const { result } = await EditorApi.get(EditorTables.SKILL_EVALS, query)
        return result
    }

    static async getProficiencyLevels(): Promise<string[]> {
        const { result } = await EditorApi.get(EditorTables.COMMON, { type: CommonCols.PROFICIENCY})
        return result.map( (r: any) => r.name)
    }

    static async getNecessityLevels(): Promise<string[]> {
        const { result } = await EditorApi.get(EditorTables.COMMON, { type: CommonCols.NECESSITY})
        return result.map( (r: any) => r.name)
    }

    static async deleteSkillEvals(id: string): Promise<number> {
        const { result } = await EditorApi.del(EditorTables.SKILL_EVALS, id)
        return result
    }

    static async deleteAssessmentByPositionId(position_id: string): Promise<boolean> {
        if( !position_id ) {
            return false;
        }
        const assessments = await EditorApi.getAssessment({ position_id });
        if(assessments.length === 1) {
            const { id } = assessments[0];
            if(!id) {
                return false;
            }
            const { result } = await EditorApi.del(EditorTables.ASSESSMENTS, id);
            return result === 1;
        }
        return false;
    }

    static async getSkills(query: Partial<ISkill | Partial<{
        only_matches: string,
        create: string
        many_field: string,
        many_values: string
    }>>): Promise<ISkill[]>  {
        const { result } = await EditorApi.get(EditorTables.SKILLS, query)
        return result
    }

    static async postSkills(data: Partial<ISkill>): Promise<ISkill[]>  {
        const { result } = await EditorApi.post(EditorTables.SKILLS, data)
        return result
    }

    static async getPositions(id: string): Promise<IPosition[]>  {
        // get position from lnrd-engine api
        //"722c599b-ab07-4958-9c89-7bafc0e663fe"
        const res = await graphQueries.sendRequest(getPositionById, {
            "positionId": id,
            "sizeOfPage": 1,
            "page": 0
        })
        const { getV2Position: result } = res
        return result
    }

    static async getAssessment(query: Partial<IAssessment>): Promise<IAssessment[]>  {
        const { result } = await EditorApi.get(EditorTables.ASSESSMENTS, query)
        return result
    }

    static async deleteAssessment(id: string): Promise<number> {
        const { result } = await EditorApi.del(EditorTables.ASSESSMENTS, id)
        return result
    }

    static async getReportMeanings(): Promise<string[]>  {
        const { result } = await EditorApi.get(EditorTables.REPORT_MEANINGS, {})
        return result
    }

    static async postPosition(data: Partial<IPosition>): Promise<IPosition[]>  {
        const { result } = await EditorApi.post(EditorTables.POSITIONS, data)
        return result
    }

    static async postAssessmentBySkills(assessment: Partial<IAssessment>, skills: { level?: string, name: string }[]): Promise<IAssessment[]>  {
        const { result } = await EditorApi.post(EditorTables.ASSESSMENTS, { assessment, skills })
        return result
    }

    static async postAssessment(data: Partial<IAssessment>): Promise<IAssessment[]>  {
        const { result } = await EditorApi.post(EditorTables.ASSESSMENTS, data)
        return result
    }

    static async changeAssessmentStatus(positionId: string, status: AssessmentStatus): Promise<any> {
        const { result } = await EditorApi.post(`${EditorTables.ASSESSMENTS}/status/${positionId}`, { status })
        return result
    }

    static async postAssessmentSkills(id: string, skills: any[]): Promise<IAssessment[]>  {
        const path = `${EditorTables.ASSESSMENTS}/${id}/skills`;
        const { result } = await EditorApi.post(path, skills);
        return result
    }

    static async postAssessmentQuestions(id: string, questions: any[], skillevalId = ''): Promise<IAssessment[]>  {
        const path = `${EditorTables.ASSESSMENTS}/${id}/questions/${skillevalId}`;
        const { result } = await EditorApi.post(path, questions);
        return result
    }

    static async postSkillEvals(data: Partial<ISkillEval>): Promise<ISkillEval[]>  {
        const { result } = await EditorApi.post(EditorTables.SKILL_EVALS, data)
        return result
    }

    static async getCommonByType(args: { type: string, name?: string, size?: number }): Promise<string[]> {
        const { type, name = "", size = 5 } = args;
        const { result } = await EditorApi.get(EditorTables.COMMON, { type, size, name })
        return result;
    }

    static async postAssessmentsByPosition(data: Partial<IPosition>): Promise<IAssessment[]>  {
        const { result } = await EditorApi.post(`${EditorTables.ASSESSMENTS}/buildingblocks`, data)
        return result
    }

    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    static async searchQuestions(elasticQuery: any): Promise<ElasticQuestionsResponse> {
        const { result } =  await protocolApi({
            path: `/api/search/questions`,
            method: "post",
            body: {elasticQuery},
            noDataKey: true
        });
        return result;
    }

    static async postQuestionToAssessment(assessId: string, skillevalId:string, data: IQuestion[]): Promise<IAssessment[]>  {
        const { result } = await EditorApi.post(`${EditorTables.ASSESSMENTS}/${assessId}/questions/${skillevalId}`, data)

        return result;
    }

    static async getBuildingBlocks(): Promise<BuildingBlock[]>  {
        const { result } = await EditorApi.get(`${EditorTables.BUILDING_BLOCKS}`, {})

        return result;
    }

    static async postBuildingBlock(buildingBlock: BuildingBlock): Promise<BuildingBlock[]>  {        
        const { result } = await EditorApi.post(`${EditorTables.BUILDING_BLOCKS}`, buildingBlock)

        return result;
    }

    static async deleteBuildingBlock(buildingBlockId: string): Promise<number>  {        
        const { result } = await EditorApi.del(`${EditorTables.BUILDING_BLOCKS}`, buildingBlockId)

        return result;
    }

    static async orderBuildingBlocks(targetId: string, afterId: string): Promise<{result: BuildingBlock[] | null, errors?: any[]}>  {
        const response = await EditorApi.post(`${EditorTables.BUILDING_BLOCKS}/order`, {
            targetId,
            afterId
        })

        return response as unknown as {result: BuildingBlock[] | null, errors?: any[]};
    }

    static async cloneAssessment(destPositionId: string, assessId: string): Promise<IAssessment[]>  {
        const { result } = await EditorApi.post(`${EditorTables.ASSESSMENTS}/clone`, {
            dest_position_id: destPositionId,
            source_assessment_id: assessId
        })

        return result;
    }

    static async updateAssessmentMetadata(data: { questions: any[] }, assessId: string): Promise<IAssessment[]> {
        const { result } = await EditorApi.post(`${EditorTables.ASSESSMENTS}/metadata/${assessId}`, data);

        return result
    }
}
