// Com-profile Main Icons
import protectionIcon from '../icons/comprofiles-icons/Protection.svg';
import controlIcon from '../icons/comprofiles-icons/Control.svg';
import collaborationIcon from '../icons/comprofiles-icons/Collaboration .svg';
import ideasIcon from '../icons/comprofiles-icons/Ideas.svg';
import competiotionIcon from '../icons/comprofiles-icons/Competition .svg';
import stabilityIcon from '../icons/comprofiles-icons/Stability .svg';
import innovationIcon from '../icons/comprofiles-icons/Innovation.svg';
import facilitationIcon from '../icons/comprofiles-icons/Faciliation.svg';
import safetyIcon from '../icons/comprofiles-icons/Safety .svg';

enum CompetabilityProfileWorkStyle {
    RV = "Protective Authority",
    SV = "Control",
    R = "Relationships & People",
    I = "Ideas",
    V = "Competition",
    S = "Structures",
    IV = "Innovation",
    RI = "Facilitation",
    RS = "Safety"
}
export enum ComProfile {
    RV = "RV",
    SV = "SV",
    R = "R",
    I = "I",
    V = "V",
    S = "S",
    IV = "IV",
    RI = "RI",
    RS = "RS"
}

export const comProfileToSrcIcon: any = {
    "RV": protectionIcon,
    "SV": controlIcon,
    "R": collaborationIcon,
    "I": ideasIcon,
    "V": competiotionIcon,
    "S": stabilityIcon,
    "IV": innovationIcon,
    "RI": facilitationIcon,
    "RS": safetyIcon
}


export const comProfileToWorkStyle: any = {
    "RV" : CompetabilityProfileWorkStyle.RV,
    "SV" : CompetabilityProfileWorkStyle.SV,
    "R" : CompetabilityProfileWorkStyle.R,
    "I" : CompetabilityProfileWorkStyle.I,
    "V" : CompetabilityProfileWorkStyle.V,
    "S" : CompetabilityProfileWorkStyle.S,
    "IV" : CompetabilityProfileWorkStyle.IV,
    "RI" : CompetabilityProfileWorkStyle.RI,
    "RS" : CompetabilityProfileWorkStyle.RS,
}

