import { Slider, styled } from '@mui/material';


const CustomSlider = styled(Slider)({
    color: '#FF8E5A',
    pointerEvents: "none",
    height: 4,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      display: "none",
    },
    '& .MuiSlider-rail': {
        backgroundColor: "black",
        opacity: "1"
    }
});  

export default CustomSlider;