
import { IPosition } from "@/interfaces/pages/positions";
import SuggestionCard from "@components/WizardComponents/SuggestionCard/SuggestionCard";
import { UserIcon } from "@heroicons/react/solid";
import { Dialog, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import ReactHtmlParser from 'react-html-parser';
// import TextField from "@/components/WizardComponents/TextField/TextField";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { SelectCustomStyles_From_Scratch } from "@/components/UnboxableForm/ui/styles/select-custom";
// import { Button } from "@/components";
import { getPositionDetails, IPositionDetails, _searchPositions } from "./helpers";
import { LNRDLoading } from "@/components";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { throttle } from "lodash";
import TextField from "@/components/WizardComponents/TextField/TextField";


export const OtherPositionSuggestions: React.FunctionComponent<{ 
    otherPositionSuggestions?: {value: any, label: string, position: IPosition}[],
    onClick: (suggestion: {value: any, label: string, position: IPosition}) => any,
    withToolTip?: boolean,
    selectedPosition?: Partial<IPosition>,
}> = ({
    otherPositionSuggestions,
    onClick,
    withToolTip,
    selectedPosition,
}): JSX.Element => {

    const showTooltipDescription = (s: {value: any, label: string, position: IPosition}): boolean => {
        return s?.position?.description ? true : false
    } 

    const showTooltipSkills= (s: {value: any, label: string, position: IPosition}): boolean => {
        return s?.position?.skills ? true : false
    } 

    const showTooltip = (s: {value: any, label: string, position: IPosition}): boolean => {
        return (showTooltipSkills(s) || showTooltipDescription(s))
    } 

    const toolTipEl = (suggestion: {value: any, label: string, position: IPosition}) => {
        if(!showTooltip(suggestion)) return "";
        
        const { position } = suggestion;
        return (
            <SuggestionHover position={position}/>
        )
    }

    return <> 
        {otherPositionSuggestions?.map((s,i) => (
                <SuggestionCard
                key={`${i}-${s.value}`}
                suggestion={s}
                selected={selectedPosition?.id === s.position.id}
                showDeleteIcon={false}
                onClick={() => onClick({...s})}
                toolTipComponent={withToolTip && toolTipEl(s)}>
                    {s?.position?.matchingTitle || s?.position?.title}
                </SuggestionCard>
        ))}
    </>
}

const layoutStyles = {
    container: "pt-8 flex flex-col justify-center items-start text-gray-500",
    body: "flex flex-wrap items-start",
    head: "w-1/2",
    selectionActions: "box-border flex flex-wrap w-1/2",
    selectionActionsBody: "flex flex-wrap",
    selectionView:"border-l border-dashed box-border flex flex-col flex-wrap w-1/2 items-center h-full",
    selectionViewTitle: "mb-3 flex font-bold items-center font-lg",
    selectionViewDescription:"mb-3 truncate max-h-36 leading-6 whitespace-normal",
    selectionViewSkills: "mb-3",
}

const widgetsStyles = {
    chip: `inline-flex items-center inline-block bg-gray-800
    text-white py-3 px-4 mr-4 my-2 truncate
    whitespace-normal max-w-xs rounded-lg cursor-pointer`,
    select: "flex justify-center flex-col my-2 w-80",
    selectLabelTitle: "font-bold text-gray-800",
    selectLabelDescription: "leading-6 text-sm max-h-12 overflow-hidden text-gray-500",
    icon: "inline mr-2 w-4 h-4",
    selectionViewSkill: "flex my-2",
    selectionViewSkillItem: "truncate rounded-lg mr-1 px-2 py-1 bg-gray-300",
}

export const SuggestionHover: React.FunctionComponent<{ position: IPosition }> = ({position}): JSX.Element => {
    return (
        <div
        className="wizard-create-new-position-hovered-suggestion-container"
        // style={{ minWidth: "400px" }}
    >
        <div className={layoutStyles.selectionViewTitle}>
        <UserIcon className={widgetsStyles.icon} />
        <span>{position.matchingTitle || position.title}</span>
        </div>

        {(position.description || position.matchingDescription) && (
        <div className={layoutStyles.selectionViewDescription}>
            <span>{ReactHtmlParser(position.matchingDescription || position.description)}</span>
        </div>
        )}

        <div className={layoutStyles.selectionViewSkills}>
        {
            (position?.skills?.length > 0 || position?.matchingSkills?.length > 0) &&
            <div className="font-bold">Skills</div>
        }
        <div>
            {(position.matchingSkills || position?.skills)?.map((s: any, i: number) =>
            <div className={widgetsStyles.selectionViewSkill}
                key={i} >
                {s?.name && <div className={widgetsStyles.selectionViewSkillItem + ' flex-grow'}>{s.name}</div>}
                {/* {s?.proficiency && <div title={s.proficiency}
                className={widgetsStyles.selectionViewSkillItem + ' text-center w-16'}>{
                    s.proficiency[0].toUpperCase()}</div>} */}
            </div>)}
        </div>
        </div>
    </div>
    )
}

const PositionPreview: React.FunctionComponent<{ 
    position: Partial<IPosition> 
}> = ({position}): JSX.Element => {
    const { matchingTitle, matchingDescription, matchingSkills, matchingEducation } = position;
    return (
        <div className="position-preview-container">
            <h2 className="position-preview-title">
                {matchingTitle}
            </h2>
            <p className="position-preview-description">{matchingDescription}</p>
            <div className="position-preview-items-container">
                <span className="position-preview-items-label">Skills:</span>
                <div className="position-preview-items-items">
                    {matchingSkills?.map((s: any, i: number) => (
                        <SuggestionCard
                        key={i}
                        className="position-preview-items-item"
                        suggestion={{ value: s.name, label: s.name }}/>
                    ))}
                </div>
            </div>
            <div className="position-preview-items-container">
                <span className="position-preview-items-label">Education:</span>
                <div className="position-preview-items-items">
                    {matchingEducation?.map((e: any, i: number) => (
                        <SuggestionCard
                        key={i}
                        className="position-preview-items-item"
                        suggestion={{ value: e, label: e }}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

const dialogStyle = {
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            minWidth: "658px",
            height: "450px",
            maxHeight: "450px"
        },
    },
}

const getPositionOptions = async (name: string) => {
    try {
        const positions = await _searchPositions(name);
        const options = positions?.map((p: string) => ({ value: p, label: p })) || [];
        return options;
    } catch (e) {
        return [];
    }

} 

export const CreatePositionFromScratchModal: React.FunctionComponent<{ 
    isModalOpen: boolean, 
    onClose: (args: any) => any,
    onSubitPositionFromScracth: (position: Partial<IPosition>) => any, 
}> = ({ 
    isModalOpen, 
    onClose,
    onSubitPositionFromScracth,
}): JSX.Element => {
    const [isResultsLoading, setIsResultsLoading] = useState<boolean>(false);
    const [isPositionLoading, setIsPositionLoading] = useState<boolean>(false);
    const [freshStart, setFreshStart] = useState<boolean>(false);

    const [selectValue, setSelectValue] = useState<string>("");
    const [positionOptions, setPositionOptions] = useState<{value: string, label: string}[] | undefined>([]);

    const [selectedPosition, setSelectedPosition] = useState<IPositionDetails| null>(null);
    const [formattedPosition, setFormattedPosition] = useState<Partial<IPosition>| null>(null);

    const [freshStartInputValue, setFreshStartInputValue] = useState<string>("");

    const throttleGetJobsSuggestions = useCallback(throttle(getPositionOptions, 1000), [selectValue]);
    
    const onSelectChanged = async (newValue: any, actionMeta: any) => {
        const { action } = actionMeta || {};
        
        const isInputChanged = action === 'input-change';
        const isOptionSelected = action === 'select-option';
        setSelectValue(newValue);
        if(isInputChanged) {
            setSelectValue(newValue);
            setIsResultsLoading(true);
            throttleGetJobsSuggestions(newValue)
            ?.then(
                s => setPositionOptions(s))
            ?.catch(e => {
                console.error(e);
                setPositionOptions([]);
            })
            ?.finally(() =>  setIsResultsLoading(false));
        }
        if(isOptionSelected) {
            setSelectValue(newValue.value)
            setIsPositionLoading(true)
            getPositionDetails(newValue.value)
            .then(setSelectedPosition)
            .catch(console.error)
            .finally(() => setIsPositionLoading(false));
        }
    }

    const formatPositionDetails = async (position: IPositionDetails): Promise<Partial<IPosition>> => {
        const { JobProfileData, ESCO } = position;
        const { JobProfile: title } = ESCO || {};
        const { 
            Skills: skills, 
            Education: education,
            Description: description,
        } = JobProfileData || {};
        
        return {
            title,
            matchingTitle: title,
            matchingDescription: description,
            matchingSkills: skills?.map((s: any) => ({ name: s.Skill, proficiency: 'none', necessityLevel: 'none' })),
            matchingEducation: education,
        }
    }

    const onSubmit = () => {
        if(freshStart) {
            return onSubitPositionFromScracth({
                matchingTitle: freshStartInputValue,
                title: freshStartInputValue
            })
        }
        return onSubitPositionFromScracth(formattedPosition!)
    }

    useEffect(() => {
        if(!selectedPosition) return;

        formatPositionDetails(selectedPosition)
        .then(setFormattedPosition)
        .catch(console.error);

    },[selectedPosition])

    return (
        <Dialog
        sx={dialogStyle}
        onClose={onClose}
        open={isModalOpen}>
            <div className="create-position-from-scratch-modal">
                <div className="create-position-from-scratch-container">
                    <span className="create-position-from-scratch-modal-title">
                        Search positions names
                    </span>
                    <p className="create-position-from-scratch-modal-description">
                        Use this tool only to create <b>new positions</b>. based on your query, it will retrieve popular titles, 
                        relevant skills, a job description and R&R. <span><b>Note!</b> If you already created a similar position before, 
                        pls create and edit a copy from the position bank).</span>
                    </p>
                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // name="introType"
                    value={String(freshStart)}
                    style={{ display: "flex", flexDirection: "row" }}
                    onChange={(e) => {
                        const val = e.target.value;
                        setFreshStart(val === "true");
                    }}>
                        <FormControlLabel
                        value={String(false)}
                        control={<Radio style={{ color: "#000" }} />}
                        label={"Search popular position name"}
                        sx={{ "& .MuiTypography-root": { fontSize: "14px"} }} />
                        <FormControlLabel
                        value={String(true)}
                        control={<Radio style={{ color: "#000" }} />}
                        label={"Fresh start"}
                        sx={{ "& .MuiTypography-root": { fontSize: "14px"} }} />
                    </RadioGroup>
                    <div className="create-position-from-scratch-search-container">
                    {!freshStart &&
                    <Select
                    className="custom-select-style"
                    styles={SelectCustomStyles_From_Scratch}
                    isLoading={isResultsLoading}
                    options={positionOptions}
                    inputValue={selectValue ?? ""}
                    placeholder={"Search for other simulations"}
                    getOptionValue={(o: any) => o.value} 
                    onInputChange={onSelectChanged}
                    onChange={onSelectChanged}
                    />}
                    {freshStart &&
                    <TextField
                    value={freshStartInputValue}
                    onChange={(e) => setFreshStartInputValue(e.target.value)}
                    className="custom-text-input-fresh-start"
                    uiType="white-bordered"
                    placeholder="Enter simulation title"/>}
                    </div>
                    {formattedPosition && !isPositionLoading && !freshStart &&
                    <PositionPreview position={formattedPosition}/>}

                    {(formattedPosition || freshStart) 
                    && !isPositionLoading &&
                    <button
                    disabled={freshStart && !freshStartInputValue ? true : false}
                    onClick={onSubmit}
                    className="create-position-from-scratch-submit-button">
                        <span>Continue With The Selected Position</span>
                        <ArrowForwardIcon/>
                    </button>}
                </div>

                {isPositionLoading &&
                <div className="create-position-from-scratch-loading">
                    <LNRDLoading/>
                </div>}
            </div>
        </Dialog>
    )
}