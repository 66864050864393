// React
import React from "react"
import { ChevronRightIcon } from '@heroicons/react/solid'

// Components
import { WizardFormContext } from "@components/WizardForm/context"

import cx from "classnames"
import { TabDisplayTypes } from ".."

export interface WizardTabControlsProps {
	showTabs: TabDisplayTypes
	sections: string[]
}

/**
 *
 *  Wizard Form Tab headers
 *  @description Renders the tab controls at the top of the wizard
 *
 */
const WizardTabControls: React.FC<WizardTabControlsProps> =
	({ sections, showTabs /*, pressEnter=true */ }): JSX.Element => {
		// Context
		const {
			handleNextPage,
			page
		} = React.useContext(WizardFormContext)

		const showAll = showTabs === "all"

		const showTab = (index: number) => {
			return showAll || index <= page
		}

		const empTab = (index: number) => {
			return index === page
		}

		if (sections.length < 1 || showTabs === "none") {
			return <span></span>
		}
		return (
			<div className="justify-between flex-wrap sm:block text-center">
				<div className="xl:w-full xl:mx-0 h-12">
					<ul className="flex">
						{sections.map((section, index) => (
							showTab(index) && <li
								key={index + 1}
								onClick={() => handleNextPage(index)}

								className={cx(" flex justify-between items-center text-sm", {
									" rounded-t font-bold text-gray-700": empTab(index),
									" text-gray-500 font-normal cursor-pointer hover:text-back": !empTab(index)
								})}>
								{(index !== 0) && <ChevronRightIcon className="w-3 h-3 mx-1" />}
								<span className="cursor-pointer">{section}</span>
							</li>
						))}
					</ul>
				</div>
			</div>
		)
	}

export default WizardTabControls
