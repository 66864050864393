const defaultSurveyJSON =  {
  completeText: "Submit",
  showQuestionNumbers: "off",
  questionsOnPageMode: "questionPerPage",
  pagePrevText: "Back",
  pageNextText: "Next",
  pages: [
    {
      elements: [
        {
          type: "matrix",
          name: "Q1",
          title: "I am:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Reasonable, well-balanced",
            },
            {
              value: 1,
              text: "Determined, persistent",
            },
            {
              value: 2,
              text: "Co-operative, participative",
            },
            {
              value: 3,
              text: "Creative, full of ideas",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q2",
          title: "I am:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Competitive, a winner",
            },
            {
              value: 1,
              text: "Warm, welcoming",
            },
            {
              value: 2,
              text: "Competent, professional",
            },
            {
              value: 3,
              text: "Enthusiastic, spontaneous",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q3",
          title: "I:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Am a fighter, a conqueror",
            },
            {
              value: 1,
              text: "Am a developer of new ideas and new options",
            },
            {
              value: 2,
              text: "Am loyal, reliable",
            },
            {
              value: 3,
              text: "Am a good partner, I am helpful",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q10",
          title: "I sometimes:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Am too nice to people or fail to give my opinion",
            },
            {
              value: 1,
              text: "Am formal, follow social conventions",
            },
            {
              value: 2,
              text: "Get off the track, say things in a complicated way",
            },
            {
              value: 3,
              text: "Am harsh, too short with people",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q12",
          title: "I like:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Playful/humorous situations",
            },
            {
              value: 1,
              text: "Situations where relationships are harmonious",
            },
            {
              value: 2,
              text: "Situations where something important is at stake",
            },
            {
              value: 3,
              text: "Crystal-clear and properly defined situations",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q13",
          title: "I like:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "To create movement",
            },
            {
              value: 1,
              text: "To make things move forward",
            },
            {
              value: 2,
              text: "To show team spirit",
            },
            {
              value: 3,
              text: "To provide clear information and analysis to others",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q16",
          title: "Sometimes, I:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Am passive, avoid difficulties",
            },
            {
              value: 1,
              text: "Conform to the existing framework, do not trust new ways of seeing things",
            },
            {
              value: 2,
              text: "Am aggressive, violent",
            },
            {
              value: 3,
              text: "Disorganized, unpredictable",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q18",
          title: "I like:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Being the best",
            },
            {
              value: 1,
              text: "Getting people's attention, interest them",
            },
            {
              value: 2,
              text: "Being liked by others",
            },
            {
              value: 3,
              text: "Being serious and pragmatic",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q19",
          title: "I like:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Being nice, being helpful",
            },
            {
              value: 1,
              text:
                "Doing each step of my work carefully and as it ought to be done",
            },
            {
              value: 2,
              text: "Surprising and amusing people",
            },
            {
              value: 3,
              text: "Showing I can do things better and differently",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q21",
          title: "I like:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Concepts, ideas",
            },
            {
              value: 1,
              text: "Action, results",
            },
            {
              value: 2,
              text: "Facts, methods",
            },
            {
              value: 3,
              text: "Relationships, contacts",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q26",
          title: "I think that often:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "I treat things with humor",
            },
            {
              value: 1,
              text: "I stand up for my position with passion and assurance",
            },
            {
              value: 2,
              text: "I stay calm and don't show my feelings",
            },
            {
              value: 3,
              text: "I am sensitive to other people's feelings and needs",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q27",
          title: "I like:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Short statements which go straight to the point",
            },
            {
              value: 1,
              text:
                "Putting things into question, I think it is useful to question things",
            },
            {
              value: 2,
              text: "Sharing what I feel with others, exchanging with them",
            },
            {
              value: 3,
              text:
                "Relying on objective information, I believe that facts are the most important things",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q33",
          title: "I like:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Setting my own objectives",
            },
            {
              value: 1,
              text: "Planning before doing",
            },
            {
              value: 2,
              text: "Being free to tackle an issue as I want",
            },
            {
              value: 3,
              text: "Being careful and being sure of the future",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q48",
          title: "Sometimes, I tend to:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Be unclear, get scattered and lose track of priorities",
            },
            {
              value: 1,
              text: "Force my point of view, want to be right all the time",
            },
            {
              value: 2,
              text: "Avoid conflicts and confrontations",
            },
            {
              value: 3,
              text: "Be rigid, go by the books",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          type: "matrix",
          name: "Q50",
          title: "Sometimes, I tend to:",
          columns: [
            {
              value: 1,
              text: "Best",
            },
            {
              value: 2,
              text: "Least",
            },
          ],
          rows: [
            {
              value: 0,
              text: "Want to impress others, appear threatening",
            },
            {
              value: 1,
              text: "Encourage controversy, argue for the sake of arguing",
            },
            {
              value: 2,
              text: "Go by the rules, make things conform to norms",
            },
            {
              value: 3,
              text: "Not give my opinion if I risk being in opposition with others",
            },
          ],
        },
      ],
    },
  ],
};

const defaultSurveyCSS = {
    header: 'bg-primary text-white p-3',
    body: 'bg-light',
    completedPage: 'p-3'
};

const defaultSurveyConfig = {
    defaultSurveyCSS,
    defaultSurveyJSON
}

export default defaultSurveyConfig;
