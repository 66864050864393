// Components
// import { Button } from "@components";

import './style.css';

interface EmptyStateProps {
  title: string
  subtitle?: string
  imageSrc: string
  buttonText?: string
  buttonOnClick?: React.MouseEventHandler<HTMLButtonElement>
}

const EmptyState: React.FunctionComponent<EmptyStateProps> = ({ title, subtitle, imageSrc, 
  // buttonText, buttonOnClick 
}): JSX.Element => {
  return (
    <div className="empty-state-container">
      <img src={imageSrc} className="empty-state-image" />
      <h1 className="empty-state-title">{title}</h1>
      {subtitle && <h4>{subtitle}</h4>}
      {/* {buttonText && 
      <Button
        text={buttonText}
        className="bg-black"
        type={"regular"}
        size="md"
        onClick={buttonOnClick}
      />} */}
    </div>
  )
}

export default EmptyState
