import ReactHtmlParser from "react-html-parser";

import {
  downloadFile,
  getCandidateChoice,
  isCorrectAnswer,
  renderObjectTypeAnswer,
} from "../helpers";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { BsDownload } from "react-icons/bs";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import halfCorrectIcon from "@assets/icons/half_correct.svg";
import correctIcon from "@assets/icons/correct.svg";
import similarCorrectIcon from "@assets/icons/similar_correct.svg";
import incorrectIcon from "@assets/icons/incorrect.svg";

import "./style.css";

interface ICorrectIncorrectAnswer {
  children: JSX.Element | any;
  isCorrectAnswer: boolean | null;
  isImagePickerType?: boolean;
}

const CorrectIncorrectAnswer: React.FunctionComponent<
  ICorrectIncorrectAnswer
> = ({ children, isCorrectAnswer, isImagePickerType }): JSX.Element =>
  isCorrectAnswer !== null ? (
    <div
      className={`correct-incorrect-container 
      ${isCorrectAnswer ? "correct-container " : "incorrect-container "} 
      ${isImagePickerType ? "correct-incorrect-container-imagepicker" : ""}`}
    >
      <img
        src={isCorrectAnswer ? correctIcon : incorrectIcon}
        className={
          isImagePickerType ? "correct-incorrect-icon-imagepicker" : ""
        }
      />
      <div
        style={{
          // marginLeft: "5px",
          // fontSize: "16px",
          // lineHeight: "18x",
          maxWidth: "500px"
        }}
      >
        {children}
      </div>
    </div>
  ) : (
    children
  );

// interface IRatingAnswer {
//   answer: {
//     correctAnswer: any;
//     answer: any;
//     type: any;
//   },
//   choices: any
// }

// export const RatingAnswer: React.FunctionComponent<IRatingAnswer> = (props):JSX.Element => {
//   const { answer, choices } = props;
//   return(
//     <div className="rating-ranking-answer">
//     <div className="rating-ranking-answer-section">
//       <span className="rating-ranking-answer-text">Candidate's answer:</span>
//       <CorrectIncorrectAnswer isCorrectAnswer={isCorrectAnswer(answer?.correctAnswer, answer.answer, answer.type)}>
//         {getCandidateChoice(choices, answer?.answer)}
//       </CorrectIncorrectAnswer>
//     </div>
//     <div className="rating-ranking-answer-section">
//       <span className="rating-ranking-answer-text">Expected answer:</span>
//       <span>
//         {getCorrectAnswer(choices, answer.correctAnswer)}
//       </span>
//     </div>
// </div>
//   )
// }

interface IRatingAnswer {
  answer: {
    correctAnswer: any;
    answer: any;
    type: any;
  };
  choices: any;
}

export const RatingAnswer: React.FunctionComponent<IRatingAnswer> = (
  props
): JSX.Element => {
  const { answer, choices } = props;
  return (
    <TableContainer
      component={Paper}
      className="custom-table-rating-answer"
      style={{
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
    >
      <Table sx={{ width: "fit-content", lineBreak: "initial" }} aria-label="simple table">
        <TableBody>
          {choices?.map((item: any, i: number) => (
            <TableRow
              key={i}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                {item?.value === answer?.answer ? (
                  <div>
                    <span
                      style={{
                        fontWeight: "300",
                        lineHeight: "18px",
                      }}
                    >
                      Candidate's answer
                    </span>
                    <ArrowRightIcon
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  </div>
                ) : null}
              </TableCell>
              <TableCell>
                {answer?.answer === item?.value ? (
                  <span
                    className={
                      answer?.correctAnswer ? isCorrectAnswer(
                        answer?.correctAnswer,
                        item?.value,
                        answer?.type
                      )
                        ? "correct-answer"
                        : "incorrect-answer"
                    : ""}
                  >
                    {ReactHtmlParser(item?.text)}
                  </span>
                ) : (
                  ReactHtmlParser(item?.text)
                )}
              </TableCell>
              <TableCell>
                {item?.value === answer?.correctAnswer ? (
                  <div>
                    <ArrowLeftIcon
                      style={{
                        fontSize: "18px",
                      }}
                    />
                    <span
                      style={{
                        fontWeight: "400",
                        lineHeight: "18px",
                      }}
                    >
                      Expected answer
                    </span>
                  </div>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface IRankingAnswer {
  answer: {
    answer: any[];
    payload: {
      choices: { value: any; text: string }[];
    };
    correctAnswer: any;
    choices: { value: any; text: string }[];
    type: string;
  };
  score: number;
}

export const RankingAnswer: React.FunctionComponent<IRankingAnswer> = (
  props
): JSX.Element => {
  const { answer, score } = props;

  const iconToText = {
    [incorrectIcon]: "Not similar",
    [halfCorrectIcon]: "Somewhat similar",
    [similarCorrectIcon]: "Similar",
    [correctIcon]: "Perfect",
  };

  let icon = "";
  if (score < 0.4) icon = incorrectIcon;
  // Red
  else if (score >= 0.4 && score < 0.75) icon = halfCorrectIcon;
  // Yellow
  else if (score >= 0.75 && score < 0.9) icon = similarCorrectIcon;
  // Green
  else icon = correctIcon; // Green

  return (
    <div className="ranking-answer-custom">
        <TableContainer
          component={Paper}
          className="custom-table-rating-answer"
          style={{
            boxShadow: "none",
            backgroundColor: "transparent",
            lineBreak: "initial"
          }}
        >
          <Table sx={{ width: "fit-content" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {answer.correctAnswer && <img
                      src={icon}
                      style={{ marginRight: "5px" }}
                      alt={iconToText[icon]}
                      title={iconToText[icon]}
                    />}
                    Candidate's order&nbsp;
                  </div>
                </TableCell>
                <TableCell>Expected order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answer?.answer?.map((item: any, i: number) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {getCandidateChoice(
                      answer?.payload?.choices ?? answer?.choices,
                      item
                    )}
                  </TableCell>
                  <TableCell>
                    {answer?.payload?.choices
                      ? ReactHtmlParser(
                          answer?.payload?.choices.find(
                            (c) => c.value === answer.correctAnswer?.[i]
                          )?.text ?? ""
                        )
                      : ReactHtmlParser(
                          answer?.choices?.find(
                            (c) => c.value === answer.correctAnswer?.[i]
                          )?.text ?? ""
                        )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  );
};

interface IBooleanAnswer {
  answer: {
    answer: boolean;
    correctAnswer: any;
    type: string;
  };
}

export const BooleanAnswer: React.FunctionComponent<IBooleanAnswer> = (
  props
): JSX.Element => {
  const { answer } = props;

  return (
    <CorrectIncorrectAnswer
      isCorrectAnswer={isCorrectAnswer(
        answer.correctAnswer,
        answer.answer,
        answer?.type
      )}
    >
      <span>{answer?.answer ? "Yes" : "No"}</span>
    </CorrectIncorrectAnswer>
  );
};

interface IArrayAnswer {
  answer: {
    answer: any[];
    payload: {
      choices: { value: any; text: string }[];
    };
    correctAnswer: any;
    choices: { value: any; text: string }[];
    type: string;
  };
}

export const ArrayAnswer: React.FunctionComponent<IArrayAnswer> = (
  props
): JSX.Element => {
  const { answer } = props;

  return (
    <ul className="checkbox-list-style" style={{ listStyle: "none" }}>
      {answer?.answer?.map((item: any, i: number) => (
        <li key={i + item}>
          <CorrectIncorrectAnswer
            key={i}
            isCorrectAnswer={isCorrectAnswer(
              answer?.correctAnswer !== undefined
                ? answer?.correctAnswer
                : null,
              item,
              answer?.type
            )}
          >
            {getCandidateChoice(
              answer?.payload?.choices ?? answer?.choices,
              item
            )}
          </CorrectIncorrectAnswer>
        </li>
      ))}
    </ul>
  );
};

interface IStringAnswer {
  answer: {
    answer: string;
    payload: {
      answer: string;
    };
    correctAnswer: any;
    type: string;
  };
}

export const StringAnswer: React.FunctionComponent<IStringAnswer> = (
  props
): JSX.Element => {
  const { answer } = props;

  return (
    <CorrectIncorrectAnswer
      isCorrectAnswer={isCorrectAnswer(
        answer?.correctAnswer,
        answer?.answer || answer?.payload?.answer,
        answer?.type
      )}
    >
      <div>{ReactHtmlParser(answer?.answer || answer?.payload?.answer)}</div>
    </CorrectIncorrectAnswer>
  );
};

interface IOneOfManyAnswer {
  answer: {
    answer: string;
    correctAnswer: any;
    type: string;
  };
  choices: { value: any; text: string }[];
}

export const OneOfManyAnswer: React.FunctionComponent<IOneOfManyAnswer> = (
  props
): JSX.Element => {
  const { answer, choices } = props;

  return (
    <CorrectIncorrectAnswer
      isCorrectAnswer={isCorrectAnswer(
        answer?.correctAnswer,
        answer?.answer,
        answer?.type
      )}
    >
      <div>{getCandidateChoice(choices, answer?.answer)}</div>
    </CorrectIncorrectAnswer>
  );
};

interface IImagePickerAnswer {
  answer: {
    answer: string;
    payload: {
      choices: { value: any; imageLink: string }[];
      correctAnswer?: any;
    };
    correctAnswer?: any;
    type: string;
  };
}

export const ImagePickerAnswer: React.FunctionComponent<IImagePickerAnswer> = (
  props
): JSX.Element => {
  const { answer } = props;
  return (
    <CorrectIncorrectAnswer
      isImagePickerType={true}
      isCorrectAnswer={isCorrectAnswer(
        answer?.correctAnswer,
        answer?.answer,
        answer.type
      )}
    >
      <img
        className="image-picker-image-size"
        src={
          answer?.payload?.choices.find((c: any) => c.value === answer.answer)
            ?.imageLink
        }
      />
    </CorrectIncorrectAnswer>
  );
};

interface IFileAnswer {
  answer: {
    answer: { content: any; name: any }[];
    correctAnswer: any;
    type: string;
  };
}

export const FileAnswer: React.FunctionComponent<IFileAnswer> = (
  props
): JSX.Element => {
  const { answer } = props;

  return (
    <CorrectIncorrectAnswer
      isCorrectAnswer={isCorrectAnswer(
        answer?.correctAnswer,
        answer?.answer ? answer?.answer[0]?.content : null,
        answer.type
      )}
    >
      <button className="flex">
        <a
          className="text-xs rounded px-3 py-1 border border-gray-800 text-gray-800 hover:bg-gray-100 no-underline flex"
          onClick={() =>
            downloadFile(
              answer?.answer[0]?.content ?? [],
              answer?.answer[0]?.name
            )
          }
        >
          Download file <BsDownload size="16" className="ml-2" />
        </a>
      </button>
    </CorrectIncorrectAnswer>
  );
};

interface IImageAnswer {
  answer: {
    payload: {
      imageLink: string;
    };
  };
}

export const ImageAnswer: React.FunctionComponent<IImageAnswer> = (
  props
): JSX.Element => {
  const { answer } = props;

  return <img src={answer?.payload?.imageLink} style={{ width: "100px" }} />;
};

interface IDynamicAnswer {
  answer: {
    answer: any[];
    correctAnswer: any;
    type: string;
  };
}

export const DynamicAnswer: React.FunctionComponent<IDynamicAnswer> = (
  props
): JSX.Element => {
  const { answer } = props;

  return (
    <ul className="checkbox-list-style">
      {answer.answer.map((a: any, i: number) => (
        <CorrectIncorrectAnswer
          isCorrectAnswer={isCorrectAnswer(
            answer?.correctAnswer[i],
            a,
            answer.type
          )}
        >
          <li key={i + a}>{renderObjectTypeAnswer(a)}</li>
        </CorrectIncorrectAnswer>
      ))}
    </ul>
  );
};

interface IMatrixAnswer {
  answer: {
    answer: any[];
    payload: {
      columns: any[];
      rows: any[];
    };
    correctAnswer: any;
    type: string;
  };
}

export const MatrixAnswer: React.FunctionComponent<IMatrixAnswer> = (
  props
): JSX.Element => {
  const { answer } = props;

  return (
    <TableContainer
      component={Paper}
      className="custom-table-rating-answer"
      style={{
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
    >
      <Table sx={{ width: "fit-content", lineBreak: "initial" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Topics</TableCell>
            <TableCell>Candidate's choice</TableCell>
            <TableCell>Expected answer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(answer.answer)?.map((key: any, i: number) => (
            <TableRow key={i}>
              {key?.includes("Row") ? (
                <TableCell className="matrix-answer-question-cell">
                  {answer?.payload?.rows?.[i]?.text}
                </TableCell>
              ) : (
                <TableCell className="matrix-answer-question-cell">
                  {key}
                </TableCell>
              )}
              <TableCell className="matrix-answer-candidates-answer-cell">
                  <CorrectIncorrectAnswer
                    isCorrectAnswer={isCorrectAnswer(
                      answer?.correctAnswer?.[key],
                      answer?.answer?.[key],
                      answer?.type
                    )}
                  >
                    {answer?.payload?.columns?.find(
                      (c: any) => (c.value || c) === answer?.answer[key]
                    )?.text ||
                      answer?.payload?.columns?.find(
                        (c: any) => (c.value || c) === answer?.answer[key]
                      )}
                  </CorrectIncorrectAnswer>
              </TableCell>
              <TableCell>{answer.correctAnswer?.[key] ?? ""}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface IMultipletextAnswer {
  answer: {
    answer: any;
    correctAnswer: any;
    type: string;
  };
}

export const MultipletextAnswer: React.FunctionComponent<
  IMultipletextAnswer
> = (props): JSX.Element => {
  const { answer } = props;

  return (
    <CorrectIncorrectAnswer
      isCorrectAnswer={isCorrectAnswer(
        answer?.correctAnswer,
        answer?.answer,
        answer.type
      )}
    >
      <div>{renderObjectTypeAnswer(answer?.answer)}</div>
    </CorrectIncorrectAnswer>
  );
};
