import { useEffect, useState } from "react";

import { IconButton, TextField } from "@mui/material";

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import './style.css';

interface ITableSearchBar {
    data: any[],
    setRows: any,
    searchOnColumns: string[],
}

const TableSearchBar: React.FunctionComponent<ITableSearchBar> = ({data, setRows, searchOnColumns}): JSX.Element => {

    const [searched, setSearched] = useState("");

    useEffect(() => {
        requestSearch(searched)
    }, [data])

    // Handle search
    const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    
    // Convert search vlue to lower case
    const lowerCaseValue = searchedVal.toLocaleLowerCase().trim();

    // If there is no search value
    if(!lowerCaseValue){
        // Set rows to original data
        setRows(data)
    }else{
        // Filter the rows by the search
        const filteredRows = data.filter((item: any) =>{
        return searchOnColumns.some((key: any) => {
            if(item[key])
            return item[key].toString().toLowerCase().includes(lowerCaseValue); 
        }); 
        });
        setRows(filteredRows);
    }

    };

    // Cancel search
    const cancelSearch = () => {    
        setSearched("");
        requestSearch("");
    };

    return (
        <>
        {/* Search input */}
        <TextField
        variant="outlined"
        value={searched}
        onChange={(e) => requestSearch(e.target.value)}
        placeholder="Search…"
        sx={{
            height: "36px",
            padding: 0,
            outline: "none",
            "& .MuiOutlinedInput-input" : {
                padding: 0,
            },
            "& .MuiOutlinedInput-root": {
                height: "inherit"
            }
        }}
        InputProps={{
            startAdornment: <SearchIcon style={{ fontSize: "18px" }} />,
            endAdornment: (
            <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: searched ? 'visible' : 'hidden', padding: 0 }}
                onClick={() => cancelSearch()}
            >
                <ClearIcon style={{ fontSize: "18px" }}  />
            </IconButton>
            ),
            
        }}
        />
        </>
    )
}

export default TableSearchBar;