import { gql } from "graphql-request";

export const gatAllReportRecommendations = gql`
    query GetReportRecommendations($companyId: UUID) {
        getReportRecommendations(companyId: $companyId) {
            id
            assessmentAxe
            CompanyId
            UserId
            priorityRank
            User {
                id
                email
                firstName
                lastName
                role
                userImage
            }
            section
            reason
            recommendationText
            positions
            createdAt
            updatedAt
        }
    }
`