import { ICompanyFormPage, ICompanyInfo } from "@/models";
import { ICompanyExtraInfo } from "@/models/companyInfo";
import { IDisplayQuestion } from "@/models/question";
import { CompanyFormPageAdapter } from "./companyFormPageAdapter";


export class CompanyCulturePageAdapter extends CompanyFormPageAdapter {
	constructor(page: ICompanyFormPage) {
		super(page, "culture");
	}

	public createUpdate(): Partial<ICompanyInfo> {
		const cultureInfo: (ICompanyExtraInfo | null)[] = 
			this.allQuestionGroups()
			.map(list => this.collectInfo(list))
			.filter(Boolean) // remove nulls

    return cultureInfo.length ? { additionalInfo: cultureInfo as ICompanyExtraInfo[] } : {}
	}

	protected _doUpdateFormPageFromCompany(company: ICompanyInfo): ICompanyFormPage {
		const questions = this.page.questions
		if (!questions) {
			return this.page
		}

		company.additionalInfo?.forEach((rec, index) => {
			questions[index] = this.createQuestions(0, 
				[{
					value: rec.title || "",
				},
				{
					value: rec.text || "",
				},
				{
					value: rec.image
				}]
			)
		})
	
		return this.page;
	}




	private collectInfo(list: IDisplayQuestion[]) : ICompanyExtraInfo | null {
		if (!list || !list.some(q => q.value)) {
			return null
		}
		return {
			title: list[0].value,
			text: list[1].value,
      // list[2] which is the image, may not exist.
			image: list[2]?.value || ''
		}
	}
}
