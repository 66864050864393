// React
import React from 'react'
import { Button } from "@components"
import { useHistory } from 'react-router'
import PrettyLink from '../PrettyLink'

// Local Interfaces
interface WizardSummaryProps {

	url?: string
	image?: {
		url: string
		title: string
	}
}

/**
 * Text Area
 * @description text area component
 */
const WizardSummary: React.FunctionComponent<WizardSummaryProps> =
	({ url }): React.ReactElement => {

		const history = useHistory()

		return (

			<div className="wizard-summary-container flex flex-col">
				{/*{*/}

				{/* Links */}
            <div className="wiz-preview-link flex">
                <PrettyLink
                  canCopy={true}
                  target="_blank"
                  styles={{
                    iconStyle: { color: "white" },
                    linkStyle: { fontSize: "14px" },
                    containerStyle: { backgroundColor: "#333333" },
                  }}
                  classes={{
                    textClass: "text-white",
                  }}
                  href={url}
                  text={url}
                />
            </div>
				<div className="mt-12">
					<Button
						text="Create a new position"
						size="sm"
						type="light" onClick={() => history.push("/positions/wizard")}
					/>
				</div>
			</div>
		)
	}


export default WizardSummary
