import { useContext, useEffect, useState } from "react"

import { getMetabaseEmbeddedUrl } from "@/graphql/queries/metabaseStatistics";
import { graphQueries } from "@/helpers";

import './style.css';
import AppContext from "@/context/AppContext";

const ReqruitmentStats: React.FunctionComponent<{ companyId?: string, dashboard?: number }> = ({ companyId: company_id, dashboard }): JSX.Element => {

    const [embeddedStatisticsUrl, setEmbeddedStatisticsUrl] = useState<string | undefined>(undefined);
    const [ error, setError ] = useState<boolean>(false);
    const { tr } = useContext(AppContext);
    
    useEffect(() => {
        if(!company_id) return;
        const params = { 
            params: {
                company_id
            },
            dashboard 
        };
        graphQueries.sendRequest(getMetabaseEmbeddedUrl, params)
        .then(({ getEmbededMetabaseUrl: response }) => {
            if(typeof response === "string") {
                setEmbeddedStatisticsUrl(response);
            }
        })
        .catch(() => setError(true));
    }, []);

    return (
        <>
            {error ? <div className="error-message-container">{tr("Sorry, cannot load statistics. Please try again later")}.</div> : 
            <iframe 
            className="hiring-statistics-iframe"
            onError={() => setError(true)}
            src={embeddedStatisticsUrl} 
            width={"100%"} 
            height={1200}/>}
        </>
    )
}

export default ReqruitmentStats