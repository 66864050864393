const Loading: React.FunctionComponent = (): JSX.Element => {
  return (
    <main className="loading">
      <div className="bg-white pt-2 sm:pt-4 h-full rounded flex flex-col sm:flex-row gap-5 select-none w-full" style={{ height: 879 }}>
        <div className="flex flex-col flex-1 gap-5 sm:pt-2">
          <div className="flex flex-1 flex-col gap-3">
            <div className="bg-gray-200 w-full animate-pulse h-24 rounded-2xl mb-8" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl" ></div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Loading
