import WizardStepTemplate from "@/components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { proxyDataService } from "@/graphql/mutations/proxyDataService";
import { flatten, graphQueries } from "@/helpers";
import { useState } from "react";
import { IWizardStep } from "../../interfaces";
import { throttle } from "lodash";
import { removeDuplicates } from '@/helpers'
import './style.css'
import SuggestionCard from "@/components/WizardComponents/SuggestionCard/SuggestionCard";
import { SkillNecessityLevel } from "@/interfaces/pages/positions";
import AutocompleteCustom, { StyledTextInput } from "@/components/WizardComponents/AutocompleteNew/AutocompleteNew";

const TITLE = (positionName: string) => `Review ${positionName} requirements`;
const DESCRIPTION  = "Allow candidates to see if they're qualified for the role by listing the requirements. Remember to differentiate between the must-have's and the advantageous to have."

export const searchSkillsOptions = throttle(async (searchVal: string): Promise<{label: string, value: string}[]> => {
    const { proxyDataService: data } = await graphQueries.sendRequest(proxyDataService, {
        path: "/common_data/skills?q=" + searchVal,
        method: "GET"
    });
    const skillsWithoutDuplicates = removeDuplicates(data, JSON.stringify)

    const skillsOptions = skillsWithoutDuplicates.map((s) => ({ label: s, value: s }));
    
    return skillsOptions;
}, 1000);

const SkillsStep: React.FunctionComponent<IWizardStep> = ({ formik }) => {
    const { values, setFieldValue } = formik;
    const { skills, title } = values;

    const [skillsSuggesstions, setSkillsSuggesstions] = useState<{ label: string, value: any }[]>([]);
    const [loadingSkillOptions, setLoadingSkillOptions] = useState<boolean>(false)

    const [showAddSkillAdventageInput, setShowAddSkillAdventageInput] = useState<boolean>(false);
    const [showAddSkillMustInput, setShowAddSkillMustInput] = useState<boolean>(false);

    const onSkillsChanged = (newSkillsArr: { value: SkillNecessityLevel, label: string }[], necessityLevel: SkillNecessityLevel) => {
        const newSkills = newSkillsArr.filter(s => s.value === necessityLevel).map(s => ({            
            name: s.label,
            necessityLevel: s.value,
            proficiency: "none"
        }));
        
        setFieldValue("skills", [
            ...(skills?.filter(s => s.necessityLevel !== necessityLevel) ?? []) 
            , ...newSkills
        ])
    }

    const onSkillInputChange = (e: React.ChangeEvent<HTMLInputElement>, necessityLevel: SkillNecessityLevel) => {
        const value = e.target.value;

        setLoadingSkillOptions(true)
        searchSkillsOptions(value as any)
        ?.then((fetchedSkills) => {
            const newSkillsList = flatten([
                fetchedSkills.map(s => ({ label: s.value, value: necessityLevel })), 
                skills?.flatMap(s =>  s.necessityLevel === necessityLevel ? { label: s.name, value: necessityLevel} : []) ?? []
            ])
            
            setSkillsSuggesstions(newSkillsList)
        })
        ?.catch(console.error)
        ?.finally(() => setLoadingSkillOptions(false))
    }
    
    return (
        <WizardStepTemplate
        title={TITLE(title ?? "The Position")}
        description={DESCRIPTION}>
            <div>
            <AutocompleteCustom
                label={"What are the must-have requirements from the candidates?"}
                style={{ border: "none", width: "100%" }}
                options={skillsSuggesstions}
                loading={loadingSkillOptions}
                value={
                    skills
                    ?.filter(s => s.necessityLevel === SkillNecessityLevel.MUST)
                    ?.map(s => ({ label: s.name, value: s.necessityLevel })) as any
                } 
                getOptionLabel={(option: {label: string, value: string}) => option.label}
                clearOptionOnClose={true}
                isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value) }
                onChange={(e, newSkills: any[]) => onSkillsChanged(newSkills, SkillNecessityLevel.MUST)}
                limitTags={10}
                renderCustomInput={(params, setInpuValue) => (
                    <>
                    <StyledTextInput
                    autoFocus={true}
                    placeholder="Search for traits and skills"
                    className="skills-and-traits-add-skill-input"
                    onBlur={() => {
                        setInpuValue("")
                        setShowAddSkillMustInput(false)
                    }}
                    onKeyDown={(event: any) => {
                        if (event.key === 'Backspace') {
                            event.stopPropagation();
                        }
                    }}
                    onChange={(e: any) => {
                        onSkillInputChange(e, SkillNecessityLevel.MUST)
                        setInpuValue(e.target.value)
                    }}
                    sx={{
                        "& input": {
                            display: showAddSkillMustInput ? "block" : "none",
                        }
                    }}
                    {...params}
                    />
                    {!showAddSkillMustInput &&
                    <div>
                        <SuggestionCard 
                        className="skills-suggestion-card-custom-add-new"
                        selected={true}
                        suggestion={{ value: undefined, label: "+ Add skill" }} 
                        onClick={() => {
                            setShowAddSkillMustInput(true)
                        }}/>
                    </div>}
                    </>
                )}/>
                <div className="discovery-editor-adventage-skills-wrapper">
                    <AutocompleteCustom
                    label={"And the advantageous requirements?"}
                    style={{ border: "none", width: "100%" }}
                    options={skillsSuggesstions}
                    loading={loadingSkillOptions}
                    value={
                        skills
                        ?.filter(s => s.necessityLevel === SkillNecessityLevel.ADVENTAGE)
                        ?.map(s => ({ label: s.name, value: s.necessityLevel })) as any
                    } 
                    getOptionLabel={(option: {label: string, value: string}) => option.label}
                    clearOptionOnClose={true}
                    isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value) }
                    onChange={(e, newSkills: any[]) => onSkillsChanged(newSkills, SkillNecessityLevel.ADVENTAGE)}
                    limitTags={10}
                    renderCustomInput={(params, setInpuValue) => (
                        <>
                        <StyledTextInput
                        autoFocus={true}
                        placeholder="Search for traits and skills"
                        className="skills-and-traits-add-skill-input"
                        onKeyDown={(event: any) => {
                            if (event.key === 'Backspace') {
                                event.stopPropagation();
                            }
                        }}
                        onBlur={() => {
                            setInpuValue("")
                            setShowAddSkillAdventageInput(false)
                        }}
                        onChange={(e: any) => {
                            onSkillInputChange(e, SkillNecessityLevel.ADVENTAGE)
                            setInpuValue(e.target.value)
                        }}
                        sx={{
                            "& input": {
                                display: showAddSkillAdventageInput ? "block" : "none",
                            }
                        }}
                        {...params}
                        />
                        {!showAddSkillAdventageInput &&
                            <div>
                                <SuggestionCard 
                                className="skills-suggestion-card-custom-add-new"
                                selected={true}
                                suggestion={{ value: undefined, label: "+ Add skill" }} 
                                onClick={() => {
                                    setShowAddSkillAdventageInput(true)
                                }}/>
                        </div>}
                        </>
                    )}/>
                </div>
            </div>
        </WizardStepTemplate>
    )
}

export default SkillsStep;