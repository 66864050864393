import React, { useContext } from "react";
import { useHistory } from "react-router";

import AuthContext from "@/auth/context";

import NotificationIconClose from '@assets/icons/notification_icon_close.svg';
import NotificationIconOpen from '@assets/icons/notification_icon_open.svg';

import Notification from "./Notification/Notification";
import DropdownButton from "../DropdownButton/DropdownButton";

import { INotification } from "./types";

import './style.css';


const NotificationsButton: React.FunctionComponent<{open: boolean;isUnreadNotifications: boolean}> = ({open, isUnreadNotifications}): JSX.Element => (
    <div className="notification-center-icon">
      <img src={open ? NotificationIconOpen : NotificationIconClose} />
      {isUnreadNotifications &&
      <div className="notification-center-new-notifications"></div>}
    </div>
)

const NotificationHeader: React.FunctionComponent<{newNotificationsLength: number;onClickMarkAllAsRead: () => any }> = 
({ newNotificationsLength, 
  onClickMarkAllAsRead }): JSX.Element => (

      <div className="notification-center-header">
          <span className="notification-center-header-text">
              Notifications 
              {newNotificationsLength > 0 ? ` (${newNotificationsLength} new)` : null}
          </span>
          
          {newNotificationsLength > 0 ? 
          <span
          className="notification-center-header-mark-as-read"
          onClick={() => onClickMarkAllAsRead()}
          >Mark all as read</span> 
          : null}
      </div>
)

interface INotifications {
  notifications: INotification[];
  readedNotifications: string[] | [];
  userEmail: string;
  userId: string;
  userIsAdmin: boolean;
  getUnreadNotificationsCount: () => number
  setCompany: (val: any) => void
  markAllNotificationAsRead: () => void;
  markNotificationAsRead: (id: string) => void
}

const Notifications: React.FunctionComponent<INotifications> = 
({ notifications,  
  readedNotifications,
  userEmail,
  userId,
  userIsAdmin,
  getUnreadNotificationsCount,
  markAllNotificationAsRead,
  markNotificationAsRead,
  setCompany }): JSX.Element => {
  
  const history = useHistory();
  const { settings } = useContext(AuthContext);

  const NotificationWithProps = (notification: INotification, i: number) => (
    <Notification
    key={i}
    notification={notification}
    showSeperateLine={i + 1 !== notifications.length}
    userEmail={userEmail}
    userId={userId}
    userIsAdmin={userIsAdmin}
    setCompany={setCompany}
    history={history}
    jobSimOrigin={settings.getSetting("JOBSIMULATOR_ORIGIN")} 
    markNotificationAsRead={markNotificationAsRead}
    isUnreadNotification={!readedNotifications.includes(notification.id as never)}/>
  )

  return (
  <DropdownButton
      buttonChildren={(open: boolean) => <NotificationsButton open={open} isUnreadNotifications={getUnreadNotificationsCount() > 0} />}
      dropdownChildren={() => <div>
        <NotificationHeader
          newNotificationsLength={getUnreadNotificationsCount()}
          onClickMarkAllAsRead={markAllNotificationAsRead} />
          {notifications.length ? 
          notifications.map((notification: INotification, i: number) =>
          userIsAdmin ? 
            NotificationWithProps(notification, i)
            
              : notification.eventName === "Job simulation changed" ?
              NotificationWithProps(notification, i)
              : null
          )
          : <div style={{textAlign: "center", marginTop: "20px"}}>No notifications yet…</div>}
        </div>
      }
      buttonClassName={"notification-center-button"}
      dropdownClassName={"custom-list-no-padding notification-dropdown"}
      dropdownStyle={{}}
      dropdownWidth={"380px"} />
  );
}


export default Notifications;