import { ComProfile } from '../../data/report.utils';
import infoIcon from '../../icons/info.svg';
import lightIcon from '../../icons/light-black.svg';

import './style.css';

const potentials: Record<string, { label: string }> = {
    II: { label :"HIGH POTENTIAL" },
    IR: { label :"HIGH POTENTIAL" },
    RR: { label :"HIGH POTENTIAL" },
    RRI: { label :"HIGH POTENTIAL" },
    RRS: { label :"HIGH POTENTIAL" },
    RS: { label :"HIGH POTENTIAL" },
    RSR: { label :"HIGH POTENTIAL" },
    RSRS: { label :"HIGH POTENTIAL" },
    RSS: { label :"HIGH POTENTIAL" },
    SR: { label :"HIGH POTENTIAL" },
    SRS: { label :"HIGH POTENTIAL" },
    SS: { label :"HIGH POTENTIAL" },
    SVS: { label :"HIGH POTENTIAL" },
    VS: { label :"HIGH POTENTIAL" }
};

const SynergyTable: React.FunctionComponent<{
    summary: { [key: string]: string },
    candidateComProfile: string,
    hrmComProfile?: ComProfile
}> = ({ summary, candidateComProfile,  hrmComProfile}):JSX.Element => {

    const objKeysArr = Object.keys(summary);
    const key = hrmComProfile + candidateComProfile;
    const potential = potentials[key]?.label || "POTENTIAL";
    
    const tableElements:React.FunctionComponent[] = [];
    for(let i=0; i<objKeysArr.length; i+=2) {
        
        if(summary[objKeysArr[i]] && summary[objKeysArr[i+1]]) {
            tableElements.push(
            () => <tr>
                <td style={{ width: "50%" }}>
                    <div className='table-cell-content'>
                        <img src={lightIcon}/>
                        <span>{summary[objKeysArr[i]]}</span>
                    </div>
                </td>
                <td style={{ width: "50%" }}>
                    <div className='table-cell-content'>
                        <img src={lightIcon}/>
                        <span>{summary[objKeysArr[i+1]]}</span>
                    </div>
                </td>
            </tr>
            )
        } 
        if( summary[objKeysArr[i]] && !summary[objKeysArr[i+1]]) {
            
            tableElements.push(
                () => <tr>
                <td>
                    <div className='table-cell-content'>
                        <img src={lightIcon}/>
                        <span>{summary[objKeysArr[i]]}</span>
                    </div>
                </td>
                <td>
                </td>
            </tr>
            )
        }
    }    
    
    return (
    <div className="report-synergy-talent-hrm-container no-print" >
        <div className="synergy-talent-hrm-description">
            <img src={infoIcon}/>
            <p>
                Here, you will find forecasts and recommendations on the <span>synergy between the Manager and Talent.</span>&nbsp;
                They correspond to relational exchanges and situations when both parties are in full possession 
                of their personal resources, skills, and talents.
            </p>
        </div>
        <h3 className="synergy-talent-hrm-title">
            This working relationship has <span>{potential}</span> of being in growth as both sides converge positively in: 
        </h3>
        <table className="talent-report-synergy-table">
        <tbody>
            {tableElements.map((E, i)=> (
                <E key={i+"synergy"}/>
            ))}
        </tbody>
        </table>
    </div>
    )
}

export default SynergyTable;