import { useContext, useEffect, useState } from "react";

import { Title } from "@/components";
import { GridColDef } from "@mui/x-data-grid";
import BuildingBlockEditor, { BuildingBlock, EditorMode } from "./BuildingBlockEditor";
import cx from "classnames";
import DropDownWithDotsMaterial from "@/components/DropDownWithDotsMaterial";
import { EditorApi } from "../AssessmentEditor/data/editor.api";
import useLocalToast from "../../hooks/useLocalizedToast";

import './style.css';

import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import WizardLoading from "@/components/WizardComponents/WizardLoading/WizardLoading";
import AppContext from "@/context/AppContext";


const DragHandle = SortableHandle(() => <span style={{ cursor: "pointer" }}>::</span>);

const SortableItem = SortableElement(({bb, columns, options }: {bb: BuildingBlock, columns: GridColDef[], options: { label: string, onClick: () => any }[] }): JSX.Element => (
    <TableRow>
        <TableCell>
            <DragHandle />
        </TableCell>
        {columns.map(({ field }) => (
            <TableCell key={field}>
                {(bb as any)?.[field] ?? (bb as any)?.metadata?.[field]}
            </TableCell>
        ))}
        <TableCell>
            <DropDownWithDotsMaterial options={options} />
        </TableCell>
    </TableRow>
));

const SortableContainerComponent = SortableContainer(({children}: { children: React.ReactNode }): JSX.Element => {
    return <TableBody>{children}</TableBody>;
});

type BuildingBlocksKeys = keyof BuildingBlock
const fieldNameHeaderName: { [key in BuildingBlocksKeys]: string | {} } = {
    id: "ID",
    name: "Name",
    metadata: {
        description: "Description",
        introType: "Intro types",
        introTitle: "Intro title",
        introDescription: "Intro description",
        assessmentLength: "Length",
        scoreAs: "Score as",
        isMirroring: "Is mirroring"
    }
}

const ADD_NEW_BB_BTN_TEXT = "Add new building block"

const BuildingBlocks: React.FunctionComponent = (): JSX.Element => {
    
    const [openBBEditor, setOpenBBEditor] = useState<boolean>(false);
    const [BBEditorMode, setBBEditorMode] = useState<EditorMode>(EditorMode.CREATE);
    const [BBToEdit, setBBToEdit] = useState<BuildingBlock | null>(null);
    
    const [loading, setLoading] = useState<boolean>(false);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [originalData, setOriginalData] = useState<BuildingBlock[]>([]);

    const [lastOrderData, setLastOrderData] = useState<BuildingBlock[]>([]);

    const { toast } = useLocalToast();
    const { tr, locale } = useContext(AppContext);

    useEffect(() => {
        setColumns(generateTableColumnsSettings(fieldNameHeaderName));
        setLoading(true);
        EditorApi.getBuildingBlocks()
            .then((bbs) => {
                setOriginalData(bbs)
                setLastOrderData(bbs)
            })
            .catch(console.error)
            .finally(() => setLoading(false))
        
    }, [locale])

    const tableActionMenu = (buildingBlock: BuildingBlock) => ([
        {
            label: "Edit Building block",
            onClick: () => openBuildingBlockEditor(EditorMode.EDIT, buildingBlock)
        },
        {
            label: "Delete Building block",
            onClick: () => deleteBuildingBlock(buildingBlock)
        }
    ])

    const generateTableColumnsSettings = (obj: { [key: string]: string | {} }, nastedKeyName?: string): GridColDef[] => {
        const keys = Object.keys(obj)
        const filteredKeys = keys.filter(k => k !== "id" && typeof(obj[k]) !== "object")        
        let columns: GridColDef[] = filteredKeys.map((fieldName: any) => ({
            field: fieldName,
            headerName: tr((obj as any)[fieldName]),
            flex: 1,
            renderCell: nastedKeyName ? ({ row }) => (row as any)?.[nastedKeyName]?.[fieldName] : undefined
        }));

        const isNestedObj = keys.find(k => typeof(obj[k]) === "object")
        if(isNestedObj){
            columns = columns.concat(generateTableColumnsSettings(obj[isNestedObj] as any, isNestedObj))
        }

        const isOptionsColExist = columns.find(c => c.field === 'opt')
        if(isOptionsColExist) 
            return columns

        const optionsCol: GridColDef = {
            field: "opt",
            headerName: "",
        }
        columns.push(optionsCol)
        
        return columns
    }

    const deleteBuildingBlock = (buildingBlock: BuildingBlock) => {
        const { id } = buildingBlock || {};
        if(!id) {
            console.error("Cannot delete building block with the given ID");
            return
        }

        EditorApi.deleteBuildingBlock(id)
        .then(() => {
            setOriginalData(prev => prev.filter(bb => bb.id !== id))
        })
        .catch(console.log)
    }

    const openBuildingBlockEditor = (mode: EditorMode = EditorMode.CREATE, buildingBlock?: BuildingBlock): void => {
        if(mode === EditorMode.CREATE) {
            setBBEditorMode(EditorMode.CREATE)
            setBBToEdit(null)
        }else {
            const { id } = buildingBlock || {};
            if(!id) {
                console.error("Cannot find building block with the given ID");
                return
            }
            setBBEditorMode(EditorMode.EDIT);
            setBBToEdit(buildingBlock || null);
        }

        setOpenBBEditor(true)
    }

    const onNewBuildingBlockCreated = (bbs: BuildingBlock[]) => {
        setOriginalData(prev => [...prev.concat(bbs)])
    }

    const onBuildingBlockUpdated = (bbs: BuildingBlock[]) => {
        const updatedBuildingBlock = bbs[0];
        const { id } = updatedBuildingBlock || {};
        
        if(!id)
            return

        setOriginalData(prev => [...prev.map(bb => bb.id === id ? updatedBuildingBlock : bb)])
    }

    const toastMessage = (msg: string, type: 'info' | 'success' | 'warning' | 'error' | 'default') => {
        toast(msg, {
            autoClose: 4000,
            containerId: "default",
            type
        });
    }

    const onFormSubmit = async (bb: BuildingBlock) => {
        const bbs = await EditorApi.postBuildingBlock(bb);
        if(!bbs) {
            toastMessage(`Cannot ${ BBEditorMode === EditorMode.CREATE ? "created" : "updated"} building block`, "error");
            return
        }
        const successMsg = `Building Block ${ BBEditorMode === EditorMode.CREATE ? "created" : "updated"} successfully`;
        toastMessage(successMsg, 'success')
        setOpenBBEditor(false);
        if(BBEditorMode === EditorMode.CREATE){
            onNewBuildingBlockCreated(bbs);
        }
        else{
            onBuildingBlockUpdated(bbs);
        }
    }

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }): void => {
        const oldIndexId = originalData[oldIndex].id;
        const newIndexId = originalData[newIndex+1].id;

        if((!oldIndexId || !newIndexId) || newIndex === oldIndex) return;

        setOriginalData((prev) => arrayMove(prev, oldIndex, newIndex));

        EditorApi.orderBuildingBlocks(oldIndexId, newIndexId)
        .then((response) => {
            const { errors, result } = response || {};

            if(!errors && result) {
                setLastOrderData(result);
            }
            
            if(errors) {
                toastMessage(`Cannot order building blocks`, "error");
                setOriginalData(() => lastOrderData);
            }
        })
        .catch(() => {
            toastMessage(`Cannot order building blocks`, "error");
            setOriginalData(() => lastOrderData);
        })
        
    }

    return (
        <>
            {!loading &&
            <div>
                <Title text={tr("Building Blocks")}/>
                <div>
                    
                    <button
                    onClick={() => openBuildingBlockEditor()}
                    className={cx([
                        "inline-flex px-6 py-3.5",
                        "items-start justify-start",
                        "bg-black rounded focus:outline-none",
                        "transition rounded-sm",
                        "text-sm font-medium leading-none text-white"
                    ])}> 
                        {tr(ADD_NEW_BB_BTN_TEXT)}
                    </button>
                </div>
                <div className="mt-3"> 
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                {columns.map(({ headerName, field }) => (
                                    <TableCell key={field}>{headerName}</TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <SortableContainerComponent useDragHandle onSortEnd={onSortEnd}>
                                {originalData.map((bb, index) => (
                                    <SortableItem 
                                    key={bb.id} 
                                    index={index} 
                                    bb={bb} 
                                    columns={columns} 
                                    options={tableActionMenu(bb)}/>
                                ))}
                            </SortableContainerComponent>
                        </Table> 
                    </TableContainer>
                    
                </div>
            </div>}
            {loading && <WizardLoading/>}
            {openBBEditor && 
                <BuildingBlockEditor
                openBuildingBlockEditor={openBBEditor} 
                setOpenBuildingBlockEditor={setOpenBBEditor}
                editorMode={BBEditorMode}
                builidingBlockToEdit={BBToEdit}
                onSubmitBuildingBlock={onFormSubmit}
                />}
        </>
    )
}

export default BuildingBlocks;