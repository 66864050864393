const AVG_TEXT = "Avg. years per employer";
const TOTAL_EXP_TEXT = "Total years of employment";
const TOTAL_MNG_EXP_TEXT = "Total years as management";
const GAPS_TEXT = "Gaps in years of employment";

export const getLabelValueArrFromCvInsights = (args: {
        avgYearsPerEmployer?: number;
        totalYearsExp?: number;
        totalYearsManagementExp?: number;
        gaps?: number;
    }): { label: string, value: number }[] => {
    const { avgYearsPerEmployer, totalYearsExp, totalYearsManagementExp, gaps } = args;
    
    const labelValueArr = [];
    if (avgYearsPerEmployer) {
    labelValueArr.push({
        label: AVG_TEXT,
        value: avgYearsPerEmployer
    });
    }
    if (totalYearsExp) {
    labelValueArr.push({
        label: TOTAL_EXP_TEXT,
        value: totalYearsExp
    });
    }
    if (totalYearsManagementExp) {
    labelValueArr.push({
        label: TOTAL_MNG_EXP_TEXT,
        value: totalYearsManagementExp
    });
    }
    if (gaps) {
    labelValueArr.push({
        label: GAPS_TEXT,
        value: gaps
    });
    }
    return labelValueArr;
}