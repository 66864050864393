export const getCompanyInformationByID = `
  query ($getAllCompanyInformationCompanyId: UUID!) {
    getAllCompanyInformation(companyId: $getAllCompanyInformationCompanyId) {
        owner
        name
        email
        emailDomain
        clientId
        customSubdomain
        websiteLink
        additionalInfo
        description
        yearFounded
        industry,
        metadata,
        locality,
        cultureAndValue,
        leaders,
        currentEmployeeEstimate,
        totalEmployeeEstimate,
        culture,
        value,
        atsProvider,
        atsCompanyUid
        sizeRange
        linkedinUrl,
        hq,
        profilePictureUrl,
        slug 
    }
  }
`
