import { IAppContext } from "@/context/AppContext";
import { IMenuItem } from "@/interfaces/sideMenu";
import { useEffect, useState } from "react";

function useMenu(data: IMenuItem[], ctx: IAppContext): {
    items: IMenuItem[]
} {

    const [items, setItems] = useState<IMenuItem[]>([]);

    useEffect(() => {
        setItems(ctx.localizer.localizeObject({
            data,
            locale: ctx.locale,
            keys: "name"
        }))
    }, [ctx.locale])

    return { items }
}

export default useMenu;