import { graphQueries } from "@/helpers";
import { IAuth } from ".";
import Cookies from "js-cookie"

export interface IUserCookieMonitor {
	readonly authContext: IAuth;
}

/**
 * Installs a listener for all gql queries and updates the auth user if 
 * the infoToken cookie changes
 */
class UserCookieMonitor implements IUserCookieMonitor {
	constructor() {
		graphQueries.addResponseListener(this, async (response: any) => {
			return this.onResponseReceived(response);
		})
		this._userCookie = Cookies.get("infoToken") || "";
	}

	private _userCookie: string;
	private _auth: IAuth | null = null;


	public set authContext(ctx: IAuth) {
		this._auth = ctx;
	}

	public get authContext(): IAuth {
		return this._auth!;
	}

	/**
	 * Callback that intercepts gql responses and checks for an infotoken cookie change
	 * @param response 
	 * @returns 
	 */
	private onResponseReceived(response: any): any {
		if (this._auth) {
			const cookie =  Cookies.get("infoToken");
			if (cookie !== this._userCookie) {
				this._userCookie = cookie || "";
				console.log(`infoToken changed to ${cookie}`)
				this._auth.updateUserFromToken(this._userCookie)
			}
		}
		return response;
	}
}

let ucm: IUserCookieMonitor;

export const useUserCookieMonitor = (auth: IAuth): IUserCookieMonitor => {
	ucm = ucm || new UserCookieMonitor();
	(ucm as UserCookieMonitor).authContext = auth;
	return ucm;
}