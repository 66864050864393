import { FormikProps, useFormik } from "formik";
import { useEffect, useState, useContext } from "react";
import ComponentStepper from "@/components/WizardComponents/ComponentsStepper";
import { WizardStepsIds } from "./interfaces";
import { CreateNewPositionStep, RequirementsStep, DescriptionStep, PublishStep, RecipientsStep, TraitsAndSkillsStep, BuildingBlocksStep } from "./wizardSteps";
import './style.css';
import { useHistory, useLocation, useParams } from "react-router";
import { useAuth } from "@/auth";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { createNewPosition, getPosition, getMatchingWizardInitialFields, thereIsNoDiscoveryFields, getDiscoveryFieldsFromMatchingFields, getQuizBuildingBlocks } from "./helpers";
import { IPosition } from "@/interfaces/pages/positions";
import { throttleUpdatePosition } from "../helpers";
import { IPage, 
    // PaginationDirections 
} from "@/components/WizardComponents/ComponentsStepper/interfaces";
import SuggestionCard from "@/components/WizardComponents/SuggestionCard/SuggestionCard";
import { SuggestionHover } from "./wizardSteps/CreateNewPositionStep/components";
import { Modalbox } from "@/components";
import IframeQuizModal from "../../IframeQuizModal";
import { getSimilarPositionTitles } from "./wizardSteps/CreateNewPositionStep/helpers";
import { removeDuplicates } from "@/helpers";
import AppContext from "@/context/AppContext";

const MatchingWizardSchema = Yup.object().shape({
    matchingTitle: Yup.string().min(1).required("Title is required field"),
    matchingSkills: Yup.array().required().min(1, "At least one skill is required"),
    atsEmail: Yup.string().email()
        .when("matchingRecipients", {
            is: (matchingRecipients: any) => !matchingRecipients || !matchingRecipients.length,
            then: Yup.string().email("Must be valid email").required("At least one recipients is required or ats email")
        }),
    matchingRecipients: Yup.array()
        .when("atsEmail", {
            is: (atsEmail: string) => atsEmail?.length <= 0,
            then: Yup.array().min(1, "At least one recipients is required or ats email")
        })
}, [["matchingRecipients", "atsEmail"]]);

const MatchingWizard: React.FunctionComponent = ():JSX.Element => {

    const history = useHistory()

    const { search } = useLocation();
    const { tr } = useContext(AppContext);
	const query = new URLSearchParams(search);

    const { positionId } = useParams<{positionId: any }>();
    const [position_id, setPosition_id] = useState<string|null>(positionId ?? null);
    const [source] = useState<string>(query.get("source") ?? "direct");

    const [isEditFlow, setIsEditFlow] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStepLoading, setIsStepLoading] = useState<boolean>(false);
    const [wizardDataIsReady, setWizardDataIsReady] = useState<boolean>(false);

    const [positionInitialValues, setPositionInitialValues] = useState<Partial<IPosition>>({});

    const [showNewTemplateWarning, setShowNewTemplateWarning] = useState<{ show: boolean, setStep?: (args: any) => any }>({show:false});

    const [showQuizModal, setShowQuizModal] = useState<boolean>(false);
    const [startQuizOnOpen, setStartQuizOnOpen] = useState<boolean>(false);
    const [quizBuildingBlocks, setQuizBuildingBlocks] = useState<any[]>([]);
    const [showQuizButtonOnLastStep, setShowQuizButtonOnLastStep] = useState<boolean>(false);

    const [currentPageId, setCurrentPageId] = useState<string>(position_id ? WizardStepsIds.TraitsAndSkills : WizardStepsIds.SELECT_POSITION );

    const { user, company } = useAuth();
    const { id: companyId } = company || {};
    
    const formik: FormikProps<Partial<IPosition>> = useFormik<Partial<IPosition>>({
        initialValues: positionInitialValues,
        enableReinitialize: true, // Enable re-set the initial values
        validationSchema: MatchingWizardSchema,
        validateOnMount: true,
        onSubmit: console.log,
    });

    const { values: wizardData, errors, isValid: wizardDataIsValid, setValues: setWizardData } = formik;
    
    const [lastWizardData, setLastWizardData] = useState<Partial<IPosition>>(wizardData);
    
    const { matchingTitle } = wizardData;

    useEffect(() => {
        if(!position_id) {
            setWizardDataIsReady(true)
            return;
        }
        setIsEditFlow(true)
        setIsLoading(true);
        getPosition(position_id)
        .then((p) => { 
            if(!p) {
                history.push("/positions")
                toast(tr("Cannot find the position you want to edit"), { containerId: "default" })
            }
            const preparedPosition = getMatchingWizardInitialFields(p!);
            setLastWizardData({...preparedPosition})
            setPositionInitialValues(preparedPosition!);
        })
        .catch(console.error)
        .finally(() => {setWizardDataIsReady(true); setIsLoading(false);})
    }, [])

    /**
     * Handle form change globally
     */
    useEffect(() => {
        const { id } = wizardData || {};
        if(!id){
            return
        }

        if(!wizardDataIsValid || !wizardDataIsReady) {
            return
        }
        
        setLastWizardData({...wizardData})

        throttleUpdatePosition({data: wizardData, lastPosition: lastWizardData, user, company, id: position_id, source})
        
    }, [wizardDataIsValid, wizardData])

    const displayErrors = () => {
        if(!errors)
            return
        
        for(const e in errors){
            toast((errors as any)?.[e], { containerId: "default", type: "error" })
        }
    }   

    const checkIfWizardDataIsValidPerStep = (currentPageId: string, errors: {[field: string]: string}): boolean => {   
        if(!wizardDataIsReady || !position_id || !errors) return true;

        const validationPerPage = {
            [WizardStepsIds.SELECT_POSITION]: ["matchingTitle"],
            [WizardStepsIds.TraitsAndSkills] : ["matchingSkills"],
            [WizardStepsIds.RECIPIENTS]: ["matchingRecipients", "atsEmail"]
        };

        let isValid = true

        Object.keys(errors).forEach((k) => {
            const pageOfError = Object.entries(validationPerPage).find(([,fields]) => fields.includes(k))?.[0];            
            if(pageOfError === currentPageId) isValid = false;
        })

        return isValid;
    }

    const onStepChange = async (pageId: number | string) => {
        if(!checkIfWizardDataIsValidPerStep(currentPageId as any, errors)) {
            displayErrors();
            return
        }
        
        const { matchingHrms } = wizardData;
        const currentUserIsHrm = matchingHrms?.email === user?.email;

        const needCreateNewPosition = pageId === WizardStepsIds.TraitsAndSkills && !position_id;
        const needGenerateOtherPositionTitles = pageId === WizardStepsIds.REQUIREMENTS && position_id;
        const needToOpenQuizModal = pageId === WizardStepsIds.BUILDING_BLOCKS && position_id && currentUserIsHrm;
        const needToGenerateDiscovertFields = pageId === WizardStepsIds.BUILDING_BLOCKS && position_id && thereIsNoDiscoveryFields(wizardData);

        setCurrentPageId(pageId as any)

        if(needGenerateOtherPositionTitles) {
            const { matchingSkills, matchingTitle, matchingOtherTitles } = wizardData || {};
            const skills = matchingSkills?.map((skill) => skill.name) ?? [];
            getSimilarPositionTitles(skills, matchingTitle!) 
            .then((ots) => {
                const newOtherTitles = [...ots, ...(matchingOtherTitles ?? [])];
                const newOtherTitlesWithoutDuplicates = removeDuplicates(newOtherTitles, JSON.stringify);
                setWizardData({...wizardData, matchingOtherTitles: newOtherTitlesWithoutDuplicates})
            })
            .catch(console.error)
        }

        if(needCreateNewPosition){
            setIsLoading(true)
            createNewPosition(wizardData, companyId, user)
            .then((position) => {
                const { id } = position || {};
                if(id && position) { 
                    setLastWizardData({...position})
                    setPositionInitialValues(position);
                    setPosition_id(id); 
                    history.replace({ pathname: `/positions/wizard/${id}` });
                }
            })
            .catch(console.error)
            .finally(() => setIsLoading(false))
        }

        if(needToOpenQuizModal) {
            setIsLoading(true)
            getQuizBuildingBlocks(user?.id ?? "", positionId)
            .then((bbs) => {
                if(!bbs || bbs.length <= 0) return;
                setQuizBuildingBlocks(bbs);
                setShowQuizButtonOnLastStep(true);
                setShowQuizModal(true);
            })
            .catch(console.error)
            .finally(() => setIsLoading(false))
        }

        if(needToGenerateDiscovertFields) {
            const discoveryData = getDiscoveryFieldsFromMatchingFields(wizardData);
            setWizardData({...wizardData, ...discoveryData})
        }
    }

    const onApprovePositionTemplateWarning = () => {
        setPosition_id(null);
        setPositionInitialValues({});
        history.push('/positions/wizard');
        setShowNewTemplateWarning((prev) => ({...prev, show: false}));
        showNewTemplateWarning?.setStep?.({ stepId: WizardStepsIds.SELECT_POSITION });
    }

    const isMatchingTitleExist = matchingTitle && matchingTitle.length > 0;
    const wizardSteps: IPage[] = [
        { 
            id: WizardStepsIds.SELECT_POSITION, 
            hideNavigationButtons: true,
            hideProgressBar: true,
            validateFields: ["matchingTitle"],
            stepName: isMatchingTitleExist ? ({ setStep }) =>
                <SuggestionCard
                toolTipStyle={{ marginTop: "4vh" }}
                className="wizard-suggestion-card-custom-step"
                toolTipComponent={<SuggestionHover position={wizardData as any}/>}
                showDeleteIcon={true}
                suggestion={{value: undefined,label: matchingTitle}} 
                onClick={() => setShowNewTemplateWarning({ show: true, setStep })}/> 
                : tr("Select a Position"),  
            component: ({ setStep, pages }) => (
                <CreateNewPositionStep 
                pages={pages}
                user={user}
                formik={formik} 
                setStep={setStep} 
                setIsLoading={setIsStepLoading} 
                isLoading={isStepLoading}
                position_id={position_id}
                companyId={company?.id}/>
            ),
        },
        { 
            id: WizardStepsIds.TraitsAndSkills, 
            stepName: "Traits and skills",
            hidePreviousButton: true,
            component: () => (
                <TraitsAndSkillsStep 
                formik={formik} 
                setIsLoading={setIsStepLoading} 
                isLoading={isStepLoading}/>
            ) 
        },
        { 
            id: WizardStepsIds.REQUIREMENTS, 
            stepName: "Requirements",  
            component: () => (
                <RequirementsStep 
                formik={formik} 
                setIsLoading={setIsStepLoading} 
                isLoading={isStepLoading}/>
            )
        },
        { 
            id: WizardStepsIds.DESCRIPTION, 
            stepName: "Description",
            component: () => (
                <DescriptionStep 
                formik={formik} 
                setIsLoading={setIsStepLoading} 
                isLoading={isStepLoading}/>
            ) 
        },
        { 
            id: WizardStepsIds.RECIPIENTS, 
            stepName: "Managers and recepients",  
            validateFields: ["matchingRecipients", "atsEmail"],
            component: () => (
                <RecipientsStep 
                formik={formik} 
                setIsLoading={setIsStepLoading} 
                isLoading={isStepLoading}/>
            ) 
        },
        { 
            id: WizardStepsIds.PUBLISH, 
            stepName: "Review",  
            nextButtonText: "Finish & get link",
            hideSkipButton: true,
            // handleStepLogic: handlePreviewStepNavigationLogic,
            component: ({ setStep, pages }) => (
                <PublishStep 
                pages={pages}
                formik={formik} 
                setStep={setStep} 
                setIsLoading={setIsStepLoading} 
                isLoading={isStepLoading}/>
            ),
        },
        { 
            id: WizardStepsIds.BUILDING_BLOCKS, 
            stepName: `Done!`,  
            onlyCurrentStepNameBreadcrumb: !isEditFlow,
            hideNavigationButtons: true,
            hideProgressBar: true,
            component: () => (
                <BuildingBlocksStep 
                openDNAQuizModal={(val: boolean) => setShowQuizModal(val)}
                formik={formik} 
                setStartQuizOnOpenModal={setStartQuizOnOpen}
                setIsLoading={setIsStepLoading} 
                isLoading={isStepLoading}
                hideQuizButton={!showQuizButtonOnLastStep}/>
            ),
        }
    ];

    return (
        <div>
            <ComponentStepper
            isLoading={isLoading || isStepLoading || !wizardDataIsReady}
            pages={wizardSteps}
            showAllBreadcrumps={isEditFlow}
            onStepChange={onStepChange}
            disableNextStep={!checkIfWizardDataIsValidPerStep(currentPageId, errors)}
            initialStepIndex={position_id ? 1 : 0}/>

            {showNewTemplateWarning.show &&
            <Modalbox
            className={"wizard-new-template-warning-modal"}
            message={tr("Quit?")}
            description={tr("You are about to exit the simulation creation proccess. Are you sure?")}
            cancelText="Cancel"
            confirmText="I'm sure"
            onCancel={() => setShowNewTemplateWarning(prev => ({...prev, show: false }))}
            onClose={() => setShowNewTemplateWarning(prev => ({...prev, show: false}))}
            onConfirm={onApprovePositionTemplateWarning}/>}

            <IframeQuizModal
            open={showQuizModal}
            iframeName="hrm-quiz-iframe"
            onClose={() => setShowQuizModal(false)} 
            startQuizOnOpen={startQuizOnOpen}
            userId={user?.id} 
            positionId={position_id!} 
            buildingBlocks={quizBuildingBlocks}/>
        </div>
    )
}

export default MatchingWizard;