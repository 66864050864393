import { useContext } from "react";
import ArrayControlForm from "@/components/WizardComponents/ArrayControlForm/ArrayControlForm";
import TextField from "@/components/WizardComponents/TextField/TextField";
import WizardStepTemplate from "@/components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { IWizardStep } from "../../interfaces";
import ImageUpload from "@/components/UnboxableForm/ui/components/ImageUpload";
import AuthContext from "@/auth/context";
import { toBase64 } from "@/helpers";
import { MediaUploader } from "@/helpers/mediaUploader";

import './style.css';
import TextArea from "@/components/WizardComponents/TextArea/TextArea";

const TITLE = "Who will the Talent report to?";
const DESCRIPTION  = `This is not a boring professional bio section, that's what LinkedIn is for. Introduce Talent to who's behind the "manager" job title. While you're at it, please indicate the direct manager so we can provide you with matching info.`

const LeadersStep: React.FunctionComponent<IWizardStep> = ({ formik }) => {
    const { values, setFieldValue, handleChange } = formik;
    const { leaders } = values;

    const { settings } = useContext(AuthContext);

    const uploadImage = async (files: FileList | null) => {
        const [ file ] = files || [];

        if(!file)
            return

        try {
            const imageData = (file && await toBase64(file)) || "";
            const mediaUploader = new MediaUploader(settings);
            const result = await mediaUploader.upload({
                data: imageData,
                name: file.name
            });
            const { url, error } = result || {};
            if(error) {
                throw new Error(error); 
            }
            if(url) {          
                return url
            }
            throw Error('Something went wrong...');
        } 
        catch(e) {
            console.error(e);
        }

    };

    const onImageChange = (leaderIndex: number, files: FileList | null) => {
        let updatedLeader;

        if(!files) {
            updatedLeader = { ...leaders?.[leaderIndex], image: null };
            const newLeaders = leaders?.filter((l, i) => i !== leaderIndex).concat(updatedLeader)
            setFieldValue("leaders", newLeaders)
        }else {
            uploadImage(files)
            .then((src) => {
                if(!src)
                    return
                    
                updatedLeader = { ...leaders?.[leaderIndex], image: src };
                const newLeaders = leaders?.filter((l, i) => i !== leaderIndex).concat(updatedLeader)
                setFieldValue("leaders", newLeaders)
            })
            .catch(console.error);
        }


    }

    return (
        <WizardStepTemplate
        title={TITLE}
        description={DESCRIPTION}>
            <ArrayControlForm
            setFieldValue={setFieldValue} 
            header={"Leaders"} 
            fieldName={"leaders"} 
            values={leaders ?? []} 
            defaultItemWhenAdded={{}} 
            Elements={(i) => (
                    <div className="leaders-tab-data-text-fields-container">
                        <div className="leaders-tab-data-text-fields-name-title">
                            <TextField
                            style={{ width: "206px" }}
                            uiType="white-bordered"
                            placeholder="Leader Name"
                            name={`leaders[${i}].name`}
                            value={leaders?.[i]?.name}
                            onChange={handleChange}
                            />
                            <TextField
                            style={{ width: "206px" }}
                            uiType="white-bordered"
                            placeholder="Job Title"
                            name={`leaders[${i}].title`}
                            value={leaders?.[i]?.title}
                            onChange={handleChange}
                            />
                            <ImageUpload 
                            className="leaders-tab-data-image-upload"
                            label={"Upload picture"}
                            name={`leaders[${i}].image`}
                            src={leaders?.[i]?.image} 
                            clear={() => onImageChange(i, null)} 
                            onChange={(e) => onImageChange(i, e.target.files)}
                            />
                        </div>
                        <TextArea
                        uiType="white-bordered"
                        style={{ width: "100%", marginTop: "14px", height: "100px" }}
                        placeholder="Type Description"
                        name={`leaders[${i}].description`}
                        value={leaders?.[i]?.description}
                        onChange={handleChange}
                        />
                    </div>
            )}/>

        </WizardStepTemplate>
    )
}

export default LeadersStep;