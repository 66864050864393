// Local Interfaces
interface IDoCustomStyles {
  width: number | string
}

const doCustomStyles = ({ width }: IDoCustomStyles): object => {
  return {
    container: (provided: any) => ({
      ...provided,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',

      // marginLeft: '10px',
      // marginRight: '5px',
      width: width,
      backgroundColor: 'transparent',
      fontSize: '18px',
      minWidth: "120px",
      fontWeight: 'regular',
      paddingBottom: 0,
      paddingTop: 0
    }),

    control: (provided: any) => ({
      ...provided,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      borderRadius: 0,
      boxShadow: 'none !important'
    }),

    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none'
    }),

    placeholder: (provided: any) => ({
      ...provided,
      // top: '52%',
    }),

    menu: (provided: any) => ({
      ...provided,
      width: "max-content",
      minWidth: "100%",
    })
  }
}

export default doCustomStyles
