import { gql } from "graphql-request";
// import { CreatePositionRequest, ReadPositionResult, UpdatePositionRequest } from "../position";
import { CreateV2PositionRequest, UpdateV2PositionRequest } from "./interfaces";
export function createMutation(
  fields: string[], 
  func: string, 
  typesMap: { [field: string]: string },
  expected: string[]): string {
    
  const typesDef = fields.map(f => `$${f}: ${typesMap[f]}`).join(', ');
  const varsDef = fields.map(f => `${f}: $${f}`).join(', ');

  return gql`mutation Mutation(${typesDef}) {
    ${func}(${varsDef}) {
      ${expected.join('\n')}
    }
  }`;
}
export enum PositionMutations {
  createV2Position = 'createV2Position',
  updateV2Position = 'updateV2Position'
}

const CreatePositionTypesMap: {[field: string]: string} = {
  companyId: "UUID!", 
  userId: "UUID!", 
  department: "String!", 
  title: "String!", 
  description: "String",
  yearsOfExperience: "Int",
  yearsOfManagmentExperience: "Int",
  status: "String", 
  skills: "[Skill]", 
  education: "[Education]", 
  dayInALife: "[DayInALife]", 
  cultureAndValue: "[CultureAndValue]", 
  leaders: "[Leader]", 
  assessmentStatus: "String", 
  assessmentName: "String", 
  assessmentId: "UUID", 
  recipients: "[String]", 
  atsEmail: "String", 
  positionFormJson: "JSON", 
  rolesAndResponsibilities: "[String]", 
  updatedRolesAndResponsibilities: "String",
  eligibilities: "[String]", 
  positionImage: "String", 
  location: "String", 
  jobTypes: "JobType", 
  templates: "String", 
  metadata: "JSON",
  matchingOtherTitles: "[String]",
  isManager: "Boolean",
  qualifications: "String",
  languages: "[String]",
  volunteering: "[String]",
  hobbies: "[String]",
  matchingDescription: "String",
  matchingYearsOfManagementExperience: "Int", 
  matchingYearsOfExperience: "Int",
  matchingDepartment: "String!",
  matchingSkills: "[JSON]",
  matchingEducation: "[JSON]",
  matchingEligibilities: "[String]",
  matchingLocation: "String",
  matchingBuildingBlocks: "[String]",
  matchingLanguages: "[String]",
  matchingHobbies: "[String]",
  matchingVolunteering: "[String]",
  matchingRecipients: "[String]",
  matchingTitle: "String!",
  matchingHrms: "JSON",
  matchingCvKeywords: "[String]",
  matchingJobTypes: "[String]",
  matchingPermissions: "[String]",
  matchingRelatedSkills: "[String]",
  matchingTemplateId: "String"
};

const UpdatePositionTypesMap: {[field: string]: string} = {
  positionId: "UUID!", 
  companyId: "UUID", 
  department: "String",
  title: "String", 
  description: "String",
  yearsOfExperience: "Int",
  yearsOfManagementExperience: "Int", 
  status: "String", 
  skills: "[Skill]", 
  comProfile: "String",
  education: "[Education]", 
  cultureAndValue: "[CultureAndValue]", 
  dayInALife: "[DayInALife]", 
  assessmentStatus: "String", 
  assessmentId: "UUID", 
  assessmentName: "String", 
  positionFormJson: "JSON", 
  recipients: "[String]", 
  deleted: "Boolean", 
  atsEmail: "String", 
  leaders: "[JSON]", 
  rolesAndResponsibilities: "[String]", 
  updatedRolesAndResponsibilities: "String",
  eligibilities: "[String]", 
  metadata: "JSON", 
  positionImage: "String", 
  location: "String", 
  jobTypes: "JobType", 
  templates: "String",
  isPublished: "Boolean",
  matchingOtherTitles: "[String]",
  isManager: "Boolean",
  qualifications: "String",
  languages: "[String]",
  volunteering: "[String]",
  hobbies: "[String]",
  matchingDescription: "String",
  matchingYearsOfManagementExperience: "Int", 
  matchingYearsOfExperience: "Int",
  matchingDepartment: "String!",
  matchingSkills: "[JSON]",
  matchingEducation: "[JSON]",
  matchingEligibilities: "[String]",
  matchingLocation: "String",
  matchingBuildingBlocks: "[String]",
  matchingLanguages: "[String]",
  matchingHobbies: "[String]",
  matchingVolunteering: "[String]",
  matchingRecipients: "[String]",
  matchingTitle: "String!",
  matchingHrms: "JSON",
  matchingCvKeywords: "[String]",
  matchingJobTypes: "[String]",
  matchingPermissions: "[String]",
  matchingRelatedSkills: "[String]",
  matchingTemplateId: "String",
  isUpdatingEditor: "Boolean"
};

const CreatePositionFields = Object.keys(CreatePositionTypesMap);

const UpdatePositionFields = Object.keys(UpdatePositionTypesMap);

// export const fromReadPositionToUpdatePosition = (position: Partial<ReadPositionResult>): Partial<UpdatePositionRequest> => {
//   return {};
// };

// export const fromReadPositionToCreatePosition = (position: Partial<ReadPositionResult>): Partial<CreatePositionRequest> => {
//   return {}
// };

export const generateCreateV2PositionMutation = (req: CreateV2PositionRequest): string => {
  const filtered = Object.keys(req)
  .filter(r => CreatePositionFields.includes(r));

  const mutation = createMutation(
    filtered, 
    PositionMutations.createV2Position, 
    CreatePositionTypesMap,
    ['message', 'id', 'title', 'description']);

  return mutation;
}

export const generateUpdateV2PositionMutation = (req: UpdateV2PositionRequest): string => {
  const filtered = Object.keys(req)
  .filter(r => UpdatePositionFields.includes(r));

  const mutation = createMutation(
    filtered, 
    PositionMutations.updateV2Position, 
    UpdatePositionTypesMap,
    ['message']);

  return mutation;
}

