// Components
import CustomCircularProgress from '../../components/ProgressCircle';

// Styles
import './style.css';

// Interfaces
interface IQualificationsAndSkillsOverall {
    qualificationScore: number;
    skillsScore: number
}

const QualificationsAndSkillsOverall: React.FunctionComponent <IQualificationsAndSkillsOverall> = (props): JSX.Element => {
    const { qualificationScore, skillsScore } = props;
    return (
        <div className='review-qualification-skills-overall'>
            {/* Skills Overall */}
            { skillsScore ? 
            <div className='review-qualification-skills-overall-circle'>
                <CustomCircularProgress 
                value={skillsScore === -1 || !skillsScore ? "N/A" : (skillsScore * 100).toFixed(0) }/>
                <h3>Skills</h3>
                <span className='review-qualification-skills-span'>OVERALL SCORE</span>
            </div>
            : null}
            {/* Qualifications Overall */}
            { qualificationScore ? 
            <div className='review-qualification-skills-overall-circle'>
                
                <CustomCircularProgress
                value={qualificationScore === -1 || !qualificationScore ? "N/A" : (qualificationScore * 100).toFixed(0)}/>
                <h3>Qualifications</h3>
                <span className='review-qualification-skills-span'>OVERALL SCORE</span>
            </div> 
            : null}
        </div>
    )
}

export default QualificationsAndSkillsOverall;