import downloadIcon from "../../icons/download.svg";
import shareIcon from "../../icons/share.svg";
import { format } from "date-fns";

import './style.css';


const ReportHeader: React.FunctionComponent<{
    positionName?: string;
    reccommendation?: string;
    cvUrl?: string;
    shareUrl?: string;
    submittedAt?: number;
    onClickShareButton?: () => any
}> = ({
    positionName,
    reccommendation,
    cvUrl,
    shareUrl,
    submittedAt,
    onClickShareButton
}): JSX.Element => {
    return (
        <div className="candidate-report-header">
            <div className='report-header-position-recomadation'>
                {/* {reccommendation && positionName &&
                <span>{'>'}</span>} */}
                {submittedAt &&
                <span>
                    {`Submitted on ${format(new Date(submittedAt), "LLL d, yyyy")}`}
                </span>}
                {positionName &&
                <>
                <span> 
                    For                    
                </span>
                <span className='report-header-position-name'>
                    {`${positionName}`}
                </span>
                </>}
                {reccommendation &&
                <span> 
                    {`by ${reccommendation}`}
                </span>}
            </div>
            <div className='report-header-resources'>
                {cvUrl &&
                <div className='report-header-resources-cv'>
                    <img src={downloadIcon} alt={"download-cv"}/>
                    <a href={cvUrl.split("?")[0]} target="_blank" rel="noopener noreferrer">
                        {'Download CV'}
                    </a>
                </div>}
                {shareUrl &&
                <div 
                className='report-header-resources-share'
                onClick={onClickShareButton}>
                    <img src={shareIcon} alt={"share-report"}/>
                    <span>{'Share'}</span>
                </div>}
            </div>
        </div>
    )
}

export default ReportHeader;