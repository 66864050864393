import React from 'react'
import ReactDOM from 'react-dom'

// Sentry
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import env from "react-dotenv";


// Auth
import { AuthProvider } from "@auth"

// App
import App from '@/App'

// Sass
import "@sass/main.scss"
import "@sass/colors.scss"
// Report
import reportWebVitals from './reportWebVitals'

if (!/\/\/localhost:/i.test(env?.REST_API)) {
  Sentry.init({
    dsn: "https://9f731f48688e499e9136b9722e270e5c@o525070.ingest.sentry.io/5970840",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
