import { ComProfile, comProfileToLabel } from "../../data/report.utils";

import Message from "@/components/Message/Message";
import arrowIcon from '../../icons/arrow.svg';
import { ComprofileIcons, ReportHeader } from "../../components";

import "./style.css";

const CommunicationStyleDescription: React.FunctionComponent<{
  qualities?: any;
  values?: any;
  needs?: any;
  strengths?: any;
  comProfile?: ComProfile;
}> = ({ qualities, values, needs, strengths,
  // comProfile 
}): JSX.Element => {

  return (
  <div className="talent-report-communication-style-workstyle-description">
  <div className="talent-report-communication-style-workstyle-description-singles-block">
      {qualities && 
      <div className="talent-report-communication-style-workstyle-description-single">
          <img src={arrowIcon}/>
          <div className="">
              <p>It means that he/she can become <span>a good</span></p>
              <div className="talent-report-communication-style-workstyle-description-single-line-v2">
                  {Object.keys(qualities).map((key, index) => (
                      <div key={key+index}>
                          {qualities[key]}
                      </div>
                  ))} for your team.
              </div>
          </div>
      </div>}
      {values && 
      <div className="talent-report-communication-style-workstyle-description-single">
          <img src={arrowIcon}/>
          <div>
              <p>This Talent <span>brings values</span> like</p>
              <div className="talent-report-communication-style-workstyle-description-single-line-v2">
                  {Object.keys(values).map((key, index) => (
                      <div key={key+index}>
                      {values[key]}
                      </div>
                  ))} for your team.
              </div>
          </div>
      </div>}
      {needs && 
      <div className="talent-report-communication-style-workstyle-description-single">
          <img src={arrowIcon}/>
          <div>
              <p>while <span>seeking</span></p>
              <div className="talent-report-communication-style-workstyle-description-single-line-v2">
                  {Object.keys(needs).map((key, index) => (
                      <div key={key+index}>
                      {needs[key]}
                      </div>
                  ))}
              </div>
          </div>
      </div>}
      {strengths && 
      <div className="talent-report-communication-style-workstyle-description-single">
          <img src={arrowIcon}/>
          <div>
              <p>in order to show the following work <span>strengths</span></p>
              <div className="talent-report-communication-style-workstyle-description-single-line-v2">
                  {Object.keys(strengths).map((key, index) => (
                      <div key={key+index}>
                      {strengths[key]}
                      </div>
                  ))}
              </div>
          </div>
      </div>}
  </div>
  {/* {comProfile &&
  <div className="talent-report-communication-style-workstyle-description-main">
      <ComprofileShape talentComProfile={comProfile}/>
  </div>} */}
</div>
)}

const CommunicationStyleSection: React.FunctionComponent<{
  firstName?: string;
  candidateComprofile?: {
    [key: string]: any;
  };
}> = ({ firstName, candidateComprofile }): JSX.Element => {
  
  if(!firstName || !candidateComprofile || !candidateComprofile.profile) return <div></div>;

  return (
    <div className="communication-style-v2">
      <ReportHeader>
      candidate workstyle
      </ReportHeader>
      {/* <div className="talent-report-communication-style-headers">
        <h2>
          {`${firstName}'s Communication Style`}
        </h2>
        <span style={{ fontSize: "12px", color: "#A1A1A1" }}>
          WHAT CAN YOU LEARN?
        </span>
      </div> */}
        <div className="talent-report-communication-style-workstyle">
          <h3>
            {`${firstName} belongs to the `}
            <span>work style of</span>
          </h3>
          <div className="talent-report-communication-style-icon">
            <div className="talent-report-communication-style-icon-image">
              <ComprofileIcons
                comProfile={candidateComprofile?.profile}
                color={"#F8F3EE"}
              />
            </div>
            <span>
              {comProfileToLabel?.[candidateComprofile?.profile]?.toUpperCase()}
            </span>
          </div>
          <a
            className="talent-report-communication-style-icon-read-more"
            href={window.location.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more
          </a>
        </div>

      <CommunicationStyleDescription
        comProfile={candidateComprofile.profile}
        qualities={candidateComprofile.qualities}
        strengths={candidateComprofile.strengths}
        values={candidateComprofile.values}
        needs={candidateComprofile.needs}
      />

      <Message>
        <h3 className="talent-report-ask-yourself-message no-print">
          <span>Ask yourself,</span> can me and my team provide this?
        </h3>
      </Message>
    </div>
  );
};

export default CommunicationStyleSection;
