// React
import { useState, useEffect, useCallback } from "react"

// Routes
import { Routes } from "@routes"

// Layouts
import { Loading } from "@layouts"

// Auth
import { useAuth, IAuth } from "@auth"

// App Context
import AppContext, { IAppContext } from "@context/AppContext"

// Interfaces
import { ICompany as ICompanyRecord } from "@auth/interfaces"

// Toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './assets/styles/toastify.css'

// Intercom
import Intercom from "react-intercom"
import { ICompanyInfo } from "./models"
import { useUserCookieMonitor } from "./auth/useUserCookieMonitor"

//google tag manager
import TagManager from 'react-gtm-module'
import Localizer from "lnrd-locales/room"

const initialURL = window ? new URL(window.location.href) : null;

const getLocale = () => {
  if (!initialURL) {
    return "en";
  }
  try {
    return initialURL.searchParams.get("locale") || window.navigator?.language || "en"
  }
  catch (e) {
    console.error(`Error getting locale ${e}`);
    return "en";
  }
}

const isLocal = (window?.location?.hostname === "localhost");


// Main App Component
const App: React.FunctionComponent = (): React.ReactElement => {
  const auth = useAuth()
  // Auth
  const {
    isLoading,
    provider,
    setCompany,
    company,
    user
  }: IAuth = auth

  // Global App States
  const [companiesList, setCompaniesList] = useState<ICompanyInfo[]>([])

  const [locale, setLocale] = useState(getLocale())

  const tr = useCallback(<T extends {}>(arg: T, keys?: string | string[]) => {
    if (typeof arg === "string") {
      return Localizer.localize({ key: arg, locale }) as unknown as T
    }
    else if (keys?.length) {
      return Localizer.localizeObject<T>({ data: arg, locale, keys: keys || '*'}) as T
    }
    return arg;
  }, [locale])

  useUserCookieMonitor(auth)

  // Global App Context
  const [appCtx, setAppCtx] = useState<IAppContext>({ companiesList, setCompaniesList, locale, setLocale, tr, localizer: Localizer })

  // Effects
  useEffect(() => {
    if (isLocal) {
      return;
    }
    const tagManagerArgs = {
      gtmId: 'GTM-M6K74HR'
    }

    TagManager.initialize(tagManagerArgs)

  }, [])

  useEffect(() => {
    // Find user company name from companies list (global app context)
    const currentCompany: ICompanyInfo = companiesList.find((c: ICompanyInfo) => c.id === company!.id)!

    // If are defined
    if (company && currentCompany) {
      // Then keep same company id but extend the company state with the name
      setCompany((company: ICompanyRecord | null): ICompanyRecord => ({
        ...company,
        id: company!.id, // doesn't compile otherwise
        name: currentCompany.name!,
        slug: currentCompany.slug!,
        emailDomain: currentCompany.emailDomain!
      }))
    }
  }, [companiesList])

  useEffect(() => {
    Localizer.setStubMode(initialURL?.searchParams.has("debug-locale"))
  }, []);

  useEffect(() => {
    setAppCtx({
      companiesList: appCtx.companiesList,
      setCompaniesList: appCtx.setCompaniesList,
      locale,
      setLocale,
      tr,
      localizer: Localizer
    })
  }, [locale])

  // Auth is loading|checking authentication
  if (isLoading && !provider) {
    return <Loading />
  }

  // After Auth0 loads|checks authentication, proceed with routes
  return (
    <AppContext.Provider value={appCtx}>
      <div className="app">
        <ToastContainer
          enableMultiContainer
          containerId="default"
          position='top-center'
          toastClassName="app-toast"
          draggablePercent={60}
          closeButton={false}
          hideProgressBar={true}
        />
        <ToastContainer
          enableMultiContainer
          containerId="secondary"
          toastClassName="app-toast"
          position='top-center'
          className={"custom no-print"} />
        <Routes />
        {
          !isLocal && <Intercom appID="he0phchh" {...user} />
        }
      </div>
    </AppContext.Provider>
  )
}

export default App
