import * as SurveyJSCreator from "survey-creator";
import $ from "jquery"
import * as ko from "knockout";
import { IUploadResult, MediaUploader } from "@/helpers/mediaUploader";
import { AnyObject } from "@/helpers/objectUtils";
// import { wrap } from 'module';
import "./ckeditor.scss";
import { graphQueries } from '@/helpers';
import { v4 as uuid } from 'uuid';

const CKE_CONFIG = {
	uiColor: '#ffffff',
	/* width: 400, */
	/*height: 100,*/
	contentsCss: 'ckeditor.css',
	startupFocus: true,
	resize_enabled: true,
	/* extraPlugins: "colorbutton", */
	/*extraPlugins: 'autogrow', */
	removePlugins: 'elementspath, exportpdf', // removes the bottom bar that displays the tags path
	enterMode: CKEDITOR.ENTER_BR,
	useComputedState: false,
	removeButtons: 'Anchor,Image,Table,HorizontalRule,Strike,Subscript,Superscript',
	// good place to get configuration info: 
	// https://ckeditor.com/latest/samples/toolbarconfigurator/index.html#basic
	toolbarGroups: [
		/* { name: 'document', groups: ['mode']}, /* /* good for debugging - can see all tags */
		{ name: 'basicstyles', groups: ['basicstyles'] },
		{ name: "links" },
		{ name: "paragraph", groups: ['list', 'align'] }
	]

}

export interface ISurveyEditorOptions {
	property: 
		{ name: string }
	propertyEditor: any;
	htmlElement?: HTMLElement;
	element?: HTMLElement;
	survey: any;
}

// Set the default target of links to "Open in a new window":
// reference: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_howtos_dialog_windows.html
CKEDITOR.on('dialogDefinition', (ev: CKEDITOR.eventInfo) => {
	// Take the dialog name and its definition from the event data.
	const dialogName = ev.data.name;
	const dialogDefinition = ev.data.definition;
	// Check if the definition is from the dialog window you are interested in (the "Link" dialog window).
	if (dialogName != 'link') {
		return;
	}
	// Getting the contents of the Target tab
	const informationTab = dialogDefinition.getContents('target');
	// Getting the contents of the dropdown field "Target" so we can set it
	const targetField = informationTab.get('linkTargetType');
	/* Now that we have the field, we just set the default to _blank
		 A good modification would be to check the value of the URL field
		 and if the field does not start with "mailto:" or a relative path,
		 then set the value to "_blank" */
	targetField['default'] = '_blank';
});

export interface ProcessedSurvey {
	data: AnyObject;
	processedCount: number;
	errors: string[]
}

interface ConvertBase64ToLinksReturnObject {
	data: any;
	processedCount: number;
	errors: string[];
}

interface IAttachToEditorOptions {
	element: HTMLElement;
	onBlur?: (elment: HTMLElement, html: string) => any;
	onChange?: (elment: HTMLElement, html: string) => any;
}

interface IEditorSurveyUtils {
	/**
	 * Try to upload survey image elements with inline data urls. If uploaded, changes the element's
	 * imageLink to the resulting url
	 * @param data 
	 * @param uploader 
	 */
	convertBase64ToLinks(data: AnyObject, uploader: MediaUploader): Promise<ProcessedSurvey>

	/**
	 * Attaches event handlers that take care of post processing the survey UI and adding the necessary
	 * Triggers for popping up inline editors
	 * @param surveyCreator 
	 */
	attachEditorToSurvey(surveyCreator: SurveyJSCreator.SurveyCreator): any;
	/**
	 * Finds an input element in the provided html element. Upon focus,
	 * the element is covered with an inline ckeditor. Data is synced between the editor and the
	 * underlying input
	 * in
	 * @param options 
	 * @returns 
	 */
	attachToItemEditor(options: IAttachToEditorOptions): any;

	/**
	 * Strips <p> tags from the input
	 * @param input 
	 */
	cleanHtmlString(input: string): string;

	/**
	 * Useful for converting ckeditor data back to item display data
	 * @param survey 
	 * @param options 
	 */
	doMarkdown(survey: any, options: any): any;

	/**
	* gets the text content of the clipboard
	* @returns promise that resolves into the text that was in the clipboard
	*/
	getClipboard(): Promise<string>;

	/**
   * Copy text to the clipboard
   * @param txt the text to copy to clipboard
   */
	copyTextToClipboard(txt: string): any;

	/**
   * Convert a panel object into text and copy it to the clipboard
   * @param panelObj the Panel's object
   */
	copyPanelToClipboard(panelObj: any): any;


	/**
	 * Copy a question object from a survey to the clipboard
	 * @param editor the survey editor object in which this was requested
	 * @param questionName the question's name
	 */
	copyQuestionToClipboard(question: any): any

	/**
   * Regenerate a Panel JSON's all names as UUID (to prevent duplicates on paste mainly)
   * @param panelJSON the original Panel's json
   * @returns New Panel json with regenerated names (as UUID)
   */
	regenerateAllPanelNames(panelJSON: any): any

	/**
	  * Helper function to convert an ENUM to array of it's elements value
	  * @param enm the enum object
	  * @returns array of the enum's values
	  */
	enumToTextArray(enm: any): string[];

	/**
   * Checks for duplicate names in a json.
   * for now- just console.error if there are duplicates.
   * later on- might consider to send a report to some end point.
   * @param json json to check duplicate names in
   */
	checkDuplicateNamesInJson(json: JSON): any
}

const cleanHtmlStringImpl = function cleanHtmlString(str: string): string {
	if (str && /<[^<]+>/.test(str)) {
		str = str.replace(/<\/?p\s*>/ig, "")
	}
	return str
}

const doMarkdownImpl = function doMarkdown(survey: any, options: any) {
	options.html = cleanHtmlStringImpl(options.html || options.text)
	options.text = ""
}

class EditorSurveyUtilsImpl implements IEditorSurveyUtils {

	public readonly cleanHtmlString = cleanHtmlStringImpl;
	public readonly doMarkdown = doMarkdownImpl;

	public attachToItemEditor(options: IAttachToEditorOptions) {
		const element = options.element,
			$parent = element && $(element.parentNode as HTMLElement)
		if (!$parent || !$parent.length) {
			return;
		}
		const $input = $parent.find("input[type='text'],input:not([type]),textarea");
		const input = $input[0] as HTMLElement;
		if (!input) {
			return
		}
		let rightClick = false
		const testRightClick = (event: MouseEvent) => {
			rightClick = event.button === 2
			setTimeout(() => rightClick = false, 10)
		}
		input.setAttribute("data-focus-attached", "1")
		input.addEventListener("mousedown", testRightClick, true)
		const $viewer = $parent.find(".sv-string-viewer");
		if ($viewer.length) {
			const v = $viewer[0] as HTMLElement
			const oldp = v.onpointerdown;
			if (oldp) {
				v.onpointerdown = (event: PointerEvent) => {
					// console.log("pointer down");
					if (event.button !== 2) {
						oldp.call(null as any, event);
					}
				}
			}
			// $viewer[0].addEventListener("mousedown", testRightClick, true)
		}
		input.addEventListener("focusin", () => {
			if (rightClick) {
				// console.log("Ignoring right click")
				rightClick = false
				return
			}
			const $txt = $input.siblings(".editor-placeholder")
			if ($txt.length) {
				console.warn("input already has a textarea placeholder")
				return
			}
			// const rect = (event.target as HTMLElement).getBoundingClientRect();
			// const $div = $(`<div contenteditable="true" class="editor-placeholder"
			// 	style="width: ${Math.max(rect.width, 1)}px; height: ${Math.max(rect.height, 100)}px;">${$input.val()}</div>`)
			const $div = $(`<div contenteditable="true" class="editor-placeholder">${$input.val()}</div>`)
			const prevDisplay = $input.css("display")
			$input.css("display", "none")
			$div.insertAfter(input);
			$div[0].style.height = "auto";
			// $div[0].style.width = "100%";
			//$div[0].style.width = "300px";
			//$div[0].style.maxWidth = "300px";
			// $div[0].style.wordBreak = "break-word";
			// $div[0].style.display = "block";
			//$div[0].style.backgroundColor = "red"; // working
			//$div[0].style.overflowWrap = "anywhere";
			//$div[0].style.textAlign = "center"; // working


			const onElementBlur = (event: Event) => {
				event.stopPropagation()
				event.stopImmediatePropagation();
				event.preventDefault()
				event.target?.removeEventListener("blur", onElementBlur)
			}
			input.addEventListener("blur", onElementBlur, true)
			const editor = CKEDITOR.inline($div[0] as any, CKE_CONFIG)
			//editor.once("focus", () => { void 0 })
			editor.once("destroy", (event: CKEDITOR.eventInfo) => {
				event.editor.removeAllListeners()
			})
			editor.once("blur", (event: CKEDITOR.eventInfo) => {
				const html = cleanHtmlStringImpl(event.editor.getData())
				// event.editor.destroy(false) // this caused errors in CKEditor focus out event
				const ckName = editor.name;
				setTimeout(function () {
					CKEDITOR.instances[ckName].destroy();
				}, 0)
				options.onBlur && options.onBlur(input, html)
				$div.remove()
				$input.css("display", prevDisplay)
			})
			editor.on("change", (event: CKEDITOR.eventInfo) => {
				if (options.onChange) {
					const html = cleanHtmlStringImpl(event.editor.getData())
					options.onChange(input, html)
				}
			});
			// make escape key trigger blur:
			editor.on("key", (event: CKEDITOR.eventInfo) => {
				const keyCode = event && event.data && event.data.keyCode;
				if (keyCode === 27) {
					// Escape key pressed
					event.editor.focusManager.blur(false);
				}
			})

			editor.focus()
		}, true)

	}

	public attachEditorToSurvey(surveyCreator: SurveyJSCreator.SurveyCreator) {

		(surveyCreator.onPropertyAfterRender as any).add((sender: unknown, options: ISurveyEditorOptions) => {
			const itemEditor: any = options.propertyEditor || {}
			const propName = options.property.name
			this.attachToItemEditor({
				element: options.htmlElement!,
				onChange: (input: HTMLElement, html: string) => {
					const newValue = cleanHtmlStringImpl(html)
					const oldValue = itemEditor.editingValue
					if (newValue === oldValue) {
						return
					}
					const modifiedOptions: any = {
						name: propName,
						newValue,
						oldValue,
						target: surveyCreator.survey,
						type: "PROPERTY_CHANGED"
					}
					itemEditor.editingValue = newValue
					surveyCreator.setModified(modifiedOptions);
				}
			})

		});

		(surveyCreator.onAdornerRendered as any)
			.add((sender: any, options: ISurveyEditorOptions) => {
				this.attachToItemEditor({
					element: options.htmlElement! || options.element!,
					onBlur: (element: HTMLElement, html: string) => {
						$(element).val(html)
						const ctx = ko.contextFor(element)
						const itemEditor = ctx && ctx.$component;
						itemEditor?.postEdit()
					},
					onChange: (input: HTMLElement, html: string) => {
						$(input).val(html)
						input.dispatchEvent(new InputEvent("input"))
					}
				})
			})
		surveyCreator
			.survey
			.onTextMarkdown
			.add(doMarkdownImpl);

		(surveyCreator
			.onDesignerSurveyCreated as any)
			.add((editor: any, options: ISurveyEditorOptions) => {
				options
					.survey
					.onTextMarkdown
					.add(doMarkdownImpl);
			});

	}

	public async convertBase64ToLinks(data: AnyObject, uploader: MediaUploader): Promise<ProcessedSurvey> {
		const returnObj: ConvertBase64ToLinksReturnObject = { data, processedCount: 0, errors: [] };
		if (!data) {
			return returnObj;
		}
		if (Array.isArray(data.pages)) {
			// this is a Survey, go over it's elements:
			for (const page of data.pages) {
				if (!page || !Array.isArray(page.elements)) {
					continue
				}
				for (const element of page.elements) {
					if (!element) {
						continue;
					}
					await this.processOneElement(element, uploader, returnObj);
				}
			}
		}
		return returnObj;
	}

	private async processOneElement(element: AnyObject, uploader: MediaUploader, returnObj: ConvertBase64ToLinksReturnObject) {
		const type = element.type;
		if (!type) {
			returnObj.errors.push(`No type error in element: ${element}`);
			return;
		}
		if (type === "image") {
			const result = await this.processOneImage(element, uploader)

			if (result.error) {
				returnObj.errors.push(result.error)
			}
			if (!result.url) {
				returnObj.errors.push('missing result.url while processing image upload');
				element.imageLink = null;
				return;
			}
			element.imageLink = result.url;
			if (result.isProcessed) {
				returnObj.processedCount++;
			}
			return;
		}
		else if (type === "imagepicker" && Array.isArray(element.choices)) {
			for (const choice of element.choices) {
				const result = await this.processOneImage(choice, uploader)

				if (result.error) {
					returnObj.errors.push(result.error)
				}
				if (!result.url) {
					returnObj.errors.push('missing result.url while processing imagepicker choice upload');
					if (choice.imageLink) {
						choice.imageLink = null;
					}
					continue;
				}
				choice.imageLink = result.url; // get the url ANYWAYS (also when errors occur) to prevent base64 stay in the JSON
				if (result.isProcessed) {
					returnObj.processedCount++;
				}
			}
		}
		else if (type === "panel" && Array.isArray(element.elements)) {
			for (const panelElement of element.elements) {
				await this.processOneElement(panelElement, uploader, returnObj); // recursive call to handle elements in panel
			}
		}
		else if (type === "file") {
			if (element.defaultValue && Array.isArray(element.defaultValue)) {
				for (const arrayElement of element.defaultValue) {
					const result = await this.processOneFile(arrayElement)
					if (result.error) {
						returnObj.errors.push(result.error)
					}
					if (!result.url) {
						returnObj.errors.push('missing result.url while processing file upload');
						arrayElement.content = null;
						continue;
					}
					arrayElement.content = result.url; // get the url ANYWAYS (also when errors occur) to prevent base64 stay in the JSON
					if (result.isProcessed) {
						returnObj.processedCount++;
					}
				}
			}
		}
	}

	/**
	* replace a data link with an image cdn link
	* @param element Guaranteed not null and type === "image"
	*/
	private async processOneImage(element: AnyObject, uploader: MediaUploader): Promise<IUploadResult> {
		if (! /^data:/.test(element.imageLink || "")) {
			return { url: element.imageLink, error: null, isProcessed: false }
		}
		let uploadResult = null;
		try {
			uploadResult = await uploader.upload({
				data: element.imageLink
			})
		}
		catch (e) {
			console.error('Error while uploading image to Cloudinary, ', e);
		}
		if (!uploadResult) {
			// prevent base64 from staying in the JSON:
			return {
				url: null, error: "failed to upload image to Cloudinary", isProcessed: false
			}
		}
		return uploadResult;
	}

	/**
	* replace (any!) data link with a cdn link
	* @param element Guaranteed not null and type === "image"
	*/
	private async processOneFile(element: AnyObject): Promise<IUploadResult> {
		if (! /^data:/.test(element.content || "")) {
			return { url: element.content, error: null, isProcessed: false }
		}
		// console.log('trying to upload file to s3: ', element)
		let result = null;
		try {
			result = await graphQueries.uploadFileToS3(element.name, element.content);
		} catch (e) {
			console.error('Error while uploading file to S3, ', e);
		}
		if (!result) {
			// prevent base64 from staying in the JSON:
			return {
				url: null, error: "failed to upload file to S3", isProcessed: false
			}
			// original code keeps the base64 when upload fails:
			// return {url: element.content, error: "failed to upload file to S3", isProcessed: false} 
		}
		return {
			url: result, error: null, isProcessed: true
		};
	}

	public async getClipboard(): Promise<string> {
		return await navigator.clipboard.readText();
	}
	public copyTextToClipboard(txt: string) {
		navigator.clipboard.writeText(txt);
	}

	public copyPanelToClipboard(panelObj: any) {
		const panelJson = panelObj.toJSON();
		panelJson.name = 'panel'; // add this forcefully, so can check if it is a panel in the paste action (we can store it in the name, because it will be overridden anyways)
		this.copyTextToClipboard(JSON.stringify(panelJson));
		console.log('panel copied! ', panelJson);
	}

	public copyQuestionToClipboard(question: any) {
		const questionType = question.getType();
		const questionJson = question.toJSON();
		questionJson.name = questionType; // the name is UUID anyways, so use it to transfer the type
		const questionString = JSON.stringify(questionJson);
		this.copyTextToClipboard(questionString);
		console.log('question copied! ', questionJson);
	}
	/**
   * Regenerate a Panel JSON's all names as UUID (to prevent duplicates on paste mainly)
   * @param panelJSON the original Panel's json
   * @returns New Panel json with regenerated names (as UUID)
   */
	public regenerateAllPanelNames(panelJSON: any) {
		const findAllNamesRegex = /"name":\s*"[^"]*"/gm;
		const panelText = JSON.stringify(panelJSON);
		const regeneratedNamesPanelText = panelText.replace(
			findAllNamesRegex,
			() => {
				return `"name":"${uuid()}"`;
			},
		);
		const regeneratedPanelJSON = JSON.parse(regeneratedNamesPanelText);
		return regeneratedPanelJSON;
	}

	public enumToTextArray(enm: any): string[] {
		const arr: string[] = Object.values(enm);
		return arr;
	}

	/**
   * Checks for duplicate names in a json.
   * for now- just console.error if there are duplicates.
   * later on- might consider to send a report to some end point.
   * @param json json to check duplicate names in
   */
	public checkDuplicateNamesInJson(json: JSON) {
		const findAllNamesRegex = /"name":\s*"[^"]*"/gm;
		const text = JSON.stringify(json);
		const array = Array.from(text.matchAll(findAllNamesRegex), (m) => m[0]);
		const findDuplicates = (arr: string[]) => {
			return arr.filter((item, index) => arr.indexOf(item) != index);
		};
		const duplicatesArray = findDuplicates(array);
		if (duplicatesArray.length > 0) {
			console.error('Found duplicate names! ', duplicatesArray);
		}
	}
}

export const EditorSurveyUtils: IEditorSurveyUtils = new EditorSurveyUtilsImpl();