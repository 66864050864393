import $ from "jquery"

export type AnyObject = { [key: string | number ]: any };
export interface IObjectUtils {
	mergeObjects(target: AnyObject, ...args: AnyObject[]): AnyObject;
	cloneObject<T>(target: T): T;
		/**
	 * safely extract a value from an object
	 * @param obj 
	 * @param path dot separated path, or array of path parts, e.g. "details.personal.id" or "details.names[5]"
	 * @param defaultValue 
	 */
	getValueFromObject(obj: any, path: string | string[], defaultValue?: any): any;
	/**
	 * safely extract a value from an object
	 * @param obj 
	 * @param keys array of keys to drill down into the provided object
	 * @param defaultValue 
	 */
	getValueByKeys(obj: any, keys: Array<string | number>, defaultValue?: any): any;
	getNumericValueFromObject(obj: any, path: string, defaultValue: number): any;

	/**
	 * Returns the first argument which is not undefined. Make sure you provide a reasonable
	 * fallback as the last argument. Note that null is a possible return value
	 * @param args 
	 */
	firstDefined<T>(...args: (T | undefined)[]): T

	/**
	 * Returns the first argument which is neither undefined nor null. Make sure you provide a reasonable
	 * fallback as the last argument
	 * @param args 
	 */
	firstDefinedNotNull<T>(...args: (T | null | undefined)[]): T
}

/**
 * Regex to convert [x] to x
 */
const INDEX_RE = /\[([^\]]+)\]/g

function splitKeys(keys: string | string[]): string[] {
	if (!keys || !keys.length) {
		return []
	}
	const expanded = (typeof keys === "string" ? keys : keys.join('.')).replace(INDEX_RE, ".$1")

	return expanded.split('.')
}

export class ObjectUtils implements IObjectUtils {

	public firstDefined<T>(...args: (T | undefined)[]): T {
		for (const value of args) {
			if (value !== undefined) {
				return value
			}
		}
		// the code should never get here, as a sensible implementation would provide a fallback at the end of the args list
		return undefined as any as T
	}

	public firstDefinedNotNull<T>(...args: (T | null | undefined)[]): T {
		for (const value of args) {
			if (value !== null && value !== undefined) {
				return value
			}
		}
		// the code should never get here, as a sensible implementation would provide a fallback at the end of the args list
		return undefined as any as T
	}

	public mergeObjects(target: object, ...args: object[]): object {
		target = target || {}
		return $.extend(true, target, ...args)
	}

	public cloneObject<T>(target: T): T {
		if (!target || typeof target !== "object") {
			return target
		}
		return $.extend(true, Array.isArray(target) ? [] : {}, target);
	}

	/**
	 * Safely retrieve a value from an object, using an array of keys as a drilldown map
	 * @param obj An object
	 * @param keys A string that may be dot separated
	 * @param separator Optional string to separate the path by
	 */
	public getValueFromObject(obj: AnyObject, path: string | string[], defaultValue: any = null): unknown {
		if (!obj) {
			return null;
		}
		const keys = splitKeys(path)
		return this.getValueByKeys(obj, keys, defaultValue)
	}

	/**
 * Safely retrieve a value from an object, using an array of keys as a drilldown map
 * @param obj An object
 * @param keys Array of keys
 */
	public getValueByKeys(obj: AnyObject, keys: Array<string | number>, defaultValue: unknown = null): unknown {
		let len: number;
		if (!obj || !keys || !(len = keys.length)) {
			return defaultValue
		}
		--len // iterate up to last component
		for (let i = 0; i < len; ++i) {
			obj = obj[keys[i]];
			if (!obj || typeof obj !== "object") {
				return defaultValue;
			}
		}
		const key = keys[len]
		return key in obj ? obj[key] : defaultValue
	}

	/**
	 * Safely retrieve a number from an object, using an array of keys as a drilldown map
	 * @param obj An object
	 * @param keys A string that may be dot separated
	 * @param separator Optional string to separate the path by
	 */
	public getNumericValueFromObject(obj: AnyObject, path: string, defaultValue: number): number {
		const rawVal = this.getValueFromObject(obj, path, defaultValue);
		if (typeof rawVal === "number") {
			return rawVal;
		}
		const nVal = Number(rawVal)
		return isNaN(nVal) ? defaultValue : nVal
	}

}