/* eslint no-eval: 0 */

import { useContext } from "react"

// Context
import { WizardFormContext } from "@components/WizardForm/context"

// Interfaces
import { IUseWizard } from "@components/WizardForm/interfaces"

// Local Interfaces
interface INodeValue {
  value: any
  key: number
  type: string
  title: string
  required?: boolean
}

/**
 *
 *  Use Wizard Custom Hook
 *  @description provides functionalities to reuse input data from user in another fields
 *
 */
export const useWizard = (): IUseWizard => {
  // DO NOT DELETE dataCollection. It's used for replace user input values in next questions!!!
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const { dataCollection } = useContext(WizardFormContext)
  /* eslint-enable  @typescript-eslint/no-unused-vars */

  return {
    getFrom: (from: string): string => {
      // Regex to detect if sentence contains value expand
      const regex = /@pages\[\d\]\.questions\[\d\]\[\d\]/

      // If no pattern to set data from another collected data detected => return input
      // IMPORTANT: Regex testing protects eval from harmful values ***
      if ( !regex.test(from) ) return from

      // Parts, contains both left and right parts of the expansion variable
      const fromParts: string[] = from.split(regex)
      // This is the expansion variable key
      const fromMatch: RegExpMatchArray = from.match(regex)!

      // Pattern detected
      try {
        // Get node value from dataCollection based on expansion variable
        const nodeValue: INodeValue | null = eval(
          fromMatch[0].replace("@pages", "dataCollection")
        )

        // Value from dataCollection node: depends of field type
        let value

        // Search value depending the field type
        switch ( nodeValue?.type ) {
          case "multiselect":
            value = nodeValue.value?.label || ""
            break


          default:
            value = nodeValue?.value || ""
        }

        // Returns the concatenation of left and right side of the sentence
        // with the value from the dataCollection node in the middle
        return (`${fromParts[0]}${value}${fromParts[1]}`)

      } catch (e: any) {
        // In error case returns the error as string
        return e.message
      }
    }
  }
}
