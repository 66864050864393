// Components
import { IFormPageCollection } from "@/models/formPage"
import { WizardForm } from "@components"

// Data from JSON | TODO: remove
import onboardingData from "@formsData/companyOnboarding.json"

const CompanyOnboarding: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className="min-h-screen min-w-screen flex justify-center items-center bg-gray-200">
      <WizardForm model={onboardingData as any as IFormPageCollection} 
      canFinish={() => false} 
      showTabs="none"
      className="w-4/5" />
    </div>
  )
}

export default CompanyOnboarding
