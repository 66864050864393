import { getCompanyInformationByID } from "@queries/createCompany"
import { buildUpdateCompanyQuery, getCompanies } from "@mutations/createCompany"

import { GraphQLClient } from "graphql-request"
import { createPosition, updatePosition } from "@mutations/createPosition";
import { buildGetAllCompaniesQuery } from "@queries/company"
import { getCompanyDefaultInfo } from "@queries/getCompanyDefaultInfo"
import { ICompanyInfo } from "@/models";
import { CompanyInfo } from "@/models/companyInfo";
import { requestEditor } from "@/graphql/mutations/requestEditor";
import { uploadFileToS3 } from "@/graphql/mutations/uploadFileToS3";
import { AnyObject } from "./objectUtils";
import { generateQueryKey, graphQueries, objectUtils } from "@/helpers";
import { getPositionByPositionId, getPositionById, getPositionSlug, getComprofileByAnswers } from "@/graphql/queries/positions";
import { getComProfile } from "@queries/review"
import { PositionStatus } from "@/models/position";
import { Analytics } from "./analytics";
import { changeUserCompanyQuery, createChildCompanyQuery } from "@/graphql/mutations/company";
import { ControlledPromise, IControlledPromise } from "./controlledPromise";

import Cookies from "js-cookie"

function getCompanyInfoArray(info: any): Array<any> {
	return (info && info.getCompanyInformation && info.getCompanyInformation.results)
		|| [];
}

interface IEditorValuesOptions {
	readonly url: string
	readonly valueType: string
	readonly inputValue: string
	readonly limit?: number
}

interface IPositionStatusOptions {
	readonly positionId: string;
	readonly status: PositionStatus
}

interface ICreateChildCompanyParams {
	companyName: string
	userId: string
	email: string,
	currentCompanyId: string
}

interface ICreateChildCompanyResponse {
	linkedCompanies: Array<any>
	companyId: string
	slug: string
}

interface IEditorValuesRecord {
	id: string
	name: string
}

interface ICreatePositionResponse {
	positionId: string
}

interface IQueryResult<T> {
	error?: string | null
	data: T | null
}

interface IPositionExtraDataResponse {
	data: AnyObject | null
}

interface IGetAllCompaniesData {
	key: string
	filter: string
	limit: number
}

interface IChangeUserCompanyResponse {
	response: string
}

type PendingQuery = {
	promise: IControlledPromise<any>
	query: string
	params: any
}

/**
 * An async function that receives a gql response and returns a (possibly different) response
 */
export type GraphQueryListenerCallback = (response: any) => Promise<any>
export interface IGraphQueries {
	/**
	 * Set the authorization token to be sent in every request header
	 * @param token 
	 */
	setAuthorizationToken(token: string): void;

	setServerURL(url: string): void;

	/**
	 * Add a listener that intercepts responses
	 * @param listener 
	 * @param callback 
	 */
	addResponseListener(listener: object, callback: GraphQueryListenerCallback): void;

	removeResponseListener(listener: object): void;

	/**
	 * returns label/value pairs for editor selection options. Swallws exceptions
	 * @param options
	 */
	getEditorValueList(options: IEditorValuesOptions): Promise<Array<IEditorValuesRecord>>
	/**
	 * Swallows exceptions
	 */
	sendCreatePosition(positionData: unknown): Promise<IQueryResult<ICreatePositionResponse>>
	/**
	 * Swallows exceptions
	 */
	sendUpdatePosition(positionData: unknown): Promise<boolean>

	/**
	 * Retrieve the info for the company. Swallows errors
	 * @param companyId
	 */
	getCompanyById(companyId: string): Promise<IQueryResult<ICompanyInfo>>
	getCompaniesByPrefix(prefix: string): Promise<any[]>
	sendUpdateCompany(companyId: string, data: Partial<ICompanyInfo>): Promise<boolean>
	/**
	 * Retrieves the positionFormJson data in the position with the specified id. Swallows exceptions
	 * @param positionId:string
	 */
	getPositionExtraData(positionId: string): Promise<IQueryResult<IPositionExtraDataResponse>>
	/**
	 * Swallaows exceptions, returns null on error
	 * @param companyName
	 */
	getCompanyDefaultInfo(companyName: string): Promise<IQueryResult<ICompanyInfo>>
	/**
	 * May throw an exception
	 * @param positionId
	 */
	getPosition(positionId: string): Promise<any>
	/**
	 * May throw an exception
	 * @param positionId
	 */
	getPositionSlug(positionId: string): Promise<any>

	getComProfile(answers: any): Promise<any>

	getComProfileReview(letter: string): Promise<any>

	uploadFileToS3(fileName: string, fileDataBase64: string): Promise<any>

	setPositionStatus(options: IPositionStatusOptions): Promise<boolean>

	getAllCompanies(data: IGetAllCompaniesData): Promise<IQueryResult<Partial<ICompanyInfo>[]>>

	createChildCompany(params: ICreateChildCompanyParams): Promise<IQueryResult<ICreateChildCompanyResponse>>

	changeUserCompany(userId: string, companyId: string): Promise<IQueryResult<IChangeUserCompanyResponse>>

	/**
	 * Sends a request through the properly configured gql client. If the client hasn't been
	 * configured yet, the returned promise is resolved only after successful configuration
	 * @param query The query string
	 * @param params Query parameters
	 * @param headers Optional headers (will be merged with the configured headers)
	 */
	sendRequest(query: string, params: AnyObject, headers?: AnyObject): Promise<any>

}

interface IGraphQueryListenerRecord {
	listener: object;
	callback: GraphQueryListenerCallback
}

/**
 * Utility class for fetching graphql data
 */
export class GraphQueries implements IGraphQueries {
	private readonly gqlClient: GraphQLClient;
	private _headers: { [key: string]: string } = {};
	private readonly _listeners: Array<IGraphQueryListenerRecord>

	constructor() {
		this.gqlClient = new GraphQLClient("", {
			credentials: 'include'
		})
		this._listeners = [];
	}

	public addResponseListener(listener: object, callback: GraphQueryListenerCallback): void {
		this._listeners.push({
			listener, callback
		})
	}

	public removeResponseListener(listener: object): void {
		this._listeners.slice().forEach((rec, index) => {
			if (rec.listener === listener) {
				this._listeners.splice(index, 1);
			}
		})
	}

	private _serverUrl = ""

	public get serverUrl(): string {
		return this._serverUrl
	}

	public setServerURL(url: string): void {
		url = url && url.trim();
		if (!url) {
			throw new Error("graphqueries: cannot use an empty server url");
		}
		this._serverUrl = url
		this.gqlClient.setEndpoint(url);
		this.flushPendingRequests()
	}

	public async changeUserCompany(userId: string, companyId: string): Promise<IQueryResult<any>> {
		try {
			const response = await this.sendRequest(
				changeUserCompanyQuery,
				{
					userId,
					companyId
				})
			const data = objectUtils.getValueFromObject(response, "changeUserCompany")
			return { data, error: data ? null : String(response.error || response.errors || "changeUserCompany: no data") }
		}
		catch (e) {
			return { error: String(e), data: null }
		}
	}

	public async createChildCompany(params: ICreateChildCompanyParams): Promise<IQueryResult<ICreateChildCompanyResponse>> {
		try {
			const response = await this.sendRequest(
				createChildCompanyQuery,
				params
			);
			const data = objectUtils.getValueFromObject(response, "createChildCompany");
			if (data) {
				return { data }
			}
			return { data: null, error: String(response.error || response.errors || "error creating child company") }
		}
		catch (e) {
			return { error: String(e), data: null }
		}
	}

	public async getAllCompanies(data: IGetAllCompaniesData): Promise<IQueryResult<Partial<ICompanyInfo>[]>> {
		const query = buildGetAllCompaniesQuery(data.key)
		try {
			const response = await this.sendRequest(query, { name: data.filter, limit: data.limit })
			const companies = objectUtils.getValueFromObject(response, "getAllCompanies.companiesName", null)
			if (Array.isArray(companies)) {
				return {
					data: companies as Array<Partial<ICompanyInfo>>
				}
			}
			const error = (response.errors && String(response.errors)) || `Failed to load companies ${data.filter}`
			return { data: null, error }

		}
		catch (e) {
			return { data: null, error: String(e) }
		}
	}


	public setAuthorizationToken(token: string): void {
		const key = "authorization";
		const value = token ? `Bearer ${token}` : ""
		this.gqlClient.setHeader(key, value);
		this._headers[key] = value;
	}

	public setInfoTokenHeader(token: string): void {
		const key = "infotoken";
		const value = token ? token : ""
		this.gqlClient.setHeader(key, value);
		this._headers[key] = value;
	}

	public async setPositionStatus(options: IPositionStatusOptions): Promise<boolean> {
		return graphQueries.sendUpdatePosition({
			"V2PositionPositionId": options.positionId,
			"V2PositionStatus": options.status
		})
	}

	public async getCompanyDefaultInfo(companyId: string): Promise<IQueryResult<ICompanyInfo>> {
		try {
			const response = await this.sendRequest(getCompanyDefaultInfo, { companyId })
			const data = objectUtils.getValueFromObject(response, "getDataServiceInfo", null)
			return { data, error: data ? undefined : `Info for company ${companyId} not found` }
		}
		catch (e) {
			console.error("getCompanyDefaultInfo: error$", e)
			return { data: null, error: String(e) }
		}
	}

	public async getPositionExtraData(positionId: string): Promise<IQueryResult<IPositionExtraDataResponse>> {
		const key = generateQueryKey(positionId)
		const query = getPositionByPositionId(key)
		try {
			const response = await this.sendRequest(query, { positionId })
			const data = objectUtils.getValueFromObject(response, "getV2Position[0].positionFormJson", null)
			return { error: data ? undefined : "not found", data }
		}
		catch (e) {
			console.error(`getPositionExtraData: error`, e)
			return { error: String(e), data: null }
		}
	}

	public async getComProfile(answers: JSON): Promise<any> {
		const res = await this.sendRequest(getComprofileByAnswers, {
			"answers": answers,
		})
		return res?.getComProfile?.perceivedProfile
	}

	public async getComProfileReview(profileLetter: string): Promise<any> {
		const res = await this.sendRequest(getComProfile, {
			"profileLetter": profileLetter,
		})
		return res?.getAllComProfiles
	}

	// public async uploadFileToS3(fileName: string, fileDataBase64: string): Promise<any> {
	// 	const res = await this.sendRequest(uploadFileToS3, {
	// 		"fileName": fileName,
	// 		"fileData": fileDataBase64,
	// 	})
	// 	return res?.data?.uploadFile
	// }

	public async uploadFileToS3(fileName: string, fileDataBase64: string): Promise<any> {
		// trim the header of the base64 string (for example, the “data:@file/pdf;base64," part):
		const base64result = fileDataBase64.split(',')[1];
		const res = await this.sendRequest(uploadFileToS3, {
			"fileName": fileName,
			"fileData": base64result,
		})
		return res?.genericUploadFile
	}

	public async getPosition(id: string): Promise<any> {
		// get position from lnrd-engine api
		//"722c599b-ab07-4958-9c89-7bafc0e663fe"
		const res = await this.sendRequest(getPositionById, {
			"positionId": id,
			"sizeOfPage": 1,
			"page": 0
		})
		const { getV2Position: result } = res
		const [first] = result
		return first
	}

	public async getPositionSlug(id: string): Promise<any> {
		// get position from lnrd-engine api
		//"722c599b-ab07-4958-9c89-7bafc0e663fe"
		const res = await this.sendRequest(getPositionSlug, {
			"positionId": id,
			"sizeOfPage": 1,
			"page": 0
		})
		const { getV2Position: result } = res
		const [first] = result
		return first
	}

	public async sendUpdateCompany(companyId: string, data: Partial<ICompanyInfo>): Promise<boolean> {
		const query = buildUpdateCompanyQuery(companyId, data);
		if (!query) {
			console.error(`Failed to build query adapter for company ${data && data.id}`)
			return false;
		}
		try {
			const { updateCompany } = await this.sendRequest(query.query, query.data)
			return updateCompany && updateCompany.message === "Company Updated"
		}
		catch (e) {
			console.error(`While updating company ${companyId}`, e)
			return false
		}
	}

	public async getEditorValueList(
		options: IEditorValuesOptions): Promise<Array<IEditorValuesRecord>> {
		try {
			const { requestToEditor } = await this.sendRequest(requestEditor, {
				"requestToEditorRequestType": "get",
				"requestToEditorRequestPath": `${options.url}?type=${options.valueType}&name=${options.inputValue}&size=${options.limit || 30}`,
				"requestToEditorParams": null
			})
			return (requestToEditor && requestToEditor.response && requestToEditor.response.result) || []
		}
		catch (e) {
			console.error(`While fetching options for value list`, e, options);
		}
		return [];

	}

	/**
	 * post the position data to the server
	 *
	 * swallows exceptions
	 * @param positionData
	 * @returns true if the response status is 200
	 */
	public async sendCreatePosition(positionData: AnyObject): Promise<IQueryResult<ICreatePositionResponse>> {
		try {
			const {
				V2PositionTitle,
				V2PositionCompanyId,
				V2PositionUserId
			} = positionData as any || {};

			const response = await this.sendRequest(createPosition, positionData)
			const positionId = objectUtils.getValueFromObject(response, "createV2Position.id")

			if (positionId) {
				Analytics.updatePositionsTable([{
					event: 'create',
					position_id: positionId,
					company_id: V2PositionCompanyId,
					position_name: V2PositionTitle,
					published_by: V2PositionUserId,
					update_by: V2PositionUserId,
					update_at: new Date()
				}])
			}

			return positionId ? { data: { positionId } } : { error: "position not created", data: null }

		}
		catch (e: any) {
			console.error(e)
			return { error: String(e), data: null };
		}
	}

	/**
	 * post the position data to the server
	 *
	 * swallows exceptions
	 * @param positionData
	 * @returns true if the response status is 200
	 */
	public async sendUpdatePosition(positionData: AnyObject): Promise<boolean> {
		try {
			// const {
			// 	V2PositionPositionId: positionId,
			// 	V2PositionCompanyId,
			// 	V2PositionTitle,
			// 	V2PositionUserId
			// } = positionData as any || {};

			const response = await this.sendRequest(updatePosition, positionData)
			const message = objectUtils.getValueFromObject(response, "updateV2Position.message")

			// if (positionId) {
			// 	Analytics.updatePositionsTable([{
			// 		event: 'update',
			// 		position_id: positionId,
			// 		company_id: V2PositionCompanyId,
			// 		position_name: V2PositionTitle,
			// 		published_by: V2PositionUserId,
			// 		update_by: V2PositionUserId,
			// 		update_at: new Date()
			// 	}])
			// }

			return Boolean(message && message === "PositionV2 Updated")
		}
		catch (e: any) {
			console.error(e)
			return false;
		}

	}


	/**
	 * Guaranteed not null, returns an object with a data field containing the company _source
	 *
	 * swallows exceptions
	 * @param companyId
	 * @returns
	 */
	public async getCompanyById(companyId: string): Promise<IQueryResult<ICompanyInfo>> {
		try {
			if (!companyId) {
				return {
					data: null,
					error: "Missing company id"
				};
			}
			const basicInfo: any = await this.sendRequest(getCompanyInformationByID, {
				getAllCompanyInformationCompanyId: companyId
			});
			const companyData = basicInfo?.getAllCompanyInformation || null;
			const ci: ICompanyInfo = companyData && new CompanyInfo(companyId).fromQueryObject(companyData);
			return {
				data: ci
			}
			//const companyName = basicInfo && basicInfo.getAllCompanyInformation && basicInfo.getAllCompanyInformation.name;
			//if (!companyName) {
			//	return ret;
			//}
			//const companies = await GraphQueries.getCompaniesByPrefix(companyName);
			//const first = companies[0];
			//ret.data = (first && first._source) || null;
		}
		catch (e) {
			console.error(e);
			return {
				error: String(e),
				data: null
			}
		}
	}

	/**
	 * Guaranteed not null
	 *
	 * swallows exceptions
	 * @param prefix prefix of the company names to return
	 * @returns an array of company information records, including _id and _source with all the properties
	 */
	public async getCompaniesByPrefix(prefix: string): Promise<any[]> {
		try {
			const response = await this.sendRequest(getCompanies, {
				getCompanyInformationCompanyName2: prefix
			});
			return getCompanyInfoArray(response);
		}
		catch (e) {
			console.error(e);
		}
		return [];
	}

	private _pendingRequests: Array<PendingQuery> = []
	public async sendRequest(query: string, params: AnyObject, headers?: AnyObject): Promise<any> {
		if (!this._serverUrl) {
			const p = new ControlledPromise<any>()
			this._pendingRequests.push({
				query,
				params,
				promise: p
			})
			return p.promise
		}
		//we need to add infoToken to the headers
		this.setInfoTokenHeader(Cookies.get("infoToken") || "");


		headers = headers && Object.assign({}, this._headers, headers)


		let response = await this.gqlClient.request(query, params, headers);
		if (this._listeners.length) {
			const l = this._listeners.slice();
			for (const rec of l) {
				response = await rec.callback(response)
			}
		}
		return response;
	}

	private flushPendingRequests() {
		if (!this._pendingRequests.length) {
			return
		}
		const reqs = this._pendingRequests.slice();
		this._pendingRequests.length = 0
		reqs.forEach(async (rec) => {
			try {
				const response = await this.sendRequest(rec.query, rec.params)
				rec.promise.resolve(response)
			}
			catch (e) {
				rec.promise.reject(e)
			}
		})
	}
}