import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from "@mui/material";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}/>
))(({style}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: "#000",
        border: "1px solid #DFE0EB",
        borderRadius: "12px",
        fontFamily: "Mulish",
        fontWeight: "200",
        ...style
    },
    "& .MuiTooltip-popper": {
        zIndex: "999999 !important"
    }
}));