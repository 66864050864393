// Create a Position

import { gql } from "graphql-request"



const POSITION_FIELD_TYPES = `
$V2PositionDescription: String,
$V2PositionStatus: String,
$V2PositionImage: String,
$V2PositionSkills: [Skill],
$V2PositionEducation: [Education],
$V2PositionCultureAndValue: [CultureAndValue],
$V2PositionDayInALife: [DayInALife],
$V2PositionPositionFormJson: JSON,
$V2PositionMetadata: JSON,
$V2PositionRolesAndResponsibilities: [String],
$V2AtsEmail: String,
$V2PositionEligibilities: [String],
$V2Recipients: [String],
$V2IsManager: Boolean,
$V2MatchingOtherTitles: [String]`

const POSITION_FIELD_VALUES = `
companyId: $V2PositionCompanyId,
title: $V2PositionTitle,
positionImage: $V2PositionImage,
department: $V2PositionDepartment,
description: $V2PositionDescription,
status: $V2PositionStatus,
skills: $V2PositionSkills,
leaders: $V2PositionLeaders,
eligibilities: $V2PositionEligibilities,
education: $V2PositionEducation,
cultureAndValue: $V2PositionCultureAndValue,
recipients: $V2Recipients,
atsEmail: $V2AtsEmail,
dayInALife: $V2PositionDayInALife,
rolesAndResponsibilities: $V2PositionRolesAndResponsibilities,
positionFormJson: $V2PositionPositionFormJson,
metadata: $V2PositionMetadata,
isManager: $V2IsManager,
matchingOtherTitles: $V2MatchingOtherTitles`

export const createPosition = `
  mutation CreateV2PositionMutation(
    $V2PositionUserId: UUID!,
	$V2PositionCompanyId: UUID!,
	$V2PositionDepartment: String!,
	$V2PositionLeaders: [Leader],
	$V2PositionTitle: String!,
		${POSITION_FIELD_TYPES}
  ) {
    createV2Position(
    userId: $V2PositionUserId,
	${POSITION_FIELD_VALUES}
    ) {
      id,
      message
    }
  }
`

// Update a Position
export const updatePosition = `
  mutation UpdateV2PositionMutation(
    $V2PositionPositionId: UUID!,
	$V2PositionLeaders: [JSON],
	$V2PositionDepartment: String,
	$V2PositionTitle: String,
	$V2PositionCompanyId: UUID,
    ${POSITION_FIELD_TYPES}
  ) {
    updateV2Position(
      positionId: $V2PositionPositionId,
${POSITION_FIELD_VALUES}
    ) {
      message
    }
  }
`

export const submitPosition = gql`
  mutation SubmitV2Position($v2PositionId: UUID!) {
    submitV2Position(v2PositionId: $v2PositionId) {
      response
  }
}`