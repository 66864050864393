// Icons
import downloadIcon from "../../icons/download.svg";
import phoneIcon from "../../icons/phone.svg";
import mailIcon from "../../icons/mail.svg";
import linkedinIcon from "../../icons/linkedin.svg";
import linkIcon from "../../icons/link.svg";

// Styles
import './style.css';

interface IReportHeader {
    candidatePersonalData: {
        phoneNumber: string,
        email: string,
        referrer?: string,
        linkedinUrl?: string | null,
        fullName: string,
    },
    refferrerName: string | null,
    cvLink: string,
    onReportPdfLinkClicked: () => void;
    // onDownloadCvClicked: () => void
}

const ReportHeader: React.FunctionComponent<IReportHeader> = (props): JSX.Element => {
    const {candidatePersonalData, refferrerName, cvLink, onReportPdfLinkClicked} = props;

    return (
        <div className="report-header">
            <div className="report-header-cv-download">
                { cvLink?.length > 0 ?
                <>
                    <img src={downloadIcon}/>
                    <a style={{color: "#000000"}} href={cvLink.split("?")[0]} target="_blank" rel="noopener noreferrer">
                    <span
                    // onClick={onDownloadCvClicked}
                    >Download CV</span> 
                    </a>
                </>
                : null} 
            </div>
            <div className="report-header-info-link-section">
                <div>
                    
                    {candidatePersonalData.phoneNumber && candidatePersonalData.phoneNumber.length > 9 ? 
                    <div className="report-header-personal-info"> 
                        <div></div>
                        <img src={phoneIcon}/>
                        <span>
                            {candidatePersonalData?.phoneNumber}
                        </span>
                    </div> : null}

                    {candidatePersonalData.email ? 
                    <div className="report-header-personal-info">
                        <div></div>
                        <img src={mailIcon}/>
                        <span>{candidatePersonalData?.email}</span>
                    </div> : null}

                    {candidatePersonalData.linkedinUrl && candidatePersonalData.linkedinUrl.length > 1 ? 
                    <div className="report-header-personal-info" >
                        <div></div>
                        <img src={linkedinIcon}/>
                        <a style={{cursor: "pointer", textDecoration: "none", color: "#262626", fontSize: "12px"}} target={"_blank"} href={candidatePersonalData.linkedinUrl}>
                            {candidatePersonalData?.fullName.trim().length > 0 ? candidatePersonalData?.fullName : "Linkedin"}
                        </a>
                    </div> : null}

                    {candidatePersonalData.referrer && refferrerName ? 
                    <div className="report-header-personal-info">
                        <div></div>
                        <span
                        style={{fontWeight: 600}}>Recommended by&nbsp;</span>
                        <span>
                            {refferrerName}
                        </span>
                    </div> : null}
                </div>
                <div className="no-print">
                    <a onClick={onReportPdfLinkClicked}>
                        <img
                        style={{
                            cursor: "pointer"
                        }} 
                        src={linkIcon}/>
                    </a>
                </div>
            </div>
        </div>
    )
};

export default ReportHeader;