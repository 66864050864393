import { Dialog } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { EditorApi } from "@/pages/AssessmentEditor/data/editor.api";
import env from "react-dotenv";
import { Player } from "@lottiefiles/react-lottie-player";
import plugAnimation from "@/assets/animations/plug-play.json";

import "./style.css";
import ComProfileSurvey from "@/components/ComProfileSurvey";
import AppContext from "@/context/AppContext";

const dialogStyle = {
  zIndex: 10000,
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.85)"
  },
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      minWidth: "usnet",
      width: "unset",
      maxWidth: "1200px",
      minHeight: "unset",
      maxHeight: "unset",
    },
  },
};

interface IQuizModal {
  open: boolean;
  onClose: () => void;
  iframeName: string;
  userId?: string;
  positionId?: string;
  buildingBlocks?: string[];
  startQuizOnOpen?: boolean;
}

const IframeQuizModal: React.FunctionComponent<IQuizModal> = ({
  open,
  onClose,
  iframeName,
  userId,
  positionId,
  buildingBlocks,
  startQuizOnOpen
}): JSX.Element => {
  const [quizUrl, setQuizUrl] = useState<string | null>(null);

  const [startQuiz, setStartQuiz] = useState<boolean>(false);

  const [openComProfileModal, setOpenComProfileModal] = useState<boolean>(false);

  const { localizer, locale, tr } = useContext(AppContext);

  const getQuizLink = async (
    userId?: string,
    positionId?: string,
    bbs?: string[]
  ) => {
    if (!userId || !positionId || !bbs) return;

    const fetchedBBs = await EditorApi.getBuildingBlocks();
    const positionBBs = fetchedBBs.filter((bb) => bbs.includes(bb?.id ?? ""));
    const bbTypes = positionBBs.map((bb) => bb?.id ?? "");
    const url = new URL(`${env.JOBSIMULATOR_ORIGIN}/dna`);
    url.searchParams.set("u_id", userId);
    url.searchParams.set("pos_id", positionId);
    url.searchParams.set("assess", bbTypes?.join(","));
    url.searchParams.set("context", "preview");

    return localizer.localizeURL(url.toString(), locale)
  };

  const openLinkInIframe = (link: string, iframeName: string) => {
    const aLink = document.createElement("a");
    aLink.href = link;
    aLink.target = iframeName;

    setStartQuiz(true);
    aLink.click();
  };

  const handleMessage = (event: any) => {
    if (event?.data?.message === 'close_modal') {
        // console.log('close_modal');
        onClose();
    }
    if(event?.data?.message === 'show_more') {
      onClose();
      setOpenComProfileModal(true);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!open) return;
    
    getQuizLink(userId, positionId, buildingBlocks)
      .then((link) => {
        if (!link) return;

        if(startQuizOnOpen) openLinkInIframe(link, iframeName);

        setQuizUrl(link);
      })
      .catch(console.error);
  }, [open, startQuiz]);

  return (
    <>
    <Dialog 
    onClose={(e, reason) => { reason === "backdropClick" ? onClose() : null }}
    open={open} 
    sx={dialogStyle}>
      {!startQuiz && !startQuizOnOpen && (
        <div className="hrm-quiz-container">
          <h1 className="hrm-quiz-header">{tr("Your Job Simulator is almost ready!")}</h1>
          <div className="hrm-quiz-sentence-container">
            <Player
              autoplay
              loop
              src={plugAnimation}
              className="hrm-quiz-plug-animation"
            />
            <p className="hrm-quiz-sentence">
              {tr("Take the Hiring DNA quiz to maximize prediction accuracy")}
            </p>
          </div>
          <button
            onClick={() => openLinkInIframe(quizUrl!, iframeName)}
            className="hrm-quiz-start-quiz-button"
          >
            {tr("Start the Quiz")}
          </button>
          <span onClick={() => onClose()} className="hrm-quiz-skip-sentence">
            {tr("I'll do it later, take me to the Simulation now")}
          </span>
        </div>
      )}

      <iframe
        name={iframeName}
        className="hrm-quiz-iframe"
        style={{ display: startQuiz ? "block" : "none" }}
      ></iframe>
    </Dialog>
    {openComProfileModal && !open &&
      <ComProfileSurvey openModal={setOpenComProfileModal.bind(this)}/>}
    </>
  );
};
export default IframeQuizModal;
