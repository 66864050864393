import { PositionClient } from "@/dal/position-client";
import { Analytics } from "@/helpers/analytics";
import { IPosition } from "@/interfaces/pages/positions";
import { diff } from "deep-object-diff";
import Cookies from "js-cookie";
import { isEmpty, throttle } from "lodash";
import { EditorApi } from "../AssessmentEditor/data/editor.api";
import { AssessmentStatus } from "../AssessmentEditor/data/editor.utils";

export const checkArrayEvent = (diff: {} | any, lastData: {} | any, entery: string): string | false => {
    if(diff[entery]?.length < lastData[entery]?.length){
        return `${entery} field - item remove`
    } 
    if(diff[entery]?.length > lastData[entery]?.length){
        return `${entery} field - item added`
    }
    return false
}

export const getEventByDataThatChanged = (lastData: {} | any, diff: {} | any): string | false => {
    const enteries = Object.keys(diff ?? {})
    
    if(enteries.length <= 0)
        return false
    
    for(let i=0;i<enteries.length;i++) {

        const entery = enteries[i]
        if(!['CompanyId', 'id'].includes(entery)) {
            if(Array.isArray(diff[entery]) && JSON.stringify(diff[entery]) !== JSON.stringify(lastData[entery])) {
                return checkArrayEvent(diff, lastData, entery);
            }
        }
    }
    return false
}

export const getScreenByDataThatChanged = (diff: {} | undefined | null): string | null => {  
    const fieldChangeToTab: any = {
        "select_a_position": ["title", "matchingTitle", "matchingOtherTitles"],
        "define_the_role": ["isManager", "matchingYearsOfExperience", "matchingYearsOfManagementExperience", "matchingDepartment", "Department", "matchingLocation", "location", "jobTypes"],
        "skills": ["matchingSkills", "skills"],
        "qualifications": ["matchingEducation", "matchingLanguages", "matchingVolunteering", "matchingHobbies", "qualifications"],
        "eligibilities": ["eligibilities", "matchingEligibilities"],
        "r&r": ["rolesAndResponsibilities"],
        "a_day_in_a_life": ["dayInALife"],
        "leaders": ["leaders"],
        "recipients": ["atsEmail", "recipients", "matchingRecipients"],
        "building_blocks": ["matchingBuildingBlocks"],
        "preview": ["templates"],
    };
    const enteries = Object.keys(diff ?? {});
    const tabs = Object.keys(fieldChangeToTab);
    let tab = null
    for(let i=0; i<tabs.length; i++) {
        fieldChangeToTab[tabs[i]].includes(enteries[0]) ? 
        tab = tabs[i] : null
    }
    return tab;
}

export const sendPositionUpdateAnalyticsReport = (args: {lastValidData: Partial<IPosition>, savedData: Partial<IPosition>, companyId: string, userId: string, source: string}): void => {
    const { lastValidData, savedData, companyId, userId, source } = args
    const event = getEventByDataThatChanged(lastValidData, savedData);
    const screen = getScreenByDataThatChanged(savedData);
    const flowType = Cookies.get('reportType') ?? "update"
    
    if(event) {
        Analytics.sendPositionWizardReportEvent(flowType, {
            position_id: lastValidData?.id, 
            user_id: userId,
            company_id: companyId,
            screen: screen ?? "unknown",
            event: event,
            source: source
        })
    }
}

export function prepareForSave(data: Partial<IPosition>): Partial<IPosition> {
    const result: Partial<IPosition> = { ...data };
    
    /**
     * Matching fields
     */
    if(!isEmpty(data?.matchingOtherTitles)) {
        result.matchingOtherTitles = data.matchingOtherTitles?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.matchingEducation)) {
        result.matchingEducation = data.matchingEducation?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.matchingLanguages)) {
        result.matchingLanguages = data.matchingLanguages?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.matchingVolunteering)) {
        result.matchingVolunteering = data.matchingVolunteering?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.matchingEligibilities)) {
        result.matchingEligibilities = data.matchingEligibilities?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.matchingHobbies)) {
        result.matchingHobbies = data.matchingHobbies?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.matchingSkills)) {
        result.matchingSkills = data.matchingSkills?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.atsEmail)) {
        result.atsEmail = result.atsEmail?.trim()
    }
    if(!isEmpty(data?.matchingRecipients)) {
        result.matchingRecipients = data.matchingRecipients?.filter((s: any) => !isEmpty(s));
        result.matchingRecipients = result.matchingRecipients?.map(r => r.trim())
    }
    if(!isEmpty(data?.matchingBuildingBlocks)) {
        result.matchingBuildingBlocks = data.matchingBuildingBlocks?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.matchingCvKeywords)) {
        result.matchingCvKeywords = data.matchingCvKeywords?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.matchingJobTypes)) {
        result.matchingJobTypes = data.matchingJobTypes?.filter((s: any) => !isEmpty(s));
    }

    /**
     * Discovery fields
     */
    if(!isEmpty(data?.skills)) {
        result.skills = data.skills?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.dayInALife)) {
        result.dayInALife = data.dayInALife?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.leaders)) {
        result.leaders = data.leaders?.filter((s: any) => (!isEmpty(s?.name) || !isEmpty(s?.title) || !isEmpty(s?.description) || !isEmpty(s?.image)));
    }
    if(!isEmpty(data?.eligibilities)) {
        result.eligibilities = data.eligibilities?.filter((s: any) => !isEmpty(s));
    }
    if(!isEmpty(data?.rolesAndResponsibilities)) {
        result.rolesAndResponsibilities = data.rolesAndResponsibilities?.filter((s: any) => !isEmpty(s));
    }    
    
    return result;
}

export const throttleUpdatePosition = throttle(({data, lastPosition, user, company, id, source}) => {
    const validData = prepareForSave(data);
    const validLastData = prepareForSave(lastPosition);
    const diffData = diff(validLastData, validData);
    const enteries = Object.keys(diffData ?? {})
    
    const needChangeAssessmentStatus = enteries.includes("matchingSkills") && validData.assessmentStatus !== AssessmentStatus.PENDING_REVIEW;
    if(needChangeAssessmentStatus){
        EditorApi.changeAssessmentStatus(id, AssessmentStatus.PENDING_REVIEW);
    }
    
    const dataToSave:any = {};
    for(let i=0;i<enteries.length;i++) {    
        dataToSave[enteries[i]] = (validData as any)[enteries[i]]
    }
    
    if(isEmpty(dataToSave)) return;

    PositionClient.update({ positionId: id, isUpdatingEditor: true, ...dataToSave })
    .then(res => {
        if(!res?.message) {
        return console.error('no data saved');
        }
        sendPositionUpdateAnalyticsReport({ 
            companyId: company.id, 
            userId: user.id, 
            lastValidData: validLastData, 
            savedData: dataToSave, 
            source: source 
        })
    })
    .catch(err => console.error(err));
}, 1000);