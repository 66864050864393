import { useContext, useState, useEffect, useCallback, MouseEventHandler } from "react";

import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Avatar from "@mui/material/Avatar";

// Router
import { useHistory } from "react-router-dom";

// Auth
import { useAuth, IAuth } from "@auth";

import ComProfileSurvey from "@/components/ComProfileSurvey";
import { Analytics } from "@/helpers/analytics";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import AppContext from "@/context/AppContext";


// import ReactHtmlParser from 'react-html-parser';
/**
 *
 *  Profile
 *  @description profile image with basic user actions
 *
 */


interface IMenuItem {
  label: string;
  action: MouseEventHandler;
  isAvailable: boolean;
}

type ItemOrDivider = IMenuItem | '-';

const Profile: React.FunctionComponent = (): JSX.Element => {
  // Auth
  // user can be null here!!
  const { user, logout, company, setChooseSiteModalVisible }: IAuth = useAuth();

  // References
  // const ref = useRef<HTMLDivElement>(null);

  const { locale, localizer } = useContext(AppContext);

  // History
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const toAction = (handler: MouseEventHandler): MouseEventHandler => {
    return () => {
      setMenuAnchor(null);
      (handler as any)();
    }
  }

  const [menuItems, setMenuItems] = useState<Array<ItemOrDivider>>([
    {
      label: "My Profile",
      action: toAction(() => history.push(`/myprofile/${user!.id}`)),
      isAvailable: true
    },
    {
      label: `Fill out your Communication style`,
      action: toAction(() => {
        const userSource = "userMenu";
        Cookies.set('reportComprofileSource', userSource)
        Cookies.set('reportComprofileSessionId', uuidv4())
        Analytics.sendComprofileWizardReportEvent({
          user_id: user?.id ?? null,
          screen: "1",
          event: "start",
          source: userSource
        })
        setOpenModal((openModal) => !openModal);
      }),
      isAvailable: false
    },
    {
      label: ``,
      action: toAction(() => setChooseSiteModalVisible(true)),
      isAvailable: false
    },

    {
      label: "Add Site",
      action: toAction(() => setChooseSiteModalVisible(true)),
      isAvailable: false
    },
    '-',
    {
      label: "Logout",
      action: toAction(() => logout()),
      isAvailable: true
    },
  ]);

  const [displayItems, setDisplayItems] = useState<Array<ItemOrDivider>>([]);

  const [avatarProps, setAvatarProps] = useState({
    props: {},
  })


  const onMenuToggle = useCallback((evt?: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(evt?.currentTarget || null);
  }, []);

  const onMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  // Effects

useEffect(() => {
  const items: Array<ItemOrDivider> = menuItems.map(item => {
    if (item === '-') {
      return item;
    }
    return localizer.localizeObject({ data: item, locale, keys: "label"})
  });
  setDisplayItems(items);
}, [menuItems, locale])

  useEffect(() => {
    if (user) {
      setAvatarProps({
        props: {
          alt: [user.firstName, user.lastName].filter(Boolean).join(' ')
            || user.email,
          src: user.profileImage || undefined
        }
      })
    }
  }, [user])



  useEffect(() => {
    const item = menuItems[1] as IMenuItem,
      avail = Boolean(user && !user.comProfile);
    if (avail !== item.isAvailable) {
      const items = menuItems.slice();
      (items[1] as IMenuItem).isAvailable = avail;
      setMenuItems(items);
    }
  }, [user, menuItems])


  useEffect(() => {
    const nCompanies = company?.linkedCompanies?.length || 0,
      avail = nCompanies > 1;
    // if (avail !== item.isAvailable) {
      const items = menuItems.slice();
      (items[2] as IMenuItem).label = `${company?.name} (${localizer.localize({ key: "Choose Site", locale})})`;
      (items[2] as IMenuItem).isAvailable = avail;
      setMenuItems(items);
    // }
  }, [user, company, locale])

  useEffect(() => {
    const item = menuItems[3] as IMenuItem,
     nCompanies = company?.linkedCompanies?.length || 0,
    avail = Boolean(
      (user?.role === "admin" ||
        user?.role === "lnrd_admin" ||
        user?.role === "company_admin") &&
      nCompanies > 1);
    if (avail !== item.isAvailable) {
      const items = menuItems.slice();
      (items[3] as IMenuItem).isAvailable = avail;
      setMenuItems(items);
    }
  }, [user, company])


  return (
    <>

      <IconButton size="medium" onClick={onMenuToggle}
        aria-controls={menuAnchor ? 'account-menu' : undefined}
        aria-haspopup={Boolean(menuAnchor)}
        aria-expanded={menuAnchor ? 'true' : undefined}>
        <Avatar component="div" {...avatarProps} />
      </IconButton>

      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={onMenuClose}>
        {
          displayItems.filter(item => item === "-" || item.isAvailable)
          .map((rec, ind) => (
            rec === '-' ? <Divider key={`mi-${ind}`} /> :
              <MenuItem key={`mi-${ind}`} onClick={rec.action}>{rec.label}</MenuItem>
          ))
        }
      </Menu>

      <div>{openModal && <ComProfileSurvey openModal={setOpenModal.bind(this)} />}</div>
    </>
  );
};

export default Profile;
