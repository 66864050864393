const Loading: React.FunctionComponent = (): JSX.Element => {
  return (
    <main className="loading">
      <div className="bg-white h-full rounded flex flex-col sm:flex-row gap-5 select-none w-4/6">
        <div className="flex flex-col flex-1 gap-5">
          <div className="flex flex-1 flex-col gap-3">
            <div className="bg-gray-200 w-full animate-pulse h-8 rounded-2xl mb-6"></div>
            <div className="bg-gray-200 w-full animate-pulse h-16 rounded-2xl mb-6"></div>

            <div className="bg-gray-200 w-full animate-pulse h-12 rounded-2xl" ></div>
            <div className="bg-gray-200 w-full animate-pulse h-12 rounded-2xl" ></div>

            <div className="bg-gray-200 w-full animate-pulse h-24 rounded-2xl"></div>

            {/* Profile Image */}
            <div className="flex">
              <div className="flex-initial bg-gray-200 animate-pulse w-56 h-24 rounded-2xl"></div>
              <div className="flex-initial ml-4 bg-gray-200 animate-pulse w-24 h-24 rounded-2xl"></div>
            </div>

            <div className="bg-gray-200 w-32 animate-pulse h-12 mt-6 rounded-2xl"></div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Loading
