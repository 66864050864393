import React, { useContext } from 'react'

/**
 *  LText Component
 *  @module components/LText
 */

// Utils
import AppContext from "@/context/AppContext";
import cx from "classnames"

interface ILTextProps {
  text: string;
  className?: string;
}


/**
 *
 *  LText
 *  @description Text span with localized content
 *
 */
const LText: React.FunctionComponent<ILTextProps> =
  ({ text, className = "" }: ILTextProps): JSX.Element => {

    const ctx = useContext(AppContext);
    return (
      <span
        className={cx(
          className
        )}>
        {ctx.tr(text)}
        </span>
      )
  }

export default LText
