// Router
import {
  Route,
  RouteProps,
  Redirect,
  useParams,
  useLocation
} from "react-router-dom"

// Middlewares
import { middlewares } from "@routes"

// Auth
import { useAuth, IAuth } from "@auth"

// Helpers
import { useQuery } from "@routes/helpers"

// Interfaces
import {
  IMiddlewareParams,
  IMiddlewareReturn,
  IQueryValues,
  IParamsValues
} from "@interfaces/routes"

import Cookies from "js-cookie"

// Component Interfaces
interface MiddlewareRouteProps extends RouteProps {
  readonly middlewares: (string | string[])[]
}

interface MiddleWareDetails {
    readonly name: string
    readonly parameters: string[]
}

function parseMiddleware(middleware: string | string[]): MiddleWareDetails {
    if (typeof middleware === "string") {
        return {
            name: middleware,
            parameters: []
        }
    }
    return {
        name: middleware[0],
        parameters: middleware.slice(1)
    }
}

const MiddlewareRoute: React.FunctionComponent<MiddlewareRouteProps> = (props): JSX.Element => {
  // URL Params
  const params: IParamsValues = useParams()

  // Query Params
  const query: IQueryValues = useQuery()

  // Location: hash - pathname
  const { hash, pathname }: { hash: string, pathname: string } = useLocation()

  // Auth
  const auth: IAuth = useAuth()

  // Result for each middleware is initialized
  let middlewareResult: IMiddlewareReturn = { pass: true }

  if ( props.middlewares.length ) {
    if (window.location.pathname === "/") {
      Cookies.set("returnTo", `${window.location.protocol}//${window.location.host}/positions/${window.location.search}`);
    }
    // Interates over each middleware key passed
    for ( const middleware of props.middlewares ) {
        const mwRecord = parseMiddleware(middleware)
      const middlewareParams: IMiddlewareParams = {
        auth,
        params,
        query,
        hash,
        pathname,
        data: mwRecord.parameters
      }

      // Middleware result
      middlewareResult = middlewares[mwRecord.name](middlewareParams)

      if ( !middlewareResult.pass ) {
        // TODO maybe add a log here?
        if ( middlewareResult.redirect instanceof Function )
          return middlewareResult.redirect()

        // Returns fallback Component case
        return <Redirect to="/404" />
      }
      else {
        if (props?.location?.state) {
          const s: any = props.location.state;
          if (s?.referrer) {
            Cookies.set("returnTo", s?.referrer);
          }
        }
      }
    }
  } 

  // If no middlewares then returns the Route by default
  return <Route {...props} />
}

export default MiddlewareRoute
