// Components
import CustomSlider from "../../components/Slider";

// Icons
import lightIcon from '../../icons/light.svg';

// Styles
import './style.css';

// Helpers
import ReactHtmlParser from 'react-html-parser';

//Interfaecs
interface IQualifications {
    qualifications: any
}

const Qualifications: React.FunctionComponent <IQualifications> = (props): JSX.Element => {
    const {qualifications} = props; 

    const renderQulification = (q: any, i: number) => {
        if(q?.question?.type !== "expression" && (q?.question?.payload?.title || q?.question?.title)) {
            return (
                <div className="report-qualifications-single" key={i+'qual'}>
                    {/* Qualification name */}
                    <div className="report-qualifications-single-name">
                        <img src={lightIcon}/>
                        <span>
                            {ReactHtmlParser(q?.question?.payload?.title)}
                        </span>
                    </div>
                    {/* Qualification slider and score */}
                    <div className="report-qualifications-single-slider"> 
                        <CustomSlider value={q.score * 100}/>
                    </div>
                    <span className="report-qualifications-single-score">
                        {q?.score !== -1 ? Math.floor(q?.score * 100) : "N/A"}
                    </span>
                </div>
            )
        }
    }
    
    return (
        <div className="report-qualifications">
            {qualifications[0]?.questions?.map((q: any, i: number) => renderQulification(q, i))}
        </div>
    )
}

export default Qualifications;