// React
import { useContext, useState } from "react"

// Drop zone
import { useDropzone } from 'react-dropzone'

// Libraries
import { Player } from "@lottiefiles/react-lottie-player"

// Logic
import useUploadImage, { IUploadImage } from "@components/WizardForm/components/UploadImage/useUploadImage"

// Utils
import cx from "classnames"

// Assets
import loading from "@assets/animations/loading.json"

// Icons
import { BsX } from "react-icons/bs"
import { UploadImageProps } from "@/interfaces/components/uploadImage"

import uploadingAnimation from "@assets/animations/uploading.gif"
import AppContext from "@/context/AppContext"

// Interfaces

// Drag and Drop Component
const UploadImage: React.FunctionComponent<UploadImageProps> =
  (props): JSX.Element => {
    // States
    const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true)

    // Hooks (logic)
    const { preview, uploadAndPreview, isUploading }: IUploadImage = useUploadImage({ ...props })

    const { tr } = useContext(AppContext);

    // Drag and Drop
    const {
      getRootProps,
      getInputProps
    } = useDropzone({
      accept: 'image/*, .svg',
      onDrop: (files: File[]) => uploadAndPreview(files)
    })

    return (
      <div className="upload-image-container flex gap-x-3 mt-6">
        {/* Lottie Placeholder */}
        { showPlaceholder && preview && (
          <Player
            className="h-32"
            autoplay
            loop
            src={loading}
            style={{ width: 'auto' }}
          />
        )}

        {/* Image Preview */}
        { preview && (
          <div className="w-auto relative">
            <button
              className="absolute top-1 right-1 text-red-500 hover:text-red-600 transition"
              onClick={() => uploadAndPreview([])}
            >
              <BsX size="32" />
            </button>

            <img
              alt="preview"
              className={cx("h-32 rounded", { "hidden": showPlaceholder })}
              src={ typeof preview === "string" ? preview : URL.createObjectURL(preview) }
              onLoad={() => setShowPlaceholder(false)}
            />

			{ isUploading && ( // TODO there should be a class for an overlay image
				<img src={uploadingAnimation} className="absolute top-0 left-0 w-9" title="uploading image..."/>
			)}

          </div>
        )}

        <section className='flex flex-col justify-center border-gray-300 p-3 border rounded text-center'>
          <div {...getRootProps({ className: 'dropzone flex justify-center items-center w-full h-full cursor-pointer' })}>
            <div className='block'>
              {/* Drop area */}
              <input {...getInputProps()} className='w-full h-full' />

              {/* Label */}
              <p className='text-gray-400 text-xl font-normal mb-0 h-full w-full'>
                { tr(preview ? "Change" : "Upload") }
              </p>
            </div>
          </div>
        </section>
      </div>
    )
  }

export default UploadImage
