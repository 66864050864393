import { useState } from "react";
import { LNRDLoading } from "@/components";
import { QuestionFeedback } from "../../components";
import ReactHtmlParser from 'react-html-parser';

import './style.css';
import React from "react";

const VideoQuestion: React.FunctionComponent<{ 
    videoUrl?: string,
    videoQuestion?: {
        id?: string,
        question?: string,
        question_category?: string,
        hrm_explanation?: string,
        candidate_guide?: string,
        hrm_guide?: string,
        default?: boolean,
        published?: boolean,
        hrm_indicator?: string,
    },
}> = ({
    videoQuestion,
    videoUrl,
}): JSX.Element => {
    
    const { question, hrm_explanation, hrm_indicator, 
        // hrm_guide 
    } = videoQuestion || {};

    const [rank, setRank] = useState<number>(0);
    const [videoLoading, setVideoLoading] = useState<boolean>(false);
    return (
        <div className="candidate-video-question-modal">
            {question &&
            <div className="candidate-video-question-modal-question">
                <span>You asked</span>
                <div className="candidate-video-question-modal-question-container">
                    <span>"</span>
                    <div>
                        {ReactHtmlParser(question)}
                    </div>
                    <span>"</span>
                </div>
            </div>}
            <div className="candidate-video-question-modal-video">
                <video 
                style={videoLoading ? {display: "none"} : {}}
                controls
                onLoadStart={() => setVideoLoading(true)}
                onLoadedData={() => setVideoLoading(false)}
                src={videoUrl}>
                    <source src={videoUrl} type="video/webm"/>
                </video>
                {videoLoading &&
                <div className="candidate-video-question-modal-video-loading">
                    <LNRDLoading/>
                </div>}
            </div>
            <div className="candidate-video-question-rank-section">
                <QuestionFeedback
                hideRankControl={true}
                questionRank={rank}
                setQuestionRank={setRank}
                title={"Make sure your rank is as  objective as can be!"}
                description={hrm_explanation ? ReactHtmlParser(hrm_explanation) : undefined}
                instructors={hrm_indicator ? ReactHtmlParser(hrm_indicator) : undefined}
                />
            </div>
        </div>
    )
};

export default VideoQuestion;