import { gql } from "graphql-request"

// Get all the data from table
export const getTableData = (name: string): string => gql`
  query ${name} (
    $genericSearchBoxTable: String!,
    $genericSearchBoxColumn: String,
    $genericSearchBoxValue: String,
    $genericSearchBoxSizeOfPage: Int,
    $genericSearchBoxPage: Int
  ) {
    genericSearchBox(
      table: $genericSearchBoxTable,
      column: $genericSearchBoxColumn,
      value: $genericSearchBoxValue,
      sizeOfPage: $genericSearchBoxSizeOfPage,
      page: $genericSearchBoxPage
    ) {
      response
    }
  }
`

// Same query to get and user
export const getUser = getTableData
