import WizardStepTemplate from "@/components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { FormControlLabel, Modal, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { IWizardStep } from "../../interfaces";
import { PositionTemplates } from "@/dal/position.interface";

import './style.css';

const discoveryTemplatesOptions = [
    { label: "Classic", value: PositionTemplates.Classic },
    { label: "The Unboxable Experience", value: PositionTemplates.TheUnboxableExperience }
]

const templateClasses = {
    [PositionTemplates.Classic]: "Classic",
    [PositionTemplates.TheUnboxableExperience]: "TheUnboxableExperience"
}

const muiFontSizeSx = {
    fontSize: "14px"
}

const TITLE = "Showcase your position with a beautiful discovery page";
const DESCRIPTION  = "Create an eye-catching discovery page for candidates so they can learn about you, your company and the position's DNA."

const PreviewStep: React.FunctionComponent<IWizardStep> = ({ formik }) => {
    const { values, setFieldValue } = formik;
    const { templates } = values;

    const [showImageInModal, setShowImageInModal] = useState<boolean>(false);

    const templateClass = templateClasses[templates ?? PositionTemplates.Classic];

    return (
        <WizardStepTemplate
        title={TITLE}
        description={DESCRIPTION}>
            <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // name="introType"
            value={String(templates)}
            style={{ display: "flex", flexDirection: "row" }}
            onChange={(e) => {
                setFieldValue("templates", discoveryTemplatesOptions[e.target.value as any].value);
            }}>
                {discoveryTemplatesOptions.map(({ label, value }) => (
                    <FormControlLabel
                    key={value}
                    value={String(value)}
                    control={<Radio style={{ color: "#000" }} />}
                    label={label}
                    sx={{ "& .MuiTypography-root": {...muiFontSizeSx} }} />

                ))}
            </RadioGroup>
            <div className="preview-image-section-container">
                <div>Preview</div>
                <div className={`${templateClass} preview-image-container`}>
                    {/* <button
                    className="preview-image-button"
                    onClick={() => setShowImageInModal(!showImageInModal)}
                    >Click to enlarge</button> */}
                </div>
            </div>
            <Modal open={showImageInModal} onBackdropClick={() => setShowImageInModal(!showImageInModal)}>
                <div 
                className={`${templateClass} preview-image-large-container`}></div>
            </Modal>
        </WizardStepTemplate>
    )
}

export default PreviewStep;