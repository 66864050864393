import WizardStepTemplate from "@/components/WizardComponents/WizardStepTemplate/WizardStepTemplate"
import { IWizardStep } from "../../interfaces";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { useContext, useState } from "react";
import { HtmlTooltip } from "@/components/WizardComponents/CustomTooltip/CustomTooltip";
import CKEditorCustom from "@/components/WizardComponents/CKEditorCustom/CKEditorCustom";

import './style.css';
import AppContext from "@/context/AppContext";

enum AllowedFileExtensions {
    DOCX = "docx",
    // DOC = "doc",
    TEXT = "txt",
}

const TITLE = "Do you have a job description?";
const DESCRIPTION = "We can use one you've previously created to automatically match candidates' work experience with your requirements. Don't worry about design at this stage, you'll be able to do that later";

const DescriptionStep: React.FunctionComponent<IWizardStep> = ({ formik }):JSX.Element => {
    const { values, setFieldValue } = formik;
    const { matchingDescription } = values;

    const [editor, setEditor] = useState<any>(null);

    const { tr } = useContext(AppContext);

    const onMatchingDescriptionChange = (newVal: string):void => { 

        editor?.setData?.(newVal);
        setFieldValue("matchingDescription", newVal);
    }


    const getFileExtension = (fileName: string): string | undefined | null => {
        return fileName.substring(fileName.lastIndexOf('.')+1, fileName.length) || fileName;
    }
    
    const getTextFromDOCX = (file: File, callback: (text: string) => any): void => {
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const content = e?.target?.result;
            const docx = new Docxtemplater(new PizZip(content));
            const text = docx.getFullText();
            callback?.(text)
        }
        reader.readAsBinaryString(file);
    }

    const getTextFromTXT= (file: File, callback: (text: string) => any): void => {
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const content = e?.target?.result;
            callback?.(content)
        }
        reader.readAsText(file);
    }
    
    const handleUploadFileClicked = () => {
        // Create a "ghost" input to trigger the file upload dialog
        const input: HTMLAnchorElement | any = document.createElement("input");
        input.type = "file";
        input.accept = Object.values(AllowedFileExtensions).map(ext => `.${ext}`).join(",");

        input.onchange = (e: any) => {
            const file: File = e.target?.files?.[0];
            const { name: fileName } = file;
            const fileExtension = getFileExtension(fileName);
            
            if (fileExtension === AllowedFileExtensions.DOCX) {
                getTextFromDOCX(file, onMatchingDescriptionChange);
            }
            else if (fileExtension === AllowedFileExtensions.TEXT) {
                getTextFromTXT(file, onMatchingDescriptionChange);
            }
        }
        
        input.click();
    };

    return (
        <WizardStepTemplate
        title={TITLE}
        description={DESCRIPTION}>
            <div className="description-section-container">
                <span className="description-section-upload-text">
                    {tr("Type, paste or")}
                    <HtmlTooltip 
                    title={`${tr("We support")} txt, docx`}
                    placement="top">
                        <span 
                        className="description-section-upload-text-upload"
                        onClick={handleUploadFileClicked}>{tr("upload")}</span> 
                    </HtmlTooltip>
                    &nbsp;{tr("a job description below")}
                </span>
                <div className="description-section-rtf-container">
                    <CKEditorCustom
                    initData={matchingDescription}
                    onLoaded={({ editor }) => setEditor(editor)}
                    onChange={(args) => onMatchingDescriptionChange(args.editor.getData())}
                    />
                </div>
            </div>
        </WizardStepTemplate>
    )
}

export default DescriptionStep;