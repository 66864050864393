import { useContext } from "react";
import { ComprofileIcons, ComprofileShape, ReportHeader } from "../../components";
import { ComProfile, comProfileToLabel } from "../../data/report.utils";
import AppContext from "@/context/AppContext";


const ComprofileCompare: React.FunctionComponent<{
    candidateComProfile?: ComProfile;
    hrmComProfile?: ComProfile;
    candidateFirstName?: string;
}> = ({ candidateComProfile, hrmComProfile, candidateFirstName }): JSX.Element => {

    const { tr } = useContext(AppContext);

    if(!candidateComProfile || !hrmComProfile || !candidateFirstName) return <div></div>;

return (
    <>
    <ReportHeader>compatibility Forecast</ReportHeader>
    <div
        style={{
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-evenly",
        }}
    >
        <div>
        <div style={{ width: "42px" }}>
            <ComprofileIcons comProfile={candidateComProfile} color={"#262626"} />
        </div>
        <div>
            
        </div>
        <h3 style={{ padding: "15px 0", fontWeight: 300 }}>
            <span style={{ fontWeight: "700" }}>{candidateFirstName},</span> The
            Talent
            <br />
            belongs to the workstyle of
        </h3>
        <div
            style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#5a7bff",
            padding: "12px 12px",
            width: "fit-content",
            borderRadius: "36px",
            color: "#F8F3EE",
            }}
        >
            <div style={{ width: "32px" }}>
            <ComprofileIcons
                comProfile={candidateComProfile}
                color={"#F8F3EE"}
            />
            </div>
            <span style={{ fontSize: "19px", fontWeight: 300 }}>
            {comProfileToLabel?.[candidateComProfile]?.toUpperCase()}
            </span>
        </div>
        </div>
        <div className="comprofile-main">
        <ComprofileShape
            talentComProfile={candidateComProfile}
            hrmComProfile={hrmComProfile}
        />
        </div>
        <div>
        <div style={{ width: "42px" }}>
            <ComprofileIcons comProfile={hrmComProfile} color={"#262626"} />
        </div>
        <h3 style={{ padding: "15px 0", fontWeight: 300 }}>
            <span style={{ fontWeight: "700" }}>{tr("You")},</span> {tr("The Hiring Manager")}
            <br />
            {tr("belongs to the workstyle of")}
        </h3>
        <div
            style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FF8E5A",
            padding: "12px 12px",
            width: "fit-content",
            borderRadius: "36px",
            color: "#F8F3EE",
            }}
        >
            <div style={{ width: "32px" }}>
            <ComprofileIcons comProfile={hrmComProfile} color={"#F8F3EE"} />
            </div>
            <span style={{ fontSize: "19px", fontWeight: 300 }}>
            {comProfileToLabel?.[hrmComProfile]?.toUpperCase()}
            </span>
        </div>
        </div>
    </div>
    <a
        className="combined-comprofile-for-print"
        href={window.location.href}
        target="_blank"
        rel="noopener noreferrer"
    >
        {tr("Read more")}
    </a>
    </>
);
};

export default ComprofileCompare;
