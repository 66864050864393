import React, { useEffect, useState } from "react";
import cx from "classnames";

import RightChevron from "@assets/icons/chevron_right.svg";
import DownChevron from "@assets/icons/chevron_down.svg";

import ComprofileIcon from "@/assets/icons/comprofileIcons";

// Auth
import { useAuth, IAuth } from "@auth";

import { graphQueries } from "@/helpers";

import _ from "lodash";

import { Button } from "@mui/material";

import "./styles.css";

const ComProfileResults: React.FunctionComponent<{ data: any, openModal: any, setCloseModal: any }> = ({
  data, openModal, setCloseModal
}): JSX.Element => {
  const [comProfileData, setComProfileData] = useState(null);

  const auth = useAuth();
  // Auth
  const { user }: IAuth = auth;

  async function fetchDataByLetter(letter: string) {
    const comprofiledata = await graphQueries.getComProfileReview(letter);
    setComProfileData(comprofiledata[0]);
  }

  useEffect(() => {
    if (user?.comProfile && !data) {
      //fetch relevent data
      fetchDataByLetter(user?.comProfile);
    }
    if (data) {
      setComProfileData(data);
    }
  }, []);

  function firstUpper(str: string) {
    return _.startCase(_.toLower(str));
  }

  function desktopView(data: any) {
    return (
      <div className={cx("pb-6 text-gray-800", "rv-padding")}>
        {/* Up Section */}
        <div className="communication-profile-up flex justify-center">
          <div className="work-style flex justify-center items-center">
            {/* Left text */}
            <div
              style={{ marginRight: "20px" }}
              className="text-md w-2/3 text-center"
            >
              You belong to the work style of
            </div>

            {/* Box */}
            <div className="w-1/2 flex justify-center">
              <div
                className={cx([
                  "work-style-box text-center flex items-center px-4 py-2 rounded-md h-12 bg-orange-rv",
                  "uppercase text-lg font-light",
                ])}
              >
                <div className="mr-1 relative -top-0.5">
                  <ComprofileIcon
                    name={data?.workStyle?.workStyleInput1?.toLowerCase()}
                  />
                </div>
                <p style={{ marginLeft: "3px" }}>
                  {data?.workStyle?.workStyleInput1}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Down Section */}
        <div className="border-t-2 border-dotted border-gray-400 pt-8 mt-8 w-2/3 flex mx-auto"></div>
        <div className="communication-profile-down">
          <div id="roles-section" className="text-center">
            {/* Upper text */}
            <div className="flex justify-center items-center">
              <div className="text-md mr-3">
                It means that you can become a good
              </div>

              {/* Skills */}

              <div className="text-md rounded-md flex justify-center items-center">
                {Object.values(data?.qualities)?.map(
                  (obj: any, key: number) => {
                    if (data?.qualities?.length === key + 1) {
                      //last one
                      return <>{firstUpper(obj)}</>;
                    } else {
                      return (
                        <>
                          <div
                            style={{
                              fontSize: "12px",
                              border: "0.6px solid #262626",
                              borderRadius: "16.17px",
                              marginRight: "10px",
                            }}
                          >
                            <p style={{ margin: "10px" }}>{firstUpper(obj)}</p>
                          </div>
                        </>
                      );
                    }
                  }
                )}
                <div>for your team</div>
              </div>
            </div>
            <div className="border-t-2 border-dotted border-gray-400 pt-8 mt-8 w-2/3 flex mx-auto"></div>

            {/* White boxes */}
            <div className="flex justify-between max-w-2xl mx-auto text-left">
              {/* Left box */}
              <div
                style={{ border: "1px solid #F3AD81", borderRadius: "5px" }}
                className="p-8 rounded w-1/2"
              >
                <p className="text-sm">You bring <b>values</b> like</p>

                <ol className="text-sm font-semibold mt-8 list-decimal text-left ml-6">
                  {Object.values(data?.values)?.map((obj: any, key: number) => {
                    return <li key={key}>{firstUpper(obj)}</li>;
                  })}
                </ol>
              </div>

              <div className="separator mx-4 flex items-center">
                <img src={RightChevron} alt="" style={{ height: 32 }} />
              </div>

              {/* Right box */}
              <div
                style={{ border: "1px solid #F3AD81", borderRadius: "5px" }}
                className="bg-white p-8 rounded w-1/2"
              >
                <p className="text-sm">While <b>seeking for</b></p>

                <ol className="text-sm font-semibold mt-8 list-decimal ml-6">
                  {Object.values(data?.needs)?.map((obj: any, key: number) => {
                    return <li key={key}>{firstUpper(obj)}</li>;
                  })}
                </ol>
              </div>
            </div>

            {/* Down separator */}
            <div
              className="separator text-right max-w-2xl px-8 mx-auto my-4"
              style={{ paddingRight: 150 }}
            >
              <img
                src={DownChevron}
                className="inline"
                alt=""
                style={{ height: 32 }}
              />
            </div>

            {/* Orange bottom box */}
            <div className="bg-orange-rv p-8 rounded flex mx-auto max-w-2xl text-left">
              {/* Left side */}
              <div>
                <p>In order to show the following work <b>Strengths</b></p>

                <ol className="text-sm font-semibold mt-8 list-decimal ml-6">
                  {Object.values(data?.strengths)?.map(
                    (obj: any, key: number) => {
                      return <li key={key}>{firstUpper(obj)}</li>;
                    }
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mx-auto mt-4">
          <Button
            color="primary"
            onClick={() => {openModal(false)
            setCloseModal(false)}}
            style={{
              color: "white",
              backgroundColor: "black",
              marginRight: "auto",
              marginLeft: "auto",
              marginBottom: "10px",
              textDecoration: "none",
            }}
          >
            Thanks, i'm done
          </Button>
        </div>
      </div>
    );
  }

  function mainView() {
    return (
      <>
        {desktopView(comProfileData)}
        {/* {mobileView(classes, props.data, history)} */}
      </>
    );
  }

  return <div>{comProfileData && mainView()}</div>;
};

export default ComProfileResults;
