
// Styles
import './style.css';

interface ICandidateNameAndTitle {
    fullName: string;
    jobTitle: string | null;
}

const CandidateNameAndTitle: React.FunctionComponent<ICandidateNameAndTitle> = (props): JSX.Element => {
    const { fullName, jobTitle } = props;

    return (
        <div className="candidate-name-and-title">
            <h1>
                <span>{fullName}</span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {jobTitle}
            </h1>
        </div>
    )
}

export default CandidateNameAndTitle;