/**
 *
 *  RC-Select LNRD theme
 *
 *  @module components/Select/styles
 *
 *  @author diegoulloao
 *
 */

// Select
import makeAnimated from "react-select/animated"

// Interfaces
import { Theme } from "react-select"

// Local Interfaces
interface IControlStyle {
  [index: string]: any
}

interface IStyles {
  option?: IControlStyle
  control?: IControlStyle
  placeholder?: IControlStyle
  multiValueLabel?: IControlStyle
  multiValueRemove?: IControlStyle
}

// Constants
const animatedComponents = makeAnimated()

// Theme
const theme = (theme: Theme): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#4F46E5",
    primary25: "#8AB1FF"
  }
})

// Styles
const styles = (customStyles?: IStyles): object => ({
  multiValueLabel: (styles: object) => ({
    ...styles,
    color: "#FFFFFF",
    backgroundColor: "#4F46E5",
    ...customStyles?.multiValueLabel
  }),

  placeholder: (styles: any) => ({
    ...styles,
    fontSize: 14,
    color: "#6B7280",
    ...customStyles?.placeholder
  }),

  control: (styles: any) => ({
    ...styles,
    fontSize: 14,
    ...customStyles?.control
  }),

  option: (styles: object) => ({
    ...styles,
    fontSize: 14,
    color: "#374151",
    backgroundColor: "#FFFFFF",
    ...customStyles?.option,

    ":hover": {
      color: "#FFFFFF",
      backgroundColor: "#4F46E5",
      ...(
        customStyles?.option
          ? customStyles.option[":hover"]
          : {}
      )
    },

    ":active": {
      color: "#FFFFFF",
      backgroundColor: "#4F46E5",
      ...(
        customStyles?.option
          ? customStyles.option[":active"]
          : {}
      )
    }
  }),

  multiValueRemove: (styles: object) => ({
    ...styles,
    color: "#FFFFFF",
    backgroundColor: "#4F46E5",
    ...customStyles?.multiValueRemove,

    ':hover': {
      color: "#FFFFFF",
      backgroundColor: "#4F46E5",
      ...(
        customStyles?.multiValueRemove
          ? customStyles.multiValueRemove[":hover"]
          : {}
      )
    }
  })
})

export default (customStyles?: IStyles): object => ({
  theme,
  styles: styles(customStyles),
  components: animatedComponents
})