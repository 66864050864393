
const allowedTypes = { string: 1, number: 1, "boolean": 1, "object": 1 }

export abstract class BaseFormModel<T> {
	constructor(props: Partial<T> | null | undefined) {
		this.merge(props);
	}
	public merge(props: Partial<T> | null | undefined): this {
		if (!props || (typeof props !== "object")) {
			return this
		}
		Object.entries(props).forEach(([ key, value]) => {
			const self: any = this as any;
			const myValue = self[key];
			const mytype = typeof self[key];
			if (myValue === null || mytype in allowedTypes) {
				self[key] = value && typeof value === "object" ? 
					JSON.parse(JSON.stringify(value))
					: value
			}
		});
		return this;
	}
}