import { Modal } from "@mui/material";

import './style.css';

interface IWarningModal {
    showModal?: boolean,
    onClose?: () => any,
    onApprove?: () => any,
    warningMessage?: string
}

const APPROVE_BTN_TEXT = "Continue";
const CANCEL_BTN_TEXT = "Cancel";

const WarningMdoal: React.FunctionComponent<IWarningModal> = ({ showModal, onClose, onApprove, warningMessage }): JSX.Element => {
    return(
        <Modal
        open={showModal ?? false}
        onClose={onClose}
        >
        <div className="wizard-warning-modal">
            <p className="wizard-warning-modal-message">
                {warningMessage}
            </p>
            <div className="wizard-warning-modal-buttons-container">
                <button 
                className="wizard-warning-modal-button wizard-warning-modal-button-approve"
                onClick={onApprove}>
                    {APPROVE_BTN_TEXT}
                </button>
                <button 
                className="wizard-warning-modal-button wizard-warning-modal-button-cancel"
                onClick={onClose}>
                    {CANCEL_BTN_TEXT}
                </button>
            </div>
        </div>
        </Modal>
    )
};

export default WarningMdoal;