// React
import { createContext } from "react"

// Intefaces
import { IPosition } from "@interfaces/pages/positions"

// Local Interface
export interface IPositionsPageContext {
  setShowModal: React.Dispatch<boolean>
  setPositionToDelete: React.Dispatch<IPosition>
  showModal: boolean
}

// Default values
const defaultContext: IPositionsPageContext = {
  setShowModal: () => null,
  setPositionToDelete: () => null,
  showModal: false
}

// Context
const PositionsPage = createContext<IPositionsPageContext>(defaultContext)

export default PositionsPage
