import React, { useContext, useEffect, useState } from "react";
import WizardStepTemplate from "@components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { IWizardStep, WizardStepsIds } from "../../interfaces";
import ReactHtmlParser from 'react-html-parser';
import { EditTemplateComponent } from "./components";
import { IPage } from "@/components/WizardComponents/ComponentsStepper/interfaces";
import { ConfirmationInput } from "@/components/WizardComponents/ConfirmationInput/ConfirmationInput";
import SuggestionCard from "@/components/WizardComponents/SuggestionCard/SuggestionCard";
import { generateRequirementsText } from "./helpers";
import CKEditorCustom from '@/components/WizardComponents/CKEditorCustom/CKEditorCustom';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import './style.css';
import { graphQueries } from "@/helpers";
import { proxyDataService } from "@/graphql/mutations/proxyDataService";
import AutocompleteCustom from "@/components/WizardComponents/AutocompleteNew/AutocompleteNew";
import { IconButton } from "@mui/material";
import AppContext from "@/context/AppContext";


const TITLE = "Review the requirements"
const DESCRIPTION = "Check this summary of your ideal candidate"

const PublishStep: React.FunctionComponent<IWizardStep & { setStep: (stepId: number)=> any, pages: IPage[] }> = ({ formik, setStep, pages }):JSX.Element => {

    const { values: position, setFieldValue } = formik || {};
    const { 
        matchingTitle, 
        matchingOtherTitles,
        matchingDescription,
        matchingSkills,
        matchingYearsOfExperience,
        matchingYearsOfManagementExperience,
        matchingJobTypes,
        matchingLanguages,
        matchingLocation,
        matchingHrms  } = position || {}; 

    const [
        // error 
        ,setError] = useState<any>(null)


    const [editor, setEditor] = useState<any>(null);
    
    const [isEditTitle, setIsEditTitle] = useState<boolean>(false);
    const [lastMatchingTitle, setLastMatchingTitle] = useState<string>(matchingTitle || "");
    const [editTtitleInputVal, setEditTitleInputVal] = useState<string>(matchingTitle || "");

    const [isEditDescription, setIsEditDescription] = useState<boolean>(false);
    const [
        // lastMatchingDescription
        , setLastMatchingDescription] = useState<string>(matchingDescription || "");
    const [editDescriptionInputVal, setEditDescriptionInputVal] = useState<string>(matchingDescription || "");

    const [isEditOtherPositionTitles, setIsEditOtherPositionTitles] = useState<boolean>(false);
    const [lastMatchigOtherTitles, setLastMatchingOtherTitles] = useState<string[]>(matchingOtherTitles || []);
    const [editOtherPositionTitlesInputVal, setEditOtherPositionTitlesInputVal] = useState<string[]>(matchingOtherTitles || []);
    const { tr } = useContext(AppContext);

    useEffect(() => {
        graphQueries.sendRequest(proxyDataService, {
            path: "/hiring/sheet/jobs/job_skills?job=" + matchingTitle,
            method: "GET"
        })
        .then(console.log)
        .catch(console.error)
    }, [])

    const onApproveTitleChange = (newTitle: string) => {
        const isTitleValid = newTitle && newTitle.length > 0 && isEditTitle
        if(!isTitleValid) {
            setError("Title is required")
            setIsEditTitle(false)
            return;
        }
        setLastMatchingTitle(newTitle)
        setFieldValue("matchingTitle", newTitle)
        setIsEditTitle(false)
    }

    const onCancelTitleChange = (lastTitle: string) => {
        setEditTitleInputVal(lastTitle);
        setIsEditTitle(false);
    }

    const onApproveOtherTitlesChanged = (newOtherTitles: string[]) => {
        setLastMatchingOtherTitles([...newOtherTitles])
        setFieldValue("matchingOtherTitles", [...newOtherTitles]);
        setIsEditOtherPositionTitles(false);
    }

    const onCancelOtherTitlesChanges = (lastOtherTitles: string[]) => {
        setFieldValue("matchingOtherTitles", [...lastOtherTitles]);
        setEditOtherPositionTitlesInputVal([...lastMatchigOtherTitles])
        setIsEditOtherPositionTitles(false)
    }
    
    const onApproveDescriptionChange = (newDescription: string) => {
        setLastMatchingDescription(newDescription)
        setFieldValue("matchingDescription", newDescription)
        setIsEditDescription(false);
    }

    // const onCancelDescriptionChange = () => {
    //     editor?.setData?.(lastMatchingDescription);
    //     setEditDescriptionInputVal(lastMatchingDescription);
    //     setIsEditDescription(false);
    // }

    const handleDescriptionChange = (newDescription: string) => {
        editor?.setData?.(newDescription);
        setEditDescriptionInputVal(newDescription);
    }

    return (
        <WizardStepTemplate
        title={TITLE}
        description={DESCRIPTION}>
            <EditTemplateComponent
            title={<h2 className="review-step-sub-header">{tr("Title")}</h2>}
            onEditClicked={() => setIsEditTitle(true)}
            titleWrapperClassName="review-step-sub-header-container"
            open={isEditTitle}
            onClickOutside={() => onApproveTitleChange(editTtitleInputVal)}
            > 
            <>
                {matchingTitle && !isEditTitle &&
                <h1 className="review-step-title-header">
                    {matchingTitle}
                </h1>}
                
                {isEditTitle && 
                <ConfirmationInput 
                inputValue={editTtitleInputVal} 
                handleInputChange={(e) => setEditTitleInputVal(e.target.value)} 
                onConfirm={() => onApproveTitleChange(editTtitleInputVal)} 
                onCancel={() => onCancelTitleChange(lastMatchingTitle)}/>}
            </>
            </EditTemplateComponent>

            <EditTemplateComponent
            title={<h2 className="review-step-sub-header">Also accept candidates with the following experience</h2>}
            className={"margin-top-wizard-review"}
            editText={isEditOtherPositionTitles ? "Save" : "Edit"}
            onEditClicked={() => {
                if(!isEditOtherPositionTitles) return setIsEditOtherPositionTitles(true);
                onApproveOtherTitlesChanged(editOtherPositionTitlesInputVal)
            }}
            titleWrapperClassName="review-step-sub-header-container"> 
                <div className="review-step-card-container">
                    
                    {!isEditOtherPositionTitles && 
                    (matchingOtherTitles && matchingOtherTitles?.length > 0) &&
                    matchingOtherTitles?.map((otherTitle, index) => (
                        <SuggestionCard 
                        key={`${otherTitle}-${index}`}
                        showDeleteIcon={false}
                        className="review-step-custom-skill-card "
                        suggestion={{ value: otherTitle, label: otherTitle }}>
                            {otherTitle}
                        </SuggestionCard>
                    ))}

                    {!isEditOtherPositionTitles && 
                    (!matchingOtherTitles || matchingOtherTitles?.length === 0) &&
                    <span className="review-step-job-description">{tr("Cannot find other titles")}</span>}
                    
                    {isEditOtherPositionTitles &&
                    <div className="other-titles-input-wrapper">
                        <AutocompleteCustom
                        onChange={(e, newOtherTitles: any[]) => {
                            setEditOtherPositionTitlesInputVal(newOtherTitles?.map(ot => ot.value))
                        }}
                        formatFreeSoloOption={(o: any) => ({ value: o?.value, label: o?.value })}
                        value={editOtherPositionTitlesInputVal.map(ot => ({ value: ot, label: ot }))}
                        options={editOtherPositionTitlesInputVal.map(ot => ({ value: ot, label: ot }))}
                        placeholder={tr("Add other position titles")}
                        getOptionLabel={o => o.label}
                        freeSolo={true}
                        />
                        <div className="other-titles-confiramtions-controllers">
                            <IconButton 
                            onClick={() => onApproveOtherTitlesChanged(editOtherPositionTitlesInputVal)}
                            className="confirmation-input-confirm-button">
                                <CheckIcon/> 
                            </IconButton>
                            <IconButton 
                            onClick={() => onCancelOtherTitlesChanges(lastMatchigOtherTitles)}
                            className="confirmation-input-confirm-button">
                                <CloseIcon/> 
                            </IconButton>
                        </div>
                    </div>}
                </div>
            </EditTemplateComponent>

            {matchingDescription &&
            <EditTemplateComponent
            title={<h2 className="review-step-sub-header">{tr("Description")}</h2>}
            onEditClicked={() => {
                if(!isEditDescription) return setIsEditDescription(true);
                onApproveDescriptionChange(editDescriptionInputVal)
            }}
            onClickOutside={() => onApproveDescriptionChange(editDescriptionInputVal)}
            open={isEditDescription}
            editText={ tr(isEditDescription ? "Save" : "Edit") }
            titleWrapperClassName={"review-step-sub-header-container"}
            className={"margin-top-wizard-review"}> 
            <>
                {!isEditDescription &&
                <div className="review-step-job-description">
                    {ReactHtmlParser(matchingDescription)}
                </div>}

                {isEditDescription &&
                <CKEditorCustom
                initData={editDescriptionInputVal}
                onLoaded={({ editor }) => setEditor(editor)}
                onChange={(args) => handleDescriptionChange(args.editor.getData())}/>}
            </>
            </EditTemplateComponent>}
            

            {matchingSkills &&
            <EditTemplateComponent 
            title={<h2 className="review-step-sub-header">{tr("Skills")}</h2>}
            onEditClicked={() => setStep(pages.findIndex(p => p.id === WizardStepsIds.TraitsAndSkills))}
            className={"margin-top-wizard-review"}
            titleWrapperClassName={"review-step-sub-header-container"}> 
                <div className="review-step-card-container">
                    {matchingSkills.map((skill, index) => (
                    <SuggestionCard 
                    className="review-step-custom-skill-card"
                    key={`${skill?.name}-${index}`}>
                        {skill?.name}
                    </SuggestionCard>
                    ))}
                </div>
            </EditTemplateComponent>}

            {(matchingYearsOfExperience || matchingYearsOfManagementExperience || matchingLanguages || matchingLocation || matchingJobTypes?.length) &&
            <EditTemplateComponent 
            title={<h2 className="review-step-sub-header">{tr("Requirements")}</h2>}
            onEditClicked={() => setStep(pages.findIndex(p => p.id ===WizardStepsIds.REQUIREMENTS))}
            className={"review-step-requirements-section"}
            titleWrapperClassName="review-step-sub-header-container">
                <p className="reivew-step-requirements-text">
                    {generateRequirementsText({
                        yearsOfExperience: matchingYearsOfExperience, 
                        yearsOfManagementExperience: matchingYearsOfManagementExperience, 
                        languages: matchingLanguages, 
                        location: matchingLocation, 
                        jobTypes: matchingJobTypes
                    })}
                </p>
            </EditTemplateComponent>}

            {matchingHrms && matchingHrms.email &&
            <EditTemplateComponent 
            title={<h2 className="review-step-sub-header">{tr("Hiring manager")}</h2>}
            onEditClicked={() => setStep(pages.findIndex(p => p.id === WizardStepsIds.RECIPIENTS))}
            className={"review-step-hrms-section"}
            titleWrapperClassName="review-step-sub-header-container">
                <p className="reivew-step-requirements-text">
                    {matchingHrms.email}
                </p>
            </EditTemplateComponent>}

        </WizardStepTemplate>
    )
}

export default PublishStep;