import React from "react";

import "./instructionsBoxStyle.css";

const InstructionsBox: 
    React.FunctionComponent<{ headline?: string, content?:string, displayInstructions: boolean}> 
    = ({ headline, content, displayInstructions }): JSX.Element => {
    
    const contentWithLineBreak = content?.split('\n').map((str,i) => <p key={i}>{str}</p>);

    return (
        <div 
            className="cp-instructions-wrap"
            style={displayInstructions? {display: "flex"} : {display: "none"}}
        >
            <div className="cp-instructions">
            <p className="cp-instructions-headline">
                {headline}
            </p>            
            {contentWithLineBreak}                            
            </div>
        </div>
    )
}

export default InstructionsBox;