export const deleteReferrerQuery = `
  mutation DeleteReferrerMutation ($referrerId: UUID) {
    deleteReferrer(referrerId: $referrerId) {
      response
    }
  }
`
export const changeUserCompanyQuery = `
  mutation ChangeUserCompany($userId: UUID!, $companyId: UUID!) {
    changeUserCompany(userId: $userId, companyId: $companyId) {
      response
    }
  }
`

export const createChildCompanyQuery = `
  mutation CreateChildCompany($companyName: String!, $userId: UUID!, $email: String!, $currentCompanyId: UUID!) {
    createChildCompany(companyName: $companyName, userId: $userId, email: $email, currentCompanyId: $currentCompanyId) {
      token,
      companyId
      slug
    }
  }
`