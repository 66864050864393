const Loading: React.FunctionComponent = (): JSX.Element => {
  return (
    <main className="loading">
      <div className="bg-white h-screen rounded flex flex-col sm:flex-row gap-5 select-none w-full">
        <div className="flex flex-col flex-1 gap-5 sm:p-2">
          <div className="flex flex-col gap-3">
            <div className="bg-gray-200 w-full animate-pulse h-24 rounded-2xl mb-8" />

            <div className="flex h-96 gap-2">
              <div className="flex flex-col gap-y-2 w-1/2 animate-pulse h-full rounded-2xl">
                <div className="bg-gray-200 h-1/2 animate-pulse rounded-2xl" />
                <div className="bg-gray-200 h-1/2 animate-pulse rounded-2xl" />
              </div>

              <div className="bg-gray-200 w-1/2 animate-pulse h-full rounded-2xl" />
            </div>
          </div>

          <div className="flex my-6">
            <div className="bg-gray-200 w-full h-8 animate-pulse rounded-2xl" />
          </div>

          <div className="bg-gray-200 w-full h-32 animate-pulse rounded-2xl" />
        </div>
      </div>
    </main>
  )
}

export default Loading
