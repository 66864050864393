import { useEffect, useState } from "react";
import _, { sum } from "lodash";
import { flatten, isEmpty } from "lodash";
import { CandidateBlockMeanings, CandidateResponse, ComProfileForecastResponse, ComProfileResponse, CorrectAnswerFields, CVBlockResponse, QuestionsBlockResponse, ReportReccomedationsResponse, ResumeData, TypeOfBlocks, VideoBlockResponse } from "../data/types.d";
import { ReportApi } from "../data/report.api";
import { IPosition } from "@/interfaces/pages/positions";
import { ComProfile } from "../data/report.utils";
import moment from "moment";
import { diffTimesItervals, removeDuplicates } from "@/helpers";
import { ProceedStatus } from "@/pages/Talents/interfaces";

interface FormattedCVData { 
    avgYearsPerEmployer?: number,
    totalYearsOfExperience?: number,
    totalYearsOfManagementExperience?: number,
    gapsInEmpyment?: number,
    candidateSummary?: string,
    skills?: string[],
    fields?: string[],
    educations?: string[],
}

interface FormattedCandidateData extends CandidateResponse { 
    fullName?: string,
    comProfileDetailed?: ComProfileResponse,
    refferalName?: string,
    isSkillChannelVerified?: boolean,
}

interface FormattedVideoData {
    videoUrl?: string,
    videoQuestion?: {
        id?: string,
        question?: string,
        question_category?: string,
        hrm_explanation?: string,
        candidate_guide?: string,
        hrm_guide?: string,
        default?: boolean,
        published?: boolean,
        hrm_indicator?: string,
    }
}

interface FormattedPositionData {
    title?: string,
    candidatesCount?: number,
    hrm?: {
        email?: string,
        comProfile?: ComProfile,
        comProfileDetailed?: ComProfileResponse
    }
}

interface FormattedQuestionsAnswersData { 
    openQuestions?: {}[],
    restOfQuestions?: {}[]
}


type WhoWrote = 'unboxable' | 'hr';
export interface FormattedRecommendations { 
    [section: string]: { 
        [who in WhoWrote]: ReportReccomedationsResponse[] 
    } 
}

export interface IReportData {
    candidateData?: FormattedCandidateData;
    cvData?: FormattedCVData,
    videoData?: FormattedVideoData,
    positionData?: FormattedPositionData,
    comProfileForecast?: ComProfileForecastResponse,
    reccomendations?: FormattedRecommendations,
    loading?: boolean,
    isOldReport?: boolean,
    questionsAnswers?: FormattedQuestionsAnswersData,
    skippedQuestions?: {},
    isUserRankAnswer: boolean,
    setIsUserRankAnswer: (val: boolean) => void,
    rankAnswer: (assessmentId: string, correctAnswers: CorrectAnswerFields) => any,
    getAndSetCandidateData: () => Promise<void>,
    setCandidateStatus: (status: ProceedStatus) => void,
}

export const useCandidateReport = ({ 
    candidateId, positionId, companyId
}: { candidateId: string, positionId: string, companyId: string }): IReportData => {

    const [isOldReport, setIsOldReport] = useState<boolean>(false);

    const [candidateData, setCandidateData] = useState<FormattedCandidateData | undefined>(undefined);
    const [unformattedCVData, setUnformattedCVData] = useState<CVBlockResponse | undefined>(undefined); 
    const [cvData, setCVData] = useState<FormattedCVData | undefined>(undefined);
    const [videoData, setVideoData] = useState<FormattedVideoData | undefined>(undefined);
    const [positionData, setPositionData] = useState<FormattedPositionData | undefined>(undefined);
    const [comProfileForecast, setComProfileForecast] = useState<ComProfileForecastResponse | undefined>(undefined);

    const [reccomendations, setReccomendations] = useState<FormattedRecommendations | undefined>(undefined);

    const [questionsAnswers, setQuestionsAnswers] = useState<FormattedQuestionsAnswersData | undefined>(undefined);
    const [skippedQuestions, setSkippedQuestions] = useState<{}>({});

    const [isUserRankAnswer, setIsUserRankAnswer] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    
    const blocks_functionality: { [key in TypeOfBlocks]?: (args: any) => any} = {
        [TypeOfBlocks.CV]: (cvBlock: CVBlockResponse) => {            
            const formattedCVData = extractFormattedDataFromCVBlock(cvBlock);
            setCVData(formattedCVData);
        },
        [TypeOfBlocks.VIDEO]: async (videoBlock: VideoBlockResponse) => {
            const videoUrl = await extractFormattedDataFromVideoBlock(videoBlock)
            setVideoData(videoUrl);
        },
    }

    useEffect(() => {
        setLoading(true);
        ReportApi.getCandidateBlocksByCandidateId(candidateId, positionId, companyId)
        .then(async (response) => {
            const { 
                getCandidateBlocks: blocks, 
                getCandidate: candidate, 
                getV2Position: position,
                generateReportRecommendations: reccomendations
            } = response || {};
            
            const isOldReport = blocks.find(block => (block.typeOfBlock as any) === "v1") || !blocks.length;
            if(isOldReport) {
                setIsOldReport(true);
            }
            
            const formattedPositionData = await extractFormattedDataFromPosition(position);
            setPositionData(formattedPositionData);
            
            const cvBlock = blocks.find(block => block.typeOfBlock === TypeOfBlocks.CV);
            setUnformattedCVData(cvBlock)

            const formattedCandidateData = await extractFormattedDataFromCandidateBlock(candidate, cvBlock);
            setCandidateData(formattedCandidateData);
            
            const comProfileForcast = await getComProfileForecastIfExists(formattedCandidateData, formattedPositionData);
            setComProfileForecast(comProfileForcast); 
            
            const formattedReccomendationsData = extractFormattedDataFromReccomendations(reccomendations, formattedCandidateData);
            setReccomendations(formattedReccomendationsData);

            const noQuestionsBlocksTypes = [TypeOfBlocks.CV, TypeOfBlocks.VIDEO];
            const questionsBlocks = blocks.filter(block => !noQuestionsBlocksTypes.includes(block.typeOfBlock));            
            const questionsAnswers = await extractFormattedDataFromQuestionsBlocks(questionsBlocks);
            setQuestionsAnswers(questionsAnswers);

            const skippedQuestions = await extractFormattedSkippedQuestionsFromQuestionsBlocks(questionsBlocks);
            setSkippedQuestions(skippedQuestions);

            for (const block of blocks) {
                await blocks_functionality?.[block.typeOfBlock]?.(block);
            }
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }, [])

    const getYearsFrommMonthes = (monthes: number): number => {
        return Number((monthes / 12).toFixed(1));
    }

    const getAvgYearsPerEmployerFromResumeData = (resumeData: ResumeData): number => {
        const { EmploymentHistory } = resumeData || {};
        const { ExperienceSummary } = EmploymentHistory || {};

        const { AverageMonthsPerEmployer } = ExperienceSummary || {};
        const avgYearsPerEmployer = getYearsFrommMonthes(AverageMonthsPerEmployer ?? 0);

        return avgYearsPerEmployer;
    }

    const getTotalYearsOfExperienceFromResumeData = (resumeData: ResumeData): number => {
        const { EmploymentHistory } = resumeData || {};
        const { ExperienceSummary } = EmploymentHistory || {};

        const { MonthsOfWorkExperience } = ExperienceSummary || {};
        const totalYearsOfExperience = getYearsFrommMonthes(MonthsOfWorkExperience ?? 0);

        return totalYearsOfExperience;
    }

    const getYearsOfManagementExperienceFromResumeData = (resumeData: ResumeData): number => {
        const { EmploymentHistory } = resumeData || {};
        const { ExperienceSummary } = EmploymentHistory || {};

        const { MonthsOfManagementExperience } = ExperienceSummary || {};
        const totalYearsOfManagementExperience = getYearsFrommMonthes(MonthsOfManagementExperience ?? 0);

        return totalYearsOfManagementExperience;
    }

    const getCandidateSummaryFromResumeData = (resumeData: ResumeData, firstName?: string): string => {
        const { EmploymentHistory } = resumeData || {};
        const { ExperienceSummary } = EmploymentHistory || {};

        const { Description: candidateSummary } = ExperienceSummary || {};

        if (candidateSummary && candidateSummary.includes("~")) {
            const newStr = candidateSummary.replace(/~+/g,'~');
            return newStr.replaceAll("~", _.capitalize(firstName || "the Talent"));
        }
        else if (candidateSummary && !candidateSummary.includes("~")) {
            return candidateSummary;
        }
        return "";
    }

    const getSkillsFromResumeData = (resumeData: ResumeData): string[] => {
        const { SkillsData } = resumeData || {};
        
        const taxonomies = SkillsData?.map(st => st.Taxonomies) || [];
        const subTaxonomies = flatten(taxonomies)?.map(t => t?.SubTaxonomies);
        const skillsTaxonomies = flatten(subTaxonomies)?.map(st => st?.Skills);
        const mergedSkillsTaxonomies = flatten(skillsTaxonomies);

        const skills = mergedSkillsTaxonomies.map(st => st?.Name);
        const filteredSkills = skills.filter(skill => !isEmpty(skill)) as string[];
        const skillsWithoutDuplicates = removeDuplicates(filteredSkills, JSON.stringify);

        return skillsWithoutDuplicates;
    }

    const getFirstNameFromResumeData = (resumeData: ResumeData): string | undefined => {
        const { ContactInformation } = resumeData || {};
        const { CandidateName } = ContactInformation || {};
        const { GivenName: firstName } = CandidateName || {};

        return firstName;
    }

    const getLastNameFromResumeData = (resumeData: ResumeData): string | undefined => {
        const { ContactInformation } = resumeData || {};
        const { CandidateName } = ContactInformation || {};
        const { FamilyName: lastName } = CandidateName || {};

        return lastName;
    }

    const getEmailFromResumeData = (resumeData: ResumeData): string | undefined => {
        const { ContactInformation } = resumeData || {};
        const { EmailAddresses } = ContactInformation || {};
        const email = EmailAddresses?.[0];

        return email;
    }

    const getLinkedInUrlFromResumeData = (resumeData: ResumeData): string | undefined => {
        const { ContactInformation } = resumeData || {};
        const { WebAddresses } = ContactInformation || {};
        const linkedInUrl = WebAddresses?.find(web => web.Type === "LinkedIn");
        const url = linkedInUrl?.Address;

        return url;
    }

    const getPhoneFromResumeData = (resumeData: ResumeData): string | undefined => {
        const { ContactInformation } = resumeData || {};
        const { Telephones } = ContactInformation || {};
        const phone = Telephones?.[0]?.Normalized;

        return phone;
    }

    const getEducationsFromResumeData = (resumeData: ResumeData): string[] | undefined => {
        const { Education } = resumeData || {};
        const { EducationDetails } = Education || {};
        const educationFields = EducationDetails?.map(ed => ed?.Majors) || [];
        const mergedEducationFields = flatten(educationFields);
        const filteredEducationFields = mergedEducationFields.filter(educationField => !isEmpty(educationField)) as string[];
        
        return filteredEducationFields;
    }

    const getFieldsFromResumeData = (resumeData: ResumeData): string[] | undefined => {
        const { EmploymentHistory } = resumeData || {};
        const { Positions } = EmploymentHistory || {};

        const fields = Positions?.map(p => p?.TaxonomyName) || [];
        const filteredFields = fields.filter(field => !isEmpty(field)) as string[];

        return filteredFields;
    }

    const getGapsInEmploymentFromResumeData = (resumeData: ResumeData): number | undefined => {
        const { EmploymentHistory } = resumeData || {};
        
        const { Positions } = EmploymentHistory || {};        
        const times = Positions?.map(p => ({ s: moment(p?.StartDate?.Date), e: moment(p.EndDate?.Date) })) || [];
        const gaps = diffTimesItervals(times);
        const gapsInYears = sum(gaps) / 12;
        const fixedGapsInYears = Number(gapsInYears.toFixed(1))

        return fixedGapsInYears;
    }

    const extractFormattedDataFromCVBlock = (cv_block: CVBlockResponse): FormattedCVData | undefined => {
        const { blockResponse } = cv_block || {};
        if(!blockResponse) return;

        const { Value } = blockResponse || {};
        if(!Value) return;

        const { RedactedResumeData } = Value || {};
        if(!RedactedResumeData) return;

        return {
            skills: getSkillsFromResumeData(RedactedResumeData),
            avgYearsPerEmployer: getAvgYearsPerEmployerFromResumeData(RedactedResumeData),
            totalYearsOfExperience: getTotalYearsOfExperienceFromResumeData(RedactedResumeData),
            totalYearsOfManagementExperience: getYearsOfManagementExperienceFromResumeData(RedactedResumeData),
            candidateSummary: getCandidateSummaryFromResumeData(RedactedResumeData, candidateData?.fullName),
            gapsInEmpyment: getGapsInEmploymentFromResumeData(RedactedResumeData),
            fields: getFieldsFromResumeData(RedactedResumeData),
            educations: getEducationsFromResumeData(RedactedResumeData),
        };
    }

    const checkIfSkillChannelIsVerified = (blockMeanings: CandidateBlockMeanings[]): boolean | undefined => {
        const blockMeaningTypeVerifiedUnverified = blockMeanings.find(bm => bm?.blockMeanings?.type === "VerifiedUnverified");
        const vrifiedUnverified = blockMeaningTypeVerifiedUnverified?.blockMeanings?.meaning?.[0]?.message;
        
        if(vrifiedUnverified === undefined) return vrifiedUnverified;

        if(vrifiedUnverified === "verified") return true;
        
        return false
    }

    const getValidLinkedinUrl = (linkedinUrl: string) => {
        const isStartWithHttps = linkedinUrl.startsWith('https://') || linkedinUrl.startsWith('http://');
        return isStartWithHttps ? linkedinUrl : `https://${linkedinUrl}`
    }

    const extractFormattedDataFromCandidateBlock = async (candidateData: CandidateResponse, cv_block?: CVBlockResponse): Promise<FormattedCandidateData | undefined> => {        
        const { firstName, lastName, email, linkedinUrl, phone, comProfile, referrer } = candidateData || {};


        const formattedCandidateData: FormattedCandidateData = {
            ...candidateData,
        };

        if(referrer) {
            const refferalName = await ReportApi.getRfferalByCode(referrer).catch(e => e);
            if(refferalName) {
                formattedCandidateData.refferalName = refferalName;
            }
        }

        if(comProfile){
            const comProfileDetails = await ReportApi.getCommunicationStyleDetailed(comProfile).catch(e => e);
            if(comProfileDetails) {
                formattedCandidateData.comProfileDetailed = comProfileDetails;
            }
        }

        formattedCandidateData.isSkillChannelVerified = checkIfSkillChannelIsVerified(candidateData?.blocksMeanings || []);

        const { blockResponse } = cv_block || {};
        const { Value } = blockResponse || {};
        const { ResumeData } = Value || {};
        if(!ResumeData) return {
            ...formattedCandidateData,
            fullName: `${firstName} ${lastName}`,
        };

        let needUpdateCandidate = false;
        if(!firstName) {
            const firstNameFromResume = getFirstNameFromResumeData(ResumeData);
            formattedCandidateData.firstName = firstNameFromResume ?? "Anonymous";
            if(firstNameFromResume) {
                needUpdateCandidate = true;
            }
        }
        
        if(!lastName) {
            const lastNameFromResume = getLastNameFromResumeData(ResumeData);
            if(lastNameFromResume){
                formattedCandidateData.lastName = lastNameFromResume;
                needUpdateCandidate = true;
            }
        }
        
        if(!email) {
            const emailFromResume = getEmailFromResumeData(ResumeData);
            if(emailFromResume) {
                formattedCandidateData.email = emailFromResume;
                needUpdateCandidate = true;
            }
        }
        
        if(!linkedinUrl) {
            const linkedinFromResume = getLinkedInUrlFromResumeData(ResumeData);
            if(linkedinFromResume) {
                formattedCandidateData.linkedinUrl = getValidLinkedinUrl(linkedinFromResume);
                needUpdateCandidate = true;
            }
        }else {
            formattedCandidateData.linkedinUrl = getValidLinkedinUrl(linkedinUrl);
        }
        
        if(!phone) {
            const phoneFromResume = getPhoneFromResumeData(ResumeData);
            if(phoneFromResume) {
                formattedCandidateData.phone = getPhoneFromResumeData(ResumeData);
                needUpdateCandidate = true;
            }
        }
        
        formattedCandidateData.fullName = `${formattedCandidateData.firstName} ${formattedCandidateData.lastName ?? ""}`;

        if(needUpdateCandidate) {
            ReportApi.updateCandidate({
                candidateId: formattedCandidateData.id,
                firstName: formattedCandidateData.firstName,
                lastName: formattedCandidateData.lastName,
                email: formattedCandidateData.email,
                phone: formattedCandidateData.phone
            });
        }
        
        return formattedCandidateData;
    }

    const extractFormattedDataFromVideoBlock = async (video_block: VideoBlockResponse): Promise<FormattedVideoData | undefined> => {
        const { blockData } = video_block || {};
        if(!blockData) return undefined;
        
        const { fileName } = blockData || {};
        if(!fileName) return undefined;
        
        const videoUrl = await ReportApi.getVideoUrlByVideoName(fileName);
        if(!videoUrl) return undefined;
        
        const { vidQuestion } = blockData || {};

        return { videoUrl: videoUrl, videoQuestion: vidQuestion };
    }

    const extractFormattedDataFromQuestionsBlocks = async (questions_blocks: QuestionsBlockResponse[]): Promise<FormattedQuestionsAnswersData> => {
        const skillevals = [];
        const allCorrectAnswers = [];
        for(const questions_block of questions_blocks){    
            const { blockResponse, correctAnswers } = questions_block || {};
            allCorrectAnswers.push(correctAnswers);

            const { result } = blockResponse || {};
            const { report } = result?.[0] || {};
            const { skillevals: skilleval } = report || {}; 
            
            const skilleavlsWithAssessmentId = skilleval?.map(se => ({...se, assessmentId: questions_block.assessmentId})) ?? []
            skillevals.push(skilleavlsWithAssessmentId);
        }

        const flattenedSkillevals = flatten(skillevals);
        const filteredSkillevals = flattenedSkillevals.filter(question => !isEmpty(question));

        const allQuestions = flatten(filteredSkillevals.map((s: any) => 
        // Add the assessmentId to each question
        s.questions?.map((q: any) => ({...q, assessmentId: s.assessmentId})) ?? []
        ));
        const formattedAllQuestions = allQuestions.map(q => ({ ...q, correctAnswer: q?.score > -1 ? q.score : undefined }))

        const openQuestions = formattedAllQuestions.filter(q => ["comment", "text"].includes(q.question.type));
        const restQuestions = formattedAllQuestions.filter(q => !openQuestions.includes(q));
        
        return {
            openQuestions: openQuestions,
            restOfQuestions: restQuestions,
        };
    }

    const extractFormattedSkippedQuestionsFromQuestionsBlocks = async (questions_blocks: QuestionsBlockResponse[]) => {
        const skippedQuestions: { [key in string]: any} = {};
        for(const questions_block of questions_blocks){
            const { blockData } = questions_block || {};
            const { skippedQuestionResponse } = blockData || {};
            Object.keys(skippedQuestionResponse??{}).forEach(key => {
                skippedQuestions[key] = skippedQuestionResponse?.[key]
            });
        }
        return skippedQuestions;
    }

    const extractFormattedDataFromPosition = async (position: Partial<IPosition>): Promise<FormattedPositionData | undefined> => {
        const { title, matchingTitle, matchingHrms } = position || {};

        const structeredPosition: FormattedPositionData = {
            title: matchingTitle ?? title,
            hrm: undefined,
        };

        const hrmMail = matchingHrms?.email;
        if(hrmMail) {
            structeredPosition.hrm = {
                email: hrmMail
            };

            const user = await ReportApi.getUserByEmail(hrmMail);
            const { comProfile } = user || {};
            if(comProfile) {
                const comProfileDetails = await ReportApi.getCommunicationStyleDetailed(comProfile);
                structeredPosition.hrm = {
                    ...structeredPosition.hrm,
                    comProfile: comProfile,
                    comProfileDetailed: comProfileDetails,
                };
            }
            
        }
        
        return structeredPosition;
    }

    const getComProfileForecastIfExists = async (candidateData?: FormattedCandidateData, positionData?: FormattedPositionData): Promise<ComProfileForecastResponse | undefined> => {
        const { comProfile } = candidateData || {};
        if(!comProfile) return undefined;

        const { hrm } = positionData || {};
        if(!hrm) return undefined;

        const { comProfile: hrmComProfile } = hrm || {};
        if(!hrmComProfile) return undefined;

        const comProfileForecast = await ReportApi.getComProfileForcast(comProfile, hrmComProfile).catch(e => e);

        return comProfileForecast;
    }

    const rankAnswer = async (assessmentId: string, correctAnswers: CorrectAnswerFields): Promise<boolean> => {
        if(!candidateId || !positionId || !assessmentId) return false;

        setIsUserRankAnswer(true)

        const res = await ReportApi.submitCorrectAnswer({
            candidateId: candidateId,
            assessmentId: assessmentId,
            correctAnswers: [correctAnswers],
        });

        if (res) {
            const updatedQuestions = questionsAnswers?.openQuestions?.map((qa: any) => 
            qa?.question?.name === correctAnswers?.questionUid 
            ? {...qa, correctAnswer: correctAnswers?.score } : qa);
            
            setQuestionsAnswers(prev => ({
                ...prev,
                openQuestions: updatedQuestions
            }));
        } 

        return res;
    }

    const getPlaceholderTextWithCandidateData = (text?: string, formattedCandidateData?: FormattedCandidateData, recommendation?: ReportReccomedationsResponse): string | undefined => {
        const { firstName, email } = formattedCandidateData || {};
        const { assessmentAxe } = recommendation || {};
        const placeholdersVariables = {
            "%user%": firstName ?? "talent",
            "%email%": email ? `<a href="mailto:${email}">${email}</a>` : "mail",
            "%axe%": assessmentAxe ?? "the topic"
        }

        let newText = text;
        Object.entries(placeholdersVariables)
        .forEach(([placeholder, value]) => {
            newText = newText?.replaceAll(placeholder, value)
        })

        return newText
    }

    const extractFormattedDataFromReccomendations = (reccomendations?: ReportReccomedationsResponse[], formattedCandidateData?: FormattedCandidateData): FormattedRecommendations => {
        const reccomendationsBySection: FormattedRecommendations = {};


        const structuredReccomendations = reccomendations?.map(r => ({
            ...r,
            section: getPlaceholderTextWithCandidateData(r?.section, formattedCandidateData, r),
            recommendationText: getPlaceholderTextWithCandidateData(r?.recommendationText, formattedCandidateData, r)
        })) ?? [];
        
        structuredReccomendations.forEach(r => {
            if(!r.section) return;
            
            const isHrRecommendation = r.CompanyId && r.CompanyId.length ? true : false;
            const whoWroteRecommendation: WhoWrote = isHrRecommendation ? 'hr' : 'unboxable';

            if(!reccomendationsBySection[r.section]) {
                reccomendationsBySection[r.section] =  {
                    unboxable: [],
                    hr: []
                }
            }
            reccomendationsBySection[r.section][whoWroteRecommendation].push(r);
        })

        return reccomendationsBySection;
    }

    const getAndSetCandidateData = async (): Promise<void> => { 
        const candidate = await ReportApi.getCandidateData(candidateId);
        const formattedCandidate = await extractFormattedDataFromCandidateBlock(candidate, unformattedCVData);
        setCandidateData(formattedCandidate)
    }

    const setCandidateStatus = (status: ProceedStatus) => {
        setCandidateData((prev) => ({ ...prev, status }) as FormattedCandidateData)
    }

    return {
        candidateData,
        cvData,
        videoData,
        positionData,
        comProfileForecast,
        reccomendations,
        loading,
        isOldReport,
        questionsAnswers,
        skippedQuestions,
        isUserRankAnswer,
        setIsUserRankAnswer,
        rankAnswer,
        getAndSetCandidateData,
        setCandidateStatus
    }
}