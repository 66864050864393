export const generateRequirementsText = ({
    yearsOfExperience, yearsOfManagementExperience, 
    languages, location, jobTypes
}: { 
    yearsOfExperience: number | undefined, 
    yearsOfManagementExperience: number | undefined, 
    languages: string[] | undefined, 
    location: string | undefined, 
    jobTypes: string[] | undefined 
}): string => {
    let text = ""
    if (yearsOfExperience) {
        text += `${yearsOfExperience} Years of relevant experience.`
    }
    if (yearsOfManagementExperience) {
        text += `${text ? " " : ""}${yearsOfManagementExperience} Years of management.`
    }
    if(jobTypes) {
        text += `${text ? " " : ""}Open to ${jobTypes.join(", ")}.`
    }
    if (location) {
        text += `${text ? " " : ""}Located in ${location}.`
    }        
    if (languages && languages.length) {
        text += `${text ? " " : ""}Speaks ${languages.join(" and ")}`
    }
    return text
}