// Styles
import './style.css';

// Interfaces
interface IMessage {
    children: JSX.Element
    backgroundColor?: string
}

const Message: React.FunctionComponent<IMessage> = (props):JSX.Element => (
    <div className='talent-report-message no-print'>
        <div className='talent-report-message-text' style={{
            backgroundColor: props.backgroundColor ? props.backgroundColor : "#D8D8D8",
        }}>
            {props.children}
        </div>
        <div className="message-traingle no-print">
            <svg viewBox="0 0 5 10" style={{ transform: "rotate(270deg)", color: "white" }} fill={props.backgroundColor ? props.backgroundColor : "#D8D8D8"}>
                <path d="M5 0 V10 L0.8 5.8 Q0 5 0.8 4.2z" />
            </svg>
        </div>
    </div>
)

export default Message;