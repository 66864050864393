import { useContext, useEffect, useState } from "react";

import Select from "react-select";
import { throttle } from "lodash";
import { SelectCustomStyles_Dropdown_white_bordered } from "@/components/UnboxableForm/ui/styles/select-custom";
import SearchIcon from '@mui/icons-material/Search';
import { IWizardStep, WizardStepsIds } from "../../interfaces";
import { generatePositionDefaultFieds, getPositions } from "./helpers";
import WizardStepTemplate from "@components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { IPosition } from "@/interfaces/pages/positions";
import { CreatePositionFromScratchModal, OtherPositionSuggestions, SuggestionHover } from "./components";
import { HtmlTooltip } from "@/components/WizardComponents/CustomTooltip/CustomTooltip";
import { IPage, IUseComponentStepper } from "@/components/WizardComponents/ComponentsStepper/interfaces";
import { IUser } from "@/auth/interfaces";
import ReactHtmlParser from 'react-html-parser';

import env from "react-dotenv";
import './style.css';
import AppContext from "@/context/AppContext";

// TODO localize

const TITLE = "Which role do you wish to assess candidates for?"


const DESCRIPTION = (args: {onClickFromScratch: () => any, currentCompanyId?: string}) =>  {
    const { onClickFromScratch, currentCompanyId } = args;
    const { tr } = useContext(AppContext);
    const companyIsPositionBank = currentCompanyId && (currentCompanyId === env.POSITIONBANK_ID);
    
    const contactUsMail = "contact@unboxable.com"; 
    const mailSubject = tr("Request new job simulation");
    const mailBody = tr("Please create a new job simulation: **please describe the position and the required skills**");

    return (
    <span>
        {tr(`Below is a list of Unboxable verified Job Simulators. Choose or search the one that fits your needs. 
        If you don't find what you're looking for`)}
         - <a href={`mailto:${contactUsMail}?subject=${mailSubject}!&body=${mailBody}`} >{tr("Contact us")}</a>
        &nbsp;
        {companyIsPositionBank && 
        <span 
        onClick={onClickFromScratch}
        className="start-from-scratch-link">start from scratch.</span>}
    </span>
)}

interface IOption { 
    label: any,
    value: string, 
    position: IPosition,
    title: string
}

const SELECT_COUNT = 10
const getThrottlePositionsAsSelectOptions = throttle(async (searchVal?: string, onMouseEnter?: (p: IPosition) => any, onMouseLeave?: () => any): Promise<IOption[]> => {
    const positions = await getPositions({ count: SELECT_COUNT, name: searchVal ?? undefined})
    const options = positions?.map((f: any) => ({
        label: 
        <HtmlTooltip 
        title={<SuggestionHover position={f}/>} 
        placement='right'>
            <div 
                onMouseEnter={() => onMouseEnter?.(f)}
                onMouseLeave={onMouseLeave}
                className="create-new-position-position-option">
            <span className="create-new-position-position-option-title">
                {f.matchingTitle ?? f.title}
            </span>
            {(f.matchingDescription || f.description) && 
            <p className="create-new-position-position-option-description">
                {ReactHtmlParser(f.matchingDescription ?? f.description)}
            </p>}
            {(f.matchingSkills || f.skills) && 
            <p className="create-new-position-position-option-skills">
                {(f.matchingSkills ?? f.skills).map((s: any) => <span key={s.name}>{s.name}</span>)}
            </p>}
        </div>
        </HtmlTooltip>,
        value: f.id,
        position: f,
        title: f.title
    }))
    return options ?? []
}, 2000)

const CreateNewPositionStep: React.FunctionComponent<
IWizardStep 
& Partial<IUseComponentStepper> 
& {
    user: IUser | null, 
    position_id: string | null, 
    companyId?: string,
    pages: IPage[],
    onStartFromScratch?: () => any
}> = ({
    formik,
    setStep,
    user,
    isLoading,
    setIsLoading,
    companyId,
    pages,
    onStartFromScratch
}): JSX.Element => {

    const { values, setValues } = formik;
    const { matchingTitle } = values;

    const [options, setOptions] = useState<IOption[]>();

    const [selectedTemplate, setSelectedTemplate] = useState<Partial<IPosition>>();

    const [otherPositionSuggestions, setOtherPositionSuggestions] = useState<{label: string, value: string, position: IPosition}[]>()

    const [showStartFromScratchModal, setShowStartFromScratchModal] = useState<boolean>(false);

    const { tr } = useContext(AppContext);
    
    const fetchAndSetSelectOptions = async (searchVal?: string) => {
        try {
            const options = await getThrottlePositionsAsSelectOptions(searchVal);
            return options;
        }
        catch(e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if(!options || options.length <= 0) {
            setIsLoading?.(true)
            fetchAndSetSelectOptions()
            ?.then((opts) => {
                if(!opts) {
                    return;
                }
                setOptions(opts); 
                setOtherPositionSuggestionsFromOptions(opts);
            })
            ?.catch(console.error)
            ?.finally(() => setIsLoading?.(false))
        }
    },[])

    useEffect(() => {
        const needToChangeStep = matchingTitle && matchingTitle.length && selectedTemplate && selectedTemplate.matchingTitle;
        if(needToChangeStep) {
            setStep?.(pages.findIndex(p => p.id === WizardStepsIds.TraitsAndSkills));
        }
    }, [matchingTitle, selectedTemplate])

    const setOtherPositionSuggestionsFromOptions = (options: IOption[]) => {
        const suggestions = options.map((o) => (
            {
                value: o.title,
                label: `+ ${o.title}`,
                position: o.position
            }
        ));
        setOtherPositionSuggestions(suggestions);
    }

    const onlyTemplateFields = async (position: Partial<IPosition>, user: IUser | null): Promise<Partial<IPosition> | undefined> => {
        const { email: userEmail, id: userId } = user || {};

        if(!userEmail || !userId || !user) return;

        const defatultFields = await generatePositionDefaultFieds(position, user) || {};

        return {
            ...defatultFields,
            id: undefined,
        }
    }

    const setPositionTemplate = (position: Partial<IPosition>) => {
        setIsLoading?.(true);
        onlyTemplateFields(position, user)
        .then((p) => {
            setValues(p ?? {})
        })
        .catch(console.error)
        .finally(() => setIsLoading?.(false))
    }

    const onTemplateSelect = (option: Partial<IOption>) => {    
        const { position } = option || {};
        if(!position) return;
        
        setSelectedTemplate(position);

        setPositionTemplate(position);
    }

    const handleTitleChange = (newValue: any, actionMeta: any) => {
        const { action } = actionMeta || {};
        
        const isInputChanged = action === 'input-change';
        const isOptionSelected = action === 'select-option';
        
        if(isInputChanged)
            fetchAndSetSelectOptions(newValue)
            ?.then((opts) => {
                if(!opts) return;
                setOptions(opts); 
            })
            ?.catch(console.error)
        
        if(isOptionSelected)
            onTemplateSelect(newValue)
    }

    return (
        <WizardStepTemplate
        title={TITLE}
        description={
            DESCRIPTION({ 
                onClickFromScratch: () => setShowStartFromScratchModal(true),
                currentCompanyId: companyId
            })
        }
        >
            <div className="wizard-create-new-position">
                {!isLoading &&
                <div className="wizard-create-new-position-searchbox-container">
                    <div className="wizard-create-new-position-names-suggestions">
                        <OtherPositionSuggestions
                        otherPositionSuggestions={otherPositionSuggestions}
                        onClick={onTemplateSelect}
                        withToolTip={true}
                        selectedPosition={selectedTemplate}
                        />
                    </div>
                    <Select
                    className="custom-select-style"
                    styles={SelectCustomStyles_Dropdown_white_bordered()}
                    components={{ 
                        DropdownIndicator: () => <SearchIcon style={{color: "#333"}}/>,
                    }}
                    isLoading={isLoading}
                    options={options}
                    // inputValue={matchingTitle ?? ""}
                    placeholder={tr("Search for other simulations")}
                    getOptionValue={(o: any) => o.title} 
                    onInputChange={handleTitleChange}
                    onChange={handleTitleChange}
                    />
                </div>} 
            </div>
            <CreatePositionFromScratchModal
            onSubitPositionFromScracth={(p) => {
                onTemplateSelect({ position: p as any });
                setShowStartFromScratchModal(false);
                onStartFromScratch?.()
            }}
            isModalOpen={showStartFromScratchModal}
            onClose={() => setShowStartFromScratchModal(false)}/>
        </WizardStepTemplate>
    )
}


export default CreateNewPositionStep;