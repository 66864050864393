import { IDisplayQuestion } from "@/models"
import { formModelUtils } from "@/models/formModelUtils"
import { QuestionCollection } from "@/models/formPage"
import React from "react"
import reactStringReplace from "react-string-replace"


// https://stackoverflow.com/questions/16167983/best-regular-expression-for-email-validation-in-c-sharp
const EMAIL_RE = /^\s*[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\s*$/i


export const isValidEmail = (email: string | null | undefined): boolean => {
	return Boolean(email && EMAIL_RE.test(email))
}

export const validateOneQuestion = (question: IDisplayQuestion): boolean => {
	if (!question) {
		return true
	}
	// First we check the question type
	if (formModelUtils.isTextQuestion(question)) {
		if (!question.value && !question.required) {
			return true
		}
		if (!question.value && question.required) {
			return false;
		}
		if (question.type === "email") {
			return isValidEmail(question.value)
		}
		return true
	} 
	else {
		if (!question.required) {
			return true
		}
		if (!question.value) {
			return false
		}
		if (typeof question.value === "object") {
			return Boolean((question.value as any).value)
		}
		return true
	}
}

// Question Validator
export const validateQuestionList = (questions: IDisplayQuestion[]): boolean => {
	if (!questions || !questions.length) {
		return true
	}
	return questions.findIndex(q => !validateOneQuestion(q)) < 0
}

export const validateQuestionCollection = (questions: QuestionCollection): boolean => {
	if (!questions || !questions.length) {
		return true
	}
	return questions.every(list => validateQuestionList(list))
}

/**
 * Try to return a sequence of text runs and react nodes, by replacing each occurance of the target
 * string (the one to match) with the provided node. If the target string is not found in the source,
 * just return the node
 * @param src 
 * @param toMatch 
 * @param node 
 * @returns 
 */
export const replaceWithOrSetReactNode = (src: string, toMatch: string, node: React.ReactNode): React.ReactNodeArray => {
	if (!src || !toMatch) {
		return [node]
	}
	if (src.indexOf(toMatch) < 0) {
		return [node]
	}
	return reactStringReplace(src, toMatch, () => node)
}