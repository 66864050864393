export const buildGetAllCompaniesQuery = (key: string): string => `
  query ${key} ($name: String, $limit: Int) {
    getAllCompanies(name: $name, limit: $limit) {
      companiesName {
        emailDomain
        id
        name
        slug
      }
    }
  }
`

export const getReferralsByCompanyId = (key: string): string => `
  query ${key} ($companyId: UUID!, $email: String, $limit: Int, $page: Int) {
    getReferrers(companyId: $companyId, email: $email, sizeOfPage: $limit, page: $page) {
      count
      referrers {
        name
        email
        id
        code
      }
    }
  }
`

export const getReferralLink = `
  query ($email: String!, $name: String!, $companyId: UUID!, $positionId: UUID) {
    getReferralLink(email: $email, name: $name, companyId: $companyId, positionId: $positionId) {
      response
      link
    }
  }
`

export const getLinkedCompanies = (key: string): string => `
  query ${key} ($companyId: UUID!) {
    getLinkedCompanies(companyId: $companyId) {
      companyName
      companyId
      slug
    }
    getAllCompanyInformation(companyId: $companyId) {
      name
      slug
      emailDomain
    }
  }
`
