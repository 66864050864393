import { useContext, useEffect, useState } from 'react';
import { UnboxableButton } from '@/components';
import AutocompleteCustom from '@/components/WizardComponents/AutocompleteNew/AutocompleteNew';
import CKEditorCustom from '@/components/WizardComponents/CKEditorCustom/CKEditorCustom';
import Dropdown from '@/components/WizardComponents/Dropdown/Dropdown';
import { getPositionTitleAndIdsByCompanyId } from '@/graphql/queries/positions';
import { graphQueries } from '@/helpers';
import { IPosition } from '@/interfaces/pages/positions';
import { throttle } from 'lodash';
import { recommendationAxes, recommendationReasons, recommendationSections } from '../../data/enums';
import { IRecommendation } from '../../data/types';
import './style.css';
import LText from '@/components/LText';
import AppContext from '@/context/AppContext';

const dropdownStyle = { borderRadius: "12px", width: '200px' };

const getPositionOptions = throttle(async (title: string, companyId: string, sizeOfPage?: number): Promise<{ value: string, label: string }[]> => {
    const { getV2Positions }: { getV2Positions: { positions: IPosition[] } } = await graphQueries.sendRequest(
        getPositionTitleAndIdsByCompanyId(), { companyId, titleName: title, sizeOfPage }) ;
    const { positions } = getV2Positions || {};
    const optionsFromPosition = positions?.map(p => ({ value: p.id, label: p.matchingTitle }))    
    return optionsFromPosition
}, 1000)

const RecommendationEditor: React.FunctionComponent<{
    isEditMode?: boolean;
    recommendationToEdit?: IRecommendation;
    onSubmit?: (recommendation: Partial<IRecommendation>) => any;
    isOpenedFromHrCorner?: boolean;
    companyId?: string;
}> = ({
    isEditMode, recommendationToEdit, onSubmit, isOpenedFromHrCorner, companyId
}): JSX.Element => {

    const sectionsOptions = Object.entries(recommendationSections).map(([k,v]) => ({ value: k, label: v }));
    const reasonsOptions = Object.entries(recommendationReasons).map(([k,v]) => ({ value: k, label: v }));
    const axeOptions = Object.entries(recommendationAxes).map(([k,v]) => ({ value: k, label: v }));

    const [showAdvencedOptions, setShowAdvecedoptions] = useState<boolean>(isOpenedFromHrCorner ? false : true);

    const [positionOptions, setPositionOptions] = useState<{ value: string; label: string }[]>([]);
    const [isLoadingPositionsOptions, setIsLoadingPositionsOptions] = useState<boolean>(false);
    const { tr } = useContext(AppContext);

    useEffect(() => {
        setIsLoadingPositionsOptions(true);
        getPositionOptions('', companyId!, 5)
        ?.then(setPositionOptions)
        ?.catch(console.error)
        ?.finally(() => setIsLoadingPositionsOptions(false))
    }, [])

    const [recommendation, setRecommendation] = useState<Partial<IRecommendation>>(
        isEditMode && recommendationToEdit ? 
        recommendationToEdit
        : {
            assessmentAxe: "",
            section: "good_to_know",
            reason: "",
        }
    );
    
    const handleChange = (name: keyof IRecommendation, value: string) => {
        setRecommendation(prev => ({
            ...prev,
            [name]: value
        }))
    };

    const handleAutocompleteChange = (values: any[]) => {
        setRecommendation(prev => ({
            ...prev,
            positions: values?.map(v => ({ id: v.value, title: v.label }))
        }))
    }
    
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault() 
        const filteredPositions = recommendation.positions?.filter(p => p !== "");
        onSubmit?.({
            ...recommendation,
            ...(recommendation.section?.length 
                ? { section: recommendation.section } : { }),
            ...(recommendation.assessmentAxe?.length 
                ? { assessmentAxe: recommendation.assessmentAxe } : { }),
            ...(recommendation.reason?.length 
                ? { reason: recommendation.reason } : { }),
            ...(filteredPositions?.length 
                ? { positions: filteredPositions } : { }),
        });
    }

    const onAutocompleteInputChange = (e: any) => {
        if(e?.target?.value) {
            setIsLoadingPositionsOptions(true);
            getPositionOptions(e.target.value, companyId!)
            ?.then(setPositionOptions)
            ?.catch(console.error)
            ?.finally(() => setIsLoadingPositionsOptions(false))
        }
    }

    return (
        <div className='recommendation-form-container'>
            <h3
            className='recommendation-form-title'>
                <LText text={
                isEditMode ? 
                    isOpenedFromHrCorner ? 
                    "Edit guideline"
                    :"Edit recommendation"
                : 
                    isOpenedFromHrCorner ? 
                    "Add your hiring guidelines"
                    :"Add new recommendation"
                } />
            </h3>
                {isOpenedFromHrCorner ? 
                <>
                    <p  className='recommendation-form-description'>
                        {tr("This is your hiring process, so make the reviewing of candidates suit you!")}
                    </p>
                    <ul className='recommendation-form-description recommendation-form-description-hr-list'>
                        <li>{tr("Your guidelines will appear in the Recommendations Center within the candidate's.*")}</li>
                        <li>{tr("By default, your guidelines will appear in the 'Good to Know' section.*")}</li>
                        <li>{tr("You can personalize your guidelines for specific topics and sections.*")}</li>
                    </ul>    
                </>
                :
                <p  className='recommendation-form-description'>
                    {tr("Gain more control over the hiring process by injecting.*")}
                </p>}
            <form className='recommendation-form-form-elements' onSubmit={handleSubmit}> 
                <div 
                className={`recommendation-form-dropdowns-container ${!showAdvencedOptions ? "recommendation-form-dropdowns-container-hide" : ""}`}> 
                    <Dropdown 
                    name='section'
                    tooltip={tr("Choose where to show your guidline in the recommendation center.")}
                    defaultValue={recommendation?.section ?? ""}
                    value={recommendation?.section ?? ""}
                    renderValue={(o) => (recommendationSections as any)[o]}
                    onChange={(e) => handleChange('section', e.target.value)}
                    style={dropdownStyle}
                    label={"Choose section"}
                    options={sectionsOptions}/>
                    <Dropdown 
                    name='reason'
                    tooltip={tr("Personalize the recommendation according to the candidate performance.")}
                    defaultValue={recommendation?.reason ?? ""}
                    value={recommendation?.reason ?? ""}
                    renderValue={(o) => (recommendationReasons as any)[o]}
                    onChange={(e) => handleChange('reason', e.target.value)}
                    style={dropdownStyle}
                    label={tr("Show when")}
                    options={reasonsOptions}/>
                    <Dropdown 
                    name='assessmentAxe'
                    tooltip={tr("Personalize the recommendation according to the candidate performance in a specific topic")}
                    defaultValue={recommendation?.assessmentAxe ?? ""}
                    value={recommendation?.assessmentAxe ?? ""}
                    renderValue={(o) => (recommendationAxes as any)[o]}
                    onChange={(e) => handleChange('assessmentAxe', e.target.value)}
                    style={dropdownStyle}
                    label={tr("Topic")}
                    options={axeOptions}/>
                </div>
                {isOpenedFromHrCorner &&
                <span
                className='recommendation-form-show-advenced-options'
                onClick={() => setShowAdvecedoptions(prev => !prev)}>
                    {showAdvencedOptions ? tr("Hide advanced options") : `+ ${tr("Advanced options")}`}
                </span>}
                <CKEditorCustom
                initData={recommendation.recommendationText ?? undefined}
                onChange={({ editor }) => handleChange('recommendationText', editor.getData())}/>
                <AutocompleteCustom 
                style={{ borderColor: "#000" }}
                value={recommendation.positions?.map((p: any) => ({ label: p?.title ?? p.label, value: p?.id ?? p.value }))}
                label={tr('Associate with specific position (leave blank if all)')}
                options={positionOptions} 
                loading={isLoadingPositionsOptions}
                onInputChange={onAutocompleteInputChange}
                onChange={(e,v) => handleAutocompleteChange(v)}
                getOptionLabel={(o) => o.label}/>
                <UnboxableButton
                className='recommendation-form-submit-btn'> 
                    <LText text="Save and close" />
                </UnboxableButton>
            </form>
        </div>
    )
}

export default RecommendationEditor;