import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LinesChart: React.FunctionComponent<{data: any, colorAndLines: {[color:string]: string}}> = 
({data, colorAndLines}): JSX.Element => {
    return (
      <ResponsiveContainer width={"100%"} height={500}>
        <LineChart
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend align="right" verticalAlign="middle" layout={"vertical"} iconType="square" />
          {Object.keys(colorAndLines).map((color, i) => (
              <Line key={i} type="monotone" dataKey={colorAndLines[color]} stroke={color} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
}

export default LinesChart;