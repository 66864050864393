import { UnboxableButton } from "@/components";
import { IconButton } from "@mui/material";
import { IRecommendation } from "../../../Recommendations/data/types";
import MaterialTable, { TableProps } from "@/components/MaterialTable";

import plusIcon from '@assets/icons/plus-icon.svg';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useEffect, useState } from "react";
import './style.css';
import { IFilter } from "@/components/MaterialTable/parts/TableEnumFilters";
import { GridColDef } from "@mui/x-data-grid";

import ReactHtmlParser from 'react-html-parser';
import { IUnboxableButton } from "@/components/UnboxableButton";
import { recommendationAxes, recommendationReasons, recommendationSections } from "../../data/enums";

const RecommendationsTable: React.FunctionComponent<{
    recommedations: IRecommendation[];
    loading: boolean;
    tableProps?: Partial<TableProps>;
    addButtonProps?: Partial<IUnboxableButton>;
    onClickAddRecommendationBtn: () => any;
    onClickDeleteRecommendation?: (recommendationId: string) => any;
    onClickEditRecommendation?: (recommendationId: string) => any;
}> = ({
    recommedations, loading, tableProps, addButtonProps, onClickAddRecommendationBtn, onClickDeleteRecommendation, onClickEditRecommendation
}): JSX.Element => {

    const [ filteredRecommendations, setFilteredRecommendations ] = useState<IRecommendation[]>([]);

    useEffect(() => {
        if(!loading) {
            setFilteredRecommendations(recommedations)
        }
    }, [loading, recommedations])

    const columsSettings: GridColDef[] = [
        { field: "recommendationText", headerName: "Recommendation", flex: 1, renderCell: (p) => ReactHtmlParser(p.row.recommendationText) },
        { field: "section", headerName: "Section", flex: 0.2, renderCell: (p) => (recommendationSections as any)?.[p.row.section] ?? "" },
        { field: "assessmentAxe", headerName: "Assessement topic", flex: 0.2, renderCell: (p) => (recommendationAxes as any)?.[p.row.assessmentAxe] ?? "" },
        { field: "reason", headerName: "Reason", flex: 0.2, renderCell: (p) => (recommendationReasons as any)?.[p.row.reason] ?? "" },
        { 
            field: "opt", 
            headerName: "", 
            sortable: false,
            disableColumnMenu: true,
            renderCell: (r) => (
                <>
                    <IconButton onClick={() => onClickEditRecommendation?.(r.row.id)}>
                        <BorderColorOutlinedIcon sx={{ fontSize: "18px", color: "#333" }}/>
                    </IconButton>
                    <IconButton onClick={() => onClickDeleteRecommendation?.(r.row.id)}>
                        <DeleteOutlineIcon sx={{ fontSize: "18px", color: "#333" }}/>
                    </IconButton>
                </>
            ) 
        },
    ]

    const getAllFiltersOptions = (): IFilter[] => {
        const reasonFilter: IFilter = {
            field: "reason",
            placeholder: "By reason",
            displayName: "Reason",
            options: Object.entries(recommendationReasons).flatMap(([value, label]) => value ? ({ value, label }) : []),
            valueMapper: recommendationReasons
        };
        const axesFilter: IFilter = {
            field: "assessmentAxe",
            placeholder: "By axe",
            displayName: "Axe",
            options: Object.entries(recommendationAxes).flatMap(([value, label]) => value ? ({ value, label }) : []),
            valueMapper: recommendationAxes
        };
        const sectionFilter: IFilter = {
            field: "section",
            placeholder: "By section",
            displayName: "Section",
            options: Object.entries(recommendationSections).flatMap(([value, label]) => value ? ({ value, label }) : []),
            valueMapper: recommendationSections
        };

        return [ reasonFilter, axesFilter, sectionFilter ]
    }

    return (
        <div className="recommendation-table-container">
            <UnboxableButton
            btnStyle={"black" as any}
            btnSize={"small" as any}
            className="add-recommendation-button"
            onClick={onClickAddRecommendationBtn}
            icon={plusIcon}
            {...addButtonProps}>
                New recommendation
            </UnboxableButton>
            <MaterialTable 
            columns={columsSettings} 
            loading={loading} 
            enumFilters={getAllFiltersOptions()} 
            emptyStateTitle={"No recommedations"} 
            originalData={filteredRecommendations}
            {...tableProps}/>
        </div>
    )
}

export default RecommendationsTable;