// React
import { useEffect, useState } from "react";

// Logic (part of)
import { useReview } from "./useReview";

// Parts
import ReportHeader from "./parts/ReportHeader/ReportHeader";
import CandidateNameAndTitle from "./parts/CandidateNameAndTitle/CandidateNameAndTitle";
import Category from "./parts/Category/Category";
import Skills from "./parts/Skills/Skills";

// Components
import Loading from "./Loading";
import SkillsTitleContent from "./parts/Skills/SkillsTitleContent";
import QualificationsAndSkillsOverall from "./parts/QualificationsAndSkillsOverall/QualificationsAndSkillsOverall";
import Qualifications from "./parts/Qualifications/Qualification";
import Highlights from "./parts/Highlights/Highlights";
import QuestionsAnswersSection from "./parts/QuestionsAnswersSection/QuestionsAnswersSection";
import TalentCompareHrm from "./parts/TalentCompareHrm";
import CommunicationStyle from "./parts/CommunicationStyle";
import ShareReportModal from "./parts/ShareReportModal/ShareReportModal";

// Icons
import reportLogoIcon from "./icons/report-logo.svg";

// Helpers
import { graphQueries } from "@/helpers";

// Query
import {
  getCandidateExperience,
  getCandidateInfoForReview,
  getComProfileForecast,
  getComProfileReview,
  getReferrerByCode,
} from "@queries/review";
import { GetV2PositionQuery } from "@/dal/graph-client/graphql-query";

// Router
import { useParams } from "react-router";
import { getUser } from "@/graphql/queries/users";

// Interfaces
import { IReview } from "@/interfaces/pages/reviewProcess";

import { MemoizedCvPdf } from "./parts/CvPdf/CvPdf";

// Local Interfaces
interface IReviewProcessURLParams {
  positionId: string;
  candidateId: string;
}

const ReviewProcess: React.FunctionComponent = () => {
  /**
   * States
   *
   */
  // Candidate communication profile and information for review
  const [candidate, setCandidate] = useState<any>({});
  const [qualifications, setQualifications] = useState<[]>([]);
  const [questionsAnswers, setQuestionsAnswers] = useState<[]>([]);
  const [skipedQuestions, setSkipedQuestions] = useState<[]>([]);
  const [skills, setSkills] = useState<[]>([]);
  const [skillSCore, setSkillScore] = useState<number>(0);
  const [qualificationSCore, setQualificationScore] = useState<number>(0);
  const [talentComProfile, setTalentComProfile] = useState<any>(null);
  const [fullName, setFullName] = useState<string | null>(null);
  // Communication profile forcast
  const [talentSurvivalModeBy, setTalentSurvivalModeBy] = useState<{} | null>(
    null
  );
  const [helpTalentBy, setHelpTalentBy] = useState<{} | null>(null);
  const [talentSummary, setTalentSummary] = useState<{} | null>(null);
  // Hiring manager communication profile
  const [hrmComProfile, setHrmComProfile] = useState<any>(null);
  // Candidate experience
  const [avgYears, setAvgYears] = useState<number | null>();
  const [totalYearsExp, setTolalYearsExp] = useState<number | null>();
  const [managementExp, setManagementExp] = useState<number | null>();
  const [historyHighlights, setHistoryHighlights] = useState<string | null>();
  const [lastJobTitle, setLastJobTitle] = useState<any>();
  const [linkedinUrl, setLinkedinUrl] = useState<string | null>(null);
  // General
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [positionName, setPositionName] = useState<string>();
  const [referrerName, setReferrerName] = useState<string | null>(null);
  const [assesStatus, setAssesStatus] = useState<string>();

  // Router
  const { candidateId, positionId }: IReviewProcessURLParams = useParams();

  // Part of the logic
  const { showShareModal, setShowShareModal }: IReview = useReview();

  // Auth
  // const { company }: IAuth = useAuth();

  /**
   * Get Candidate Communication Profile and Information for Review
   *
   */
  useEffect(() => {
    setIsLoading(true);
    graphQueries
      .sendRequest(getCandidateInfoForReview(""), { candidateId: candidateId })
      .then((data) => {
        setCandidate(data.candidate);
        setFullName(
          data.candidate.firstName
            ? `${data.candidate.firstName} ${data.candidate.lastName}`
            : null
        );
        setAssesStatus(
          data.candidate?.scoredQuestionResponse?.result[0]?.assessment?.status
        );
        setSkills(
          data.candidate?.scoredQuestionResponse?.result[0]?.report?.skillevals?.filter(
            (s: any) => s.evaluator?.eval_type !== "eligibility"
          )
        );
        setQualifications(
          data.candidate?.scoredQuestionResponse?.result[0]?.report?.skillevals?.filter(
            (s: any) => s.evaluator?.eval_type === "eligibility"
          )
        );
        setQuestionsAnswers(
          data.candidate?.scoredQuestionResponse?.result[0]?.report?.skillevals
        );
        setSkipedQuestions(data?.candidate?.skippedQuestionResponse);
        setSkillScore(
          data.candidate?.scoredQuestionResponse?.result[0]?.report?.score
        );
        setQualificationScore(
          data.candidate?.scoredQuestionResponse?.result[0]?.report?.skillevals.find(
            (s: any) => s.evaluator?.eval_type === "eligibility"
          )?.score
        );

        setIsLoading(false);

        if (data?.candidate?.isSharingProfile && data.candidate.comProfile) {
          graphQueries
            .sendRequest(getComProfileReview(""), {})
            .then(({ comprofile }) => {
              // console.log(comprofile);
              // console.log(data.candidate.comProfile);

              if (data.candidate?.comProfile) {
                const talentCp = comprofile.find(
                  (cp: any) => cp.profile === data.candidate.comProfile
                );
                // console.log(talentCp);

                setTalentComProfile(talentCp);
              }
            });
        }
      });
  }, []);

  /**
   * Get Hiring Manager Communication Profile
   *
   */
  useEffect(() => {
    if (positionId) {
      graphQueries
        .sendRequest(GetV2PositionQuery, { positionId: positionId })
        .then((data) => {
          // Get hrm email
          const hrmMail = data?.getV2Position[0]?.leaders?.filter(
            (l: any) => l.isHrm
          )[0]?.email;
          setPositionName(data?.getV2Position[0]?.title);

          if (hrmMail) {
            // Get company users
            graphQueries
              .sendRequest(
                getUser,
                {
                  "email": hrmMail,
                  "userId": null,
                },
                {}
              )
              .then(({ getUser: user }) => {

                // Get hrm comProfile
                if (user && user?.comProfile) {
                  graphQueries
                    .sendRequest(getComProfileReview(""), {})
                    .then(({ comprofile }) => {
                      const hrmCp = comprofile.find(
                        (cp: any) => cp.profile === user.comProfile
                      );

                      setHrmComProfile(hrmCp);
                    });
                }
              })
              .catch((e) => console.error(e));
          }
        })
        .catch((e) => console.error(e));
    }
  }, [positionId]);

  /**
   * Get Communication Profile Forcast
   *
   */
  useEffect(() => {
    if (talentComProfile && hrmComProfile) {
      graphQueries
        .sendRequest(getComProfileForecast, {
          comProfileOne: talentComProfile?.profile,
          comProfileTwo: hrmComProfile?.profile,
        })
        .then((data) => {
          setHelpTalentBy(data.getComProfileForecast?.helpYourTalentThriveBy);
          setTalentSurvivalModeBy(
            data.getComProfileForecast?.avoidSurvivalModeBy
          );
          setTalentSummary(data.getComProfileForecast?.summary);
        })
        .catch((e) => console.error(e));
    }
  }, [talentComProfile, hrmComProfile]);

  /**
   * Get Candidate Experience (CV, Linkedin)
   *
   */
  // useEffect(() => {
  //   if (candidate?.email) {
  //     graphQueries
  //       .sendRequest(getCandidateExperience, { email: candidate?.email })
  //       .then(({ getCandidateExperience }) => {
  //         setAvgYears(
  //           getCandidateExperience?.finalProcessedProfile
  //             ?.averageMonthsPerEmployer?.content
  //         );
  //         setManagementExp(
  //           getCandidateExperience?.finalProcessedProfile
  //             ?.monthsOfManagementExperience?.content
  //         );
  //         setTolalYearsExp(
  //           getCandidateExperience?.finalProcessedProfile
  //             ?.monthsOfWorkExperience?.content
  //         );
  //         setHistoryHighlights(
  //           getCandidateExperience?.finalProcessedProfile
  //             ?.employmentHistoryHighlight?.content
  //         );
  //         setLastJobTitle(
  //           getCandidateExperience?.finalProcessedProfile?.lastJobTitle?.title
  //         );
  //         setLinkedinUrl(getCandidateExperience?.linkedinUrl);
  //       })
  //       .catch((e) => console.error(e));
  //   }
  // }, []);

  /**
   * Get Hiring Manager Communication Profile
   *
   */
  useEffect(() => {
    graphQueries
      .sendRequest(GetV2PositionQuery, { positionId: positionId })
      .then((data) => {
        // Get hrm email
        const hrmMail = data?.getV2Position[0]?.leaders?.filter(
          (l: any) => l.isHrm
        )[0]?.email;
        setPositionName(data?.getV2Position[0]?.title);
        if (hrmMail) {
          // Get company users
          graphQueries
            .sendRequest(
              getUser,
              {
                "email": hrmMail,
                "userId": null,
              },
              {}
            )
            .then(({ getUsers: user }) => {

              // Get hrm comProfile
              if (user && user?.comProfile) {
                graphQueries
                  .sendRequest(getComProfileReview(""), {})
                  .then(({ comprofile }) => {
                    const hrmCp = comprofile.find(
                      (cp: any) => cp.profile === user.comProfile
                    );
                    
                    setHrmComProfile(hrmCp);
                  });
              }
            })
            .catch((e) => console.error(e));
        }
      })
      .catch((e) => console.error(e));
  }, []);

  /**
   * Get Communication Profile Forcast
   *
   */
  useEffect(() => {
    if (talentComProfile && hrmComProfile) {
      graphQueries
        .sendRequest(getComProfileForecast, {
          comProfileOne: talentComProfile?.profile,
          comProfileTwo: hrmComProfile?.profile,
        })
        .then((data) => {
          setHelpTalentBy(data.getComProfileForecast?.helpYourTalentThriveBy);
          setTalentSurvivalModeBy(
            data.getComProfileForecast?.avoidSurvivalModeBy
          );
          setTalentSummary(data.getComProfileForecast?.summary);
        })
        .catch((e) => console.error(e));
    }
  }, [talentComProfile, hrmComProfile]);

  /**
   * Get Candidate Experience (CV, Linkedin)
   *
   */
  useEffect(() => {
    if (candidate?.email) {
      graphQueries
        .sendRequest(getCandidateExperience, { email: candidate?.email })
        .then(({ getCandidateExperience }) => {
          setAvgYears(
            getCandidateExperience?.finalProcessedProfile
              ?.averageMonthsPerEmployer?.content
          );
          setManagementExp(
            getCandidateExperience?.finalProcessedProfile
              ?.monthsOfManagementExperience?.content
          );
          setTolalYearsExp(
            getCandidateExperience?.finalProcessedProfile
              ?.monthsOfWorkExperience?.content
          );
          setHistoryHighlights(
            getCandidateExperience?.finalProcessedProfile
              ?.employmentHistoryHighlight?.content
          );
          setLastJobTitle(
            getCandidateExperience?.finalProcessedProfile?.lastJobTitle
          );
          setLinkedinUrl(getCandidateExperience?.linkedinUrl);
        })
        .catch((e) => console.error(e));
    }
    if (candidate?.referrer) {
      graphQueries
        .sendRequest(getReferrerByCode, { code: candidate?.referrer })
        .then(({ getReferrer }) => {
          setReferrerName(getReferrer?.name);
        });
    }
  }, [candidate]);

  // const handleDownloadCv = (url: string | null, name: string | null) => {
  //   // Need cv field in candidate object
  //   if (url && name) {
  //     axios({
  //       url: url,
  //       method: "GET",
  //       responseType: "blob",
  //     })
  //       .then((response) => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", name);
  //         document.body.appendChild(link);
  //         link.click();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };

  let pageOrSpinner = <Loading />;
  if (!isLoading) {
    pageOrSpinner = (
      <div
        className="report-wrapper"
        style={{ width: "100%", backgroundColor: "#F8F3EE" }}
      >
        <div
          className="report-pdf"
          style={{
            padding: "20px 60px",
            margin: "auto",
            backgroundColor: "#F8F3EE",
            minHeight: "100vh",
            fontFamily: "Mulish",
            maxWidth: "90%",
          }}
        >
          {/* Report Header */}
          <ReportHeader
            candidatePersonalData={{
              phoneNumber: candidate?.phone,
              email: candidate?.email,
              referrer: candidate?.referrer,
              linkedinUrl: linkedinUrl,
              fullName: fullName ?? "Anonymous",
            }}
            refferrerName={referrerName}
            cvLink={candidate?.cv}
            // onDownloadCvClicked={() =>
            //   handleDownloadCv(candidate?.cv, candidate?.cvName || "CV.pdf")
            // }
            onReportPdfLinkClicked={() => setShowShareModal(true)}
          />
          {/* Candidate Name and Job Title */}
          {candidate?.firstName || positionName ? (
            <Category
              content={
                <CandidateNameAndTitle
                  fullName={fullName ?? "Anonymous"}
                  jobTitle={positionName ?? null}
                />
              }
            />
          ) : null}
          {/* Highlights */}
          {historyHighlights ||
          avgYears ||
          totalYearsExp ||
          managementExp ||
          lastJobTitle?.content ||
          lastJobTitle?.current ? (
            <Category
              title={"Highlights"}
              description={`Important information we gathered from ${
                candidate?.firstName?.length > 0
                  ? candidate?.firstName
                  : "the Talent"
              }'s CV`}
              content={
                <Highlights
                  candidateSummary={historyHighlights!}
                  avgYearsPerEmployer={avgYears!}
                  totalYearsExp={totalYearsExp!}
                  totalYearsManagementExp={managementExp!}
                  lastJobTitle={lastJobTitle || null}
                  firstName={candidate?.firstName}
                />
              }
            />
          ) : null}
          {/* Qualifications & Skills Progress Circles */}
          {(assesStatus === "ready" || assesStatus === "pending_review") ? (
            <Category
              content={
                <QualificationsAndSkillsOverall
                  qualificationScore={qualificationSCore}
                  skillsScore={skillSCore}
                />
              }
            />
          ) : null}
          {/* Qualifications */}
          {qualifications?.length > 0 && (assesStatus === "ready" || assesStatus === "pending_review") ? (
            <Category
              title={"Qualifications"}
              description={`How qualified is ${
                candidate?.firstName?.length > 0
                  ? candidate?.firstName
                  : "the Talent"
              }? Based on their Job Simulator answers`}
              content={<Qualifications qualifications={qualifications} />}
            />
          ) : null}
          {/* Skills */}
          {skills?.length > 0 && (assesStatus === "ready" || assesStatus === "pending_review") ? (
            <Category
              title={"Skills"}
              description={`How skilled is ${
                candidate?.firstName?.length > 0
                  ? candidate?.firstName
                  : "the Talent"
              }? Based on their Job Simulator answers`}
              extraTitleContent={<SkillsTitleContent />}
              content={<Skills skills={skills} />}
            />
          ) : null}
          <div className="communication-style-sections-for-print">
            {/* Talent Communication Style */}
            {talentComProfile && candidate?.isSharingProfile ? (
              <CommunicationStyle
                talentfirstName={candidate?.firstName}
                talentComProfile={talentComProfile}
              />
            ) : null}
            {/* Talent Compare to HRM */}
            {talentComProfile && hrmComProfile ? (
              <TalentCompareHrm
                talentComProfile={talentComProfile?.profile}
                hrmComProfile={hrmComProfile?.profile}
                talentfirstName={candidate?.firstName}
                helpTalentBy={helpTalentBy}
                avoidSurvivalModeBy={talentSurvivalModeBy}
                talentSummary={talentSummary}
              />
            ) : null}
          </div>
          {/* DeepDive (Questions and Answers) */}
          {questionsAnswers && (assesStatus === "ready" || assesStatus === "pending_review") ? (
            <QuestionsAnswersSection
              skills={questionsAnswers}
              skipedQustions={skipedQuestions}
            />
          ) : null}
          {/* Report Logo */}
          <img
            style={{ margin: "auto", marginTop: "100px" }}
            src={reportLogoIcon}
          />
          {candidate?.cv ? (
            <MemoizedCvPdf
              url={candidate?.cv}
            />
          ) : null}
          {/* Share Report Modal */}
          {showShareModal && (
            <ShareReportModal setShowShareModal={setShowShareModal} />
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={{ height: "-webkit-fill-available" }}>{pageOrSpinner}</div>
  );
};

export default ReviewProcess;
