import { IUser } from "@/auth/interfaces";
import { proxyDataService } from "@/graphql/mutations/proxyDataService";
import { getPositionsByCompanyId } from "@/graphql/queries/positions";
import { graphQueries } from "@/helpers";
import { IPosition, PositionSkill } from "@/interfaces/pages/positions";
import { EditorApi } from "@/pages/AssessmentEditor/data/editor.api";
import { isEmpty, throttle } from "lodash";
import env from "react-dotenv";

const COMPANY_ID = env.POSITIONBANK_ID
const ASSESS_STATUS = "ready"

const positionGraphRequest = async (query: {
    count?: number;
    name?: string;
    }) => {

    if (!COMPANY_ID) {
        throw new Error("Missing env variable POSITIONBANK_ID");
    }
    return graphQueries.sendRequest(getPositionsByCompanyId(""), {
        companyId: COMPANY_ID,
        sizeOfPage: query.count || 0,
        page: 0,
        titleName: query.name || "",
        assessmentStatus: ASSESS_STATUS
    })
}

export const getPositions = async (query: { count?: number; name?: string }): Promise<IPosition[] | undefined> => {
    try {
        const data = await positionGraphRequest(query)
        const { positions } = data?.getV2Positions || {}
        return positions || []
    } catch (e) {
        console.error(e)
    }
}

export const getRelevantSkillsByMatchingTitle = async (matchingTitle: string): Promise<PositionSkill[] | undefined> => {
    const getSkills: any = await graphQueries.sendRequest(proxyDataService, {
        path: "/hiring/sheet/jobs/job_skills?job=" + matchingTitle,
        method: "GET"
    });
    const { proxyDataService: data } = getSkills || {};

    return data?.[0].Skills?.map((s: string) => ({
        necessityLevel: "",
        proficiency: "",
        name: s,
    }))
}

export const getSimilarPositionTitles = async ( skills: string[], positionTitle: string): Promise<string[]> => {
    const { proxyDataService: response} = await graphQueries.sendRequest(proxyDataService, {
        path: `/hiring/sheet/positions/job_similarity`,
        method: "POST",
        body: {
            skills: skills,
            position: positionTitle
        }
    });

    const positionTitles = response?.map((ot: any) => ot.position) ?? [];
    
    return positionTitles;
}

export const generatePositionDefaultFieds = async (position: Partial<IPosition>, user: IUser): Promise<Partial<IPosition> | undefined> => {
    const { email: userEmail } = user || {};

    const buildingBlocks = await EditorApi.getBuildingBlocks();
    const filteredBuildingBlocks = buildingBlocks.length > 0 ? buildingBlocks.flatMap(bb => bb.id !== "mng" ? [bb.id!] : []) : []

    const { 
        id,
        matchingJobTypes, 
        matchingLanguages, 
        matchingYearsOfExperience,
        matchingOtherTitles,
        matchingYearsOfManagementExperience,
        matchingDepartment, 
        matchingBuildingBlocks, 
        matchingSkills, 
        matchingTitle,
        matchingRelatedSkills } = position || {};

    return {
        ...position,
        matchingTitle: matchingTitle ?? position.title ?? "",
        title: matchingTitle ?? position.title ?? "",
        matchingOtherTitles: matchingOtherTitles ?? [],
        matchingDepartment: matchingDepartment ?? "General",
        department: matchingDepartment ?? "General",
        matchingSkills: matchingSkills ?? [],
        matchingRelatedSkills: matchingRelatedSkills ?? [],
        matchingYearsOfExperience: matchingYearsOfExperience ?? 0,
        matchingYearsOfManagementExperience: matchingYearsOfManagementExperience ?? 0,
        matchingLanguages: !isEmpty(matchingLanguages) ? matchingLanguages : ["en"],
        matchingJobTypes: !isEmpty(matchingJobTypes) ? matchingJobTypes : ["FullTime"],
        matchingBuildingBlocks: !isEmpty(matchingBuildingBlocks) ? matchingBuildingBlocks : filteredBuildingBlocks as string[],
        matchingRecipients: [userEmail],
        matchingPermissions: [userEmail],
        atsEmail: "",
        matchingHrms: { name: "", email: userEmail },
        matchingTemplateId: id,
        leaders: [],
    }
}


export const searchPositions = throttle(async (name: string): Promise<string[]> => {
    const { proxyDataService: response } = await graphQueries.sendRequest(proxyDataService, {
        path: `/hiring/jobs/suggestions?query=${name}`,
        method: "GET",
    });

    const { data } = response || {};

    return data;
}, 2000)

export const _searchPositions = async (name: string): Promise<any>=> {
    const { proxyDataService: response } = await graphQueries.sendRequest(proxyDataService, {
        path: `/hiring/jobs/suggestions?query=${name}`,
        method: "GET",
    });

    const { data } = response || {};

    return data;
}

export interface IPositionDetails {
    CustomValues: string
    ESCO: {
        JobProfile: string, 
        Description: string , 
        AlternateLabels: string[], 
        Link: string, 
        RelatedSkills: {Skill: string, SkillType: string, RelationType: string, Link: string}[]
    },
    JobProfileData: {
        Abilities: string[],
        Alias: string[],
        CanadaNOC: {
            BroadOccupationCategory: string,
            MajorLevel: string,
            MinorLevel: string,
            NocCode: string,
            SkillLevel: string,
            Title: string,
        }
        Description: string,
        Education: string[],
        FormattedJobProfile: string,
        JobProfileOntology: string
        Knowledge: string[],
        Skills: {Skill: string, ProficiencyLevel: string, SkillType: string}[]
        Tasks: string
        WorkActivities: string[]
    }
    Language: string,
    Locale: string,
    ONet: any,
    SearchKeyword: string,
}

export const getPositionDetails = async (positionName: string): Promise<IPositionDetails> => {
    const { proxyDataService: response } = await graphQueries.sendRequest(proxyDataService, {
        path: `/hiring/jobs/details?query=${positionName}`,
        method: "GET",
    });

    const { data } = response || {};

    return data;
}