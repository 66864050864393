import './style.css';

const ContentCard: React.FunctionComponent<{
    title: string;
    children: JSX.Element | null | undefined;
    size?: "small" | "medium" | "max"
}> = ({title, children, size = "small"}): JSX.Element => {
    const sizeToClassName = {
        "small": "card-sm",
        "medium": "card-md",
        "max": "card-max"
    }
    return (
        <div className={`content-card ${sizeToClassName[size]}`}>
            <h3 className='content-card-title'>{title}</h3>
            {children}
        </div>
    )
}

export default ContentCard;