export interface IAssessmentsEvents {
	assessment_id: any; // Sql type: TEXT
	assessment_name: any; // Sql type: TEXT
	position_id: any; // Sql type: TEXT
	updated_by: any; // Sql type: TEXT
	updated_at: any; // Sql type: TEXT
	evaluate_skills: any; // Sql type: SUPER
	event: any; // Sql type: TEXT
	env?: any; // Sql type: TEXT
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	client_time?: any; // Sql type: TEXT
}
export interface IDiscoveryEvents {
	env?: any; // Sql type: TEXT
	event: any; // Sql type: TEXT
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	candidate_cookie: any; // Sql type: TEXT
	candidate_id: any; // Sql type: TEXT
	position_id: any; // Sql type: TEXT
	applied_at: any; // Sql type: TEXT
	apply_dialog: any; // Sql type: TEXT
	params: any; // Sql type: TEXT
	referral_id: any; // Sql type: TEXT
	template: any; // Sql type: TEXT
	client_time?: any; // Sql type: TEXT
}
export interface ICompaniesEvents {
	company_id: any; // Sql type: TEXT
	company_name: any; // Sql type: TEXT
	created_at: any; // Sql type: TIMESTAMP
	created_by: any; // Sql type: TEXT
	env?: any; // Sql type: TEXT
	event: any; // Sql type: TEXT
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	client_time?: any; // Sql type: TEXT
}
export interface ICompanyUsers {
	event: any; // Sql type: TEXT
	env?: any; // Sql type: TEXT
	user_id: any; // Sql type: TEXT
	company_id: any; // Sql type: TEXT
	is_admin: any; // Sql type: TEXT
	is_editor: any; // Sql type: TEXT
	is_leader: any; // Sql type: TEXT
	is_deleted: any; // Sql type: TIMESTAMP
	is_created: any; // Sql type: TIMESTAMP
	registered_with: any; // Sql type: TEXT
	last_visit: any; // Sql type: TIMESTAMP
	department: any; // Sql type: TEXT
	invited_by: any; // Sql type: TEXT
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	client_time?: any; // Sql type: TEXT
}
export interface IPositions {
	event: any; // Sql type: TEXT
	env?: any; // Sql type: TEXT
	position_id: any; // Sql type: TEXT
	company_id: any; // Sql type: TEXT
	position_name: any; // Sql type: TEXT
	published_by: any; // Sql type: TEXT
	update_by: any; // Sql type: TEXT
	update_at: any; // Sql type: TIMESTAMP
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	client_time?: any; // Sql type: TEXT
}
export interface ISurveyCvSubmissions {
	candidate_cookie: any; // Sql type: TEXT
	candidate_id: any; // Sql type: TEXT
	position_id: any; // Sql type: TEXT
	submit_type: any; // Sql type: TEXT
	survey_started: any; // Sql type: TIMESTAMP
	submitted_at: any; // Sql type: TIMESTAMP
	assess_page_number: any; // Sql type: BIGINT
	source: any; // Sql type: TEXT
	location: any; // Sql type: TEXT
	referral_id: any; // Sql type: TEXT
	params: any; // Sql type: TEXT
	event: any; // Sql type: TEXT
	env?: any; // Sql type: TEXT
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	client_time?: any; // Sql type: TEXT
	skip_reason?: any; // Sql type: TEXT
	question_id?: any; // Sql type: TEXT
	question_type?: any; // Sql type: TEXT
}
export interface IReferralEvents {
	env?: any; // Sql type: TEXT
	event: any; // Sql type: TEXT
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	client_time?: any; // Sql type: TEXT
	code: any; // Sql type: TEXT
	name: any; // Sql type: TEXT
	email: any; // Sql type: TEXT
}
export interface IPositionWizardEvents {
	session_id: any; // Sql type: TEXT
	user_id: any; // Sql type: TEXT
	position_id?: any; // Sql type: TEXT
	company_id?: any; // Sql type: TEXT
	screen: any; // Sql type: TEXT
	type: any; // Sql type: TEXT
	source: any; // Sql type: TEXT
	location?: any; // Sql type: TEXT
	event: any; // Sql type: TEXT
	env?: any; // Sql type: TEXT
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	client_time?: any; // Sql type: TEXT
}
export interface IComprofileEvents {
	session_id: any; // Sql type: TEXT
	user_id: any; // Sql type: TEXT
	screen: any; // Sql type: TEXT
	source: any; // Sql type: TEXT
	event: any; // Sql type: TEXT
	env?: any; // Sql type: TEXT
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	client_time?: any; // Sql type: TEXT
}
export interface ICompanyWizardEvents {
	user_id: any;
	screen: any; // Sql type: TEXT
	type: any; // Sql type: TEXT
	location?: any; // Sql type: TEXT
	event: any; // Sql type: TEXT
	env?: any; // Sql type: TEXT
	os?: any; // Sql type: TEXT
	browser?: any; // Sql type: TEXT
	timezone?: any; // Sql type: INTEGER
	client_time?: any; // Sql type: TEXT
}
export type AnalyticsTableRows = IAssessmentsEvents | IDiscoveryEvents | ICompaniesEvents | ICompanyUsers | IPositions | ISurveyCvSubmissions | IReferralEvents | IPositionWizardEvents | IComprofileEvents | ICompanyWizardEvents;
export interface LnrdAnalytics {
        send: (eventName: string, rows: AnalyticsTableRows[], sync?: boolean) => Promise<{ result: any }>;
        getAnalyticsInterfaceString: () => string;
        getBaseEvents: () => { client_time: any, timezone: any, env: any, os: any, browser: any };
        getTablesDescription: () => Promise;
}
      
export enum AnalyticsTables {
	ASSESSMENTS_EVENTS = 'assessments_events',
	DISCOVERY_EVENTS = 'discovery_events',
	COMPANIES_EVENTS = 'companies_events',
	COMPANY_USERS = 'company_users',
	POSITIONS = 'positions',
	SURVEY_CV_SUBMISSIONS = 'survey_cv_submissions',
	REFERRAL_EVENTS = 'referral_events',
	POSITION_WIZARD_EVENTS = 'position_wizard_events',
	COMPROFILE_EVENTS = 'hrm_comprofile_wizard_flow_events',
	COMPANY_WIZARD_EVENTS = 'company_wizard_events'
}