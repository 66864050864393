import { IPosition } from "@/interfaces/pages/positions"
import { FormikProps } from "formik"

export interface IWizardStep {
    formik: FormikProps<Partial<IPosition>>
}

export enum DiscoveryWizardStepsIds {
    GENERAL = 'general',
    SKILLS = 'skills',
    QUALIFICATIONS = 'qualifications',
    RNR = "r&r",
    DAYINLIFE = 'day_in_a_life',
    LEADERS = 'leaders',
    PREVIEW = 'preview'
}