import { useEffect, useState, useContext } from "react";
import { UnboxableButton } from "@/components";
import MaterialTable from "@/components/MaterialTable";
import Dropdown from "@/components/WizardComponents/Dropdown/Dropdown";
import Select from "react-select";
import TextField from "@/components/WizardComponents/TextField/TextField";
import { flatten } from "@/helpers";
import { Search } from "@mui/icons-material";
import { Dialog, MenuItem, Select as SelectMui ,SelectChangeEvent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { EditorApi } from "../../data/editor.api";
import { IAssessment, IQuestion, ISkillEval } from "../../data/types";
import { getThrottlePositionsAsSelectOptions, throttleSearchQuestion } from "./helpers";
import useLocalToast from "../../../../hooks/useLocalizedToast";

import './style.css';
import { SelectCustomStyles_wizard_bordered_white_scondary } from "@/components/UnboxableForm/ui/styles/select-custom";
import AppContext from "@/context/AppContext";
import LText from "@/components/LText";

const SearchQuestionsDialog: React.FunctionComponent<{
    openSearchDialog: boolean, 
    setOpenSearchDialog: (val: boolean) => void,
    positionId: string | null,
    currentAssessment: IAssessment,
    skillevalId: string
    setAssessmentToEdit: (se: ISkillEval) => void,
    setCurrentAssessment: (bb: IAssessment | null) => void,
}> = 
({ positionId, openSearchDialog, setOpenSearchDialog, currentAssessment, skillevalId, setAssessmentToEdit, setCurrentAssessment }): JSX.Element => {

    const { id: currentAssessmentId } = currentAssessment || {};

    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [isLoadingPosition, setIsLoadingPositions] = useState<boolean>(true)
    const [isLoadingBBoptions, setIsLodingBBOptions] = useState<boolean>(true);

    const allBBOption = { value: 'none', label: "All bbs" }
    const [allBBOptions, setAllBBOptions] = useState<{value: string, label: string}[]>([]);
    const allPositionsOption = { value: 'none', label: "All positions" }
    const [allPositionOptions, setAllPositionOptions] = useState<{value: string, label: string}[]>([]);

    const [searchVal, setSearchVal] = useState<string>("");
    const [selectedBuildingBlockType, setSelectedBuildingBlockType] = useState<{ label: string, value: string }>(allBBOption);
    const [selectedPosition, setSelectedPosition] = useState<{ label: string, value: string }>(allPositionsOption);

    const { toast } = useLocalToast();
    const { tr } = useContext(AppContext);

    useEffect(() => {
        setLoading(true)
        throttleSearchQuestion({ searchVal: searchVal, buildingBlockType: undefined, positionName: undefined })!
        .then((qs) => {
            setQuestions(qs);
        })
        .catch(console.error)
        .finally(() => setLoading(false))

        EditorApi.getBuildingBlocks()
        .then(bbs => {
            if(bbs) setAllBBOptions([allBBOption, ...bbs.map(bb => ({ value: bb.id!, label: bb.name! }))])
        })
        .finally(() => setIsLodingBBOptions(false))

        getThrottlePositionsAsSelectOptions(undefined)
        ?.then((positions) => {
            setAllPositionOptions([allPositionsOption, ...positions])
        })
        ?.finally(( ) => setIsLoadingPositions(false))

    }, [])

    const fetchAndSetSelectOptions = async (searchVal?: string) => {
        try {
            const options = await getThrottlePositionsAsSelectOptions(searchVal);
            return options;
        }
        catch(e) {
            console.error(e)
        }
    }

    const onBuildingBlockDropdownChanged = (e: SelectChangeEvent<unknown>) => {
        const value = e.target.value;
        const foundOption = allBBOptions.find(bb => bb.value === value)
        setSelectedBuildingBlockType(foundOption!)
    }

    const onPositionDropdownChanged = (newValue: any, actionMeta: any) => {
        const { action } = actionMeta || {};
        
        const isInputChanged = action === 'input-change';
        const isOptionSelected = action === 'select-option';
        
        if(isInputChanged)
            setIsLoadingPositions(true)
            fetchAndSetSelectOptions(newValue)
            ?.then((opts) => {
                setAllPositionOptions(() => [allPositionsOption, ...(opts ?? [])]); 
            })
            ?.catch(console.error)
            ?.finally(() => setIsLoadingPositions(false))
        
        if(isOptionSelected)
            setSelectedPosition(newValue)
    }


    const onClickSearch = () => {
        const buildingBlockType = selectedBuildingBlockType.value !== 'none' ? selectedBuildingBlockType.value : undefined;
        const position = selectedPosition.value !== 'none' ? selectedPosition.value : undefined;

        setLoading(true)
        throttleSearchQuestion({ searchVal: searchVal, buildingBlockType: buildingBlockType, positionName: position })!
        .then((qs) => {        
            setQuestions(qs);
        })
        .catch(console.error)
        .finally(() => setLoading(false))
    }

    const popupMessage = (msg: string, type?: 'success' | 'error' | 'info') => {
        toast(msg, { containerId: "default", type })
    }

    const copyToClipboard = async (item: any): Promise<void> => {
        const text = JSON.stringify(item);
        
        return await navigator.clipboard.writeText(text)
    }

    const copyQuestionToClipboard = (question: IQuestion) => {
        const { surveyjs_question } = question || {};
        const { type } = surveyjs_question || {};
        if(!type) {
            toast("Cannot copy to clipboard", { containerId: "error" })
            return
        }
        const questionToCopy = {
            ...surveyjs_question,
            name: type,
        }        
        copyToClipboard(questionToCopy)
        ?.then(() => popupMessage("Copied to clipboard", 'success'))
    }

    const getQuestoinsFromAssessment = (assessment: IAssessment) => {
        const { skillevals } = assessment || {};
        const { content } = skillevals[0] as any || {};
        const { pages } = content || {};
        if(!pages){
            return;
        }
        const questionsMatrix = pages.map((page: {elements: any[]}) => page?.elements)
        const questions = flatten(questionsMatrix).filter(q => q !== undefined)
        const formattedQuestions = questions.map((question: any) => ({ ...question, name: question?.type }))
        return formattedQuestions
    }

    const copyBuildingBlockToClipboard = async (question: IQuestion) => {
        const { assessment_id } = question || {};
        
        if(!assessment_id || !positionId)
            return
        
        const foundAssessment = await EditorApi.getAssessment({ id: assessment_id })
        const { id } = foundAssessment?.[0] || {};
        if(!id)
            return

        const questions = getQuestoinsFromAssessment(foundAssessment[0]);
        
        copyToClipboard(questions)
        ?.then(() => popupMessage("Copied to clipboard", 'success'))
    }

    const insertQuestionToCurrentAssessmentSkilleval = (questions: IQuestion[]) => {
        if(!currentAssessmentId) return;

        EditorApi.postQuestionToAssessment(currentAssessmentId, skillevalId, questions)
        .then((res) => {
            const updatedBuildingBlock = res[0]
            const { id } = updatedBuildingBlock || {};
            if(!id) {
                popupMessage("Failed to add question to assessment", "error")
                return
            }
            setCurrentAssessment(updatedBuildingBlock);
            const assessToEdit = updatedBuildingBlock.skillevals?.find(se => se.id === skillevalId)
            setAssessmentToEdit(assessToEdit || {} as ISkillEval)
            popupMessage("Question added successfully to current assessment", "success")
        })
        .catch(console.error)
    }

    const dropdownData = [
        {  
            text: "Insert qustion", 
            onClick: (q: any) => insertQuestionToCurrentAssessmentSkilleval([q])
        },
        {
            text: "Copy question to clipboard",
            onClick: copyQuestionToClipboard
        },
        {
            text: "Copy entire BB to clipboard",
            onClick: copyBuildingBlockToClipboard
        }
    ];

    const columns: GridColDef[] = [
        {
            field: "surveyjs_question.title",
            headerName: "QUESTION & ANSWERS",
            width: 250,
            renderCell: (params) => (
                <span>{params.row?.surveyjs_question?.title}</span>
            )
        },
        {
            field: "position_name",
            headerName: "POSITION",
            flex: 1,
        },
        {
            field: "group_id",
            headerName: "BB",
            flex: 1,
            renderCell: (params) => params.row?.group_id
        },
        {
            field: "surveyjs_question.type",
            headerName: "Q TYPE",
            flex: 1,
            renderCell: (params) => (
                <span>{params.row?.surveyjs_question?.type}</span>
            )
        },
        {
            field: "createdAt",
            headerName: "MODIFIED",
            flex: 1,
            type: 'date',
            valueFormatter: (params: any) => {
                // first converts to JS Date, then to locale option through date-fns
                return new Date(params?.value)
            },
            // valueGetter for filtering
            valueGetter: (params) => {
                return new Date(params?.value)
            },
            renderCell: (params: any) => {
            
            return (
            <div>
                <div>{params.value != "Invalid Date" ? format(new Date(params.value), "LLLL d, yyyy") : "Invalid Date"}</div>
                <div className="mt-3 text-xs text-gray-400">{params.value != "Invalid Date" ? format(new Date(params.value), "hh:mm aa") : "Invalid Date"}</div>
            </div>
            )
            }
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            width: 180,
            renderCell: (params) => (
                <SelectMui
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={"placeholder"}
                >   
                    <MenuItem value={"placeholder"} disabled style={{display: "none"}}>
                        {"actions"}
                    </MenuItem>
                    {dropdownData.map(dd => (
                        <MenuItem key={dd.text} onClick={() => dd.onClick?.(params.row)}>
                            {tr(dd.text)}
                        </MenuItem>
                    ))}
                </SelectMui>
            )
        }
    ]

    return (
        <Dialog 
        open={openSearchDialog}
        onClose={() => setOpenSearchDialog(false)}
        sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "80%",
                    minHeight: "90vh",
                    padding: "16px"
                },
            },
        }}>
            <h3>
            {tr("Search")}
            </h3>
            <div className="search-questions-dialog-filters-section">
                <TextField
                uiType="white-bordered"
                className="custom-search-input"
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                placeholder={`${tr("Search")}...`} />
                <Dropdown 
                className="custom-search-dropdown"
                uiType="white-bordered"
                value={selectedBuildingBlockType.value}
                onChange={onBuildingBlockDropdownChanged}
                options={allBBOptions}/>
                <Select
                className="custom-select-style"
                styles={SelectCustomStyles_wizard_bordered_white_scondary}
                options={allPositionOptions}
                // inputValue={""}
                isOptionSelected={(o, sv) => (sv && sv[0] && sv[0].value === o.value) ? true : false}
                value={selectedPosition}
                isLoading={isLoadingPosition}
                placeholder={tr("Search for other simulations")}
                getOptionValue={(o: any) => o.title} 
                onInputChange={onPositionDropdownChanged}
                onChange={onPositionDropdownChanged}
                />
                <UnboxableButton 
                onClick={onClickSearch}
                className="custom-search-button">
                    <Search/>
                    <LText text="Search" />
                </UnboxableButton>
            </div>
            <MaterialTable 
            columns={columns} 
            loading={loading || isLoadingBBoptions} 
            emptyStateTitle={tr("No Questions found")} 
            originalData={questions}/>
        </Dialog >
    )
}

export default SearchQuestionsDialog;