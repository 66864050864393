export const GetV2PositionQuery = `query Query($positionId: UUID, $sizeOfPage: Int, $slug: String, $page: Int) {
  getV2Position(positionId: $positionId, sizeOfPage: $sizeOfPage, slug: $slug, page: $page) {
    CompanyId
    id
    assessmentId
    assessmentName
    assessmentStatus
    department
    title
    description
    yearsOfExperience
    yearsOfManagementExperience
    status
    skills {
      necessityLevel
      proficiency
      name
    }
    education {
      degree
      educationType
      scale
    }
    dayInALife {
      description
      time
    }
    positionFormJson
    cultureAndValue {
      value
      belief
    }
    recipients
    atsEmail
    metadata
    rolesAndResponsibilities
    eligibilities
    isPublished
    leaders
    positionImage
    slug
    comProfile
    location
    jobTypes
    templates
    educations
    qualifications
    isManager
    languages
    hobbies
    volunteering
    matchingOtherTitles
    matchingYearsOfManagementExperience
    matchingYearsOfExperience
    matchingTitle
    matchingDescription
    matchingDepartment
    matchingSkills
    matchingEducation
    matchingEligibilities
    matchingLocation
    matchingBuildingBlocks
    matchingLanguages
    matchingHobbies
    matchingVolunteering
    matchingRecipients
    matchingHrms
    matchingCvKeywords
    matchingJobTypes
    matchingPermissions
    matchingRelatedSkills
    matchingTemplateId
  }
}`