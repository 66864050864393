import AppContext from "@/context/AppContext";
import React, { useContext, useRef, useEffect } from "react";

export const EditTemplateComponent: React.FunctionComponent<{ 
    children?: React.ReactChild, 
    title?: string | React.ReactChild,
    className?: string,
    editText?: string, 
    titleWrapperClassName?: string, 
    onEditClicked?: () => any, 
    onClickOutside?: () => any,
    open?: boolean
}> = ({ 
    children, titleWrapperClassName, className, onEditClicked, editText = "Edit", onClickOutside, title, open
}): JSX.Element => {
    const ref = useRef(null);
    const { tr } = useContext(AppContext);

    useEffect(() => {
        if(!open || !onClickOutside) return;
        const handleClickOutside = (event: any) => {
            if (ref.current && !(ref?.current as any)?.contains(event.target)) {
            onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ onClickOutside ]);

    return (
        <div ref={(onClickOutside && open) ? ref : undefined} className={className}>
            <div className={`edit-template-component-container ${titleWrapperClassName ?? ""}`}> 
                {title}
                <span className="review-step-edit-link" onClick={() => onEditClicked?.()}>{tr(editText)}</span>
            </div>
            <div>
                {children}
            </div>
        </div>
    )
}