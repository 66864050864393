// Parts
import { QuestionCollection } from '@/models/formPage'
import Question from '@components/WizardForm/components/Question'

// Local Interfaces
interface QuestionsListProps {
  questions: QuestionCollection
  pageIndex: number
  isCustom?: boolean
}

/**
 *
 *  Questions List
 *  @description show a list of questions
 *
 */
const QuestionsList: React.FunctionComponent<QuestionsListProps> =
  ({ questions, pageIndex, isCustom=false }): React.ReactElement => {
    if (!questions || questions.some(qs => qs?.some(q => !q))) {
        console.warn("null question")
    }   
    return (
      <div className="wiz-text-md min-w-full">
        { questions && questions.map((q: any, i: number) => (
          <div key={i} className="w-full">
            {(isCustom && i === 0) && <hr className="border-gray-300 my-5"/>}
            <Question
              question={q}
              key={i}
              index={i}
              pageIndex={pageIndex}
              isCustom={isCustom}
            />
            {(i !== questions.length - 1) && <hr className="border-gray-300 my-5"/>}
          </div>
          
        )) }
      </div>
    )
  }

export default QuestionsList
