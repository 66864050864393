import { ControlledPromise } from "./controlledPromise"

/**
 * Describes a single transaction
 */
interface IOneTransactionRecord {
	readonly key: string
	readonly errors: string[]
}

/**
 * TransactionManager promises resolve to this object, which describes the accumulated results of all the 
 * transactions that were batched, completed and not reported yet
 */
export interface ITransactionResults {
	/**
	 * All the transactions that are part of this result
	 */
	readonly results: IOneTransactionRecord[]
	/**
	 * Quick lookup if this transaction had any errors
	 */
	readonly hasErrors: boolean
	/**
	 * Returns an array of errors produced by  this transaction 
	 */
	getAllErrors(): string[]
}

export interface IMultiTransactionManager {
	/**
	 * Returns a promise that resolves when this manager has no open transactions
	 */
	done(): Promise<ITransactionResults>
	readonly name: string
}

/**
 * Implements the core logic of a transaction manager. The actual transactions are created by subclasses
 */
export abstract class MultiTransactionManager implements IMultiTransactionManager {
	constructor(public readonly name: string) { }
	private readonly _openPromises: Array<ControlledPromise<ITransactionResults>> = []
	private readonly _openTransactions: { [key: string]: OneTransactionResult } = {}
	private readonly _doneTransctions: { [key: string]: OneTransactionResult } = {}

	/**
	 * Returns the number of open transactions
	 */
	public get transactionCount(): number {
		return Object.keys(this._openTransactions).length
	}

	public done(): Promise<ITransactionResults> {
		if (this.transactionCount === 0) {
			return Promise.resolve(new TransactionResults([]))
		}
		const p = new ControlledPromise<ITransactionResults>()
		this._openPromises.push(p)
		return p.promise
	}

	private _seed = Date.now() % 10000

	/**
	 * Creates and returns a new transaction record with a new key
	 */
	protected addTransaction(): IOneTransactionRecord {
		const key = `transaction-${this.name}-${this._seed++}`
		const ret = new OneTransactionResult(key)
		this._openTransactions[key] = ret
		return ret
	}

	/**
	 * Remove the transaction with the specified key from the open transactions queue
	 * 
	 * Resolve all the pending promises, if the queue has just been emptied
	 * @param key The key of the transaction
	 * @param errors Must be defined, even if empty
	 */
	protected onTransactionDone(key: string, errors: (string | null)[]): void {
		const rec = this._openTransactions[key]
		if (!rec) {
			console.warn(`remove transaction: unkown key ${key}`)
			return
		}
		// don't save empty errors
		rec.errors = (errors).filter(Boolean) as string[]
		this._doneTransctions[key] = rec
		delete this._openTransactions[key]

		if (this.transactionCount === 0) {
			const done = Object.values(this._doneTransctions)
			Object.keys(this._doneTransctions).forEach(key => delete this._doneTransctions[key])
			this.resolveAll(new TransactionResults(done))
		}
	}

	/**
	 * Resolve all pending promises
	 * @param result 
	 */
	protected resolveAll(result: ITransactionResults): void {
		const promises = this._openPromises.splice(0, this._openPromises.length)
		promises.forEach(p => p.resolve(result))
	}

	/**
	 * Reject all pending promises
	 * @param result 
	 */
	protected rejectAll(error: unknown): void {
		const promises = this._openPromises.splice(0, this._openPromises.length)
		promises.forEach(p => p.reject(error))
	}

}

class OneTransactionResult implements IOneTransactionRecord {
	constructor(public readonly key: string) {

	}
	public errors: string[] = []
	public get hasErrors() {
		return this.errors.length > 0
	}
}

class TransactionResults implements ITransactionResults {
	constructor(public results: IOneTransactionRecord[]) {

	}
	public get hasErrors(): boolean {
		return this.results.some(r => r.errors && r.errors.length > 0)
	}
	public getAllErrors(): string[] {
		return this.results.map(r => r.errors).filter(a => a && a.length).map(a => a.join(','))
	}

}