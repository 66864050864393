export const requestEditor = `
  mutation requestEditor(
    $requestToEditorRequestType: String,
    $requestToEditorRequestPath: String,
    $requestToEditorParams: JSON
  ) {
    requestToEditor(
      requestType: $requestToEditorRequestType,
      requestPath: $requestToEditorRequestPath,
      params: $requestToEditorParams
    ) {
      response
    }
  }
`