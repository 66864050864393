import { useEffect, useState } from "react";
import { RecommendationEditor, RecommendationsTable } from "@/pages/Recommendations/components";
import useRecommendations from "@/pages/Recommendations/useRecommendations";

import './style.css';
import WarningMdoal from "@/components/WizardComponents/WarningModal/WarningModal";
import Modal from "@/components/WizardComponents/Modal/Modal";
import { IRecommendation } from "@/pages/Recommendations/data/types";
import { useQuery } from "@/routes/helpers";
import useLocalToast from "../../../../hooks/useLocalizedToast";

const MyRecommendations: React.FunctionComponent<{ companyId?: string, userId?: string }> = ({
    companyId, userId
}): JSX.Element => {

    const { 
        recommendations, 
        isLoadingRecommendations,
        deleteRecommendation,
        createRecommendation,
        updateRecommendation} = useRecommendations({ companyId });
    
    const [openRecommendationEditor, setOpenRecommendationEditor] = useState<boolean>(false);
    const [isRecommendationEditorInEditMode, setIsRecommendationEditorInEditMode] = useState<boolean>(false);
    const [recommendationToEdit, setRecommendationToEdit] = useState<string | undefined>(undefined);
    
    const [ showDeleteWarningModal, setShowDeleteWarningModal ] = useState<boolean>(false);
    const [ recommendationToDelete, setRecommendationToDelete ] = useState<string | undefined>(undefined);
    const { toast } = useLocalToast();

    const query = useQuery();
    useEffect(() => {
        const { openEditor } = query || {};
        if(openEditor) {
            setOpenRecommendationEditor(true)
        }
    }, [])

    const onClickDeleteRecommendation = (recommendationId: string) => {
        setRecommendationToDelete(recommendationId)
        setShowDeleteWarningModal(true)
    }

    const onApproveRecommendationDeletion = () => {
        setShowDeleteWarningModal(false);
        if(!recommendationToDelete) return alert("Recommendation Id is missing");
        deleteRecommendation({ reportRecommendationId: recommendationToDelete})
        .then(() => {
            toast("Recommendation deleted", { containerId: "default", type: "success" })            
        });
    }

    const onClickAddRecommedation = () => {
        setIsRecommendationEditorInEditMode(false);
        setOpenRecommendationEditor(true);
    }
    
    const onClickEditRecommendation = (recommendationId: string) => {
        setIsRecommendationEditorInEditMode(true);
        setRecommendationToEdit(recommendationId);
        setOpenRecommendationEditor(true);
    }

    const onSaveRecommendation = (recommendation: IRecommendation, isEditMode?: boolean) => {
        if(!companyId) {
            toast(`Failed to ${ isEditMode ? "update" : "create" } recommendation`, { containerId: "default", type: "error" } )
            return
        }
        const { id } = recommendation;
        const mode = isEditMode ? "edit" : "create";
        const funcToExecute = {
            "edit": async () => await updateRecommendation({ reportRecommendationId: id!, ...recommendation }),
            "create": async () => await createRecommendation({ ...recommendation, companyId, userId })
        }

        funcToExecute[mode]()
        .then(() => {
            toast(`Recommendation ${ isEditMode ? "updated" : "created" } successfully`, { containerId: "default", type: "success" } )
        })
        .catch(() => {
            toast(`Failed to ${ isEditMode ? "update" : "create" } recommendation`, { containerId: "default", type: "error" } )
        })
        .finally(() => {
            setOpenRecommendationEditor(false)
        })
    }

    return (
        <>
            <RecommendationsTable 
            loading={isLoadingRecommendations} 
            recommedations={recommendations}
            onClickEditRecommendation={onClickEditRecommendation}
            onClickDeleteRecommendation={onClickDeleteRecommendation}
            onClickAddRecommendationBtn={onClickAddRecommedation}/>

            <Modal 
            openModal={openRecommendationEditor} 
            onClose={() => setOpenRecommendationEditor(false)}>
                <RecommendationEditor
                isOpenedFromHrCorner={true}
                companyId={companyId}
                isEditMode={isRecommendationEditorInEditMode}
                recommendationToEdit={recommendations.find(r => r.id === recommendationToEdit)}
                onSubmit={(r) => onSaveRecommendation(r as IRecommendation, isRecommendationEditorInEditMode) }/>
            </Modal>

            <WarningMdoal
            warningMessage="Are you sure you want to continue? this action will delete the specified recommendation."
            showModal={showDeleteWarningModal}
            onClose={() => setShowDeleteWarningModal(false)}
            onApprove={onApproveRecommendationDeletion}/>
        </>
    )
}

export default MyRecommendations;