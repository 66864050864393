import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { getCandidateName } from '@/helpers';
import { ICandidate } from '@/pages/Talents/interfaces';
import { HtmlTooltip } from '../../WizardComponents/CustomTooltip/CustomTooltip';
import './style.css';
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
// import { Pagination } from '@mui/material';
import usePagination from '@/hooks/usePagination';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

export enum RelevencyCardType {
    PRIMARY = "primary",
    SECONDARY = "secondary"
}

const RelevencyCard: React.FunctionComponent<{
    type?: RelevencyCardType
    title: string;
    subTitle?: string;
    icon: string;
    candidates: ICandidate[]; 
    noCandidatesMessage: string;
    showPositionName?: boolean;
    loading ?: boolean;
    withPositionTitles?: boolean;
    tooltip?: string;
    onNameClicked?: (candidate: ICandidate) => any;
    downloadIconProps?: {
        onClick: (candidate: ICandidate) => any,
        show: (candidate: ICandidate) => boolean
    };
    CVIconProps?: {
        onClick: (candidate: ICandidate) => any,
        show: (candidate: ICandidate) => boolean
    };
    reportIconProps?: {
        onClick: (candidate: ICandidate) => any,
        show: (candidate: ICandidate) => boolean
    };
}> = ({ 
    title, 
    subTitle,
    icon, 
    candidates, 
    type = "primary", 
    noCandidatesMessage, 
    tooltip,
    showPositionName,
    downloadIconProps,
    reportIconProps,
    loading,
    CVIconProps,
    onNameClicked,
}): JSX.Element => {
    const classesByType: any = {
        [RelevencyCardType.PRIMARY]: {
            "bg_calss":"recommendation-card-bg-white",
            "color_class":"recommendation-card-color-black",
            "hex_color": "#33333370",
            "loadingClass": "recommendation-card-loading-primary",
            "paginationNavBtnActive": "pagination-nav-btn-selected-primary",
            "paginationNavBtn": "pagination-nav-btn-primary",
        },
        [RelevencyCardType.SECONDARY]: {
            "bg_calss": "recommendation-card-bg-orange",
            "color_class": "recommendation-card-color-white",
            "hex_color": "#FFFF",
            "loadingClass": "recommendation-card-loading-secondary",
            "paginationNavBtnActive": "pagination-nav-btn-selected-secondary",
            "paginationNavBtn": "pagination-nav-btn-secondary",
        }
    }

    const classes = classesByType[type];

    const iconsStyle ={ 
        color: classes.hex_color, 
        width: "16px", 
        height: "16px", 
        cursor: "pointer"
    }

    const MAX_PER_PAGE = 5;

    const { maxPage, getDataByPage } = usePagination(candidates, MAX_PER_PAGE)

    return (
        <div className={`recommendation-card ${classes.bg_calss}`}>
            <HtmlTooltip
            placement='top'
            title={tooltip ? <p className='recommendation-card-tooltip-text'>{tooltip}</p>: false}>
                <div className={`recommendation-card-headers ${classes.color_class}`}>
                    <img src={icon}/>
                    <h1>
                    {title}
                    {subTitle &&
                    <span>{subTitle}</span>}
                    </h1>
                </div>
            </HtmlTooltip>
            <Swiper 
            spaceBetween={18}
            pagination={{
                clickable: true,
                horizontalClass: "pagination-nav-container",
                bulletActiveClass: classes.paginationNavBtnActive,
                // bulletClass:  candidates.length > maxPage ? `pagination-nav-btn ${classes.paginationNavBtn}` : "pagination-nav-btn-hide",
                dynamicBullets: true,
                dynamicMainBullets: 3,
            }} 
            loop={true}
            initialSlide={1}
            modules={[Pagination]}
            className='candidates-swiper-container'>
                {Array(maxPage).fill(0).map((p,i) => (
                <SwiperSlide key={`${p}-${i}`} style={{ height: "auto" }}>
                    <ul className={`recommendation-card-candidates-list ${classes.color_class}`}>
                        {candidates && candidates.length > 0 && !loading &&
                        getDataByPage(i+1).map((c, i)=> (
                            <li key={i}>
                                <div className='recommendation-card-candidates-list-single'>
                                    <span onClick={() => onNameClicked?.(c)}>
                                        {`${getCandidateName(c.firstName!, c.lastName!)}`}
                                        &nbsp;
                                        {showPositionName && c.v2PositionTitle && 
                                        <span className='recommendation-card-position-name'>({c.v2PositionTitle})</span> }
                                    </span>
                                    <div className='recommendation-card-candidates-list-row-buttons'>
                                        {downloadIconProps?.show?.(c) &&
                                        <HtmlTooltip
                                        className='customtooltip-border'
                                        placement='top'
                                        title={<p className='tooltip-icon-btns'>Download</p>}>
                                            <FileDownloadOutlinedIcon 
                                            onClick={() => downloadIconProps?.onClick?.(c)}
                                            style={iconsStyle}/>
                                        </HtmlTooltip>}

                                        {CVIconProps?.show?.(c) &&                            
                                        <HtmlTooltip
                                        className='customtooltip-border'
                                        placement='top'
                                        title={<p className='tooltip-icon-btns'>Open CV</p>}>
                                        <DescriptionOutlinedIcon 
                                        onClick={() => CVIconProps?.onClick?.(c)}
                                        style={iconsStyle}/>
                                        </HtmlTooltip>}

                                        {reportIconProps?.show?.(c) &&
                                        <HtmlTooltip
                                        className='customtooltip-border'
                                        placement='top'
                                        title={<p className='tooltip-icon-btns'>Open report</p>}>
                                        <AssessmentOutlinedIcon 
                                        onClick={() => reportIconProps?.onClick?.(c)}
                                        style={iconsStyle}/>
                                        </HtmlTooltip>}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </SwiperSlide>
                ))}
            </Swiper>

            {(!candidates || !candidates.length) 
            && (noCandidatesMessage && !loading) &&
            <div className='no-candidates-message-container'>
                <span 
                className={`no-candidates-message ${classes.color_class}`}>
                    {noCandidatesMessage}
                </span>
            </div>}

            {loading &&
            <LinearProgress className={`reccomendation-card-loading ${classes.loadingClass}`}/>}
        </div>
    )
}

export default RelevencyCard;