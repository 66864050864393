import { useContext, useEffect, useState } from "react";
import ComponentStepper from "@/components/WizardComponents/ComponentsStepper";
import { FormikProps, useFormik } from "formik";
import { useHistory, useLocation, useParams } from "react-router";
import { DiscoveryWizardStepsIds } from "./interfaces";
import * as Yup from 'yup';
import { PositionClient } from "@/dal/position-client";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { DayInALifeStep, GeneralStep, LeadersStep, PreviewModal, PreviewStep, QualificationsStep, RolesAndResponsibilitiesStep, SkillsStep } from "./wizardSteps";
import { IPosition } from "@/interfaces/pages/positions";
import { PositionTemplates } from "@/dal/position.interface";
import { throttleUpdatePosition } from "../helpers";
import { useAuth } from "@/auth";
import { IPage } from "@/components/WizardComponents/ComponentsStepper/interfaces";

import './style.css';
import { getJobSimPositionNewUrl, validateEmailandDomain } from "@/helpers";
import AuthContext from "@/auth/context";
import { dayInALifeOptions } from "./wizardSteps/DayInALifeStep";
import AppContext from "@/context/AppContext";

const getDiscoveryInitialValues = (position: Partial<IPosition>): Partial<IPosition> => {
    const { 
        title,
        yearsOfExperience,
        yearsOfManagementExperience, 
        description,
        department,
        location,
        skills,
        jobTypes,
        eligibilities,
        rolesAndResponsibilities,
        dayInALife,
        leaders,
        templates
    } = position;

    return {
        ...position,
        title: title || '',
        yearsOfExperience: yearsOfExperience || 0,
        yearsOfManagementExperience: yearsOfManagementExperience || 0,
        description: description || '',
        department: department || '',
        location: location || '',
        jobTypes: jobTypes || "",
        skills: !isEmpty(skills) ? skills : [],
        eligibilities: !isEmpty(eligibilities) ? eligibilities : ["", "", "", "", ""],
        rolesAndResponsibilities: !isEmpty(rolesAndResponsibilities) ? rolesAndResponsibilities : ["", "", "", "", ""],
        dayInALife: !isEmpty(dayInALife) ? dayInALife : dayInALifeOptions.map(dil => ({ time: dil.value, description: undefined })),
        leaders: !isEmpty(leaders) ? leaders : [{}],
        templates: !isEmpty(templates) ? templates : PositionTemplates.Classic,
    } as Partial<IPosition>
}

const DiscoveryWizardSchema = Yup.object().shape({
    title: Yup.string().min(1).required("Title is required field")
})

const DiscoveyWizard: React.FunctionComponent = (): JSX.Element => {
    
    const { user, company } = useAuth();
    const { settings } = useContext(AuthContext);
    const { localizer, locale, tr } = useContext(AppContext);
    const history = useHistory();
    const { search } = useLocation();
	const query = new URLSearchParams(search);

    const { positionId: position_id } = useParams<{positionId: any }>();
    
    const [source] = useState<string>(query.get("source") ?? "direct");
    

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    const [discoveryInitialValues, setDiscoveryInitialValues] = useState<Partial<IPosition>>({});

    const formik: FormikProps<Partial<IPosition>> = useFormik<Partial<IPosition>>({
        initialValues: discoveryInitialValues,
        enableReinitialize: true, // Enable re-set the initial values
        validationSchema: DiscoveryWizardSchema,
        validateOnMount: true,
        onSubmit: console.log,
    });

    const { values: discoveryData, errors, isValid: isDiscoveryDataValid  } = formik;
    const [lastDiscoveryData, setLastDiscoveryData] = useState<Partial<IPosition>>(discoveryData);

    useEffect(() => {
        setIsLoading(true)
        PositionClient.read({ positionId: position_id })
        .then((p) => {
            if(!p){
                history.push("/positions")
            }
            setLastDiscoveryData({...p as any})
            setDiscoveryInitialValues(getDiscoveryInitialValues(p as any))
        })
        .catch(() => {
            history.push("/positions")
            toast(tr("Error fetching position data"), {containerId: "default"})
        })
        .finally(() => setIsLoading(false))
    }, [])

    /**
     * Handle form change globally
     */
    useEffect(() => {
        const { id } = discoveryData || {};
        if(!id){
            return
        }
        
        if(!isDiscoveryDataValid || isLoading) {
            return
        }
        
        setLastDiscoveryData({...discoveryData})

        throttleUpdatePosition({data: discoveryData, lastPosition: lastDiscoveryData, user, company, id: position_id, source})
        
    }, [discoveryData])

    const needDisableNextStep = (): boolean => {
        if(!isLoading || !position_id) return true;
        
        if(!isDiscoveryDataValid) {
            return false
        }

        return true;
    }

    const displayErrors = () => {
        if(!errors)
            return
        
        for(const e in errors){
            toast((errors as any)?.[e], { containerId: "default" })
        }
    }   

    const onWizardStepChanged = (stepId: string | number) => {
        const stepIndex = discoveryWizardSteps.findIndex(s => s.id === stepId);
        setCurrentStepIndex(stepIndex)
        
        if(!needDisableNextStep()) {
            displayErrors()
            return
        }
    }

    const handleStepLogicLeaderStep = (setStepIndex: (i: number) => void, stepIndex: number) => {
        setCurrentStepIndex(() => stepIndex)
        
        if(stepIndex === currentStepIndex) {
            setShowPreviewModal(true)
            return;
        }

        setStepIndex(stepIndex !== (undefined || -1) ? stepIndex : currentStepIndex-1)
    }
    
    const discoveryWizardSteps: IPage[] = [
        { 
            id: DiscoveryWizardStepsIds.PREVIEW, 
            stepName: "Preview", 
            component: () => <PreviewStep formik={formik}/>,
            hideSkipButton: true,
            hidePreviousButton: true,
        },
        { 
            id: DiscoveryWizardStepsIds.GENERAL, 
            stepName: "General", 
            component: () => <GeneralStep formik={formik}/>
        },
        { 

            id: DiscoveryWizardStepsIds.SKILLS, 
            stepName: "Skills", 
            component: () => <SkillsStep formik={formik}/>
        },
        { 
            id: DiscoveryWizardStepsIds.QUALIFICATIONS, 
            stepName: "Qualifications", 
            component: () => <QualificationsStep formik={formik}/>
        },
        { 
            id: DiscoveryWizardStepsIds.RNR, 
            stepName: "R&R", 
            component: () => <RolesAndResponsibilitiesStep formik={formik}/> 
        },
        { 
            id: DiscoveryWizardStepsIds.DAYINLIFE, 
            stepName: "Day In a Life", 
            component: () => <DayInALifeStep formik={formik}/>
        },
        { 
            id: DiscoveryWizardStepsIds.LEADERS, 
            stepName: "Leaders", 
            component: () => <LeadersStep formik={formik}/>,
            nextButtonText: "Finish",
            hideSkipButton: true,
            handleStepLogic: ({ setStepIndex, stepIndex }) => handleStepLogicLeaderStep(setStepIndex, stepIndex)
        },
    ]

    return (
        <div className="position-discovey-wizard-main">
            <ComponentStepper 
                isLoading={isLoading}
                showAllBreadcrumps={true}
                pages={discoveryWizardSteps}
                onStepChange={onWizardStepChanged}
                disableNextStep={!needDisableNextStep()}
                initialStepIndex={0}/>

                <PreviewModal
                onClose={() => setShowPreviewModal(false)}
                open={showPreviewModal}
                onClickPreview={() => {
                    const a = document.createElement('a')
                    a.target = "_blank"
                    a.href = localizer.localizeURL(getJobSimPositionNewUrl({
                        url: settings.getSetting("JOBSIMULATOR_ORIGIN"), 
                        companySlug: company!.slug,
                        positionSlug: discoveryData?.slug ?? "",
                        templatesNum: discoveryData?.templates ?? "",
                        isUnboxableUser: validateEmailandDomain(user!.email, 'unboxable.com')
                    }), locale);
                    
                    a.click()
                }}
                onClickReturnToAllPositions={() => history.push("/positions")}/>
        </div>
    )
}
export default DiscoveyWizard;
