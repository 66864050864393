import orangeArrowIcon from '../../icons/orange-arrow.svg';

import './style.css';

const ReportHeader: React.FunctionComponent<{ children: React.ReactNode, className?: string }> = ({
    children, className
}): JSX.Element => {
    return (
        <div className={`report-header-container ${className ?? ''}`}>
            <img src={orangeArrowIcon} alt="arrow"/>
            <h2>{children}</h2>
        </div>
    )
}

export default ReportHeader;