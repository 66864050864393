/**
 *
 * @module src/pages/Talents
 *
 * @description show candidates list of specific postion
 *
 * @author zohar
 *
 */

import React, { useState, useEffect, useContext } from 'react';

// Auth
import { useAuth, IAuth } from "@auth";

// Router
import { useParams, useLocation, useHistory } from "react-router-dom";

// Queries
import { getCandidatesScoresToCsv } from "@queries/candidates";


import LoadingButton from '@mui/lab/LoadingButton';

// Helpers
import { graphQueries, navigateSmoothToDivById } from "@helpers";

// Types
import { Location } from "history";

import './style.css';
import {  DownloadIcon } from '@/components/Icons';
import WeeklyReportDialog from '@/components/WeeklyReportDialog';
import {  changeCandidateProceedStatus, getCandaiteByRelevancy } from './helpers';
import { TypeOfRelevency } from '../CandidateReport/V2/data/report.utils';
import RelevancyCards from '@/components/RelevencyCards';
import { ICandidate, ProceedStatus } from './interfaces';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ChatRooms from '../ChatRooms';
import { ChatWrapper, Modalbox } from '@/components';
import { TalentsTable } from './components';
import { EditorApi } from '../AssessmentEditor/data/editor.api';
import { BuildingBlock } from '../BuildingBlocks/BuildingBlockEditor';
import AppContext from '@/context/AppContext';

const CHAT_CONTAINER_ID = "talent-chat-container";

const Talents: React.FunctionComponent = (): React.ReactElement => {
    // Auth
    const { company }: IAuth = useAuth();

    // Router
    const { positionId }: { positionId: string } = useParams();

    // Location
    const { state }: Location<{ positionName?: string }> = useLocation();
    const history = useHistory();

    // States
    const [allCandidates, setAllCandidates] = useState<ICandidate[]>([]);
    const [candidatesByRelevancy, setCandidatesByRelevancy] = useState<{ [key in TypeOfRelevency]: ICandidate[] }>();
    const [positionBlocks, setPositionBlocks] = useState<string[]>([]);

    const [buildingBlocks, setBuildingBlocks] = useState<BuildingBlock[]>([]);
    const [loadingBuildingBlocks, setLoadingBuildingBlocks] = useState<boolean>(true);

    const [loadingCandidates, setLoadingCandidates] = useState<boolean>(true);
    const [csvLoading, setCsvLoading] = useState<boolean>(false);
    
    const [openWeeklyReport, setOpenWeeklyReport] = useState<boolean>(false);

    const [chatIframeProps, setChatIframeProps] = useState<{iframeId?: string, chatUrl?: string}>({})
    const [showChat, setShowChat] = useState<boolean>(false)

    const [showRejectAllWarning, setShowRejectAllWarning] = useState<boolean>(false)
    const [isRejectAllCandidates, setIsRejectAllCandidates] = useState<boolean>(false)

    const { tr } = useContext(AppContext)

    // Fetch data
    useEffect(() => {
        setLoadingCandidates(true);
        getCandaiteByRelevancy({
            positionId: positionId,
            titleName: "",
            sizeOfPage: 1000000,
            page: 0
        })
        .then(({ gemsCandidates,starsCandidates, qualifiedCandidates, allCandidates, blockTypes }) => {
                setCandidatesByRelevancy({
                    [TypeOfRelevency.GEM]: gemsCandidates,
                    [TypeOfRelevency.STAR]: starsCandidates,
                    [TypeOfRelevency.QUALIFIED]: qualifiedCandidates,
                    [TypeOfRelevency.NOTQUALIFIED]: []
                })
                setAllCandidates(allCandidates);
                setPositionBlocks(blockTypes);
        })
        .catch(console.error)
        .finally(() => setLoadingCandidates(false));
    }, [ ]);

    useEffect(() => {
        setLoadingBuildingBlocks(true);
        EditorApi.getBuildingBlocks()
        .then(setBuildingBlocks)
        .catch(console.error)
        .finally(() => setLoadingBuildingBlocks(false));
    }, [])

    const exportTalentsToCsv = async (): Promise<void> => {
        try {
            setCsvLoading(true);
            const res = await graphQueries.sendRequest(getCandidatesScoresToCsv('getCandidates'),{
                "companyId": company?.id,
                "positionId": positionId,
            });
            
            // We create a "ghost" anchor DOM element
            const a: HTMLAnchorElement = document.createElement("a");
    
            // We add all properties that allow us to download the file
            a.href = encodeURI(res.getCandidatesScoresToCsv);
            // a.target = "_blank";
    
            // We trigger the click (download)
            a.click();
            setCsvLoading(false);
        }catch(err) {
            console.log(err);
            setCsvLoading(false)
        }
    }

    const onClickRejectPendingCandidates = () => {
        const pendingCandidates = allCandidates.filter(c => !c.status);
        setIsRejectAllCandidates(true);
        Promise.all(
            pendingCandidates.map(c => (
                changeCandidateProceedStatus({ candidateId: c.id, positionId: c.V2PositionId, status: ProceedStatus.notGo })
            ))
        )
        .then(() => {
            setAllCandidates(prev => prev.map(c => !c.status ? { ...c, status: ProceedStatus.notGo } : c));
        })
        .catch(console.error)
        .finally(() => {
            setIsRejectAllCandidates(false);
            setShowRejectAllWarning(false);
        });
    }

    return(
        <main id="talents">
            <div className="talents-headers-section">
                <h1>{state?.positionName}</h1>
                <span
                onClick={() => history.push('/positions')}>{"Back to all positions"}</span>
            </div>
            <div className="talents-actions-section">
                {/* Download as CSV / Import csv */}
                {/* <button 
                className='talents-headers-custom-button'
                onClick={() => setOpenWeeklyReport(true)}> {"View statistics"} </button> */}
                <ChatRooms
                onChatAvailable={(iframeId, chatUrl) => setChatIframeProps({ iframeId, chatUrl })}
                buttonProps={{
                    className: 'talents-headers-custom-button',
                    onClick: () => {
                        setShowChat(true)
                        setTimeout(( )=> {
                            navigateSmoothToDivById(CHAT_CONTAINER_ID)
                        }, 500)
                    }
                }} 
                roomId={positionId}>
                    <MessageOutlinedIcon sx={{ fontSize: "12px", color: "#B0B0B0", marginRight: "4px" }}/>
                    {`Discuss this position`}
                </ChatRooms>
                <button 
                onClick={() => setShowRejectAllWarning(true)}
                className='talents-headers-custom-button'> {"Reject pending candidates"} </button>
                <button 
                onClick={() => history.push(`/positions/wizard/${positionId}`)}
                className='talents-headers-custom-button'> {"Edit Simulation"} </button>
                <LoadingButton
                className='talents-headers-custom-loading-button'
                style={{border: 'none'}}
                color='inherit'
                loading={csvLoading}
                loadingPosition="start"
                startIcon={<DownloadIcon color='#000'/>}
                variant="outlined"
                onClick={() => exportTalentsToCsv()}
                />
            </div>
            <RelevancyCards 
            loading={loadingCandidates}
            candidatesByRelevancy={candidatesByRelevancy as any}/>
            <div>
                {/* Talents Table */}
                <TalentsTable 
                positionBlockTypes={positionBlocks}
                buildingBlocks={buildingBlocks}
                candiadtes={allCandidates} 
                setCandidates={setAllCandidates}
                isLoading={loadingCandidates || loadingBuildingBlocks}/>
            </div>
            <ChatWrapper 
            showChat={showChat}
            setShowChat={setShowChat}
            chatContainerId={CHAT_CONTAINER_ID} 
            chatIframeProps={chatIframeProps as any}
            chatIsAvailable={chatIframeProps && chatIframeProps.iframeId && chatIframeProps.chatUrl ? true : false}>
                <h3 style={{ fontSize: "15px", marginBottom: "20px" }}>
                    {"Discuss this position"}
                </h3> 
            </ChatWrapper>
            {openWeeklyReport && 
            <WeeklyReportDialog openModal={openWeeklyReport} setModal={setOpenWeeklyReport}/>}
            {showRejectAllWarning &&
            <Modalbox
            buttonsWidth='140px'
            message={tr('Are you sure you want to reject all pending candidates?')}
            description={tr("This action will send a rejection email to ALL pending candidates and you will not be able to proceed with them later")}
            confirmText="Yes"
            disableConfirmButton={isRejectAllCandidates}
            onCancel={() => setShowRejectAllWarning(false)}
            onConfirm={onClickRejectPendingCandidates}
            />}
        </main>
    )
}

export default Talents;