// Icons
import { PlusIcon } from "@heroicons/react/solid"

// Local Interfaces
interface AddFieldProps {
  text?: string
  onClick: Function
  className?: string
}
const baseStyle = "flex items-center text-lg text-gray-500 hover:text-black font-light cursor-pointer"
/**
 *
 *  Add Field
 *  @description let to the user add a new field
 *
 */
const AddField: React.FunctionComponent<AddFieldProps> =
  ({ text="Add one", onClick: onClickHandler, className }): JSX.Element => {
    return (
      <div
        onClick={() => onClickHandler()}
        className={baseStyle + ' ' + className}>
        <PlusIcon className="h-3 w-3 mr-1"/>
        <span className="underline">{ text }</span>
      </div>
    )
  }

export default AddField
