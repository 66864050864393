
import { IPlayerProps, Player } from '@lottiefiles/react-lottie-player';
import './style.css';

enum IconDir {
    LEFT = "left",
    RIGHT = "right"
}

enum BtnStyle {
    PRIMARY = "primary",
    GREY = "grey",
    ORANGE = "orange",
    WHITE = "white",
    BLACK = "black",
    SUCCESS = "success",
    DANGER = "danger",
}

enum BtnSize {
    SMALL = "small",
    MEDIUM = "medium",
}

const btnDirectionClasses = {
    [IconDir.LEFT]: "unboxable-button-direction-left",
    [IconDir.RIGHT]: "unboxable-button-direction-right"
}

const btnStyleClasses ={
    [BtnStyle.PRIMARY]: "unboxable-button-color-primary",
    [BtnStyle.GREY]: "unboxable-button-color-grey",
    [BtnStyle.ORANGE]: "unboxable-button-color-orange",
    [BtnStyle.WHITE]: "unboxable-button-color-white",
    [BtnStyle.BLACK]: "unboxable-button-color-black",
    [BtnStyle.SUCCESS]: "unboxable-button-color-success",
    [BtnStyle.DANGER]: "unboxable-button-color-danger",
}

const btnSizeClasses = {
    [BtnSize.SMALL]: "unboxable-button-size-small",
    [BtnSize.MEDIUM]: "unboxable-button-size-medium",
}

export interface IUnboxableButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: any;
    iconDir?: IconDir;
    btnStyle?: BtnStyle;
    btnSize?: BtnSize;
    iconIsPlayable?: boolean;
    playableIconProps?: Partial<IPlayerProps>;
}

const UnboxableButton:React.FunctionComponent<IUnboxableButton> = ({
    children,
    className,
    icon,
    iconIsPlayable,
    playableIconProps,
    iconDir = IconDir.LEFT,
    btnStyle = BtnStyle.PRIMARY,
    btnSize = BtnSize.MEDIUM,
    ...props
}) => {
    
    const classess = ["unboxable-button"];
    if(className) classess.push(className);
    
    classess.push(btnDirectionClasses[iconDir]);
    classess.push(btnStyleClasses[btnStyle]);
    classess.push(btnSizeClasses[btnSize]);

    const getIcon = (icon: any): JSX.Element => {
        if(typeof icon === "string") {
            return <img className="unboxable-button-icon" src={icon as string}/> 
        }
        return icon()
    }

    return (
        <button
        {...props}
        className={classess.join(" ")}>
            {icon ?
                iconIsPlayable ? 
                <Player {...playableIconProps} src={icon}/>
                :  getIcon(icon)
            : null}
            {children}
        </button>
    )
}

export default UnboxableButton;