// React
import React, { createContext } from "react"

// Interfaces
import { WizardCustomQuestionProps } from "@components/WizardForm/interfaces"
import { IFormPage, QuestionCollection } from "@/models/formPage"
import { MediaUploader } from "@/helpers/mediaUploader"


// Context Interface
interface IWizardFormContext {
  page: number
  pages: number
  readonly dataCollection: IFormPage[],
  templateQuestions: QuestionCollection[],
  customQuestions: QuestionCollection[]
  finishLoading: boolean
  handlePrevPage: () => void
  handleFinish: () => Promise<any>
  setCustomQuestions: React.Dispatch<any>
  validateQuestion: (question: any) => boolean
  handleNextPage: (pageIndex?: number) => void
  setDataCollection: React.Dispatch<any>
  /**
   * No point in allowing sub components to set dirty to false
   */
  setDirty: React.Dispatch<true>
  setError: React.Dispatch<string | string[] | null>
  /**
   * Handles all media uploads for this wizard instance
   */
  uploader: MediaUploader

  /**
   * If true, upload image controls should start uploading when loaded with base64 content
   */
  autoUpload: boolean
  /**
   * If present, determines whether or not the finish button is shown
   */
   canFinish?: (pageNumber: number, pageData: Partial<IFormPage>) => boolean
  custom?: {
    [index: string]: React.FunctionComponent<WizardCustomQuestionProps>
  }
}

// Context
const WizardFormContext = createContext<IWizardFormContext>({
  page: 0,
  pages: 0,
  dataCollection: [],
  customQuestions: [],
  templateQuestions: [],
  finishLoading: false,
  handleNextPage: () => null,
  handlePrevPage: () => null,
  handleFinish: () => Promise.resolve(true),
  setDirty: () => true,
  canFinish: () => true,
  setDataCollection: () => null,
  validateQuestion: () => false,
  setCustomQuestions: () => null,
  setError: () => null,
  uploader: null as any,
  autoUpload: false
})

export { WizardFormContext }
