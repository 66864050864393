import { TypeOfRelevency } from '@/pages/CandidateReport/V2/data/report.utils';
import { RelevencyCardType } from '@/components/RelevencyCards/RelevancyCard';

import StarCandidates from "@assets/icons/star_candidate.svg";
import GemCandidates from "@assets/icons/gem_candidate.svg";
import QualifiedCandidates from "@assets/icons/Relevant.svg";

export const relevancyCards = {
    [TypeOfRelevency.STAR]: {
        uiType: RelevencyCardType.SECONDARY,
        title: "Star Quality",
        icon: StarCandidates,
        noCandidatesMessage: 'No stars yet!',
        description: "A Star is the rarest Talent. Candidates possess the skills to knock job duties out of the park, the accomplishments that prepare them for the role's responsibilities, and a highly compatible Workstyle with yours. This is a trifecta for success!"
    },
    [TypeOfRelevency.GEM]: {
        uiType: RelevencyCardType.PRIMARY,
        title: "Gems",
        icon: GemCandidates,
        noCandidatesMessage: 'No gems yet!',
        description: "A Gem is the purest Talent. Candidates don't have the ideal professional experience or education, yet proved they have the skills to deliver the goods. Their Workstyle is in sync with yours, forecasting a thriving work relationship that will overcome roadblocks and enjoy success."
    },
    [TypeOfRelevency.QUALIFIED]: {
        uiType: RelevencyCardType.PRIMARY,
        title: "Relevant",
        icon: QualifiedCandidates,
        noCandidatesMessage: 'No relevant candidates yet!',
        description: "Heads up! High potential is identified! These candidates seem to be well qualified and compatible but might need guidance in terms of hard skills required for the position."
    }
}