import axios from "axios";

import ReactHtmlParser from 'react-html-parser';


export const downloadFile = (url: string, name: string): void => {
    if (url && name) {
    axios({
        url: url,
        method: "GET",
        responseType: "blob",
    })
        .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", name)
        document.body.appendChild(link)
        link.click()
        })
        .catch((error) => {
        console.log(error)
        });
    }
}

  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getCandidateChoice = (choices: {value: any, text: string}[], item: any): any => {
    const answer = choices?.find((c:any) => c?.value === item)?.text || 
                        choices?.find((c:any) => c?.value === item) || 
                        choices?.find((c:any) => c === item)

    return ReactHtmlParser(answer as any ?? "")
}

export const getCorrectAnswer = (choices: {value: any, text: string}[], correctAnswer: any): any => {
    const correctAnswerToReturn = choices?.find((c:any) => c?.value === correctAnswer)?.text || 
                        choices?.find((c:any) => c?.value === correctAnswer) || 
                        choices?.find((c:any) => c === correctAnswer)

    return ReactHtmlParser(correctAnswerToReturn as any ?? "")
}

export const renderObjectTypeAnswer = (object: {}): string => {
    let str: any = "";
    for (const [key, value] of Object.entries(object)) {
        str += `${key}: ${value}<br>`;                    
    }
    return str
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const isCorrectAnswer = (correctAnswer: any, answer: any, answerType: string): boolean | null => {
    if(correctAnswer !== undefined && correctAnswer !== null) {
        if(answerType === "ranking") 
            return JSON.stringify(correctAnswer) === JSON.stringify(answer)
        
        if(answerType === 'text') 
            return correctAnswer?.toLowerCase()?.includes(answer?.toLowerCase())
        
        if(Array.isArray(correctAnswer)) 
            return correctAnswer.includes(answer)
        
        if(JSON.stringify(correctAnswer) === JSON.stringify(answer)) 
            return true
        
        return false
    }else 
        return null
    
}

