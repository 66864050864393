import ImageUpload from "@/components/UnboxableForm/ui/components/ImageUpload";
import { Checkbox, Dialog, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Button, LNRDLoading } from "@/components";
import React, { useContext, useEffect, useState } from "react";
import { capitalizeFirstLetter, toBase64 } from "@/helpers";
import { MediaUploader } from "@/helpers/mediaUploader";
import AuthContext from "@/auth/context";

import './style.css';
import { EditorApi } from "@/pages/AssessmentEditor/data/editor.api";

const muiFontSizeSx = {
    fontSize: "14px"
}

const BuildingBlockInformation: React.FunctionComponent<{onChange: (name: string, value: any, isImage?: boolean) => any, formData: any}> = 
({onChange, formData}): JSX.Element => {
    return (
        <div className="building-block-editor-form-section">
            <h2>{"BB information for workspace"}</h2>
            <TextField 
            variant="outlined" 
            placeholder="Internal tilte"
            value={formData?.name}
            name={"name"}
            style={{ width: "100%" }}
            sx={{
                '& .MuiInputBase-root': {
                    ...muiFontSizeSx
                }
            }}
            onChange={(e) => onChange(e.target.name, e.target.value)}/>
            <div className="flex flex-row">
                <TextField
                variant="outlined" 
                value={formData.description ?? ""}
                placeholder="description"
                name={"description"}
                multiline
                rows={2}
                style={{         
                    width: "100%",
                    marginRight: "6px",
                }}
                sx={{
                    '& .MuiInputBase-root': {
                        ...muiFontSizeSx,
                        height: "100% !important"
                    }
                }}
                onChange={(e) => onChange(e.target.name, e.target.value)}/>
                <ImageUploaderCustom 
                name="bbImage"
                src={formData?.bbImage} 
                onImageClear={() => onChange("bbImage", null, true)} 
                onChange={(e) => onChange(e.target.name, e.target.files, true)}/>
            </div>
        </div>
    ) 
}

const ImageUploaderCustom: React.FunctionComponent<{ src: any, onImageClear: () => any, onChange: (e: any) => any, name: string}> = ({
    src, onImageClear, onChange, name
}): JSX.Element => (
    <ImageUpload 
    name={name}
    multiple={false}
    src={src}
    clear={() => onImageClear()}
    onChange={(e: any) => onChange(e)}
    size={200}
    customImageTagStyle={{
        width: "99%",
        height: "98%",
        top: "1px",
        right: "1px"
    }}
    label={
        <div className="image-uploader-custom">
            <AddPhotoAlternateIcon/>
            <div className="image-uploader-custom-texts">
                <p>
                    {"Add image"}
                </p>
                <span>{"PNG 460x320px"}</span>
            </div>
        </div>
    }
    />
)

const BuildingBlockIntroPage: React.FunctionComponent<{onChange: (name: string, value: any, isImage?: boolean) => any, formData: any}>=
    ({onChange, formData}): JSX.Element => (
    <div className="building-block-editor-form-section">
    <h2>{"Intro page (Make sure text and images are approved by product!)"}</h2>
    <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    name="introType"
    value={formData?.introType}
    style={{ display: "flex", flexDirection: "row" }}
    onChange={(e) => onChange(e.target.name, e.target.value)}>
        <FormControlLabel
        value="show_intro_page" 
        control={<Radio style={{ color: "#000" }} />}
        label="Show an intro page"
        sx={{ "& .MuiTypography-root": {...muiFontSizeSx} }} />
        <FormControlLabel
        value="show_intro_message"
        control={<Radio style={{ color: "#000" }} />}
        label="Show an Intro message"
        sx={{ "& .MuiTypography-root": {...muiFontSizeSx} }}/>
        <FormControlLabel
        value="no_intro" 
        control={<Radio style={{ color: "#000"}} />}
        label="Without an intro"
        sx={{ "& .MuiTypography-root": {...muiFontSizeSx} }}/>
    </RadioGroup>
    <TextField
    variant="outlined" 
    placeholder="Title"
    name="introTitle"
    value={formData?.introTitle}
    multiline
    rows={2}
    style={{ width: "100%" }}
    sx={{
        '& .MuiInputBase-root': {
            ...muiFontSizeSx
        }
    }}
    onChange={(e) => onChange(e.target.name, e.target.value)}/>
    <div style={{ display: 'flex', flexDirection: "row" }}>
        <TextField
        variant="outlined" 
        placeholder="Description"
        name="introDescription"
        value={formData.introDescription ?? ""}
        multiline
        rows={2}
        style={{ width: "100%", marginRight: "6px" }}
        sx={{
            '& .MuiInputBase-root': {
                ...muiFontSizeSx,
                height: "100% !important"
            }
        }}
        onChange={(e) => onChange(e.target.name, e.target.value)}/>
        <ImageUploaderCustom 
        name={"bbIntroImage"}
        src={formData?.bbIntroImage} 
        onImageClear={() => onChange("bbIntroImage", null, true)} 
        onChange={(e) => onChange(e.target.name, e.target.files, true)}/>
    </div>
</div>
) 

const BuildingBlockSettings: React.FunctionComponent<{onChange: (name: string, value: any) => any, formData: any, reportMeanings: { value: string, label: string }[]}> = 
    ({onChange, formData, reportMeanings}): JSX.Element => {
    const selectData = [
        {
            placeholder: "Assessment length",
            name: "assessmentLength",
            options: Array(60).fill(0).map((val, i) => (
                {value: i + 1, label: `${i+1} Minutes`}
            ))
        },
        {
            placeholder: "Score as...",
            name: "scoreAs",
            options: [
                ...["qualification", "skillfulness", "compatibility"].map((val) => (
                {value: val, label: `Score as ${val}`}
                )),
                { value: "noScore", label: "No channel" }
            ]
        },
        {
            placeholder: "Report meaning",
            name: "report_meaning",
            options: reportMeanings ?? []
        }
    ]
    return (
        <div>
            <div className="building-block-editor-settings-section">
                {selectData.map((selectData, i: number) => (
                    <Select
                    key={`${selectData.name}-${i}`}
                    value={formData[selectData.name] ?? selectData.placeholder}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    name={selectData.name}
                    sx={{ "& .MuiSelect-select": { ...muiFontSizeSx }, "& .MuiList-root": { padding: "0px !imortant"} }}
                    >
                        <MenuItem value={selectData.placeholder} disabled style={{display: "none"}} sx={{ "& .MuiMenuItem-root": { ...muiFontSizeSx } }}>
                            {selectData.placeholder}
                        </MenuItem>
                        {selectData.options.map((o: any, i: number) => (
                            <MenuItem value={o.value} key={i} sx={{ "& .MuiMenuItem-root": { ...muiFontSizeSx } }}>
                                {o.label}
                            </MenuItem>
                        ))}
                    </Select>
                ))}
            </div>

            <div>
                <FormControlLabel 
                name="isMirroring"
                checked={formData["isMirroring"] ?? false}
                onChange={(e: any, checked) => onChange(e.target.name, checked)}
                control={<Checkbox style={{ color: "#000" }} />} 
                sx={{ "& .MuiTypography-root": {...muiFontSizeSx} }}
                label="Mirroring BB" />
                <FormControlLabel 
                name="isRequireChooseDefault"
                checked={formData["isRequireChooseDefault"] ?? false}
                onChange={(e: any, checked) => onChange(e.target.name, checked)}
                control={<Checkbox style={{ color: "#000" }} />} 
                sx={{ "& .MuiTypography-root": {...muiFontSizeSx} }}
                label="Requires choosing a test in position wizard" />
            </div>
        </div>
    )
}

const BuildingBlockActions: React.FunctionComponent<{onSubmit: () => any, editorMode: EditorMode, isLoading: boolean}> = 
    ({onSubmit, editorMode, isLoading}): JSX.Element => (
    <div className="building-block-editor-actions-section">
        <Button disabled={isLoading} text="Preview"/>
        <Button disabled={isLoading} text={editorMode === EditorMode.CREATE ? "Publish" : "Update"}type="regular" onClick={onSubmit}/>
    </div>
)

const dialogStyle = {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        minWidth: "640px",
        padding: "18px"
      },
    },
}

export interface BuildingBlock {
    id?: string
    name?: string,
    metadata?: {
        description?: string,
        introType?: string,
        introTitle?: string,
        introDescription?: string,
        assessmentLength?: number,
        scoreAs?: string,
        isMirroring?: boolean,
        bbImage?: string,
        bbIntroImage?: string,
        report_meaning?: string;
        isRequireChooseDefault?: boolean;
    }
}

export interface BuildingBlockForm {
    id?: string
    name?: string,
    description?: string,
    introType?: string,
    introTitle?: string,
    introDescription?: string,
    assessmentLength?: number,
    scoreAs?: string,
    isMirroring?: boolean
    bbImage?: string,
    bbIntroImage?: string,
    report_meaning?: string,
    isRequireChooseDefault?: boolean
}

export enum EditorMode {
    EDIT = 'edit',
    CREATE = 'create'
}

const BuildingBlockEditor: React.FunctionComponent<{
    openBuildingBlockEditor: boolean;
    setOpenBuildingBlockEditor: (val: boolean) => void;
    editorMode?: EditorMode
    builidingBlockToEdit?: null | BuildingBlock,
    onSubmitBuildingBlock?: (formData: BuildingBlock) => any
}> = ({ openBuildingBlockEditor, setOpenBuildingBlockEditor, editorMode = EditorMode.CREATE, builidingBlockToEdit = null, onSubmitBuildingBlock }): JSX.Element => {
    
    const [ formData, setFormData ] = useState<BuildingBlockForm>({});
    const [ loading, setLoading ] = useState<boolean>(false);
    const { settings } = useContext(AuthContext);

    const [reportMeaningOptions, setReportMeaningOptions] = useState<{value: string, label: string}[]>([]);

    useEffect(() => {        
        const isEditFlow = editorMode === EditorMode.EDIT && builidingBlockToEdit
        console.log(builidingBlockToEdit);
        if(isEditFlow) {
            
            const { metadata } = builidingBlockToEdit || {};
            setFormData({
                id: builidingBlockToEdit?.id,
                name: builidingBlockToEdit?.name,
                description: metadata?.description,
                introType: metadata?.introType,
                introTitle: metadata?.introTitle,
                introDescription: metadata?.introDescription,
                assessmentLength: metadata?.assessmentLength,
                scoreAs: metadata?.scoreAs,
                isMirroring: metadata?.isMirroring,
                bbImage: metadata?.bbImage,
                bbIntroImage: metadata?.bbIntroImage,
                report_meaning: metadata?.report_meaning
            })
        }
        
    },[]);


    useEffect(() => {
        setLoading(true);
        EditorApi.getReportMeanings()
        .then((rms) => {
            setReportMeaningOptions(rms.map((rm) => ({value: rm, label: rm})));
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }, []);

    const uploadImage = async (files: FileList | null) => {
        const [ file ] = files || [];
        if(file) {
        try {
            const imageData = (file && await toBase64(file)) || "";
            const mediaUploader = new MediaUploader(settings);
            const result = await mediaUploader.upload({
                data: imageData,
                name: file.name
            });
            const { url, error } = result || {};
            if(error) {
                throw new Error(error); 
            }
            if(url) {          
                return url
            }
            throw Error('Something went wrong...');
        } catch(e) {
            console.error(e);
        }
    
        }
    };

    const uploadImageAndSetSrc = (fieldName: string, files: any) => {
        if(files) {
            setLoading(true)
            uploadImage(files)
            .then((src) => {
                console.log(src);
                
                if(src) { 
                    setFormData((prevState) => ({
                        ...prevState, [fieldName]: src
                    }))
                }
            })
            .catch(console.error)
            .finally(() => setLoading(false))
        }else {
            setFormData((prevState) => ({
                ...prevState, [fieldName]: null
            }))
        }
    }

    const onFormChange = (name: string, value: any, isImageInput?: boolean) => {        
        if(isImageInput) {
            uploadImageAndSetSrc(name, value)
        }else {
            setFormData((prevState) => 
            ({ ...prevState, [name]: value }))
        }
    }

    const isFormDataValid = (formData: BuildingBlock): boolean => {
        const { name } = formData || {};
        if(!name || name.length <= 0) {
            return false
        }
        
        return true
    }

    const generateEditorTitle = () => {
        return `${capitalizeFirstLetter(editorMode)} Building Block`
    }

    const getEmptyBuildingBlock = (): BuildingBlock => {
        return {
            id: undefined,
            name: undefined,
            metadata: {
                description: undefined,
                introType: undefined,
                introTitle: undefined,
                introDescription: undefined,
                assessmentLength: 0,
                scoreAs: undefined,
                isMirroring: false,
                bbImage: undefined,
                bbIntroImage: undefined,
            }
        }
    }

    const generateBuildingBlockTypeFromName = (name: string) => {
        return name.toLowerCase().trim().replace(" ", "_");
    }

    const isuuid = (string: string) => {
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

        return regexExp.test(string);
    }

    const convertFormDataToBuildingBlockStructure = (formData: BuildingBlockForm): BuildingBlock => {
        const buildingBlock: BuildingBlock = getEmptyBuildingBlock()

        for(const key in formData){ 
            const isMetaData = !["id", "name"].includes(key)
            if(isMetaData) {
                (buildingBlock.metadata as any)[key] = (formData as any)[key]
            }else {
                (buildingBlock as any)[key] = (formData as any)[key]
            }
        }

        const needToGenerateType = ((!buildingBlock?.id || isuuid(buildingBlock?.id) )&& buildingBlock.name)? true : false;
        if(needToGenerateType) {
            (buildingBlock as any).id = generateBuildingBlockTypeFromName(buildingBlock.name!);
        }

        return buildingBlock
    }

    const onFormSubmit = (formData: BuildingBlock) => {
        const formDataIsValid = isFormDataValid(formData);
        if(!formDataIsValid)
        return
        
        if(onSubmitBuildingBlock) {
            setLoading(true)
        }

        const BuldingBlock = convertFormDataToBuildingBlockStructure(formData)
        
        onSubmitBuildingBlock?.(BuldingBlock)
        .then(() => {
            setLoading(false);
        })
    }

    return (
        <Dialog
        open={openBuildingBlockEditor}
        onClose={() => setOpenBuildingBlockEditor(false)}
        sx={dialogStyle}>
            <div className="building-block-editor-container"> 
                <h1 className="building-block-editor-main-header">
                    {generateEditorTitle()}
                </h1>
                <BuildingBlockInformation 
                onChange={onFormChange}
                formData={formData}/>
                <BuildingBlockIntroPage 
                onChange={onFormChange}
                formData={formData}/>
                <BuildingBlockSettings 
                onChange={onFormChange}
                formData={formData}
                reportMeanings={reportMeaningOptions}/>
                <BuildingBlockActions 
                isLoading={loading}
                editorMode={editorMode}
                onSubmit={() => onFormSubmit(formData)}/>
            </div>
            {loading && 
            <div className="loading-custom-backdrop">
                <LNRDLoading/>
            </div>}
        </Dialog>
    )
}

export default BuildingBlockEditor;