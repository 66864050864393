import { stringUtils } from "@/helpers"
import { IFormPage, WizardFormPage } from "./formPage"

/**
 * Aggragates the data model for company related questions, and the fields used by the wizard UI
 */
export interface ICompanyFormPage extends IFormPage{
	/**
	 * duplicate one field, for linting
	 */
	//readonly key: number
	toString(): string
}

/**
 * Aggragates the data model for company related questions, and the fields used by the wizard UI
 */
 export interface ICompanyStoryFormPage extends ICompanyFormPage{
	readonly yearFounded: string
}

export interface ICompanyFormData {
	readonly story: ICompanyStoryFormPage
	readonly values: ICompanyFormPage
	readonly culture: ICompanyFormPage
	readonly leaders: ICompanyFormPage
	readonly invite: ICompanyFormPage
	readonly integrations: ICompanyFormPage

}

/**
 * Used for readable access to a company pages collection
 */
export class CompanyFormDataWrapper implements ICompanyFormData {
	constructor(pages: Array<ICompanyFormPage>) {
		if (pages && pages.length) {
			const findPageByName = (name: string): ICompanyFormPage => {
				const re = stringUtils.stringToRegex(name, "i")
				return pages.find((page) => page && re.test(page.name) || re.test(page.hash))!
			}
			const anythis = this as any
			["story", "values", "culture", "leaders", "invite", "integrations"]
				.forEach(name => anythis[name] = findPageByName(name) as IFormPage)
		}
	}

	public story: ICompanyStoryFormPage = {} as ICompanyStoryFormPage
	public culture: ICompanyFormPage = {} as ICompanyFormPage
	public values: ICompanyFormPage = {} as ICompanyFormPage
	public leaders: ICompanyFormPage = {} as ICompanyFormPage
	public invite: ICompanyFormPage = {} as ICompanyFormPage
	public integrations: ICompanyFormPage = {} as ICompanyFormPage

}

export class CompanyFormPage extends WizardFormPage implements ICompanyFormPage {
	constructor(props: Partial<ICompanyFormPage> | null) {
		super(props)
	}
} 

export class CompanyStoryFormPage extends CompanyFormPage implements ICompanyStoryFormPage{
	constructor(props: Partial<ICompanyStoryFormPage> | null) {
		super(props)
	}
	//public yearFounded = ""
} 
