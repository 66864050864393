import { IUser } from "@/auth/interfaces"
import { IPosition } from "@/interfaces/pages/positions"
import { ComProfile } from "@/pages/ReviewProcess/parts/comProifleHelpers"
import { TypeOfChannels, TypeOfRelevency } from "./report.utils"
import { ProceedStatus } from "@/pages/Talents/interfaces"

export enum TypeOfBlocks { 
    CV = "cv",
    VIDEO = "vid",
    CULTURAL_FIT = "cult",
    PROFFESIONAL_FIT = "pro_fit",
    SKILLS = "skl",
    GOOD_EMPLOYEE = "good_emp",
    MANAGEMENT = "mng",
    QUALIFICATION = "qlf",
    REMOTE = "remote"
}

export interface CorrectAnswerFields {
    questionUid: string,
    score: number,
}

export interface ComProfileResponse {
    needs: { [key: string]: string }
    profile: string
    qualities: { [key: string]: string }
    strengths: { [key: string]: string }
    values: { [key: string]: string }
    workStyle: { [key: string]: string }
    metadata: { [key: string]: string }
}

export interface ComProfileForecastResponse {
    avoidSurvivalModeBy: { [key: string]: string }
    comProfileOne: string
    comProfileTwo: string
    helpYourTalentThriveBy: { [key: string]: string }
    id: string
    summary: { [key: string]: string }
}

export interface CandidateBlockMeanings {
    blockMeanings?: {
        description?: string
        meaning?: { message?: string, type?: string }[]
        type?: string
    }
    blockType?: string
}

export interface ReportReccomedationsResponse {
    id: string;
    UserId: string; 
    CompanyId: string;
    assessmentAxe?: string;
    section?: string;
    sectionType: string;
    User: IUser;
    reason?: string;
    recommendationText?: string;
    positions?: string[];
    createdAt: string;
    updatedAt: string;
}

export interface CandidateResponse {
    id: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    linkedinUrl?: string;
    phone?: string;
    cv?: string;
    referrer?: string;
    comProfile?: ComProfile;
    pdfUrl?: string;
    V2PositionId: string;
    relevancy?: TypeOfRelevency;
    createdAt?: string;
    blocksMeanings?: CandidateBlockMeanings[]
    relevancyPerChannel: { 
        compatibility: TypeOfChannels
        qualification: TypeOfChannels
        skillfullness: TypeOfChannels
    };
    imageUrl: string;
    status?: ProceedStatus;
}

export interface BlockResponse {
    assessmentId?: string;
    blockData?: any
    blockResponse?: any
    candidateId?: string
    createdAt?: string
    id?: string
    metadata: any
    typeOfBlock: TypeOfBlocks
    updatedAt?: string
    correctAnswers?: {questionUid: string, score: number}[]
}

export interface CandidateReportResponse {
    getCandidateBlocks: BlockResponse[],
    getCandidate: CandidateResponse,
    getV2Position: Partial<IPosition>
    generateReportRecommendations: ReportReccomedationsResponse[]
}

interface ResumeDataContactInformation {
    CandidateName?: { FamilyName?: string, FormattedName?: string, GivenName?: string },
    EmailAddresses?: string[],
    Telephones?: {Raw?: string, Normalized?: string, SubscriberNumber?: string}[],
    WebAddresses?: {Address?: string, Type?: string}[]
}

interface ResumeDataEducation { 
    HighestDegree?: {
        Name?: { Raw?: string, Normalized?: string },
        Type?: string
    }, 
    EducationDetails?: {
        Degree?: {
            Name?: { Raw?: string, Normalized?: string },
            Type?: string
        }
        Graduated?: { Value: boolean }
        Id?: string
        LastEducationDate?: {Date?: string, IsCurrentDate?: boolean, FoundYear?: boolean, FoundMonth?: boolean, FoundDay?: boolean}
        Location?: {CountryCode?: string, Municipality?: string}
        Majors?: string[]
        SchoolName?: {Raw?: string, Normalized?: string}
        SchoolType?: string
        Text?: string
    }[]
}

interface ResumeDataEmploymentHistory {
    ExperienceSummary?: {
        AverageMonthsPerEmployer?: number,
        CurrentManagementLevel?: string,
        Description?: string,
        ExecutiveType?: string
        FulltimeDirectHirePredictiveIndex?: number,
        ManagementScore?: number,
        ManagementStory?: string
        MonthsOfManagementExperience?: number,
        MonthsOfWorkExperience?: number,
    }
    Positions?: {
        Description?: string,
        Employer?: {
            Name?: { Normalized?: string, Probability?: string, Raw?: string }
        }
        EndDate?: {Date: string, IsCurrentDate: boolean, FoundYear: boolean, FoundMonth: boolean, FoundDay: boolean},
        Id?: string
        IsCurrent?: boolean
        IsSelfEmployed?: boolean
        JobLevel?: string
        JobTitle?: {Raw?: string, Normalized?: string, Probability?: string}
        JobType?: string
        StartDate?: {Date?: string, IsCurrentDate?: boolean, FoundYear?: boolean, FoundMonth?: boolean, FoundDay?: boolean}
        SubTaxonomyName?: string
        TaxonomyName?: string
        TaxonomyPercentage?: number
    }[]
}

interface ResumeDataResumeMetadata {
    DocumentCulture?: string,
    DocumentLanguage?: string,
    DocumentLastModified?: string,
    FoundSections?: {FirstLineNumber?: number, LastLineNumber?: number, SectionType?: string, HeaderTextFound?: string}[],
    ParserSettings?: string
    PlainText?: string
    ResumeQuality?: {
        Findings?: { Message?: string, QualityCode?: string }[]
        Level?: string
    }[]
    SovrenSignature?: string[]
}

interface ResumeDataSkillsData {
    Root?: string, 
    Taxonomies?: {
        Id?: string
        Name?: string
        PercentOfOverall?: number
        SubTaxonomies?: any[]
    }[] 
}

export interface ResumeData {
    ContactInformation?: ResumeDataContactInformation
    Education?: ResumeDataEducation
    EmploymentHistory?: ResumeDataEmploymentHistory,
    LanguageCompetencies?: {Language?: string, LanguageCode?: string, FoundInContext?: string}[],
    ProfessionalSummary?: string,
    QualificationsSummary?: string,
    ResumeMetadata?: ResumeDataResumeMetadata
    SkillsData?: ResumeDataSkillsData[]
}

export interface CVBlockResponse extends BlockResponse {
    blockData?: string,
    blockResponse?: {
        Info?: any;
        Value?: {
            ConversionMetadata?: {
                DetectedType?: string
                DocumentHash?: string
                ElapsedMilliseconds?: number
                OutputValidityCode?: string
                SuggestedFileExtension?: string
            }
            IndexingResponse?: { Code?: string, Message?: string }
            ParsingMetadata?: {ElapsedMilliseconds?: number, TimedOut?: boolean}
            ParsingResponse?: { Code?: string, Message?: string }
            RedactedResumeData?: ResumeData
            ResumeData?: ResumeData
        };
    }
}

export interface VideoBlockResponse extends BlockResponse {
    blockData?: {
        fileName: string,
        vidQuestion?: {
            id?: string,
            question?: string,
            question_category?: string,
            hrm_explanation?: string,
            candidate_guide?: string,
            hrm_guide?: string,
            default?: boolean,
            published?: boolean,
            hrm_indicator?: string,
        }
    }
}

export interface QuestionsBlockResponse extends BlockResponse { 
    blockData?: {
        questionResponse: { [id: string]: any },
        skippedQuestionResponse: { [id: string]: any }
    },
    blockResponse?: {
        result: {
            assessment: {
                content: null | string,
                content_type: null | string,
                createdAt: string,
                description: null,
                id: string,
                metadata: any,
                position_id: string,
                skillevals: any[],
                status: string,
                tags: string[],
                title: string,
                type: null | string,
                updatedAt: string,
                report: any
                report_meaning: any[]
            },
            report: {
                properties: {}[],
                score: number,
                skillevals: {}[],
            },
            report_meaning: {
                meaning: { message: string, type: string }[],
                type: string,
                description: string
            }[]
        }[]
    }
}