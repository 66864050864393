import { useRef, useState, useEffect } from 'react';
import Label from '../Label/Label';
import './style.css'

const TextArea: React.FunctionComponent<React.TextareaHTMLAttributes<HTMLTextAreaElement> & { label?: string, uiType?: "grey" | "white-bordered" }> = ( props ): JSX.Element => {
    const { label, uiType="grey", onChange, value, ...restProps } = props;

    const [cursor, setCursor] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        const input: any = ref.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor, value])

    const uiClasses = {
        "grey": "wizard-elements-textarea-field-grey",
        "white-bordered": "wizard-elements-textarea-field-white-bordered",
    }
    const uiClass = uiClasses[uiType];

    const handleChange = (e: any) => {
        setCursor(e.target.selectionStart)
        onChange && onChange(e);
    }

    return(
        <div className='wizard-textarea-field-container'>
            {label &&
            <Label>{label}</Label>}
            <textarea 
            {...restProps}
            onChange={handleChange}
            ref={ref}
            value={value}
            className={`wizard-custom-textarea-field ${uiClass} ${props.className ?? ""}`}/>

        </div>
    )
}

export default TextArea;