// React
import { useContext } from "react"

// WizardForm Components
import {
  DynamicInputSelect,
  SimpleInput,
  UploadImage
} from "@components/WizardForm/components"

// Components
import TextArea from "@components/TextArea"

// Hooks
import { useWizard } from "@components/WizardForm/hooks"

// Context
import { WizardFormContext } from "@components/WizardForm/context"

// Utils
import cx from "classnames"

// Interfaces
import { QuestionFactoryProps } from "@interfaces/pages/question"
import { IUseWizard, WizardCustomQuestionProps } from "@components/WizardForm/interfaces"

import { IDisplayQuestion } from "@models"
import { replaceWithOrSetReactNode } from "../../helpers"

/**
 *
 *  QuestionFactory
 *  @description build a question replacing all values by inputs
 *
 */
const QuestionFactory: React.FunctionComponent<QuestionFactoryProps> =
  ({ question: questionOrQuestions, index, subIndex, pageIndex, isCustom, grow }): React.ReactElement => {
    // Context
    const { custom, setDataCollection, dataCollection } = useContext(WizardFormContext)
    const question = questionOrQuestions as IDisplayQuestion

    // Wizard Hook
    const { getFrom }: IUseWizard = useWizard()

    return (
      <div className={cx("inline-flex justify-start items-center font-light text-black", {
        "w-full my-1": ["textarea", "custom"].includes(question.type) || question.fullWidth || grow
      })}>
        {
          (() => {
            switch ( question.type ) {
              case "multiselect":
                return replaceWithOrSetReactNode(getFrom(question.title), "@value",
                  <DynamicInputSelect
                    index={index}
                    subIndex={subIndex}
                    isCustom={isCustom}
                    grow={grow}
                    key={Math.random()} // FIXME: fix key issue
                    url={question.dataSource || question.value}
                    pageIndex={pageIndex}
                    valueType={question.valueType}
                    isMulti={question.isMulti}
                    editable={question.editable}
                    placeholder={question.placeholder}
                    fullWidth={question.fullWidth}
                    createLabel={question.createLabel}
                    noOptionsMessage={question.noOptionsMessage}
                  />
                )

              case "textarea":
                return replaceWithOrSetReactNode(getFrom(question.title), "@value", <TextArea
                    key={index}
                    isCustom={isCustom}
                    index={index}
                    subIndex={subIndex}
                    pageIndex={pageIndex}
                    placeholder={getFrom(question.placeholder)}
                  />
              )

              case "input":
              case "email":
                return replaceWithOrSetReactNode(getFrom(question.title), "@value",
                  <SimpleInput
                    key={index}
                    index={index}
                    subIndex={subIndex}
                    pageIndex={pageIndex}
                    placeholder={getFrom(question.placeholder)}
                    isCustom={isCustom}
                    isInline={question.inline}
                    disabled={question.disabled}
                  />
                )

              case "image":
                return (
                  <UploadImage
                    key={index}
                    index={index}
                    subIndex={subIndex}
                    pageIndex={pageIndex}
                    isCustom={isCustom}
                    maxSize={1024} //{parseInt(question.width as string)}
                  />
                )

              case "static":
                return <div>{getFrom(question.title)}</div>

              case "custom":
                try {
                  // If there is no custom question prop or it's empty => return error
                  if ( !custom || !("component" in custom) ) {
                    throw new Error("Bad custom question format")
                  }

                  // Custom Component to render
                  const Custom: React.FunctionComponent<WizardCustomQuestionProps> = custom[(question as any).component]

                  if ( !Custom ) {
                    throw new Error(`Failed to create question custom component ${(question as any).component}`)
                  }

                  // Props for Custom Component
                  const props: WizardCustomQuestionProps = {
                    dataCollection: {},
                    questionData: dataCollection[pageIndex].questions[index][subIndex],
                    setQuestionData: (value) => setDataCollection((dc: any) => {
                      // Data collection array copy
                      const newDc: any = [...dc]
                      const currentQuestion = newDc[pageIndex].questions[index][subIndex]

                      // We assign value to current question from data collection
                      currentQuestion.value = value

                      return newDc
                    }),

                    useWizard
                  }

                  return (
                    <Custom {...props} />
                  )

                } catch ( e: any ) {
                  return <>Component not found.</>
                }
              case "none":
                return <span className="null-question"></span>
              default:
                console.warn(`QuestionFactory: Unknown question type ${question.type}`)
                return <span className={`unknown-type-${question.type}`}></span>

            }
          })()
        }
      </div>
    )
}

export default QuestionFactory
