//Icons 
import successIcon from '../../icons/success.svg';
import failIcon from '../../icons/fail.svg';
import Message from '../../components/Message/Message';

// Interfaces
interface ITalentNeeds {
    helpTalentBy: any;
    avoidSurvivalModeBy: any;
}

const TalentNeeds: React.FunctionComponent<ITalentNeeds> = (props): JSX.Element => {
    const { helpTalentBy, avoidSurvivalModeBy } = props;
    
    return(
        <div className="talent-needs no-print">
        <div className="talent-needs-do-section">
            <Message backgroundColor='#50B685'> 
                <h3 className="talents-needs-header">
                    If hired, these actions will help the Talent thrive
                </h3>
            </Message>
            <div className="talents-needs-list">
                {Object.keys(helpTalentBy).map(key => (
                    <div key={key} className="talents-needs-list-single"> 
                        <img src={successIcon}/>
                        <span>{helpTalentBy[key]?.charAt(0).toUpperCase() + helpTalentBy[key]?.slice(1)}</span>
                    </div>

                ))}
            </div>
        </div>
        <div className="talent-needs-dont-do-section"> 
            <Message backgroundColor="#F06969">
                <h3 className="talents-needs-header">
                    The Talent will be pushed into survival mode if you
                </h3>
            </Message>
            <div className="talents-needs-list">
            {Object.keys(avoidSurvivalModeBy).map(key => (
                avoidSurvivalModeBy[key]?.length > 0 ? 
                <div key={key} className="talents-needs-list-single"> 
                    <img src={failIcon}/>
                    <span>{avoidSurvivalModeBy[key]?.charAt(0).toUpperCase() + avoidSurvivalModeBy[key]?.slice(1)}</span>
                </div> : null
             ))}
            </div>
        </div>
    </div>
    )
}

export default TalentNeeds;1