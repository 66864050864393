import React from "react";

import { Button, ClickAwayListener, Grow, MenuList, Paper, Popper, PopperPlacementType, Stack } from "@mui/material";

interface IDropdownButton {
    buttonClassName?: string;
    buttonChildren:(isOpen: boolean) => JSX.Element;
    dropdownClassName?: string;
    dropdownStyle?: React.CSSProperties;
    dropdownWidth?: string;
    dropdownChildren?: (isOpen?: boolean, setOpen?: (val: boolean) => any) => JSX.Element;
    beforeToggle?: (isOpen: boolean) => any;
    dropdownPlacement?: PopperPlacementType;
  }
  
const DropdownButton: React.FunctionComponent<IDropdownButton> = 
  ({buttonClassName, 
    buttonChildren, 
    dropdownClassName, 
    dropdownStyle, 
    dropdownWidth, 
    dropdownPlacement,
    dropdownChildren,
    beforeToggle}): JSX.Element => {
  
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
  
    const handleToggle = () => {
      if(beforeToggle)
        beforeToggle(open)
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event: Event | React.SyntheticEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
        ) {
          return;
        }
      if(beforeToggle)
        beforeToggle(open)
      setOpen(false);
    };
  
    function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === "Tab") {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === "Escape") {
        setOpen(false);
      }
    }
  
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus();
      }
  
      prevOpen.current = open;
    }, [open]);
    
    return (
      <Stack direction="row" spacing={2}>
      <div>
        <Button
          disableElevation
          disableRipple
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={buttonClassName ?? ""}
        >
          {buttonChildren(open)}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement={dropdownPlacement ?? "bottom-end"}
          transition
          // disablePortal
          style={{
            zIndex: 199,
            width: dropdownWidth ?? "300px"
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    className={dropdownClassName}
                    style={dropdownStyle ?? {}}
                  >
                    {dropdownChildren ? dropdownChildren(open, setOpen) : null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
    )
}

export default DropdownButton;