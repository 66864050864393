import { getCandidatesForTalentsPage } from "@/graphql/queries/candidates";
import { getFavoritesCandidatesByUserIdQuery } from "@/graphql/queries/favorites";
import { graphQueries } from "@/helpers";
import { ChannelValues, TypeOfRelevency } from "../CandidateReport/V2/data/report.utils";
import { ICandidate, ProceedStatus } from "./interfaces";
import { validateCandidate } from "@/graphql/mutations/validateCandidate";

export const relevancyPerChannelToRank: any = {
    [ChannelValues.LOW]: 25,
    [ChannelValues.MEDIUM]: 50,
    [ChannelValues.HIGH]: 75,
    [ChannelValues.VERYHIGH]: 100
} 


export const rankToRelevancyPerChannel: any = {
    25: "Low",
    50: "Medium",
    75: "High",
    100: "Very High"
}

export const relevancyLabel: any = {
    [TypeOfRelevency.GEM]: "A Gem!",
    [TypeOfRelevency.STAR]: "A Star!",
    [TypeOfRelevency.QUALIFIED]: "Relevant!",
}

export enum CompetabilityProfile {
    RV = "Protective Authority",
    SV = "Control",
    R = "Relationships & People",
    I = "Ideas",
    V = "Competition",
    S = "Structures",
    IV = "Innovation",
    RI = "Facilitation",
    RS = "Safety"
}

export const comProfileGeneretor: any = {
    "RV" : CompetabilityProfile.RV,
    "SV" : CompetabilityProfile.SV,
    "R" : CompetabilityProfile.R,
    "I" : CompetabilityProfile.I,
    "V" : CompetabilityProfile.V,
    "S" : CompetabilityProfile.S,
    "IV" : CompetabilityProfile.IV,
    "RI" : CompetabilityProfile.RI,
    "RS" : CompetabilityProfile.RS,
}

export const getCandidatesCaregorizedByNormalGemsStarsQualified = (candidates: ICandidate[]): {
    gemsCandidates:ICandidate[], starsCandidates:ICandidate[], qualifiedCandidates:ICandidate[], allCandidates:ICandidate[]
} => {
    const gemsStarsQualifiedCandidates = candidates
    ?.filter((c: any) => [TypeOfRelevency.GEM, TypeOfRelevency.STAR, TypeOfRelevency.QUALIFIED].includes(c?.relevancy) )
    ?.map((c: any) => ({...c, skills: c.scoredQuestionResponse?.result?.[0]?.report.score}))
    

    const gemsCandidates = gemsStarsQualifiedCandidates?.filter((c: any) => c.relevancy === TypeOfRelevency.GEM)??[];
    const starsCandidates = gemsStarsQualifiedCandidates?.filter((c: any) => c.relevancy === TypeOfRelevency.STAR)??[];
    const qualifiedCandidates = gemsStarsQualifiedCandidates?.filter((c: any) => c.relevancy === TypeOfRelevency.QUALIFIED)??[];


    return { gemsCandidates, starsCandidates, qualifiedCandidates, allCandidates: candidates }
}

export const getCandaiteByRelevancy = async (args: {sizeOfPage: number, page: number, positionId?: string, titleName?: string },userId?: string): Promise<{
    gemsCandidates:ICandidate[], starsCandidates:ICandidate[], qualifiedCandidates:ICandidate[], allCandidates:ICandidate[], blockTypes: string[]
}> => {
    let candidates;
    if(userId) {
        const { getFavByUserId } = await graphQueries.sendRequest(getFavoritesCandidatesByUserIdQuery, { userId: userId }) as any;
        candidates = getFavByUserId
    }else {
        const { getCandidates } = await graphQueries.sendRequest(getCandidatesForTalentsPage('getCandidates'), args) || {};
        const { candidates: res } = getCandidates || {};
        candidates = res;
    }
    const blockTypes = new Set<string>();
    const formattedCandidates = candidates.map((candidate: any) => { 
        Object.entries(candidate.blocksScore ?? {}).map(([key,]) => {
            blockTypes.add(key);
            candidate.blocksScore[key].score = (candidate.blocksScore[key]?.score ?? 0) * 100
        })
        const fc = {
            ...candidate, 
            compatibilityChannel: relevancyPerChannelToRank?.[candidate?.relevancyPerChannel?.compatibility] ?? null,
            qualificationChannel: relevancyPerChannelToRank?.[candidate?.relevancyPerChannel?.qualification] ?? null,
            skillfullnessChannel: relevancyPerChannelToRank?.[candidate?.relevancyPerChannel?.skillfullness] ?? null,
            ...candidate.blocksScore
        }
        delete fc.blocksScore
        return fc
    });

    const { gemsCandidates, starsCandidates, qualifiedCandidates, allCandidates } = getCandidatesCaregorizedByNormalGemsStarsQualified(formattedCandidates);
    return {
        gemsCandidates, starsCandidates, qualifiedCandidates, allCandidates, blockTypes: [...blockTypes]
    }
}

type ProceedStatusPayload = {
    candidateId: string;
    status: ProceedStatus.notGo | ProceedStatus.go; 
    positionId: string;
}

export const changeCandidateProceedStatus = async (payload: ProceedStatusPayload): Promise<any> => {
    const response = await graphQueries.sendRequest(validateCandidate, payload);
    return response;
}