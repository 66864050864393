import { FormControlLabel, Checkbox as MUICheckbox, CheckboxProps } from "@mui/material";

const Checkbox: React.FunctionComponent<CheckboxProps & {label: string}> = (props): JSX.Element => {
    const { label, className, style, ...rest } = props;
    return (
        <FormControlLabel 
        label={label}
        className={className}
        sx={{
            "& .MuiTypography-root": {
                fontSize: "12px" 
            }
        }}
        control={<MUICheckbox {...rest} style={{ color: "#000", ...style }} />}/>
    )
}

export default Checkbox;