/**
 *
 * @module src/pages/Referrals/AddReferral
 *
 * @description content for add referral modal box
 *
 * @author diegoulloao
 *
 */

// React
import { useState } from "react"

// Components
import { Button } from "@components"

// Parts
import OneByOne from "@pages/Referrals/AddReferral/OneByOne"

// Helpers
import { getRefCodeFromLink } from "@helpers/index"

// Utils
import ObjectsToCsv from "objects-to-csv"
import cx from "classnames"

// Types
type Modes = "one-by-one" | "csv"

// Interfaces
import { IReferrer } from "@interfaces/pages/referrals"
import { IReferrer as IReferrerData } from "@pages/Referrals/useReferral"

// Local Interfaces
interface AddReferralProps {
  withReferral: IReferrerData | null
  onClose: () => void
}

// Local Types
type ICsvData = Omit<IReferrer, "position"|"link"> & { position?: string | null }

// Handlers
const exportCSVFile = async (referrers: IReferrer[]): Promise<void> => {
  // We format data to export as CSV
  const data: ICsvData[] = referrers.map((r: IReferrer) => {
    // We match refCode
    const refCode: string | null = getRefCodeFromLink(r.link!)

    return {
      name: r.name,
      email: r.email,
      position: r.position?.label,
      // This line extract only the refCode value as refcode
      url: r.link,
      refCode,
    }
  })

  // New ObjectsToCsv instance
  const csv: ObjectsToCsv = new ObjectsToCsv(data)

  // We save CSV file to disk
  const csvString: string = await csv.toString()

  // We create a "ghost" anchor DOM element
  const a: HTMLAnchorElement = document.createElement("a")

  // We add all properties that allow us to download the file
  a.href = `data:text/csv;charset=utf-8,${encodeURI(csvString)}`
  a.target = "_blank"
  a.download = "referrals.csv"

  // We trigger the click (download)
  a.click()
}

/**
 *
 *  Part: Add Referral Box Content
 *  @description add referral modal content that allows to user to generate referral codes
 *
 */
const AddReferral: React.FunctionComponent<AddReferralProps> = ({ withReferral, onClose }): JSX.Element => {
  // States
  const [selectedMode, setSelectedMode] = useState<Modes>("one-by-one")
  const [referrers, setReferrers] = useState<IReferrer[]>(
    [{
      position: null,
      email: withReferral?.email || null,
      name: withReferral?.name || null,
      link: null
    }]
  )

  // Constants
  const edit = !!withReferral

  return (
    <div className="add-referral-box space-y-8 text-gray-700 px-8 py-4">
      {/* Head */}
      <div className="add-referral-head">
        <h1 className="font-light">{ !edit ? "Add Referrals" : "New Referral Link" }</h1>

        { !edit ? (
          <p className="mt-4 text-gray-500">
            Choose the company positions page or a specific position, and then associated the referral's email address with it. You may paste a single or multiple email address, or upload a csv file with the email address.
          </p>
        ) : (
          <p className="mt-4 text-gray-500">
            Choose the company positions page or a specific position, and then associated the referral's email address with it. You may paste a single or multiple email address, or upload a csv file with the email address.
          </p>
        ) }
      </div>

      {/* Controls */}
      { !edit && (
        <div className="add-referral-controls space-x-16">
          {/* One by One button */}
          <button
            onClick={() => setSelectedMode("one-by-one")}
            className={cx("pb-1", { "border-b": selectedMode === "one-by-one" })}
          >
            Create 1 By 1
          </button>

          {/* CSV Button */}
          {/*<button*/}
            {/*onClick={() => setSelectedMode("csv")}*/}
            {/*className={cx("pb-1", { "border-b": selectedMode === "csv" })}*/}
          {/*>*/}
            {/*Upload list in CSV*/}
          {/*</button>*/}
        </div>
      )}

      {/* Body */}
      <div className="add-referrals-body">
        {/* One By One mode */}
        { selectedMode === "one-by-one" && <OneByOne referrers={referrers} setReferrers={setReferrers} edit={edit} /> }

        {/* CSV Mode */}
        { selectedMode === "csv" && ( "CSV mode") }
      </div>

      {/* Bottom (Buttons) */}
      <div className={cx("add-referrals-buttons flex pt-4", { "justify-between": !edit, "justify-end": edit })}>
        { !edit && <Button text="Download CSV" type="wizard-black" size="md" className="w-auto hover:bg-gray-700" onClick={() => exportCSVFile(referrers)} /> }
        <Button text="OK" type="wizard-black" className="w-32 hover:bg-gray-700" onClick={() => onClose()} />
      </div>
    </div>
  )
}

export default AddReferral
