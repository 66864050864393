import { ICompanyFormPage, ICompanyInfo, IDisplayQuestion } from "@/models";
import { ILeaderInfo } from "@/models/companyInfo";
import { CompanyFormPageAdapter } from "./companyFormPageAdapter";

function isValidLeaderInfo(info: ILeaderInfo | null): boolean {
	if (!info) {
		return false;
	}

	// return true in order to send empty data (if user want's to remove data)
	return true
}

export class CompanyLeadersPageAdapter extends CompanyFormPageAdapter {
	constructor(page: ICompanyFormPage) {
		super(page, "leaders");
	}

	protected _doUpdateFormPageFromCompany(company: ICompanyInfo): ICompanyFormPage {
		const questions = this.page.questions;

		company.leaders?.forEach((rec, index) => {
			questions[index] = this.createQuestions(0,
				[
					{
						value: rec.name || ""
					},
					{
						value: rec.jobTitle || ""
					},
					{
						value: rec.email || "",
						type: "email"
					},
					{
						value: rec.description || ""
					},
					{
						value: rec.image
					}
				]
			)
		})

		return this.page;
	}

	createUpdate(): Partial<ICompanyInfo> {
		const allValues = this.allQuestionGroups()
			.map(list => this.collectValues(list))
			.filter(isValidLeaderInfo)

		return allValues.length ? { leaders: allValues as ILeaderInfo[] } : {};
	}

	/////////// Implementation ///////////

	private collectValues(list: IDisplayQuestion[]): ILeaderInfo | null {
		if (!list || !list.length) {
			return null
		}
		return {
			name: list[0]?.value as string,
			jobTitle: list[1]?.value as string,
			email: list[2]?.value as string,
			description: list[3]?.value as string,
			image: list[4]?.value as string,
		}
	}
}