import IconButton from "@mui/material/IconButton"
import Avatar from "@mui/material/Avatar";
import { useState, useContext, useCallback, useEffect } from "react";
import Menu from "@mui/material/Menu"
import type { ILocaleRecord } from "lnrd-locales"

import AppContext from "@/context/AppContext";
import { MenuItem } from "@mui/material";

const topStyle = {
  width: "24px",
  height: "24px"
}

const buttonStyle = {
  ...topStyle,
  "marginTop": "6px"
}

const itemStyle = {
  width: "16px",
  height: "16px",
  "margin": "0 5px 0 0"
}

const LocaleMenu: React.FunctionComponent = (): JSX.Element => {

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const { locale, setLocale, localizer } = useContext(AppContext);

  const onMenuToggle = useCallback((evt?: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(evt?.currentTarget || null);
  }, []);

  const onClickOutside = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const onSetLocale = useCallback((loc: string) => {
    setMenuAnchor(null);
    setLocale(loc);
  }, [])


  // const [avatarProps, setAvatarProps] = useState({
  //   props: {},
  //   // defaultComponent: "div",
  // })

  const [langs] = useState<ILocaleRecord[]>(localizer.getAllLocales());
  const [localeRec, setLocalRec ] = useState<ILocaleRecord | null>(null); 

  useEffect(() => {
    setLocalRec(localizer.getLocale(locale))
  }, [locale])


  // Effects

  if (! (langs?.length > 1) || !localeRec) {
    return <></>;
  }


  return (
    <>

      <IconButton size="small" onClick={onMenuToggle}
        style={{...buttonStyle}}
        aria-controls={menuAnchor ? 'account-menu' : undefined}
        aria-haspopup={Boolean(menuAnchor)}
        aria-expanded={menuAnchor ? 'true' : undefined}>
        <Avatar component="div" src={`/icons/lang/${localeRec.iconFile}`} style={{...topStyle}}/>
      </IconButton>
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={onClickOutside}>
        {
          langs.map((lang, ind) => (
            <MenuItem onClick={() => onSetLocale(lang.locale)} key={`ln-${ind}`}>
            <img src={`/icons/lang/${lang.iconFile}`} style={{...itemStyle}}/>
            {lang.localName}</MenuItem>
          ))
        }
      </Menu>
    </>
  );
};

export default LocaleMenu;
