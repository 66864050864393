// React
import React, { useEffect, useState } from 'react'

import AutosizeInput from 'react-input-autosize'

import cx from 'classnames'

// Wizard Form Context
import { WizardFormContext } from "@components/WizardForm/context"
import { objectUtils } from '@/helpers'
import { QuestionCollection } from '@/models/formPage'
import { formModelUtils } from '@/models/formModelUtils'
import { ITextSelectionState, createTextSelectionState } from '../../../../helpers/textSelectionState'

interface ISimpleInputProps {
  placeholder: string
  pageIndex: number
  index: number
  subIndex: number
  isCustom: boolean
  isInline: boolean
  disabled: boolean
}

const SimpleInput: React.FC<ISimpleInputProps> = ({
  placeholder,
  isCustom,
  pageIndex,
  index,
  subIndex,
  isInline,
  disabled
}) => {

  // Context
  const {
    setDataCollection,
    setDirty,
    dataCollection,
    templateQuestions,
    customQuestions
  } = React.useContext(WizardFormContext)

  const [textInput, setTextInput] = useState<HTMLInputElement | null>(null);
  const [textSelection, setTextSelection] = useState<ITextSelectionState | null>(null)


  // Const
  const questionTypeKey = !isCustom ? "questions" : "customQuestions"

  // Handlers
  const onChange = (e: any) => {
    setDataCollection((dc: any) => {
      // Copy of data collection to be modified
      const newDc: any = [...dc]
      const questions: QuestionCollection = newDc[pageIndex][questionTypeKey]
      const templates = isCustom ? customQuestions : templateQuestions
      formModelUtils.setValueInQuestionCollection({
        collection: questions,
        index,
        subIndex,
        template: templates[pageIndex][index][subIndex],
        data: { value: e.target.value }
      })
      return newDc
    })
    setDirty(true)
    textSelection?.save()
  }

  useEffect(() => {
    textSelection?.restore();
  })

  useEffect(() => {
    if (textInput) {
        setTextSelection(createTextSelectionState(textInput))
    }
}, [textInput])



  // Reference to current Question values (including all sub-parts)
  const currentQuestionValue = objectUtils.getValueByKeys(dataCollection, [pageIndex, questionTypeKey, index, subIndex, "value"], "")

  return (
    <AutosizeInput
      ref={(el: unknown) => {
        const asi = el as AutosizeInput;
        if (asi?.getInput as any) {
          setTextInput(asi.getInput());
        }
      }}
      className={cx("placeholder-text-gray-400 wiz-edit-text pb-1",
        {
          "mt-2": !isInline,
          "ml-2 mr-2 mt-1.5": isInline
        }
      )}
      inputStyle={{
        outline: 'none',
        fontWeight: 300
      }}
      placeholder={placeholder}
      value={currentQuestionValue || ""}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default SimpleInput
