import { useContext } from "react";
import AppContext from "@/context/AppContext";
import { IComponentRenderProps, IPage } from "./interfaces";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ProgressBar from "@ramonak/react-progress-bar";

export const ProgressBarSection: React.FunctionComponent<{ currentStep: number, stepsCount: number }> = ({
    currentStep, stepsCount
}):JSX.Element => {
    const { tr } = useContext(AppContext);
    return (
        <div>
            <div className="progress-bar-label">{`${Number(currentStep)}/${Number(stepsCount)} ${tr("Complete")}`}</div>
            <ProgressBar
            width="159px"
            height="4px"
            bgColor="black"
            isLabelVisible={false}
            completed={100 * (Number(currentStep) / Number(stepsCount))}
            />
        </div>
    )
}

export const StepperTrackerSection: React.FunctionComponent<{ 
    pagesFromStartToCurrent: IPage[], 
    navigateToStep: (stepId: number) => any,
    componentProps: IComponentRenderProps,
    currentStepIndex: number
}> = ({
    pagesFromStartToCurrent,
    navigateToStep,
    componentProps,
    currentStepIndex
}):JSX.Element => {
    const { tr } = useContext(AppContext);
    return(
        <div className="components-stepper-step-name-tracker">
            {pagesFromStartToCurrent.map((page, i)=> (
                <div key={`${i}-${page.stepName}`} className={`${i===currentStepIndex ? 'step-name-tracker-bold' : ''}`}>
                    {i !== 0 &&
                    <span>{">"}&nbsp;</span>}
                    
                    {typeof page.stepName === "string" && 
                    <span 
                    className="step-name-tracker-step-name-single"
                    onClick={() => navigateToStep(i)}>{tr(page.stepName)}&nbsp;</span>}

                    {typeof page.stepName !== "string" &&
                    page.stepName(componentProps)}

                </div>
            ))}
        </div>
    )
}

export const StepperNavigationButtons: React.FunctionComponent<{ 
    onNextBtnClicked: () => any,
    onPrevBtnClicked: () => any,
    onSkipBtnClicked: () => any,
    hidePrevButton?: boolean, 
    customNextBtnText?: string | null | undefined,
    hideSkipButton?: boolean,
    }> = ({
    onNextBtnClicked, 
    onPrevBtnClicked,
    onSkipBtnClicked,
    hidePrevButton,
    customNextBtnText,
    hideSkipButton
}): JSX.Element => {
    const { tr } = useContext(AppContext)

    return (
    <div>
        {!hidePrevButton && 
        <button 
        onClick={() => onPrevBtnClicked()} 
        className="components-stepper-prev-button components-stepper-button">
            <ArrowBackIosIcon/>
        </button>}
        <button 
        onClick={() => onNextBtnClicked()} 
        className={`components-stepper-next-button 
                    components-stepper-button 
                    ${hidePrevButton ? "components-stepper-next-button-radius" : ""}`}>
            {customNextBtnText ?? tr("Next")}
        </button>
        {!hideSkipButton &&
        <button 
        onClick={() => onSkipBtnClicked()} 
        className="component-stepper-skip-button"> 
            {tr("Skip")}
        </button>}
    </div>
    )
}