import { getPositionsByCompanyId } from "@/graphql/queries/positions";
import { graphQueries } from "@/helpers";
import { IPosition } from "@/interfaces/pages/positions";
import { throttle } from "lodash";
import env from "react-dotenv";
import { EditorApi } from "../../data/editor.api";

const getElasticQuery = (searchVal: string | null | undefined, buildingBlockType?: string, positionName?: string ) => {
    const isSearchValExist = searchVal && searchVal.length > 0 ? true : false;
    const query = {
        "bool": {
            "should": [
                ...(buildingBlockType ? [{ "match": { "group_id": buildingBlockType } }] : []),
                ...(positionName ? [{ "match": { "position_name": positionName } }] : []),
                ...(isSearchValExist ? 
                    [{ "match": { "surveyjs_question.title": searchVal } }] 
                    : (!buildingBlockType && !positionName) ? [{"match_all": {}}] : []),
            ]
        }
    } 
    
    return {query}
}

export const throttleSearchQuestion = throttle(async ({searchVal, buildingBlockType, positionName}) => {
    const query = getElasticQuery(searchVal, buildingBlockType, positionName)
    console.log(query);
    
    const response = await EditorApi.searchQuestions(query)
    const { result } = response || {};
    return result ?? []
}, 1000);

const COMPANY_ID = env.POSITIONBANK_ID
const positionGraphRequest = async (query: {
    count?: number;
    name?: string;
    }) => {

    if (!COMPANY_ID) {
        throw new Error("Missing env variable POSITIONBANK_ID");
    }
    return graphQueries.sendRequest(getPositionsByCompanyId(""), {
        companyId: COMPANY_ID,
        sizeOfPage: query.count || 0,
        page: 0,
        titleName: query.name || "",
        // assessmentStatus: ASSESS_STATUS
    })
}

const getPositions = async (query: { count?: number; name?: string }): Promise<IPosition[] | undefined> => {
    try {
        const data = await positionGraphRequest(query)
        const { positions } = data?.getV2Positions || {}
        return positions || []
    } catch (e) {
        console.error(e)
    }
}

const SELECT_COUNT = 10
export const getThrottlePositionsAsSelectOptions = throttle(async (searchVal?: string): Promise<{ value: string, label: any }[]> => {
    const positions = await getPositions({ count: SELECT_COUNT, name: searchVal ?? undefined})
    const options = positions?.map((f: any) => ({
        label: f.matchingTitle,
        value: f.matchingTitle,
    }))
    return options ?? []
}, 2000)