//PDF renderer for CV at the bottom of report
import { Document, pdfjs, Page } from "react-pdf";
import React from "react";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import './style.css';

export function CvPdf({
  url,
}: {
  url: any;
}): JSX.Element {
  const [numPages, setNumPages] = React.useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const renderPages = (numOfPages: number) => {
    const pagesArray: JSX.Element[] = [];
    if (numOfPages && numOfPages > 0) {
      for (let i = 1; i <= numOfPages; i++) {
        pagesArray.push(
          <Page
            renderAnnotationLayer={false}
            key={i}
            pageNumber={i}
            className="cv-custom-page"
            renderTextLayer={false}
          />
        );
      }
      return pagesArray;
    }
  };

  return (
    <div className="cv-pdf">
      <Document 
        error={"Failed to load CV file"}
        file={{
          url: url?.split("?")[0],
        }}
        renderMode="canvas"
        // options={DocOptions}
        // onLoadError={() => alert("Load Error!")}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {renderPages(numPages)}
      </Document>
    </div>
  );
}
export const MemoizedCvPdf = React.memo(CvPdf);
