import { isEmpty } from "lodash"
import { useState } from "react"

export const CheckListDropDown: React.FunctionComponent<{
    list: string[], 
    selected: number,
    noItemsMessage?: string
}> = ({ list, selected, noItemsMessage }) => {
    const [ _selected, _setSelected ] = useState<number>(selected)
    const [ open, setOpen ] = useState(false)
    if(isEmpty(list.filter(l => Boolean(l)))) {
        return <div className="m-2 relative opacity-50">
            <div className="px-4 py-1 m-1 bg-gray-100 inline-block rounded">{ 
                noItemsMessage || "No Items" 
            }</div>
        </div>
    }
    return <div className="m-2 relative">
        <div className="px-4 py-1 m-1 bg-gray-100 inline-block rounded cursor-pointer " onClick={ () => setOpen(!open) } >{ 
            list[_selected] 
        }</div>
        {<div 
            style={ { zIndex: 99, minWidth: '100px' } }
            className={"absolute inline-block bg-gray-100 m-1 rounded " + (
            open ? "" : " invisible w-0 h-0 opacity-0 m-0 p-0 "
        )}>
            { list.map((item, index) => <div 
                key={index} 
                className={"hover:bg-gray-300 cursor-default p-1 rounded m-1 whitespace-nowrap"}>
                    <input type="checkbox" />
                    <div 
                        className="px-4 py-1 inline-block cursor-pointer "
                        onClick={ () => {
                        _setSelected(index)
                        setOpen(false)
                    } }>{ item }</div>
                </div>) }
        </div>}
    </div>
}