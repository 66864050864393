import React, { useContext } from "react";
// Utils
import ProgressBar from "@ramonak/react-progress-bar";

import cx from "classnames";

import "./styles.css";
import AppContext from "@/context/AppContext";


/**
 *
 *  ProgressBarComponent in survey
 *  @description ProgressBarComponent in survey
 *
 */
const ProgressBarComponent: React.FunctionComponent<{mobile: boolean, currentPage: number, totalPages: number, completed: number}> = ({ mobile, currentPage, totalPages, completed }): JSX.Element => {
  const { tr } = useContext(AppContext);
    return (
        <div
          className={cx("progress-bar-container", {
            "progress-bar-container-desktop": !mobile,
          })}
        >
          <span style={{fontSize: "10px"}}>{`${currentPage} / ${totalPages} ${tr("complete")}`}</span>
    
          <ProgressBar
            width="90px"
            height="4px"
            bgColor="black"
            isLabelVisible={false}
            completed={completed}
          />
        </div>
      );
    };

export default ProgressBarComponent
