/**
 *
 * @module src/pages/Referrals
 *
 * @description referrals list
 *
 * @author zohar
 *
 */

// React
import { useState, useEffect, useContext } from "react"

// Auth
import { useAuth, IAuth } from "@auth";

import ObjectsToCsv from "objects-to-csv";

// Components
import {
  Modalbox,
} from "@components";
import MaterialTable from "@/components/MaterialTable";
import DropDownWithDotsMaterial from "@/components/DropDownWithDotsMaterial";
import LoadingButton from '@mui/lab/LoadingButton';


// Parts
import AddReferral from "@pages/Referrals/AddReferral";

// Logic
import useReferral, { Referral, IReferrer } from "@pages/Referrals/useReferral";

// Graphql
import { getReferralsByCompanyId } from "@queries/company";

// Helpers
import { generateQueryKey } from "@helpers";

// Utils
import cx from "classnames";

// Interfaces
import { GridColDef } from "@mui/x-data-grid";

import { ISettingsService, SettingsService } from "@/helpers/settingsService"

// Icons
import SummarizeIcon from '@mui/icons-material/Summarize';
import AppContext from "@/context/AppContext";

const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search)

const Referrals: React.FunctionComponent = (): JSX.Element => {
    // Auth
    const { company }: IAuth = useAuth();
    const [settings] = useState<ISettingsService>(new SettingsService(urlSearchParams));
    const SIMULATOR_LINK = `${settings.getSetting("JOBSIMULATOR_ORIGIN")}`;

    // States
    const [refferalsCount, setReferralsCount] = useState<number>(0);
    const [originalData, setOriginalData] = useState<Referral[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [csvLoading, setCsvLoading] = useState<boolean>(false);

    const { tr } = useContext(AppContext);

    // Logic
    const {
    fetcher,
    onClose,
    showModal,
    setShowModal,
    deleteReferrer,
    actionMenuItems,
    referralToRenew,
    referralToDelete,
    showAddReferralBox,
    openAddReferralModal,
    }: Referral = useReferral();

    // Fetch data
    useEffect(() => {
        setLoading(true);
        fetcher(getReferralsByCompanyId("getReferrers"), 0 , 20, '')
            .then((data: any) => {
                setLoading(false);
                // Save original data for search
                setOriginalData(data.items);
                // Save referrals count
                setReferralsCount(data.count);
                fetcher(getReferralsByCompanyId("getReferrers"), 0 ,data.count, '')
                    .then((data: any) => {
                        // Save original data for search
                        setOriginalData(data.items);
                    })
            })
    }, [ company?.id ]);

    // Handlers
    const exportReferralsToCsv = async (): Promise<void> => {
        setCsvLoading(true)
        const res = await fetcher(
            getReferralsByCompanyId(generateQueryKey(company!.id)),
            0,
            refferalsCount,
            ""
        )
        // We format data to export as CSV
        const data: any = res.items.map((r: IReferrer) => {
        const link = SIMULATOR_LINK + "/" + company?.slug + "/all-positions?refCode=" + r.code;

        return {
            name: r.name,
            email: r.email,
            position: "all-positions",
            // This line extract only the refCode value as refcode
            url: link,
            refCode: r.code,
        };
        });

        // New ObjectsToCsv instance
        const csv: ObjectsToCsv = new ObjectsToCsv(data);

        // We save CSV file to disk
        const csvString: string = await csv.toString();

        // We create a "ghost" anchor DOM element
        const a: HTMLAnchorElement = document.createElement("a");

        // We add all properties that allow us to download the file
        a.href = `data:text/csv;charset=utf-8,${encodeURI(csvString)}`;
        a.target = "_blank";
        a.download = "referrals.csv";

        // We trigger the click (download)
        a.click();
        setCsvLoading(false)
    };

    // Columns
    const columns:GridColDef[] = [
        {field: 'name', headerName: tr('Name'), flex: 1, renderCell: (params) => (
            <div
            className={cx("font-medium", {
              "text-xs text-gray-500": !params.row.name,
              "text-gray-700": params.row.name,
            })}
            >
            {params.row.name || tr("Not provided")}
          </div>
        )},
        {field: 'email', headerName: tr('Email'), flex: 1,},
        {field: 'options' ,headerName: '', flex: 1, align: 'right', sortable: false, disableColumnMenu: true, renderCell:  (params: any) => <DropDownWithDotsMaterial options={actionMenuItems(params.row)} /> }
    ];

  return (
    <main id="referrals">
        {/* Actions */}
        <div style={{display: 'flex', padding: '38px 0', height: '120px', width: '100%'}} >
          {/* Add Referrals Button */}
          <button
            onClick={() => openAddReferralModal()}
            className={cx(
              [
                "inline-flex ml-5 px-6 py-3.5",
                "items-start justify-start",
                "bg-black rounded focus:outline-none",
                "transition rounded-sm",
              ]
            )}
            >
            <p className="text-sm font-medium leading-none text-white">
              { `+ ${tr("Add Referrals")}`}
            </p>
          </button>
          {/* Download CSV Button */}
          <LoadingButton
              style={{border: 'none', marginLeft: 'auto'}}
              color='inherit'
              loading={csvLoading}
              loadingPosition="start"
              startIcon={<SummarizeIcon/>}
              variant="outlined"
              onClick={() => exportReferralsToCsv()}
              >
                  {tr("Export Report")}
            </LoadingButton>
        </div>
      {/* Refferals Table */}
        <MaterialTable
        columns={columns}
        loading={loading}
        emptyStateTitle={tr("There are currently no referrals links created.")}
        originalData={originalData}
        searchOnColumns={['name', 'email']} 
        />
      {/* Confirm Delete Modalbox */}
      {showModal && (
        <Modalbox
          message={`${tr("Delete")} ${referralToDelete!.name}?`}
          description={`${tr("You're about to permanently delete")} ${
            referralToDelete!.name
          }. ${tr("Are you sure you want to continue?")}`}
          confirmText="Delete"
          onCancel={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
          onConfirm={() => deleteReferrer(referralToDelete!)}
        />
      )}

      {/* Show Add Referral Modalbox: Add | Edit (with referral prop) */}
      {showAddReferralBox && (
        <Modalbox
          className="w-9/12 max-w-6xl max-h-full overflow-y-auto"
          onClose={() => onClose()}
        >
          <AddReferral
            withReferral={referralToRenew || null}
            onClose={onClose}
          />
        </Modalbox>
      )}
    </main>
  );
};

export default Referrals;