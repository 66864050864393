// React
import React from 'react'

// Context
import { WizardFormContext } from "@components/WizardForm/context"

// Parts
import AddField from "@components/WizardForm/components/AddField"
import QuestionsList from "@components/WizardForm/components/QuestionsList"
import CustomQuestions from "@components/WizardForm/components/CustomQuestions"

// Hooks
import { useWizard } from "@components/WizardForm/hooks"

// Utils
import cx from "classnames"

// Interfaces
import { IUseWizard } from "@components/WizardForm/interfaces"
import { IFormPage } from '@/models/formPage'

// Local Interfaces
interface PageProps {
  page: IFormPage 
  pageIndex: number
}

/**
 *
 *  Page
 *  @description contains all questions
 *
 */
const Page: React.FC<PageProps> = ({ page, pageIndex }) => {
  // Context
  const {
    customQuestions,
    setCustomQuestions
  } = React.useContext(WizardFormContext)
  // Wizard Hook
  const { getFrom }: IUseWizard = useWizard()

  // Handlers
  const onAddFieldClick = (): void => {
    // Constants
    const customQuestionModel = page.models ? page.models[0] : {}
    const newCq = [...customQuestions]

    // We add a new field to custom question list from model
    newCq[page.key].push(customQuestionModel)

    // customQuestions is a global state property
    setCustomQuestions(newCq)
  }


  return (
    <div className="w-full max-w-2xl pt-8 flex flex-col justify-center items-start">
      <div className="wiz-page-header">
      { page.questionsTitle && <div 
        className="mb-4 wiz-text-lg">{getFrom(page.questionsTitle)} </div> }

      {page.questionsSubtitle && <div className={cx("mb-16 wiz-text-sm", {
        "wiz-text-lg": !page.questionsTitle
      }) }>{getFrom(page.questionsSubtitle)}</div>}
      </div>
      <div className="w-full">
          {/* List of Questions (main question array) */}
          <QuestionsList questions={page.questions || []} pageIndex={pageIndex} />

        {/* Added by User Questions */}
        { (page.addFields || page.customQuestions?.length) && 
              <CustomQuestions 
                questions={customQuestions[page.key]} 
                pageIndex={page.key} /> }

        {/* Add new Question button */}
        { page.addFields && <AddField 
              className="mt-10 wiz-add-field"
              text={`Add ${page.singularName}`} 
              onClick={onAddFieldClick} /> }
      </div>
   

    </div>
  )
}

export default Page
