import { IconButton, Select, SelectProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TooltipIconButton: React.FunctionComponent<{tooltipTitle: string, iconSrc: string, onClick?: () => any }> = ({ 
tooltipTitle, iconSrc, onClick }): JSX.Element => (
    <Tooltip title={tooltipTitle} placement='top'componentsProps={{
        tooltip: {
        style: { backgroundColor: "#fff", border: "1px solid #DFE0EB", color: "#000" }
        }
    }}>
        <IconButton onClick={onClick}>
        <img src={iconSrc}/>
        </IconButton>
    </Tooltip>
)

export const StyledSelect = styled(Select)<SelectProps>({
    height: "35px",
    fontSize: "14px",
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #0006 !important"
    },
    "& .MuiOutlinedInput-notchedOutline:focused": {
        border: "1px solid #0006 !important"  
    },
});
    