import { ICompanyFormPage, ICompanyInfo, ICompanyFormData } from "@/models"
import { ICompanyFormPageAdapter } from "./companyFormPageAdapter"
import { CompanyCulturePageAdapter } from "./companyCulturePageAdapter"
import { CompanyValuesPageAdapter } from "./companyValuesPageAdapter"
import { CompanyLeadersPageAdapter } from "./companyLeadersPageAdapter"
import { CompanyStoryPageAdapter } from "./companyStoryPageAdapter"
import { CompanyIntegrationsPageAdapter } from "./companyIntegrationsPageAdapter"
import { CompanyInvitePageAdapter } from "./companyInvitePageAdapter"
import { stringUtils } from "@/helpers"

export type CompanyFormPageKeys = keyof ICompanyFormData

const adapterMap: Map<CompanyFormPageKeys, (c: ICompanyFormPage) => ICompanyFormPageAdapter> = 
	new Map();

// for some reason, I couldn't initialize the map inline

adapterMap.set("story", (c: ICompanyFormPage) => new CompanyStoryPageAdapter(c))
adapterMap.set("culture", (c: ICompanyFormPage) => new CompanyCulturePageAdapter(c))
adapterMap.set("values", (c: ICompanyFormPage) => new CompanyValuesPageAdapter(c))
adapterMap.set("leaders", (c: ICompanyFormPage) => new CompanyLeadersPageAdapter(c))
adapterMap.set("integrations", (c: ICompanyFormPage) => new CompanyIntegrationsPageAdapter(c))
adapterMap.set("invite", (c: ICompanyFormPage) => new CompanyInvitePageAdapter(c))


function updateFormPageFromCompany(pageName: CompanyFormPageKeys, 
	adapter: ICompanyFormData,
	company: ICompanyInfo): ICompanyFormData {
	const creator = adapterMap.get(pageName)
	if (!creator) {
		throw new Error(`updateCompanyFormPage: unknown page ${pageName}`)
	}
	const page = adapter[pageName] as ICompanyFormPage
	const pageAdapter = creator(page)
	pageAdapter.updateFormPageFromCompany(company)

	return adapter
}

/**
 * creates an adapter for the provided page
 * if data is not null, we use its data instead of the page
 * @param page 
 * @param data 
 * @returns 
 */
function getAdpaterForFormPage(page: ICompanyFormPage, data?: ICompanyFormPage): ICompanyFormPageAdapter | null {
	if (!page) {
		return null;
	}
	const keys = adapterMap.keys()
	for (let next = keys.next(); !next.done; next = keys.next()) {
		const re = stringUtils.stringToRegex(next.value, "i")
		if (re.test(page.name) || re.test(page.hash)) {
			const creator = adapterMap.get(next.value)
			return (creator && creator(data || page)) || null
		}
	}
	return null
}

export const applyCompanyInfoToFormPage = updateFormPageFromCompany
export const getAdapterForPage = getAdpaterForFormPage

