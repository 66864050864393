// Auth
import { IAuth } from "@auth"

// Shared Interfaces
interface IPermissionsCollection {
  [index: string]: (auth: IAuth) => boolean
}

/**
 *
 *  Permissions Collection
 *  @description contains all types of permissions
 *
 */
const permissions: IPermissionsCollection = {
  admin: (auth) => {
    return ["admin", "lnrd_admin"].includes(auth.user!.role)
  },
  companyAdmin: (auth) => {
    return ["company_admin","admin", "lnrd_admin"].includes(auth.user!.role)
  },
  companyUser: (auth) => {
    return ["company_admin","admin", "lnrd_admin","company_user","user"].includes(auth.user!.role)
  },
  hr: (auth) => {
    return ["HR", "Management"].includes(auth.user!.department) || ["company_admin","admin", "lnrd_admin"].includes(auth.user!.role)
  }
}

export default permissions
export type { IPermissionsCollection }
