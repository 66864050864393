import { CKEditor, CKEditorEventHandlerProp, CKEditorProps } from "ckeditor4-react";
import Label from "../Label/Label";

import './style.css';

const CKEditorCustom: React.FunctionComponent<CKEditorProps<Partial<CKEditorEventHandlerProp>> & {label?: string }> = (props):JSX.Element => {
    const { label } = props;
    return (
        <div>
            {label &&
            <Label>{label}</Label>}
            <div className="richtext-wrapper">
                <CKEditor
                config={{
                    toolbar: [
                        { name: 'basicstyles', items: [ 'Underline', 'Italic', 'Bold', 'Bullet' ] },
                        { name: 'paragraph', items: [ 'BulletedList' ] }
                    ],
                    removeButtons: 'Subscript,Superscript',
                }}
                {...props}/>
            </div>
        </div>
    )
}

export default CKEditorCustom;