import { IAuth, useAuth } from "@/auth";
import { createCompanyMutation } from "@/graphql/mutations/createCompany";
import { graphQueries } from "@/helpers";
import { ICompanyInfo } from "@/models";
import { Dialog, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Button from "../Button";
import LNRDLoading from "../LNRDLoading";

import isValidDomain from 'is-valid-domain';

import './style.css';

interface IAddNewCompanyDialog {
    openModal: boolean,
    setModal: (val: boolean) => void
    onCompanyCreated: () => void
}

const AddNewCompanyDialog: React.FunctionComponent<IAddNewCompanyDialog> = ({
    openModal,
    setModal,
    onCompanyCreated
}): JSX.Element => {

    const { setCompany }: IAuth = useAuth()

    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [emailDomain, setEmailDomain] = useState<string>("");
    const [companyDomains, setCompanyDomains] = useState<string[] | null>(null);
    const [validationError, setValidationError] = useState<string | null>(null);

    const getAllCompanies = async (filter = ""): Promise<Partial<ICompanyInfo>[] | undefined | null> => {
        const res = await graphQueries.getAllCompanies({ key: 'companies', filter: filter,limit: 1000 });
        if (res.error) {       
            console.error("Can't fetch companies for validation.")
            return 
        }
        
        return res.data
    }

    const createCompany = async (emailDomain: string) => {
        const res = await graphQueries.sendRequest(createCompanyMutation ,{"emailDomain": emailDomain});
        if(res.errors) {
            console.error("Can't create company.")
            return 
        }

        return res
    }

    const getCompanyDomainsFromCompanies = (companies: Partial<ICompanyInfo[]> | null): string[] | null => {
        if (!companies) 
            return null
        
        return companies.map(c => c?.emailDomain as string)
    }

    const isUniqueDomain = (domain: string) => {
        return !companyDomains?.includes(domain)
    }

    const onInputChanged = (value: string) => {
        if(validationError)
            setValidationError(null)
            
        setEmailDomain(value);
    }

    const onNewCompanyCreated = (companyData: Partial<ICompanyInfo>) => {
        setCompany(companyData as any)
        history.push("/company/create#company-story")
        setModal(false)
        onCompanyCreated();
    }

    const onFormSubmit = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        e.preventDefault();
        
        if(!isUniqueDomain(emailDomain)) {
            setValidationError("The given domain is already taken.")
            return 
        }
            
        if(!isValidDomain(emailDomain)) {
            setValidationError("The given domain is not valid.")
            return 
        }
        
        setLoading(true)
        createCompany(emailDomain)
            .then((reponse) => {

                if(reponse) {
                    
                    getAllCompanies(emailDomain)
                        .then((companyData) => {

                            if(companyData && companyData.length > 0) {
                                onNewCompanyCreated(companyData[0])
                            }

                        })
                        .catch(e => {
                            console.error(e);
                            setLoading(false)
                        })
                }
        })
        .catch(e => {
            console.error(e);
            setLoading(false)
        })
    }

    const handleClose = (event: any, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && reason == "backdropClick") 
            setModal(false);
            return;
    }

    useEffect(() => {
        if(!companyDomains) {
            setLoading(true)
            getAllCompanies()
                .then(data => {
                    if (data) {
                        const allCompanyDomains = getCompanyDomainsFromCompanies(data as any)
                        setCompanyDomains(allCompanyDomains)
                    }
                    setLoading(false)
                })
                .catch(e => {
                    console.error(e);
                    setLoading(false)
                })
        }
        
    }, [companyDomains])

    const UI = (
        <div className="create-new-company-container">
            <h1 className="create-new-company-header">
                {"Add a new company"}
            </h1>
            <div className="create-new-company-error-section">
                {validationError ? 
                    <span>
                        {validationError}
                    </span> 
                : null}
            </div>
            <form className="create-new-company-form">
                <div className="create-new-company-form-input">
                    <label>
                        {"Email domain name: "}
                    </label>
                    <TextField 
                    placeholder="i.e somthing.io"
                    id="standard-basic" 
                    variant="standard"
                    onChange={(e) => onInputChanged(e.target.value)} />
                </div>
                <Button text={"OK"} type="regular" onClick={(e) => onFormSubmit(e as any)}/>
            </form>
        </div>
    )

    return (
        <Dialog
        open={openModal}
        onClose={handleClose}>
            {
                loading ? <LNRDLoading/> : UI
            }
        </Dialog>
    )
}

export default AddNewCompanyDialog;