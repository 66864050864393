/**
 *
 *  Use Create Company
 *
 *  @module pages/CreateCompany/useCreateCompany
 *
 *  @author diegoulloao
 *
 */

// React
import { useEffect, useState } from "react"

// Model
import defaultModel from "@formsData/createCompany.json"

// Interfaces
import { ICreateCompany } from "@interfaces/pages/company"
import { graphQueries, objectUtils } from "@/helpers"
import { ICompanyInfo, ICompanyFormPage } from "@/models"
import { CompanyFormDataWrapper } from "@/models/companyFormData"
import { applyCompanyInfoToFormPage, CompanyFormPageKeys } from "./formAdapters"
import { IAuth, useAuth } from "@/auth"
import { IFormPageCollection } from "@/models/formPage"

// Local Interfaces
interface IUseCreateCompanyProps {
    //  selectedCompany?: any
    //companyInformation?: ICompanyInfo
	companyId: string
}

function applyCompanyDataToFormModel(company: ICompanyInfo, pages: Array<ICompanyFormPage>): Array<ICompanyFormPage> {
    //const scrappedData = newModel;

	if (!company) {
        console.warn(`applyCompanyDataToFormModel: missing company`);
        return pages;
    }
	pages = pages.filter(p => p.enabled !== false)
    const formData = new CompanyFormDataWrapper(pages)
    const pageNames: Array<CompanyFormPageKeys> = ["story", "leaders", "values", "culture"]
    pageNames.forEach(name => {
        applyCompanyInfoToFormPage(name, formData, company)
    })

    return pages
}

/**
 *
 *  Logic: Create Company
 *  @description store all create company logic, functions and states management
 *
 */
const useCreateCompany = ({ companyId }: IUseCreateCompanyProps): ICreateCompany => {

    // States
    //const [onboardingData, setOnboardingData] = useState<any>(null)
	const [companyInfo, setCompanyInfo] = useState<ICompanyInfo | null>(null)

    const [ model, setModel ] = 
		useState<IFormPageCollection | null>(null)
	const [isLoading, setIsLoading ] = useState<boolean>(false)

	const [refreshToken, setRefreshToken] = useState<number>(0)
	// used to indicate a change in the refresh token
	const [prevRefreshToken, setPrevefreshToken] = useState<number>(0)
	// Company from Auth
	const { settings, company: authCompany }: IAuth = useAuth()

	
    const ret: ICreateCompany = {
        model,
		isLoading,
		companyInfo,
		setCompanyInfo,
		refresh: () => {
			setRefreshToken(refreshToken + 1)
		}
        //onboardingData,
        //setOnboardingData,
    }
	useEffect(() => {
		let subscribed = true;
		(async (): Promise<any> => {
			const targetId = settings.getSetting("USER_COMPANY_ID") || companyId || authCompany?.id
			if (!subscribed || !targetId) {
				return
			}
			const changed =
				refreshToken !== prevRefreshToken || !companyInfo || companyInfo.id !== targetId
			if (changed) {
				setIsLoading(true)
				setPrevefreshToken(refreshToken)
				const newCompany = await graphQueries.getCompanyById(targetId);
				if (newCompany.data && subscribed) {			
					const tmpl = objectUtils.cloneObject(defaultModel) as IFormPageCollection
					const pages = applyCompanyDataToFormModel(newCompany.data, tmpl.pages as ICompanyFormPage[])
					setCompanyInfo(newCompany.data)
					setModel({ pages, options: tmpl.options })
				}
				setIsLoading(false)
			}
		})()
		return () => { subscribed = false }
	}, [companyId, refreshToken])

    return ret;
}

export default useCreateCompany
