import { ReportHeader } from "../../components";
import { MemoizedCvPdf } from "../../../../../components/CvPdf/CvPdf";
import { getLabelValueArrFromCvInsights } from "../../helpers";

import './style.css';

const CVAndQualifications:React.FunctionComponent<{
    avgYearsPerEmployer?: number;
    totalYearsExp?: number;
    totalYearsManagementExp?: number;
    gaps?: number;
    skills?: string[];
    fields?: string[];
    cv?: string;
    educations?: string[];
    onClickReadRecommendations?: () => void;
}> = ({
    avgYearsPerEmployer,
    totalYearsExp,
    totalYearsManagementExp,
    gaps,
    skills,
    // fields,
    cv,
    educations,
    onClickReadRecommendations
}):JSX.Element => {
    return (
        <div className='cv-and-qualifiqation-container'>
            <ReportHeader
            className="no-print">CV and Qualification</ReportHeader>
            <div className='cv-and-qualifiqation-content-container'>
                {/*Fake CV PLACEHOLDER*/}
                <div className="cv-and-qualifiqation-pdf-container">
                    <MemoizedCvPdf 
                    url={cv}/>
                </div>
                <div className='cv-and-qualifiqation-cv-analyzes-container no-print'>
                    {(avgYearsPerEmployer || totalYearsExp || totalYearsManagementExp || gaps) &&
                    <div className='cv-and-qualifiqation-cv-analyze'>
                        <h3 className='cv-and-qualifiqation-header'>Insights</h3>
                        <div className='cv-and-qualifiqation-insights'>
                            {getLabelValueArrFromCvInsights({
                                avgYearsPerEmployer,
                                totalYearsExp,
                                totalYearsManagementExp,
                                gaps,
                            }).map((item, index) => (
                                <div key={index} className='cv-and-qualifiqation-insight-single'>
                                    <span className='cv-and-qualifiqation-insights-value'>{item.value}</span>
                                    <span className='cv-and-qualifiqation-insights-label'>{item.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>}
                    {skills && skills.length > 0 &&
                    <div className='cv-and-qualifiqation-cv-analyze'>
                        <h3 className='cv-and-qualifiqation-header'>Relevant skills from CV</h3>
                        <div className='cv-and-qualifiqation-cards'>
                            {skills?.map((skill, index) => (
                                <div key={index} className='cv-and-qualifiqation-card-single'>
                                    {skill}
                                </div>
                            ))}
                        </div>
                    </div>}
                    {educations && educations.length > 0 &&
                    <div className='cv-and-qualifiqation-cv-analyze'>
                        <h3 className='cv-and-qualifiqation-header'>Interesting experience</h3>
                        <div className='cv-and-qualifiqation-cards'>
                            {educations?.map((skill, index) => (
                                <div key={index} className='cv-and-qualifiqation-card-single'>
                                    {skill}
                                </div>
                            ))}
                        </div>
                    </div>}
                    {/* {fields && fields.length > 0 &&
                    <div className='cv-and-qualifiqation-cv-analyze'>
                        <h3 className='cv-and-qualifiqation-header'>Fields</h3>
                        <div className='cv-and-qualifiqation-cards'>
                            {fields?.map((skill, index) => (
                                <div key={index} className='cv-and-qualifiqation-card-single'>
                                    {skill}
                                </div>
                            ))}
                        </div>
                    </div>} */}
                    <button 
                    onClick={onClickReadRecommendations}
                    className='cv-and-qualifiqation-cta-tips-button'>
                        Read recommendations
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CVAndQualifications;