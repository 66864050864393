// React
import React from "react"

// Context
import { WizardFormContext } from "@components/WizardForm/context"

// Components
import QuestionFactory from "@components/WizardForm/components/QuestionFactory"

// Utils
import cx from "classnames"

// Helpers
import { validateQuestionList } from "@components/WizardForm/helpers"

// Interfaces
import { QuestionProps } from "@interfaces/pages/question"
import { IDisplayQuestion } from "@/models"
import { TrashIcon } from "@heroicons/react/solid"
import { objectUtils } from "@/helpers"

/**
 *
 *  Question
 *  @description builds and show all questions
 *
 */
const Question: React.FunctionComponent<QuestionProps> =
  ({ question, index, pageIndex, isCustom }): React.ReactElement => {
    // Note: Question contains inline subquestions (subarray of questions)
    //
    // pageIndex: is the page index starting from zero
    // index: is the question (main array) index starting from zero
    // subIndex: is the inline subquestion (child array) starting from zero

	const questions = question as IDisplayQuestion[];
    // Context
    const {
      dataCollection,
      setDataCollection,
      setCustomQuestions
    } = React.useContext(WizardFormContext)

    // Question type key const: "questions" | "customQuestions"
    const questionTypeKey = !isCustom ? "questions" : "customQuestions"

    // Handlers
    const onRemoveFieldClick = (): void => {
      setCustomQuestions((cq: any) => {
        // Copy of custom questions state
        const newCq = [...cq]

        // Remove item in customQuestions array
        newCq[pageIndex].splice(index, 1)

        return newCq
      })

      setDataCollection((dc: any) => {
        // Copy of Data Collection array
        const newDc = [...dc]

        // Remove item in dataCollection array
        newDc[pageIndex][questionTypeKey].splice(index, 1)

        return newDc
      })
    }

    // Validations
    let isValid: boolean

    // If isn't custom then validate
    if ( !isCustom ) {
      const questionData: IDisplayQuestion[] = objectUtils.getValueByKeys(dataCollection, [pageIndex, questionTypeKey, index], []) 
      isValid = validateQuestionList(questionData)

    } else {
      // If custom then no validation needed
      isValid = true
    }

    // If exists at least 1 not static field
    const showIndicators = questions.some(sq => {
		return sq?.type !== "static"
	})

    // If at least one question has inline === true
    const isInline = questions.some((sq: any) => sq?.inline)

    return (
      <div className={cx("w-full",{
        // Border color depends of field validation status
        "q-show-indicator": showIndicators && !isCustom,
        "q-invalid": showIndicators && !isCustom && !isValid,
        "q-valid": showIndicators && !isCustom && isValid,
        "flex justify-start items-center": isInline
      })}>
  
        { questions.map((sq: any, i: number) => (
          <QuestionFactory
            key={i}
            grow={sq.grow}
            subIndex={i}
            question={sq}
            index={index}
            isCustom={isCustom}
            pageIndex={pageIndex}
          />
        )) }
        <div>
        { isCustom && <TrashIcon 
            className="inline-block ml-4 w-5 h-5 cursor-pointer wix-trash" 
            onClick={onRemoveFieldClick}/> }
        </div>
      
      </div>
    )
  }

export default Question
