import { PositionTemplates } from "@/dal/position.interface";

export interface PositionDayInALife {
  time: string;
  description: string;
}

export enum SkillNecessityLevel {
  NONE = "none",
  ADVENTAGE = "an advantage",
  MUST = "a must"
}
export interface PositionSkill {
  necessityLevel: string;
  proficiency: string;
  name: string;
}

export interface IPosition {
  CompanyId: string
  id: string
  assessmentId: string
  assessmentName: string
  assessmentStatus: string
  department: string
  title: string
  description: string
  status: string
  skills: Partial<PositionSkill>[]
  education: any[]
  dayInALife: Array<Partial<PositionDayInALife>>
  positionFormJson: JSON
  cultureAndValue:any[]
  recipients: string[]
  atsEmail: string
  metadata: JSON
  rolesAndResponsibilities: string[]
  updatedRolesAndResponsibilities: string
  eligibilities: string[]
  isPublished: boolean
  leaders: any[]
  positionImage: string
  slug: string
  comProfile: string
  location: string
  jobTypes: string
  templates: PositionTemplates
  educations: any[]
  qualifications: string
  isManager: boolean
  languages: string[]
  hobbies: string[]
  volunteering: string[]
  matchingOtherTitles: string[]
  yearsOfExperience: number
  yearsOfManagementExperience: number
  matchingTitle: string
  matchingDescription: string
  matchingDepartment: string
  matchingSkills: PositionSkill[]
  matchingEducation: any[]
  matchingEligibilities: string[]
  matchingLocation: string
  matchingBuildingBlocks: string[]
  matchingLanguages: string[]
  matchingHobbies: string[]
  matchingVolunteering: string[]
  matchingRecipients: string[]
  matchingYearsOfExperience: number
  matchingYearsOfManagementExperience: number
  matchingHrms: { name?: string, email?: string  }
  matchingCvKeywords: string[];
  matchingJobTypes: string[];
  matchingPermissions: string[];
  matchingRelatedSkills: string[];
  matchingTemplateId: string;
}