// Parts
import { QuestionCollection } from "@/models/formPage"
import QuestionsList from "@components/WizardForm/components/QuestionsList"

// Local Interfaces
interface CustomQuestionsProps {
  questions: QuestionCollection
  pageIndex: number
}

/**
 *
 *  Custom Questions
 *  @description shows all custom questions added by user
 *
 */
const CustomQuestions: React.FunctionComponent<CustomQuestionsProps> =
  ({ questions=[], pageIndex }): React.ReactElement => {
    if (!questions || questions?.some(qs => qs?.some(q => !q))) {
        console.warn("null CUSTOM question")
    }   
    return (
      <QuestionsList questions={questions} pageIndex={pageIndex} isCustom={true} />
    )
  }

export default CustomQuestions
