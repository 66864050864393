/**
 *
 * @module src/pages/Referrals/AddReferral/OneByOne/ReferralItem
 *
 * @description add referral item row
 *
 * @author diegoulloao
 *
 */

// React
import { useState } from "react"

// Auth
import { useAuth, IAuth } from "@auth"

// Components
import { Loading } from "@components"

// Third Party Components
import AsyncSelect from "react-select/async"


// Graphql
import { getPositionsByCompanyId /*, getPositionInfoById */ } from "@queries/positions"
import { getReferralLink as getReferralLinkQuery } from "@queries/company"

// SWR
import useSWRInmutable from "swr/immutable"

// Helpers
import {
  generateQueryKey,
  copyToClipboard,
  validateEmail,
  getRefCodeFromLink,
  graphQueries
} from "@helpers"

// Analytics (BI)
import { Analytics } from "@helpers/analytics"

// Utils
import cx from "classnames"

// Icons
import { BsFillFileEarmarkFill as BsFile } from "react-icons/bs"


// Interfaces
import {
  IReferrer,
  SelectOption
} from "@interfaces/pages/referrals"

// Third Party Interfaces
import { SingleValue } from "react-select"
import { SWRResponse } from "swr"
import { IPosition } from "@/interfaces/pages/positions"

import useLocalToast from "../../../../hooks/useLocalizedToast";


// Local Interfaces
interface ReferralItemProps {
  setReferrers: React.Dispatch<React.SetStateAction<IReferrer[]>>
  referralItem: IReferrer
  index: number
  edit: boolean
}

interface IGetLinkBody {
  email: string
  name: string
  companyId: string
  positionId?: string
}

// Types
type IReferrerField = "position" | "email" | "name"

// Custom Styles
const selectCustomStyles = {
  control: (styles: any) => ({
    ...styles,
    border: "none"
  }),

  singleValue: (styles: any) => ({
    ...styles,
    color: "#374151"
  }),

  placeholder: (styles: any) => ({
    ...styles,
    color: "#9ca3af"
  })
}

// Constants
const maxPositionsToShow = 6
const allPositionsId = "0000-0000-0000"

// Fetchers
const fetcher = (query: string, companyId: string): Promise<SelectOption[]> =>
  graphQueries.sendRequest(query, {
    companyId: companyId
  })
    .then(({ getV2Positions }: any): SelectOption[] => {
      // We slice Positions coming from API
      const defaultPositionsToShow: IPosition[] = getV2Positions.positions.slice(0, maxPositionsToShow - 1)

      // We map it into a SelectOption array
      return defaultPositionsToShow.map((p: IPosition) => ({ label: p.title, value: p.id }))
    })

// Actions
const validateReferral = (referrer: IReferrer, edit?: boolean): boolean => {
  // Data from referral
  const { email, position }: IReferrer = referrer

  // If form is not valid, return false
  if (!email || !validateEmail(email)) {
    return false
  }

  // Only for creating mode: validate position
  if (!edit && !position) {
    return false
  }

  // else form is valid
  return true
}

/**
 *
 *  Part: Referral Item
 *  @description row for add a new Referral
 *
 */
const ReferralItem: React.FunctionComponent<ReferralItemProps> =
  ({ setReferrers, referralItem, index, edit }): JSX.Element => {
    // Auth (company)
    const { company }: IAuth = useAuth()

    // States
    const [isGeneratingLink, setIsGeneratingLink] = useState<boolean>(false)
    const [isValidForm, setIsValidForm] = useState<boolean>(true)
    const { toast } = useLocalToast();

    // SWR (Immutable) * load once and it doesn't change
    const {
      data: defaultPositions,
      isValidating: isDefaultPositionsLoading

    }: SWRResponse<SelectOption[], Error> = useSWRInmutable(
      [getPositionsByCompanyId(generateQueryKey(company!.id)), company!.id],
      fetcher
    )

    // Fetcher
    const loadPositions = async (search: string): Promise<SelectOption[] | undefined> => {
      try {
        // Fetch all Positions from Company
        const result: { getV2Positions: { positions: IPosition[] } } =
          await graphQueries.sendRequest(getPositionsByCompanyId(generateQueryKey(company!.id)), {
            companyId: company!.id,
            titleName: search
          })

        // Positions from API
        const { positions }: { positions: IPosition[] } = result.getV2Positions

        // Positions Options Array (Select)
        const positionOptions: SelectOption[] = positions.slice(0, maxPositionsToShow).map((p: IPosition) => ({
          label: p.title,
          value: p.id
        }))

        return positionOptions

      } catch (e: any) {
        console.error(e)
      }
    }

    // Handlers
    const handleInputChange = (key: number, field: IReferrerField, value: string | SingleValue<SelectOption>): void => {
      // Update referrer input to the collection
      setReferrers((currentReferrers: IReferrer[]) => {
        // Copy of current referrers list
        const referrers: IReferrer[] = [...currentReferrers]

        // We set the new value to the referrer row field (string and object cases)
        if (field !== "position")
          referrers[key][field] = value as string
        else
          referrers[key][field] = (value as SelectOption)

        return referrers
      })
    }

    const generateLink = async (key: number): Promise<void> => {
      // Validate Referral Form
      const isValid: boolean = validateReferral(referralItem, edit)

      // If not valid
      if (!isValid) {
        // Mark form as invalid to the user
        setIsValidForm(false)

        // Cancel
        return
      }
      // Continue
      setIsValidForm(true)

      // Data to get new referral link
      const { name, email, position }: IReferrer = referralItem

      // Set loading besides "Generate" button
      setIsGeneratingLink(true)

      // Position Id (undefined when "all positins")
      const positionId: string | undefined = (position?.value !== "0000-0000-0000") ? position?.value : undefined

      // Query body
      const getLinkBody: IGetLinkBody = {
        email: email!,
        name: name || "",
        companyId: company!.id
      }

      // Only send position id if position is not "All Positions"
      if (positionId) {
        getLinkBody.positionId = positionId
      }

      try {
        // Query to API
        const { getReferralLink }: { getReferralLink: { link: string, response: string } } =
          await graphQueries.sendRequest(getReferralLinkQuery, getLinkBody)

        // If an error ocurrs, then show message to user
        if (getReferralLink?.response !== "Referral Link Created") {
          toast("There was an error creating the referral link", { containerId: "default" })
          return console.log("There was an error creating the referral link")
        }

        // Created Link
        const { link }: { link: string } = getReferralLink

        // Set Referrer Link
        setReferrers((currentReferrers: IReferrer[]) => {
          // Copy of current referrers list
          const referrers: IReferrer[] = [...currentReferrers]

          // Set Link Generated to Referrer
          referrers[key].link = link

          return referrers
        })

        // Send created link event to BI
        Analytics.updateReferralEventsTable([{
          name,
          email,
          event: "",
          code: getRefCodeFromLink(link)
        }])

        // Show success message to user
        toast(`${edit ? "New link" : "Link"} generated!`, { containerId: "default" })

      } catch (e: any) {
        console.error(e)

        // Set loading off
        setIsGeneratingLink(false)
        toast("Link could not be created. Try again.", { containerId: "default" })
      }

      // Set loading off
      setIsGeneratingLink(false)
    }

    return (
      <div className="space-y-4" key={index}>
        {/* Input Container: position or company, email, name */}
        <div className="flex space-x-4">
          <div className="input-row flex flex-grow items-stretch space-x-4">
            {/* Position */}
            <AsyncSelect
              loadOptions={loadPositions}
              isLoading={isDefaultPositionsLoading}
              placeholder={`${!edit ? "*" : ""}Choose position`}
              styles={selectCustomStyles}
              className={cx("w-1/3 rounded-md border-2", {
                "border-red-500": !isValidForm && !edit && !referralItem.position
              })}
              onChange={
                (selectedPosition: SingleValue<SelectOption>) =>
                  handleInputChange(index, "position", selectedPosition)
              }
              defaultOptions={[
                // We prepend "All Positions"
                { label: "All Positions", value: allPositionsId },
                ...(!edit ? defaultPositions : defaultPositions?.slice(0, 3)) || []
              ]}
            />

            {/* Email */}
            <input
              disabled={edit}
              type="text" name="email" value={referralItem.email || ""}
              onChange={({ target: email }) => handleInputChange(index, "email", email.value)}
              placeholder="*Referrer email"
              className={cx("w-1/3 border-2 rounded-md py-1 px-3 transition", {
                "border-red-500": !isValidForm && !validateEmail(referralItem.email || "")
              })}
            />

            {/* Name */}
            <input
              type="text" name="name" value={referralItem.name || ""}
              onChange={({ target: name }) => handleInputChange(index, "name", name.value)}
              placeholder="Referrer name"
              className="w-1/3 border-2 rounded-md py-1 px-3 transition"
            />
          </div>

          {/* Generate Link*/}
          <div className="w-20 flex justify-center align-middle overflow-hidden">
            {!isGeneratingLink ? (
              <button className="text-black hover:text-gray-700 transition" onClick={() => generateLink(index)}>Generate</button>
            ) : (
              <Loading className="mt-3.5 -right-2.5" />
            )}
          </div>
        </div>

        {/* Generated Link */}
        <div className="generated-link pt-4 relative">
          {/* Link */}
          <input
            type="text"
            name="generated-link"
            value={referralItem.link || ""}
            className={cx("border-b-2 pb-2 w-full pl-1 pr-7 px-3 focus:outline-none disabled:bg-white text-gray-700", {
              "border-indigo-700": referralItem.link
            })}
            disabled
          />

          {/* Copy to clipboard Icon */}
          <button onClick={() => copyToClipboard(referralItem.link || "")}>
            <BsFile size="20" className="text-black hover:text-gray-700 absolute right-0 top-4 transition" />
          </button>
        </div>
      </div>
    )
  }

export default ReferralItem
