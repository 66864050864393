import { useState } from "react";

function usePagination(data: any[], itemsPerPage: number): {
    next: () => void;
    prev: () => void;
    jump: (page: number) => void;
    getCurrentData: () => any[];
    getDataByPage: (page: number) => any[]
    currentPage: number;
    maxPage: number;

} {
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    function getDataByPage(page: number) {
        const begin = (page - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    function getCurrentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page: number) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(() => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, getDataByPage, getCurrentData, currentPage, maxPage };
}

export default usePagination;