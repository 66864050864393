import { useContext, useEffect, useState } from "react";

import WizardStepTemplate from "@components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import Dropdown from "@components/WizardComponents/Dropdown/Dropdown";

import { IWizardStep } from "../../interfaces";

import CustomPlacesSelect from "@components/WizardComponents/CustomPlacesSelect/CustomPlacesSelect";
import { getEducationsOptions, getLanguagesOptions } from "./helpers";
import { removeDuplicates } from "@/helpers";
import './style.css';
import { SelectChangeEvent } from "@mui/material";
import { EditorApi } from "@/pages/AssessmentEditor/data/editor.api";
import { BuildingBlock } from "@/pages/BuildingBlocks/BuildingBlockEditor";
import { Modalbox } from "@/components";
import AutocompleteCustom from "@/components/WizardComponents/AutocompleteNew/AutocompleteNew";
import AppContext from "@/context/AppContext";

const TITLE = "Basic requirements";
const DESCRIPTION = "These inputs will be used to automatically match between the candidate's work experience and your requierments";

interface IOption {
    value: string | number;
    label: string;
}
// Template option collections, to be localized
const _experienceOptions = [
    { value: 0, label: "Not required" },
    { value: 1, label: "1-3 years" },
    { value: 3, label: "3-7 years"},
    { value: 7, label: "More than 7 years" }
]

const _managmentExperienceOptions = [
    { value: 0, label: "Not required" },
    { value: 1, label: "1-3 years" },
    { value: 3, label: "3-7 years"},
    { value: 7, label: "More than 7 years" }
]

const _jobTypeOptions = [
    { value: "FullTime", label: "Full Time" },
    { value: "PartTime", label: "Part Time" },
    { value: "Freelance", label: "Freelance" },
    { value: "Remote", label: "Remote" },
    { value: "Advisory", label: "Advisory" },
    { value: "Hybrid", label: "Hybrid" }
]


const RequirementsStep: React.FunctionComponent<IWizardStep> = ({ formik, setIsLoading, isLoading}):JSX.Element => {
    const { handleChange, values, setFieldValue } = formik;
    const { 
        matchingYearsOfExperience, 
        matchingYearsOfManagementExperience,
        matchingBuildingBlocks, 
        matchingLocation,
        matchingJobTypes,
        matchingEducation,
        matchingLanguages,
    } = values;

    const { locale, localizer, tr } = useContext(AppContext);

    const [langOptFetched, setLangOptFetched] = useState<boolean>(false);
    const [eduOptFetched, setEduOptFetched] = useState<boolean>(false);
    const [buildingBlockOptFetched, setBuildingBlockOptFetched] = useState<boolean>(false);

    const [languagesOptions, setLanguagesOptions] = useState<{value: string, label: string}[]>([]);
    const [educationOptions, setEducationOptions] = useState<{value: string, label: string}[]>([]);
    const [buildingBlocksOptions, setBuildingBlocksOptions] = useState<BuildingBlock[]>([]);

    const [experienceOptions, setExperienceOptions] = useState<IOption[]>([]);
    const [managmentExperienceOptions, setManagmentExperienceOptions] = useState<IOption[]>([]);
    const [jobTypeOptions, setJobTypeOptions] = useState<IOption[]>([]);

    // experienceOptions, managmentExperienceOptions, jobTypeOptions

    const [showManagementBBWaring, setShowManagementBBWaring] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading?.(true);
        getLanguagesOptions()
        .then((options) => {
            if(!options) return;

            const optionsWithoutDuplicates = removeDuplicates(options, JSON.stringify)
            setLanguagesOptions(optionsWithoutDuplicates);
        })
        .catch(console.error)
        .finally(() => setLangOptFetched(true))

        getEducationsOptions()
        .then((options) => {
            if(!options) return;

            const optionsWithoutDuplicates = removeDuplicates(options, JSON.stringify);
            setEducationOptions(optionsWithoutDuplicates);
        })
        .catch(console.error)
        .finally(() => setEduOptFetched(true))

        EditorApi.getBuildingBlocks()
        .then((bbs) => {
            if(!bbs) return;
            setBuildingBlocksOptions(bbs);
        })
        .catch(console.error)
        .finally(() => setBuildingBlockOptFetched(true))
    }, [])  

    useEffect(() => {

        const isDataFetched = langOptFetched && eduOptFetched && buildingBlockOptFetched;
        if (isDataFetched) {
            setIsLoading?.(false)
        }

    }, [langOptFetched, eduOptFetched, buildingBlockOptFetched])

    useEffect(() => {
        setExperienceOptions(localizer.localizeObject({ data: _experienceOptions, locale, keys: "label" }))
        setManagmentExperienceOptions(localizer.localizeObject({ data: _managmentExperienceOptions, locale, keys: "label" }))
        setJobTypeOptions(localizer.localizeObject({ data: _jobTypeOptions, locale, keys: "label" }))

    }, [locale])

    const manipulateManagmentBuildingBlockOfPosition= (all_bbs: BuildingBlock[], action: 'remove' | 'add') => {
        const managementBuildingBlockId = all_bbs.find(bb => bb?.id === "mng")?.id;
        if(!managementBuildingBlockId) return;
        
        const positionHasManagementBuildingBlock = matchingBuildingBlocks?.find(bb => bb === managementBuildingBlockId);
        
        const needRemoveBuildingBlock = action === 'remove' && positionHasManagementBuildingBlock;
        const needAddBuildingBlock = action === 'add' && !positionHasManagementBuildingBlock;
        
        let newMatchingBuildingBlocks;
        
        if(needRemoveBuildingBlock) {
            newMatchingBuildingBlocks = matchingBuildingBlocks?.filter(bb => bb !== managementBuildingBlockId);
        }
        
        if(needAddBuildingBlock) {
            newMatchingBuildingBlocks = [...(matchingBuildingBlocks ?? []), managementBuildingBlockId];
        }

        if(needRemoveBuildingBlock || needAddBuildingBlock) {
            setFieldValue("matchingBuildingBlocks", newMatchingBuildingBlocks);
        }
    }

    const onManagementExperienceChange = (e: SelectChangeEvent<unknown>) => {
        const { target } = e;
        const { value } = target;
        const stringValue = String(value);
        
        const noManagementExperience = stringValue === "0";
        if(noManagementExperience) {
            setShowManagementBBWaring(true);
            return;
        }

        manipulateManagmentBuildingBlockOfPosition(buildingBlocksOptions, 'add');
        handleChange(e);
    }

    const onApproveManangementBBWarning = () => {
        const zeroYearsOfExperienceValue = managmentExperienceOptions.find(o => o.value === 0)?.value;
        setFieldValue("matchingYearsOfManagementExperience", zeroYearsOfExperienceValue);
        manipulateManagmentBuildingBlockOfPosition(buildingBlocksOptions, 'remove');
        setShowManagementBBWaring(false);
    }
 
    const onCancelBBWarning = () => {
        const zeroYearsOfExperienceValue = managmentExperienceOptions.find(o => o.value === 0)?.value;
        setFieldValue("matchingYearsOfManagementExperience", zeroYearsOfExperienceValue);
        setShowManagementBBWaring(false);
    }

    const getLanguageLabel = (lang: string): string => {
        const langOption = languagesOptions.find(option => option.value === lang);
        return langOption ? langOption.label : lang;
    }

    const getEducationLabel = (degree: string): string => {
        const degreeOption = educationOptions.find(option => option?.value === degree);
        return degreeOption ? degreeOption?.label : degree;
    }

    return (
        <WizardStepTemplate
        title={tr(TITLE)}
        description={tr(DESCRIPTION)}>
            {!isLoading && 
            <div className="wizard-define-the-role">
                <div className="wizard-define-the-role-role-exp-section">
                    <Dropdown
                    label={tr("Level of relevant experience?")}
                    name="matchingYearsOfExperience"
                    value={matchingYearsOfExperience}
                    onChange={handleChange}
                    options={experienceOptions}
                    />
                    <Dropdown
                    label={tr("Level of management experience?")}
                    name="matchingYearsOfManagementExperience"
                    value={matchingYearsOfManagementExperience}
                    onChange={onManagementExperienceChange}
                    options={managmentExperienceOptions}
                    />
                    <CustomPlacesSelect 
                    label={tr("Where is the office located?")}
                    handleChange={(newVal: any) => setFieldValue("matchingLocation", newVal?.value ?? "")} 
                    name={"matchingLocation"} 
                    value={matchingLocation ? {label: matchingLocation, value: matchingLocation} : ""}/>
                </div>
                <div className="wizard-define-the-role-role-quali-section">
                    <AutocompleteCustom
                    label={tr("Add preferred job type")}
                    options={jobTypeOptions}
                    getOptionLabel={(o) => o.label}
                    onChange={(e, newValues: any[]) => setFieldValue("matchingJobTypes", newValues.map((jt) => jt.value))}
                    value={
                        matchingJobTypes?.map(jt => ({ 
                            label: jobTypeOptions.find(jto => jto.value === jt)?.label ?? jt, 
                            value: jt 
                        })) ?? []
                    }/>
                    {eduOptFetched &&
                    <AutocompleteCustom
                    label={tr("Add preferred education or certificates")}
                    options={educationOptions}
                    value={matchingEducation?.map((e: string) => ({label: getEducationLabel(e), value: e})) ?? []}
                    getOptionLabel={(o) => o?.label}
                    freeSolo={true}
                    onChange={(e, newvalues: any[]) => {
                        setFieldValue("matchingEducation", newvalues.map((l) => l.value));
                    }}/>}
                    {langOptFetched &&
                    <AutocompleteCustom
                    label={tr("Add languages")}
                    options={languagesOptions}
                    value={matchingLanguages?.map((l) => ({ label: getLanguageLabel(l), value: l }))}
                    getOptionLabel={(o) => o.label}
                    onChange={(e, newvalues: any[]) => {
                        setFieldValue("matchingLanguages", newvalues.map((l) => l.value));
                    }}/>}
                </div>
            </div>}
            {showManagementBBWaring &&
            <Modalbox
            className={"wizard-new-template-warning-modal"}
            // message={"Warning"}
            description={tr("Do you wish to continue assessing the candidate's management skills?")}
            confirmText="Remove from simulation?"
            onCancel={() => onCancelBBWarning()}
            onClose={() => onCancelBBWarning()}
            onConfirm={onApproveManangementBBWarning}/>}
        </WizardStepTemplate>
    )
}

export default RequirementsStep;