// Powered by logo
// import poweredByLogo from "../../assets/images/poweredby-grey.svg";

import React, { useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, DialogContent } from "@mui/material";
import * as Survey from "survey-react";
// // Survey JS Custom Widgets
import LNRD from "../../components/SurveyJs/extend";

import { BsX } from "react-icons/bs";

// SurveyJs Model
import defaultSurveyConfig from "../../components/SurveyJs/comProfile";

//comprofile instructions box
import InstructionsBox from "../../components/InstructionsBox/InstructionsBox";

// // SurveyJs Styling
import "survey-react/survey.css";
import "select2/dist/css/select2.css";
import "jquery-ui/themes/base/all.css";

import customCss from "../../components/SurveyJs/customCss";

import "../../components/SurveyJs/custom.css";

import "./ComProfileSurvey.css";

import ComProfileResults from "../ComProfileResults/index";

import { graphQueries } from "@/helpers";
import { updateUserProfile } from "@mutations/updateUserProfile";
import ProgressBarComponent from "../SurveyJs/components/ProgressBar";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// Auth
import { useAuth, IAuth } from "@auth";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Analytics } from "@/helpers/analytics";
import Cookies from "js-cookie";
import AppContext from "@/context/AppContext";

const AT_LEAST_ONE_ERR = "Please choose at least one 'Best' and at least one 'Least' answers!";

/**
 *
 *  Communication Profile Survey for HRM
 *  @description Communication Profile Survey for HRM
 *
 */
const ComProfileSurvey: React.FunctionComponent<{ openModal: any }> = ({
  openModal,
}): JSX.Element => {
  const questions: any = {};
  const [completed, setCompleted] = React.useState(false);
  const [dataForComProfile, setDataForComProfile] = React.useState(null);
  const [, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [completedProgress, setCompletedProgress] = React.useState(1);

  const [displayInstructions, setDisplayInstructions] = React.useState(false);

  const [closeModal, setCloseModal] = React.useState(true);

  const [, setFullWidth] = React.useState(false);

  const [welcome, setWelcome] = React.useState(true);

  const [, setHeight] = React.useState("100%");

  const [, setWidth] = React.useState("30%");

  const [spinner, setSpinner] = React.useState(false);

  const [source] = React.useState(Cookies.get("reportComprofileSource") ?? "unknown");

  const [surveyData, setSurveyData] = React.useState({});
  const [surveyModel, setSurveyModel] = React.useState<Survey.SurveyModel | null>(null);
  // const [surveyModel, setSurveyModel] = React.useState(new Survey.SurveyModel(defaultSurveyConfig.defaultSurveyJSON));
  const [survey, setSurvey] = React.useState({});

  const theme = useTheme();

  const { tr } = useContext(AppContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const auth = useAuth();
  // Auth
  const { user }: IAuth = auth;

  function rearrangeMatrixObj(matrix: any, changedKey: any, newValue: any) {
    for (const key in matrix) {
      // loop all other keys in the matrix:
      if (key != changedKey) {
        const oldValue = matrix[key];
        if (oldValue == newValue) {
          // change this key to 0 (empty all radio buttons):
          delete matrix[key];
        }
      }
    }
    //return matrix;
  }

  function atLeastOneValidator(s: any, options: any) {
    //   if (options.name != 'behaviorRate') {
    //     return;
    //   }
    const err =
      tr(AT_LEAST_ONE_ERR);
    const matrix = options.value;
    if (!matrix) {
      options.error = err;
      return;
    }
    let gotBest = false;
    let gotLeast = false;
    Object.values(matrix).forEach((val) => {
      if (val == 1) {
        gotBest = true;
      }
      if (val == 2) {
        gotLeast = true;
      }
    });
    if (!gotBest || !gotLeast) {
      options.error = err;
    }
  }

  function getFirstChangeBetweenObjects(oldObj: any, newObj: any) {
    if (newObj == null) {
      console.error("Error: Empty newObj in getFirstChangeBetweenObjects()");
      return null;
    }
    // if (oldObj == null) {
    //   console.log(
    //     `No oldObj in getFirstChangeBetweenObjects(). returning the first element of newObj`,

    //   );
    //   return newObj[0];
    // }
    for (const newKey in newObj) {
      const newValue = newObj[newKey];
      if (
        oldObj == null ||
        !Object.prototype.hasOwnProperty.call(oldObj, newKey)
      ) {
        // console.log(`New Key detected: ${newKey}`)
        return { key: newKey, value: newValue };
      }
      // key exist, check the value of that key:
      const oldValue = oldObj[newKey];
      if (oldValue != newValue) {
        // console.log(`Data change detected in key: ${newKey}. old value: ${oldValue}, new value: ${newValue}`);
        return { key: newKey, value: newValue };
      }
    }
    // no changes detected
    console.log("no changes detected");
    return null;
  }

  function getChangedKey(oldObj: any, newObj: any) {
    const theChange = getFirstChangeBetweenObjects(oldObj, newObj);
    if (theChange) {
      return theChange.key;
    }
    return null;
  }

  const setJsonForApi = (toAddJson: any) => {
    const json: any = {};
    const objectKeys = Object.keys(toAddJson);
    for (let i = 0; i < objectKeys.length; i++) {
      const keyName = objectKeys[i];
      const jObject = toAddJson[keyName];
      const jObjectKeys = Object.keys(jObject);
      let bestItem;
      let leastItem;
      for (let j = 0; j < jObjectKeys.length; j++) {
        const jObjectKeyName = jObjectKeys[j];
        if (jObject[jObjectKeyName] === 1) {
          //this is "Best"
          bestItem = parseInt(jObjectKeyName);
        }
        if (jObject[jObjectKeyName] === 2) {
          //this is "Least"
          leastItem = parseInt(jObjectKeyName);
        }
      }
      json[keyName] = [bestItem, leastItem];
    }
    return json;
  };

  const onAfterRenderQuestion = (s: any) => {
    s.hideRequiredErrors = true;
    s.currentPage.hideRequiredErrors = true;
    try {
      (document.querySelector(".sv_next_btn") as HTMLButtonElement).disabled =
        true;
    } catch {
      console.log("No next button found");
    }

    const labels = document.getElementsByClassName("sv_q_m_label");
    const matrixTable = document.getElementsByClassName("sv_q_matrix");
    matrixTable[0]?.classList.add("com-profile-matrix");
    for (let i = 0; i < labels.length; i++) {
      const inputHtmlElm = labels[i].children[0];
      const newSpan = document.createElement("SPAN");
      const labelText =
        labels[i]?.parentElement?.title?.toLowerCase() ||
        labels[i]?.parentElement
          ?.getAttribute("data-responsive-title")
          ?.toLowerCase();
      newSpan.textContent = inputHtmlElm.getAttribute("aria-label") || "";
      newSpan.classList.add("input-span");
      if (labelText) {
        newSpan.textContent = labelText;
        inputHtmlElm.classList.add(newSpan.textContent);
        inputHtmlElm?.parentNode?.insertBefore(
          newSpan,
          inputHtmlElm.nextSibling
        );
      }
    }
  };

  const afterPageRender = (s: any) => {
    const surveyWrap = document.querySelector(".comprofile-container .survey-container");
    if (s.currentPageNo && s.currentPageNo !== 0) {
      Analytics.sendComprofileWizardReportEvent({
        user_id: user?.id ?? null,
        screen: String(s.currentPageNo + 1),
        event: "progress",
        source: source
      })
      surveyWrap?.classList?.remove("with-instructions");
      setDisplayInstructions(false);
    }
    else {
      setDisplayInstructions(true);
      surveyWrap?.classList?.add("with-instructions");
      //inserting comprofile instructions div into survey div
      const comprofileSurveyWrap = document.querySelector(".container1");
      const cpInstructionsDiv = document.querySelector(".cp-instructions-wrap");
      comprofileSurveyWrap && cpInstructionsDiv !== null ? comprofileSurveyWrap.prepend(cpInstructionsDiv) : null;
    }
  };

  const onValueChanged = (s: any, options: any) => {
    s.hideRequiredErrors = true;
    const dataForSurvey: any = {};
    const questionName = options.question.name;
    const matrixValue = options.value;
    const oldMatrixValue = questions[questionName]; // get the previous state of this question's matrix object (if exixts)
    const changedKey = getChangedKey(oldMatrixValue, matrixValue);

    if (changedKey) {
      // change detected
      const newValue = matrixValue[changedKey];
      //console.log(`Change detected on key ${changedKey}, value is: ${newValue}`);
      // logic of unique "best" and unique "least"
      rearrangeMatrixObj(matrixValue, changedKey, newValue);
    }
    questions[questionName] = matrixValue;
    dataForSurvey[questionName] = matrixValue;
    s.data = Object.assign(s.data, questions);
    const cookieJson = {
      surveyData: s.data,
      pageNumber: currentPage,
    };

    Cookies.set("cp", JSON.stringify(cookieJson), { path: "/" });
    const err =
      tr(AT_LEAST_ONE_ERR);
    const matrix = options.value;
    if (!matrix) {
      options.error = err;
      return;
    }
    let gotBest = false;
    let gotLeast = false;
    Object.values(matrix).forEach((val) => {
      if (val == 1) {
        gotBest = true;
      }
      if (val == 2) {
        gotLeast = true;
      }
    });
    if (!gotBest || !gotLeast) {
      options.error = err;
    }
    if (!options.error) {
      try {
        (document.querySelector(".sv_next_btn") as HTMLButtonElement).disabled =
          false;
      } catch {
        console.log("No next button found");
      }
    }
    else {
      try {
        (document.querySelector(".sv_next_btn") as HTMLButtonElement).disabled =
          true;
      } catch {
        console.log("No next button found");
      }
    }
  };

  useEffect(() => {
    setWelcome(false);
    setHeight("50%");
    setWidth("90%");
    const cpCookie: any = JSON.parse(Cookies.get("cp") ?? "{}");
    if (user?.comProfile) {
      // user already has a com profile so we need to show the results
      setCompleted(true);
      setFullWidth(true);
      setHeight("90%");
      setWidth("60%");
      setWelcome(false);
    }
    else if (cpCookie) {
      if (cpCookie?.pageNumber) {
        setCurrentPage(Number(cpCookie?.pageNumber));
      } else {
        setCurrentPage(1);
      }
      setSurveyData(cpCookie?.surveyData);
    }
    Survey.StylesManager.applyTheme("default");
    setSurvey(defaultSurveyConfig?.defaultSurveyJSON);
    LNRD.extendSurveyJs(Survey);
    setOpen(true);
  }, []);

  useEffect(() => {
    if (!survey) {
      return;
    }
    const model = new Survey.SurveyModel(tr(survey, [
      "title", "text", "completeText", "showQuestionNumbers",
      "questionsOnPageMode", "pagePrevText", "pageNextText"]));
    let cPage: Number;
    const cpCookie: any = JSON.parse(Cookies.get("cp") ?? "{}");
    if (cpCookie && cpCookie.pageNumber) {
      cPage = Number(cpCookie.pageNumber) + 1;
    }
    else {
      cPage = currentPage;
    }
    model.currentPageNo = Number(cPage);
    model.data = surveyData;
    setCurrentPage(Number(model.currentPageNo));
    setSurveyModel(model);
    setCompletedProgress(model.getProgress());
  }, [survey, tr])

  const onComplete = async (s: any) => {
    setSpinner(true);
    const comprofiledata = await graphQueries.getComProfile(
      setJsonForApi(s.data)
    );
    Cookies.remove("cp");
    // console.log(user?.id);
    setDataForComProfile(comprofiledata);
    Analytics.sendComprofileWizardReportEvent({
      user_id: user?.id ?? null,
      screen: "15",
      event: "finish",
      source: source
    })
    try {
      const { updateUser: result } = await graphQueries.sendRequest(
        updateUserProfile,
        {
          updateUserUserId: user?.id,
          updateUserComProfile: comprofiledata?.profile,
        }
      );

      // Then an error ocurrs in server side
      if (result?.message !== "User Updated") {
        console.log("user could not be updated.");
      }
    } catch (error: any) {
      console.error(error);
    }
    setCompleted(true);
    setFullWidth(true);
    setSpinner(false);
    setHeight("90%");
  };

  const onCurrentPageChanged = (s: any) => {
    s.hideRequiredErrors = true;
    setCurrentPage(currentPage + 1);
    setCompletedProgress(s.getProgress());
  };

  const onCurrentPageChanging = (s: any, options: any) => {
    s.hideRequiredErrors = true;
    if (!options.error) {
      try {
        (document.querySelector(".sv_next_btn") as HTMLButtonElement).disabled =
          false;
      } catch {
        console.log("No next button found");
      }
    }
  };

  // function welcomeView() {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <div style={{ marginBottom: "20px" }}>
  //         <b> Instructions | Communication style simulation</b>
  //       </div>
  //       <div
  //         style={{
  //           marginRight: "auto",
  //           marginLeft: "auto",
  //           textAlign: "center",
  //         }}
  //       >
  //         Try to answer as naturally and as honestly as you can.
  //       </div>
  //       <div
  //         style={{
  //           marginRight: "auto",
  //           marginLeft: "auto",
  //           textAlign: "center",
  //           marginBottom: "20px",
  //         }}
  //       >
  //         Each of the next 15 questions consist of four phrases. <br />
  //         Select the phrase that describes you best and the phrase that
  //         describes you least.
  //       </div>
  //       <Button
  //         color="primary"
  //         onClick={() => {
  //           setWelcome(false);
  //           setHeight("50%");
  //           setWidth("90%");
  //         }}
  //         style={{
  //           color: "white",
  //           backgroundColor: "black",
  //           marginRight: "auto",
  //           marginLeft: "auto",
  //           marginBottom: "10px",
  //         }}
  //       >
  //         Continue
  //       </Button>
  //     </div>
  //   );
  // }

  return (
    <Dialog
      sx={{ padding: "0px" }}
      open={closeModal}
      onClose={() => {
        if (!dataForComProfile) {
          Analytics.sendComprofileWizardReportEvent({
            user_id: user?.id ?? null,
            screen: String(currentPage),
            event: "close_dialog",
            source: source
          });
        }
        setCloseModal(false);
        openModal(false);
      }}
      fullWidth={true}
      maxWidth="md"
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          width: '700px',
        },
      }}
    // PaperProps={{ sx: { height: { height } } }}
    >
      <DialogTitle>
        {!completed && !spinner && !welcome && (
          <div style={{ float: "left" }}>
            <ProgressBarComponent
              currentPage={currentPage}
              totalPages={15}
              mobile={false}
              completed={completedProgress}
            />
          </div>
        )}
        <BsX
          style={{ float: "right" }}
          onClick={() => {
            Analytics.sendComprofileWizardReportEvent({
              user_id: user?.id ?? null,
              screen: String(currentPage),
              event: "close_dialog",
              source: source
            });
            setCloseModal(false);
            openModal(false);
          }}
        />
      </DialogTitle>
      <DialogContent>
        {!user?.comProfile && !welcome && !completed ? (
          <div className="container1">
            <div className={displayInstructions ? "container2 with-instructions" : "container2"}>
              {/* {!completed && !spinner && (
                <ProgressBarComponent
                  currentPage={currentPage}
                  totalPages={15}
                  mobile={false}
                  completed={completedProgress}
                />
              )} */}
              {spinner && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "70px",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {!completed && surveyModel && (
                <>
                  <Survey.Survey
                    css={customCss}
                    model={surveyModel}
                    // json={defaultSurveyConfig.defaultSurveyJSON}
                    onComplete={onComplete}
                    onAfterRenderQuestion={onAfterRenderQuestion}
                    onAfterRenderPage={afterPageRender}
                    onValueChanged={onValueChanged}
                    onValidateQuestion={atLeastOneValidator}
                    showCompletedPage={false}
                    onCurrentPageChanged={onCurrentPageChanged}
                    onCurrentPageChanging={onCurrentPageChanging}
                  // logo={poweredByLogo}
                  />
                  <InstructionsBox
                    displayInstructions={displayInstructions}
                    headline={tr(`Let's start`)}
                    content={`${tr("Below is a short questionnaire to forecast the compatibility between you and the candidate's working styles")}.
                    *${tr("Please choose a phrase which describes you the best, and one which describes you the least")}.`}
                  />
                </>
              )}
              {/* {open && !user?.comProfile && (
            <InformationAboutComprofileModal open={open} />
          )} */}
            </div>
          </div>
        ) : null}
        {/* {!completed && welcome ? welcomeView() : null} */}
        {completed && (
          <ComProfileResults
            data={dataForComProfile}
            setCloseModal={setCloseModal.bind(this)}
            openModal={openModal.bind(this)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ComProfileSurvey;
