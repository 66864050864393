import AuthContext from '@/auth/context';
import { useContext } from 'react';
// import { useSettings } from "@/helpers/settingsService";
import { isEmpty } from "lodash";
import AsyncSelect from 'react-select/async';
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import { SelectCustomStyles_Dropdown_regular, SelectTheme } from "../styles/select-custom";
import { graphQueries } from '@/helpers';
import { proxyDataService } from '@mutations/proxyDataService';
export interface PlacesSelectProps extends StateManagerProps {
  countryCode: string;
}

export const PlacesSelect: React.FunctionComponent<PlacesSelectProps> = (props) => {
  const {
    countryCode
  } = props;
  const { settings } = useContext(AuthContext)
  const DATA_SERVICES_ORIGIN = settings.getSetting('DATA_SERVICES_ORIGIN');
  const apiRequestPath = (place: string, country: string) => DATA_SERVICES_ORIGIN ? 
  `/r/places/search?text=${place}&countries=${country}`: "";
  const toSelectOptions = (values: string[]) => {
    return values.map(val => ({ value: val, label: val }));
  }
  const loadOptions =  async (value: string) => {
    try {
      const path = apiRequestPath(value, countryCode);
      if(!path) {
        return [];
      }
      const data = await graphQueries.sendRequest(proxyDataService, {
        path: path,
        method: "GET"
      });
      const { result } = data?.proxyDataService;
      
      if(isEmpty(result)) {
        return [{ label: `${value}`, value }]
      }
      return toSelectOptions(result);
    } catch(e) {
      console.error(e);
      return [];
    }
  }
  return  <div className="flex">
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      isDisabled={!DATA_SERVICES_ORIGIN}
      isClearable={true}
      styles={SelectCustomStyles_Dropdown_regular}
      theme={SelectTheme}
      { ...props }
    />
  </div>
}