import { ComProfile } from "../parts/comProifleHelpers";

// Interfaces
interface IComprofileIcons {
    comProfile: ComProfile;
    color?: string;
}

const ComprofileIcons: React.FunctionComponent<IComprofileIcons> = (props): JSX.Element => {
    switch (props.comProfile) {
        case ComProfile.RV:
            return(
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42">
                    <defs>
                    </defs>
                    <path fill={props.color ? props.color : ""} d="M39,7.89C35.66,7.89,27.08,4,26.05,2.64a1.07,1.07,0,0,0-1.6,0c-1,1.38-9.61,5.25-12.93,5.25a1,1,0,0,0-1,1V23.42c0,9.64,13.25,16,14.48,16.31a1,1,0,0,0,.48,0C26.72,39.42,40,33.06,40,23.42V8.89A1,1,0,0,0,39,7.89ZM38,9.81V23.42C38,31,27.55,36.76,25.25,37.7,22.94,36.76,12.52,31,12.52,23.42V9.81c3.54-.48,10.19-3.2,12.73-5.2C27.79,6.61,34.44,9.33,38,9.81Z"/>
                    <path fill={props.color ? props.color : ""} d="M24.39,15.22l-1.57,3-3.35.61a.94.94,0,0,0-.72.64,1,1,0,0,0,.22.95l2.38,2.42-.46,3.37a1,1,0,0,0,.39.88.91.91,0,0,0,.55.18.86.86,0,0,0,.41-.1l3.05-1.51,3.06,1.47a.93.93,0,0,0,1.33-1l-.5-3.36,2.35-2.45a1,1,0,0,0,.21-1,.94.94,0,0,0-.73-.63l-3.35-.57-1.61-3a1.05,1.05,0,0,0-.83-.49A.93.93,0,0,0,24.39,15.22Zm2.87,7.4.3,2.06-1.87-.9a1,1,0,0,0-.83,0L23,24.71l.28-2.07a1,1,0,0,0-.26-.78l-1.46-1.49L23.61,20a.93.93,0,0,0,.66-.49l1-1.85,1,1.84a.93.93,0,0,0,.67.48l2.06.35-1.44,1.5A1,1,0,0,0,27.26,22.62Z"/>
                </svg>
            )
        case ComProfile.SV:
            return (
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42">
                    <defs>
                    </defs>
                        <path fill={props.color ? props.color : ""} d="M42.54,26.83a18.18,18.18,0,0,0,1-5.83,18.5,18.5,0,1,0-36,5.84.3.3,0,0,0,0,.1,18.47,18.47,0,0,0,35,0A.56.56,0,0,0,42.54,26.83Zm-15.4-.32A2.15,2.15,0,1,1,25,24.35a2.14,2.14,0,0,1,2.14,2.16s0,0,0,0Zm13.64-1.05H29.09A4.22,4.22,0,0,0,27,22.78l2.21-9.1a1,1,0,0,0-.12-.79,1.07,1.07,0,0,0-.65-.47,1.06,1.06,0,0,0-.78.12,1.09,1.09,0,0,0-.48.65L25,22.26a4.24,4.24,0,0,0-4.05,3.2H9.22a16.41,16.41,0,1,1,31.56,0ZM20.91,27.54a4.22,4.22,0,0,0,8.18,0H40a16.39,16.39,0,0,1-30.08,0Z"/>
                </svg>
            )
        case ComProfile.R:
            return (
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42">
                    <defs>
                    </defs>
                    <path fill={props.color ? props.color : ""} d="M23.45,6.27a.79.79,0,0,0-.54.26,12.1,12.1,0,0,0-3.11,8.13V38.71a.79.79,0,1,0,1.58,0v-24a10.53,10.53,0,0,1,2.7-7.07A.8.8,0,0,0,24,6.47.77.77,0,0,0,23.45,6.27Z"/>
                    <path fill={props.color ? props.color : ""} d="M46.67,22.6h0l-2.8-7v-.93a12.1,12.1,0,0,0-12-12.16,11.8,11.8,0,0,0-5.47,1.34.78.78,0,0,0,.13,1.45.83.83,0,0,0,.6,0,10.25,10.25,0,0,1,4.74-1.16A10.53,10.53,0,0,1,42.29,14.66v1.08a.8.8,0,0,0,.06.3l2.85,7.14c.07.19.07.26.08.26s-.2.21-1.46.21h-.74a.79.79,0,0,0-.79.79V28c0,4.42-1,4.9-4.83,4.9h-2.6a.8.8,0,0,0-.8.79v5a.8.8,0,0,0,1.59,0V34.48h1.81c4.52,0,6.41-.9,6.41-6.48V25.23c1.05,0,2.18-.12,2.71-.9A1.8,1.8,0,0,0,46.67,22.6Z"/>
                    <path fill={props.color ? props.color : ""} d="M18.16,2.5a12.11,12.11,0,0,0-12,12.16v.93l-2.8,7a1.75,1.75,0,0,0,.09,1.72c.53.78,1.66.9,2.71.9V28c0,5.58,1.89,6.48,6.41,6.48h1.8v4.23a.79.79,0,1,0,1.58,0v-5a.8.8,0,0,0-.79-.79H12.54c-3.85,0-4.83-.48-4.83-4.9V24.44a.79.79,0,0,0-.79-.79H6.18a3,3,0,0,1-1.44-.18.6.6,0,0,1,.06-.28L7.65,16a.8.8,0,0,0,.06-.3V14.66a10.46,10.46,0,1,1,20.91,0V38.71a.79.79,0,1,0,1.58,0v-24A12.11,12.11,0,0,0,18.16,2.5Z"/>
                </svg>
            )
        case ComProfile.I:
            return (
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42">
                    <defs>
                    </defs>
                    <path fill={props.color ? props.color : ""} d="M15.09,20.1a.73.73,0,0,0,.26,1A19.77,19.77,0,0,0,25,23.26a19.77,19.77,0,0,0,9.65-2.16.73.73,0,1,0-.74-1.26,18.55,18.55,0,0,1-8.91,2,18.55,18.55,0,0,1-8.91-2A.74.74,0,0,0,15.09,20.1Z"/>
                    <path fill={props.color ? props.color : ""} d="M42.74,20.6c0-3-4.08-5.58-10.66-6.72a.73.73,0,1,0-.24,1.43c5.65,1,9.44,3.11,9.44,5.29,0,2.82-6.54,5.84-16.28,5.84s-16.28-3-16.28-5.84c0-2.05,3.49-4.12,8.69-5.14A.73.73,0,0,0,17.13,14C11,15.24,7.26,17.7,7.26,20.6c0,2.41,2.72,4.51,7,5.83a.43.43,0,0,0,0,.11V34.9c0,3,5.55,4.6,10.78,4.6s10.78-1.61,10.78-4.6V26.54a.43.43,0,0,0,0-.11C40,25.11,42.74,23,42.74,20.6ZM34.32,34.9C34.32,36.21,30.78,38,25,38s-9.32-1.83-9.32-3.14V26.83A38.52,38.52,0,0,0,25,27.9a38.52,38.52,0,0,0,9.32-1.07Z"/>
                    <path fill={props.color ? props.color : ""} d="M20.85,18.18,22,20.54a.73.73,0,0,0,1.3,0l1.18-2.36L26.87,17a.74.74,0,0,0,.41-.66.73.73,0,0,0-.41-.65l-2.36-1.18-1.18-2.36a.76.76,0,0,0-1.3,0l-1.18,2.36-2.36,1.18a.73.73,0,0,0-.41.65.74.74,0,0,0,.41.66Zm.87-2.47a.73.73,0,0,0,.32-.33l.64-1.27.64,1.27a.73.73,0,0,0,.32.33l1.27.63L23.64,17a.73.73,0,0,0-.32.33l-.64,1.27L22,17.31a.73.73,0,0,0-.32-.33l-1.27-.64Z"/>
                    <path fill={props.color ? props.color : ""} d="M15.44,7.72,17.8,8.9,19,11.26a.74.74,0,0,0,1.31,0L21.47,8.9l2.36-1.18a.74.74,0,0,0,.4-.66.72.72,0,0,0-.4-.65L21.47,5.23,20.29,2.87a.77.77,0,0,0-1.31,0L17.8,5.23,15.44,6.41a.74.74,0,0,0-.4.65A.75.75,0,0,0,15.44,7.72Zm3.23-1.29A.75.75,0,0,0,19,6.1l.64-1.27.63,1.27a.75.75,0,0,0,.33.33l1.27.63L20.6,7.7a.7.7,0,0,0-.33.33L19.64,9.3,19,8a.7.7,0,0,0-.33-.33L17.4,7.06Z"/>
                    <path fill={props.color ? props.color : ""} d="M23.5,10.16a.74.74,0,0,0,.4.65l2.88,1.44,1.44,2.87a.72.72,0,0,0,1.3,0L31,12.25l2.88-1.44a.74.74,0,0,0,.4-.65.75.75,0,0,0-.4-.66L31,8.07,29.52,5.19a.76.76,0,0,0-1.3,0L26.78,8.07,23.9,9.5A.75.75,0,0,0,23.5,10.16Zm4.15-.9A.78.78,0,0,0,28,8.94l.89-1.79.89,1.79a.73.73,0,0,0,.33.32l1.79.9-1.79.89a.75.75,0,0,0-.33.33l-.89,1.79L28,11.38a.81.81,0,0,0-.33-.33l-1.79-.89Z"/>
                </svg>
            )
        case ComProfile.V:
            return(
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42">
                    <defs>
                    </defs>
                    <path fill={props.color ? props.color : ""} d="M42.79,6.18h-6V3.26A.76.76,0,0,0,36,2.5H14a.76.76,0,0,0-.76.76V6.18h-6A.77.77,0,0,0,6.45,7v9.71c0,3.91,2.52,7.1,5.61,7.1h1.75a11.8,11.8,0,0,0,8.67,7.88,2.67,2.67,0,0,1-2.33,2.55h0c-2.17,0-3.94,2-3.94,4.55a.76.76,0,0,0,.77.76H33.06a.76.76,0,0,0,.77-.76c0-2.51-1.77-4.55-3.94-4.55h0a2.67,2.67,0,0,1-2.33-2.55,11.8,11.8,0,0,0,8.67-7.88h1.75c3.09,0,5.61-3.19,5.61-7.1V7A.77.77,0,0,0,42.79,6.18ZM12.06,22.23C9.81,22.23,8,19.73,8,16.66V7.71h5.26V20.15a11.92,11.92,0,0,0,.19,2.08ZM29.89,35.72h.05A2.61,2.61,0,0,1,32.22,38H17.78a2.61,2.61,0,0,1,2.28-2.25h.05A4.16,4.16,0,0,0,24,31.86c.33,0,.67.06,1,.06s.68,0,1-.06A4.16,4.16,0,0,0,29.89,35.72ZM25,30.39A10.25,10.25,0,0,1,14.77,20.15V4H35.23V6.94h0V20.15A10.25,10.25,0,0,1,25,30.39ZM42,16.66c0,3.07-1.83,5.57-4.08,5.57H36.57a11.92,11.92,0,0,0,.19-2.08V7.71H42Z"/>
                </svg>
            )
        case ComProfile.S:
            return(
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42">
                    <defs>
                    </defs>
                    <path fill={props.color ? props.color : ""} d="M43.5,21A18.39,18.39,0,0,0,38.08,7.91,18.31,18.31,0,0,0,32.2,4,18.53,18.53,0,0,0,12.56,7.29L11,5.74a.91.91,0,0,0-1.26,0A.89.89,0,0,0,9.74,7L11.3,8.56A18.5,18.5,0,0,0,37.44,34.7L39,36.25a.92.92,0,0,0,1.26,0,.9.9,0,0,0,0-1.27L38.7,33.43A18.38,18.38,0,0,0,43.5,21Zm-30-6.42a17.92,17.92,0,0,0,2.62,5.53,35.57,35.57,0,0,0,4.42,5.33,30.64,30.64,0,0,0,8.52,6.22,11,11,0,0,0,3.69,1A4.28,4.28,0,0,0,35,32.27l1.16,1.16a16.71,16.71,0,0,1-23.6-23.6L13.71,11A5.06,5.06,0,0,0,13.49,14.58ZM36.59,30a5.94,5.94,0,0,0-.08-2.16,16.12,16.12,0,0,0-2.18-5.07.87.87,0,0,0-.56-.4.83.83,0,0,0-.67.11.89.89,0,0,0-.3,1.23,14.23,14.23,0,0,1,2,4.48,2.76,2.76,0,0,1-.32,2.35c-.71.71-2.45.54-4.65-.46a29.1,29.1,0,0,1-8-5.85,34.77,34.77,0,0,1-4.19-5.06,16.32,16.32,0,0,1-2.38-5,3.24,3.24,0,0,1,.13-2.46,1,1,0,0,0,.2-.15,1.15,1.15,0,0,0,.14-.17c1-.67,3.45-.08,6.19,1.52a.89.89,0,1,0,.9-1.54A15.47,15.47,0,0,0,18,9.42a5.72,5.72,0,0,0-2.06,0,3.47,3.47,0,0,0-.94.32L13.83,8.57a16.71,16.71,0,0,1,23.6,23.59L36.25,31A3.53,3.53,0,0,0,36.59,30Z"/>
                    <path fill={props.color ? props.color : ""} d="M33.92,9.4a6,6,0,0,0-2.13.06,15.67,15.67,0,0,0-5,2.1,28.79,28.79,0,0,0-2.72,1.87c-.47.36-1,.75-1.42,1.16-.73.63-1.46,1.3-2.15,2-.31.31-.62.63-.92,1a.9.9,0,0,0-.24.64.94.94,0,0,0,.28.63.9.9,0,0,0,1.26-.05l.88-.9c.68-.68,1.37-1.32,2.06-1.91.44-.38.9-.75,1.35-1.1a26.34,26.34,0,0,1,2.54-1.75,14.14,14.14,0,0,1,4.39-1.88,2.83,2.83,0,0,1,2.31.33,1.69,1.69,0,0,1,.36,1.29c-.06,1.52-1,3.82-2.64,6.31a32.66,32.66,0,0,1-6.09,7,.89.89,0,0,0-.31.61.92.92,0,0,0,.2.65.9.9,0,0,0,.69.31.86.86,0,0,0,.57-.21A32.73,32.73,0,0,0,30.73,24a35,35,0,0,0,2.93-3.86c1.82-2.8,2.86-5.36,2.93-7.22a3.32,3.32,0,0,0-.9-2.63A3.16,3.16,0,0,0,33.92,9.4Z"/>
                    <path fill={props.color ? props.color : ""} d="M14.22,31.75a3.29,3.29,0,0,0,1.77.89,5.71,5.71,0,0,0,.84.07,7.52,7.52,0,0,0,1.29-.13,15.86,15.86,0,0,0,5-2.1.9.9,0,0,0,.3-1.23.89.89,0,0,0-.55-.4.9.9,0,0,0-.68.1,13.81,13.81,0,0,1-4.38,1.87,2.75,2.75,0,0,1-2.29-.33c-.92-.92-.37-3.46,1.4-6.46a.89.89,0,0,0-1.54-.91,15.26,15.26,0,0,0-2,4.84,5.57,5.57,0,0,0,0,2.07A3.22,3.22,0,0,0,14.22,31.75Z"/>
                </svg>
            )
        case ComProfile.IV:
            return(
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42">
                    <defs>
                    </defs>
                    <path fill={props.color ? props.color : ""} d="M28.12,34H21.94a.94.94,0,1,1,0-1.88h6.18a.94.94,0,0,1,0,1.88Z"/>
                    <path fill={props.color ? props.color : ""} d="M28.12,36.8H21.94a.94.94,0,1,1,0-1.88h6.18a.94.94,0,0,1,0,1.88Z"/>
                    <path fill={props.color ? props.color : ""} d="M26.73,39.5H23.32a.94.94,0,1,1,0-1.87h3.41a.94.94,0,1,1,0,1.87Z"/>
                    <path fill={props.color ? props.color : ""} d="M30.18,20.74a.94.94,0,0,1-.94-.94,3.74,3.74,0,0,0-2.64-3.59.94.94,0,0,1,.57-1.79,5.61,5.61,0,0,1,3.94,5.38A.94.94,0,0,1,30.18,20.74Z"/>
                    <path fill={props.color ? props.color : ""} d="M21.26,31.11a.93.93,0,0,1-.93-.84,5.46,5.46,0,0,0-1.55-2.51,10.94,10.94,0,0,1-3.22-7.54,9.46,9.46,0,0,1,18.92,0,11,11,0,0,1-3.21,7.54,5.42,5.42,0,0,0-1.55,2.51.93.93,0,1,1-1.86-.19,6.7,6.7,0,0,1,2-3.53c1.29-1.54,2.77-3.27,2.77-6.33a7.59,7.59,0,0,0-15.17,0c0,3.06,1.47,4.79,2.77,6.33a6.7,6.7,0,0,1,2,3.53.94.94,0,0,1-.83,1Z"/>
                    <path fill={props.color ? props.color : ""} d="M25,7.71a.94.94,0,0,1-.94-.94V3.44a.94.94,0,1,1,1.88,0V6.77A.94.94,0,0,1,25,7.71Z"/>
                    <path fill={props.color ? props.color : ""} d="M15.69,11.57A1,1,0,0,1,15,11.3L12.66,8.94a1,1,0,0,1,0-1.33.94.94,0,0,1,1.33,0L16.35,10a.94.94,0,0,1-.66,1.6Z"/>
                    <path fill={props.color ? props.color : ""} d="M36.67,32.56a.94.94,0,0,1-.66-.28l-2.36-2.36a.92.92,0,0,1,0-1.32.94.94,0,0,1,1.33,0L37.34,31a.94.94,0,0,1,0,1.32A1,1,0,0,1,36.67,32.56Z"/>
                    <path fill={props.color ? props.color : ""} d="M11.83,20.88H8.49a.94.94,0,1,1,0-1.87h3.34a.94.94,0,1,1,0,1.87Z"/>
                    <path fill={props.color ? props.color : ""} d="M41.51,20.88H38.17a.94.94,0,1,1,0-1.87h3.34a.94.94,0,1,1,0,1.87Z"/>
                    <path fill={props.color ? props.color : ""} d="M13.33,32.56a1,1,0,0,1-.67-.28.94.94,0,0,1,0-1.32L15,28.6a.94.94,0,0,1,1.33,0,.92.92,0,0,1,0,1.32L14,32.28A.94.94,0,0,1,13.33,32.56Z"/>
                    <path fill={props.color ? props.color : ""} d="M34.31,11.57a.94.94,0,0,1-.66-1.6L36,7.61a.94.94,0,0,1,1.33,0,1,1,0,0,1,0,1.33L35,11.3A1,1,0,0,1,34.31,11.57Z"/>
                </svg>
            )
        case ComProfile.RI:
            return(
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42">
                    <defs>
                    </defs>
                    <path fill={props.color ? props.color : ""} d="M44.52,13.76l-5-8.66a5.2,5.2,0,0,0-7.1-1.9l-8.66,5a.7.7,0,0,0-.35.44.76.76,0,0,0,.08.57l2.62,4.54a1,1,0,0,0,.92.48,1,1,0,0,0,.82-.65,1.65,1.65,0,0,1,.8-.94,1.67,1.67,0,1,1,.46,3.12,1,1,0,0,0-1,.38,1,1,0,0,0-.05,1l2.63,4.55a.74.74,0,0,0,1,.27l3.74-2.16A3.06,3.06,0,0,0,35.86,21a3.17,3.17,0,0,0,2,1.49,3.2,3.2,0,0,0,.75.09,3.08,3.08,0,0,0,1.7-.51A3.17,3.17,0,0,0,41.38,18a3.3,3.3,0,0,0-.87-1l3.74-2.16A.75.75,0,0,0,44.52,13.76ZM39,16.11a1,1,0,0,0,.17,1.74,1.62,1.62,0,0,1,.94.8,1.67,1.67,0,1,1-3.12.46,1,1,0,0,0-.38-1A1,1,0,0,0,36,18a1,1,0,0,0-.48.13l-3.9,2.26-1.79-3.1a3.25,3.25,0,0,0,1.17-.4,3.15,3.15,0,1,0-4.08-4.65L25.14,9.11l8-4.64a3.73,3.73,0,0,1,5.08,1.37l4.63,8Z"/>
                    <path fill={props.color ? props.color : ""} d="M35,23.56H29.79a1,1,0,0,0-.72,1.59,1.64,1.64,0,0,1,.41,1.16,1.68,1.68,0,0,1-1.59,1.62,1.75,1.75,0,0,1-1.24-.46,1.67,1.67,0,0,1-.1-2.32,1,1,0,0,0-.72-1.59H21.32V20a3.2,3.2,0,0,0,3.49-.73,3.11,3.11,0,0,0,.87-2.33,3.16,3.16,0,0,0-3-3,3.26,3.26,0,0,0-1.32.24V9.84a.74.74,0,0,0-.74-.74h-10a5.21,5.21,0,0,0-5.2,5.2v20a5.2,5.2,0,0,0,5.2,5.2h20a5.2,5.2,0,0,0,5.2-5.2v-10A.74.74,0,0,0,35,23.56ZM6.86,14.3a3.73,3.73,0,0,1,3.72-3.72h9.26V15.1a1,1,0,0,0,1.59.71,1.67,1.67,0,0,1,1.16-.41,1.67,1.67,0,1,1-1.16,2.93,1,1,0,0,0-1.59.72v4.51H16.26A3.15,3.15,0,0,0,13.2,19.2a3.17,3.17,0,0,0-3,3,3.1,3.1,0,0,0,.24,1.32H6.86Zm0,20V25h4.51a1,1,0,0,0,.72-1.59,1.64,1.64,0,0,1-.41-1.16,1.68,1.68,0,0,1,1.59-1.61,1.66,1.66,0,0,1,1.24.46A1.68,1.68,0,0,1,15,22.35a1.64,1.64,0,0,1-.42,1.1A1,1,0,0,0,15.33,25h4.51v3.58a3.2,3.2,0,0,0-3.49.73,3.15,3.15,0,0,0,2.17,5.33,3,3,0,0,0,1.32-.24V38H10.58A3.73,3.73,0,0,1,6.86,34.3Zm27.44,0A3.73,3.73,0,0,1,30.58,38H21.32V33.51a1,1,0,0,0-.56-.87.84.84,0,0,0-.27-.08,1,1,0,0,0-.76.23,1.63,1.63,0,0,1-1.16.41A1.67,1.67,0,0,1,17,31.61a1.61,1.61,0,0,1,.46-1.23,1.71,1.71,0,0,1,2.31-.11,1,1,0,0,0,1.59-.72V25H24.9A3.15,3.15,0,0,0,28,29.4a3.15,3.15,0,0,0,3-3A3.1,3.1,0,0,0,30.72,25H34.3Z"/>
                </svg>
            )
        case ComProfile.RS:
            return(
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42">
                    <defs>
                    </defs>
                    <path fill={props.color ? props.color : ""} d="M12,2.5H3.49A1.06,1.06,0,0,0,2.43,3.56v8.55a1.06,1.06,0,0,0,1.06,1.07H12a1.06,1.06,0,0,0,1.06-1.07V3.56A1.06,1.06,0,0,0,12,2.5ZM11,4.63v6.42H4.55V4.63Z"/>
                    <path fill={props.color ? props.color : ""} d="M12,15.66H3.49a1.06,1.06,0,0,0-1.06,1.06v8.56a1.06,1.06,0,0,0,1.06,1.06H12a1.06,1.06,0,0,0,1.06-1.06V16.72A1.06,1.06,0,0,0,12,15.66ZM11,17.79v6.42H4.55V17.79Z"/>
                    <path fill={props.color ? props.color : ""} d="M12,28.82H3.49a1.06,1.06,0,0,0-1.06,1.07v8.55A1.06,1.06,0,0,0,3.49,39.5H12a1.06,1.06,0,0,0,1.06-1.06V29.89A1.06,1.06,0,0,0,12,28.82ZM11,31v6.42H4.55V31Z"/>
                    <path fill={props.color ? props.color : ""} d="M17.25,6.9H39.71a1.07,1.07,0,1,0,0-2.13H17.25a1.07,1.07,0,0,0,0,2.13Z"/>
                    <path fill={props.color ? props.color : ""} d="M17.25,10.91H46.51a1.07,1.07,0,0,0,0-2.13H17.25a1.07,1.07,0,0,0,0,2.13Z"/>
                    <path fill={props.color ? props.color : ""} d="M17.25,20.06H39.71a1.07,1.07,0,1,0,0-2.13H17.25a1.07,1.07,0,0,0,0,2.13Z"/>
                    <path fill={props.color ? props.color : ""} d="M46.51,21.94H17.25a1.07,1.07,0,0,0,0,2.13H46.51a1.07,1.07,0,0,0,0-2.13Z"/>
                    <path fill={props.color ? props.color : ""} d="M17.25,33.22H39.71a1.06,1.06,0,1,0,0-2.12H17.25a1.06,1.06,0,1,0,0,2.12Z"/>
                    <path fill={props.color ? props.color : ""} d="M46.51,35.1H17.25a1.07,1.07,0,0,0,0,2.13H46.51a1.07,1.07,0,0,0,0-2.13Z"/>
                </svg>
            )
        default:
            return <div></div>
    }
};

export default ComprofileIcons;