import WizardLoading from "@/components/WizardComponents/WizardLoading/WizardLoading";
import { ReportNavigation } from "..";

import './style.css';

const ReportLayout: React.FunctionComponent<{
    children: React.ReactNode;
    routes?: { label: string, iconSrc: string | React.ReactNode, sectionid: string, href?: string, printModeLabel?: string, onlyAts?: boolean }[];
    isLoading?: boolean;
    activeSection?: string;
    onLinkClicked?: (sectionId: string) => any
}> = ({ children, routes, isLoading, activeSection, onLinkClicked }):JSX.Element => {
    return(
        <div className="report-layout-container">
            <ReportNavigation
            onLinkClicked={onLinkClicked}
            activeLink={activeSection}
            routes={routes}/>

            <div className="report-layout-contnent">
                {children}
            </div>

            <WizardLoading
            isLoading={isLoading}/>
        </div>
    )
}

export default ReportLayout;