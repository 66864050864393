import { ICompanyFormPage, ICompanyInfo } from "@/models";
import { CompanyFormPageAdapter } from "./companyFormPageAdapter";



export class CompanyInvitePageAdapter extends CompanyFormPageAdapter {
	constructor(page: ICompanyFormPage) {
		super(page, "invite");
	}

	protected _doUpdateFormPageFromCompany(company: ICompanyInfo): ICompanyFormPage {
		void company		
		return this.page;
	}



	createUpdate(): Partial<ICompanyInfo> {
		return { };
	}
}