import { gql } from "graphql-request"

export const updateUserProfile = gql`
  mutation UpdateUserMutation(
    $updateUserUserId: UUID!,
    $updateUserFirstName: String,
    $updateUserLastName: String,
    $updateUserJob: String,
    $updateUserDeleted: Boolean,
    $updateUserPending: Boolean,
    $updateUserPhone: Boolean,
    $updateUserDescription: String,
    $updateUserUserImageName: String,
    $updateUserUserImageData: String,
    $updateUserUserImage: String,
    $updateUserRole: String
    $updateUserComProfile: String
    $updateActive: Boolean
    $updateDepartmentId: UUID
  ) {
    updateUser(
      userId: $updateUserUserId,
      firstName: $updateUserFirstName,
      lastName: $updateUserLastName,
      jobTitle: $updateUserJob,
      deleted: $updateUserDeleted,
      pending: $updateUserPending,
      phone: $updateUserPhone,
      description: $updateUserDescription,
      userImageName: $updateUserUserImageName,
      userImageData: $updateUserUserImageData,
      userImage: $updateUserUserImage,
      role: $updateUserRole,
      comProfile: $updateUserComProfile,
      active: $updateActive,
      DepartmentId: $updateDepartmentId
    ) {
      message
    }
  }
`