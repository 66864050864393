import { gql } from "graphql-request";

export const getFavByCandidateIdQuery = gql`
query getFavByCandidateId($candidateId: UUID) {
    getFavByCandidateId(candidateId: $candidateId) {
        id
        firstName
    }
}`;

export const getFavByUserIdQuery = gql`
query GetFavByUserId($userId: UUID) {
    getFavByUserId(userId: $userId) {
        id
    }
}`;

export const getFavoritesCandidatesByUserIdQuery = gql`
query getFavoritesCandidatesByUserId($userId: UUID) {
    getFavByUserId(userId: $userId) {
        id
        finalProcessedProfile
        V2PositionId
        v2PositionTitle
        firstName
        lastName
        pdfUrl
        email
        referrer
        deleted
        isSharingProfile
        phone
        candidateStatus
        comProfile
        totalScore
        relevancy
        relevancyPerChannel
        favoredBy
        cvName
        cv
        status
        blocksScore
        createdAt
        updatedAt
    }
}`;