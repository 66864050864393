import { INotification } from "@/components/Notifications/types";
import { graphQueries } from "@/helpers";
import { useEffect, useState } from "react"
import useLocalStorage from "../../../hooks/useLocalStorage";

const useNotifications = (query: string, params: {}, pathToData: string): {
    notifications: any[] | [],
    readedNotifications: string[] | [] | undefined,
    getUnreadNotificationsCount: () => number,
    markAllNotificationsAsRead: () => void,
    markNotificationAsRead: (id: string) => void
} => {
    
    const [readedNotifications, setReadedNotifications] = useLocalStorage("readedNotifications", []);

    const [notifications, setNotifications] = useState<any[] | []>([]);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState<number>(0);

    const notificationIsRead = (id: string): boolean => {
        if(Array.isArray(readedNotifications))
            return readedNotifications.includes((id))
        return false
    }

    const markNotificationAsRead = (id: string): void => {
        if(notificationIsRead(id))
            return

        const newReadedNotifications = [...readedNotifications]
        console.log(newReadedNotifications);
        
        newReadedNotifications.push(id)

        setReadedNotifications(newReadedNotifications)
        setUnreadNotificationCount(unreadNotificationCount-1)
    }

    
    const markAllNotificationsAsRead = (): void => {
        setUnreadNotificationCount(0);
        setReadedNotifications(notifications.map(n => n.id))
    }

    const getUnreadNotificationsCount = (): number => {
        return notifications.length - readedNotifications.length
    }
    
    const checkForUnreadedNotification = (notifications: any) => {
        const newReadedNotifications: string[] = [];
        notifications.map((n: any) => {
            if(notificationIsRead(n.id))
                newReadedNotifications.push(n.id)
        });
        
        setReadedNotifications(newReadedNotifications)
    }

    const navigateToNotificactionArr = (data: any, pathToNotification: string): any[] => {
        const paths = pathToNotification.split('/');

        let dataToReturn: any = data;
        paths.map(p => dataToReturn = dataToReturn[p])
        
        return dataToReturn
    }

    const fetchAllNotifications = async (): Promise<INotification[] | []> => {
        try {
            const data = await graphQueries.sendRequest(query, params);
    
            return navigateToNotificactionArr(data, pathToData)
        } catch(e) {
            console.error(e);
            return []
        }
    }
    
    useEffect(() => {
        fetchAllNotifications()
        .then((notifications) => {
            setNotifications(notifications);
            checkForUnreadedNotification(notifications)
        });
    }, []);

    return { 
        notifications, 
        readedNotifications,
        getUnreadNotificationsCount,
        markAllNotificationsAsRead,
        markNotificationAsRead
    }
}

export default useNotifications