import React, { useEffect, useState } from "react";
import Dropdown, { IDropdown } from "../Dropdown/Dropdown";
import { ListItemText, MenuItem, SelectChangeEvent } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import './style.css';

type SimpleSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;
interface IMultiSelectExtra {
    onChange?: (name: string, selectedOptions: string[]) => any;
    containerStyle?: React.CSSProperties;
    disableHandleChange?: boolean;
}

// eslint-disable-next-line
export interface IMultiSelect extends SimpleSpread<IDropdown, IMultiSelectExtra> {}

const MultiSelect: React.FunctionComponent<IMultiSelect> = 
(props): JSX.Element => {
    const { options, onChange, placeholder, value, containerStyle, children, disableHandleChange, ...rest } = props;
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    useEffect(( )=> {
        setSelectedOptions(value)
    }, [value])

    const handleSelectChange = (event: SelectChangeEvent<any>) => {
        if(disableHandleChange) {
            return null;
        }
        const {
            target: { value },
        } = event;

        const selected = typeof value === 'string' ? value.split(',') : value
        setSelectedOptions(
            selected
        );

        onChange?.(event.target.name, selected)
    };

    return (
    <Dropdown
    containerStyle={containerStyle}
    renderValue={(value: any) => value && value.length ? options.flatMap(o => value?.includes?.(o.value) ? o.label : []).join(", ") : placeholder ?? ""}
    {...rest}
    multiple
    value={selectedOptions}
    options={[]}
    onChange={handleSelectChange}
    > 
        {children ? children : 
        options &&
        options.map((o, i) => (
        <MenuItem key={i} value={o.value}>
            {/* <Checkbox checked={selectedOptions.findIndex(so => so === o.value) > -1} /> */}
            <div className="checked-icon-container">
            {selectedOptions.findIndex(so => so === o.value) > -1 &&
            <DoneIcon sx={{ fontSize: "14px" }}/>}
            </div>
            <ListItemText 
            sx={{
                "& .MuiTypography-root": {
                    fontSize: "12px",
                    fontFamily: "Mulish"
                }
            }}
            primary={o.label} />
        </MenuItem>
        ))}
    </Dropdown>
    )
}


export default MultiSelect;