import React, { useCallback, useContext } from "react";
import { toast, ToastOptions, UpdateOptions, Id } from "react-toastify";
import AppContext from "@/context/AppContext";

export type ToastFunction = (message: string, options: ToastOptions) => React.ReactText;


export interface IUseToast {
    toast: ToastFunction
    update(toastId: Id, options: UpdateOptions): void;
}


function useIt(): IUseToast {
    const { tr, locale } = useContext(AppContext)

    const toastIt = useCallback((message: string, options: ToastOptions) => {
        return toast(tr(message), options)
    }, [locale])

    const updateIt = useCallback((id: Id, options: UpdateOptions) => {
        if (typeof options?.render === "string") {
            options = {
                ...options,
                render: tr(options.render)
            }
        }
        return toast.update(id, options)
    }, [locale])

    return {
        toast: toastIt,
        update: updateIt
    }
}

export default useIt;