import { useState, useContext, useEffect } from "react";
import Title from "../Title/Title";

import './style.css';
import AppContext from "@/context/AppContext";

const WizardStepTemplate: React.FunctionComponent<{ children: any, title?: string, description?: string | JSX.Element, previewComponent?: any}> = ({
    children, title, description, previewComponent
}):JSX.Element => {
    const { tr, locale } = useContext(AppContext);
    const [desc, setDesc] = useState<string | JSX.Element>(description!);

    useEffect(() => {
        if (typeof description === "string") {
            setDesc(tr(description));
        }
    }, [locale, description]);

return (
        <div>
            <div className="wizard-step-template-header-container">
                {title &&
                <Title
                size="xl" 
                className="wizard-step-template-title"
                >{tr(title)}</Title>}
                {description &&
                <p className="wizard-step-template-description">{desc}</p>}
            </div>
            <div className="wizard-step-template-content-container">
                <div className="wizard-step-template-content">
                    {children}
                </div>
                {previewComponent &&
                <div className="wizard-step-template-preview-section">
                    {previewComponent}
                </div>}
            </div>
        </div>
    )
}

export default WizardStepTemplate;