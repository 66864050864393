// Router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Middleware Routes
import { MiddlewareRoute } from "@routes";

// Sidebar Layout
// import SidebarLayout from "@layouts/SidebarLayout"

// Pages
import {
  // Public
  Login,
  SignUp,

  // Private
  Talents,
  Positions,
  Dashboard,
  Permissions,
  CompanyOnboarding,
  Assessments,
  AssessmentEditor,
  CreateCompany,
  UserProfile,
  Referrals,
  BuildingBlocks,
  PositionWizard,
  DiscoveryWizard,
  CandidateReportNavigator,
  HRCorner,
  Recommendations,
  MyFavorites,
  // Integrations,
  // Fall case
  NotFound,
} from "@pages"

// Stages
//import PreCreateCompany from "@pages/CreateCompany/steps/PreCreateCompany"
import { ResumeCreateCompany } from "@/pages/CreateCompany/steps"
import Layout from "@/layouts/Layout"

import { useAuth } from "@/auth";

// import ComProfileSurvey from "@/components/ComProfileSurvey"

/**
 *
 *  Application Routes
 *  @description contains all public, private and fall cases routes. Includes Middleware Routes.
 *
 */
const Routes: React.FunctionComponent = (): React.ReactElement => {
  const auth = useAuth();
  return (
    <Router>
      <Switch>
        {/* Public */}
        <MiddlewareRoute
          exact
          path="/"
          middlewares={["logged"]}
          component={Login}
        />
        <MiddlewareRoute
          exact
          path="/sign-up"
          middlewares={["logged"]}
          component={SignUp}
        />

        {/* Private */}
        <MiddlewareRoute
          exact
          path="/onboarding"
          middlewares={["auth"]}
          component={CompanyOnboarding}
        />
        {auth?.isAuthenticated === false ? (
          <Route render={() => <Redirect to={{
            pathname: "/",
            search: window.location.search,
            state: { referrer: window.location.href }
          }} />} />
        ) : null}


        {/* Private with Sidebar Layout */}
        {auth?.isAuthenticated && (
          <Layout>
            <Switch>
              {/* Dashboard */}
              <MiddlewareRoute
                exact
                path="/dashboard"
                middlewares={["auth"]}
                component={Dashboard}
              />

              {/* Assessments */}
              <MiddlewareRoute
                exact
                path="/assessments"
                middlewares={["auth", "admin", "position-bank"]}
                component={Assessments}
              />
              <MiddlewareRoute
                exact
                path="/editor/assessment"
                middlewares={["auth", "admin"]}
                component={AssessmentEditor}
              />
              {/* BuildingBlocks */}
              <MiddlewareRoute
                exact
                path="/buildingblocks"
                middlewares={["auth", "admin", "position-bank"]}
                component={BuildingBlocks}
              />

              <MiddlewareRoute
                exact
                path="/recommendations"
                middlewares={["auth", "admin", "position-bank"]}
                component={Recommendations}
              />

              {/* Roles & Permissions */}
              <MiddlewareRoute
                exact
                path="/permissions"
                middlewares={["auth", "all-admins"]}
                component={Permissions}
              />

              {/* Positions */}
              <MiddlewareRoute
                exact
                path="/positions"
                middlewares={["auth"]}
                component={Positions}
              />
              <MiddlewareRoute
                exact
                path="/positions/wizard"
                middlewares={["auth"]}
                component={PositionWizard}
              />
              <MiddlewareRoute
                exact
                path="/positions/wizard/:positionId"
                middlewares={["auth"]}
                component={PositionWizard}
              />
              <MiddlewareRoute
                exact
                path="/positions/wizard/discovery/:positionId"
                middlewares={["auth"]}
                component={DiscoveryWizard}
              />
              <MiddlewareRoute
                exact
                path="/positions/talents/:positionId"
                middlewares={["auth"]}
                component={Talents}
              />

              <MiddlewareRoute
                exact
                path="/my-favorites"
                middlewares={["auth"]}
                component={MyFavorites}
              />

              {/* comProfile */}
              {/* <MiddlewareRoute exact path="/com-profile" middlewares={["auth"]} component={ComProfileSurvey} /> */}

              {/* Company */}
              {/*<MiddlewareRoute exact path="/company/create/stage" middlewares={["auth"]} component={PreCreateCompany} />*/}
              <MiddlewareRoute
                exact
                path="/company/create"
                middlewares={["auth"]}
                component={CreateCompany}
              />
              <MiddlewareRoute
                exact
                path="/company/create/done"
                middlewares={["auth"]}
                component={ResumeCreateCompany}
              />
              {/* <MiddlewareRoute
                exact
                path="/integrations"
                middlewares={["auth"]}
                component={Integrations}
              /> */}
              <MiddlewareRoute
                exact
                path="/hr-corner"
                middlewares={["auth", "hr"]}
                component={HRCorner}
              />

              {/* Review */}
              <MiddlewareRoute
                exact
                path="/review/:positionId/:candidateId"
                middlewares={["auth"]}
                component={CandidateReportNavigator}
              />

              {/* Referrals */}
              <MiddlewareRoute
                exact
                path="/referrals"
                middlewares={["auth"]}
                component={Referrals}
              />

              {/* User Profile */}
              <MiddlewareRoute
                exact
                path="/myprofile/:userId"
                middlewares={["auth"]}
                component={UserProfile}
              />
              <MiddlewareRoute
                exact
                path="/profile/:userId"
                middlewares={["auth", "all-admins"]}
                component={UserProfile}
              />

              {/* 404 fall case */}
              <MiddlewareRoute middlewares={["auth"]} component={NotFound} />
            </Switch>
          </Layout>
        )}
      </Switch>
    </Router>
  );
};

export default Routes;
