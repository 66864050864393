// React
import React from 'react'

// Select
import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'

// Custom Styles
import doCustomStyles from "@components/WizardForm/components/DynamicInputSelect/customStyles"

// Wizard Form context
import { WizardFormContext } from "@components/WizardForm/context"

// Utils
import cx from "classnames"
import { graphQueries, objectUtils } from '@/helpers'
import { formModelUtils } from '@/models/formModelUtils'
import { QuestionCollection } from '@/models/formPage'

// Local Interfaces
interface DynamicInputSelectProps {
  url: string | any[]
  index: number
  grow?: boolean
  isMulti: boolean
  subIndex: number
  valueType: string | Array<any>
  isCustom: boolean
  pageIndex: number
  editable: boolean
  placeholder: string
  fullWidth: boolean
  createLabel: string
  noOptionsMessage: string
}

const dropdownWidth: any[] = []

/**
 *
 *  Dynamic Input Select
 *  @description generates a react input select with async data loading and custom values creation
 *
 */
const DynamicInputSelect: React.FC<DynamicInputSelectProps> = ({
  url,
  valueType,
  placeholder,
  fullWidth,
  isMulti,
  editable=false,
  pageIndex,
  index,
  subIndex,
  isCustom,
  createLabel,
  grow=false,
  noOptionsMessage }) => {

  // Context
  const {
    setDataCollection,
    dataCollection,
    templateQuestions,
    customQuestions
  } = React.useContext(WizardFormContext)

  const questionTypeKey = !isCustom ? "questions" : "customQuestions"

  const Select = editable ? AsyncCreatableSelect : AsyncSelect

  // Load default Options
  const loadDefaultOptions = (inputValue: string, callback: any) => {
      if (typeof url !== "string") {
          if (Array.isArray(url)) {
            callback(url)

          } else if (typeof url === "object") {
            callback([ {
              value: (url as any).value || 0,
              label: (url as any).label ||
                Object.entries(url).map(([key, value]) => `${key}: ${value}`).join(',')
            }])

          } else {
            callback([{ key: 0, label: String(url)}])
          }
      }

      // Fixed by dfl - maybe this was stale data, but anyway, the url was https://6112e00389c6d00017ac05cb.mockapi.io/jobs
      // So I handled this case
      else if (/^https?:\/\/.+/i.test(url)) {
        fetch(url)
          .then(response => {
            response.json().then(data => {
              if (Array.isArray(data)) {
                callback(data.map(item => ({
                  value: item.id,
                  label: item.name
                })))
              }
            })
          })
          .catch(err => {
            console.error(err)
            callback([ String(err)]) // So it will show up in the select options
          })
      } else if (/^(\/[a-z]+)+$/.test(url)) {
          graphQueries.getEditorValueList({ url, valueType: valueType as string, inputValue })
            .then((items: Array<{id: string, name: string}>) => {
              const defaultValues = items.map(item => ({
                value: item.id,
                label: item.name
              }))

              callback(defaultValues)
          })
          .catch(err => {
            console.error(err)
            callback([ String(err)]) // So it will show up in the select options
        })
    } else {
      callback([url])
    }
  }

  // Handlers
  const onSelectChange = (selectedOptions: any, triggeredAction: any) => {
    // On Clear
    if (triggeredAction.action === 'clear' || !selectedOptions) {
      dropdownWidth[pageIndex][index][subIndex] = `${( 8 * placeholder.length ) + 90}px`
    }


    setDataCollection((dc: any) => {
      // Copy of data collection to be modified
      const newDc: any = [...dc]
      const questions: QuestionCollection = newDc[pageIndex][questionTypeKey]
      const templates = isCustom ? customQuestions : templateQuestions

      formModelUtils.setValueInQuestionCollection({
        collection: questions,
        index,
        subIndex,
        template: templates[pageIndex][index][subIndex],
        data: {
          value: selectedOptions
        }
      })

      // Set a new copy of data collection (dc)
      return newDc
    })
  }

  // Reference to current Question values (including all sub-parts)
  const currentQuestionValues = objectUtils.getValueByKeys(dataCollection, [pageIndex, questionTypeKey, index, subIndex, "value"], null)

  return (
    <Select
      isMulti={isMulti}
      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null, LoadingIndicator:() => null }}
      onChange={onSelectChange}
      placeholder={placeholder}
      cacheOptions={true}
      defaultOptions={true}
      loadOptions={loadDefaultOptions}
      isClearable={false}
      classNamePrefix="wizard-form"
      formatCreateLabel={(value: string) => `${createLabel || 'Create'} ${value}`}
      noOptionsMessage={() => noOptionsMessage || 'No Options'}
      className={cx({ "flex-grow": grow })}
      styles={doCustomStyles(
        {
          width: fullWidth ? '90%' : ''
        })
      }
      value={currentQuestionValues}
    />
  )
}

export default DynamicInputSelect
