export default {
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  extendSurveyJs: (Survey: any): void => {
    Survey.useWidgets = function (widgetCollection: any) {
      widgetCollection.forEach((widget: any) => {
        this.CustomWidgetCollection.Instance.addCustomWidget(widget);
      });
    };
  },
};
