const Dashboard: React.FunctionComponent = (): JSX.Element => {
  return (
    <main id="dashboard">
      <div className="grid grid-cols-2 gap-4">
        <div className="flex justify-center items-center">Analytics 1</div>
        <div className="flex justify-center items-center">Analytics 2</div>
        <div className="flex justify-center items-center">Analytics 3</div>
        <div className="flex justify-center items-center">Analytics 4</div>
      </div>
    </main>
  )
}

export default Dashboard
