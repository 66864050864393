import { generateCreateV2PositionMutation, generateUpdateV2PositionMutation } from "./graphql-mutations";
import { GetV2PositionQuery } from "./graphql-query";
import { 
  CreateV2PositionRequest, 
  CreateV2PositionResponse, 
  GetV2PositionRequest, 
  GetV2PositionResponse, 
  UpdateV2PositionRequest, 
  UpdateV2PositionResponse 
} from "./interfaces";
import { graphQueries } from "@/helpers";

export class GraphQlClient {
  static async createPosition(args: CreateV2PositionRequest): Promise<CreateV2PositionResponse | undefined> {
    const mutation = generateCreateV2PositionMutation(args);
    try {
      const result = await graphQueries.sendRequest(mutation, args);
      return result;
    } catch(e: any) {
      console.error({ func: 'createPosition', args });
      console.log(mutation);
      console.error(e);
    }    
  }
  
  static async updatePosition(args: UpdateV2PositionRequest): Promise<UpdateV2PositionResponse | undefined> {
    const mutation = generateUpdateV2PositionMutation(args);
    try {
      const result = await graphQueries.sendRequest(mutation, args);
      return result;
    } catch(e) {
      console.error({ func: 'updatePosition', args });
      console.log(mutation);
      console.error(e);
    }    
  }

  static async getPosition(args: GetV2PositionRequest): Promise<GetV2PositionResponse | undefined> {
    try {
      const result = await graphQueries.sendRequest(GetV2PositionQuery, args);
      return result;
    } catch(e) {
      console.error(e);
    }    
  }

}