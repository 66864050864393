// React
import { ICompanyInfo } from "@/models"
import { ILocalizer } from "lnrd-locales"
import { createContext } from "react"

// Intefaces

export interface SelectCompanyOption {
  label: string
  value: string
}

export type IAppArguments = URLSearchParams

export interface IAppContext {
  readonly companiesList: ICompanyInfo[]
  readonly setCompaniesList: React.Dispatch<ICompanyInfo[]>
  readonly locale: string;
  setLocale: React.Dispatch<string>;
  tr<T extends {}>(key: T, keys?: string | string[]): T;
  readonly localizer: ILocalizer;
}

// Default context
const defaultCtx: IAppContext = {
  companiesList: [],
  setCompaniesList: () => { throw new Error(`Default setCompaniesList`); },
  locale: "en",
  setLocale: (arg) => { throw new Error(`Default set locale ${arg}`); },
  tr: <T extends string | object>(arg: T) => { throw new Error(`Default tr ${arg}`); },
  localizer: null as unknown as ILocalizer
}

// Context
const AppContext = createContext<IAppContext>(defaultCtx)

export default AppContext
