import { CircularProgress, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';


const MainCircularProgress = styled(CircularProgress)({
    color: '#FF8E5A',
});  

const BackgroundCircularProgress = styled(CircularProgress)({
    color: '#262626',
});  

// Interfaces
interface ICustomCircularProgress {
    value: number | any
}

const CustomCircularProgress: React.FunctionComponent <ICustomCircularProgress> = (props): JSX.Element => {
    const {value} = props;
    return (
        <Box className="box-wrapper" sx={{ position: "relative", display: "inline-flex"}}>
            <MainCircularProgress
            variant="determinate"
            thickness={1.4} 
            value={isNaN(value) ? 0 : Number(value)}
            size={120}
            style={{
                // width: "100%",
                // height: "100%",
                position: "relative",
                zIndex: "2"
            }}/>
            <BackgroundCircularProgress
                variant="determinate"
                thickness={1.4}
                value={100}
                size={120}
                style={{
                    // width: "100%",
                    // height: "100%",
                    right: "0",
                    position: "absolute"
                }}
            />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px"
                    }}>
                    <Typography
                        variant="caption"
                        component="div"
                        style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "30px",
                            color: "#262626",
                            lineHeight: "38px",
                            textAlign: "center",
                        }}              
                        >{value}</Typography>
                </Box>
        </Box>
    )
}

export default CustomCircularProgress;