// Interfaces
import { IUser } from "@interfaces/pages/permissions"

import { GraphQueries, IGraphQueries } from "./graphQueries"
import { IObjectUtils, ObjectUtils } from "./objectUtils"
import { IStringUtils, StringUtils } from "./stringUtils"
import { IImageUtils, ImageUtils} from "./imageUtils"
/**
 *
 *  URL With Params
 *  @description add url params to the end of the provided url
 *
 */
export const URLWithParams = (url: string, params: { [index: string]: any }): string => {
  const urlParams: URLSearchParams = new URLSearchParams()
  Object.entries(params).forEach(p => urlParams.set(p[0], p[1]))

  return `${url}?${urlParams.toString()}`
}

/**
 *
 *  To Base64
 *  @description convert any File to Base64
 *
 */
export const toBase64 = (file: File): Promise<string|ArrayBuffer|null> => new Promise((resolve, reject) => {
  const reader = new FileReader()

  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
  reader.readAsDataURL(file)
})

const URL_RE = /^\s*https?:\/\/.+/i
export const isURL = (url: string | null | undefined): boolean => {
	if (!url) {
		return false
	}
	return URL_RE.test(url)
}

/**
 *
 *  Get Rank
 *  @description returns text and color for assessment ranking
 *
 *  Very Poor (0-60) red
 *  Poor (60-70) orange
 *  Okay (70-80) yellow
 *  Good (80-95) green
 *  Superb (95-100) dark green
 */
export const formatRank = (rank: number): any => {
  let formattedRank = {}

  if (rank <= 60) {
    formattedRank = { text: `Very Poor (${rank})`, color: "text-red-400" }

  } else if (rank > 60 && rank <= 70) {
    formattedRank = { text: `Poor (${rank})`, color: "text-yellow-600" }

  } else if (rank > 70 && rank <= 80) {
    formattedRank = { text: `Okay (${rank})`, color: "text-yellow-400" }

  } else if (rank > 80 && rank <= 95) {
    formattedRank = { text: `Good (${rank})`, color: "text-green-600" }

  } else if (rank > 95 && rank <= 100) {
    formattedRank = { text: `Superb (${rank})`, color: "text-green-600" }
  }

  return formattedRank
}

/**
 *
 *  Copy to Clipboard
 *  @description copy a text to browser clipboard
 *
 */
export async function copyToClipboard(text: string): Promise<any> {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text)

  } else {
    return document.execCommand('copy', true, text)
  }
}

/**
 *
 *  Truncate string
 *  @description truncate a string
 *
 */
export const truncateString = (str: string, num: number): string => {
  if (str.length <= num) {
    return str
  }

  return str.slice(0, num) + '…'
}

/**
 *
 *  Generate Query Key
 *  @description generate a clean query key based on id or concatenations of id's
 *
 */
export const generateQueryKey = (dirtyKey: string): string => {
  return dirtyKey.replace(/[0-9]/g, "").replaceAll("-", "_")
}

/*
 *  Get Public Name by User
 *  @description returns public name based on user data info
 *
 */
export const getPublicNameByUser = (user: IUser): string => {
  // If both names exists => show fullname
  if ( user.firstName && user.lastName ) {
    return `${user.firstName} ${user.lastName}`
  }

  // else if only firstname exists => show only firstname
  if ( user.firstName ) {
    return user.firstName
  }

  // otherwise show email or just "User" as fallback case
  return user.email || "User"
}

/*
 *  Get Candidate Name
 *  @description returns candidate name
 *
 */
export const getCandidateName = (firstName: string, lastName: string): string => {
  // If both names exists => show fullname
  if ( firstName && lastName ) {
    return `${firstName} ${lastName}`
  }

  // else if only firstname exists => show only firstname
  if ( firstName ) {
    return firstName
  }

  // otherwise show email or just "User" as fallback case
  return "Anonymous"
}

/*
 *  Validate an email
 *  @description returns true|false based on email validation
 *
 */
export const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

/* 
 * Validate email pattern and domain 
 *  @description returns true|false based on email validation
 */
export const validateEmailandDomain = (email: string, companyDomain: string): boolean => {
  const domain = email.substring(email.lastIndexOf("@") +1);

  if (validateEmail(email) && domain === companyDomain) {
    return true
  }
  
  return false
}

/**
 *
 * Get Ref Code from Link (referral link)
 * @description extract ref code from referral generated link
 *
 */
export const getRefCodeFromLink = (link: string): string | null => {
  // We match refCode
  const match: RegExpMatchArray | null = link?.match(/refCode=[-_a-zA-z0-9]+$/)
  const refCode: string | null = match ? match[0].replace("refCode=", "") : null

  return refCode
}

/**
 *
 * Name to ID convertion
 * @description converts a single name to ID format
 *
 */
export const nameToIdConvertion = (name: string): string =>
  name.replace(/\s|\./g, "-").toLowerCase()

/*
 *  Get Job Simulator position url
 *  @description returns Job Simulator position url
 *
 */
export const getJobSimPositionUrl = (
    url: string,
    companyName: string,
    companyId: string,
    positionId: string
  ): string => {

  const paramsObj: any = {
    "[company_name]": companyName,
    "[company_id]": companyId,
    "[position_id]": positionId
  }

  return url.replace(/(\[(?:\[??[^[]*?\]))/gi, matched => paramsObj[matched]);
}

export interface IJobSimulatorURLParams {
  url: string;
  companySlug: string;
  positionSlug: string;
  templatesNum: string;
  isUnboxableUser: boolean;
  params?: {
    [key: string]: string
  }
}

export const getJobSimPositionNewUrl = ({
  url,
  companySlug,
  positionSlug,
  templatesNum,
  isUnboxableUser,
  params
}: IJobSimulatorURLParams
): string => {
  const ret = new URL(
    (!templatesNum || templatesNum === '0') ?
    `${url}/${companySlug}/${positionSlug}/position`
    : `${url}/${companySlug}/${positionSlug}/${templatesNum}/position`
  )
  if (isUnboxableUser) {
    ret.searchParams.set("visit", "internal");
  }
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      ret.searchParams.set(key, value);
    })
  }
  return ret.toString();
  // if(templatesNum == undefined || (templatesNum && templatesNum === '0')) {
  //   return`${url}/${companySlug}/${positionSlug}/position${isUnboxableUser ? `?visit=internal`: ``}`;

  // }
  // return`${url}/${companySlug}/${positionSlug}/${templatesNum}/position${isUnboxableUser ? `?visit=internal`: ``}`;
}

export const isUnboxableUser = (userEmail: string | null | undefined): boolean => {
  if(userEmail)
    return validateEmailandDomain(userEmail, 'unboxable.com');
  return false;
}

export const capitalizeFirstLetter = (string: string): string => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const flatten = (arr:any[][]): any[] => {
  return arr.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}


export const removeDuplicates = (a: any[], key: (args: any) => string): any[] => {
  const seen: any = {};
  return a.filter(function(item) {
      const k = key(item);
      return Object.prototype.hasOwnProperty.call(seen, k) ? false : (seen[k] = true);
  })
}

export const diffTimesItervals = (
  times: { s: moment.Moment; e: moment.Moment }[],
): number[] => {
  const sortedByStarts = times.sort((t1, t2) => t1.s.diff(t2.s, 'months'));
  const diff_result = [];
  let end: any;
  for (const time of sortedByStarts) {
    const { s, e } = time;
    if (!end) {
      end = e;
    }
    const diff = s.diff(end, 'months');
    if (diff > 0) {
      diff_result.push(diff);
    }
    end = e;
  }
  return diff_result;
};

export const navigateSmoothToDivById = (sectionId: string): void => {
  const section = document.getElementById(sectionId);
  section && section.scrollIntoView({ behavior: 'smooth' });
}

export const objectUtils: IObjectUtils = new ObjectUtils();
export const graphQueries: IGraphQueries = new GraphQueries();
export const stringUtils: IStringUtils = new StringUtils()
export const imageUtils: IImageUtils = new ImageUtils()
