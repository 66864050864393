// Interfaces
import { IMenuItem } from "@interfaces/sideMenu"

// Icons
import BulbIcon from "@assets/icons/bulb.png"
// import DashboardIcon from "@assets/icons/dashboard.png"
// import SettingsIcon from "@assets/icons/settings.png"
import UsersIcon from "@assets/icons/users.png"

import companyModel from "@/formsData/createCompany.json"

// import KnowledgeIcon from "@assets/icons/knowledge.png"

// Menu Items
export const mainMenuItems: IMenuItem[] = [
  // {
  //   name: "Data & Insights",
  //   route: "/dashboard",
  //   icon: DashboardIcon,
  //   iconSize: "h-3"
  // },
  {
    name: "Simulations & Candidates",
    icon: BulbIcon,
    route: "/positions",
    iconSize: "h-4",
    // subItems: [
    //   { name: "All Simulations", route: "/positions" },
    //   { name: "Create a new job simulation", route: "/positions/wizard" }
    // ]
  },
  {
    name: "Company",
    icon: UsersIcon,
    // route: "/company/create/#company-story",
    iconSize: "h-3",
    subItems: [
      // Items from company tabs model
      ...companyModel.pages.filter(page => page.enabled !== false)
        .map(page => ({ name: page.name, route: `/company/create#${page.hash}`})),

      // ...rest
      {
        name: "Referrals",
        route: "/referrals",
      },
      // {
      //   name: "Integrations",
      //   route: "/integrations",
      // },
      {
        name: "Roles and Permissions",
        route: "/permissions",
        permissions: ["companyAdmin"]
      }
    ] 
  },
  {
    name: "HR corner",
    icon: BulbIcon,
    route: "/hr-corner",
    iconSize: "h-4",
    permissions: ["hr"],
    subItems: [
      {
        name: "Recommendations",
        route: "/hr-corner#recommendations"
      },
      {
        name: "Recruitment Statistics",
        route: "/hr-corner#stats",
      },
      // {
      //   name: "Hiring Brain",
      //   route: "/hr-corner#hiring-brain",
      // },
    ]
  },
  {
    name: "Favorites",
    route: "/my-favorites"
  },
  {
    name: "Internal",
    subItems: [
      {
        name: "Assessments & Questions bank",
        route: "/assessments"
      },
      {
        name: "Building blocks",
        route: "/buildingblocks",
      },
      {
        name: "Recommendations center",
        route: "/recommendations",
      }
    ]
  }
  // {
  //   name: "Settings",
  //   route: "/dashboard",
  //   icon: SettingsIcon,
  //   iconSize: "h-4"
  // },
  // {
  //   name: "Knowledge center",
  //   route: "/dashboard",
  //   icon: KnowledgeIcon,
  //   iconSize: "h-4"
  // }
];