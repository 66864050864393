
import { AnyObject } from "@/helpers/objectUtils";
import { IQueryObject } from "."


export interface ILeaderInfo {
	name: string
	jobTitle: string
	email: string
	description: string
	image: string
}

export interface ICultureValueInfo {
	text: string
}

export interface ICompanyExtraInfo {
	readonly text: string
	readonly title: string
	readonly image: string;
}

export interface ICompanyInfo extends IQueryObject {
	readonly owner: string | null
	readonly name: string | null
	readonly email: string | null
	readonly emailDomain: string
	readonly clientId: string | null
	readonly customSubdomain: string | null
	readonly websiteLink: string | null
	readonly description: string | null
	readonly yearFounded: string | null,
	readonly industry: string[]
	readonly sizeRange: string | null
	readonly locality: string[]
	readonly cultureAndValue: Partial<ICultureValueInfo>[]
	readonly leaders: Partial<ILeaderInfo>[]
	readonly linkedinUrl: string | null
	readonly domain: string | null
	readonly hq: AnyObject | null;
	readonly additionalInfo: Partial<ICompanyExtraInfo>[]
	readonly profilePictureUrl: string | null
	readonly currentEmployeeEstimate: number
	readonly totalEmployeeEstimate: number
	readonly atsProvider: string | null
	readonly atsApiKey: string | null
	readonly atsCompanyUid: string | null
	readonly metadata: AnyObject | null
	readonly slug: string | null
}


export class CompanyInfo implements ICompanyInfo {

	constructor(id: string) {
		this.id = id;
	}

	fromQueryObject(data: AnyObject): this {
		const companyData = data as Partial<ICompanyInfo>;
		const plainThis = this as AnyObject;
		if (!companyData || (typeof companyData !== "object")) {
			return this;
		}
		for (const [key, value] of Object.entries(companyData)) {
			if (value === null || value === undefined) {
				continue;
			}
			if (Array.isArray(value)) {
				if (value.length) {
					plainThis[key] = value;
				}
			}
			else {
				plainThis[key] = value;
			}
		}
		return this;
	}

	toQuery(includeEmptyFields: boolean): string {
		if (includeEmptyFields) {
			includeEmptyFields = true;
		}

		throw new Error("Method not implemented.");
	}
	update(data: AnyObject): this {
		if (!data || typeof data !== "object") {
			return this;
		}

		return this;
	}

	private _id = ""

	public get id(): string {
		return this._id
	}

	private set id(_id: string) {
		if (_id === this._id) {
			return;
		}
		if (this._id) {
			throw new Error("Company info: cannot reset id")
		}
		this._id = _id;
	}

	emailDomain = ""
	owner: string | null = null
	name: string | null = null
	email: string | null = null
	clientId: string | null = null
	subdomain: string | null = null
	websiteLink: string | null = null
	description: string | null = null
	yearFounded: string | null = null
	industry: string[] = []
	sizeRange: string | null = null
	locality: string[] = []
	cultureAndValue: ICultureValueInfo[] = []
	additionalInfo: ICompanyExtraInfo[] = []
	leaders: ILeaderInfo[] = []
	linkedinUrl: string | null = null
	currentEmployeeEstimate = 0;
	totalEmployeeEstimate = 0
	atsProvider: string | null = null
	atsApiKey: string | null = null
	atsCompanyUid: string | null = null
	hq: AnyObject | null = null
	profilePictureUrl: string | null = null
	metadata: AnyObject | null = null
	customSubdomain: string | null = null
	domain: string | null = null
	slug: string | null = null;

}