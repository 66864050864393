// React
import { createContext } from "react"

// Interfaces
import { IAuthContext } from "@auth/interfaces"

// Auth Context
const AuthContext = createContext({} as IAuthContext)

export default AuthContext
