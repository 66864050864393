import { gql } from "graphql-request";

export const createReportRecommendation = gql`
mutation Mutation($positions: [JSON], $recommendationText: String, $section: String, $reason: String, $priorityRank: Int, $companyId: UUID, $userId: UUID, $assessmentAxe: String) {
    createReportRecommendation(positions: $positions, recommendationText: $recommendationText, section: $section, reason: $reason, priorityRank: $priorityRank, CompanyId: $companyId, UserId: $userId, assessmentAxe: $assessmentAxe) {
        id
    }
}`;

export const updateReportRecommendation = gql`
mutation Mutation($reportRecommendationId: UUID!, $assessmentAxe: String, $userId: UUID, $priorityRank: Int, $companyId: UUID, $reason: String, $section: String, $positions: [JSON], $recommendationText: String) {
    updateReportRecommendation(reportRecommendationId: $reportRecommendationId, assessmentAxe: $assessmentAxe, UserId: $userId, priorityRank: $priorityRank, CompanyId: $companyId, reason: $reason, section: $section, positions: $positions, recommendationText: $recommendationText) {
        response
    }
}`;

export const deleteReportRecommendation = gql`
mutation DeleteReportRecommendation($reportRecommendationId: UUID!) {
    deleteReportRecommendation(reportRecommendationId: $reportRecommendationId) {
        response
    }
}
`