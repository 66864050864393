import { useState, useContext } from "react";
import { IconButton } from "@mui/material"

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Modalbox from "@/components/Modalbox";
import Label from "../Label/Label";

import './style.css';
import AppContext from "@/context/AppContext";

const ArrayControlForm: React.FunctionComponent<{ 
    setFieldValue: (fieldName: string, value: any) => any, 
    header: string,
    fieldName: string, 
    values: any[], 
    defaultItemWhenAdded: any,
    addButtonText?: string, 
    Elements: (i: number) => any,
    warningDeleteModal?: boolean;
}> = ({ 
    setFieldValue,
    header,
    fieldName,
    values,
    defaultItemWhenAdded,
    warningDeleteModal = true,
    addButtonText,
    Elements
 }):JSX.Element => {

    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

    const [indexToDelete, setIndexToDelete] = useState<number | undefined>(undefined);

    const { tr } = useContext(AppContext)

    const deleteFromArray = (indexToDelete: number) => {
        setFieldValue(fieldName, values.filter((item, index) => index !== indexToDelete))
        setShowWarningModal(false)
    }

    const handleDeleteButtonClicked = (indexToDelete: number) => {
        if(!warningDeleteModal) return deleteFromArray(indexToDelete);

        setIndexToDelete(indexToDelete)
        setShowWarningModal(true);
    }

    return(
        <div>
            <div className="array-control-header">
                <Label>{header}</Label>

            </div>
            {values?.map((e, i) => (
                <div key={i} className="array-control-inputs">
                    <div className="array-control-inputs-group">
                        {Elements(i)}
                    </div>
                    <div className="array-control-delete-container">
                        <IconButton 
                        onClick={() => handleDeleteButtonClicked(i)}> 
                            <DeleteOutlineIcon/>
                        </IconButton>
                    </div>
                </div>
            ))}
            <span 
            className="array-control-header-add-button"
            onClick={() => setFieldValue(fieldName, [...values ?? [], defaultItemWhenAdded])}>{ addButtonText ??  `+ Add ${header}`}</span>
            {showWarningModal && indexToDelete !== undefined && 
            <Modalbox
            // className={"wizard-new-template-warning-modal"}
            message={tr("Delete item")}
            description={tr("Are you sure you want to delete this item?")}
            cancelText={tr("No")}
            confirmText={tr("Yes")}
            onCancel={() => setShowWarningModal(false)}
            onClose={() => setShowWarningModal(false)}
            onConfirm={() => deleteFromArray(indexToDelete)}/>}
        </div>
    )
}

export default ArrayControlForm;