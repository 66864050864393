import { proxyChatService } from "@/graphql/mutations/proxyChatService";
import { graphQueries } from "@/helpers";
import Cookies from "js-cookie";
import { ICreateChannel, IGetChannelMeesgaes, IJoinToChannel, ILogin, IPostMessage } from "./types";
import env from "react-dotenv";

export class ChatApi {
    public static CHAT_GATEWAY_ENDPOINT = env.CHAT_GATEWAY_ENDPOINT
    public static ENVIRONMENT =  env.ENVIRONMENT ?? "DEV"

    static async loginToChat(userInfo: ILogin): Promise<{ success: boolean, token?: string, userId?: string }> {        
        const { requestToChatServer } = await graphQueries.sendRequest(proxyChatService, {
            requestType: "post",
            requestPath: '/auth/login',
            params: {
                isLoginRequest: true,
                data: userInfo
            }
        });
        const { response } = requestToChatServer;

        return response
    }

    static async createNewChannel(channelInfo: ICreateChannel): Promise<{ success: boolean }> {
        try {
            const { requestToChatServer } = await graphQueries.sendRequest(proxyChatService, {
                requestType: "post",
                requestPath: '/channels',
                params: {
                    data: {
                        ...channelInfo,
                        name: `${ChatApi.ENVIRONMENT}-${channelInfo.name}`
                    },
                    headers: {
                        'x-auth-token': Cookies.get("rc_token"),
                        'x-user-id': Cookies.get("rc_uid"),
                    }
                }
            });
    
            const { response } = requestToChatServer;
    
            return response;
        }catch(error) {
            return { success: false }
        }
    }

    static async getChatMessages(channelMessages: IGetChannelMeesgaes): Promise<{ success: boolean, messages: any[] }> {        
        const { requestToChatServer } = await graphQueries.sendRequest(proxyChatService, {
            requestType: "get",
            requestPath: "/channels/messages",
            params: {
                params: {
                    ...channelMessages,
                    roomName: `${ChatApi.ENVIRONMENT}-${channelMessages.roomName}`
                },
                headers: {
                    'x-auth-token': Cookies.get("rc_token"),
                    'x-user-id': Cookies.get("rc_uid"),
                }
            }
        });

        const { response } = requestToChatServer;

        return response
    }

    static async postMessageToRoom(postMessage: IPostMessage): Promise<{ message: any, success: boolean }> {
        const { requestToChatServer } = await graphQueries.sendRequest(proxyChatService, {
            requestType: "post",
            requestPath: '/messages',
            params: {
                data: {
                    roomName: `${ChatApi.ENVIRONMENT}-${postMessage.roomName}`,
                    msg: postMessage.message
                },
                headers: {
                    'x-auth-token': Cookies.get("rc_token"),
                    'x-user-id': Cookies.get("rc_uid"),
                }
            }
        });

        const { response } = requestToChatServer;

        return response;
    }

    static async joinToChannel(data: IJoinToChannel): Promise<{ success: boolean }> {
        const { requestToChatServer } = await graphQueries.sendRequest(proxyChatService, {
            requestType: "post",
            requestPath: '/channels/join',
            params: {
                data: {
                    ...data, 
                    roomName: `${ChatApi.ENVIRONMENT}-${data.roomName}`
                },
                headers: {
                    'x-auth-token': Cookies.get("rc_token"),
                    'x-user-id': Cookies.get("rc_uid"),
                }
            }
        });

        const { response } = requestToChatServer;
        
        return response;
    }
}