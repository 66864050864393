import { CompanyFormPageAdapter } from "./companyFormPageAdapter";
import { ICompanyFormPage, ICompanyInfo } from "@/models"
import { formModelUtils, ISelectValueRecord } from "@/models/formModelUtils";


export class CompanyIntegrationsPageAdapter extends CompanyFormPageAdapter {
	constructor(page: ICompanyFormPage) {
		super(page, "integrations");
	}

	protected _doUpdateFormPageFromCompany(company: ICompanyInfo): ICompanyFormPage {
		this.setQuestionValue(0, 1, company.atsApiKey as string)
		this.setQuestionValue(2, 0, company.atsCompanyUid)
		this.setAtsProvider(company.atsProvider as string);
		return this.page;
	}

	private setAtsProvider(provider: string): void {
		const values = this.getQuestionField(0, 0, "dataSource") as Array<ISelectValueRecord>
		const finalValues = formModelUtils.setSelectValue(values, provider)
		const ind = finalValues.findIndex(rec => rec.label === provider)
		if (ind >= 0) {
			this.setQuestionValue(0, 0, finalValues[ind])
		}
	}


	createUpdate(): Partial<ICompanyInfo> {
		return {
			atsProvider: formModelUtils.getQuestionStringValue(this.getQuestion(0, 0)),
			atsApiKey: this.getQuestionValue(0, 1) as string,
			atsCompanyUid: this.getQuestionValue(2, 0) as string			
		};
	}
}