import ArrayControlForm from "@/components/WizardComponents/ArrayControlForm/ArrayControlForm";
import TextField from "@/components/WizardComponents/TextField/TextField";
import WizardStepTemplate from "@/components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { IWizardStep } from "../../interfaces";

const TITLE = "What are the candidates responsibilities?";
const DESCRIPTION  = "List the duties required for the position."

const RolesAndResponsibilitiesStep: React.FunctionComponent<IWizardStep> = ({ formik }) => {

    const { values, setFieldValue, handleChange } = formik;
    const { rolesAndResponsibilities } = values;
    
    return (
        <WizardStepTemplate
        title={TITLE}
        description={DESCRIPTION}>
            <ArrayControlForm 
            setFieldValue={setFieldValue} 
            header={"What are the expected Roles & Responsibilities?"} 
            addButtonText={"+ New qualification"}
            fieldName={"rolesAndResponsibilities"} 
            values={rolesAndResponsibilities ?? []} 
            defaultItemWhenAdded={""} 
            Elements={(i) => (
                <>
                    <TextField
                    uiType="white-bordered"
                    style={{ width: "545px" }}
                    // placeholder="Add qualification here" 
                    name={`rolesAndResponsibilities[${i}]`}
                    onChange={handleChange}
                    value={rolesAndResponsibilities?.[i]}/>
                </>
            )}/>

        </WizardStepTemplate>
    )
}

export default RolesAndResponsibilitiesStep;