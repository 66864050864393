
export type AnyObject = { [key: string | number ]: any };
export interface IStringUtils {
	/**
	 * Returns a regex for the  properly escaped input. If the input is falsy, returns
	 * a regex that always succeeds
	 * @param s
	 * @param flags 
	 */
	stringToRegex(s: string | null, flags?: string): RegExp
}

export class StringUtils implements IStringUtils {

	public stringToRegex(s: string | null, flags?: string): RegExp {
		if (!s) {
			return new RegExp("", flags)
		}
		// From MDN
		const escaped = String(s).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
		return new RegExp(escaped)
	}	

}