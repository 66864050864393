// Auth
import { useState, useContext, useEffect } from "react"
import { useAuth } from "@auth"

// Components
// import Icon from "@components/LoginButton/Icon"

// Google Icon
import googleIcon from "@assets/icons/google.svg"

// Miocrosoft Icon
import microsoftIcon from "@assets/icons/microsoft.svg"

// Utils
import cx from "classnames"

// Interfaces
import { LoginButtonProps } from "@interfaces/components"
import AppContext from "@/context/AppContext"

// Local Interfaces
interface IButtonProperties {
  [index: string]: {
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    text: string,
    className: string
  }
}

// Default Handlers
const defaultOnClick: React.MouseEventHandler<HTMLButtonElement> = () => null

// OAuth Styles
const OAuthStyles: string[] = [
  "bg-gray-1000 focus:outline-none focus:ring-1 focus:ring-gray-600",
  "border rounded text-white",
  "flex justify-center items-center",
  "py-3 px-4 mb-3",
  "w-full relative"
]

/**
 * Login Button
 * @description provides basic login buttons types including OAuth ones
 */
const LoginButton: React.FunctionComponent<LoginButtonProps> =
  ({ type = "custom", text, onClick, className, icon }): React.ReactElement => {
    // Auth
    const {
      isLoading,
      provider,
      loginWithGoogle,
      loginWithLinkedin,
      loginWithMicrosoft,
    } = useAuth()

    const { locale, tr } = useContext(AppContext);

    // Data
    const [properties, setProperties] = useState<IButtonProperties | null>(null);

    useEffect(() => {
      setProperties({
        google: {
          text: tr(text || "Continue with Google"),
          onClick: loginWithGoogle,
          className: cx(OAuthStyles, className)
        },

        microsoft: {
          text: tr(text || "Continue with Microsoft"),
          onClick: loginWithMicrosoft,
          className: cx(OAuthStyles, className)
        },

        linkedin: {
          text: tr(text || "Continue with Linkedin"),
          onClick: loginWithLinkedin,
          className: cx(OAuthStyles, className)
        },

        custom: {
          text: tr(text || "Sign in"),
          onClick: onClick || defaultOnClick,
          className: cx([
            "focus:outline-none rounded",
            "bg-black hover:bg-indigo-600 text-white",
            "transition duration-150 ease-in-out",
            "px-8 py-3 mt-6",
            "w-full flex justify-center items-center relative"
          ], className)
        }
      })
    }, [locale]);

    if (!properties) {
      return <></>
    }

    return (
      <button
        type="button"
        aria-label={properties[type].text}
        className={properties[type].className}
        onClick={properties[type].onClick}
      >
        {type === "google" && !icon ? <img src={googleIcon} className="h-5" /> : null}
        {type === "microsoft" && !icon ? <img src={microsoftIcon} className="h-5" /> : null}
        {icon && <img src={icon} className="h-5" />}

        <p className={cx("", { "ml-4": type !== "custom" })}>
          {properties[type].text}
        </p>

        {isLoading && provider === type && <span className="rounded-md shadow-sm ml-3 absolute right-2 align-middle">
          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </span>}

        {/* TODO */}
        {/* <Loading className="inline-block ml-3 absolute right-2" /> */}
      </button>
    )
  }

export default LoginButton
