// Auth
import { useAuth, IAuth } from "@auth"

// Middlewares
import { permissions } from "@permissions"

// Local Interfaces
interface AuthComponentProps {
  permissions: string[]
  children: React.ReactElement
  fallback?: React.ReactElement
}

const AuthComponent: React.FunctionComponent<AuthComponentProps> =
  (props): React.ReactElement => {
    // Auth
    const auth: IAuth = useAuth()

    // If user data still has to load => return a empty fragment
    if (!auth.user) return <></>

    // If there are permissions to check
    if ( props.permissions.length ) {
      // Interates over each middleware key passed
      for ( const name of props.permissions ) {
        // Middleware result
        const permissionResult: boolean = permissions[name](auth)

        if ( !permissionResult ) {
          // Returns fallback Component case
          if ( props.fallback )
            return props.fallback 

          else
            return <></>
        }
      }
    } 

    // Else all good or no permissions to check
    return props.children
  }

export default AuthComponent
