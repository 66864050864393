
/**
 * A promise that can be resolved or rejected programatically
 */
export interface IControlledPromise<T> {
	/**
	 * The actual promise object
	 */
	readonly promise: Promise<T>
	/**
	 * Resolve the promise, if it hasn't been resolved/rejected yet
	 */
	resolve(t: T): void
	/**
	 * Reject the promise, if it hasn't been resolved/rejected yet
	 */
	reject(err: unknown): void
}

export class ControlledPromise<T> implements IControlledPromise<T>{
	
	constructor() {
		// this is executed synchronously, so the values of _resolve and _reject are set by the time the constructor exits
		this.promise = new Promise<T>((resolve, reject) => {
			this._resolve = resolve
			this._reject = reject
		})
	}
	public readonly promise: Promise<T>
	public resolve(t: T): void {
		if (this._resolve !== null) {
			const r = this._resolve
			this._resolve = null
			this._reject = null
			r(t)
		}
		else {
			console.warn("Attempt to resolve resolved controlled promise")
		}
	}
	public reject(err: unknown): void {
		if (this._reject !== null) {
			const r = this._reject
			this._resolve = null
			this._reject = null
			r(err)
		}
		else {
			console.warn("Attempt to reject resolved controlled promise")
		}
	}
	//// Implementation ///
	private _resolve: ((t: T) => unknown) | null = null
	private _reject: ((err: unknown) => unknown) | null = null
}