import { DownloadIcon } from '@/components/Icons';

import { LoadingButton } from '@mui/lab';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import PiesChart from './Parts/PiesChart/PiesChart';
import ContentCard from './Parts/ContentCard/ContentCard';

import './style.css';
import { Dialog } from '@mui/material';
import LinesChart from './Parts/LinesChart/Lines.chart';

const statisticsMockData = {
    "small-cards": [
        {
            name: "Visited Discovery page",
            value: "18,063"
        },
        {
            name: "Applied via CV",
            value: "1803"
        },
        {
            name: "Applied via Simulator",
            value: "5021"
        },
        {
            name: "Complited simulator",
            value: "4586"
        },
        {
            name: "Submitted both (CV+JS)",
            value: "6059"
        }
    ],
    "medium-cards": [
        {
            title: "Canditades by aplication type - flow complition",
            data: [
                {name: "CV only flow", value: 25},
                {name: "CV and work style only", value: 25},
                {name: "CV entire job simulator ", value: 50}
            ]
        },
        {
            title: "Canditades by device type",
            data: [
                {name: "Desktop", value: 50},
                {name: "Mobile", value: 50}
            ]
        },
    ],
    "large-card": [
        {
            name: 'Dec 2021',
            discoveryPage: 4000,
            appliedViaCv: 2400,
            appliedViaSim: 2400,
            both: 1500,
            completedSim: 1200
          },
          {
            name: 'Jan 2022',
            discoveryPage: 4500,
            appliedViaCv: 3400,
            appliedViaSim: 4200,
            both: 2222,
            completedSim: 1900
          },
          {
            name: 'March 2022',
            discoveryPage: 3800,
            appliedViaCv: 400,
            appliedViaSim: 3400,
            both: 2800,
            completedSim: 3000
          },
          {
            name: 'June 2022',
            discoveryPage: 4000,
            appliedViaCv: 2400,
            appliedViaSim: 2400,
            both: 1500,
            completedSim: 1200
          },
          {
            name: 'Aug 2022',
            discoveryPage: 4000,
            appliedViaCv: 2400,
            appliedViaSim: 2400,
            both: 1500,
            completedSim: 1200
          },
    ]

}

const WeeklyReportDialog: React.FunctionComponent<{
    openModal: boolean;
    setModal: (val: boolean) => void;
}> = ({openModal, setModal}): JSX.Element => {

    const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && reason == "backdropClick") 
            setModal(false);
            return;
    }

    return (
        <Dialog
        open={openModal}
        onClose={handleClose}
        sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                backgroundColor: "#F4F4F4",
                padding: "20px 72px",
                width: "100%",
                maxWidth: "85%",  // Set your width here
              },
            },
          }}>
            <div className="weekly-report-page">
                <div className="weekly-report-headers">
                    <h1 className="headers-big">
                        {"Marketing Data Analyst"} 
                        {" | "} 
                        <span>
                            {"Weekly Report"}
                        </span>
                    </h1>
                    <span className="headers-sub">
                        {"Total for chosen date range compared to the previous period"}
                    </span>
                </div>
                <div className='weekly-report-actions'>
                    <button className='actions-date-button'>
                        <CalendarTodayIcon fontSize='small'/>
                        {"April 3 - May 2"}
                        <KeyboardArrowDownIcon/>
                    </button>
                    <LoadingButton
                        className='actions-loading-button'
                        style={{border: 'none'}}
                        color='inherit'
                        loading={false}
                        loadingPosition="start"
                        startIcon={<DownloadIcon color='#000'/>}
                        variant="outlined"
                        onClick={() => console.log("Unimplemented")}/>
                </div>
                <div className='weekly-report-statistics'>
                    <div className='statistics-cards'>
                        {statisticsMockData["small-cards"].map((s, i) => (
                            <ContentCard title={s.name} key={i}>
                                <h1 style={{ marginTop: "20px" }}>{s.value}</h1>
                            </ContentCard>
                        ))}
                    </div>
                    <div className='statistics-cards'>
                        {statisticsMockData["medium-cards"].map((s, i) => (
                                <ContentCard size={"medium"} title={s.title} key={i}>
                                    <div style={{ marginTop: "20px" }}> 
                                        <PiesChart 
                                        data={s.data} 
                                        colors={["#AD8FB5", "#56BBD1", "#F7995E"]}
                                        hieght={119}
                                        width={"65%"}/>
                                    </div>
                                </ContentCard>
                        ))}
                    </div>
                    <div className='statistics-cards'>
                        <ContentCard title={"Canditades Activity"} size="max">
                            <LinesChart 
                            data={statisticsMockData["large-card"]} 
                            colorAndLines={{
                                "#AD8FB5": "discoveryPage",
                                "#F7995E":"appliedViaCv",
                                "#56BBD1":"appliedViaSim",
                                "#AABA6B":"both",
                                "#DC8EBD":"completedSim",
                            }}/>
                        </ContentCard>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default WeeklyReportDialog;