import { useEffect, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import './style.css';

const callBack = (entries: any[], func: () => any) => {
    const [entry] = entries; 

    if (entry.isIntersecting) {
        const id = setTimeout(() => {
            func()
        }, 2000);

        entry.target.start_intersecting_timeout_id = id; 
    }
    else {
        if (entry.target.start_intersecting_timeout_id) {
            clearTimeout(entry.target.start_intersecting_timeout_id); 
            delete entry.target.start_intersecting_timeout_id;
        }
    }
}

const ChatWrapper: React.FunctionComponent<{
    chatContainerId: string;
    chatIframeProps: {
        iframeId: string;
        chatUrl: string;
    },
    chatIsAvailable?: boolean;
    showChat: boolean;
    setShowChat: (show: boolean) => any; 
}> = ({
    chatContainerId, chatIframeProps, children, chatIsAvailable, showChat, setShowChat
}): JSX.Element => {

    const chatRef = useRef(null)

    const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1,
    }

    useEffect(() => {
        if(!chatIsAvailable) 
            return
        
        const observer = new IntersectionObserver((entries) => callBack(entries, () => setShowChat(true)), options);
        if (chatRef.current) {
            observer.observe(chatRef.current);
        }
        return () => {
            if (chatRef.current) {
                observer.unobserve(chatRef.current);
            }
        };
    }, [chatIsAvailable])
    
    return (
        <div
        ref={chatRef}
        id={chatContainerId} className="positions-chat-container">
            {children}
            <InView
            threshold={1}
            // onChange={() => {
            //     timout(() => {
            //         if(chatIsAvailable) {
            //             setShowChat(true);
            //         }
            //     })
            // }}
            >
                {showChat &&     
                <iframe
                title={"chat"}
                className={"custom-chat-iframe-container"}
                id={chatIframeProps.iframeId}
                src={chatIframeProps.chatUrl}/>} 
            </InView>
        </div>
    )
}

export default ChatWrapper;