import { useEffect, useState } from "react";

// Application routes
import { getAllNotifications, getAllNotificationsByCompanyId } from "@/graphql/queries/notifications";

import { graphQueries, isUnboxableUser } from "@/helpers";
import useNotifications from "@/layouts/Layout/hooks/useNotifications";
import { IAuth, useAuth } from "@/auth";

import './style.css';
import NavigationBar from "./parts/NavigationBar";
import useBackgroundColor from "@/layouts/Layout/hooks/useBackgroundColor";
import AddNewCompanyDialog from "@/components/AddNewCompanyDialog";
import { useCompanyDropdown } from "./hooks/useCompanyDropdowm";
import { hrmInPositions } from "@/graphql/queries/hrmInPositions";
import IframeQuizModal from "@/pages/IframeQuizModal";
import { useHistory } from "react-router";
// import ChatRooms from "@/pages/ChatRooms";

const Layout: React.FunctionComponent = ({ children }): JSX.Element => {
    const { company, setCompany, user }: IAuth = useAuth()
	
	const history = useHistory();

    const [isAdmin] = useState<boolean>(isUnboxableUser(user?.email))

	const [notificationQuery] = useState<string>(
		isAdmin ? getAllNotifications : getAllNotificationsByCompanyId)

	const [notificationParams] = useState<{limit: number, companyId?: string}>(
		isAdmin ? {limit: 10} : {limit: 10, companyId: company?.id})

	const [notificationPathToData] = useState<string>(
		isAdmin ? "getAllNotifications/notifications" : "getAllNotificationsByCompanyId/notifications")
	
	const [showQuizButton, setShowQuizButton] = useState<boolean>(false)
	const [showQuizModal, setShowQuizModal] = useState<boolean>(false)
	const [quizPositionId, setQuizPositionId] = useState<string | null>(null)
	const [quizBuildingBlocks, setQuizBuildingBlocks] = useState<string[] | null>(null)

	const { 
		notifications, 
		readedNotifications,
		getUnreadNotificationsCount,
		markAllNotificationsAsRead,
		markNotificationAsRead
	} = useNotifications(notificationQuery, notificationParams, notificationPathToData);
	
	const [openNewCompanyModal, setOpenNewCompanyModal] = useState<boolean>(false)

	const { 
		searchCompanies,
		onCompanySelectChange,
		getInitialCompanies,
		setCompaniesAndOptions,
		companiesOptions
	} = useCompanyDropdown({
		setCurrentCompany: setCompany,
		isUserAdmin: isAdmin,
		currentCompany: company,
		onClickAddNewCompany: () => setOpenNewCompanyModal(true)
	})

	const bgColorToRoute = {
		"#F4F4F4": [
			"/positions", 
			"/positions/talents/:id", 
			"/referrals", 
			"/permissions", 
			"/assessments", 
			"/buildingblocks", 
			"/recommendations",
			"/hr-corner",
			"/my-favorites"
		],
	}
	const { bgColor } = useBackgroundColor("#FFF", bgColorToRoute)

	const checkForHrmQuiz = (userId: string) => {
		graphQueries.sendRequest(hrmInPositions, {
			userId: userId
		})
		.then((data) => {
			
			const { hrmInPositions } = data || {};
			const { normalBlocks, oneTimeCleanBlocks } = hrmInPositions || {};
			
			const isUserHrmAtLeastInOnePosition = Object.keys(normalBlocks || {}).length > 0;
			const isUserHasAtLeastOneOneTimeBlock = oneTimeCleanBlocks?.length > 0;
			if(hrmInPositions &&  (isUserHrmAtLeastInOnePosition || isUserHasAtLeastOneOneTimeBlock)) {
				const positionId = Object.keys(normalBlocks)?.[0]
				const quizBlocks = [...(oneTimeCleanBlocks || []), ...(Object.values(normalBlocks)?.[0] as any|| [])]

				setShowQuizButton(true)
				setQuizPositionId(positionId)
				setQuizBuildingBlocks(quizBlocks)
			}
		})
		.catch(console.error)
	}

	const onCloseQuizModal = () => { 
		if(user && user.id) {
			setShowQuizModal(false)
			setShowQuizButton(false)
			checkForHrmQuiz(user.id)
		}
	}

	useEffect(() => {
		if(user && user.id) {
			checkForHrmQuiz(user.id)
		}
	}, [user])

    return (
        <div className={`layout-custom`} style={{
			backgroundColor: bgColor
		}}>
            <NavigationBar 
                user={user}
				auth={useAuth()}
				history={history}
                setCompany={setCompany}
                notifications={notifications}
                companyId={company?.id}
                getUnreadNotificationsCount={getUnreadNotificationsCount}
                readedNotifications={readedNotifications}
                markAllNotificationsAsRead={markAllNotificationsAsRead}
                markNotificationAsRead={markNotificationAsRead} 
                loadCompanies={searchCompanies} 
                onCompanySelectChange={onCompanySelectChange} 
                getDefaultOptions={companiesOptions as any} 
                getInitialCompany={getInitialCompanies} 
                companies={companiesOptions as any}
				userIsAdmin={isAdmin}
				showQuizButton={showQuizButton}
				onClickQuizButton={() => setShowQuizModal(true)}
				companyName={company?.name ?? ""}/>

				{/* {company?.id && 
				<ChatRooms                 
				buttonProps={{ className: "chat-button-custom" }}
				iframeChatTitle={`Discuss hiring for ${company?.name ?? ""}`}
				tooltipText={`Discuss ${company?.name ?? ""}`}
				roomId={company.id}/>} */}

				{openNewCompanyModal && 
				<AddNewCompanyDialog 
				openModal={openNewCompanyModal} 
				setModal={setOpenNewCompanyModal} 
				onCompanyCreated={setCompaniesAndOptions}/>}

				{quizPositionId && quizBuildingBlocks &&
				<IframeQuizModal 
				open={showQuizModal} 
				onClose={onCloseQuizModal}
				iframeName={"layout-quiz"}
				userId={user?.id}
				positionId={quizPositionId ?? undefined}
				buildingBlocks={quizBuildingBlocks ?? undefined}
				startQuizOnOpen={true}
				/>}
            <main className={`layout-custom-page-container`}>
                {children}
            </main>
        </div>
    )
};

export default Layout;