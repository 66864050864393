// Router
import { useLocation } from "react-router-dom"

// Interfaces
import { IQueryValues } from "@interfaces/routes"

export const useQuery: Function = (): IQueryValues => {
  // Search string from Router
  const { search }: { search: string } = useLocation()

  // Query Params as URLSearchParams instance
  const queryParams: URLSearchParams = new URLSearchParams(search)

  // Initializes Query values
  const queryValues: IQueryValues = {}

  for ( const entry of queryParams.entries() ) {
    queryValues[entry[0]] = entry[1]
  }

  return queryValues
}
