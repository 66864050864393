import React, { useEffect } from "react";

import './style.css';
import { IComponentStepper } from "./interfaces";
import { ProgressBarSection, StepperNavigationButtons, StepperTrackerSection } from "./components";
import { useComponentStepper } from "./useComponentStepper";
import WizardLoading from "../WizardLoading/WizardLoading";

const ComponentStepper: React.FunctionComponent<IComponentStepper> = ({
    isLoading,
    pages,
    onStepChange,
    disableNextStep,
    initialStepIndex,
    showAllBreadcrumps,
}): JSX.Element => {

    const {        
        currentStepIndex,
        currentStep,
        Component,
        pagesCount,
        setStep,
        getPagesFromStartToCurrentStep,
        setPages,
        componentProps
    } = useComponentStepper({
        initialPages: [],
        initialStepIndex: initialStepIndex,
        onStepChange: onStepChange,
        disableNextStep: disableNextStep,
    })

    useEffect(() => {
        if (!isLoading)
            setPages(pages)
    }, [pages, isLoading])
    
    
    const { 
        onlyCurrentStepNameBreadcrumb, 
        hideNavigationButtons, 
        hideProgressBar, 
        hideSkipButton,
        hidePreviousButton } = currentStep || {};

    return (
    <div className="components-stepper-container">
            <WizardLoading isLoading={isLoading}/>
            
            <StepperTrackerSection
            componentProps={componentProps}
            navigateToStep={setStep}
            currentStepIndex={onlyCurrentStepNameBreadcrumb ? 0 : currentStepIndex}
            pagesFromStartToCurrent={ onlyCurrentStepNameBreadcrumb ? [currentStep] : 
            showAllBreadcrumps ? 
            pages : getPagesFromStartToCurrentStep()
            }/>
            
            <div className="component-stepper-content-navigation-container">
                <div className="components-stepper-content-container">
                    {Component}
                </div>

                <div 
                className="components-stepper-navigation-footer">
                    {hideProgressBar ?
                    <div></div> :
                    <ProgressBarSection 
                    currentStep={currentStepIndex+1}
                    stepsCount={pagesCount}/>}
                    
                    {hideNavigationButtons ? 
                    <div> </div> :
                    <StepperNavigationButtons
                    onPrevBtnClicked={() => setStep(currentStepIndex - 1)}
                    onNextBtnClicked={() => setStep(currentStepIndex + 1)}
                    onSkipBtnClicked={() => setStep(currentStepIndex + 1)}
                    hidePrevButton={hidePreviousButton}
                    customNextBtnText={currentStep?.nextButtonText}
                    hideSkipButton={hideSkipButton}/>}
                </div>
            </div>
        </div> )
}

export default ComponentStepper;