/**
 *
 *  Resume Create Company
 *
 *  @module pages/CreateCompany/steps/ResumeCreateCompany
 *
 *  @author diegoulloao
 *
 */

import { RouteComponentProps } from "react-router-dom"
// Router
import { useHistory } from "react-router-dom"

// Components
import { Button, LNRDLoading } from "@components"

// Logic
//import useResumeCreateCompany
//from "@pages/CreateCompany/steps/ResumeCreateCompany/useResumeCreateCompany"

// Helpers
import { copyToClipboard, graphQueries } from "@helpers"

// Utilities
//import { Player } from "@lottiefiles/react-lottie-player"

//// Assets
//import generating from "@assets/animations/generating.json"

// Interfaces
//import { useState } from "react"
import LNRDImage, { ILNRDImageProps } from "@/components/LNRDImage"
import { ICompanyInfo } from "@/models"
import { StaticContext } from "react-router"
import { useEffect, useState } from "react"
import useLocalToast from "../../../../hooks/useLocalizedToast";

interface IResumeCompanyState {
	companyId: string
}
/**
 *
 *  Resume: Create Company
 *
 *  @description shows a create company resume of all the process
 *  this component is only mounted when data collected from onboarding process
 *  need to be saved.
 *
 */
const ResumeCreateCompany: React.FunctionComponent<RouteComponentProps<{}, StaticContext, IResumeCompanyState>> =
	({ location }): JSX.Element => {
		// History
		const history = useHistory()
		const companyId = location.state?.companyId

		if (!companyId) {
			console.error("Resume create company invoked with no company info")
			history.push("/")
			return (<></>)
		}

		const [companyInfo, setCompanyInfo] = useState<ICompanyInfo | null>(null)
		const [error, setError] = useState<string>("")
		const { toast } = useLocalToast();

		useEffect(() => {
			let subscribed = true
			if (!companyInfo) {
				(async () => {
					if (!subscribed) {
						return
					}
					try {
						const response = await graphQueries.getCompanyById(companyId)
						if (!subscribed) {
							return
						}
						if (response.data) {
							setError("")
							setCompanyInfo(response.data)
						}
						else {
							setError(`Error: ${response.error || "company not found"}`)
						}
					}
					catch (e) {
						setError(`company not found: ${e}`)
					}

				})()
			}
			return () => { subscribed = false }
		}, [])

		useEffect(() => {
			if (error) {
				toast(`Failed to fetch company information: ${error}`.slice(0, 150), {
					toastId: "resume-create-company-error",
					autoClose: 5000,
					containerId: "default"
				})
			}
		}, [error])


		return (
			<div className="create-company finish mt-12 text-gray-600">
				{(() => {
					// If creating company, show loading (lottie)
					//if ( isCreatingCompany ) {
					//  return (
					//    <div className="text-center">
					//      <Player
					//        className="h-64"
					//        autoplay
					//        loop
					//        src={generating}
					//        style={{ width: 'auto' }}
					//      />

					//      <h2>Building Job Simulator. Please wait ...</h2>
					//    </div>
					//  )
					//}

					// If error, show message to user
					//if ( error ) {
					//  return (
					//    <span className="error">Error</span>
					//  )
					//}
					//const data = contentModel;
					if (error) {
						return (
							<span className="error">Error</span>
						)
					}

					if (!companyInfo) {
						return (
							<LNRDLoading />
						)
					}
					const url = `https://jobsimulator.vercel.app/apple/${companyInfo.id}`

					const imageProps: ILNRDImageProps = {
						// huge image url: "https://www.paunchstevenson.com/wp-content/uploads/2018/07/cropped-outer-space-header.jpg", //
						source: companyInfo.profilePictureUrl || "",
						title: companyInfo.name || "",
						showError: true,
						showLoading: true,
						showEmpty: false

					}

					return (
						<div className="create-company success">
							{/* Company Title */}

							<div className="flex max-h-16 h-16" style={{alignItems: "baseline", gap: "10px"}}>
								<LNRDImage {...imageProps} />
								<h2 className="text-4xl font-light">{companyInfo.name}</h2>
							</div>

							{/* Company Info */}
							<p className="mt-6">{companyInfo.description}</p>

							{/* Company Links */}
							<div className="mt-12 pl-4 border-l-4 border-green-500">
								{/* Description */}
								<p className="text-2xl mb-4">A Job Simulator is available on the link below.</p>

								{/* Job Simulator Link */}
								<a href={`${url}`} target="_blank" className="text-3xl no-underline">
									{url}
								</a>

								{/* Copy Link Button */}
								<div className="mt-8">
									<Button
										text="Copy link"
										type="light"
										size="sm"
										onClick={() => copyToClipboard(`${url}`)}
									/>
								</div>
							</div>

							<div className="mt-12">
								<Button
									text="Create a new position"
									size="sm"
									type="light" onClick={() => history.push("/positions/create/stage")}
								/>
							</div>
						</div>
					)
				})()}
			</div>
		)
	}

export default ResumeCreateCompany
