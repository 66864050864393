// import env from 'react-dotenv'

export const CILENT_ORIGIN = window.location.origin

export const EditorApiHeaders = { 'Content-Type': 'application/json' }

export enum AssessmentStatus {
    DRAFT = "draft",
    READY = "ready",
    PENDING_REVIEW = "pending_review"
}

export enum SkillEvalStatus {
    DRAFT = "draft",
    READY = "ready"  
}

export enum EvalTypes {
    ELIGIBILITY = 'eligibility', 
    EVALUATOR = 'evaluator', 
    GENERAL = 'general'
}

export enum CommonCols {
    PROFICIENCY = 'Seniority/ Proficiency  Level',
    NECESSITY = 'Necessity Level'
}

export enum EditorTables {
    ASSESSMENTS = 'assessments',
    SKILL_EVALS = 'skillevals',
    REPORT_MEANINGS = 'report_meanings',
    COMMON = 'common',
    SKILLS = 'skills',
    POSITIONS = 'positions',
    BUILDING_BLOCKS = 'buildingblocksmetadata'
}

export const GQL_EDITOR = `
mutation RequestToEditorMutation(
    $requestToEditorRequestType: String, 
    $requestToEditorRequestPath: String, 
    $requestToEditorParams: JSON) {

    requestToEditor(
        requestType: $requestToEditorRequestType,
        requestPath: $requestToEditorRequestPath,
        params: $requestToEditorParams) {

    response

    }
}
`