
const recommendationSections = {
    'cv_qualifications': 'Understanding CV & qualifications',
    'contact_candidate': 'Before contacting the candidate',
    'prepare_interview': 'Preparing for the interview',
    'hiring': 'Considering hiring the candidate?',
    'after_hiring': 'If the candidate was hired',
    'not_fit': 'If the candidate is not a fit',
    'good_to_know': 'Good to know',
};
const recommendationReasons = {
    "": "No specific reason",
    "low_verification": "Open questions score is low",
    // "low_skipped_block": "Low Skipped Block",
    "low_skipped_questions": "The candidate has skipped crucial questions",
    "low_bad_answers": "The candidate answered incorrectly"
};
const recommendationAxes = {
    "": "No specific topic",
    "programming_capabilities": "Programming capabilities",
    "analytical_thinking": "Analytical thinking",
    "prioritizing_skills": "Prioritizing Skills",
    "self_learning": "Self learning",
    "learning_motivation": "Learning motivation",
    "project_management_capabilities": "Project management capabilities",
    "data_driven": "Data-driven",
    "effective_communication": "Effective Communication",
    "domain_knowledge": "Domain knowledge",
    "product_management_methodologies": "Product Management methodologies",
    "information_technology": "Information technology",
    "problem_solving": "Problem solving",
    "service_oriented": "Service oriented",
    "collaboration": "Collaboration",
    "design_fundamentals": "Design fundamentals",
    "design_sense": "Design sense",
    "attention_to_detail": "Attention to detail",
    "research": "Research",
    "independence": "Independence",
    "methodologic": "Methodologic",
    "test_methodologies": "Test methodologies",
    "planning": "Planning",
    "organization_and_structure": "Organization and structure",
    "relationship_management": "Relationship Management",
    "hr_methodologies": "HR methodologies",
    "strategic_thinking": "Strategic thinking",
    "talent_acquisition_management": "Talent Acquisition Management",
    "campaign_management": "Campaign Management",
    "proactiveness": "Proactiveness",
    "persuasiveness": "Persuasiveness",
    "diplomacy": "Diplomacy",
    "negotiation_conflict_management": "Negotiation & conflict management",
    "writing_capabilities": "Writing capabilities",
    "marketing_research": "Marketing Research",
    "business_acumen": "Business Acumen",
    "curiosity": "Curiosity",
    "sales_management": "Sales management",
    "self_management": "Self-management",
    "staying_up_to_date": "Staying up-to-date",
    "self_efficacy": "Self efficacy",
    "agreeableness": "Agreeableness",
    "work_drive": "Work Drive",
    "emergent_leadership": "Emergent Leadership"
};

export {
    recommendationAxes,
    recommendationReasons,
    recommendationSections
}