     
import { IUser } from "@/auth/interfaces";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import env from "react-dotenv";
import { ChatApi } from "./data/chat.api";

const CHAT_API_ORIGIN = env.CHAT_API_ORIGIN;
const IFRAME_ID = "chat-iframe";

interface IChatRooms {
    user: IUser | null;
    initialChatRoom: string;
}

interface ChatRooms {
    iframeId: string;
    currentChatUrl: string;
    isChatAvailable: boolean;
}

const useChatRooms = ({
    user, initialChatRoom
}: IChatRooms): ChatRooms => {

    const iframeRef = document.getElementById(IFRAME_ID) as HTMLIFrameElement;

    const [currentChatUrl, setCurrentChatUrl] = useState<string>(`${CHAT_API_ORIGIN}/channel/${initialChatRoom}/?layout=embedded`);
    
    const [isAuthDetailsExist, setIsAuthDetailsExist] = useState<boolean>(checkIfTokenAndUserIdExist());
    const [isIframeInitialize, setIsIframeInitialize] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isChatAvailable, setIsChatAvailable] = useState<boolean>(false);

    const { firstName, lastName, email, userImage } = user as any || {};

    useEffect(() => {
        if(iframeRef && user && initialChatRoom && !isChatAvailable) {
            window.addEventListener('message', handleRocketMessage);
            return () => {
                window.removeEventListener("message", handleRocketMessage);
            };
        }
    }, [iframeRef,user, initialChatRoom, isChatAvailable])

    const handleRocketMessage = (e: MessageEvent<any>) => {

        const isIframeStart = (e.data.eventName === 'startup' && e.data.data) || isIframeInitialize ? true : false;
        const needToLogin = isIframeStart && (!isAuthDetailsExist || e.data.eventName === "Custom_Script_Logged_Out") && !isLoggedIn;
        const needOnlyIframeLogin = isIframeStart && isAuthDetailsExist && !isLoggedIn;
        const loggedInSuccessfully = e.data.eventName === "Custom_Script_Logged_In" && (isAuthDetailsExist || checkIfTokenAndUserIdExist());
        const needToActivateChat = e.data.eventName ==='room-opened';
        

        if(isIframeStart) {
            setIsIframeInitialize(isIframeStart)
        }

        if(needOnlyIframeLogin) {
            iframeChatLogin(Cookies.get("rc_token")!)
        }

        if(needToLogin) {
            loginAndSetCookies(firstName!, `${firstName} ${lastName}`, email!, userImage)
            .then((token) => {
                if(!token) return;

                iframeChatLogin(token)
                setIsAuthDetailsExist(true)
            })
        }

        if(loggedInSuccessfully) {
            setIsLoggedIn(loggedInSuccessfully)
            setChatRoom(initialChatRoom)
        }

        if(needToActivateChat) {
            setIsChatAvailable(true);
        }
    }

    function iframeChatLogin(token: string): void {
        iframeRef.contentWindow?.postMessage({
            externalCommand: 'login-with-token',
            token: token
        }, CHAT_API_ORIGIN)
    }

    async function loginAndSetCookies(username: string, name: string , email: string, profileImage?: string): Promise<string | undefined> {
        const { token, userId, success } = await ChatApi.loginToChat({ username, name, email, profileImage });

        if(!token || !userId || !success) return undefined;
        
        Cookies.set("rc_token", token);
        Cookies.set("rc_uid", userId);  

        return token;
    }   

    function setChatRoom(roomname: string) {
        const chatPath = `channel/${env.ENVIRONMENT}-${roomname}/?layout=embedded`
        ChatApi.createNewChannel({
            name: roomname,
            members: [""]
        })
        .finally(() => {

            ChatApi.joinToChannel({ roomName: roomname })
            .finally(() => {
                setCurrentChatUrl(`${CHAT_API_ORIGIN}/${chatPath}`)
                iframeRef.contentWindow?.postMessage({
                    externalCommand: 'go',
                    path: chatPath
                }, CHAT_API_ORIGIN)    
            })

        })
    }

    function checkIfTokenAndUserIdExist(): boolean {
        const token = Cookies.get("rc_token");
        const userId = Cookies.get("rc_uid");

        return token && userId ? true : false;
    }
    
    return {
        iframeId: IFRAME_ID,
        currentChatUrl,
        isChatAvailable
    }
}

export default useChatRooms;