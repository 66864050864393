import { ICompanyFormPage, ICompanyInfo } from "@/models";
import { ICultureValueInfo } from "@/models/companyInfo";
import { formModelUtils } from "@/models/formModelUtils";
import { IDisplayQuestion } from "@/models/question";
import { CompanyFormPageAdapter } from "./companyFormPageAdapter";

function isValidCultureInfo(info: ICultureValueInfo | null): boolean {
	if (!info) {
		return false;
	}
	return Object.values(info).some(val => Boolean(val))
}

function getFullValueField(q: IDisplayQuestion): string {
	if (!q?.value) {
		return ""
	}
	return formModelUtils.appendTitleAndValue(q.title, formModelUtils.getQuestionStringValue(q))
}
export class CompanyValuesPageAdapter extends CompanyFormPageAdapter {
	constructor(page: ICompanyFormPage) {
		super(page, "values");
	}

	protected _doUpdateFormPageFromCompany(company: ICompanyInfo): ICompanyFormPage {
		const questions = this.page.questions;
		if (!questions) {
			return this.page
		}

		company.cultureAndValue?.forEach((rec, index) => {
			questions[index] = this.createQuestions(0, [{
				title: "@value",
				value: rec.text,
				type: "input",
				editable: false
			}]
			)
		})
		return this.page;
	}



	createUpdate(): Partial<ICompanyInfo> {
		const allValues = this.allQuestionGroups()
			.map(list => this.collectValues(list))
			.filter(isValidCultureInfo)

		return { cultureAndValue: allValues as ICultureValueInfo[] }
	}

	/////////// Implementation ///////////


	private collectValues(list: IDisplayQuestion[]): ICultureValueInfo | null {
		if (!list || !list.length) {
			return null
		}
		if (list.length === 1) {
			return { text: getFullValueField(list[0]) }
		}
		else {
			return {
				text: list.map(q => getFullValueField(q)).filter(Boolean).join(' ')
			}
		}
	}

}