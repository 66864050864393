import { useContext, useEffect, useState } from "react";
import MaterialTable from "@/components/MaterialTable";
import { capitalizeFirstLetter, getCandidateName } from "@/helpers";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { BuildingBlock } from "../BuildingBlocks/BuildingBlockEditor";
import { comProfileGeneretor, rankToRelevancyPerChannel, relevancyLabel, relevancyPerChannelToRank } from "./helpers";
import { ICandidate, ProceedStatus } from "./interfaces";
import AutocompleteCustom from "@/components/WizardComponents/AutocompleteNew/AutocompleteNew";
import { LNRDLoading, UnboxableButton } from "@/components";
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import './style.css';
import { Menu, } from "@mui/material";
import { useFavoriteCandidates } from "../MyFavorites/hooks/useFavoriteCandidates";
import { BsX } from "react-icons/bs";
import useLocalToast from "../../hooks/useLocalizedToast";
import AppContext from "@/context/AppContext";

const AddToFavoritesButton: React.FunctionComponent<{
    onFavoritesModalOpen?: (args: { setSelectedUsers: React.Dispatch<React.SetStateAction<{ label: string; value: string; }[]>> }) => any;
    isFetchCompanyUsersOptions?: boolean;
    companyUserOptions?: { label: string, value: string }[];
    fetchCompanyUsers: () => Promise<void>;
    selectedFavoredUsers?: { label: string, value: string }[];
    isSelected?: boolean;
    onSaveClicked: (selected: { label: string, value: string }[]) => any;
    isLoadingFavorites?: boolean;
}> = ({
    onFavoritesModalOpen,
    fetchCompanyUsers,
    isFetchCompanyUsersOptions,
    companyUserOptions,
    selectedFavoredUsers,
    isSelected,
    onSaveClicked,
    isLoadingFavorites
}): JSX.Element => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);

        const [selectedUsers, setSelectedUsers] = useState<{ label: string, value: string }[]>(selectedFavoredUsers ?? []);

        useEffect(() => {
            if (selectedFavoredUsers?.length) {
                setSelectedUsers(selectedFavoredUsers ?? [])
            }
        }, [selectedFavoredUsers])

        useEffect(() => {
            if (!companyUserOptions || !companyUserOptions.length) {
                fetchCompanyUsers()
            }
        }, [])

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            const anchor = event.currentTarget;
            setAnchorEl(anchor);
            if (anchor) {
                setSelectedUsers(selectedFavoredUsers ?? [])
                onFavoritesModalOpen?.({
                    setSelectedUsers
                })
            }
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <div
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e: any) => handleClick(e)}
                    style={{ cursor: 'pointer' }}
                >
                    {isSelected ?
                        <PushPinIcon sx={{ cursor: "pointer" }} /> :
                        <PushPinOutlinedIcon sx={{ cursor: "pointer" }} />}
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    className="table-items-menu"
                >
                    {isLoadingFavorites ?
                        <LNRDLoading className="candiates-add-favorite-modal-loading" />
                        : <div className="candiates-add-favorite-modal">
                            <BsX
                                onClick={handleClose}
                                style={{ position: "absolute", right: "10px", top: "20px", cursor: "pointer" }} />
                            <p
                                className="candiates-add-favorite-modal-title">Add candidate to favorite?</p>
                            <AutocompleteCustom
                                autocompleteStyle={{
                                    "& .MuiOutlinedInput-root": { flexFlow: "row", alignItems: "center" },
                                    "& .MuiFormControl-root": { height: "100%" }
                                }}
                                onChange={(e, v) => setSelectedUsers(v)}
                                chipStyle={{
                                    padding: "2px 10px",
                                    lineHeight: "10px",
                                    backgroundColor: "#F7995E",
                                    borderColor: "#F7995E"
                                }}
                                value={selectedUsers}
                                loading={isFetchCompanyUsersOptions}
                                className="candiates-add-favorite-modal-autocomplete"
                                options={companyUserOptions ?? []}
                                placeholder={"Add users"}
                                getOptionLabel={(o) => o.label} />
                            <UnboxableButton
                                onClick={() => onSaveClicked(selectedUsers)}
                                className="candiates-add-favorite-modal-button"
                                btnStyle={"black" as any}>
                                Save
                            </UnboxableButton>
                        </div>}
                </Menu>
            </div>
        )
    }

export const proceedStatuses: Partial<Record<ProceedStatus, string>> = {
    [ProceedStatus.go]: 'Proceed',
    [ProceedStatus.notGo]: 'Reject',
};

export const TalentsTable: React.FunctionComponent<{
    candiadtes: ICandidate[],
    setCandidates: React.Dispatch<React.SetStateAction<ICandidate[]>>,
    isLoading: boolean,
    buildingBlocks: BuildingBlock[],
    positionBlockTypes: string[],
    isFavoritesPage?: boolean;
}> = ({
    isLoading, candiadtes, buildingBlocks, positionBlockTypes, isFavoritesPage
}): JSX.Element => {

        const {
            companyUsers,
            fetchCompanyUsers,
            isFetchCompanyUsers,
            currentUser,
            getFavoriteByUserId,
            getFavoriteByCandidateId,
            createFavoriteCandidate,
            deleteFavoriteCandidate
        } = useFavoriteCandidates();

        const notQuestionBlocks = ["cv", "vid", "comp"];
        const questionBlocks = buildingBlocks.filter(bb => !notQuestionBlocks.includes(bb.id!) && positionBlockTypes.includes(bb.id!));

        const [candidateUsers, setCandidateUsers] = useState<{ [candidateId: string]: { value: string; label: string }[] }>({});
        const [isLoadingFavorites, setIsLoadingFavorites] = useState<boolean>(true);

        const [isLaodingSpecificFavorites, setIsLaodingSpecificFavorites] = useState<boolean>(false);
        const { toast } = useLocalToast();

        useEffect(() => {
            setIsLoadingFavorites(true);
            getFavoriteByUserId(currentUser.value)
                .then((res) => {
                    const toSet: { [candidateId: string]: { value: string; label: string }[] } = {};
                    res.forEach(c => {
                        toSet[c.id] = [{ ...currentUser, label: `Me (${currentUser.label})` }]
                    })
                    setCandidateUsers(toSet);
                })
                .finally(() => setIsLoadingFavorites(false))
        }, [])

        const onOpenFavoriteModal = async (setSelectedUsers: React.Dispatch<React.SetStateAction<{ label: string; value: string; }[]>>, candidate: ICandidate) => {
            setIsLaodingSpecificFavorites(true);
            const favorites = await getFavoriteByCandidateId(candidate.id).catch(console.error);
            const needToAddCurrentUser = !favorites || !favorites.length;
            if (!needToAddCurrentUser) {
                setCandidateUsers(prev => ({
                    ...prev,
                    [candidate.id]: favorites.map(u => ({
                        label: u.id === currentUser.value ? `Me (${u.firstName})` : u.firstName,
                        value: u.id
                    }))
                }));
            }
            else {
                setSelectedUsers([{ ...currentUser, label: `Me (${currentUser.label})` }]);
            }
            setIsLaodingSpecificFavorites(false)
        }

        const onSaveFavoritesClicked = (selected: { label: string; value: string; }[], candidateId: string) => {
            const prevSelected = candidateUsers[candidateId];
            const candidateToAdd = selected?.filter(s => !prevSelected?.find(ps => ps.value === s.value));
            const candidateToDelete = prevSelected?.filter(s => !selected?.find(ps => ps.value === s.value));

            const removeFromFavoritePromises = candidateToDelete?.map(s => deleteFavoriteCandidate(candidateId, s.value))
            if (removeFromFavoritePromises?.length) {
                Promise.all(removeFromFavoritePromises)
                    .then(() => toast("Favorites updated successfully!", { containerId: "default", type: "success" }))
            }

            const addToFavoritePromises = candidateToAdd?.map(s => createFavoriteCandidate(candidateId, s.value, currentUser.label))
            if (addToFavoritePromises?.length) {
                Promise.all(addToFavoritePromises)
                    .then(() => toast("Favorites updated successfully!", { containerId: "default", type: "success" }))
            }

            if (addToFavoritePromises?.length || removeFromFavoritePromises?.length) {
                setCandidateUsers(prev => ({
                    ...prev,
                    [candidateId]: selected
                }))
            }
        }

        const { locale, tr} = useContext(AppContext);

        const [columns, setColumns] = useState<GridColDef[]>([]);

        useEffect(() => {
            setColumns([
                {
                    field: 'favorites',
                    headerName: '',
                    width: 20,
                    align: 'right',
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (p) => (
                        <AddToFavoritesButton
                            onSaveClicked={(selected) => onSaveFavoritesClicked(selected, p.row.id)}
                            selectedFavoredUsers={candidateUsers?.[p.row.id] ?? []}
                            isFetchCompanyUsersOptions={isFetchCompanyUsers}
                            isSelected={candidateUsers?.[p.row.id]?.find(u => u.value === currentUser.value) ? true : false}
                            companyUserOptions={companyUsers}
                            fetchCompanyUsers={fetchCompanyUsers}
                            onFavoritesModalOpen={({ setSelectedUsers }) => onOpenFavoriteModal(setSelectedUsers, p.row)}
                            isLoadingFavorites={isLaodingSpecificFavorites} />
                    )
                },
                {
                    field: 'firstName',
                    headerName: tr('Name'),
                    // flex: 1, 
                    minWidth: 136,
                    renderCell: (params: any) => (
                        <div className="flex items-center">
                            <div>
                                <Link
                                    className="no-underline text-gray-700 hover:text-indigo-600"
                                    to={{
                                        pathname: `/review/${params.row.V2PositionId}/${params.row.id}`,
                                        state: { positionName: params.row.v2PositionTitle }
                                    }}
                                >
                                    <p className="font-medium">{getCandidateName(params.row.firstName, params.row.lastName)}</p>
                                </Link>
                            </div>
                        </div>
                    ),
                    flex: 1,
                },
                {
                    field: 'lastName',
                    hide: true
                },
                // { 
                //     field: 'totalScore', 
                //     headerName: 'Total Rank',
                //     minWidth: 110,
                //     // flex: 1,
                //     // maxWidth: 200,
                //     renderCell: (params) => (
                //         <span>{params.row.totalScore && params.row.totalScore >= 0 ? Math.floor(params.row.totalScore * 100) : 'No Rank'}</span>
                //     ),
                //     flex: 1,

                // },
                {
                    field: 'compatibilityChannel',
                    headerName: tr("Compatibility"),
                    renderCell: ({ row }) => (
                        <span>
                            {rankToRelevancyPerChannel?.[row.compatibilityChannel] ?? "N/A"}
                        </span>
                    ),
                    minWidth: 110,
                    flex: 1,

                },
                {
                    field: 'qualificationChannel',
                    headerName: tr("Qualifications"),
                    renderCell: ({ row }) => (
                        <span>
                            {rankToRelevancyPerChannel?.[row.qualificationChannel] ?? "N/A"}
                        </span>
                    ),
                    minWidth: 110,
                    flex: 1,
                },
                {
                    field: 'skillfullnessChannel',
                    headerName: tr("Skillfullness"),
                    renderCell: ({ row }) => (
                        <span>
                            {rankToRelevancyPerChannel?.[row.skillfullnessChannel] ?? "N/A"}
                        </span>
                    ),
                    minWidth: 136,
                    flex: 1,
                },
                // {
                //     field: 'comProfile',
                //     headerName: 'Communication Style',
                //     minWidth: 136,
                //     flex: 1,
                //     maxWidth: 200,
                //     renderCell: (params) => (
                //         <span>{params?.row?.comProfile && params?.row?.isSharingProfile ? comProfileGeneretor[params.row.comProfile] : 'N/A'}</span>
                //     )
                // },
                {
                    field: 'finalProcessedProfile',
                    headerName: tr('Experience'),
                    minWidth: 90,
                    flex: 1,
                    // maxWidth: 100,
                    renderCell: (params) => (
                        <span>{params?.row?.finalProcessedProfile?.monthsOfWorkExperience?.content ?? 'N/A'}</span>
                    )
                },
                ...(isFavoritesPage ? [{
                    field: 'favoredBy',
                    renderCell: (p: any) => p.row.favoredBy === currentUser.label ? "You" : p.row.favoredBy
                }] : []),
                {
                    field: 'createdAt',
                    headerName: tr('Submitted'),
                    type: 'date',
                    minWidth: 80,
                    flex: 1,
                    valueFormatter: (params: any) => {
                        // first converts to JS Date, then to locale option through date-fns
                        return new Date(params?.value)
                    },
                    // valueGetter for filtering
                    valueGetter: (params) => {
                        return new Date(params?.value)
                    },
                    renderCell: (params: any) => {

                        return (
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div>{params.value != "Invalid Date" ? format(new Date(params.value), "LLL d, yy") : tr("Invalid Date")}</div>
                                {/* <div className="text-gray-400 ml-1">{params.value != "Invalid Date" ? format(new Date(params.value), "hh:mm aa") : "Invalid Date"}</div> */}
                            </div>
                        )
                    }
                },
                {
                    field: "relevancy",
                    headerName: tr("Recommended"),
                    disableColumnMenu: true,
                    align: "center",
                    minWidth: 135,
                    renderCell: (params) => relevancyLabel?.[params?.row?.relevancy] ?? tr("Less Relevant")
                },
                {
                    field: "status",
                    headerName: tr("Status"),
                    disableColumnMenu: true,
                    align: "center",
                    minWidth: 135,
                    renderCell: ({ row }) => proceedStatuses[row.status as ProceedStatus] ?? tr('Pending')
                }
            ])
        }, [locale]);
        return (
            <>
                <MaterialTable
                    columns={columns}
                    loading={isLoading || isLoadingFavorites}
                    numberFilterPlaceholder={tr("By simulation")}
                    emptyStateTitle={tr(
                        candiadtes && candiadtes.length ?
                            "We couldn't find any matching candidates" :
                            "There are currently no talents for this position")}
                    originalData={candiadtes}
                    searchOnColumns={['firstName', 'lastName', 'comProfile']}
                    enumFilters={[
                        { field: "compatibilityChannel", displayName: tr("Compatibility"), placeholder: tr("By compatibility"), options: Object.values(relevancyPerChannelToRank).map(r => ({ value: r, label: rankToRelevancyPerChannel[r as any] })) as any, valueMapper: rankToRelevancyPerChannel },
                        { field: "qualificationChannel", displayName: tr("Qualification"), placeholder: tr("By qualification"), options: Object.values(relevancyPerChannelToRank).map(r => ({ value: r, label: rankToRelevancyPerChannel[r as any] })) as any, valueMapper: rankToRelevancyPerChannel },
                        { field: "skillfullnessChannel", displayName: tr("Skillfullness"), placeholder: "By skillfullness", options: Object.values(relevancyPerChannelToRank).map(r => ({ value: r, label: rankToRelevancyPerChannel[r as any] })) as any, valueMapper: rankToRelevancyPerChannel },
                        { field: "comProfile", displayName: tr("Communication style"), placeholder: tr("By communication style"), options: Object.entries(comProfileGeneretor).map(([value, label]) => ({ value, label })) as any },
                        { field: "relevancy", displayName: tr("Relevance"), placeholder: tr("By recommendation"), options: Object.keys(relevancyLabel).map((k) => ({ value: k, label: capitalizeFirstLetter(k) })) },
                        ...questionBlocks.map(bb => ({
                            field: bb.id ?? "",
                            displayName: bb.name ?? "",
                            placeholder: `By ${bb.name}`,
                            options: [],
                            type: "number" as 'number',
                            pathToField: "score"
                        }))
                    ]}
                />
            </>
        )
    } 