// React
import React, { useContext } from "react"

// Router
import { useHistory } from "react-router-dom"

// Auth Provider
import AuthProvider from "@auth/provider"

// Auth Context
import AuthContext from "@auth/context"

// Axios
import { AxiosRequestConfig } from "axios"

// Interfaces
import { IAuthContext, IAuthContextStates, ICompany, IUser } from "@auth/interfaces"
import TagManager from "react-gtm-module"

import Cookies from "js-cookie"

// Local Interfaces
interface IAuth extends IAuthContextStates {
  readonly setCompany: React.Dispatch<React.SetStateAction<ICompany | null> | ICompany | null>
  readonly setUser: React.Dispatch<React.SetStateAction<IUser | null> | IUser | null>
  /**
   * Updates the current user with info from the user info cookie
   */
  readonly updateUserFromToken: (userToken: string | null) => IUser | null
  readonly login: () => null,
  readonly loginWithGoogle: () => void,
  readonly loginWithLinkedin: () => void,
  readonly loginWithMicrosoft: () => void,
  readonly logout: () => void,
  readonly axiosAuth: AxiosRequestConfig
  readonly setChooseSiteModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  readonly isChooseSiteModalVisible: boolean
}

// Return to URL
// const returnTo: string = Cookies?.get("returnTo") ? encodeURIComponent(`${window.location.protocol}//${window.location.host}`) : encodeURIComponent(`${window.location.protocol}//${window.location.host}`)



// Endpoint

/**
 * useAuth custom hook
 * @description provides auth management for all components
 *
 */
const useAuth = (): IAuth => {
  // Router
  const history = useHistory()

  // Auth Global Context
  const {
    user,
    setUser,

    token,
    //setToken,

    company,
    setCompany,

    provider,
    setProvider,

    //refreshToken,
    //setRefreshToken,
    //setIsAuthenticated,

    isChooseSiteModalVisible,
    setChooseSiteModalVisible,

    isLoading,
    setIsLoading,

    setError,
    error,

    isAuthenticated,
    updateUserFromToken,

	logUserOut: logout,

	settings
  }: IAuthContext = useContext(AuthContext)

  return {
    // States
    user,
    token,
    company,
    provider,
    isLoading,
    isAuthenticated,
    error,
    isChooseSiteModalVisible,
	settings,

    // Set states
    setCompany,
    setUser,
    setChooseSiteModalVisible,
    updateUserFromToken,

    // Functions
    login: () => null,

    // Login with Google
    loginWithGoogle: async () => {
      // Set Google as provider
      setProvider("google")

      // Change state as loading
      setIsLoading(true)

      //report clicked on login with google
      TagManager.dataLayer({
        dataLayer: {
          event: 'GA_Event',
          event_action: "login_button_click",
          event_category: "workspace_activities",
          event_label: "google",
        },
        });

      try {
        // Redirect to Google Login
        let whereToGo;
        if (Cookies.get("returnTo")) {
          whereToGo = encodeURIComponent(Cookies?.get("returnTo") as string);
          Cookies.remove("returnTo");
        }
        else {
          whereToGo = encodeURIComponent(`${window.location.protocol}//${window.location.host}`);
        }
        window.location.href = `${settings.getSetting("REST_API")}/auth/google?returnTo=${whereToGo}`

      } catch (error: any) {
        // Set loading to false
        setIsLoading(false)

        // Set error global state
        setError(error)
      }
    },

    // Login with Microsoft
    loginWithMicrosoft: async () => {
      // Set Google as provider
      setProvider("microsoft")

      // Change state as loading
      setIsLoading(true)

      //report clicked on login with ms
      TagManager.dataLayer({
        dataLayer: {
          event: 'GA_Event',
          event_action: "login_button_click",
          event_category: "workspace_activities",
          event_label: "microsoft",
        },
        });

      try {
        // Redirect to Google Login
        let whereToGo;
        if (Cookies.get("returnTo")) {
          whereToGo = encodeURIComponent(Cookies?.get("returnTo") as string);
          Cookies.remove("returnTo");
        }
        else {
          whereToGo = encodeURIComponent(`${window.location.protocol}//${window.location.host}`);
        }
        window.location.href = `${settings.getSetting("REST_API")}/auth/microsoft?returnTo=${whereToGo}`

      } catch (error: any) {
        // Set loading to false
        setIsLoading(false)

        // Set error global state
        setError(error)
      }
    },

    // Login with Linkedin
    loginWithLinkedin: () => console.log("Login with Linkedin"),

    // Logout
    logout: async () => {
      try {
        logout()

        // Redirect to login page
        history.push('/')

      } catch (e) {
        console.log(e)
      }
    },

    // Http
    axiosAuth: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export { useAuth, AuthProvider }
export type { IAuth }
