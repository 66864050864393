/**
 *
 *  @module src/components/Modalbox
 *
 *  @description overlay modal box component
 *
 *  @author diegoulloao
 *
 */

// React
import { useRef, useLayoutEffect, useContext } from "react"

// Utils
import cx from "classnames"

// Icons
import { BsX } from "react-icons/bs"

import './style.css';
import AppContext from "@/context/AppContext";

// Local Interfaces
interface ModalboxProps {
  message?: string
  description?: string | JSX.Element
  actionMessage?: string
  confirmText?: string
  cancelText?: string
  onClose?: () => void
  onCancel?: () => void
  onConfirm?: () => void
  children?: React.ReactNode
  className?: string | [] | {}
  disableConfirmButton?: boolean
  buttonsWidth?: string
}

/**
 *
 *  Component: Modal Box
 *  @description overlay modal box component with confirm/cancel actions
 *
 */
const Modalbox: React.FunctionComponent<ModalboxProps> =
  ({
    onClose,
    children,
    className,
    message="",
    description="",
    actionMessage="",
    confirmText="Confirm",
    cancelText="Cancel",
    onCancel=(() => null),
    onConfirm=(() => null),
    buttonsWidth,
    disableConfirmButton
  }): JSX.Element | React.ReactElement => {
    // References
    const ref = useRef<HTMLDivElement>(null)

    const { tr } = useContext(AppContext)

    // Effects
    useLayoutEffect(() => {
      setTimeout(() => ref.current?.classList.remove("opacity-0"), 0)
    }, [])

    return (
      <div
        // Overlaps menu (menu is using z-index: 1109)
        style={{ zIndex: 1199 }}
        className={cx([
          "modal fixed top-0 left-0 w-screen h-screen flex bg-gray-800 bg-opacity-70",
          "items-center justify-center py-8 px-4"
        ])}
      >
        {/* Modal Head */}
        <div className={cx("relative rounded shadow-lg p-6 bg-white opacity-0 duration-100 transition", className)} ref={ref}>
          {/* Close button */}
          <BsX className="absolute right-3 top-4 cursor-pointer text-gray-700 transition" size="32" onClick={() => (onClose || onCancel)()} />

          {/* If no children, then show default modal structure */}
          { !children && (
            <div>
              <h1 style={{ width: '98%' }} className="text-gray-800 font-bold text-lg">{ message }</h1>

              <p className="py-4 text-sm font-medium  dark:text-gray-100 text-gray-800">{ description }</p>
              <p className="text-sm font-medium  dark:text-gray-100 text-gray-600">{ actionMessage }</p>

              {/* Buttons */}
              <div className="sm:flex items-center justify-center pt-6">
                {/* Cancel */}
                <button
                  style={{ width: buttonsWidth }}
                  onClick={onCancel}
                  className={cx([
                    "py-4 px-9 text-black leading-3 focus:outline-none hover:opacity-90",
                    "text-sm font-semibold border-black rounded border"
                  ])}
                >
                  { tr(cancelText) }
                </button>

                {/* Confirm */}
                <button
                  style={{ width: buttonsWidth }}
                  disabled={disableConfirmButton}
                  onClick={onConfirm}
                  className={cx([
                    "confirm-modal-button",
                    "py-4 px-9 sm:mt-0 mt-2 sm:ml-2 leading-3 text-white border border-black focus:outline-none hover:opacity-80",
                    "text-sm font-semibold rounded bg-black"
                  ])}
                >
                  { tr(confirmText) }
                </button>
              </div>
            </div>
          ) }

          {/* Shows children only if exists */}
          { children }
        </div>
      </div>
    )
  }

export default Modalbox
