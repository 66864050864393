import { useContext, useState, useCallback } from "react";

import AuthContext from "@/auth/context";
import ImageUpload from "@/components/UnboxableForm/ui/components/ImageUpload";
import CKEditorCustom from "@/components/WizardComponents/CKEditorCustom/CKEditorCustom";
// import CustomAutocomplete from "@/components/WizardComponents/CustomAutocomplete/CustomAutocomplete";
import CustomPlacesSelect from "@/components/WizardComponents/CustomPlacesSelect/CustomPlacesSelect";
import Dropdown from "@/components/WizardComponents/Dropdown/Dropdown";
import TextField from "@/components/WizardComponents/TextField/TextField";
import WizardStepTemplate from "@/components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { toBase64 } from "@/helpers";
import { MediaUploader } from "@/helpers/mediaUploader";
import { IWizardStep } from "../../interfaces";

import './style.css';
import AppContext from "@/context/AppContext";

const TITLE = "What position are you hiring for?";
const DESCRIPTION  = "You're now building what candidates will see when they're applying. What you write here won't affect the answers you gave when creating the role's DNA."

const jobTypeOptions = [
    { value: "FullTime", label: "Full Time" },
    { value: "PartTime", label: "Part Time" },
    { value: "Freelance", label: "Freelance" },
    { value: "Remote", label: "Remote" },
    { value: "Advisory", label: "Advisory" },
    { value: "Hybrid", label: "Hybrid" }
]

const GeneralStep: React.FunctionComponent<IWizardStep> = ({ formik }) => {
    const { values, handleChange, setFieldValue } = formik;
    const { 
        location, 
        title, 
        description,
        department,
        jobTypes,
        positionImage } = values;

    const [editor, setEditor] = useState<any>(null);
    const { tr } = useContext(AppContext)

    const onDescriptionChange = (newVal: string):void => { 
        editor?.setData?.(newVal);
        setFieldValue("description", newVal);
    }

    const handleFieldChange = useCallback((newVal: any) => setFieldValue("location", newVal?.value ?? ""), []);

    const { settings } = useContext(AuthContext);

    const uploadImage = async (files: FileList | null) => {
        const [ file ] = files || [];

        if(!file)
            return

        try {
            const imageData = (file && await toBase64(file)) || "";
            const mediaUploader = new MediaUploader(settings);
            const result = await mediaUploader.upload({
                data: imageData,
                name: file.name
            });
            const { url, error } = result || {};
            if(error) {
                throw new Error(error); 
            }
            if(url) {          
                return url
            }
            throw Error('Something went wrong...');
        } 
        catch(e) {
            console.error(e);
        }

    };

    const onImageChange = (files: FileList | null) => {

        if(!files) {
            setFieldValue("positionImage", undefined)
        }else {
            uploadImage(files)
            .then((src) => {
                if(!src)
                    return
                setFieldValue("positionImage", src)
            })
            .catch(console.error);
        }
    }


    return (
        <WizardStepTemplate
        title={TITLE}
        description={DESCRIPTION}>
            <div className="general-tab-container">
                <div className="general-tab-title-image-wrapper">
                    <TextField
                    uiType="white-bordered"
                    name="title"
                    className="custom-textfield-size"
                    label="Position title"
                    placeholder="Title"
                    value={title}
                    onChange={handleChange}/>
                    <ImageUpload 
                    className="leaders-tab-data-image-upload-general"
                    label={"Cover image"}
                    name={`positionImage`}
                    src={positionImage } 
                    clear={() => onImageChange(null)} 
                    onChange={(e) => onImageChange(e.target.files)}
                    />
                </div>
                <CKEditorCustom
                label={"Job description"}
                initData={description}
                onLoaded={({ editor }) => setEditor(editor)}
                onChange={(args) => onDescriptionChange(args.editor.getData())}
                />
                <div className="general-tab-row">
                    <TextField
                    style={{ width: "300px" }}
                    uiType="white-bordered"
                    label={tr("Department")}
                    name="department"
                    placeholder={tr("Department")}
                    value={department}
                    onChange={handleChange}/>
                    <CustomPlacesSelect 
                    label={tr("Office location")}
                    uiType="white-bordered"
                    handleChange={handleFieldChange} 
                    name={"location"} 
                    value={location ? {label: location, value: location} : ""}/>
                </div>
                {/* <CustomAutocomplete
                label="Add preferable the job type"
                placeholder="Full Time, Remote, etc."
                options={jobTypeOptions}
                selectedOptions={jobTypes ? [{ label: jobTypes ,value: jobTypes }] : []}
                onChange={newValues => setFieldValue("matchingJobTypes", newValues.map(jt => jt.value))}/> */}
                <Dropdown
                uiType="white-bordered"
                label={tr("Add preferred job type")}
                name="jobTypes"
                value={jobTypes}
                onChange={handleChange}
                options={jobTypeOptions}
                />
            </div>
        </WizardStepTemplate>
    )
}

export default GeneralStep;