// React
import { useCallback, useContext, useState } from "react"

// Auth
import { useAuth, IAuth } from "@auth"

// Graphql
import { deleteReferrerQuery as deleteReferrerMutation } from "@mutations/company"

// Types
import { IFetcher, IFetchData } from "@components/Table"
import { graphQueries } from "@/helpers"
import AppContext from "@/context/AppContext"

// Interfaces
interface IReferrer {
  name: string
  email: string
  id: string
  code: string
}

interface IReferralsData {
  count: string
  referrers: IReferrer[]
}

interface IActionMenuItem {
  label: string
  onClick: () => any
}

interface Referral {
  showModal: boolean
  onClose: () => void
  showAddReferralBox: boolean
  fetcher: IFetcher<IReferrer>
  referralToRenew: IReferrer|null
  referralToDelete: IReferrer|null
  openAddReferralModal: () => void
  setMutate: React.Dispatch<Function>
  setShowModal: React.Dispatch<boolean>
  deleteReferrer: (referrer: IReferrer) => void
  setShowAddReferralBox: React.Dispatch<boolean>
  actionMenuItems: (referrer: IReferrer) => IActionMenuItem[]
}

/**
 *
 *  Logic: Referrals
 *  @description contains all Referrals page logic
 *
 */
const useReferral = (): Referral => {
  // Auth
  const { company }: IAuth = useAuth()

  // States
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showAddReferralBox, setShowAddReferralBox] = useState<boolean>(false)
  const [referralToRenew, setReferralToRenew] = useState<IReferrer|null>(null)
  const [referralToDelete, setReferralToDelete] = useState<IReferrer|null>(null)
  const [mutate, setMutate] = useState<Function>(() => () => null)

  const { locale, localizer } = useContext(AppContext);
  const actionMenuItems = useCallback((referral: IReferrer): IActionMenuItem[] => ([
    {
      label: localizer.localize({ key: "Get New Referral Link", locale }),
      onClick: () => {
        setReferralToRenew(referral)
        setShowAddReferralBox(true)
      }
    },
    {
      label: localizer.localize({ key: "Delete User", locale }),
      onClick: () => {
        setReferralToDelete(referral)
        setShowModal(true)
      }
    }]), [locale])


    const onClose = (): void => {
    setReferralToRenew(null)
    setShowAddReferralBox(false)
    mutate()
  }

  // Referrals List fetcher
  const fetcher = (query: string, page: number, limit: number, keyword: string): Promise<IFetchData<IReferrer>> =>
  graphQueries.sendRequest(query, {
      companyId: company!.id,
      email: keyword,
      page,
      limit
    })
    .then(({ getReferrers }: { getReferrers: IReferralsData }) => ({
      items: getReferrers.referrers,
      count: parseInt(getReferrers.count)
    }))

  // Handlers
  const deleteReferrer = async (referrer: IReferrer): Promise<void> => {
    const response: any = await graphQueries.sendRequest(deleteReferrerMutation, {
      referrerId: referrer.id
    })

    if ( response?.deleteReferrer.response !== "Referrer deleted" ) {
      // TODO: warn to user

      setShowModal(false)
      return console.log("error")
    }

    // Hide modal
    setShowModal(false)
    mutate()
  }

  const openAddReferralModal = (): void => {
    setShowAddReferralBox(true)
  }

  return {
    fetcher,
    onClose,
    setMutate,
    showModal,
    setShowModal,
    deleteReferrer,
    actionMenuItems,
    referralToRenew,
    referralToDelete,
    showAddReferralBox,
    openAddReferralModal,
    setShowAddReferralBox
  }
}

export default useReferral
export type { Referral, IReferrer, IReferralsData }
