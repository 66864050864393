
import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import SkillEvalEditor from "./components/SkillEvalEditor"
import { EditorApi } from "./data/editor.api"
import { 
    AssessmentStatus, 
    // EvalTypes 
} from "./data/editor.utils"
import { IAssessment, ISkillEval } from "./data/types"
// import { 
//     SortableTab, 
//     // SortableTabs 
// } from "./components/SortableTabs"
// import { compact, isEmpty } from "lodash"
// import { BsToggleOff, BsToggleOn } from "react-icons/bs"
import LNRDLoading from "@components/LNRDLoading"
import SearchQuestionsDialog from "./components/SearchQuestionsDialog"
import { Button } from "@/components"
import BuildingBlockEditor, { BuildingBlock, EditorMode } from "../BuildingBlocks/BuildingBlockEditor"
import BuildingBlocksSelect from "./components/BuildingBlocksSelect"
import { IPosition } from "@/interfaces/pages/positions"
import useLocalToast from "../../hooks/useLocalizedToast";

export enum BuildingBlockEnums {
    SKILLS = "skl",
}

const AssessmentEditor: React.FunctionComponent = () => {
    
    const [err, setErr] = useState<string>('')
    const [position, setPosition] = useState<IPosition | null>(null)
    const [toedit, setToEdit] = useState<ISkillEval | undefined>()
    
    const [currentAssessment, setCurrentAssessment] = useState<IAssessment | null>(null)
    const [positionsAssessments, setPositionsAssessments] = useState<(IAssessment[] | null)>(null);

    const [openQuestionSearchDialog, setOpenQuestionSearchDialog] = useState<boolean>(false);
    const [openBuildingBlockEditor, setOpenBuildingBlockEditor] = useState<boolean>(false); 
    const { toast } = useLocalToast();

    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const position_id = search.get('position_id')

    if (!position_id) {
        console.warn('no position_id')
        setErr(() => 'no position_id')
    }

    const getPositionAssessments =async (positionId: string): Promise<IAssessment[] | null> => {

        if (!positionId) {
            return null
        }

        const fetchedAssessments = await EditorApi.getAssessment({
            position_id: positionId
        })

        if(!fetchedAssessments) {
            throw Error('no assessment for position: ' + positionId);
        }

        return fetchedAssessments;
    }

    const setStatus = async (positionId?: string, status?: AssessmentStatus) => {
        if (positionId && status) {

            const [a] = await EditorApi.changeAssessmentStatus(positionId ,status)
            if (a.status === status) {
                toast(`Assessment Published!`, { containerId: "default" })
            } else {
                toast(`Assessment publish fails..`, { containerId: "default" })
            }

            setCurrentAssessment(() => a)
        }
    }

    const onEvalChange = async (neweval: ISkillEval, changeState = true) => {
        if (changeState && toedit?.id === neweval.id) {
            setToEdit(() => neweval)
        }

        const index = currentAssessment?.skillevals.findIndex(f => f.id === neweval.id)

        if (index !== undefined && index > -1) {
            currentAssessment?.skillevals.splice(index, 1, neweval)
            
            const [newassessment] = await EditorApi.postAssessment({ ...currentAssessment })
            if (changeState) {
                setCurrentAssessment(() => newassessment)
            }
        } else {
            return false
        }
        return true
    }

    const onFocusTab = async (skilleval: ISkillEval) => {
        
        setToEdit(() => skilleval)
    }

    const onSubmitEditBuildingBlock = async (buildingBlock: BuildingBlock) => {
        const newassessment = {
            ...currentAssessment,
            metadata: {
                ...currentAssessment?.metadata,
                buildingblock: buildingBlock 
            }
        }
        
        const updated = await EditorApi.postAssessment(newassessment);
        if(!updated) {
            console.error('failed to update assessments building block')
            toast(`failed to update assessments building block`, { containerId: "default", type: "error" })
        }
        setCurrentAssessment(updated[0])
        toast(`Building block updated!`, { containerId: "default", type: "success" })
        setOpenBuildingBlockEditor(() => false)
    }

    useEffect(() => {
        
        if (!position_id) {
            return console.error('no position id');
        }

        EditorApi.getPositions(position_id).then(positions => {
            const [p] = positions
            if (p) {           
                setPosition(() => p);
                getPositionAssessments(position_id)
                    .then (( assessments ) => {
                        
                        if(!assessments) {
                            return null
                        }
                        
                        setPositionsAssessments(assessments)
                    })
            } else {
                setErr('no position with id: ' + position_id)
            }
        }).catch(console.error)

    }, [position_id])

    const UI = <div>
        <div className='flex flex-row items-center my-8 justify-between'>
            {currentAssessment?.title && <div className="px-2 text-xl text-gray-700">
                {currentAssessment?.title}
            </div>}
            {currentAssessment?.description && <div className="px-2">
                {currentAssessment?.description}
            </div>}
            <div className={'flex items-center'}>
                <button
                    disabled={AssessmentStatus.READY === currentAssessment?.status}
                    onClick={() => setStatus(position_id!, AssessmentStatus.READY)}
                    className='px-6 mr-8 py-2 rounded-lg bg-black text-white'>
                    {AssessmentStatus.READY === currentAssessment?.status ? 'PUBLISHED' : 'PUBLISH'}
                </button>
            </div>

        </div>
        <div className="flex flex-row justify-between my-2">
            <BuildingBlocksSelect
            position={position}
            buildingBlocks={positionsAssessments ?? []}
            initialId={"0"}
            setAssessmentToEdit={onFocusTab}
            setCurrentBuildingBlock={setCurrentAssessment}/>
            <div className=""> 
                <Button 
                onClick={() => setOpenQuestionSearchDialog(true)} 
                type="wizard-black"
                text="Search questions"
                className={"mb-2"}
                />
                <p>
                    This BB has intro page <span className="underline cursor-pointer" onClick={() => setOpenBuildingBlockEditor(true)}> Edit </span>
                </p>
            </div>
        </div> 

        {toedit && <SkillEvalEditor
            position={position}
            onChange={onEvalChange}
            evaluator={toedit} 
            selectedBuildingBlock={currentAssessment}/>}
        
        {openQuestionSearchDialog && toedit?.id && currentAssessment?.id && 
            <SearchQuestionsDialog
            positionId={position_id}
            openSearchDialog={openQuestionSearchDialog} 
            setOpenSearchDialog={setOpenQuestionSearchDialog} 
            currentAssessment={currentAssessment}
            skillevalId={toedit.id}
            setAssessmentToEdit={onFocusTab}
            setCurrentAssessment={setCurrentAssessment}/>}
        
        {openBuildingBlockEditor && currentAssessment &&
            <BuildingBlockEditor 
            openBuildingBlockEditor={openBuildingBlockEditor} 
            setOpenBuildingBlockEditor={setOpenBuildingBlockEditor}
            editorMode={EditorMode.EDIT}
            builidingBlockToEdit={currentAssessment?.metadata?.buildingblock}
            onSubmitBuildingBlock={onSubmitEditBuildingBlock}/>}

    </div>

    return <>
        { err
            ? <>{err}</>
            : (position && positionsAssessments ? UI : <LNRDLoading />)
        }
    </>
}

export default AssessmentEditor


