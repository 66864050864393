import { ICompany } from "@/auth/interfaces";
import { SelectCompanyOption } from "@/context/AppContext";
import { graphQueries } from "@/helpers";
import { ICompanyInfo } from "@/models";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import useLocalToast from "../../../hooks/useLocalizedToast";

export const useCompanyDropdown = (args: {
    setCurrentCompany: (company: ICompany) => void;
    isUserAdmin: boolean;
    currentCompany: ICompany | null;
    onClickAddNewCompany: (args?: any) => any;
}): {
    searchCompanies: (search: string) => Promise<SelectCompanyOption[] | void>,
    onCompanySelectChange: (company: ICompanyInfo) => void,
    getInitialCompanies: () => {value: any, label: any}[],
    setCompaniesAndOptions: () => void,
    companiesOptions: { label: string, value: any }[]
} => {
    const { setCurrentCompany, isUserAdmin, currentCompany, onClickAddNewCompany } = args;

    const history = useHistory();
    
    const [companiesList, setCompaniesList] = useState<Partial<ICompanyInfo>[] | null>(null);
    
	const [companiesOptions, setCompaniesOptions] = useState<{ label: string, value: any }[] >([]);

	const { toast } = useLocalToast();

	const showGetCompaniesError = useCallback((error: string) => {
		toast(`Error fetching companies: ${error}`, {
			toastId: "navbar-companies",
			containerId: "default",
			type: "error",
		})
	
	}, []);
	
	

	const ADD_COMPANY_OPTION_ID = "01010101"

	useEffect(() => {
		if(!companiesList || !companiesOptions) {
			setCompaniesAndOptions()
		}
	}, [companiesList, companiesOptions])

	const setCompaniesAndOptions = () => {
		graphQueries.getAllCompanies({ key: 'companies', filter: "", limit: 1000 })
		.then(response => {
			if(response.data) {
				setCompaniesList(response.data as any)
				setCompaniesOptions(getOptionsFromCompaniesList(response.data))
			}
		})
		.catch(e => showGetCompaniesError(`Error fetching companies: ${e}`))
	} 

	const getOptionsFromCompaniesList = (companies: Partial<ICompanyInfo>[]) => {
		// Positions Options Array (Select)
		const companyOptions: SelectCompanyOption[] = companies.map(p => ({
			label: p.name!,
			value: p.id!
		})).sort((a: any, b: any) => a.label > b.label && 1 || -1)

		// Add "Add company..." option
		if(isUserAdmin) {
			companyOptions.unshift({
				value: ADD_COMPANY_OPTION_ID,
				label: "Add new..."
			});
		}

		return companyOptions
	}

    const searchCompanies = async (search: string): Promise<SelectCompanyOption[] | void> => {
		try {
			// Fetch all Positions from Company
			const result = await graphQueries.getAllCompanies({ key: 'companies', filter: search, limit: 20 })
			if (result.error) {
				showGetCompaniesError(result.error)
				return
			}

			// Positions Options Array (Select)
			const companyOptions: SelectCompanyOption[] = result.data!.map(p => ({
				label: p.name!,
				value: p.id!
			})).sort((a: any, b: any) => a.label > b.label && 1 || -1)

			return companyOptions

		} catch (e: any) {
			console.error(e)
		}
	}

    const onCompanySelectChange = (item: any) => {
		if(item.value === ADD_COMPANY_OPTION_ID && isUserAdmin) {
			onClickAddNewCompany()
			return 
		}
		const selectedCompanyId: string = item.value
		if(!companiesList || companiesList.length <= 0) {
			setCompaniesAndOptions()
		}

		const selectedCompany = companiesList!.find((c) => c.id === selectedCompanyId)!

		setCurrentCompany({
			id: item.value,
			name: selectedCompany.name!,
			slug: selectedCompany.slug!,
			emailDomain: selectedCompany.emailDomain!
		})

		if (history.location.pathname !== "/positions") {
			history.push("/positions")
		}
	}

	const getInitialCompanies = () => {
		return companiesOptions?.filter((options: any) => options.value === currentCompany?.id)
	}

    return {
        searchCompanies,
        onCompanySelectChange,
        getInitialCompanies,
        setCompaniesAndOptions,
        companiesOptions
    }
}