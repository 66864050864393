import { gql } from "graphql-request"

// Get candidates
export const getCandidates = (name: string): string => gql`
  query ${name} ($positionId: UUID, $titleName: String, $sizeOfPage: Int, $page: Int) {
    getCandidates(positionId: $positionId, titleName: $titleName, sizeOfPage: $sizeOfPage, page: $page) {
      count
      candidates {
        id
        finalProcessedProfile
        V2PositionId
        firstName
        lastName
        email
        referrer
        deleted
        isSharingProfile
        phone
        cv
        candidateStatus
        comProfile
        totalScore
        scoredQuestionResponse
        relevancy
        relevancyPerChannel
        createdAt
        updatedAt
      }
    }
  }
`

export const getCandidatesForTalentsPage = (name: string): string => gql`
  query ${name} ($positionId: UUID, $titleName: String, $sizeOfPage: Int, $page: Int) {
    getCandidates(positionId: $positionId, titleName: $titleName, sizeOfPage: $sizeOfPage, page: $page) {
      count
      candidates {
        id
        finalProcessedProfile
        V2PositionId
        v2PositionTitle
        firstName
        lastName
        pdfUrl
        email
        referrer
        deleted
        isSharingProfile
        phone
        candidateStatus
        comProfile
        totalScore
        relevancy
        relevancyPerChannel
        cvName
        cv
        status
        blocksScore
        createdAt
        updatedAt
      }
    }
  }
`

// Get candidates count
export const getCandidatesCount = (name: string): string => gql`
  query ${name} ($positionId: UUID, $titleName: String, $sizeOfPage: Int, $page: Int) {
    getCandidates(positionId: $positionId, titleName: $titleName, sizeOfPage: $sizeOfPage, page: $page) {
      count
      candidates{
        id
      }
    }
  }
`

// Get export link to candidates
export const getCandidatesScoresToCsv = (name: string): string => gql`
  query ${name}($companyId: UUID!, $positionId: UUID!) {
    getCandidatesScoresToCsv(companyId: $companyId, positionId: $positionId)
  }
`

export const getCandidateBlocks = gql`
query($candidateId: ID!) {
  getCandidateBlocks(candidateId: $candidateId) {
    id
    candidateId
    assessmentId
    typeOfBlock
    blockData
    blockResponse
    metadata
    createdAt
    updatedAt
  }
}
`

export const getReportCandidateData = gql`
query getCandidate($candidateId: UUID) {
  getCandidate(candidateId: $candidateId) {
    id
    firstName
    lastName
    email
    phone
    linkedinUrl
    cv
    pdfUrl
    referrer
    comProfile
    V2PositionId
    relevancy
    createdAt
    blocksMeanings
    relevancyPerChannel
    imageUrl
  }
}
`;

export const getAllReportData = gql`
query GetReportData($candidateId: ID!, $candidateId2: UUID, $positionId: UUID, $companyId: UUID) {
  getCandidateBlocks(candidateId: $candidateId) {
    correctAnswers
    id
    candidateId
    assessmentId
    typeOfBlock
    blockData
    blockResponse
    metadata
    channel
    createdAt
    updatedAt
  }
  generateReportRecommendations(candidateId: $candidateId2, companyId: $companyId) {
    id
    assessmentAxe
    UserId
    CompanyId
    priorityRank
    section
    sectionType
    reason
    recommendationText
    User {
      id
      email
      firstName
      lastName
      jobTitle
      phone
      active
      comProfile
      role
      deleted
      description
      pending
      userImageName
      userImage
    }
    positions
    createdAt
    updatedAt
  }
  getCandidate(candidateId: $candidateId2) {
    id
    firstName
    lastName
    email
    phone
    linkedinUrl
    pdfUrl
    cv
    referrer
    comProfile
    V2PositionId
    relevancy
    createdAt
    blocksMeanings
    relevancyPerChannel
    imageUrl
    status
  }
  getV2Position(positionId: $positionId,) {
    CompanyId
    id
    title
    matchingTitle
    matchingHrms
  }
}
`

export const getCandidatesByCompanyId = gql`
query Query($companyId: UUID) {
  getCandidates(companyId: $companyId) {
    candidates {
      id
      V2PositionId
      firstName
      lastName
      email
      pdfUrl
      createdAt
      updatedAt
      cvName
      cv
      relevancy
      imageUrl
      v2PositionTitle
    }
  }
}
`

