import { useContext} from "react";
// Helpers
import { ComProfile, comProfileToWorkStyle } from "../comProifleHelpers";

//Icons
import ComprofileIcons from "../../components/ComprofileIcons";
import ComProfileMain from "../../components/ComProfileMain/ComProfileMain";
import AppContext from "@/context/AppContext";

// Interfaces
interface IComProfileCompare {
  talentComProfile: ComProfile;
  hrmComProfile: ComProfile;
  talentfirstName: string;
}

const ComProfileCompare: React.FunctionComponent<IComProfileCompare> = (
  props
): JSX.Element => {
  const { talentComProfile, hrmComProfile, talentfirstName } = props;

  const { tr } = useContext(AppContext);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ width: "42px" }}>
            <ComprofileIcons comProfile={talentComProfile} color={"#262626"} />
          </div>
          <h3 style={{ padding: "15px 0", fontWeight: 300 }}>
            <span style={{ fontWeight: "700" }}>{talentfirstName},</span> The
            Talent
            <br />
            belongs to the workstyle of
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#5a7bff",
              padding: "12px 12px",
              width: "fit-content",
              borderRadius: "36px",
              color: "#F8F3EE",
            }}
          >
            <div style={{ width: "32px" }}>
              <ComprofileIcons
                comProfile={talentComProfile}
                color={"#F8F3EE"}
              />
            </div>
            <span style={{ fontSize: "19px", fontWeight: 300 }}>
              {comProfileToWorkStyle[talentComProfile]?.toUpperCase()}
            </span>
          </div>
        </div>
        <div className="comprofile-main">
          <ComProfileMain
            talentComProfile={talentComProfile}
            hrmComProfile={hrmComProfile}
          />
        </div>
        <div>
          <div style={{ width: "42px" }}>
            <ComprofileIcons comProfile={hrmComProfile} color={"#262626"} />
          </div>
          <h3 style={{ padding: "15px 0", fontWeight: 300 }}>
            <span style={{ fontWeight: "700" }}>{tr("You")},</span> {tr("The Hiring Manager")}
            <br />
            {tr("belongs to the workstyle of")}
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#FF8E5A",
              padding: "12px 12px",
              width: "fit-content",
              borderRadius: "36px",
              color: "#F8F3EE",
            }}
          >
            <div style={{ width: "32px" }}>
              <ComprofileIcons comProfile={hrmComProfile} color={"#F8F3EE"} />
            </div>
            <span style={{ fontSize: "19px", fontWeight: 300 }}>
              {comProfileToWorkStyle[hrmComProfile]?.toUpperCase()}
            </span>
          </div>
        </div>
      </div>
      <a
        className="combined-comprofile-for-print"
        href={window.location.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {tr("Read more")}
      </a>
    </>
  );
};

export default ComProfileCompare;
