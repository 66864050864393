import { ICompanyInfo } from "@/models"
import { GraphQLMutationInfo, IGraphQLFieldMap, IGraphQLQueryAndData } from "@/graphql/mutations"

export const getCompanies = `
  mutation Mutation($getCompanyInformationCompanyName2: String!) {
    getCompanyInformation(companyName: $getCompanyInformationCompanyName2) {
      results
    }
  }
`
export const createCompanyMutation = `
mutation Mutation($emailDomain: String) {
  createCompany(emailDomain: $emailDomain) {
    response
  }
}
`

const updateCompanyFieldMap: IGraphQLFieldMap = {
    companyId: { name: "companyId", type: "UUID!" },
    owner: { name: "companyOwner", type: "String" },
    name: { name: "companyName", type: "String" },
    email: { name: "companyEmail", type: "String" },
    clientId: { name: "companyClientId", type: "String" },
    customSubdomain: { name: "companyCustomSubdomain", type: "String" },
    websiteLink: { name: "websiteLink", type: "String" },
    description: { name: "companyDescription", type: "String" },
    yearFounded: { name: "companyYearFounded", type: "String" },
    industry: { name: "companyIndustry", type: "[String]" },
    sizeRange: { name: "companySizeRange", type: "String" },
    locality: { name: "companyLocality", type: "[String]" },
    cultureAndValue: { name: "updateCompanyCultureAndValue", type: "[JSON]" },
    leaders: { name: "updateCompanyLeaders", type: "[JSON]" },
    linkedinUrl: { name: "updateCompanyLinkedinUrl", type: "String" },
    domain: { name: "domain", type: "String" },
    hq: { name: "companyHQ", type: "JSON" },
    additionalInfo: { name: "companyAdditionalInfo", type: "[JSON]" },
    profilePictureUrl: { name: "profilePictureUrl", type: "String" },
    currentEmployeeEstimate: { name: "companyCurrentEmployeeEstimate", type: "Int" },
    companyTotalEmployeeEstimate: { name: "companyTotalEmployeeEstimate", type: "Int" },
    atsProvider: { name: "companyAtsProvider", type: "String" },
    atsApiKey: { name: "companyAtsApiKey", type: "String" },
    companyAtsCompanyUid: { name: "companyAtsCompanyUid", type: "String" },
    metadata: { name: "companyMetadata", type: "JSON" }
}

export const updateCompanyHeader = `mutation UpdateCompanyMutation(
    %PARAMS%
)
`

const allUpdateCompanyParams = `$companyId: UUID!,
$owner: String,
$companyName: String,
$companyEmail: String,
$companyClientId: String,
$companyCustomSubdomain: String,
$websiteLink: String,
$companyDescription: String,
$companyYearFounded: String,
$companyIndustry: [String],
$companySizeRange: String,
$companyLocality: [String],
$updateCompanyCultureAndValue: [JSON],
$updateCompanyLeaders: [JSON],
$updateCompanyLinkedinUrl: String,
$companyDomain: String,
$companyHQ: JSON,
$profilePictureUrl: String
$companyAdditionalInfo: [JSON],
$companyCurrentEmployeeEstimate: Int,
$companyTotalEmployeeEstimate: Int,
$companyAtsProvider: String,
$companyAtsApiKey: String,
$companyAtsCompanyUid: String,
$companyMetadata: JSON`

const updateCompanyMutationTemplate = `mutation UpdateCompanyMutation(
%PARAMS%
) {
    updateCompany(
    %DATA%
    ) {
      message
    }
  }`

export const updateCompany = 

updateCompanyMutationTemplate
    .replace("%PARAMS", allUpdateCompanyParams)
    .replace("%DATA%",
  Object.entries(updateCompanyFieldMap).map(([key, value]) => `${key}: $${value.name}`)
      .join(',\n\t'));
//      companyId: $companyId,
//      owner: $companyOwner,
//      name: $companyName,
//      email: $companyEmail,
//      clientId: $companyClientId,
//	  additionalInfo: $updateAdditionalInfo
//      customSubdomain: $companyCustomSubdomain,
//      websiteLink: $updateWebsiteLink,
//      description: $companyDescription,
//      yearFounded: $companyYearFounded,
//      industry: $companyIndustry,
//      sizeRange: $companySizeRange,
//      locality: $companyLocality,
//      cultureAndValue: $companyCultureAndValue,
//      leaders: $companyLeaders,
//      linkedinUrl: $companyLinkedinUrl,
//      currentEmployeeEstimate: $companyCurrentEmployeeEstimate,
//      totalEmployeeEstimate: $companyTotalEmployeeEstimate,
//      atsProvider: $companyAtsProvider,
//      atsApiKey: $companyAtsApiKey,
//      atsCompanyUid: $companyAtsCompanyUid,
//      metadata: $companyMetadata
//    ) {
//      message
//  }
//}`


export const buildUpdateCompanyQuery = (companyId: string, update: Partial<ICompanyInfo>): IGraphQLQueryAndData | null => {
    const adapter = new GraphQLMutationInfo (
        updateCompanyMutationTemplate, updateCompanyFieldMap)
    const ret = adapter.buildQuery({ companyId }, update)
    return ret
}