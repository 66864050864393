// Environment variables
import env from "react-dotenv"

// Router
import { Redirect } from "react-router-dom"

// Auth Types
import { IAuth } from "@auth"

// Interfaces
import {
  IMiddlewareParams,
  IMiddlewareReturn
} from "@interfaces/routes"

// Module Interfaces
interface IMiddlewares {
  [index: string]: (params: IMiddlewareParams) => IMiddlewareReturn
}

/*
 * Your middleware can receive:
 *
 * - auth       [useAuth0 value returned]
 * - params     [URL params]
 * - query      [URL query params]
 * - hash       [URL hashtag]
 * - pathname   [URL pathname]
 * - shared     [Shared data between middlewares]
 *
 */
const middlewares: IMiddlewares = {
  /**
   * Auth Middleware
   *
   * @description check if user is authenticated
   *
   */
  auth: ({ auth }) => {

    // Auth
    const { isAuthenticated, isLoading }: IAuth = auth

    // If definitely user is not authenticated
    if (!isLoading && !isAuthenticated) {
      return {
        pass: false,
        redirect: () => <Redirect to="/" />
      }
    }

    // Then is authenticated
    return { pass: true }
  },

  hr: ({ auth }) => {
    const { user }: IAuth = auth;
    const { department, role } = user || {};

    if( ["HR", "Management"].includes(department!) || ["admin", "lnrd_admin", "company_admin"].includes(role!)) {
      return {
        pass: true
      }
    }

    return {
      pass: false,
      redirect: () => <Redirect to={{pathname:"/positions"}} />
    }
  },
  /**
   * Logged Middleware
   *
   * @description check if user is already logged at login
   *
   */
  logged: ({ auth }) => {
    // Auth
    const { isAuthenticated, isLoading }: IAuth = auth

    // User is authenticated at login
    if (!isLoading && isAuthenticated) {
      return {
        pass: false,
        redirect: () => <Redirect to={{pathname:"/positions"}} />
      }
    }

    // Or continue to Login
    return { pass: true }
  },

  /**
   * Position Bank Middleware
   *
   * @description show assessment page only if selected company is "Position Bank"
   *
   */
  "position-bank": ({ auth }) => {
    // Company from Auth
    const { company }: IAuth = auth

    return {
      pass: company?.id === env.POSITIONBANK_ID,
      redirect: () => <Redirect to={{pathname:"/positions"}} />
    }
  },

  /**
   * All Admins Middleware
   *
   * @description check if user is company-admin || lnrd-admin
   *
   */
  "all-admins": ({ auth }) => {
    // Logged user information
    const { user }: IAuth = auth

    return {
      // Pass only if user is admin|lnrd_admin|company_admin
      pass: ["admin", "lnrd_admin", "company_admin"].includes(user!.role),
      redirect: () => <Redirect to="/404" />
    }
  },

  /**
   * Admin Middleware
   *
   * @description check if user is admin
   *
   */
  admin: ({ auth }) => {
    // Logged user information
    const { user }: IAuth = auth

    return {
      // Pass only if user is admin|company_admin
      pass: ["admin", "lnrd_admin"].includes(user!.role),
      redirect: () => <Redirect to="/404" />
    }
  },

  /**
   * Company Admin Middleware
   *
   * @description check if user is a company admin
   *
   */
  "company-admin": ({ auth }) => {
    // Logged user information
    const { user }: IAuth = auth

    return {
      // Pass only if user is admin|company_admin
      pass: ["company_admin"].includes(user!.role),
      redirect: () => <Redirect to="/404" />
    }
  },

  /**
   * Custom Middleware
   *
   * @description custom middleware example
   *
   */
  custom: ({ data }) => {
    return {
      data,
      pass: false,
      redirect: () => <Redirect to="/404" />
    }
  },

  log: ({ data }) => {
    if (Array.isArray(data)) {
      console.log.call(console, ...data);
    }
    return {
      pass: true
    }
  },

  logRoute: ({ params, query, hash, pathname }) => {
    console.log(`Navigating to ${pathname}, query ${query} hash ${hash} parameters:\n`, params);
    return {
      pass: true
    }
  }
}

export default middlewares
