export const DownloadIcon: React.FunctionComponent<{color?: string, onClick?: () => any}> = ({color = "white", onClick}): JSX.Element => (
    <svg onClick={onClick} style={{ cursor: "pointer" }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.875 5.625H9.375V1.875H5.625V5.625H3.125L7.5 10L11.875 5.625ZM3.125 11.25V12.5H11.875V11.25H3.125Z" fill={color}/>
    </svg>
);
export const AnalyticsIcon: React.FunctionComponent<{color?: string, onClick?: () => any}> = ({color = "white", onClick}): JSX.Element => (
    <svg onClick={onClick} style={{ cursor: "pointer" }} width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 3.9375L7.13281 0H1.97656C1.63468 0 1.3068 0.138281 1.06506 0.384422C0.823311 0.630564 0.6875 0.964403 0.6875 1.3125V11.8125C0.6875 12.1606 0.823311 12.4944 1.06506 12.7406C1.3068 12.9867 1.63468 13.125 1.97656 13.125H9.71094C10.0528 13.125 10.3807 12.9867 10.6224 12.7406C10.8642 12.4944 11 12.1606 11 11.8125V3.9375ZM3.91016 11.1562H2.62109V5.25H3.91016V11.1562ZM6.48828 11.1562H5.19922V7.21875H6.48828V11.1562ZM9.06641 11.1562H7.77734V9.1875H9.06641V11.1562ZM7.13281 4.59375H6.48828V1.3125L9.71094 4.59375H7.13281Z" fill={color}/>
    </svg>
);
export const CvIcon: React.FunctionComponent<{color?: string, onClick?: () => any}> = ({color = "white", onClick}): JSX.Element => (
    <svg onClick={onClick} style={{ cursor: "pointer" }} width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3125 3.9375L6.44531 0H1.28906C0.947182 0 0.619304 0.138281 0.377558 0.384422C0.135812 0.630564 0 0.964403 0 1.3125V11.8125C0 12.1606 0.135812 12.4944 0.377558 12.7406C0.619304 12.9867 0.947182 13.125 1.28906 13.125H9.02344C9.36532 13.125 9.6932 12.9867 9.93494 12.7406C10.1767 12.4944 10.3125 12.1606 10.3125 11.8125V3.9375ZM6.44531 4.59375H5.80078V1.3125L9.02344 4.59375H6.44531Z" fill={color}/>
        <path d="M3.78926 10.06C3.47726 10.06 3.20326 9.996 2.96726 9.868C2.73526 9.74 2.55526 9.558 2.42726 9.322C2.29926 9.082 2.23526 8.798 2.23526 8.47C2.23526 8.142 2.29926 7.862 2.42726 7.63C2.55926 7.398 2.74126 7.22 2.97326 7.096C3.20526 6.972 3.47726 6.91 3.78926 6.91C3.98926 6.91 4.18326 6.942 4.37126 7.006C4.56326 7.066 4.71926 7.154 4.83926 7.27L4.61126 7.792C4.50326 7.692 4.38126 7.618 4.24526 7.57C4.11326 7.518 3.98326 7.492 3.85526 7.492C3.58726 7.492 3.37726 7.576 3.22526 7.744C3.07726 7.912 3.00326 8.156 3.00326 8.476C3.00326 8.796 3.07726 9.042 3.22526 9.214C3.37726 9.386 3.58726 9.472 3.85526 9.472C3.97926 9.472 4.10726 9.448 4.23926 9.4C4.37526 9.352 4.49926 9.278 4.61126 9.178L4.83926 9.694C4.71526 9.81 4.55726 9.9 4.36526 9.964C4.17726 10.028 3.98526 10.06 3.78926 10.06ZM6.35557 10L5.04757 6.97H5.84557L6.79357 9.34H6.60757L7.57957 6.97H8.32957L7.01557 10H6.35557Z" fill={color === "#FFFF" ? "#F7995E" : "white"}/>
    </svg>
);