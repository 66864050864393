/**
 *
 * @module src/pages/Assessments
 *
 * @description show position's assessments
 *
 * @author zohar
 * 
 */

import { useContext, useEffect, useState } from "react";

// Graphql
import { getAllPositions} from "@queries/positions";

// Router
import { useHistory } from "react-router-dom";

// Components
import DropDownWithDotsMaterial from "@/components/DropDownWithDotsMaterial";
import MaterialTable from "@/components/MaterialTable";
import { Title } from "@/components";

// Utils
import { graphQueries } from "@/helpers";
import cx from "classnames";

// Interfaces
import { AnyObject } from "@/helpers/objectUtils";
import { AssessmentStatus } from "../AssessmentEditor/data/editor.utils";
import { isEmpty } from "lodash";
import { EditorApi } from "../AssessmentEditor/data/editor.api";
import { PositionClient } from "@/dal/position-client";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { Modal } from "@mui/material";
import DeleteModalMessage from "@/components/DeleteMessages/DeleteModalMessage";
import { GridColDef } from "@mui/x-data-grid";
import AppContext from "@/context/AppContext";

const modalStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    outline: 'none'
};

const removeAssessmentByPositionId = async (args: {position_id: string, assessment_id: string}): Promise<boolean> => {
    const { position_id, assessment_id } = args;
    const { message } = await PositionClient.update({ 
        positionId: position_id, 
        assessmentId: null, 
        assessmentName: null, 
        assessmentStatus: null }) || {};
    
    if(!message) {
        console.error('fail to upadte position');
        return false;
    }
    const test = await PositionClient.read({ positionId: position_id });
    console.log('test position:', test);
    const count = await EditorApi.deleteAssessment(assessment_id);
    if(count === 1) {
        return true;
    }
    const err = `assessment do not have id, assessment data removed from 
    position ${position_id}, but are not deleted from assessments table!`;
    console.error(err);
    return false;
}


const AssessmentStatusLabel: {[key: string]: string} = {
    "": "no  assessment",
    [AssessmentStatus.DRAFT]: "Draft",
    [AssessmentStatus.PENDING_REVIEW]: "Pending review",
    [AssessmentStatus.READY]: "Ready"
};

const Assessments: React.FunctionComponent = (): JSX.Element => { 

    // States
    const [originalData, setOriginalData] = useState<[]>([]);
    const [loading, setLoading] = useState(true);
    const [ openDeleteModal, setOpenDeleteModal ]  = useState<{ 
        open: boolean, onApprove?: () => void, onClose?: () => void }>({
        open: false
    });
    // Router
    const history = useHistory()

    const { tr } = useContext(AppContext);

    // Fetch data
    useEffect(() => {
        setLoading(true);
        graphQueries.sendRequest(getAllPositions("Assessments"), {
            "titleName": "",
            "sizeOfPage": 20,
            "page": 0,
            "haveCandidates": false
        }).then((data: AnyObject) => {
            setLoading(false);
            // Save original data for search
            setOriginalData(data.getAllV2Positions.positions)
            // Save data for the rows
            graphQueries.sendRequest(getAllPositions("Assessments"), {
                "titleName": "",
                "sizeOfPage": data.getAllV2Positions.count,
                "page": 0,
                "haveCandidates": false
            }).then((data) => {
                // Save original data for search
                setOriginalData(data.getAllV2Positions.positions)
            });
        })
    }, []);

    const actionMenuItems = (positionId: string, row?: any): any[] => (
        [
            {
                label: <div className="flex items-center">
                    <PencilIcon width={16} height={16} className="mr-2"/>{tr("Edit Skills")}</div>,
                onClick: () => history.push(`/editor/assessment?position_id=${positionId}`)
            },
            {
                label: <div className="flex items-center">
                    <TrashIcon width={16} height={16} className="mr-2"/>
                    {tr("Delete Assessment")}</div>,
                onClick: async () => {
                    const { assessmentId, id } = row || {};
                    if(!assessmentId || !id) {
                        return;
                    }
                    setOpenDeleteModal({
                        open: true, 
                        onApprove: async () => {
                            const success = await removeAssessmentByPositionId({ 
                                position_id: id, assessment_id: assessmentId 
                            });
                            if(success) {
                                window.location.reload();
                            }
                            setOpenDeleteModal({ open: false });
                        },
                        onClose: () => {
                            setOpenDeleteModal({ open: false });
                        }
                    });
                }
            }
        ]
    )

    //Columns
    const columns: GridColDef[] = [
        { 
            field: 'matchingTitle', 
            headerName: 'Position', 
            width: 300,
            renderCell: (params) => params?.row?.matchingTitle ?? params?.row?.title
        },
        { 
            field: 'companyName', 
            headerName: 'Company', 
            width: 200, 
            renderCell: (params) => (
                <div>
                    <div className="text-gray-700">{params.row.companyName}</div>
                    <div className="text-xs text-gray-400">{(!params.row.openedBy || params.row.openedBy === "null null") ? params.row.openedByEmail : (params.row.openedBy).replace(" null", "")}</div>
                </div>
            )
        },
        { 
            field: 'assessmentName', 
            headerName: 'Assessment name', 
            width: 480, 
            renderCell: (params) => (
                <div className="text-gray-700">{params.row.assessmentName || 'Unnamed'}</div>
            )
        },
        { 
            field: 'assessmentStatus', 
            headerName: 'Assessment status', 
            width: 150, 
            valueOptions: Object.values(AssessmentStatus), 
            renderCell: (params) => {
                if (!params.row.assessmentStatus) {
                    return <span className="text-gray-400">No Assessment</span>
                }
                return (
                <div className={cx(
                    "h-6 px-4 mb-4 md:mb-0 rounded-xl inline-flex items-center justify-center",
                    {
                    "bg-yellow-400": (
                        params.row.assessmentStatus === AssessmentStatus.DRAFT || 
                        params.row.assessmentStatus === AssessmentStatus.PENDING_REVIEW
                        ),
                    "bg-green-400": params.row.assessmentStatus === AssessmentStatus.READY,
                    "bg-gray-900": params.row.assessmentStatus === AssessmentStatus.PENDING_REVIEW,
                    "bg-red-400": isEmpty(params.row.assessmentStatus)
                    }
                )}>
                    <span className="text-xs text-white uppercase">{tr(AssessmentStatusLabel[params.row.assessmentStatus || "" ])}</span>
                </div>
                )
            }
        },
        { 
            field: 'options' ,
            headerName: '', 
            flex: 1, 
            align: 'right', 
            sortable: false, 
            disableColumnMenu: true, 
            renderCell:  (params: any) => <DropDownWithDotsMaterial
            options={actionMenuItems(params.row.id, params.row)} /> 
        }
    ]

    return (
        <main id="assessments">
            {/* Section Title */}
            <Title text={tr("Assessments & Question")} />
            {/* Search Bar */}
            <Modal open={openDeleteModal.open} 
                onBackdropClick={openDeleteModal.onClose}>
                <div style={modalStyle}>
                    <DeleteModalMessage
                        title={tr("Are you sure you want to delete this assessment?")}
                        content={`${tr("You're about to permanently delete the assessment")}. ${tr("Are you sure you want to continue?")}`}
                        withCloseButton={true}
                        onApprove={openDeleteModal.onApprove} 
                        onClose={openDeleteModal.onClose}/>
                </div>
            </Modal>
            {/* Assessments Table */}
            <MaterialTable
            columns={columns}
            loading={loading}
            emptyStateTitle={tr("There are currently no assessments")}
            originalData={originalData}
            searchOnColumns={['matchingTitle', 'companyName', 'assessmentName', 'assessmentStatus']}
            />
        </main>
    )
}

export default Assessments;