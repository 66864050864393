import { objectUtils } from "@/helpers";
import { ICompanyFormPage, ICompanyInfo } from "@/models";
import { CompanyFormPageAdapter } from "./companyFormPageAdapter";


// Company bio template
const companybioTemplate = "@name was founded in @year_founded. HQ's are located in @locations."


export class CompanyStoryPageAdapter extends CompanyFormPageAdapter {
	constructor(page: ICompanyFormPage) {
		super(page, "story");
	}

	protected _doUpdateFormPageFromCompany(company: ICompanyInfo): ICompanyFormPage {
		// Set default values to model copy
		this.setQuestionValue(0, 0, company.profilePictureUrl as string);
		this.setQuestionValue(1, 0, company.name as string)
		
		const description = company.description || this.extractDescription(company) as string
		this.setQuestionValue(2, 0, description);
//		this.setQuestionField(2, 0, "title", description);
	
		this.setQuestionValue(3, 0,  company.websiteLink as string);
		
		return this.page;
	}

	private extractDescription(company: ICompanyInfo): string {
		return companybioTemplate
		.replace("@name", company.name || "@name")
		.replace("@year_founded", `${company.yearFounded || ""}`.split(".")[0] || "(information pending)")
		.replace("@locations", (() => {
			// HQ Location
			const hq: string = objectUtils.getValueFromObject(company.hq, "city", "")

			// Fill with company data if selected in pre process
			const companyLocations = objectUtils.getValueFromObject(company, "locality", [])
			const locations: any[] = []
			

			// Locations
			for (let i = 0; i < 3 && companyLocations[i];) {
				locations.push(companyLocations[i++])
			}

			locations.splice(locations.findIndex((l: any) => l === hq), 1)

			const otherLocationsQuantity: number = companyLocations.length - locations.length
			const locationsString: string = locations.join(", ")

			return (otherLocationsQuantity > 0)
				? `${hq} with offices in ${locationsString} and ${otherLocationsQuantity} other locations`
				: locationsString
		})() || "(information pending)")
	}

	createUpdate(): Partial<ICompanyInfo> {
		const values = {
			profilePictureUrl: this.getQuestionValue(0, 0) as string,
			name: this.getQuestionValue(1, 0) as string,
			description: this.getQuestionValue(2, 0) as string,
			websiteLink: this.getQuestionValue(3, 0) as string
		}

		return values;
	}
}