/**
 *
 * @module src/components/LNRDImage
 *
 * @description LNRD Image component
 *
 * @author dfl
 *
 */

import loadingCircle from "@assets/animations/loading-circle.json"
import { Player } from "@lottiefiles/react-lottie-player"

// Assets
import React, { useEffect, useState } from "react"

export interface ILNRDImageProps {
	readonly source: string
	readonly showLoading: boolean
	readonly showError: boolean
	readonly title: string
	readonly className?: string
	readonly errorSource?: string
	readonly width?: number
	readonly height?: number
	/**
	 * defaults to true
	 */
	readonly showEmpty?: boolean
	readonly onError?: (props: ILNRDImageProps, reason: string) => void
	readonly onLoading?: (props: ILNRDImageProps) => void
	readonly onLoaded?: (props: ILNRDImageProps) => void
}

/**
 *
 *  LNRD Loading
 *  @description loading state screen for LNRD
 *
 */


const LNRDImage: React.FunctionComponent<ILNRDImageProps> =
	(props: ILNRDImageProps): JSX.Element => {
		type ImageStates = "loading" | "loaded" | "error" | "empty"
		const [imageStatus, setImageStatus] = useState<ImageStates>("empty")
		const [imageSource, setImageSource] = useState<string>("")

		const onImageError = (event: string | Event) => {
			if (imageStatus === "loading") {
				props.onError && props.onError({ ...props }, String(event))
				setImageStatus("error")
			}
		}

		useEffect(() => {
			if (props.source) {
				setImageStatus("loading")
				const img = new Image(1, 1)
				img.onerror = onImageError
				img.onload = () => {
					setImageSource(img.src)
					setImageStatus("loaded")
				}
				img.src = props.source
			}
			else {
				setImageStatus("empty")
			}
		}, [props.source])

		const placeHolder = <span className="lnrd-image-placeholder"></span>
		let finalSource = imageSource
		let finalTitle = props.title
		switch (imageStatus) {
			case "empty":
				if (props.showEmpty === false) {
					return placeHolder
				}
				break
			case "loading":
				if (!props.showLoading) {
					return placeHolder
				}
				return (
					<Player
						autoplay
						loop
						src={loadingCircle}
						style={{ width: 'auto', height: "100%" }}
					/>
				)

				break
			case "error":
				if (!props.showError) {
					return placeHolder
				}
				finalSource = props.errorSource || finalSource
				finalTitle = "error"
				break
			default:
				break;
		}

		return (

			<img src={finalSource} alt={finalTitle} style={{ height: "100%" }}/>
		)
	}

export default LNRDImage
