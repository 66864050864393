/**
 *
 *  Create Company
 *
 *  @module pages/CreateCompany
 *
 *  @author diegoulloao
 *
 */

// Components
import { LNRDLoading, Title, WizardForm } from "@components"

// Logic
import useCreateCompany from "@pages/CreateCompany/useCreateCompany"

// Interfaces
import { ICreateCompany } from "@interfaces/pages/company"
import { IOnFinishParams } from "@components/WizardForm/interfaces"

// Router Types
import { StaticContext, useHistory } from "react-router"
import { RouteComponentProps } from "react-router-dom"
import { ICompanyFormPage, ICompanyInfo } from "@models"
import { IWizardPageCallbackParams, WizardDataUpdater, WizardPageChangeStatus } from "@components/WizardForm"

import { applyCompanyInfoToFormPage, getAdapterForPage } from "./formAdapters"
import { graphQueries } from "@helpers"
import { useContext, useState } from "react"
import WizardSummary from "@components/WizardSummary/WizardSummary"
import { toast } from "react-toastify"
import { IFormPage } from "@models/formPage"
import { CompanyFormDataWrapper } from "@models/companyFormData"
import AuthContext from "@/auth/context"
import { Analytics } from "@/helpers/analytics"
import { useAuth } from "@/auth"
import AppContext from "@/context/AppContext"

const CREATE_COMPANY_TOAST_ID = "create-company-regenerate"

// Local Interfaces
interface ICompanyState {
  //  company: ICompany
  //  companyInfo: ICompanyInfo
  companyId: string
}

/**
 *
 *  Onboarding: Company
 *  @description onboarding for company
 *
 */
const CreateCompany: React.FunctionComponent<RouteComponentProps<{}, StaticContext, ICompanyState>> =
  ({ location }): JSX.Element => {
    //const selectedCompany: ICompany|undefined = location.state?.company
    const companyId: string = location.state?.companyId
    const history = useHistory()

    const [showSummary, setShowSummary] = useState(false)

    // Logic parts
    const {
      model,
      //  company,
      // refresh,
      //isLoading,
      companyInfo,
      setCompanyInfo
    }: ICreateCompany = useCreateCompany({ /*selectedCompany,*/ companyId })

    const [dataUpdater, setDataUpdater] = useState<WizardDataUpdater | null>(null);
    const { settings } = useContext(AuthContext);
    const { user } = useAuth();
    const { localizer, locale, tr } = useContext(AppContext);

    // On finish
    const onFinish = async ({ dataCollection }: IOnFinishParams) => {
      void dataCollection// lint
      //  setOnboardingData(dataCollection)
      history.push("/company/create/done", { companyId: companyInfo!.id })
    }

    const canShowSave = (page: number, pageData: Partial<IFormPage>) => {
      void pageData
      return Boolean(model && model.pages && page < (model.pages.length - 1))
    }

    const createSimulatorLink = (company: ICompanyInfo): string => {
      const SIMULATOR_LINK = `${settings.getSetting("JOBSIMULATOR_ORIGIN")}`
      // return SIMULATOR_LINK.replace(/\[company_name\]/ig, escape(company.name!))
      //   .replace(/\[company_id\]/ig, company.id)
      return localizer.localizeURL(`${SIMULATOR_LINK}/${company.slug}/company`, locale);
    }

    const onRegenerate = async () => {
      const cid = companyInfo?.id
      if (!cid) {
        return
      }
      let regenError: string;
      const promise = new Promise((resolve, reject) => {
        graphQueries.getCompanyDefaultInfo(cid)
          .then(result => {
            if (result.data) {
              setDataUpdater(() => (pages: IFormPage[]) => {
                const newData = [...pages] as ICompanyFormPage[]
                const formData = new CompanyFormDataWrapper(newData)
                applyCompanyInfoToFormPage("story", formData, result.data!)
                return newData
              })
              resolve(true)
            }
            else {
              regenError = (result.error || `Failed to get company default information for company ${companyInfo?.name}:\n${result.error}`).slice(0, 150)
              reject(regenError)
            }
          })
      })
      toast.promise(promise, {
        pending: {
          render: `Loading information for ${companyInfo?.name}`
        },
        error: {
          render: () => {
            toast.update(CREATE_COMPANY_TOAST_ID, { type: "error" })
            return regenError
          }
        },
        success: {
          render: () => {
            toast.update(CREATE_COMPANY_TOAST_ID, { type: "success" })
            return "updated"
          }
        }
      }, {
        toastId: CREATE_COMPANY_TOAST_ID
      })
    }

    const getCompanyWizrardEvent = (diff: any, oldData: any) => {
      if (!diff || !oldData) return false;
      const enteries = Object.entries(diff)[0];
      for(let i = 0; i < enteries.length; i++) {
        const entery = typeof enteries[i] === 'string' ? enteries[i] as any : null;
        if(entery && Array.isArray(oldData[entery])) {
          if (diff[entery].length > oldData[entery].length) {
            return `${entery} - item added`
          }
          if (diff[entery].length < oldData[entery].length) {
            return `${entery} - item removed`
          }
        }
      }
      return false;
    }


    const onWizardPageChange = async (params: IWizardPageCallbackParams): Promise<WizardPageChangeStatus> => {
      setShowSummary(Boolean(params.next && params.next.page + 1 === model?.pages.length))
      Analytics.sendCompanyWizardReportEvent({
        user_id: user?.id,
        screen: params?.next?.data?.hash ?? 'unknown',
        event: "visit"
      });
      if (!companyInfo) {
        console.warn("company wizard page change: missing company info")
        return "ok" // no reason to prevent progress
      }
      // if (!params.next?.data || !params.next.model) {
      //   console.warn("company wizard page change: missing page or data")
      //   return "ok" // no reason to prevent progress
      // }
      if (!params.current || !params.getDirty()) {
        return "ok"
      }

      const pageModel = params.current.model as ICompanyFormPage,
        pageData = params.current.data;
      
        if (!pageData || !pageModel) {
          console.warn(`company wizard page change: current page missing page data ${typeof pageData} or page model ${typeof pageModel}`)
          return "ok" // no reason to prevent progress  
        }


      params.setDirty(false);
      let success = true
      try {
        const adapter = getAdapterForPage(pageModel, pageData)
        if (!adapter) {
          console.warn(`Failed to find adapter for page ${params.current.page} ${pageModel.name}`)
          return "ok";
        }
        // assume we either have no update, or the update will succeed
        const update = adapter.createUpdate()
        if (update) {
          success = await graphQueries.sendUpdateCompany(companyInfo.id, update)
        }
        
        const updatedCompany = await graphQueries.getCompanyById(companyInfo?.id ?? "");
        if(updatedCompany?.data) {
          setCompanyInfo(updatedCompany?.data as any);
        }
        
        const event = getCompanyWizrardEvent(update, companyInfo);
        if(event) {
          Analytics.sendCompanyWizardReportEvent({
            user_id: user?.id,
            screen: params?.current?.data?.hash ?? 'unknown',
            event: event
          });
        }
      }
      catch (e) {
        console.error(`While saving company data for page ${pageModel.name}`, e)
        success = false
      }
      finally {
        if (!success) {
          params.setDirty(true)
        }
      }
      return success ? "saved" : "error"
    }

    // If user select start from scratch then show wizard form
    return (
      <main id="company-onboarding">
        <div className="flex justify-between">
          <Title text={`${tr("Edit Company")} ${companyInfo?.name || ""}`} />
          {model && (<button onClick={onRegenerate}>Regenerate</button>)}
        </div>

        {(() => {
          // Show onboarding process
          if (model) {
            return (
              <div className="flex flex-col">
                <WizardForm model={model}
                  onFinish={onFinish} canFinish={() => false}
                  dataUpdater={dataUpdater!}
                  showSave={canShowSave}
                  showTabs="sequential"
                  onPageChange={onWizardPageChange} />
                {showSummary && (
                  <WizardSummary url={createSimulatorLink(companyInfo!)} />
                )}
              </div>

            )
          }
          // else, still loading
          return (
            <div className="text-center">
              <LNRDLoading />
            </div>)
        })()}
      </main>
    )
  }

export default CreateCompany
