import { useContext, useState } from "react"

import { PlacesSelect } from "@/components/UnboxableForm/ui/components/PlacesSelect";
import { SelectCustomStyles_wizard_bordered_white, SelectCustomStyles_wizard_grey } from "@/components/UnboxableForm/ui/styles/select-custom";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Label from "../Label/Label";

import './style.css';
import AppContext from "@/context/AppContext";

const CustomPlacesSelect: React.FunctionComponent<{ 
    handleChange: (newVal: any)=> any, 
    uiType?: "white-bordered" | "primary",
    name: string, 
    value: any, 
    label?: string 
}> = ({
    handleChange, name, value, label, uiType = "primary"
}):JSX.Element => {

    const { tr } = useContext(AppContext)

    const uiTypeStyle = {
        ["white-bordered"]: SelectCustomStyles_wizard_bordered_white,
        ["primary"]: SelectCustomStyles_wizard_grey
    }

    const style = {color:"rgba(0, 0, 0, 0.54)"}
    const [DropDownIcom, setDropDownIcon] = useState<JSX.Element>(<ArrowDropDownIcon style={style}/>)
    return (
        <div className="custom-location-select-container">
            {label &&
            <Label>{tr(label)}</Label>}
            <PlacesSelect 
            className="custom-location-select-style"
            onMenuOpen={() => setDropDownIcon(<ArrowDropUpIcon style={style}/>)}
            onMenuClose={() => setDropDownIcon(<ArrowDropDownIcon style={style}/>)}
            components={{ 
                DropdownIndicator: () => DropDownIcom
            }}
            name={name}
            onChange={(newVal: any) => handleChange(newVal)}
            countryCode={""} 
            placeholder={tr("Location")}
            value={value}
            styles={(uiTypeStyle as any)[uiType] as any}/>
        </div>
    )
}

export default CustomPlacesSelect;