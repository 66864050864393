import { Theme } from "react-select"

export const SelectCustomStyles = {
  control: (p: any): any => ({ ...p, 
    border: 'none', 
    boxShadow:'none', 
    cursor: 'pointer',
    display: 'flex',
    maxWidth: '250px'
  }),
  dropdownIndicator: (): any  => ({ display: 'none' }),
  indicatorSeparator: (): any  => ({ display: 'none' }),
  clearIndicator: (): any  => ({ display: 'none' }),
  menu: (p: any): any  => ({ ...p, width: 'auto' }),
  option: (p: any): any => ({...p, whiteSpace: 'nowrap'}),
  loadingIndicator: (): any => ({ display: 'none' })
}

export const SelectCustomStyles_Dropdown_regular = {
  ...SelectCustomStyles,
  control: (p: any): any => ({ 
    ...p, 
    cursor: 'pointer',
    width: '250px',
    backgroundColor: "#F7F7F7",
    border: "none",
    marginLeft: "0.25rem"
  }),
  dropdownIndicator: (p: any): any  => (p),
  clearIndicator: (): any  => ({ display: 'none' }),
  indicatorSeparator: (): any  => ({ display: 'none' }),
  menu: (p: any): any  => ({ ...p, width: 'auto', minWidth: '100%'})
}

export const SelectCustomStyles_Dropdown_white = {
  ...SelectCustomStyles,
  control: (p: any): any => ({ 
    ...p, 
    cursor: 'pointer',
    // width: '250px',
    backgroundColor: "#FFF",
    border: "none",
    marginLeft: "0.25rem"
  }),
  dropdownIndicator: (p: any): any  => (p),
  clearIndicator: (): any  => ({ display: 'none' }),
  indicatorSeparator: (): any  => ({ display: 'none' }),
  menu: (p: any): any  => ({ ...p, width: 'auto', maxWidth: '100%'})
}

export const SelectCustomStyles_wizard_grey = {
  ...SelectCustomStyles,
  control: (p: any): any => ({ 
    ...p, 
    fontFamily: "Mulish",
    cursor: 'pointer',
    // width: '250px',
    backgroundColor: "#EDEDED",
    padding: "0 8px",
    fontSize: "12px",
    minWidth: "220px",
    height: "35px",
    border: "none",
    borderRadius: "50px",
    minHeight: "35px",
    boxShadow: 'none'
  }),
  dropdownIndicator: (p: any): any  => (p),
  clearIndicator: (): any  => ({ display: 'none' }),
  indicatorSeparator: (): any  => ({ display: 'none' }),
  menu: (p: any): any  => ({ 
    ...p, 
    width: '100%',
    maxWidth: '100%', 
    border: "none",
    fontSize: "12px",
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
  })
}

export const SelectCustomStyles_wizard_bordered_white = {
  ...SelectCustomStyles,
  control: (p: any): any => ({ 
    ...p, 
    fontFamily: "Mulish",
    cursor: 'pointer',
    // width: '250px',
    backgroundColor: "#fff",
    padding: "0 8px",
    fontSize: "12px",
    minWidth: "270px",
    height: "45px",
    borderRadius: "12px",
    minHeight: "35px",
    boxShadow: 'none',
    border: "1px solid #000"
  }),
  dropdownIndicator: (p: any): any  => (p),
  clearIndicator: (): any  => ({ display: 'none' }),
  indicatorSeparator: (): any  => ({ display: 'none' }),
  menu: (p: any): any  => ({ 
    ...p, 
    width: '100%',
    maxWidth: '100%', 
    border: "none",
    fontSize: "12px",
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
  })
}

export const SelectCustomStyles_wizard_bordered_white_scondary = {
  ...SelectCustomStyles,
  control: (p: any): any => ({ 
    ...p, 
    fontFamily: "Mulish",
    cursor: 'pointer',
    // width: '250px',
    backgroundColor: "#fff",
    padding: "0 8px",
    fontSize: "12px",
    minWidth: "270px",
    height: "45px",
    borderRadius: "12px",
    minHeight: "35px",
    boxShadow: 'none',
    border: "1px solid rgba(0, 0, 0, 0.3)"
  }),
  dropdownIndicator: (p: any): any  => (p),
  clearIndicator: (): any  => ({ display: 'none' }),
  indicatorSeparator: (): any  => ({ display: 'none' }),
  menu: (p: any): any  => ({ 
    ...p, 
    width: '100%',
    maxWidth: '100%', 
    border: "none",
    fontSize: "12px",
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
  })
}



export const SelectCustomStyles_Dropdown_white_bordered = (args?: {hideMenu?: boolean, inputStyle?: React.CSSProperties}): {
  control: (p: any) => any;
  dropdownIndicator: () => any;
  indicatorSeparator: () => any;
  clearIndicator: () => any;
  menu: (p: any) => any;
  option: (p: any) => any;
  loadingIndicator: () => any;
} => {
  const { hideMenu, inputStyle } = args || {};
  return {
    ...SelectCustomStyles,
    control: (p: any): any => ({ 
      ...p, 
      fontFamily: "Mulish",
      cursor: 'pointer',
      width: '290px',
      height: "40px",
      backgroundColor: "#FFF",
      fontSize: "14px",
      border: "1px solid #000",
      borderRadius: "50px",
      boxShadow: "none",
      display: "flex",
      flexFlow: "row-reverse",
      padding: "8px 12px",
      marginTop: "20px",
      ...inputStyle
    }),
    clearIndicator: (): any  => ({ display: 'none' }),
    indicatorSeparator: (): any  => ({ display: 'none' }),
    menu: (p: any): any  => hideMenu ? ({ display: 'none' }) : ({...p, width: '100%', maxWidth: '100%', marginBottom: "5vh"}),
  }
}

export const SelectCustomStyles_From_Scratch = {
  ...SelectCustomStyles,
  control: (p: any): any => ({ 
    ...p, 
    fontFamily: "Mulish",
    cursor: 'pointer',
    width: '290px',
    height: "40px",
    backgroundColor: "#FFF",
    fontSize: "14px",
    border: "1px solid #000",
    borderRadius: "50px",
    boxShadow: "none",
    display: "flex",
    flexFlow: "row-reverse",
    padding: "8px 12px",
    marginTop: "20px",
  }),
  clearIndicator: (): any  => ({ display: 'none' }),
  indicatorSeparator: (): any  => ({ display: 'none' }),
  menu: (p: any): any  => ({...p, width: '100%', maxWidth: '100%', marginBottom: "5vh"}),
  menuList: (p: any): any  => ({...p, maxHeight: "200px"}),
}

export const SelectCustomStyles_Suggestions = {
  ...SelectCustomStyles,
  control: (p: any): any => ({ 
    ...p, 
    cursor: 'pointer',
    width: '250px' ,
    backgroundColor: "#F7F7F7",
    border: "none",
    marginLeft: "0.25rem"
  }),
  clearIndicator: (): any  => ({ display: 'none' }),
  indicatorSeparator: (): any  => ({ display: 'none' }),
  menu: (p: any): any  => ({ ...p, width: 'auto', minWidth: '100%'})
}

export const SelectTheme = (theme: Theme): any => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#eee',
    primary: 'black',
  },
})