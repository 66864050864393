import React, { useState } from 'react';
import { graphQueries } from '@/helpers';
import { getCandidateAIQuestionResponse } from '@/graphql/queries/ai';
import './style.css';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';

const AICenter: React.FunctionComponent<{
  firstName?: string;
  candidateId?: string;
}> = ({ firstName, candidateId }): JSX.Element => {
  const [userQuestion, setUserQuestion] = useState<string>('');
  const [response, setResponse] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserQuestion(e.target.value);
  };

  const mockFunction = async () => {
    setIsLoading(true);

    const response = await graphQueries.sendRequest(getCandidateAIQuestionResponse, {
      candidateId,
      question: userQuestion,
    });
    setResponse(response.getCandidateAIQuestionResponse);
    setIsLoading(false);
  };

  const suggestedQuestions = [
    `What is ${firstName}'s experience?`,
    `Tell me about ${firstName}'s skills.`,
    `How punctual is ${firstName}?`,
    `What projects has ${firstName} worked on?`,
  ];

  return (
    <div className="ai-center-container" style={{ paddingTop: '50px' }}>
      <div className="input-and-suggestions-container">
        <TextField
          id="outlined-multiline"
          label={`Ask a question about ${firstName}`}
          variant="outlined"
          value={userQuestion}
          onChange={handleInputChange}
          className="search-bar"
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={mockFunction} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : <SearchIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="suggested-questions">
          {suggestedQuestions.map((question, index) => (
            <div key={index} className="question-bubble" onClick={() => setUserQuestion(question)}>
              {question}
            </div>
          ))}
        </div>
      </div>
      {response && !isLoading && (
        <div className="another-test">
          <Card className="response-card">
            <CardContent className="response-container">{response}</CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};

export default AICenter;
