import { AnalyticsTables, IPositions, ICompanyWizardEvents, IReferralEvents, IPositionWizardEvents, IComprofileEvents } from "../interfaces/utils/analytics.d";
import env from "react-dotenv";
import { ReadPositionResult } from "@/dal/position.interface";
import { IUser, ICompany } from "@auth/interfaces"
const analytic_env = String(env.ANALYTICS_ENV) || 'no env';
const queryAnalytics = `mutation ProxyAnalytics($body: JSON, $method: String, $path: String) {
  proxyAnalytics(body: $body, method: $method, path: $path)
}`

import { detect } from "detect-browser";

import { graphQueries } from "@/helpers";
import Cookies from "js-cookie";

const isLocal = /\/\/localhost:/i.test(env?.REST_API)


export class Analytics {
    static updatePositionsTable(rows: IPositions[]): void {
        this.sendReport(AnalyticsTables.POSITIONS, rows);
    }
    static updateReferralEventsTable(rows: IReferralEvents[]): void {
        this.sendReport(AnalyticsTables.REFERRAL_EVENTS, rows);
    }
    static updatePositionsWizardReportsTable(rows: IPositionWizardEvents[]): void {
        this.sendReport(AnalyticsTables.POSITION_WIZARD_EVENTS, rows);
    } 
    static updateComprofileEventsTable(rows: IComprofileEvents[]): void {
        this.sendReport(AnalyticsTables.COMPROFILE_EVENTS, rows);
    }
    static updateCompanyEventsTable(rows: ICompanyWizardEvents[]): void {
        this.sendReport(AnalyticsTables.COMPANY_WIZARD_EVENTS, rows);
    }

    static sendPositionEvent(
        event: string, 
        data: { 
            position: Partial<ReadPositionResult> | null | undefined, 
            user: IUser | null,
            company: ICompany | null,
        } ): void {
        const { position, user, company } = data;
        if(!user || !position || !company) {
            return;
        }
        
        const { id, title } = position;
        const { id: userId } = user;
        const { id: company_id } = company;
        Analytics.updatePositionsTable([{ 
            event,
            position_id: id,
            company_id,
            position_name: title, 
            published_by: userId,
            update_by: userId,
            update_at: new Date(),
        }]);
    }

    static sendPositionWizardReportEvent(
        type: string, 
        data: { 
            position_id: string | null | undefined, 
            user_id: string | null,
            company_id: string | null,
            screen: string,
            event: string,
            source: string
        } ): void {
        const { position_id, user_id, company_id, screen, event, source } = data;
        if(!user_id || !company_id) {
            return;
        }
        
        Analytics.updatePositionsWizardReportsTable([{ 
            session_id: Cookies.get("reportSessionId"),
            user_id: user_id,
            position_id: position_id,
            company_id: company_id,
            screen: screen,
            type: type,
            event: event,
            client_time: new Date().toLocaleString(),
            source: source
        }]);
    }

    static sendComprofileWizardReportEvent(
        data: { 
            user_id: string | null,
            screen: string,
            event: string,
            source: string
        } ): void {
        const { user_id, screen, event, source } = data;
        if(!user_id) {
            return;
        }
        
        Analytics.updateComprofileEventsTable([{ 
            session_id: Cookies.get("reportComprofileSessionId"),
            user_id: user_id,
            screen: screen,
            event: event,
            client_time: new Date().toLocaleString(),
            source: source
        }]);
    }

    static sendCompanyWizardReportEvent(
        data: { 
            user_id: string | null | undefined,
            screen: string,
            event: string
        } ): void {
        const { user_id, screen, event } = data;
        if(!user_id) {
            return;
        }
        
        Analytics.updateCompanyEventsTable([{
            user_id: user_id,
            screen: screen,
            event: event,
            type: "edit",
            client_time: new Date().toLocaleString(),
        }]);
    }
    
    static async sendReport(table_name: string, rows: any[]): Promise<any> {
        if (isLocal) {
            return;
        }
        //Adding missing fields to rows
        rows[0]["env"] = analytic_env;
        rows[0]["os"] = detect()?.os || null;
        rows[0]["browser"] = detect()?.name || null;
        rows[0]["timezone"] = new Date()?.getTimezoneOffset() || null;
        rows[0]["params"] = window?.location?.search || null;
        await graphQueries.sendRequest(
            queryAnalytics,
            {
                body: rows,
                method: "POST",
                path: "/api/r/write/event/" + table_name,
            }
          )
    }
}
