import AuthComponent from "@permissions/AuthComponent"
import permissions from "@permissions/permissions"

// Auth
import { useAuth, IAuth } from "@auth"

// Shared Interfaces
interface IPermissions {
  hasPermissions: (type: string) => boolean
}

/**
 *
 *  Use Permissions Custom Hook
 *  @description returns permissions utilities
 *
 */
const usePermissions = (): IPermissions => {
  // Auth
  const auth: IAuth = useAuth()

  return {
    hasPermissions: (type: string) => permissions[type](auth)
  }
}

// Exports
export {
  usePermissions,
  AuthComponent,
  permissions
}

export type { IPermissions }
