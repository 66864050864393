import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Checkbox from "@components/WizardComponents/Checkbox/Checkbox";
import { useState } from 'react';
import { HtmlTooltip } from '@/components/WizardComponents/CustomTooltip/CustomTooltip';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LText from '@/components/LText';

export const CustomizeYourSimulation: React.FunctionComponent<{ 
    buildingBlocks: { label: string, value: any, description?: string, link?: string }[]
    matchingBuildingBlocks: string[] | undefined,
    needToRenderSeperateLine: (index: number, bbLength: number) => boolean,
    onCancelCustomize: (args: any) => any,
    onSaveCustomizeSimulation: (bbs: string[]) => any,
    onCopyLinkClicked: (link?: string) => any
 }> = ({ 
    buildingBlocks, 
    matchingBuildingBlocks, 
    needToRenderSeperateLine,
    onCancelCustomize,
    onSaveCustomizeSimulation,
    onCopyLinkClicked
}):JSX.Element => {

    const [chosenBuildingBlocks, setChosenBuildingBlocks] = useState<string[]>(matchingBuildingBlocks ?? []);

    const onChangeBuildingBlockField = (id: string, checked: boolean) => {
        const set = new Set(chosenBuildingBlocks);
        if(checked) {
            set.add(id);
        } else {
            set.delete(id);
        }
        setChosenBuildingBlocks([...set]);        
    }

    return (
    <div className="building-blocks-step-container">
        <h2 className="building-blocks-step-header">Customize your simulation</h2>
        <p className="building-blocks-step-sentence">
            Choose which simulations to be included in the default simulation, or share has specific one with your candidates
        </p>
        <div className="building-blocks-checkboxes">
            {buildingBlocks.map((bb, i) => (
                <div key={bb.value}>
                    <HtmlTooltip 
                    placement='right'
                    title={bb.description ?? ""}>
                        <div style={{ width: "fit-content" }}>
                        <Checkbox 
                        key={bb.value}
                        label={bb.label}
                        className="building-blocks-checkbox"
                        style={{ color: "#fff" }}
                        checked={chosenBuildingBlocks?.includes(bb.value)}
                        onChange={(e, checked) => onChangeBuildingBlockField(bb.value, checked)}
                        name={`matchingBuildingBlocks[${i}]`}/>
                        {bb.link && 
                        <FileCopyOutlinedIcon 
                        onClick={() => onCopyLinkClicked(bb.link)}
                        className={`building-blocks-step-sim-icon-small ${!chosenBuildingBlocks?.includes(bb.value) ? "building-blocks-step-sim-icon-disabled" : ""}`}/>}
                        </div>
                    </HtmlTooltip>
                    
                    { needToRenderSeperateLine(i+1, buildingBlocks.length) && 
                    <div className="building-blocks-checkbox-seperate-line"></div>}
                </div>
            ))}
            <div className="building-blocks-step-sim-btn-container">
                <button 
                onClick={() => onSaveCustomizeSimulation(chosenBuildingBlocks)}
                className="building-blocks-step-sim-btn">
                    Save default simulation
                </button>
                <button
                className="building-blocks-step-cancel-btn"
                onClick={onCancelCustomize}>
                    Cancel
                </button>
            </div>
        </div>
    </div>
    )
}


export const GetTheSimulation: React.FunctionComponent<{
    simulatorUrl: string,
    copyJobSimUrlToClipboard: (link: string) => any,
    onClickCustomizeSim: (args: any) => any,
}> = ({
    simulatorUrl,
    copyJobSimUrlToClipboard,
    onClickCustomizeSim
}):JSX.Element => {
    return (
    <div className="building-blocks-step-container">
        <h2 className="building-blocks-step-header"><LText text="Get the Simulation" /></h2>
        <p className="building-blocks-step-sentence">
            <LText text="In-depth Simulation that includes qualifications matching, situational simulations and Unboxable Hiring DNA matching simulation" />
        </p>
        <div className="building-blocks-step-sim-btn-container">
                <a 
                href={simulatorUrl}
                target="_blank"
                className="building-blocks-step-sim-btn">
                    <LText text="Go to the simulation" />
                </a>
                <FileCopyOutlinedIcon 
                onClick={() => copyJobSimUrlToClipboard(simulatorUrl)}
                className="building-blocks-step-sim-icon" />
        </div>
        <p 
        className='building-blocks-step-customize-button'
        onClick={onClickCustomizeSim}>
            <LText text="Customize your Job Simulations" />
        </p>
    </div>
    )
}

export const WhatsNext: React.FunctionComponent<{ cards: {text: string, onClick: () => any, hide?: boolean}[] }> = ({
    cards
}): JSX.Element => {
    return (
        <div className="whats-next-component">
            <span className="whats-next-title">
                <LText text="What's next?" />
            </span>
            <div className="whats-next-cards">
                {cards.flatMap((c) => c.hide ? undefined : (
                    <div className="whats-next-card" key={c.text} onClick={c.onClick}>
                        <ArrowForwardIcon/>
                        <LText text={c.text} />
                    </div>
                ))}
            </div>
        </div>
    )
}