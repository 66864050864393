import React, { useContext, useState } from "react";

import WizardStepTemplate from "@components/WizardComponents/WizardStepTemplate/WizardStepTemplate";
import { IWizardStep } from "../../interfaces";
import TextField from "@components/WizardComponents/TextField/TextField";
import SuggestionCard from "@/components/WizardComponents/SuggestionCard/SuggestionCard";
import Label from "@/components/WizardComponents/Label/Label";

import { ConfirmationInput } from "@/components/WizardComponents/ConfirmationInput/ConfirmationInput";

import './style.css';
import AutocompleteCustom from "@/components/WizardComponents/AutocompleteNew/AutocompleteNew";
import AppContext from "@/context/AppContext";

const TITLE = "Receiving applicants' info"
const DESCRIPTION = "Add your ATS inbox below (don't worry if you don't have one.) For any update on Workspace activity, like when a candidate applies or when there's a match, add email info to the second line."

const RecipientsStep: React.FunctionComponent<IWizardStep> = ({ formik }):JSX.Element => {
    const { values, handleChange, setFieldValue } = formik;
    const { atsEmail, matchingRecipients, matchingPermissions, matchingHrms } = values;
    const { tr } = useContext(AppContext);

    const [
        lastHrmMail, 
        setLastHrmMail
    ] = useState(matchingHrms?.email ?? '');

    const [hrmInputVal, setHrmInputVal] = useState<string>(matchingHrms?.email ?? '');

    const onChangedHrmMail = (mail: string | null) => {
        if (mail) {
            setLastHrmMail(mail);
            setFieldValue('matchingHrms', { name: "", email: mail});
        } else {
            setFieldValue('matchingHrms', []);
        }
    }   

    return (
        <WizardStepTemplate
        title={TITLE}
        description={tr(DESCRIPTION)}>
            <div className="ats-mail-section-mail-inputs-container">
                <TextField 
                label={tr("Specify the ATS email address")}
                uiType="white-bordered"
                value={atsEmail}
                name={"atsEmail"}
                onChange={handleChange}
                placeholder={tr("ATS inbox address")}/>
                <AutocompleteCustom
                placeholder={tr("Specify the recipients email address")}
                label={tr("Send Talent reports to the following inbox/es")}
                value={matchingRecipients?.map((r) => ({ value: r, label: r }))}
                options={matchingRecipients?.map(r => ({label: r, value: r})) ?? []}
                freeSolo={true}
                getOptionLabel={o => o.label}
                onChange={(e, newArr: any[]) => setFieldValue("matchingRecipients", newArr.map(r => r.value))}/>
                <AutocompleteCustom
                placeholder={tr("Limit access to this position, by giving permissions to specific Email addresses")}
                label={tr("Who can view this position? (Leave blank for everyone)")}
                value={matchingPermissions?.map((p) => ({ value: p, label: p }))}
                freeSolo={true}
                getOptionLabel={o => o.label}
                options={matchingPermissions?.map((p) => ({ value: p, label: p })) ?? []}
                onChange={(e, newArr: any[]) => setFieldValue("matchingPermissions", newArr.map(p => p.value))}/>
                <div className="recipients-step-hrm-section">
                    <Label>{tr("The hiring manager for this position")}</Label>
                    <div className="recipients-step-hrm-input-or-card">
                        {matchingHrms?.email ?
                        <SuggestionCard
                        selected={true}
                        suggestion={{value: matchingHrms?.email, label: matchingHrms?.email} as any}
                        showDeleteIcon={true}
                        onClick={() => onChangedHrmMail(null)}/> 
                        :
                        <ConfirmationInput 
                        placeholder="user@company.com"
                        inputValue={hrmInputVal} 
                        handleInputChange={(e)=> setHrmInputVal(e.target.value)} 
                        onConfirm={() => onChangedHrmMail(hrmInputVal)} 
                        hideCancelButton={lastHrmMail ? false : true}
                        onCancel={() => {
                            setHrmInputVal(lastHrmMail);
                            onChangedHrmMail(lastHrmMail);
                        }}/>}
                    </div>
                </div>
            </div>
        </WizardStepTemplate>
    )
}

export default RecipientsStep;