import { useContext, useEffect, useState } from "react";
import { relevancyCards } from "@/content/talentRelevancyCards";
import { CvPdf } from "@/pages/CandidateReport/V1/parts/CvPdf/CvPdf";
import Modal from "@/components/WizardComponents/Modal/Modal";
import { TypeOfRelevency } from "@/pages/CandidateReport/V2/data/report.utils";
import { ICandidate } from "@/pages/Talents/interfaces";
import env from "react-dotenv";
import { useHistory } from "react-router";
import RelevancyCard from "./RelevancyCard";

import CVIcon from '@assets/icons/cv-icon.svg';
import ReportIcon from '@assets/icons/report-icon.svg';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import './style.css'
import { copyToClipboard } from "@/helpers";
import axios from "axios";

import useLocalToast from "../../hooks/useLocalizedToast";
import AppContext from "@/context/AppContext";
//     const { toast } = useLocalToast();


const S3_ENDPOINT = env.LNRD_ENGINE_S3_WEST;
const APP_ORIGIN = window.location.origin;

const RelevancyCards: React.FunctionComponent<{ candidatesByRelevancy: { [key in TypeOfRelevency]: ICandidate[] }, loading?: boolean, allPositions?: boolean }> = ({ 
    candidatesByRelevancy, loading, allPositions }): JSX.Element => {
    const history = useHistory();
    
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [showCVModal, setShowCVModal] = useState<boolean>(false);

    const [cvUrl, setCVUrl] = useState<string | undefined>(undefined);
    const [reportUrl, setReportUrl] = useState<string | undefined>(undefined);
    const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);

    const [cvName, setCVName] = useState<string | undefined>(undefined);
    const [candidateEmail, setCandidateEmail] = useState<string | undefined>(undefined);
    const [positionName, setPositionName] = useState<string | undefined>(undefined);
    const [cards, setCards] = useState<null | typeof relevancyCards>(null);

    const { locale, tr } = useContext(AppContext);

    const { toast } = useLocalToast();

    useEffect(() => {
        setCards(tr(relevancyCards, ["noCandidatesMessage", "title", "description"]))
    }, [locale])


    const onReportIconClicked = (c: ICandidate) => history.push(`/review/${c.V2PositionId}/${c.id}`);

    const onReportCVClicked = (c: ICandidate) => {
        setCVUrl(`${S3_ENDPOINT}${c.cv}`);
        setShowCVModal(true);
    }

    const onDownloadClicked = (c: ICandidate) => {

        setReportUrl(`${APP_ORIGIN}/review/${c?.V2PositionId}/${c?.id}`);
        setCVUrl(`${S3_ENDPOINT}${c?.cv}`);
        setPdfUrl(c?.pdfUrl)

        setPositionName(c?.v2PositionTitle)
        setCandidateEmail(c?.email)
        setCVName(c?.cvName)
        
        setShowDownloadModal(true);
    }    

    const onClickCopyReportLink = (reportUrl: string) => {
        copyToClipboard(reportUrl)
        .then(() => toast('Link Copied!', { containerId: "default", type: "success" }))
    }
    
    const downloadPdfFromLink = (pdfUrl: string, fileName: string) => {
    
        axios({
        url: pdfUrl,
        method: "GET",
        responseType: "blob",
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
        })
        .catch(console.error);
    }

    if (!cards) {
        return <></>
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "42px" }}>
            {Object.entries(cards).map(([key, value], i) => (
                <RelevancyCard
                key={i}
                noCandidatesMessage={value.noCandidatesMessage}
                loading={loading}
                type={value.uiType}
                title={value.title}
                subTitle={allPositions ?  `- ${tr("All Simulations")}` : undefined}
                showPositionName={allPositions}
                icon={value.icon}
                tooltip={value.description}
                candidates={(candidatesByRelevancy as any)?.[key] ?? []}
                onNameClicked={onReportIconClicked}
                reportIconProps={{
                    onClick: onReportIconClicked,
                    show: (c: ICandidate) => c.id && c.V2PositionId ? true : false,
                }}
                CVIconProps={{
                    onClick: onReportCVClicked,
                    show: (c: ICandidate) => c.cv && c.cv.length > 0 ? true : false,
                }}
                downloadIconProps={{
                    onClick: onDownloadClicked,
                    show: (c: ICandidate) => c.cv || c.pdfUrl ? true : false,
                }}
                />
            ))}

            <Modal 
            openModal={showCVModal} 
            onClose={() => setShowCVModal(false)}>
                <div className="cv-modal">
                    <CvPdf 
                    url={cvUrl}/>
                </div>
            </Modal>
            <Modal 
            openModal={showDownloadModal} 
            onClose={() => setShowDownloadModal(false)}>
                <div className="downlaod-modal">
                    <h3 className="downlaod-modal-header">Download</h3>
                    <div className="downlaod-modal-downloads-buttons">
                        {pdfUrl &&
                        <a onClick={() => downloadPdfFromLink(pdfUrl, `${candidateEmail}-${positionName}.pdf`)}>
                            <span>Report</span>
                            <img src={ReportIcon}/>
                        </a>}
                        {cvUrl &&
                        <a onClick={() => downloadPdfFromLink(cvUrl.split("?")[0], cvName ?? `${candidateEmail}-${positionName}-CV.pdf`)}>
                            <span>CV</span>
                            <img src={CVIcon}/>
                        </a>}
                    </div>
                    {reportUrl &&
                    <div className="downlaod-modal-share">
                        <span className="downlaod-modal-share-header">Share report with link</span>
                        <div className="downlaod-modal-share-input">
                            <p>{reportUrl}</p>
                            <FileCopyOutlinedIcon
                            onClick={() => onClickCopyReportLink(reportUrl)}/>
                        </div>
                    </div>}
                </div>
            </Modal>
        </div>
    )
}

export default RelevancyCards;