import { useEffect, useState } from 'react';

// Components
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Loading from "@components/Table/Loading";
import {  EmptyState } from "@components";

// Assets
import emptyStateImageSrc from "@assets/images/empty-image.png";

import './style.css';
import TableEnumFilters, { IFilter, ISelectedFilters, NumberFilterOperators } from './parts/TableEnumFilters';
import TableSearchBar from './parts/TableSearchBar';
import { IMultiSelect } from '../WizardComponents/MuiltiSelect/MultiSelect';

// Interfaces
export interface TableProps{
  columns: GridColDef[]
  loading: boolean
  emptyStateTitle: string
  emptyStateBtnText?: string
  emptyStateBtnAction?: () => void
  originalData: any[];
  searchOnColumns?: string[];
  enumFilters?: IFilter[];
  filterMultiSelectProps?: IMultiSelect;
  numberFilterPlaceholder?: string; 
}

const MaterialTable: React.FunctionComponent<TableProps> = 
({
  columns, 
  loading, 
  emptyStateTitle, 
  emptyStateBtnText, 
  emptyStateBtnAction,
  originalData,
  searchOnColumns,
  enumFilters,
  filterMultiSelectProps,
  numberFilterPlaceholder
}): JSX.Element => {

  // States
  const [pageSize, setPageSize] = useState<number>(10);
  const [tableRows, setTableRows] = useState(originalData);
  const [ filters, setFilters ] = useState<ISelectedFilters>({
    enum: {},
    number: {}
  });

  useEffect(() => {
    if(!loading) {
      setTableRows(originalData)
    }
  }, [originalData, loading])

  useEffect(() => {
    applyFilters();
  }, [filters])

  const applyFilters = () => {

    if(!Object.entries(filters.enum).length && !Object.entries(filters.number).length){
      setTableRows(originalData)
      return
    }

    let filteredData = originalData;
    Object.entries(filters.enum)
    .forEach(([field, value]) => {
        if(!value || !value.length) return;
        
        filteredData = filteredData.filter( r => value.includes((r as any)[field]) )
    });

    Object.entries(filters.number)
    .forEach(([name, filter]) => {
      filteredData = filteredData.filter(r => {
        let value = r[name];
        const pathToField = enumFilters?.find(f => f.field === name)?.pathToField;
        if(pathToField){
          const pathes = pathToField.split(".")
          pathes.forEach(p => value = value?.[p])
        }
        return generateConditionFunction(filter.operator!)(value, filter.filterValue!)
      })
    });

    setTableRows(filteredData)
  }


  const handleEnumFiltering = (name: string, selected: string[]) => {
    const enumFilters = filters.enum
    const newFilters = {
        ...enumFilters,
        [name]: selected
    }
    if(!selected.length) delete newFilters[name];

    setFilters(prev => ({ 
      ...prev, 
      enum: newFilters
    }))
  }

  const generateConditionFunction = (operator: NumberFilterOperators): ((value: number, filterValue: number) => boolean) => {
    const operatorFunc: { [op in NumberFilterOperators]: (value: number, filterValue: number) => boolean } = {
      ">=": (value: number, filterValue: number) => value >= filterValue,
    }

    return operatorFunc[operator]
  }

  const handleNumberFiltering = (name: string, operator: NumberFilterOperators, filterValue: number | null) => {

    const numberFilters = filters.number
    const newFilters = {
        ...numberFilters,
        [name]: { name, operator, filterValue }
    }

    if(!filterValue) {
      delete newFilters[name];
    }
    
    setFilters((prev) => ({
      ...prev,
      number: newFilters as any
    }))
  }

  return (
    <>
    {!loading &&
    <div className='table-toolbar'>
      <TableEnumFilters 
      onFilterChange={handleEnumFiltering}
      onNumberFilterChange={handleNumberFiltering}
      filters={enumFilters}
      multiSelectProps={filterMultiSelectProps}
      selectedFilters={filters}
      numberFilterPlaceholder={numberFilterPlaceholder}
      />
      {searchOnColumns && searchOnColumns.length && 
      <TableSearchBar 
      data={originalData} 
      setRows={setTableRows} 
      searchOnColumns={searchOnColumns}/>}
    </div>}
      {/* Table */}
      <DataGrid
      autoHeight
      disableColumnMenu
      rows={tableRows} 
      columns={columns} 
      disableSelectionOnClick
      rowHeight={80}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 25, 100]}
      sx={{
          backgroundColor: "#fff",
          borderRadius: "5px",
          padding: "20px",
          border: "none",
          display: "relative",
          "& .MuiDataGrid-main": {
            // overflow: "unset",
          },
          "& .MuiDataGrid-columnHeaders":{
            overflow: "unset",
            borderBottom: loading || tableRows.length < 1 ?  "none" : "",
            "& .MuiDataGrid-columnHeader": {
              outline: "none",
            }
          },
          "& .MuiDataGrid-columnHeadersInner" :{
            display: loading || tableRows.length < 1 ?  "none" : "",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell": {
            paddingLeft: "14px",
            fontFamily: 'Mulish',
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: 'Mulish',
            fontWeight: 700
          }
      }}
      loading={loading}
      hideFooter={loading || tableRows.length < 1}
      components={{
        NoRowsOverlay: () => <EmptyState
                              title={emptyStateTitle}
                              imageSrc={emptyStateImageSrc}
                              buttonText={emptyStateBtnText}
                              buttonOnClick={emptyStateBtnAction}
                            />,
        LoadingOverlay: () => <Loading/>,        
      }}
      />
  </>
  );
}

export default MaterialTable;