import env from "react-dotenv";
import { ControlledPromise, IControlledPromise } from "./controlledPromise";

/**
 * A service, typically a singleton, responsible for looking up configuration values that arrive
 * from .env, settings files and the URL. The implementation must be instantiated before any routing takes
 * place (a process that removes that various url search params)
 * Example url: http://localhost:3000/?config=config/settings-auto-update.json
 * This will load the file settings-auto-update.json from the config folder under public
 */
export interface ISettingsService {
	getSetting(key: string, defaultValue?: string): string
	getBooleanSetting(key: string, defaultValue: boolean): boolean
	hasSetting(key: string): boolean
	isLocalMode(): boolean
	readonly isLoaded: boolean

}

export class SettingsService implements ISettingsService {
	private readonly _searchParams: URLSearchParams;
	private readonly _privateSettings: { [key: string]: string } = {}
	constructor(params: URLSearchParams) {
		this._searchParams = new URLSearchParams(params);
		//this.loadConfiguration(this.getSetting("config", ""))
	}

	// allow many clients to await this.load. simple solution instead of finding my multipromise class
	private readonly loadPromises: IControlledPromise<boolean>[] = []

	private status: "none" | "loading" | "loaded" = "none"
	/**
	 * Load the settings from settings files
	 * @param config leave null to use the convention config=XXX in the URL
	 */
	public async load(config?: string): Promise<boolean> {
		if (this.status === "loaded") {
			return true
		}
		if (this.status === "loading") {
			const p = new ControlledPromise<boolean>();
			this.loadPromises.push(p);
			return p.promise
		}
		this.status = "loading"
		let finalConfig = this.getSetting("config", "")
		if (config) {
			finalConfig = finalConfig.split(',').concat([config]).join(',')
		}
		const ret = await this.loadConfiguration(finalConfig)
		const toResolve = this.loadPromises.slice();
		this.loadPromises.length = 0;
		this.status = "loaded"
		toResolve.forEach(cp => cp.resolve(ret));
		return ret
	}

	public get isLoaded(): boolean {
		return this.status === "loaded"
	}

	public hasSetting(key: string): boolean {
		return Boolean(key) && (key in this._privateSettings || this._searchParams.has(key) || env[key] !== undefined)
	}

	public getSetting(key: string, defaultValue?: string): string {
		if (!key) {
			return defaultValue || ""
		}
		// URL args take precedence
		if (this._searchParams.has(key)) {
			return this._searchParams.get(key)!;
		}
		// Next we check the loaded settings
		if (key in this._privateSettings) {
			return this._privateSettings[key]
		}
		// Finally look up the key in .env
		const envValue = env[key]
		return (envValue !== undefined) ? envValue : (defaultValue || "")
	}

	// true, yes, 1 are all truthy
	private TRUE_RE = /^\s*(:?true|yes|1)\s*$/i

	public getBooleanSetting(key: string, defaultValue: boolean): boolean {
		if (!key) {
			return defaultValue
		}
		const value: any = this.getSetting(key)
		const type = typeof value
		if (type === "string") {
			return this.TRUE_RE.test(value)
		}
		if (type === "boolean") {
			return value as boolean
		}
		if (type === "number") {
			return value === 1
		}
		return defaultValue
	}

	public isLocalMode(): boolean {
		const url = this.getSetting("REST_API", "")
		return (/\/\/localhost:/i.test(url))
	}

	/**
	 * Load extra configuration files
	 * @param config comma separated list of configuration files, under /
	 */
	private async loadConfiguration(config: string): Promise<boolean> {
		if (!config) {
			return true
		}
		// collect all valid configs
		const configs = config.split(',').map(s => s.trim()).filter(Boolean)
		let ret = true
		for (const conf of configs) {
			try {
				const response = await fetch(`/${conf}`)
				const values = await response.json()
				if (values && typeof values === "object") {
					Object.assign(this._privateSettings, values)
				}
				else {
					console.error(`No values found in ${conf}`)
					ret = false
				}
			}
			catch (e) {
				console.error(`Error loading ${conf} ${e}`)
				ret = false
			}
		}
		return ret
	}

}
