// Styles
import './style.css';

// Icons
import arrowIcon from '../../icons/arrow.svg';

//Helpers
import ReactHtmlParser from 'react-html-parser';

import { BooleanAnswer, RatingAnswer, ArrayAnswer, StringAnswer, OneOfManyAnswer, ImagePickerAnswer, FileAnswer, ImageAnswer, DynamicAnswer, MatrixAnswer, MultipletextAnswer, RankingAnswer } from './parts/answerTypesComponents';
import { downloadFile } from './helpers';

// Interfaces
interface IQuestionsAnswersSection {
    skills: any[]
    skipedQustions: any
}

const renderAnswerBasedType = (ans: any, type: string, score: number) => {
    if(type === 'tagbox' || type === 'checkbox') {
        return <ArrayAnswer 
                answer={ans}/>
    }
    if(type == 'text' || type === 'comment' || type === 'dropdown' || type === 'expression'){
        return <StringAnswer 
                answer={ans}/>
    }
    if(type === 'ranking') {
        return <RankingAnswer
                answer={ans}
                score={score}/>
    }
    if(type === 'rating') {
        return <RatingAnswer
                answer={ans} 
                choices={(ans?.payload?.rateValues ?? ans?.payload?.choices) || (ans?.rateValues ?? ans?.choices)}/>
    }
    if(type === 'radiogroup'){
        return <OneOfManyAnswer 
                answer={ans} 
                choices={(ans?.payload?.rateValues ?? ans?.payload?.choices) || (ans?.rateValues ?? ans?.choices)}/>
    }
    if(type === 'matrixdynamic' || type === 'paneldynamic') {              
        return <DynamicAnswer answer={ans}/>
    }
    if(type === 'boolean') {
        return <BooleanAnswer 
                answer={ans}/>
    }
    if (type === 'imagepicker') {
        return <ImagePickerAnswer answer={ans} />
    }
    if(type === 'file') {        
        return <FileAnswer answer={ans} />
    }
    if(type === 'image') {        
        return <ImageAnswer answer={ans}/>
    }
    if(type === 'matrix') {
        if(ans?.answer) {
            return <MatrixAnswer answer={ans}/>
        }
    }
    if(type === 'multipletext') {        
        return <MultipletextAnswer answer={ans}/>
    }
    else{
        console.log("Error display answer: ",ans);
        return <span>Talent did not answer this question</span>
    }
}

const panelAnswerHandler = (childrens: any, skipedQustions: any) => {
    let isSkippedQuestionRendered = false;
    const listItems: JSX.Element[] = []
    return childrens?.map((a:any, i: number) => {
        const isSkippedQuestion = Object.prototype.hasOwnProperty.call(skipedQustions ?? {}, a?.question?.name);
        if(isSkippedQuestion && !isSkippedQuestionRendered) {
            isSkippedQuestionRendered = true;
            return (
                <>
                    <div>
                    <p>The Talent decided to skip this question.</p>
                    <p>Their reason: {skipedQustions[a?.question?.name]}</p>
                    </div>
                </>
            )
        }
        if(!isSkippedQuestion && !["file", "image"].includes(a.question.type)) {
            listItems.push(<li>{ renderAnswerBasedType(a.question, a.question.type, a.score) }</li>)            
            if(i === childrens.length - 1){
                return <ul className='report-qa-custom-list'> 
                        {listItems.map(Item => Item)}
                     </ul>
            }
        }
    });
}

const panelQuestionHandler = (elements: any) => {    
    
    return (
        <ul className='report-qa-custom-list'>
        {elements?.map((e:any, i: number) => {
                if(i === 0) {
                    if(e.type === 'image') {
                        return <img key={i+e?.title} src={e.imageLink} style={{ width: "180px", marginBottom: "10px" }}/>;
                    }
                    if(e.type === 'expression') {
                        return <a style={{
                                        fontWeight: 700,
                                        color: "#262626",
                                        cursor: "pointer", 
                                        marginBottom: "10px"
                                    }} 
                                    key={i+e?.title}
                                    onClick={() => downloadFile(e?.title, e?.title)}
                                    >
                                        {"Download file"}
                                </a>
                    }
                    if(e.type === 'file') {
                        return <a style={{
                            fontWeight: 700,
                            color: "#262626",
                            cursor: "pointer", 
                            marginBottom: "10px"
                        }} 
                        key={i+e?.title}
                        onClick={() => downloadFile(e?.defaultValue[0]?.content, e?.defaultValue[0]?.name)}
                        >
                            {"Download file"}
                        </a>
                    } else {
                        return (
                            <li key={i+e?.title}>
                                {ReactHtmlParser(e?.title)}
                            </li>
                        )
                    }
                }
                return (
                <li key={i+e?.title}>
                    {ReactHtmlParser(e?.title)}
                </li>
                )
            })}
        </ul>
    )
}

const renderQuestion = (q: any) => {
    if(q?.question?.type !== 'panel') {
        return ReactHtmlParser(q?.question?.payload?.title ?? q?.question?.title)
    }
    
    return panelQuestionHandler(q?.question?.payload?.elements)
}

const renderAnswer = (skipedQustions: any, q: any) => {

    if(Object.prototype.hasOwnProperty.call(skipedQustions ?? {}, q.question.name)) {
        return (
            <>
        <p>The Talent decided to skip this question.</p>
        <p>Their reason: {skipedQustions[q.question.name]}</p>
        </>
       )
    }
    if(q.question.type !== 'panel') {
        return renderAnswerBasedType(q?.question, q?.question?.type, q?.score)   
    } 
    return panelAnswerHandler(q?.children, skipedQustions)
}

const questionTypeToName: any = {
    "Multiple options": ["checkbox", "tagbox", "matrix"],
    "Selected option": ["radiogroup", "imagepicker"],
    "Ordering question": ["ranking"],
    "Content upload" : ["file"],
    "Open question" : ["comment", "text"],
    "Rate question": ["rating"],
    "Yes/No question": ["boolean"],
    "Grouped questions": ["panel"]
};

const getQuestionType = (q: any): string => {
    const qTypeName = Object.keys(questionTypeToName).find((k: any) => {
        if (questionTypeToName[k]?.includes(q.question.type)) {
            return k 
        }
    });
    return qTypeName ? qTypeName :  q.question.type
}

const QuestionsAnswersSection: React.FunctionComponent<IQuestionsAnswersSection> = (props): JSX.Element => {
    const { skills, skipedQustions } = props;    
    
    return (
        <div className='talent-report-qustions-answers-section' id={"answer-question-section"}>
            <h2>Deep Dive</h2>

            <div style={{ marginTop: "20px" }}>
                {skills?.map((s: any, i: number) => (
                    <div key={i+"skill-header"} id={s.evaluator.title}>
                    <div className='talent-report-qustions-answers-head' >
                        <div className='talent-report-qustions-answers-head-content'> 
                            <img src={arrowIcon}/>
                            <h3>
                                {s.evaluator.title === "Eligibility questions" ? "Qualifications questions" : s.evaluator.title}
                            </h3>
                            {s.evaluator.level && s.evaluator.level !== "none" ? 
                                <>
                                    <div></div> 
                                    <span>
                                        {s.evaluator.level}
                                    </span>
                                </>
                            : null}
                        </div>
                    </div>
                    {s.questions?.map((q: any, i: number) => (
                        q?.question?.type !== 'image' && q?.question?.type !== 'expression' ? 
                        <div key={i+"skill-q-a"}>
                            {i !== 0 ? <div className="talent-report-qustions-answers-seperate-line"> </div> : null}
                            <div className='talent-report-qustions-answers-q-n-a'>
                            <div className='talent-report-qustions-answers-q-n-a-content' >
                                <div className='talent-report-qustions-answers-q-n-a-qustion'>

                                    <div className='talent-report-qustions-answers-q-n-a-qustion-text'>
                                        <span className='talent-report-qustions-answers-question-type'>
                                            {getQuestionType(q)}
                                        </span>
                                        <div>
                                            {renderQuestion(q)}
                                        </div>
                                    </div>
                                </div>
                                <div className='talent-report-qustions-answers-q-n-a-answer'>
                                    <span className='talent-report-qustions-answers-question-type'>
                                            Reply
                                    </span>
                                    <div className='talent-report-qustions-answers-q-n-a-answer-text'>
                                        {renderAnswer(skipedQustions, q)}
                                    </div>
                                </div>
                            </div> 
                            </div>
                        </div>
                        
                        : null
                    ))}
                    </div>
                ))}

            </div>

        </div>
    )
} 

export default QuestionsAnswersSection;