import { gql } from "graphql-request";

export const createFavoriteCandidateMutation = gql`
mutation CreateFavoriteCandidate($candidateId: UUID!, $userId: UUID!, $favoredBy: String) {
    createFavoriteCandidate(CandidateId: $candidateId, UserId: $userId, favoredBy: $favoredBy) {
        response
    }
}`

export const deleteFavoriteCandidateMutation = gql`
mutation Mutation($candidateId: UUID!, $userId: UUID!) {
    deleteFavorite(candidateId: $candidateId, userId: $userId) {
        response
    }
}`