
export interface PositionSkill {
  necessityLevel: string;
  proficiency: string;
  name: string;
}

export interface PositionEducation {
  degree: string;
  educationType: string;
  scale: string;
}

export interface PositionDayInALife {
  time: string;
  description: string;
}

export interface PositionCultureAndValue {
  value: string;
  belief: string;
}

export interface PositionLeaders {
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  isUserExisting: string;
  userId: string;
  description: string;
  sequence: string;
  isHrm: boolean;
}

export enum PositionJobTypes {
  FullTime = 'FullTime',
  PartTime = 'PartTime'
}

export enum PositionTemplates {
  Classic = '0',
  TheUnboxableExperience = '1'
}

interface CommonPosition {
  assessmentId: string | null;
  assessmentName: string | null;
  assessmentStatus: string | null;
  metadata: { [k: string]: any };
  department: string;
  title: string;
  description: string;
  yearsOfExperience: string;
  yearsOfManagmentExperience: string; 
  status: string;
  positionFormJson: { [k: string]: any };
  rolesAndResponsibilities: string[];
  updatedRolesAndResponsibilities: string;
  leaders: Array<Partial<PositionLeaders>>;
  cultureAndValue: Array<Partial<PositionCultureAndValue>>;
  location: string;
  jobTypes: PositionJobTypes;
  templates: PositionTemplates;
  skills: Array<Partial<PositionSkill>>;
  comProfile: string;
  positionImage: string;
  eligibilities: string[];
  recipients: string[];
  education: Array<Partial<PositionEducation>> | any[];
  atsEmail: string;
  dayInALife: Array<Partial<PositionDayInALife>>;
  isPublished: boolean;
  buildingBlocks: string[];
  isManager: boolean;
  qualifications: any;
  educations: any[];
  languages: string[];
  hobbies: string[];
  volunteering: string[];
  matchingOtherTitles: string[];
  matchingDescription: string;
  matchingDepartment: string;
  matchingSkills: Array<Partial<PositionSkill>>;
  matchingEducation: Array<Partial<PositionEducation>> | any[];
  matchingEligibilities: string[];
  matchingLocation: string;
  matchingBuildingBlocks: string[];
  matchingLanguages: string[];
  matchingHobbies: string[];
  matchingVolunteering: string[];
  matchingRecipients: string[];
  matchingTitle: string;
  matchingHrms: { name: string, email: string }[]
  matchingTemplateId: string;
  isUpdatingEditor: boolean;
}

export interface CreatePositionRequest extends Partial<CommonPosition> {
  companyId: string;
  userId: string;
  title: string;
  department: string;
}

export interface UpdatePositionRequest  extends Partial<CommonPosition>{
  positionId: string;
  companyId?: string;
  deleted?: boolean;
}

export interface ReadPositionResult extends CommonPosition {
  CompanyId: string;
  id: string;
  recipients: string[];
  slug: string;
}