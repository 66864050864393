import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "@/auth";
import { Title } from "@/components";
import {
    // HiringBrain,
    MyRecommendations,
    ReqruitmentStats
} from "./parts";

import './style.css';
import AppContext from "@/context/AppContext";

const HRCorner: React.FunctionComponent = (): JSX.Element => {

    const { user, company } = useAuth();
    const { locale, tr } = useContext(AppContext);
    const [sections, setSections] = useState<{ [key: string]: { component: JSX.Element, header: string } }>({});

    const history = useHistory();
    const hash = history.location.hash;

    useEffect(() => {
        const routes = Object.keys(sections);
        if (!routes.includes(hash)) {
            history.push(routes[0])
        }
    }, [hash])

    useEffect(() => {
        const sects = {
            "#recommendations": {
                component: <MyRecommendations
                    userId={user?.id}
                    companyId={company?.id} />,
                header: tr("My Recommendations")
            },
            "#stats": {
                component: <ReqruitmentStats
                    dashboard={8}
                    companyId={company?.id} />,
                header: tr("Recruitment Statistics")
            }
            ,
            // "#hiring-brain": {
            //     component: <HiringBrain/>,
            //     header: "Hiring Brain"
            // }
        }
        setSections(sects);
    }, [locale])


    return (
        <div>
            <Title text={(sections as any)?.[hash]?.header} />
            {/* <div className="hr-corner-sections-navigator">
                {Object.entries(sections).map(([hashKey,value], index) => (
                    <span
                    key={index}
                    className={hash === hashKey ? "hr-corner-sections-navigator-active" : undefined}
                    onClick={() => history.push(hashKey)}>
                        {value.header}
                    </span>
                ))}
            </div> */}
            <div className="hr-corner-current-section-container">
                {(sections as any)?.[hash]?.component}
            </div>
        </div>
    )
}

export default HRCorner;